import { useState, useContext, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './ApplyPage.module.css';
import AuthContext from '../../../store/auth-context';
import CheckboxAndLabel from '../../UI/CheckboxAndLabel';
import SimpleInput from 'components/UI/SimpleInput';
import { simpleSubmitHandler } from 'util/HelperFunctions';
import Swal from 'sweetalert2';
import useValidatePageAccess from 'hooks/useValidatePageAccess';

const initialState = {
  biz_reg_no: '',
  company_name: '',
  user_name: '',
  user_email: '',
  htel: '',
  api_reason: '',
  terms_agree_yn: false,
  note_check_yn: false,
  sol_email: '',
  agency_name: '',
};

function reducer(state, action) {
  if (action.type === 'REPLACE') {
    const value = action.value;

    return {
      ...state,
      biz_reg_no: value.biz_reg_no,
      company_name: value.company_name,
      user_name: value.username,
      htel: value.htel,
      api_reason: value.api_reason,
      sol_email: value.sol_no && value.email,
      ser_cd: value.ser_cd,
      user_email: value.email
    };
  } else {
    return {
      ...state,
      [action.key]: action.value
    };
  }
}

const ApplyPage = () => {
  useValidatePageAccess();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const userInfo = authCtx.userInfo;
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, dispatchFormValues] = useReducer(reducer, initialState)
  const [userType, setUserType] = useState('일반');
  const formConfig = {
    company_name: { title: '사업자명', type: 'text' },
    biz_reg_no: { title: '사업자번호', type: 'text'},
    agency_name: { title: '노출 대행사명', type: 'text', note: '2.0솔루션 [설정>시스템설정>API대행사 사용여부]에 입력한 대행사명으로 노출됩니다.'},
    user_email: { title: '신청자 이메일', type: 'text', note: 'API 담당자의 계정으로 신청해 주세요. 신청자 이메일로 API 신청 승인결과와 이후 API 관련 공지를 발송해 드립니다.'},
    user_name: { title: '신청자명', type: 'text' },
    htel: { title: '휴대폰번호', type: 'text' },
    api_reason: { title: 'API사용 용도' },
    terms_agree_yn: { label: '개인정보 수집 및 이용에 대해서 동의합니다.' },
    note_check_yn: { label: '유의사항 내용을 확인하였습니다.' },
    sol_email: { title: '연동할 솔루션 계정', type: 'text', note: '신청 계정의 솔루션만 연동 가능합니다.', warning: '연동 가능한 2.0 솔루션이 없습니다. 통합홈페이지 [나의정보]에서 서비스 현황을 확인해 보세요.' }
  };

  const formIsValid = 
    (userType === '대행사' && formValues.terms_agree_yn && formValues.note_check_yn && formValues.api_reason?.trim() && formValues.agency_name?.trim() && formValues.htel?.trim()) ||
    (userType === '일반' && formValues.terms_agree_yn && formValues.note_check_yn && formValues.sol_email && formValues.htel?.trim())
    
    useEffect(() => {
      if (userInfo) {
        dispatchFormValues({ type: 'REPLACE', value: userInfo });
      }
    }, [userInfo, navigate]);
    
  function disableInputGenerator(key) {
    return <SimpleInput
      value={formValues[key]}
      note={formConfig[key].note}
      onChange={null}
      disabled={true}
    />
  }

  async function handleSubmit() {
    /**
     * API 사용제한이 없어져서 해당 처리는 주석
     * 혹시 나중에 다시 해달라고 할수 있으니 로직은 남겨둠.
     */
    // if (!userInfo.first_pay_no) {
    //   return Swal.fire({
    //     icon: 'error',
    //     title: '솔루션 무료 사용자 입니다.',
    //     text: '무료 사용자는 API 신청이 불가합니다.'
    //   });
    // } else if (!['CLASSIC', 'SPECIAL', 'PLATINUM', 'VIP', 'VIP+'].includes(userInfo.sol_version)) {
    //   return Swal.fire({
    //     icon: 'error',
    //     title: 'OPEN API 서비스를 지원하는 버전이 아닙니다.',
    //     text: '사용중인 솔루션 버전을 확인해 보세요.\nClassic버전 이상부터 API 사용이 가능합니다.'
    //   });
    // }

    if (!userInfo.htel) {
      if (!/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3,4}[-\s.]?[0-9]{4,6}$/.test(formValues.htel)) {
        Swal.fire('오류', '휴대폰번호 형식이 올바르지 않습니다.');

        return false;
      }
    }

    const requestOption = {
      method: 'POST',
      body: JSON.stringify({
        api_reason: formValues.api_reason || '',
        userType,
        agency_name: formValues.agency_name,
        htel: formValues.htel || userInfo.htel,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      }
    };

    simpleSubmitHandler(
      setIsLoading,
      '/center/apply/submit',
      requestOption,
      userInfo => authCtx.setUserInfo(userInfo),
      () => {
        if (userType === '대행사') {
          navigate('/applyComplete')
        } else {
          // 일반인경우 바로 신청확인 페이지로 이동
          navigate('/applyStatus')
        }
      }
    );
  };

  return (
    <div className={classes.container}>
      <h2 className={classes.heading}>사용신청</h2>
      <h3>사용신청</h3>
      <div className={classes.apllyForm}>
        <div className={classes.usertype}>
          <h6>사용자 구분 선택</h6>
          <div className={classes.radio_group}>
            <div>사용자구분</div>
            {
              userInfo?.c_type !== '개인판매자' &&
                <div>
                  <input onChange={e => setUserType(e.target.value)} type="radio" id="radio-company" name="usertype" value="대행사" checked={userType === '대행사'} />
                  <label htmlFor="radio-company">
                    사업자(대행사)<br />
                    <span className={classes.description}>여러개의 솔루션 계정 연동이 필요할 경우</span>
                  </label>
                </div>
            }
            <div>
              <input onChange={e => setUserType(e.target.value)} type="radio" id="radio-individual" name="usertype" value="일반" checked={userType === '일반'} />
              <label htmlFor="radio-individual">
                일반<br />
                <span className={classes.description}>하나의 솔루션 계정 연동이 필요한 경우</span>
              </label>
            </div>
          </div>
        </div>
        <div className={classes.apply_info}>
          <h6>신청 정보 확인 및 작성</h6>
          <div className={classes.apply_form}>
            <ul>
              {
                userType === '대행사' ?
                  <>
                    <li className='ast'>
                      <div className={classes.title}>{formConfig.company_name.title}</div>
                      {disableInputGenerator('company_name')}
                    </li>
                    <li className='ast'>
                      <div className={classes.title}>사업자번호</div>
                      {disableInputGenerator('biz_reg_no')}
                    </li>
                    <li className='ast'>
                      <div className={classes.title}>노출 대행사명</div>
                      <SimpleInput
                        value={formValues.agency_name}
                        note={formConfig.agency_name.note}
                        onChange={e => dispatchFormValues({ type: 'SINGLE', key: 'agency_name', value: e.target.value })}
                        disabled={false}
                      />
                    </li>
                  </> :
                  <li className='ast'>
                    <div className={classes.title}>{formConfig.sol_email.title}</div>
                    <SimpleInput
                      value={formValues.sol_email}
                      note={formValues.sol_email ? formConfig.sol_email.note : '' }
                      warning={formValues.sol_email ? '' : formConfig.sol_email.warning}
                      onChange={null}
                      disabled={true}
                    />
                  </li>
              }
              <li className='ast'>
                <div className={classes.title}>{formConfig.user_name.title}</div>
                {disableInputGenerator('user_name')}
              </li>
              <li className='ast'>
                <div className={classes.title}>{formConfig.htel.title}</div>
                {
                  // 휴대폰번호가 없는경우 직접 입력 받을수 있도록 함.
                  userInfo?.htel.trim() ? 
                    disableInputGenerator('htel') : 
                    <SimpleInput
                      value={formValues.htel}
                      onChange={e => dispatchFormValues({ type: 'SINGLE', key: 'htel', value: e.target.value })}
                      disabled={false}
                    />
                }
              </li>
              <li className={classes.align_top + ' ast'}>
                <div className={classes.title}>{formConfig.api_reason.title}</div>
                <textarea value={formValues.api_reason || ''} onChange={e => dispatchFormValues({ type: 'SINGLE', key: 'api_reason', value: e.target.value })} rows='5' />
              </li>
              <li className={classes.align_top + ' ast'}>
                <div className={classes.title}>개인정보 수집 및<br />이용에 대한 안내</div>
                <div className={classes.terms}>
                  <p>
                    주식회사 플레이오토는 API 사용을 희망하는 기업 및 개인을 대상으로 아래와 같이 개인정보를 수집하고 있습니다.<br />
                    1. 수집 개인정보 항목: 회사명, 담당자명, 메일 주소, 전화번호, 홈페이지 주소(신청에 필요한 경우), 솔루션 계정 정보, 사업자유형, 사업자번호, 사업자명, 대표자명<br />
                    2. 개인정보의 수집 및 이용목적: API 사용신청에 따른 본인확인 및 원활한 의사소통 경로 확보, API 사용 적합성 확인<br />
                    3. 개인정보의 보유 및 이용기간: API 사용승인 거절 및 API 이용 계약해지 시점으로부터 1개월간 보관 후 파기합니다.<br />
                    4. 동의거부권리 안내 추가: 위와같은 개인정보 수집 동의를 거부할 수 있습니다. 다만 동의를 거부하는 경우 API 사용신청이 제한 될 수 있습니다.<br />
                    그 밖의 사항은 주식회사 플레이오토의 개인정보처리 방침을 준수합니다.
                  </p>
                  <CheckboxAndLabel
                    label={formConfig.terms_agree_yn.label}
                    onChange={e => dispatchFormValues({ type: 'SINGLE', key: 'terms_agree_yn', value: e.target.checked })}
                    checked={formValues.terms_agree_yn}
                  />
                </div>
              </li>
              <li className={classes.align_top + ' ast'}>
                <div className={classes.title}>유의사항</div>
                <div className={classes.terms}>
                  <p>
                    승인 기간은 2~5일정도 소요될 수 있으며, 추가 확인사항이 필요할 경우 입력한 담당자 정보로 연락드립니다.<br />
                    사용 승인 결과는 신청자 이메일로 발송되며, 신청 완료 후 [사용신청] 에서도 확인할 수 있습니다.<br />
                    일부 API는 사용 버전과 조건이 맞지않으면 사용에 제한이 있을수 있습니다.<br />
                    사용자 구분을 [일반]으로 선택하여 발급받은 API key는 연동 솔루션 사용기간이 만료일로부터 14일 경과 시 만료되어 재 신청이 필요합니다.<br />
                    <br />
                    아래와 같은 경우 API 사용 승인이 거절될 수 있습니다.<br />
                    <br />
                    신청한 정보와 실제 정보가 상이할 경우<br />
                    업종이 API 사용에 적합하지 않다고 판단되는 경우<br />
                    사용 용도 검토 후 적합하지 않다고 판단되는 경우<br />
                    <br />
                    검토 당시 당사의 사업적 방향이나 사용 조건이 맞지 않는 등의 여러 이유로 거절될 수 있습니다. 따라서, 이 점 감안하시어 당사에 공개 가능한 정보만 기재하여 주시기 바라며, 당사는 사용신청 거절에 대해 제안자에 대한 보상, 회신 등 어떠한 의무도 부담하지 않는다는 점 양해 부탁드립니다.
                  </p>
                  <CheckboxAndLabel
                    label={formConfig.note_check_yn.label}
                    onChange={e => dispatchFormValues({ type: 'SINGLE', key: 'note_check_yn', value: e.target.checked })}
                    checked={formValues.note_check_yn}
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      
      
      <div className={classes.submitBtn}>
        <button onClick={handleSubmit} disabled={!formIsValid}>사용신청</button>
        {isLoading && <p>잠시만 기다려주세요...</p>}
      </div>
    </div>
  );
};

export default ApplyPage;