import { useState } from 'react';
import { useParams } from 'react-router-dom';
import classes from '../NoticePage/Notice.module.css';
import moment from 'moment';
import useFetchData from '../../../hooks/useFetchData';
import { Link } from 'react-router-dom';

/**
 * 공지사항 상세 컴포넌트
 */
const NoticeDetailPage = () => {
  const [notiData, setNoticeDetail] = useState({
    now: {},
    prev: null,
    next: null
  });
  const { noti_no } = useParams()

  const option = { method: 'GET' };
  useFetchData(`/center/notice/detail/${noti_no}`, option, res_json => setNoticeDetail(res_json), noti_no);

  return (
    <>
      <div className={classes.container}>
        <h2 className={classes.heading}>공지사항</h2>
        <div className={classes.detailWrap}>
          <div className={classes['sub-heading']}>
            <p>{notiData.now.noti_no}</p>
            <p>{notiData.now.noti_type}</p>
            <p>{notiData.now.title}</p>
            <p>{moment(notiData.now.wdate).format('YYYY-MM-DD')}</p>
          </div>
          <div className={classes.noticeDesc} dangerouslySetInnerHTML={{ __html: notiData.now.content }} />
        </div>
        <div className={classes.btnList}>
          <Link to={`/notice`}>목록</Link>
        </div>

        <div className={classes.btnLDetaiList}>
          <div>
            <p>이전공지</p>
            <p>{notiData.prev?.noti_type || '-'}</p>
            <p>
              {notiData.prev ? 
                <Link to={`/notice/detail/${notiData.prev?.noti_no}`}>{notiData.prev.title}</Link> :
                <span>게시물이 없습니다. 목록 보기를 이용해 주세요.</span>}
            </p>
            <p>{(notiData.prev && moment(notiData.prev.wdate).format('YYYY-MM-DD')) || '-'}</p>
          </div>
          <div>
            <p>다음공지</p>
            <p>{notiData.next?.noti_type || '-'}</p>
            <p>
              {notiData.next ? 
                <Link to={`/notice/detail/${notiData.next?.noti_no}`}>{notiData.next.title}</Link> :
                <span>게시물이 없습니다. 목록 보기를 이용해 주세요.</span>}
            </p>
            <p>{(notiData.next && moment(notiData.next.wdate).format('YYYY-MM-DD')) || '-'}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoticeDetailPage;