import styled from 'styled-components';
import SimpleSideNav from '../../layout/SideNav/SimpleSideNav';
import { GuideContentList } from '../../../store/LeftContentList';

const InstructionPage = () => {
  return (
    <>
      <SimpleSideNav data={GuideContentList} />
      <Container>
        <h2>시작하기</h2>

        <section>
          <h3>사용 전 안내</h3>
          <div>
            <p>
              • API를 사용하려면 연동할 솔루션 인증키 또는 솔루션 접속정보와 API Key가 필요합니다. <br/>
              • 솔루션 인증키는 마스터 계정에서만 확인 가능합니다. ( 확인경로 : 환경설정 > API 사용 설정 )<br/>
              • 상품의 카테고리, 템플릿 등의 UI가 필요한 항목은 API로 지원되지 않기 때문에 API사용 전 솔루션에 먼저 접속하여 설정하는 작업이 필요합니다. <br/>
              • 기본적으로 모든 API는 PLAYAUTO 2.0 솔루션의 데이터를 조회, 수정, 등록하는 목적으로 사용됩니다. <br/>
              • 쇼핑몰에 운송장 정보를 전송하거나, 문의 답변을 전송하는 기능을 찾으신다면 작업 문서를 참고해 주세요.
            </p>
            <p>
              플레이오토 2.0 솔루션의 사용이 처음이라면 API 사용 전 솔루션 이용 가이드와 도움말을 참고해 주세요.
            </p>
            <div className='btnWrap'>
              <a href='https://cloud.plto.com/plto20/download/guide/PLAYAUTO2.0_guide.pdf' target="_blank" rel="noreferrer">플레이오토 2.0 솔루션 가이드 보기</a>
              <a href='https://www.plto.com/customer/Help' target="_blank" rel="noreferrer">플레이오토 2.0 솔루션 도움말 보기</a>
            </div>
          </div>
        </section>

        <section>
          <h3>API 사용준비</h3>
          <div>
            <p>
              API 호출을 하려면 <WarningText>API Key</WarningText>와 연동할 <WarningText>솔루션 인증키</WarningText>( 또는 솔루션 접속 정보 )를 이용하여 발급받은 Token이 필요합니다. <br/>
              아래 내용은 API Key 발급을 위한 내용이며, Token 발급 방법은 <PrimaryText>API 문서 &gt; 인증 토큰 발급</PrimaryText>에서 확인할수 있습니다.
            </p>
            <dl>
              <dt>
                개발자 준비
              </dt>
              <dd>
                1. API 문서확인 및 사용신청을 위해 회원가입 후 로그인 해주세요. <br />
                2. API Key 발급을 위해 상단 [사용신청] 메뉴에서 사용신청을 진행해 주세요.<br />
                3. 사용 신청 후 승인결과는 신청자 메일이나 [사용신청] 메뉴에서 확인 가능합니다.<br />
                4. 대행사로 사용신청 후 승인이 완료되면 솔루션에 대행사명이 노출되어 누구나 선택 가능합니다.<br />
                5. API Key가 발급 되었다면 사용 준비가 완료되었습니다.<br />
                사용신청 및 승인에 문제가 있을경우 1:1문의를 이용해주세요.
              </dd>
              <dt>
                연동할 솔루션 준비
              </dt>
              <dd>
                1. <PrimaryText>설정 &gt; 환경설정 &gt; 시스템 설정 &gt; API 사용 설정</PrimaryText>에서 <WarningText>솔루션 인증키</WarningText>를 확인할 수 있습니다. 솔루션 인증키가 안 보인다면 로그인 한 계정이 마스터 계정이 맞는지 확인해 주세요. <br/>
                2. 대행사를 통한 연동의 경우 플레이오토 2.0 솔루션에 접속하여 <PrimaryText>설정 &gt; 환경설정 &gt; 시스템 설정 &gt; API 사용 설정</PrimaryText>에서 연동할 대행사를 선택해 주세요. <br />
                3. 일반 연동의 경우는 솔루션에서 설정할 것이 없습니다. 대행사가 설정되어 있다면 해제해 주세요. <br />
                발급받는 키는 REQUEST HEADER의 x-api-key 필드에 넣어서 전송해야 합니다.
              </dd>
              <dt>
                API 호출 Header에 발급받은 API-KEY와 인증토큰 입력 필드
              </dt>
              <dd>
                <WarningText>
                  x-api-key : 솔루션에서 발급한 API-KEY<br/>
                  Authorization : 'Token ' + 솔루션 인증키로 발급받은 인증토큰 정보
                </WarningText>
              </dd>
            </dl>
          </div>
        </section>

        <section>
          <h3>주의사항</h3>
          <div>
            <p>
              • 모든 API 호출에는 x-api-key를 Header에 필수로 등록 후 호출해야 합니다.<br/>
              • 해당 키 정보와 매칭된 솔루션 정보가 일치하지 않는 경우 정상적으로 호출되지 않습니다. <br/>
              • 토큰발급 호출을 제외한 나머지 API 호출시 Header에 x-api-key와 토큰정보(Authorization)를 함께 전송해야 합니다.<br />
            </p>
          </div>
        </section>

        <section>
          <h3>기타 참고 사항</h3>
          <div>
            <dl>
              <dt>PROTOCOL</dt>
              <dd>보안을 위해 API 호출은 HTTPS 프로토콜만 사용이 가능하며 HTTP 프로토콜은 지원하지 않습니다.</dd>
              <dt>TLS</dt>
              <dd>TLS버전은 1.2 버전을 사용합니다.</dd>
              <dt>Parameter OR Response type</dt>
              <dd>파라미터 및 응답 데이터는 JSON 형식을 사용합니다.</dd>
            </dl>
          </div>
        </section>

      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 110px 100px;

  > h2 {
    font-size: 36px;
    font-weight: bold;
  }

  > section {
    padding: 50px 0;
    border-bottom: 1px solid #ddd;
    &:last-child {
      border-bottom: none;
    }

    > h3 {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px
    }

    > div {
      
      > p {
        color: #666;
        font-size: 16px;
        line-height: 1.8;
      }

      > img {
        display: block;
        margin: 25px 0;
      }

      > dl {
        > dt {
          font-weight: bold;
          font-size: 18px;
          margin: 20px 0 10px;
          &:before {
            content: "•";
            display: inline-block;
            width: 10px;
          }
        }
        > dd {
          color: #666;
          font-size: 16px;
          padding-left: 10px;
          line-height: 1.8;
        }
      }

      >.btnWrap {

        > a {
          width: 300px;
          height: 60px;
          color: #fff;
          background-color: var(--plto-theme);
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          &:not(:last-child) {
            margin-right: 20px;
          }
        }
      }
    }
  }
`;

const PrimaryText = styled.span`
  color: var(--plto-theme);
  text-decoration: underline;
`;

const WarningText = styled.span`
  color: red;
`

export default InstructionPage;