import { default_header, default_header_example } from './defaultData';

const data = (userInfo) => ({
  title: '주문',
  children: [
    {
      title: '주문 리스트 조회',
      url: '/doc/주문 리스트 조회',
      data: [
        {
          version: 'v1.0',
          url: '/orders',
          desc: '주문/배송관리 > 주문 및 배송 관리 리스트를 가져옵니다.',
          method: 'POST',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'start',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  검색 시작 값
                  <div className='description'>
                    기본값 0부터 시작됩니다. 
                    <ul className='style'>
                      <li><strong><code>index 기준 : bundle_yn</code></strong></li>
                      <ul className='style'>
                        <li>start: 0, length: 3000 으로 검색한 경우, 검색결과의 index 0-2999건까지 검색됩니다.</li>
                        <li>다음 주문건을 검색하기 위해서는 이전 입력한 start, length 프로퍼티의 값을 고려하여 start: 3000 을 입력하여 호출합니다.</li>
                        <li><strong>bundle_yn이 true이면 recordsTotal에 묶음번호별 그룹핑된 주문의 총개수가 출력됩니다.</strong></li>
                        <li><strong>bundle_yn이 true이면 results에 출력된 주문을 묶음번호별 그룹핑된 배열로 변환 후 해당 배열의 길이를 <code>start</code>에 넣어 다음 주문건을 검색해야 합니다.</strong></li>
                      </ul>
                    </ul>
                    <div style={{ padding: '12px 0' }}>
                      <strong>Default value: <code>0</code></strong><br />
                    </div>
                  </div>
                </div>
              )
            },
            {
              title: 'length',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  조회할 주문 갯수
                  <ul className='style'>
                    <li><strong><code>bundle_yn이 true</code>이면 recordsTotal에 묶음번호별 그룹핑된 주문의 총개수가 출력됩니다.<br/>총개수를 고려하여 length 설정해야 합니다.</strong></li>
                  </ul>
                  <div className='description'>
                    묶음주문 기준 최대 3000건 단위로 검색 가능합니다.<br />
                    <div style={{ padding: '12px 0' }}>
                      <strong>Max value: <code>3000</code></strong><br />
                      <strong>Default value: <code>500</code></strong>
                    </div>
                  </div>
                </div>
              ),
            },
            {
              title: 'orderby',
              required: '',
              type: 'String',
              desc: (
                <div>
                  정렬
                  <div className='description' style={{ padding: '12px 0' }}>
                    <ul className='style'>
                      <li>
                        <code>값을 넣지 않는 경우 등록일 기준 오름차순으로 검색됩니다.</code>
                      </li>
                      <li>
                        <code>아래의 정렬 가능 필드와 asc/desc를 조합하여 입력합니다. (orderby: wdate desc)</code>
                      </li>
                    </ul>
                    <br />
                    <br />
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>bundle_no</code>
                          </strong>
                        </td>
                        <td>
                          <strong>묶음번호</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>ord_status</code>
                          </strong>
                        </td>
                        <td>
                          <strong>주문상태</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>주문수집일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_name</code>
                          </strong>
                        </td>
                        <td>
                          <strong>쇼핑몰 계정</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'search_key',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색 키<br />
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>bundle_no</code>
                          </strong>
                        </td>
                        <td>
                          <strong>묶음번호</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_ord_no</code>
                          </strong>
                        </td>
                        <td>
                          <strong>주문번호</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_sale_no</code>
                          </strong>
                        </td>
                        <td>
                          <strong>쇼핑몰 상품코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>sku_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>c_sale_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>판매자관리코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_sale_name</code>
                          </strong>
                        </td>
                        <td>
                          <strong>온라인상품명</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>prod_name, attri</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU상품명</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_opt_name, shop_add_opt_name</code>
                          </strong>
                        </td>
                        <td>
                          <strong>옵션명, 추가구매옵션</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>order_name, order_id, to_name</code>
                          </strong>
                        </td>
                        <td>
                          <strong>주문자, 주문자아이디, 수령자</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>order_tel, order_htel</code>
                          </strong>
                        </td>
                        <td>
                          <strong>주문자전화번호, 주문자 휴대전화번호</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>to_tel, to_htel</code>
                          </strong>
                        </td>
                        <td>
                          <strong>수령자전화번호, 수령자휴대폰번호</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>memo</code>
                          </strong>
                        </td>
                        <td>
                          <strong>메모</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>gift_name</code>
                          </strong>
                        </td>
                        <td>
                          <strong>사은품</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <strong>
                    <ul className='style'>
                      <li>
                        <code>값을 넣지않는경우 전체검색됩니다.</code>
                      </li>
                    </ul>
                  </strong>
                </div>
              ),
            },
            {
              title: 'search_word',
              required: '',
              type: 'String',
              desc: '검색어',
            },
            {
              title: 'search_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색타입
                  <br />
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>exact</code>
                          </strong>
                        </td>
                        <td>
                          <strong>완전일치</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>partial</code>
                          </strong>
                        </td>
                        <td>
                          <strong>부분일치</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'date_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  날짜검색타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>주문수집일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>mdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>주문변경일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>ord_time</code>
                          </strong>
                        </td>
                        <td>
                          <strong>주문일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>pay_time</code>
                          </strong>
                        </td>
                        <td>
                          <strong>결제완료일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>ord_status_mdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>상태변경일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>ship_plan_date</code>
                          </strong>
                        </td>
                        <td>
                          <strong>발송예정일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>ord_confirm_time</code>
                          </strong>
                        </td>
                        <td>
                          <strong>주문확인일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>out_order_time</code>
                          </strong>
                        </td>
                        <td>
                          <strong>출고지시일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>out_time</code>
                          </strong>
                        </td>
                        <td>
                          <strong>출고완료일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>invoice_send_time</code>
                          </strong>
                        </td>
                        <td>
                          <strong>송장전송일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>api_read_time</code>
                          </strong>
                        </td>
                        <td>
                          <strong>API 호출로 주문 조회해간 시간</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sdate',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  검색시작일
                  <div className='description'>(YYYY-MM-DD)</div>
                </div>
              ),
            },
            {
              title: 'edate',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  검색종료일
                  <div className='description'>(YYYY-MM-DD)</div>
                </div>
              ),
            },
            {
              title: 'shop_id',
              required: '',
              type: 'String',
              desc: '쇼핑몰아이디',
            },
            {
              title: 'shop_cd',
              required: '',
              type: 'String',
              desc: (
                <div>
                  쇼핑몰코드
                  <div className='description'>
                    (옥션:A001, 지마켓: A006, 11번가: A112, 인터파크: A027...)
                  </div>
                </div>
              ),
            },
            {
              title: 'status',
              required: '',
              type: 'String[]',
              desc: (
                <div>
                  주문상태
                  <div className='description'>
                    전체조회시 ["ALL"]
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>결제완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>신규주문</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>출고대기</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>출고보류</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>운송장출력</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>출고완료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>배송중</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>배송완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>구매결정</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>취소요청</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>취소완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>반품요청</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>반품진행</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>반품회수완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>반품교환요청</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>반품완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>교환요청</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>교환진행</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>교환완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>교환회수완료</code>
                          </strong>
                        </td>
                        <td> 
                          <strong>
                            <code>맞교환요청</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>맞교환완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>주문재확인</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>주문보류</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매완료</code>
                          </strong>
                        </td>
                        <td colSpan='5'></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'bundle_yn',
              required: '',
              type: 'Boolean',
              desc: (
                <div>
                  묶음번호 조회 기준<br/>
                  <strong><code>설정값에 따라 start, length 기준이 변경됩니다.</code></strong>
                  <div className='description' style={{ padding: '12px 0' }}>
                    <strong>Default value : <code>false</code></strong>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>true</code>
                          </strong>
                        </td>
                        <td>
                          <ul className='style' style={{ margin: '0' }}>
                            <li><strong>묶음번호가 생성된 주문건만 조회</strong></li>
                            <li><strong>묶음번호별 그룹화된 주문이 출력</strong></li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                        <td>
                        <ul className='style' style={{ margin: '0' }}>
                            <li><strong>묶음번호가 생성여부 상관없이 모든 주문건 조회</strong></li>
                            <li><strong>단건주문 출력</strong></li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'delivery_vendor',
              required: '',
              type: 'String',
              desc: '배송처 코드',
            },
            {
              title: 'gift_prod_name',
              required: '',
              type: 'String',
              desc: '사은품코드',
            },
            {
              title: 'delay_status',
              required: '',
              type: 'Boolean',
              desc: '배송지연여부',
            },
            {
              title: 'unstore_status',
              required: '',
              type: 'Boolean',
              desc: '출고가능여부',
            },
            {
              title: 'delay_ship',
              required: '',
              type: 'Boolean',
              desc: '출고지연여부',
            },
            {
              title: 'memo_yn',
              required: '',
              type: 'Boolean',
              desc: '메모여부',
            },
            {
              title: 'map_yn',
              required: '',
              type: 'Boolean',
              desc: '재고매칭 여부',
            },
            {
              title: 'supp_vendor',
              required: '',
              type: 'String',
              desc: '매입처코드',
            },
            {
              title: 'multi_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  멀티검색 키
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>c_sale_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>판매자관리코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_sale_no</code>
                          </strong>
                        </td>
                        <td>
                          <strong>쇼핑몰상품코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>bundle_no</code>
                          </strong>
                        </td>
                        <td>
                          <strong>묶음번호</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>sku_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_ord_no</code>
                          </strong>
                        </td>
                        <td>
                          <strong>쇼핑몰주문번호</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>invoice_no</code>
                          </strong>
                        </td>
                        <td>
                          <strong>운송장번호</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop</code>
                          </strong>
                        </td>
                        <td>
                          <strong>쇼핑몰아이디</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'multi_search_word',
              required: '',
              type: 'String',
              desc: (
                <div>
                  멀티검색어 ( \n 으로 묶어서 전송 ) ex&gt; 하나\n둘\n셋\n넷
                  <div className='description'>
                    <br />
                    multi_type이 shop인 경우에는 다음과 같이 설정합니다.
                    <ul className='style'>
                      <li>사이트만 조회(shop_cd): A001\nA006\nA112</li>
                      <li>
                        사이트+계정으로
                        조회(shop_cd|shop_id):A001|playauto\nA006|playauto\nA112|playauto
                      </li>
                    </ul>
                  </div>
                </div>
              ),
            },
            {
              title: 'masking_yn',
              required: '',
              type: 'Boolean',
              desc: (
                <div>
                  개인정보 마스킹 처리 여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    <strong>Default value : <code>false</code></strong>
                  </div>
                </div>
              ),
            },
            {
              title: 'api_read_status',
              required: '',
              type: 'String',
              desc: (
                <div>
                  API 호출여부에 따른 조회
                  <div className='description' style={{ padding: '12px 0' }}>
                    ※ API 수집여부와 상관없이 전체 주문건의 조회를 원하시는경우
                    해당 파라미터를 제거후 호출하여 주십시오.
                    <br />
                    <br />
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>NONE</code>
                          </strong>
                        </td>
                        <td>API 미수집주문</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>CALLED</code>
                          </strong>
                        </td>
                        <td>API 수집 + 주문정보 미수정</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EDITED</code>
                          </strong>
                        </td>
                        <td>API 수집 + 주문정보 수정</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            ...([1051].includes(userInfo.aff_no) ? [{
              title: 'shopType',
              required: '',
              type: 'String',
              desc: (
                <div>
                  국내/해외 주문 조회
                  <div className='description' style={{ padding: '12px 0' }}>
                    <br />
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code></code>
                          </strong>
                        </td>
                        <td>전체 주문 조회</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>domestic</code>
                          </strong>
                        </td>
                        <td>국내 주문 조회</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>global</code>
                          </strong>
                        </td>
                        <td>해외 주문 조회</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'target',
              required: '',
              type: 'String',
              desc: (
                <div>
                  스마트스토어 타겟 키

                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>normal</code>
                          </strong>
                        </td>
                        <td>
                          <strong>일반 주문만</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>guarantee</code>
                          </strong>
                        </td>
                        <td>
                          <strong>도착 주문만</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>all</code>
                          </strong>
                        </td>
                        <td>
                          <strong>전체</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <strong>
                    <ul className='style'>
                      <li>
                        <code>값을 넣지않는경우 전체검색됩니다.</code>
                      </li>
                    </ul>
                  </strong>
                </div>
              ), 
            }] : [])
          ],
          body_example: {
            start: 0,
            length: 100,
            orderby: 'wdate desc',
            search_key: '',
            search_word: '',
            search_type: 'partial',
            date_type: 'wdate',
            sdate: '2020-03-10',
            edate: '2020-04-10',
            shop_id: '',
            shop_cd: '',
            status: ['신규주문'],
            delivery_vendor: '',
            gift_prod_name: '',
            delay_status: false,
            multi_type: 'shop_sale_no',
            multi_search_word: '',
            delay_ship: false,
            memo_yn: true,
          },
          response: [
            {
              title: 'result',
              required: 'O',
              type: 'Object',
              desc: '',
            },
            {
              title: 'sol_no',
              required: 'O',
              type: 'Number(8)',
              desc: '솔루션번호',
              indent: 1,
            },
            {
              title: 'uniq',
              required: 'O',
              type: 'String(20)',
              desc: '주문 고유번호',
              indent: 1,
            },
            {
              title: 'ori_uniq',
              required: 'O',
              type: 'String(20)',
              desc: (
                <div>
                  원본 주문 고유번호
                  <div className='description'>(사본주문인 경우)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'bundle_no',
              required: 'O',
              type: 'String',
              desc: '주문 묶음번호',
              indent: 1,
            },
            {
              title: 'ord_status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  주문상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>결제완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>신규주문</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>출고대기</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>출고보류</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>운송장출력</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>출고완료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>배송중</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>배송완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>구매결정</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>취소요청</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>취소완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>반품요청</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>반품진행</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>반품회수완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>반품교환요청</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>반품완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>교환요청</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>교환진행</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>교환완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>교환회수완료</code>
                          </strong>
                        </td>
                        <td> 
                          <strong>
                            <code>맞교환요청</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>맞교환완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>주문재확인</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>주문보류</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매완료</code>
                          </strong>
                        </td>
                        <td colSpan='5'></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'carr_no',
              required: 'O',
              type: 'Number(5)',
              desc: '택배사코드',
              indent: 1,
            },
            {
              title: 'carr_ser_no',
              required: 'O',
              type: 'Number(5)',
              desc: '택배사 서비스 코드',
              indent: 1,
            },
            ...([1051].includes(userInfo.aff_no) ? [{
              title: 'carr_name',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                 택배사 이름
                  <div className='description'>
                    Qoo10 쇼핑몰은 주문 수집 시 배송사 이름이 저장됩니다.
                  </div>
                </div>
              ),
              indent: 1,
            }] : [{
              title: 'carr_name',
              required: 'O',
              type: 'String(30)',
              desc: '택배사 이름',
              indent: 1,
            }]),
            {
              title: 'carr_ser_name',
              required: 'O',
              type: 'String(30)',
              desc: '택배사 서비스 이름',
              indent: 1,
            },
            {
              title: 'invoice_no',
              required: 'O',
              type: 'String(20)',
              desc: '운송장 번호',
              indent: 1,
            },
            {
              title: 'ship_plan_date',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  배송예정일
                  <div className='description'>(YYYY-MM-DD)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String(4)',
              desc: '쇼핑몰 코드',
              indent: 1,
            },
            {
              title: 'shop_name',
              required: 'O',
              type: 'String(50)',
              desc: '쇼핑몰 이름',
              indent: 1,
            },
            {
              title: 'shop_id',
              required: 'O',
              type: 'String(40)',
              desc: '쇼핑몰 계정',
              indent: 1,
            },
            {
              title: 'seller_nick',
              required: 'O',
              type: 'String(50)',
              desc: '판매자 별칭',
              indent: 1,
            },
            {
              title: 'shop_ord_no',
              required: 'O',
              type: 'String(50)',
              desc: '쇼핑몰 주문번호',
              indent: 1,
            },
            {
              title: 'shop_ord_no_real',
              required: '',
              type: 'String(50)',
              desc: '원주문번호 (가공되지않은 쇼핑몰 주문번호 입니다)',
              indent: 1,
            },
            ...([1051].includes(userInfo.aff_no) ? [{
              title: 'shop_ord_no_2',
              required: 'O',
              type: 'String(50)',
              desc: (
                <div>
                  쇼핑몰 주문번호2
                  <div className='description'>
                    Qoo10 쇼핑몰의 장바구니 번호가 저장됩니다.
                  </div>
                </div>
              ),
              indent: 1,
            }, {
              title: 'shop_prod_cd',
              required: 'O',
              type: 'String(100)',
              desc: '카페24 상품코드',
              indent: 1,
            }, {
              title: 'shop_item_cd',
              required: 'O',
              type: 'String(100)',
              desc: '카페24 자체품목코드',
              indent: 1,
            }, {
              title: 'mem_grade',
              required: 'O',
              type: 'String(30)',
              desc: '회원등급',
              indent: 1,
            }] : []),
            {
              title: 'shop_sale_no',
              required: 'O',
              type: 'String(40)',
              desc: '쇼핑몰 판매번호',
              indent: 1,
            },
            {
              title: 'shop_sale_name',
              required: 'O',
              type: 'String(255)',
              desc: '상품명',
              indent: 1,
            },
            {
              title: 'shop_sku_cd',
              required: 'O',
              type: 'String(40)',
              desc: '쇼핑몰에서 수집된 sku_cd',
              indent: 1,
            },
            {
              title: 'shop_opt_name',
              required: 'O',
              type: 'String(250)',
              desc: '옵션명',
              indent: 1,
            },
            ...([1051].includes(userInfo.aff_no) ? [{
              title: 'shop_opt_no',
              required: 'O',
              type: 'String(200)',
              desc: (
                <div>
                 옵션코드
                  <div className='description'>
                    Rakuten 쇼핑몰은 [merchantDefinedSkuId : 시스템 연동용 SKU 번호] 가 수집됩니다.
                  </div>
                </div>
              ),
              indent: 1,
            }] : []),
            {
              title: 'sale_cnt',
              required: 'O',
              type: 'Number(8)',
              desc: '주문수량',
              indent: 1,
            },
            {
              title: 'shop_add_opt_name',
              required: 'O',
              type: 'String(255)',
              desc: '추가구매옵션명',
              indent: 1,
            },
            {
              title: 'ship_method',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  배송방법
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>무료배송</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>선결제</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>착불</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>조건부배송</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>방문수령</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>퀵배송</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>일반우편</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>설치배송</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>기타</code>
                          </strong>
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            ...([1051].includes(userInfo.aff_no) ? [{
              title: 'arrival_guaranteed_status',
              required: '',
              type: 'String',
              desc: (
                <div>
                  스마트스토어 도착보장 여부
                  <div className='description'>
                    normal: 일반 주문
                    <br /> guarantee: 도착보장 주문
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>normal</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>guarantee</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            }] : []),
            {
              title: 'out_time',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  출고일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'wdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  주문수집일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ord_time',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  주문일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'pay_time',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  결제완료일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ord_confirm_time',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  주문확인일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'claim_time',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  취소, 반품, 교환등의 클레임 상태 변경일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ship_hope_time',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  배송희망일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'api_read_time',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  API 호출로 주문 조회해본 시간
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'api_read_status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  API 호출여부
                  <div className='description'>
                    NONE: api 미수집 주문
                    <br /> CALLED: api 수집 + 주문정보 미수정
                    <br />
                    EDITED: api 수집 + 주문정보 수정
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>NONE</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>CALLED</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EDITED</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'pay_amt',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  실결제금액
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'discount_amt',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  할인금액
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_discount',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  쇼핑몰부담할인액
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'seller_discount',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  판매자부담할인액
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'coupon_discount',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  쿠폰할인액
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'point_discount',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  포인트할인액
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sales',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  판매금액
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sales_tax',
              required: 'O',
              type: 'Number(11)',
              desc: (
                <div>
                  부가세
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_cost_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  쇼핑몰 원가
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_supply_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  쇼핑몰 공급가
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            ...([1051].includes(userInfo.aff_no) ? [{
              title: 'misc1',
              required: 'O',
              type: 'String(60)',
              desc: '쇼핑몰 추가 데이터',
              indent: 1,
            }, {
              title: 'misc2',
              required: 'O',
              type: 'String(60)',
              desc: '쇼핑몰 추가 데이터',
              indent: 1,
            }, {
              title: 'misc3',
              required: 'O',
              type: 'String(60)',
              desc: '쇼핑몰 추가 데이터',
              indent: 1,
            }, {
              title: 'misc4',
              required: 'O',
              type: 'String(60)',
              desc: '쇼핑몰 추가 데이터',
              indent: 1,
            }, {
              title: 'misc5',
              required: 'O',
              type: 'String(60)',
              desc: '쇼핑몰 추가 데이터',
              indent: 1,
            }, {
              title: 'misc6',
              required: 'O',
              type: 'String(60)',
              desc: '쇼핑몰 추가 데이터',
              indent: 1,
            }, {
              title: 'misc7',
              required: 'O',
              type: 'String(60)',
              desc: '쇼핑몰 추가 데이터',
              indent: 1,
            }, {
              title: 'misc8',
              required: 'O',
              type: 'String(60)',
              desc: '쇼핑몰 추가 데이터',
              indent: 1,
            }, {
              title: 'misc9',
              required: 'O',
              type: 'String(60)',
              desc: '쇼핑몰 추가 데이터',
              indent: 1,
            }, {
              title: 'misc10',
              required: 'O',
              type: 'String(60)',
              desc: '쇼핑몰 추가 데이터',
              indent: 1,
            }, {
              title: 'misc11',
              required: 'O',
              type: 'String(60)',
              desc: '쇼핑몰 추가 데이터',
              indent: 1,
            }, {
              title: 'misc12',
              required: 'O',
              type: 'String(60)',
              desc: '쇼핑몰 추가 데이터',
              indent: 1,
            }, {
              title: 'misc13',
              required: 'O',
              type: 'String(60)',
              desc: '쇼핑몰 추가 데이터',
              indent: 1,
            }, {
              title: 'misc14',
              required: 'O',
              type: 'String(60)',
              desc: '쇼핑몰 추가 데이터',
              indent: 1,
            }, {
              title: 'misc15',
              required: 'O',
              type: 'String(60)',
              desc: '쇼핑몰 추가 데이터',
              indent: 1,
            }, {
              title: 'misc16',
              required: 'O',
              type: 'String(60)',
              desc: '쇼핑몰 추가 데이터',
              indent: 1,
            }, {
              title: 'misc17',
              required: 'O',
              type: 'String(60)',
              desc: '쇼핑몰 추가 데이터',
              indent: 1,
            }, {
              title: 'misc18',
              required: 'O',
              type: 'String(60)',
              desc: '쇼핑몰 추가 데이터',
              indent: 1,
            }, {
              title: 'misc19',
              required: 'O',
              type: 'String(60)',
              desc: '쇼핑몰 추가 데이터',
              indent: 1,
            }, {
              title: 'misc20',
              required: 'O',
              type: 'String(60)',
              desc: '쇼핑몰 추가 데이터',
              indent: 1,
            }] : []),
            {
              title: 'sihp_delay_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '배송지연여부',
              indent: 1,
            },
            {
              title: 'order_name',
              required: 'O',
              type: 'String(30)',
              desc: '주문자 명',
              indent: 1,
            },
            {
              title: 'order_id',
              required: 'O',
              type: 'String(30)',
              desc: '주문자 아아디',
              indent: 1,
            },
            {
              title: 'order_tel',
              required: 'O',
              type: 'String(20)',
              desc: '주문자 전화번호',
              indent: 1,
            },
            {
              title: 'order_htel',
              required: 'O',
              type: 'String(20)',
              desc: '주문자 휴대폰번호',
              indent: 1,
            },
            {
              title: 'order_email',
              required: 'O',
              type: 'String(50)',
              desc: '주문자 이메일',
              indent: 1,
            },
            {
              title: 'ship_msg',
              required: 'O',
              type: 'String(65535)',
              desc: '배송메세지',
              indent: 1,
            },
            {
              title: 'out_order_time',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  출고지시일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'to_ctry_cd',
              required: 'O',
              type: 'String(2)',
              desc: '수령자 국가',
              indent: 1,
            },
            {
              title: 'to_name',
              required: 'O',
              type: 'String(30)',
              desc: '수령자 명',
              indent: 1,
            },
            {
              title: 'to_tel',
              required: 'O',
              type: 'String(30)',
              desc: '수령자 전화번호',
              indent: 1,
            },
            {
              title: 'to_htel',
              required: 'O',
              type: 'String(30)',
              desc: '수령자 휴대폰번호',
              indent: 1,
            },
            {
              title: 'to_addr1',
              required: 'O',
              type: 'String(255)',
              desc: '수령자 주소 1',
              indent: 1,
            },
            {
              title: 'to_addr2',
              required: 'O',
              type: 'String(255)',
              desc: '수령자 주소 2',
              indent: 1,
            },
            {
              title: 'to_zipcd',
              required: 'O',
              type: 'String(10)',
              desc: '수령자 우편번호',
              indent: 1,
            },
            {
              title: 'ship_cost',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  배송비
                  <div className='description'>(data type: Decimal(8,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'c_sale_cd',
              required: 'O',
              type: 'String(40)',
              desc: '판매자관리코드',
              indent: 1,
            },
            {
              title: 'ord_curr_cd',
              required: 'O',
              type: 'String(4)',
              desc: '통화',
              indent: 1,
            },
            {
              title: 'gprivate_no',
              required: 'O',
              type: 'String(50)',
              desc: '개인통관번호',
              indent: 1,
            },
            {
              title: 'barcode',
              required: 'O',
              type: 'String(40)',
              desc: '바코드',
              indent: 1,
            },
            {
              title: 'depot_no',
              required: 'O',
              type: 'Number(8)',
              desc: '배송처 코드',
              indent: 1,
            },
            {
              title: 'depot_name',
              required: 'O',
              type: 'String(45)',
              desc: '배송처 이름',
              indent: 1,
            },
            {
              title: 'invoice_send_time',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  운송장 전송일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'gift_name',
              required: 'O',
              type: 'String(50)',
              desc: '쇼핑몰에서 수집된 사은품정보',
              indent: 1,
            },
            {
              title: 'map_yn',
              required: 'O',
              type: 'Number(1)',
              desc: 'SKU상품 매칭여부',
              indent: 1,
            },
            {
              title: 'ship_avail_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '출고가능 여부',
              indent: 1,
            },
            {
              title: 'ship_unable_reason',
              required: 'O',
              type: 'String(100)',
              desc: '출고불가 이유',
              indent: 1,
            },
            ...([1051].includes(userInfo.aff_no) ? [{
              title: 'ord_status_msg',
              required: 'O',
              type: 'String(255)',
              desc: (
                <div>
                  클레임 사유
                  <div className='description'>
                    ( SHOPEE 쇼핑몰은 발급된 송장의 PDF 파일이 출력됩니다. )
                  </div>
                </div>
              ),
              indent: 1,
            }] : [{
              title: 'ord_status_msg',
              required: 'O',
              type: 'String(255)',
              desc: '클레임 사유',
              indent: 1,
            }]),
            {
              title: 'exchange_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '교환여부',
              indent: 1,
            },
            {
              title: 'memo_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '메모 유무',
              indent: 1,
            },
            {
              title: 'bundle_avail_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '합포장여부',
              indent: 1,
            },
            {
              title: 'multi_bundle_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '묶음주문여부',
              indent: 1,
            },
            {
              title: 'set_no',
              required: 'O',
              type: 'Number(10)',
              desc: '세트 번호',
              indent: 1,
            },
            {
              title: 'pack_unit',
              required: 'O',
              type: 'Number(6)',
              desc: '출고수량',
              indent: 1,
            },
            {
              title: 'out_cnt',
              required: 'O',
              type: 'Number(6)',
              desc: '총 출고수량',
              indent: 1,
            },
            {
              title: 'set_name',
              required: 'O',
              type: 'String(200)',
              desc: '세트상품명',
              indent: 1,
            },
            {
              title: 'pay_method',
              required: 'O',
              type: 'String(20)',
              desc: (
                <div>
                  결제방법
                  <div className='description'>
                    ( 0:현금, 1:카드, 2:이머니, 3:알수없음 )
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'notice_msg',
              required: 'O',
              type: 'String(60)',
              desc: (
                <div>
                  기타메세지
                  <div className='description'>
                    (특정쇼핑몰만 처리되는 특이사항이 있는경우 사용됩니다.)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'order_msg',
              required: 'O',
              type: 'String(60)',
              desc: (
                <div>
                  추가메세지
                  <div className='description'>
                    (특이사항이 있는경우 사용됩니다.)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_ship_no',
              required: 'O',
              type: 'String(30)',
              desc: '쇼핑몰 배송 번호',
              indent: 1,
            },
            {
              title: 'return_wdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  반송 접수 요청일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'return_carr_no',
              required: 'O',
              type: 'Number(5)',
              desc: '반송 택배사코드',
              indent: 1,
            },
            {
              title: 'return_carr_id',
              required: 'O',
              type: 'String(40)',
              desc: '반송 택배사 아이디',
              indent: 1,
            },
            {
              title: 'return_invoice_no',
              required: 'O',
              type: 'String(20)',
              desc: '반송 송장 번호',
              indent: 1,
            },
            {
              title: 'gift_rule',
              required: 'O',
              type: 'Object',
              desc: '적용된 사은품 규칙 (묶음 주문은 적용된 규칙 사은품이 각 주문 별로 모두 출력됩니다.)',
              indent: 1,
            },
            {
              title: 'name',
              required: 'O',
              type: 'String(50)',
              desc: '사은품명',
              indent: 2,
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String(40)',
              desc: 'sku 코드',
              indent: 2,
            },
            {
              title: 'count',
              required: 'O',
              type: 'Number(8)',
              desc: '사은품 출고갯수',
              indent: 2,
            },
            {
              title: 'gift_name',
              required: 'O',
              type: 'String(50)',
              desc: '제공 사은품명',
              indent: 2,
            },
            {
              title: 'gift_rule_name',
              required: 'O',
              type: 'String(50)',
              desc: '사은품 규칙명',
              indent: 2,
            },
            {
              title: 'ori_bundle_no',
              required: 'O',
              type: 'String(20)',
              desc: '변경전 주문 묶음번호',
              indent: 1,
            },
            {
              title: 'pa_shop_cd',
              required: 'O',
              type: 'String(4)',
              desc: '상위 쇼핑몰 코드',
              indent: 1,
            },
            {
              title: 'results_prod',
              required: 'O',
              type: 'Object',
              desc: '매칭된 SKU상품 정보',
            },
            {
              title: 'sol_no',
              required: 'O',
              type: 'Number(8)',
              desc: '솔루션번호',
              indent: 1,
            },
            {
              title: 'uniq',
              required: 'O',
              type: 'String(20)',
              desc: '주문 고유번호',
              indent: 1,
            },
            {
              title: 'bundle_no',
              required: 'O',
              type: 'Number(20)',
              desc: '주문묶음번호',
              indent: 1,
            },
            {
              title: 'ord_opt_seq',
              required: 'O',
              type: 'Number(5)',
              desc: '옵션순번',
              indent: 1,
            },
            {
              title: 'set_no',
              required: 'O',
              type: 'Number(10)',
              desc: '매칭된 세트상품번호',
              indent: 1,
            },
            {
              title: 'set_name',
              required: 'O',
              type: 'String(200)',
              desc: '매칭된 세트상품명',
              indent: 1,
            },
            {
              title: 'add_opt_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '추가구매옵션여부',
              indent: 1,
            },
            {
              title: 'ord_opt_name',
              required: 'O',
              type: 'Number(120)',
              desc: '옵션명',
              indent: 1,
            },
            {
              title: 'prod_no',
              required: 'O',
              type: 'Number(10)',
              desc: '매칭된 SKU상품 고유번호',
              indent: 1,
            },
            {
              title: 'prod_name',
              required: 'O',
              type: 'String(200)',
              desc: '매칭된 SKU상품명',
              indent: 1,
            },
            {
              title: 'attri',
              required: 'O',
              type: 'String(50)',
              desc: '매칭된 SKU상품 속성',
              indent: 1,
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String(40)',
              desc: '매칭된 SKU상품 sku코드',
              indent: 1,
            },
            {
              title: 'stock_cd',
              required: 'O',
              type: 'String(100)',
              desc: '매칭된 SKU상품 재고관리코드',
              indent: 1,
            },
            {
              title: 'bundle_avail_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '합포장가능여부',
              indent: 1,
            },
            {
              title: 'barcode',
              required: 'O',
              type: 'String(40)',
              desc: '매칭된 SKU상품 바코드',
              indent: 1,
            },
            {
              title: 'model',
              required: 'O',
              type: 'String(50)',
              desc: '매칭된 SKU상품 모델명',
              indent: 1,
            },
            {
              title: 'prod_img',
              required: 'O',
              type: 'String(255)',
              desc: '매칭된 SKU상품 이미지',
              indent: 1,
            },
            {
              title: 'opt_sale_cnt',
              required: 'O',
              type: 'Number(8)',
              desc: '옵션수량',
              indent: 1,
            },
            {
              title: 'pack_unit',
              required: 'O',
              type: 'Number(6)',
              desc: '출고수량',
              indent: 1,
            },
            {
              title: 'supp_no',
              required: 'O',
              type: 'Number(8)',
              desc: '매입처 번호',
              indent: 1,
            },
            {
              title: 'supp_name',
              required: 'O',
              type: 'String(50)',
              desc: '매입처명',
              indent: 1,
            },
            {
              title: 'recordsTotal',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  검색된 전체 주문건수
                  <div>
                    <strong>
                      <code>묶음주문기준</code>
                    </strong>
                  </div>
                  <div className='description'>
                    recordsFiltered와 같은 값으로 줄 중 하나를 조회해 사용해주세요.
                  </div>
                </div>
              ),
            },
            {
              title: 'recordsFiltered',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  검색된 전체 주문건수
                  <div>
                    <strong>
                      <code>묶음주문기준</code>
                    </strong>
                  </div>
                  <div className='description'>
                    recordsTotal과 같은 값으로 줄 중 하나를 조회해 사용해주세요.
                  </div>
                </div>
              ),
            },
            {
              title: 'recordsTotalCount',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  검색된 전체 주문건수
                  <div>
                    <strong>
                      <code>단건주문기준</code>
                    </strong>
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    묶음주문 내의 단건주문의 주문건수로, 합포장된 주문의 개수에 따라<br />
                    묶음주문기준 주문건수(recordsTotal, recordsFiltered)보다 많을 수 있습니다.
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th colSpan='2'>단건주문 2, 3개가 각각 합포장 처리된 묶음주문 2건을 조회시</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>recordsTotal/recordsFiltered</code>
                          </strong>
                        </td>
                        <td>2</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>recordsTotalCount</code>
                          </strong>
                        </td>
                        <td>5</td>
                      </tr>
                    </tbody>
                  </table>
                  </div>
              ),
            },
          ],
          response_example: {
            result: {
              sol_no: '솔루션 번호',
              uniq: '주문 고유번호',
              ori_uniq: '원본 주문 고유번호 (사본주문인경우)',
              bundle_no: '주문 묶음번호',
              ord_status:
                '주문상태 (결제대기, 결제완료, 신규주문, 출고대기, 출고보류, 운송장출력, 출고완료, 발송완료, 배송중, 배송완료, 구매결정, 취소요청, 취소진행, 취소반려, 취소완료, 반품요청, 반품교환요청, 반품진행, 반품교환완료, 반품회수, 반품회수완료, 반품완료, 교환요청, 교환완료, 맞교환요청, 맞교환완료, 주문재확인, 취소, 삭제)',
              carr_no: '택배사 코드',
              carr_ser_no: '택배사 서비스 코드',
              carr_name: '택배사 이름',
              carr_ser_name: '택배사 서비스 이름',
              invoice_no: '운송장 번호',
              ship_plan_date: '배송예정일',
              shop_cd: '쇼핑몰 코드',
              shop_name: '쇼핑몰 이름',
              shop_id: '쇼핑몰 계정',
              seller_nick: '판매자 별칭',
              shop_ord_no: '쇼핑몰 주문번호',
              shop_ord_no_real: '원주문번호',
              shop_sale_no: '쇼핑몰 판매번호',
              shop_sale_name: '상품명',
              shop_sku_cd: '쇼핑몰에서 수집된 SKU코드',
              shop_opt_name: '옵션명',
              sale_cnt: '주문수량',
              shop_add_opt_name: '추가구매옵션명',
              ship_method: '배송방법',
              out_time: '출고일',
              wdate: '주문수집일',
              ord_time: '주문일',
              pay_time: '결제완료일',
              mdate: '툴에서 주문수정일',
              ord_confirm_time: '주문확인일',
              claim_time: '클레임상태 변경일',
              api_read_time: 'API 호출로 주문 조회해간 시간',
              api_read_status: 'API 호출여부',
              pay_amt: '실결제금액',
              discount_amt: '할인금액',
              shop_discount: '쇼핑몰부담할인액',
              seller_discount: '판매자부담할인액',
              coupon_discount: '쿠폰할인액',
              point_discount: '포인트할인액',
              sales: '판매금',
              sales_tax: '부가세',
              shop_cost_price: '쇼핑몰 원가',
              shop_supply_price: '쇼핑몰 공급가',
              ship_delay_yn: '배송지연여부',
              order_name: '주문자 명',
              order_id: '주문자 아아디',
              order_tel: '주문자 전화번호',
              order_htel: '주문자 휴대폰번호',
              order_email: '주문자 이메일',
              ship_msg: '배송메세지',
              out_order_time: '출고지시일',
              to_ctry_cd: '수령자 국가',
              to_name: '수령자 명',
              to_tel: '수령자 전화번호',
              to_htel: '수령자 휴대폰번호',
              to_addr1: '수령자 주소 1',
              to_addr2: '수령자 주소 2',
              to_zipcd: '수령자 우편번호',
              ship_cost: '배송비',
              c_sale_cd: '판매자관리코드',
              ord_curr_cd: '통화',
              curr_sign: '₩',
              gprivate_no: '개인통관번호',
              barcode: '바코드',
              sale_img: '',
              depot_no: '배송처 코드',
              depot_name: '배송처 이름',
              invoice_send_time: '운송장 전송일',
              gift_name: '사은품',
              gift_prod_name: '매칭된 사은품 명',
              map_yn: 'SKU상품 매칭여부',
              ship_avail_yn: '출고가능 여부',
              ship_unable_reason: '출고불가 이유',
              ord_status_msg: '클레임 사유',
              depot_no_cnt: '',
              set_out_available_cnt: 0,
              gift_prod_out_unable: '출고불가 사은품 카운트',
              exchange_yn: '교환여부',
              exchange_ord_yn: '',
              now_time: '현재시간',
              memo_yn: '메모 유무',
              bundle_avail_yn: '합포장여부',
              multi_bundle_yn: '묶음주문여부',
              out_chk_all_yn: '',
              set_no: '세트 번호',
              pack_unit: '출고수량',
              set_name: '세트상품명',
              pay_method: '결제방법',
              notice_msg: '기타메세지',
              order_msg: '추가메세지',
              shop_ship_no: '쇼핑몰 배송 번호',
              return_wdate: '반송 접수 요청일',
              return_carr_no: '반송 택배사 번호',
              return_carr_id: '반송 택배사 아이디',
              return_invoice_no: '반송 송장 번호',
              misc1: 'etc값',
              misc3: 'etc값',
              gift_rule: [
                {
                  name: '사은품규칙 이름',
                  sku_cd: '사은품규칙 sku 코드',
                  count: '사은품규칙 출고수량',
                  gift_name: '제공사은품명',
                },
              ],
            },
            results_prod: [
              {
                sol_no: '솔루션번호',
                uniq: '주문 고유번호',
                bundle_no: '주문묶음번호',
                ord_opt_seq: '옵션순번',
                set_no: '세트상품번호',
                set_name: '세트상품명',
                add_opt_yn: '추가구매옵션여부',
                ord_opt_name: '옵션명',
                prod_no: '매칭된 SKU상품 고유번호',
                prod_name: '매칭된 SKU상품명',
                attri: '매칭된 SKU상품 속성',
                sku_cd: '매칭된 SKU상품 sku코드',
                stock_cd: '매칭된 SKU상품 재고관리코드',
                bundle_avail_yn: '합포장가능여부',
                barcode: '매칭된 SKU상품 바코드',
                model: '매칭된 SKU상품 모델명',
                prod_img: '매칭된 SKU상품 이미지',
                opt_sale_cnt: '옵션수량',
                pack_unit: '출고수량',
                out_chk_yn: '(미사용)',
                supp_no: '매입처 번호',
                supp_name: '매입처명',
              },
            ],
            recordsTotal: '검색된 전체 주문건수',
            statusCount: {
              결제완료: '주문상태가 결제완료인 주문건수',
              결제완료_오늘수집: '주문상태가 결제완료면서 오늘 수집된 주문건수',
              구매결정: '주문상태가 구매결정인 주문건수',
              반품완료: '주문상태가 반품완료인 주문건수',
              반품요청: '주문상태가 반품요청인 주문건수',
              반품회수완료: '주문상태가 반품회수완료인 주문건수',
              배송관리_전체:
                '주문상태가 출고완료,배송중,배송완료,구매결정인 주문건수',
              배송중: '주문상태가 배송중인 주문건수',
              배송지연: '현재 배송지연된 주문건수',
              신규주문: '주문상태가 신규주문인 주문건수',
              신규주문_배송지연:
                '주문상태가 신규주문이면서 배송지연된 주문건수',
              신규주문_오늘수집:
                '주문상태가 신규주문이면서 오늘 수집된 주문건수',
              전체: '전체 주문건수',
              출고관리_전체:
                '주문상태가 출고대기,출고보류,운송장출력인 주문건수',
              출고대기: '주문상태가 출고대기인 주문건수',
              출고완료: '주문상태가 출고완료인 주문건수',
              출고지연: '주문상태가 출고지연인 주문건수',
              취소완료: '주문상태가 취소완료인 주문건수',
              클레임_전체:
                '주문상태가 취소요청,취소완료,반품요청,반품회수완료,반품완료,교환요청,교환완료,주문재확인인 주문건수',
            },
            availCount: {
              total_cnt: '검색된 전체 주문건수',
              출고완료_avail_cnt: '검색 결과중 출고완료 처리 가능한 주문건수',
              출고지시_avail_cnt: '검색 결과중 출고지시 처리 가능한 주문건수',
              운송장전송_avail_cnt:
                '검색 결과중 운송장전송 처리 가능한 주문건수',
              운송장출력_avail_cnt:
                '검색 결과중 운송장출력 처리 가능한 주문건수',
            },
          },
        },
      ],
    },
    {
      title: '주문 상세 조회',
      url: '/doc/주문 상세 조회',
      data: [
        {
          version: 'v1.0',
          url: '/order/:uniq',
          desc: '주문 상세정보를 가져옵니다.',
          method: 'GET',
          header: default_header,
          header_example: default_header_example,
          parameter: [
            {
              title: 'uniq',
              required: 'O',
              type: 'Number',
              desc: '주문 고유번호',
            },
          ],
          response: [
            {
              title: 'order_data',
              required: 'O',
              type: 'Object',
              description: '',
            },
            {
              title: 'sol_no',
              required: 'O',
              type: 'Number(8)',
              desc: '솔루션번호',
              indent: 1,
            },
            {
              title: 'uniq',
              required: 'O',
              type: 'String(20)',
              desc: '주문 고유번호',
              indent: 1,
            },
            {
              title: 'ori_uniq',
              required: 'O',
              type: 'String(20)',
              desc: '원본 주문 고유번호',
              indent: 1,
            },
            {
              title: 'ori_time',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  주문일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ord_opt_seq',
              required: 'O',
              type: 'Number(5)',
              desc: '주문순번',
              indent: 1,
            },
            {
              title: 'shop_name',
              required: 'O',
              type: 'String(50)',
              desc: '쇼핑몰 이름',
              indent: 1,
            },
            {
              title: 'shop_etc',
              required: 'O',
              type: 'String',
              desc: '',
              indent: 1,
            },
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String(4)',
              desc: '쇼핑몰코드',
              indent: 1,
            },
            {
              title: 'shop_id',
              required: 'O',
              type: 'String(40)',
              desc: '쇼핑몰 계정',
              indent: 1,
            },
            {
              title: 'shop_ord_no',
              required: 'O',
              type: 'String(50)',
              desc: '쇼핑몰 주문번호',
              indent: 1,
            },
            {
              title: 'shop_ord_no_real',
              required: '',
              type: 'String(50)',
              desc: '원주문번호 (가공되지않은 쇼핑몰 주문번호 입니다)',
              indent: 1,
            },
            {
              title: 'ord_status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  주문상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>결제완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>신규주문</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>출고대기</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>출고보류</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>운송장출력</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>출고완료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>배송중</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>배송완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>구매결정</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>취소요청</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>취소완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>반품요청</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>반품진행</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>반품회수완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>반품교환요청</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>반품완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>교환요청</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>교환진행</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>교환완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>교환회수완료</code>
                          </strong>
                        </td>
                        <td> 
                          <strong>
                            <code>맞교환요청</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>맞교환완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>주문재확인</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>주문보류</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매완료</code>
                          </strong>
                        </td>
                        <td colSpan='5'></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ord_confirm_time',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  주문확인일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'claim_time',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  취소, 반품, 교환등의 클레임 상태 변경일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ship_hope_time',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  배송희망일
                  <div className='description'>(YYYY-MM-DD)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ord_status_msg',
              required: 'O',
              type: 'String(255)',
              desc: '클레임메세지',
              indent: 1,
            },
            {
              title: 'order_name',
              required: 'O',
              type: 'String(30)',
              desc: '주문자 명',
              indent: 1,
            },
            {
              title: 'order_id',
              required: 'O',
              type: 'String(30)',
              desc: '주문자 아이디',
              indent: 1,
            },
            {
              title: 'order_tel',
              required: 'O',
              type: 'String(20)',
              desc: '주문자 전화번호',
              indent: 1,
            },
            {
              title: 'order_htel',
              required: 'O',
              type: 'String(20)',
              desc: '주문자 핸드폰번호',
              indent: 1,
            },
            {
              title: 'order_email',
              required: 'O',
              type: 'String(50)',
              desc: '주문자 이메일',
              indent: 1,
            },
            {
              title: 'out_order_time',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  출고지시일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ord_status_mdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  상태변경일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'out_time',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  출고일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'invoice_send_time',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  운송장전송일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'to_name',
              required: 'O',
              type: 'String(30)',
              desc: '수령자 명',
              indent: 1,
            },
            {
              title: 'to_name_eng',
              required: 'O',
              type: 'String(30)',
              desc: '수령자 영문명',
              indent: 1,
            },
            {
              title: 'to_tel',
              required: 'O',
              type: 'String(20)',
              desc: '수령자 전화번호',
              indent: 1,
            },
            {
              title: 'to_htel',
              required: 'O',
              type: 'String(20)',
              desc: '수령자 휴대폰번호',
              indent: 1,
            },
            {
              title: 'to_zipcd',
              required: 'O',
              type: 'String(10)',
              desc: '수령자 우편번호',
              indent: 1,
            },
            {
              title: 'to_addr1',
              required: 'O',
              type: 'String(255)',
              desc: '수령자 주소 1',
              indent: 1,
            },
            {
              title: 'to_addr2',
              required: 'O',
              type: 'String(255)',
              desc: '수령자 주소 2',
              indent: 1,
            },
            {
              title: 'to_ctry_cd',
              required: 'O',
              type: 'String(2)',
              desc: '수령자 국가',
              indent: 1,
            },
            {
              title: 'to_ctry_name_eng',
              required: 'O',
              type: 'String(50)',
              desc: '수령자 국가(영문)',
              indent: 1,
            },
            {
              title: 'ship_cost',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  배송비
                  <div className='description'>(data type: Decimal(8,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'carr_name',
              required: 'O',
              type: 'String(30)',
              desc: '택배사 이름',
              indent: 1,
            },
            {
              title: 'carr_no',
              required: 'O',
              type: 'String(5)',
              desc: '택배사 코드',
              indent: 1,
            },
            {
              title: 'invoice_no',
              required: 'O',
              type: 'String(20)',
              desc: '운송장 번호',
              indent: 1,
            },
            {
              title: 'bundle_no',
              required: 'O',
              type: 'String(20)',
              desc: '주문 묶음번호',
              indent: 1,
            },
            {
              title: 'ship_method',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  배송방법
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>무료배송</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>선결제</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>착불</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>조건부배송</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>방문수령</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>퀵배송</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>일반우편</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>설치배송</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>기타</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ship_msg',
              required: 'O',
              type: 'String(65535)',
              desc: '배송메세지',
              indent: 1,
            },
            {
              title: 'shop_sale_no',
              required: 'O',
              type: 'String(40)',
              desc: '쇼핑몰상품코드',
              indent: 1,
            },
            {
              title: 'shop_sale_name',
              required: 'O',
              type: 'String(255)',
              desc: '상품명',
              indent: 1,
            },
            {
              title: 'shop_opt_name',
              required: 'O',
              type: 'String(250)',
              desc: '옵션명',
              indent: 1,
            },
            {
              title: 'sale_cnt',
              required: 'O',
              type: 'Number(8)',
              desc: '주문수량',
              indent: 1,
            },
            {
              title: 'shop_add_opt_name',
              required: 'O',
              type: 'String(255)',
              desc: '추가구매옵션명',
              indent: 1,
            },
            {
              title: 'ord_curr_cd',
              required: 'O',
              type: 'String(4)',
              desc: '통화',
              indent: 1,
            },
            {
              title: 'c_sale_cd',
              required: 'O',
              type: 'String(40)',
              desc: '판매자 관리코드',
              indent: 1,
            },
            {
              title: 'pay_amt',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  실결제금액
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'discount_amt',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  할인금액
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sales',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  판매금액
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sales_tax',
              required: 'O',
              type: 'Number(11)',
              desc: (
                <div>
                  부가세
                  <div className='description'>(data type: Decimal(11,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ship_plan_date',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  배송예정일
                  <div className='description'>(YYYY-MM-DD)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ship_delay_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '배송지연여부',
              indent: 1,
            },
            {
              title: 'ship_delay_msg',
              required: 'O',
              type: 'String(255)',
              desc: '배송지연 메세지',
              indent: 1,
            },
            {
              title: 'wdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  주문수집일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'pay_time',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  결제완료일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'gift_name',
              required: 'O',
              type: 'String(50)',
              desc: '사은품',
              indent: 1,
            },
            {
              title: 'gprivate_no',
              required: 'O',
              type: 'String(50)',
              desc: '개인통관번호',
              indent: 1,
            },
            {
              title: 'map_yn',
              required: 'O',
              type: 'Number(1)',
              desc: 'SKU상품 매칭여부',
              indent: 1,
            },
            {
              title: 'set_no',
              required: 'O',
              type: 'Number(10)',
              desc: '세트 번호',
              indent: 1,
            },
            {
              title: 'set_pack_unit',
              required: 'O',
              type: 'Number(3)',
              desc: '주문수량 1건에 대한 매칭된 세트상품 포장단위',
              indent: 1,
            },
            {
              title: 'set_name',
              required: 'O',
              type: 'String(200)',
              desc: '세트상품명',
              indent: 1,
            },
            {
              title: 'depot_no',
              required: 'O',
              type: 'Number(8)',
              desc: '배송처 코드',
              indent: 1,
            },
            {
              title: 'out_avail_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '출고가능여부',
              indent: 1,
            },
            {
              title: 'pack_unit',
              required: 'O',
              type: 'Number(6)',
              desc: '주문수량 1건에 대한 매칭된 상품 포장단위',
              indent: 1,
            },
            {
              title: 'prod_name',
              required: 'O',
              type: 'String(200)',
              desc: '매칭된 SKU상품명',
              indent: 1,
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String(40)',
              desc: '매칭된 SKU상품 SKU코드',
              indent: 1,
            },
            {
              title: 'attri',
              required: 'O',
              type: 'String(50)',
              desc: '매칭된 SKU상품 속성',
              indent: 1,
            },
            {
              title: 'prod_img',
              required: 'O',
              type: 'String(255)',
              desc: '매칭된 SKU상품 이미지',
              indent: 1,
            },
            {
              title: 'sale_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  매칭된 SKU상품 판매가
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'depot_name',
              required: 'O',
              type: 'String(45)',
              desc: '배송처 이름',
              indent: 1,
            },
            {
              title: 'misc1',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc2',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc3',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc4',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc5',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc6',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc7',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc8',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc9',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc10',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc11',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc12',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc13',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc14',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc15',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc16',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc17',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc18',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc19',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc20',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'pay_method',
              required: 'O',
              type: 'String',
              desc: '결제방법',
              indent: 1,
            },
            {
              title: 'pay_method',
              required: 'O',
              type: 'String',
              desc: '결제방법',
              indent: 1,
            },
            {
              title: 'prod_list',
              required: 'O',
              type: 'Object',
              desc: '매칭된상품정보',
            },
            {
              title: 'sol_no',
              required: 'O',
              type: 'Number(8)',
              desc: '솔루션번호',
              indent: 1,
            },
            {
              title: 'uniq',
              required: 'O',
              type: 'String(20)',
              desc: '주문 고유번호',
              indent: 1,
            },
            {
              title: 'ord_opt_seq',
              required: 'O',
              type: 'Number(5)',
              desc: '주문순번',
              indent: 1,
            },
            {
              title: 'prod_no',
              required: 'O',
              type: 'Number(10)',
              desc: '매칭된 SKU상품번호',
              indent: 1,
            },
            {
              title: 'depot_no',
              required: 'O',
              type: 'Number(8)',
              desc: '배송처코드',
              indent: 1,
            },
            {
              title: 'opt_sale_cnt',
              required: 'O',
              type: 'Number(8)',
              desc: '옵션주문수량',
              indent: 1,
            },
            {
              title: 'pack_unit',
              required: 'O',
              type: 'Number(6)',
              desc: '옵션 주문수량 1건에 대한 매칭된 상품 포장단위',
              indent: 1,
            },
            {
              title: 'sale_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  매칭된 SKU상품 판매가
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'depot_name',
              required: 'O',
              type: 'String(45)',
              desc: '배송처 이름',
              indent: 1,
            },
            {
              title: 'out_avail_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '출고가능여부',
              indent: 1,
            },
            {
              title: 'shop_sale_no',
              required: 'O',
              type: 'String(40)',
              desc: '쇼핑몰 판매번호',
              indent: 1,
            },
            {
              title: 'shop_sale_name',
              required: 'O',
              type: 'String(255)',
              desc: '상품명',
              indent: 1,
            },
            {
              title: 'bundle_no',
              required: 'O',
              type: 'String(20)',
              desc: '주문 묶음번호',
              indent: 1,
            },
            {
              title: 'ord_opt_name',
              required: 'O',
              type: 'String(120)',
              desc: '옵션 상품명',
              indent: 1,
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String(40)',
              desc: '매칭된 상품 SKU코드',
              indent: 1,
            },
            {
              title: 'prod_img',
              required: 'O',
              type: 'String(255)',
              desc: '매칭된 상품 이미지',
              indent: 1,
            },
            {
              title: 'prod_name',
              required: 'O',
              type: 'String(200)',
              desc: '매칭된 상품명',
              indent: 1,
            },
            {
              title: 'attri',
              required: 'O',
              type: 'String(50)',
              desc: '매칭된 상품 속성',
              indent: 1,
            },
            {
              title: 'ord_opt_list',
              required: 'O',
              type: 'Object',
              desc: '주문옵션',
            },
            {
              title: 'prod_no',
              required: 'O',
              type: 'Number(10)',
              desc: '매칭된 상품 번호',
              indent: 1,
            },
            {
              title: 'pack_unit',
              required: 'O',
              type: 'Number(6)',
              desc: '주문수량 1건에 대한 매칭된 상품 포장단위',
              indent: 1,
            },
            {
              title: 'cost_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  매칭된 상품의 원가
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ord_opt_seq',
              required: 'O',
              type: 'Number(5)',
              desc: '주문순번',
              indent: 1,
            },
            {
              title: 'opt_sale_cnt',
              required: 'O',
              type: 'Number(8)',
              desc: '옵션 주문 수량',
              indent: 1,
            },
            {
              title: 'memo_list',
              required: 'O',
              type: 'Object',
              desc: '주문메모',
            },
            {
              title: 'wdate',
              required: 'O',
              type: 'Number(19)',
              desc: '메모 등록일',
              indent: 1,
            },
            {
              title: 'mdate',
              required: 'O',
              type: 'Number(19)',
              desc: '메모 수정일',
              indent: 1,
            },
            {
              title: 'memo_status',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  메모상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>등록</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>완료</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'name',
              required: 'O',
              type: 'Number(60)',
              desc: '메모 작성자명',
              indent: 1,
            },
            {
              title: 'content',
              required: 'O',
              type: 'Number(65535)',
              desc: '메모내용',
              indent: 1,
            },
            // {
            //   title: 'to_state',
            //   required: 'O',
            //   type: 'String(30)',
            //   desc: '수령자 주/도',
            //   indent: 1
            // },
            // {
            //   title: 'to_district',
            //   required: 'O',
            //   type: 'String(30)',
            //   desc: '원주문 수령자 구역',
            //   indent: 1
            // },
            // {
            //   title: 'to_town',
            //   required: 'O',
            //   type: 'String(30)',
            //   desc: '원주문 수령자 마을',
            //   indent: 1
            // },
            // {
            //   title: 'to_email',
            //   required: 'O',
            //   type: 'String(30)',
            //   desc: '원주문 수령자 이메일',
            //   indent: 1
            // },
            // {
            //   title: 'ori_to_name',
            //   required: 'O',
            //   type: 'String(30)',
            //   desc: '원주문 수령자명',
            //   indent: 1
            // },
            // {
            //   title: 'ori_to_addr1',
            //   required: 'O',
            //   type: 'String(30)',
            //   desc: '원주문 수령자 주소 1',
            //   indent: 1
            // },
            // {
            //   title: 'ori_to_addr2',
            //   required: 'O',
            //   type: 'String(30)',
            //   desc: '원주문 수령자 주소 2',
            //   indent: 1
            // },
            // {
            //   title: 'ori_to_town',
            //   required: 'O',
            //   type: 'String(30)',
            //   desc: '원주문 수령자 마을',
            //   indent: 1
            // },
            // {
            //   title: 'ori_to_district',
            //   required: 'O',
            //   type: 'String(30)',
            //   desc: '원주문 수령자 구역',
            //   indent: 1
            // },
            // {
            //   title: 'ori_to_state',
            //   required: 'O',
            //   type: 'String(30)',
            //   desc: '원주문 수령자 주/도',
            //   indent: 1
            // },
            // {
            //   title: 'ori_to_city',
            //   required: 'O',
            //   type: 'String(30)',
            //   desc: '원주문 수령자 도시',
            //   indent: 1
            // },
            // {
            //   title: 'ori_to_zipcd',
            //   required: 'O',
            //   type: 'String(30)',
            //   desc: '원주문 수령자 도시',
            //   indent: 1
            // },
            // {
            //   title: 'ori_to_ctry_cd',
            //   required: 'O',
            //   type: 'String(30)',
            //   desc: '원주문 수령자 국가코드',
            //   indent: 1
            // },
            // {
            //   title: 'ori_to_htel',
            //   required: 'O',
            //   type: 'String(30)',
            //   desc: '원주문 수령자 휴대폰번호',
            //   indent: 1
            // },
            // {
            //   title: 'ori_to_email',
            //   required: 'O',
            //   type: 'String(30)',
            //   desc: '원주문 수령자 이메일',
            //   indent: 1
            // },
            // {
            //   title: 'ori_ord_curr_cd',
            //   required: 'O',
            //   type: 'String(30)',
            //   desc: '원주문 통화코드',
            //   indent: 1
            // },
            // {
            //   title: 'ori_shop_sale_name',
            //   required: 'O',
            //   type: 'String(30)',
            //   desc: '원주문 상품명',
            //   indent: 1
            // },
            // {
            //   title: 'ori_shop_opt_name',
            //   required: 'O',
            //   type: 'String(30)',
            //   desc: '원주문 옵션명',
            //   indent: 1
            // },
            // {
            //   title: 'ori_sales_unit',
            //   required: 'O',
            //   type: 'String(30)',
            //   desc: '원주문 쇼핑몰 판매단가',
            //   indent: 1
            // },
            // {
            //   title: 'ori_sales',
            //   required: 'O',
            //   type: 'String(30)',
            //   desc: '원주문 판매금액',
            //   indent: 1
            // },
            // {
            //   title: 'ori_sale_cnt',
            //   required: 'O',
            //   type: 'String(30)',
            //   desc: '원주문 주문수량',
            //   indent: 1
            // },
            // {
            //   title: 'ori_shop_sku_cd',
            //   required: 'O',
            //   type: 'String(30)',
            //   desc: '원주문 쇼핑몰 SKU코드',
            //   indent: 1
            // },
            // {
            //   title: 'ori_shop_cost_price',
            //   required: 'O',
            //   type: 'String(30)',
            //   desc: '원주문 원가',
            //   indent: 1
            // },
            // {
            //   title: 'ori_misc16',
            //   required: 'O',
            //   type: 'String(30)',
            //   desc: '원주문 무게',
            //   indent: 1
            // }
          ],
          response_example: {
            order_data: {
              sol_no: '솔루션 번호',
              uniq: '주문 고유번호',
              ori_uniq: '원본 주문 고유번호',
              ord_time: '주문일',
              ord_opt_seq: '주문순번',
              shop_name: '쇼핑몰 이름',
              shop_etc: '',
              shop_cd: '쇼핑몰 코드',
              shop_id: '쇼핑몰 계정',
              shop_ord_no: '쇼핑몰 주문번호',
              shop_ord_no_real: '원주문번호',
              ord_status: '주문상태',
              ord_confirm_time: '주문확인일',
              claim_time: '취소,반품,교환등의 클레임 상태 변경일',
              ship_hope_time: '배송희망일',
              ord_status_msg: '클레임메세지',
              order_name: '주문자 명',
              order_id: '주문자 아이디',
              order_tel: '주문자 전화번호',
              order_htel: '주문자 핸드폰번호',
              order_email: '주문자 이메일',
              out_order_time: '출고지시일',
              ord_status_mdate: '상대변경일',
              out_time: '출고일',
              invoice_send_time: '운송장전송일',
              to_name: '수령자 명',
              to_name_eng: '수령자 아이디',
              to_tel: '수령자 전화번호',
              to_htel: '수령자 휴대폰번호',
              to_zipcd: '수령자 우편번호',
              to_addr1: '수령자 주소 1',
              to_addr2: '수령자 주소 2',
              to_ctry_cd: '수령자 국가',
              to_ctry_name_eng: '수령자 국가(영문)',
              to_state: '',
              to_city: '',
              ship_cost: '배송비',
              carr_name: '택배사 이름',
              carr_ser_name: '택배사 서비스 이름',
              carr_no: '택배사 코드',
              carr_ser_no: '택배사 서비스 코드',
              invoice_no: '운송장 번호',
              bundle_no: '주문 묶음번호',
              ship_method: '배송방법',
              ship_msg: '배송메세지',
              shop_sale_no: '쇼핑몰 판매번호',
              shop_sale_name: '상품명',
              shop_opt_name: '옵션명',
              sale_cnt: '주문수량',
              shop_add_opt_name: '추가구매옵션명',
              ord_curr_cd: '통화',
              curr_sign: '통화 표시',
              c_sale_cd: '판매자 관리코드',
              shop_sku_cd: '쇼핑몰에서 수집된 SKU코드',
              set_sale_price: '',
              pay_amt: '실결제금액',
              discount_amt: '할인금액',
              sales: '판매금액',
              sales_tax: '부가세',
              ship_plan_date: '배송예정일',
              ship_delay_yn: '배송지연여부',
              ship_delay_msg: '배송지연 메세지',
              custom_label: '커스텀 라벨',
              wdate: '주문수집일',
              pay_time: '결제완료일',
              mdate: '툴에서 주문수정일',
              sol_cate_name: '카테고리명',
              gift_name: '사은품',
              gprivate_no: '개인통관부호',
              map_yn: 'SKU상품 매칭여부',
              set_no: '세트 번호',
              set_pack_unit: '주문수량 1건에 대한 매칭된 상품 포장단위',
              set_name: '세트상품명',
              prod_no: '매칭된 상품 번호',
              depot_no: '배송처 코드',
              pal_no: '팔레트 코드',
              out_avail_yn: '출고가능여부',
              pack_unit: '주문수량 1건에 대한 매칭된 상품 포장단위',
              prod_name: '매칭된 상품명',
              sku_cd: '매칭된 상품 SKU코드',
              attri: '매칭된 상품 속성',
              gift_prod_name_pack: '',
              gift_prod_sku_pack: '',
              sale_img: '',
              prod_img: '매칭된 상품 이미지',
              sale_price: '매칭된 상품 판매가',
              depot_name: '배송처 이름',
              ebaydepot_yn: '이베이 배송처서비스 여부',
              misc1: 'etc값',
              misc2: 'etc값',
              misc3: 'etc값',
              misc4: 'etc값',
              misc5: 'etc값',
              misc6: 'etc값',
              misc7: 'etc값',
              misc8: 'etc값',
              misc9: 'etc값',
              misc10: 'etc값',
              misc11: 'etc값',
              misc12: 'etc값',
              misc13: 'etc값',
              misc14: 'etc값',
              misc15: 'etc값',
              misc16: 'etc값',
              misc17: 'etc값',
              misc18: 'etc값',
              misc19: 'etc값',
              misc20: 'etc값',
              notice_msg: '',
              pay_method: '결제방법',
              claim_id: '',
              claim_refund_sales: '',
              claim_refund_sales_curr_cd: '',
              bundle_weight: '박스 무게',
              bundle_width: '박스 사이즈(가로)',
              bundle_box_weight: '',
              bundle_height: '박스 사이즈(세로)',
              bundle_depth: '박스 사이즈(높이)',
              bundle_cate_jppost: 'jppost_내용품 종류',
              bundle_insur_price_curr_cd: '',
              bundle_insur_price: '',
              bundle_invoice_carr_send_date: '',
              ebaydepot_link_status: '이베이 물류서비스 연동상태',
              ebaydepot_uniq: '이베이연동 주문번호',
              bundle_cnt: '',
              bundle_sku_cnt: '',
            },
            prod_list: [
              {
                sol_no: '솔루션 번호',
                uniq: '주문 고유번호',
                ord_opt_seq: '주문순번',
                prod_no: '매칭된 상품 번호',
                depot_no: '배송처 코드',
                pal_no: '팔레트 코드',
                opt_sale_cnt: '옵션 주문 수량',
                pack_unit: '옵션 주문수량 1건에 대한 매칭된 상품 포장단위',
                sale_price: '매칭된 상품 판매가',
                depot_name: '배송처 이름',
                out_avail_yn: '출고가능여부',
                shop_sale_no: '쇼핑몰 판매번호',
                shop_sale_name: '상품명',
                bundle_no: '주문 묶음번호',
                ord_opt_type: '옵션 추가 타입',
                ord_opt_name: '옵션 상품명',
                sku_cd: '매칭된 상품 SKU코',
                prod_img: '매칭된 상품 이미지',
                prod_name: '매칭된 상품명',
                attri: '매칭된 상품 속성',
              },
            ],
            ord_opt_list: [
              {
                prod_no: '매칭된 상품 번호',
                depot_no: '배송처 코드',
                pack_unit: '주문수량 1건에 대한 매칭된 상품 포장단위',
                cost_price: '매칭된 상품의 원가',
                ord_opt_seq: '주문순번',
                opt_sale_cnt: '옵션 주문 수량',
              },
            ],
            memo_list: [
              {
                wdate: '2020-11-06 11:31:56',
                mdate: '2020-11-06 11:39:27',
                memo_status: '등록',
                name: '김바보',
                content: '판매자가 교환요청 함.',
              },
            ],
          },
        },
      ],
    },
    {
      title: '배송정보 업데이트',
      url: '/doc/배송정보 업데이트',
      data: [
        {
          version: 'v1.0',
          url: '/order/setInvoice',
          desc: (
            <ul className='style'>
              <li>
                입력한 묶음번호의 주문의 송장번호와 배송사를 업데이트 합니다.
              </li>
              <li>묶음번호 및 택배사번호, 송장번호는 모두 필수 값입니다.</li>
              { [1051, 1057].includes(userInfo.aff_no) ?
              <>
                <li>신규주문, 출고대기, 운송장출력, 주문재확인, 출고완료 상태 주문만 업데이트가 가능합니다.</li>
              </> : 
              <>
                <li>출고대기, 출고보류, 운송장출력, 주문재확인, 배송중 상태 주문만 업데이트가 가능합니다.</li>
                <li>출고보류, 배송중 상태 주문은 주문상태가 변경되지 않습니다.</li>
              </>
              }
              <li>
                overwrite 는 이미 값이 입력되어있는경우 덮어쓸지 여부로 기본값은
                false 입니다.
              </li>
              <li>
                change_complete 는 상태변경시 출고완료로 변경할건지의
                여부입니다. 기본값은 false 이며 변경되는 상태는 다음과 같습니다.
                : false - 운송장출력 : true - 출고완료
              </li>
              <li>결과 데이터는 전송한 묶음번호만큼 Array 로 리턴됩니다</li>
              { [1051, 1057].includes(userInfo.aff_no) ?
              <>
                <li>
                  배송정보 업데이트에 성공한 주문건은 자동으로 운송장 전송 작업이 등록됩니다.
                  <br />
                  <font color="red">배송정보 업데이트</font> 결과는 response data의 <font color="red">update_list</font>로,
                  <br />
                  <font color="red">운송장 전송 작업 등록</font> 결과는 <font color="red">work_result</font>로 출력됩니다.
                </li>
                <li>배송정보 업데이트에 성공한 주문이 없을 경우 운송장 전송 작업은 등록되지 않습니다.</li>
                <li>
                  배송정보 업데이트에 성공했으나 운송장 전송 작업 등록에 실패한 주문은<br />
                  overwrite를 true로 설정한 후 재호출하거나 선택 작업 등록 API를 이용하여 운송장을 전송하여 주십시오.
                </li>
              </> : ''
              }
            </ul>
          ),
          method: 'PUT',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'orders',
              required: 'O',
              type: 'Array',
              desc: '변경할 주문데이터',
            },
            {
              title: 'bundle_no',
              required: 'O',
              type: 'String',
              desc: '묶음번호',
              indent: 1,
            },
            {
              title: 'carr_no',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  택배사코드
                  <div className='description'>
                    (택배사코드조회 api를 호출하여 확인)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'invoice_no',
              required: 'O',
              type: 'String',
              desc: '송장번호',
              indent: 1,
            },
            {
              title: 'overwrite',
              required: '',
              type: 'boolean',
              desc: (
                <div>
                  이미 송장번호가 입력되어있는 주문일경우 신규정보로 덮어쓸지
                  여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    { [1051, 1057].includes(userInfo.aff_no) ?
                    <>
                      ※ 
                      배송정보 업데이트에 성공했으나 운송장 전송 작업에 실패한 경우 주문상태는 change_complete 설정값에 따라 [운송장출력, 출고완료] 상태로 변경됩니다.<br />
                      해당 주문의 운송장 전송 작업을 등록하려면 overwrite를 true로 설정해주십시오.<br />
                      <br />
                    </> : ''
                    }
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'change_complete',
              required: '',
              type: 'boolean',
              desc: (
                <div>
                  송장번호와 택배사가 정상 반영된경우 변경할 주문상태
                  <div className='description'>
                    (true:출고완료, false:운송장출력)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            ...([1051].includes(userInfo.aff_no) ? [{
              title: 'ship_plan_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  배송예정일
                  <div className='description'>
                    Rakuten 운송장 전송시 필수값이며 입력하지 않을 시 오늘 날짜가 기본값으로 설정됩니다.
                  </div>
                </div>
              ),
            },
            {
              title: 'api_callback_url',
              required: '',
              type: 'String',
              desc: (
                <div>
                  작업완료시 작업결과를 수신받을 URL
                  <div className='description'>
                    해당정보 입력시 작업결과를 입력하신 주소로 POST 방식으로 전송합니다.
                  </div>
                </div>
              ),
            }] : [])
          ],
          body_example: {
            orders: [
              {
                bundle_no: '3772225519903274',
                carr_no: '4',
                invoice_no: '377811119999',
              },
              {
                bundle_no: '3772207204045999',
                carr_no: '5',
                invoice_no: '377811118888',
              },
              {
                bundle_no: '37722255199523523',
                carr_no: '5',
                invoice_no: '377811110001',
              },
            ],
            overwrite: true,
            change_complete: true,
          },
          response: [
            {
              title: 'bundle_no',
              required: 'O',
              type: 'String',
              desc: '주문 묶음번호',
            },
            {
              title: 'result',
              required: 'O',
              type: 'Boolean',
              desc: '성공, 실패 여부',
            },
            {
              title: 'message',
              required: 'O',
              type: 'Boolean',
              desc: '실패시 실패사유',
            },
          ],
          response_example: (
            userInfo.aff_no === 1051 ?
              {
                result: [
                  {
                    bundle_no: '3772225519903274',
                    setInvoiceResult: '성공',
                    setInvoiceMessage: '',
                    work_no: 2766345451,
                    addWorkResult: '성공',
                    addWorkMessage: '작업이 정상적으로 등록되었습니다.'
                  },
                  {
                    bundle_no: '3772207204045999',
                    setInvoiceResult: '성공',
                    setInvoiceMessage: '',
                    work_no: 0,
                    addWorkResult: '실패',
                    addWorkMessage: '점검으로 인해 작업이 제한됩니다.'
                  },
                  {
                    bundle_no: '37722255199523523',
                    setInvoiceResult: '실패',
                    setInvoiceMessage: '[출고관리]메뉴에서 조회되지 않는 묶음고유번호입니다.',
                    work_no: '배송정보 업데이트 실패',
                    addWorkResult: '배송정보 업데이트 실패',
                    addWorkMessage: '배송정보 업데이트 실패'
                  }
                ]
              } :
              [
                {
                  bundle_no: '3772225519903274',
                    result: '성공',
                  message: '',
                },
                {
                  bundle_no: '3772207204045999',
                    result: '성공',
                  message: '',
                },
                {
                  bundle_no: '37722255199523523',
                  result: '실패',
                  error_code: 'e2001',
                  messages: [
                    '[출고관리]메뉴에서 조회되지 않는 묶음고유번호입니다.'
                  ],
                },
              ]
          ),
        },
        {
          authorized_m_no: [1, 1957, 5422, 77327, 129725, 121024, 122081, 122082],
          authorized_aff_no: [1051],
          version: 'v1.1',
          url: '/order/setInvoice/v1.1',
          desc: (
            <ul className='style'>
              <li>
                입력한 묶음/패키지번호의 주문의 송장번호와 배송사를 업데이트 합니다.
              </li>
              <li>묶음/패키지번호 및 택배사번호, 송장번호는 모두 필수 값입니다.</li>
              <li>
                overwrite 는 이미 값이 입력되어있는경우 덮어쓸지 여부로 기본값은
                false 입니다.
              </li>
              <li>
                change_complete 는 상태변경시 출고완료로 변경할건지의
                여부입니다. 기본값은 false 이며 변경되는 상태는 다음과 같습니다.
                : false - 운송장출력 : true - 출고완료
              </li>
              <li>
                isPackage는 입력한 bundle_no가 묶음번호인지 또는 패키지번호인지의 여부입니다.
              </li>
              <li>결과 데이터는 전송한 묶음번호만큼 Array 로 리턴됩니다</li>
            </ul>
          ),
          method: 'PUT',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'orders',
              required: 'O',
              type: 'Array',
              desc: '변경할 주문데이터',
            },
            {
              title: 'bundle_no',
              required: 'O',
              type: 'String',
              desc: '묶음/패키지번호',
              indent: 1,
            },
            {
              title: 'carr_no',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  택배사코드
                  <div className='description'>
                    (택배사코드조회 api를 호출하여 확인)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'invoice_no',
              required: 'O',
              type: 'String',
              desc: '송장번호',
              indent: 1,
            },
            {
              title: 'overwrite',
              required: '',
              type: 'boolean',
              desc: (
                <div>
                  이미 송장번호가 입력되어있는 주문일경우 신규정보로 덮어쓸지
                  여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'change_complete',
              required: '',
              type: 'boolean',
              desc: (
                <div>
                  송장번호와 택배사가 정상 반영된경우 변경할 주문상태
                  <div className='description'>
                    (true:출고완료, false:운송장출력)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'isPackage',
              required: '',
              type: 'Boolean',
              desc: (
                <div>
                  배송정보 업데이트 기준
                  <div className='description'>
                    (true:패키지번호 기준, false:묶음번호 기준)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )
            }
          ],
          body_example: {
            orders: [
              {
                bundle_no: '3772225519903274',
                carr_no: '4',
                invoice_no: '123123123123',
              },
              {
                bundle_no: '3772207204045999',
                carr_no: '5',
                invoice_no: '321321321321',
              },
            ],
            overwrite: true,
            change_complete: true,
          },
          response: [
            {
              title: 'bundle_no',
              required: 'O',
              type: 'String',
              desc: '주문 묶음/패키지번호',
            },
            {
              title: 'result',
              required: 'O',
              type: 'Boolean',
              desc: '성공, 실패 여부',
            },
            {
              title: 'message',
              required: 'O',
              type: 'Boolean',
              desc: '실패시 실패사유',
            },
          ],
          response_example: [
            {
              bundle_no: '3772225519903274',
              result: '성공',
              message: '',
            },
            {
              bundle_no: '3772207204045999',
              result: '실패',
              error_code: 'e2001',
              messages: [
                '[출고관리]메뉴에서 조회되지 않는 패키지번호입니다.'
              ],
            },
          ],
        },
      ],
    },
    {
      title: '보류',
      url: '/doc/보류',
      data: [
        {
          version: 'v1.0',
          url: '/order/hold',
          desc: (
            <ul className='style'>
              <li>입력한 묶음번호의 주문상태를 출고보류로 변경합니다.</li>
              <li>
                uniqList 는 전송하는 묶음번호에 해당하는 모든 값을 보내주어야
                합니다.
              </li>
            </ul>
          ),
          method: 'PUT',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'bundle_codes',
              required: 'O',
              type: 'String[]',
              desc: '주문 묶음번호 리스트',
            },
            {
              title: 'holdReason',
              required: 'O',
              type: 'String',
              desc: '보류 사유',
            },
            {
              title: 'status',
              required: '',
              type: 'String',
              desc: (
                <div>
                  적용할 주문상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>출고보류</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>출고보류</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>주문보류</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
          ],
          body_example: {
            bundle_codes: ['7370201794300489674', '7370203818826115936'],
            holdReason: '재고부족으로 출고보류 처리',
            status: '출고보류',
          },
          response: [
            {
              title: 'results',
              required: '',
              type: 'String',
              desc: '성공, 실패여부',
            },
          ],
          response_example: {
            results: '성공',
          },
        },
      ],
    },
    {
      title: '주문 삭제',
      url: '/doc/주문 삭제',
      data: [
        {
          version: 'v1.0',
          url: '/order/delete',
          desc: '주문정보를 삭제합니다.',
          method: 'DELETE',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'uniqList',
              required: 'O',
              type: 'String[]',
              desc: '주문 고유번호 리스트',
            },
          ],
          body_example: {
            uniqList: ['7432132435432', '4657432132121'],
          },
          response: [
            {
              title: 'results',
              required: 'O',
              type: 'String',
              desc: '성공, 실패여부',
            },
          ],
          response_example: {
            result: 'success',
          },
        },
      ],
    },
    {
      title: '주문 상세리스트 조회',
      url: '/doc/주문 상세리스트 조회',
      data: [
        {
          version: 'v1.0',
          url: '/order/detail-list',
          authorized_m_no: [91809, 91810, 91812, 223054, 88912, 88913, 88916, 64370],
          method: 'POST',
          desc: (
            <div>
              주문/배송관리 {'>'} 주문 및 배송 관리 상세 리스트를 가져옵니다.
              <br />
              🚨 상세정보를 리스팅하는 API로, 주문리스트 조회 API 보다 조회
              소요시간이 더 길어질 수 있습니다.🚨 <br />
              가급적
              <a href='/doc/주문 리스트 조회' style={{ color: 'blue' }}>
                주문리스트 조회
              </a>
              혹은
              <a href='/doc/주문 상세 조회' style={{ color: 'blue' }}>
                주문상세조회 API
              </a>
              를 이용해주시기 바랍니다.
            </div>
          ),
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'start',
              required: '',
              type: 'Number',
              desc: '검색 시작 값',
            },
            {
              title: 'length',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  조회할 주문 갯수
                  <br />
                  Max value:
                  <strong>
                    <code>3000</code>
                  </strong>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>500</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'orderby',
              required: '',
              type: 'String',
              desc: '정렬',
            },
            {
              title: 'search_key',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색 키
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>bundle_no</code>
                          </strong>
                        </td>
                        <td>
                          <strong>묶음번호</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_ord_no</code>
                          </strong>
                        </td>
                        <td>
                          <strong>주문번호</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_sale_no</code>
                          </strong>
                        </td>
                        <td>
                          <strong>쇼핑몰 상품코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>sku_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>c_sale_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>판매자관리코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_sale_name</code>
                          </strong>
                        </td>
                        <td>
                          <strong>온라인상품명</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>prod_name,attri</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU상품명</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_opt_name, shop_add_opt_name</code>
                          </strong>
                        </td>
                        <td>
                          <strong>옵션명, 추가구매옵션</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>order_name, order_id, to_name</code>
                          </strong>
                        </td>
                        <td>
                          <strong>주문자명, 주문자아이디, 수령자명</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>order_tel, order_htel</code>
                          </strong>
                        </td>
                        <td>
                          <strong>주문자전화번호, 주문자 휴대폰번호</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>to_tel, to_htel</code>
                          </strong>
                        </td>
                        <td>
                          <strong>수령자전화번호, 수령자 휴대폰번호</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>memo</code>
                          </strong>
                        </td>
                        <td>
                          <strong>메모</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>gift_name</code>
                          </strong>
                        </td>
                        <td>
                          <strong>사은품</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <strong>
                    <ul className='style'>
                      <li>
                        <code>값을 넣지않는경우 전체검색됩니다.</code>
                      </li>
                    </ul>
                  </strong>
                </div>
              ),
            },
            {
              title: 'search_word',
              required: '',
              type: 'String',
              desc: '검색어',
            },
            {
              title: 'search_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>exact</code>
                          </strong>
                        </td>
                        <td>
                          <strong>완전일치</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>partial</code>
                          </strong>
                        </td>
                        <td>
                          <strong>부분일치</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'date_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  날짜검색타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead style={{ backgroundColor: '#F5F6F9' }}>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>주문수집일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>mdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>주문변경일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>ord_time</code>
                          </strong>
                        </td>
                        <td>
                          <strong>주문일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>pay_time</code>
                          </strong>
                        </td>
                        <td>
                          <strong>결제완료일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>ord_status_mdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>상태변경일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>ship_plan_date</code>
                          </strong>
                        </td>
                        <td>
                          <strong>발송예정일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>ord_confirm_time</code>
                          </strong>
                        </td>
                        <td>
                          <strong>주문확인일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>out_order_time</code>
                          </strong>
                        </td>
                        <td>
                          <strong>출고지시일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>out_time</code>
                          </strong>
                        </td>
                        <td>
                          <strong>출고완료일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>invoice_send_time</code>
                          </strong>
                        </td>
                        <td>
                          <strong>송장전송일</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sdate',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  검색시작일
                  <div className='description'>
                    ( YYYY-MM-DD | YYYY-MM-DD HH:MM:SS )
                  </div>
                </div>
              ),
            },
            {
              title: 'edate',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  검색종료일
                  <div className='description'>
                    ( YYYY-MM-DD | YYYY-MM-DD HH:MM:SS )
                  </div>
                </div>
              ),
            },
            {
              title: 'shop_id',
              required: '',
              type: 'String',
              desc: '쇼핑몰아이디',
            },
            {
              title: 'shop_cd',
              required: '',
              type: 'String',
              desc: (
                <div>
                  쇼핑몰코드
                  <div className='description'>
                    ( 옥션: A001, 지마켓: A006, 11번가: A112, 인터파크: A027...
                    )
                  </div>
                </div>
              ),
            },
            {
              title: 'status',
              required: '',
              type: 'Array',
              desc: (
                <div>
                  주문상태
                  <div className='description'>
                    ["출고대기", "출고보류", "운송장출력"] 전체조회시 ["ALL"]
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>결제완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>신규주문</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>출고대기</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>출고보류</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>운송장출력</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>출고완료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>배송중</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>배송완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>구매결정</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>취소요청</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>취소완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>반품요청</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>반품진행</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>반품회수완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>반품교환요청</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>반품완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>교환요청</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>교환진행</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>교환완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>교환회수완료</code>
                          </strong>
                        </td>
                        <td> 
                          <strong>
                            <code>맞교환요청</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>맞교환완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>주문재확인</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>주문보류</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매완료</code>
                          </strong>
                        </td>
                        <td colSpan='5'></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'bundle_yn',
              required: '',
              type: 'Boolean',
              desc: (
                <div>
                  묶음번호 조회기준
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>bundle_yn = true : 묶음번호가 생성된 주문건만 조회</li>
                    <li>
                      bundle_yn = false : 묶음번호가 생성여부 상관없이 모든
                      주문건 조회
                    </li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead style={{ backgroundColor: '#F5F6F9' }}>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'delivery_vendor',
              required: '',
              type: 'String',
              desc: '배송처 코드',
            },
            {
              title: 'gift_prod_name',
              required: '',
              type: 'String',
              desc: '사은품코드',
            },
            {
              title: 'delay_status',
              required: '',
              type: 'Boolean',
              desc: '배송지연여부',
            },
            {
              title: 'unstore_status',
              required: '',
              type: 'Boolean',
              desc: '출고가능여부',
            },
            {
              title: 'delay_ship',
              required: '',
              type: 'Boolean',
              desc: '출고지연여부',
            },
            {
              title: 'memo_yn',
              required: '',
              type: 'Boolean',
              desc: '메모여부',
            },
            {
              title: 'map_yn',
              required: '',
              type: 'Boolean',
              desc: '재고매칭 여부',
            },
            {
              title: 'supp_vendor',
              required: '',
              type: 'String',
              desc: '매입처코드',
            },
            {
              title: 'multi_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  멀티검색 키
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead style={{ backgroundColor: '#F5F6F9' }}>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>c_sale_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>판매자관리코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_sale_no</code>
                          </strong>
                        </td>
                        <td>
                          <strong>쇼핑몰상품코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>bundle_no</code>
                          </strong>
                        </td>
                        <td>
                          <strong>묶음번호</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>sku_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_ord_no</code>
                          </strong>
                        </td>
                        <td>
                          <strong>쇼핑몰주문번호</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>invoice_no</code>
                          </strong>
                        </td>
                        <td>
                          <strong>운송장번호</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop</code>
                          </strong>
                        </td>
                        <td>
                          <strong>쇼핑몰아이디</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'multi_search_word',
              required: '',
              type: 'String',
              desc: (
                <div>
                  멀티검색어
                  <div className='description'>
                    ( \n 으로 묶어서 전송 ) ex{'>'} 하나\n둘\n셋\n넷
                  </div>
                </div>
              ),
            },
            {
              title: 'masking_yn',
              required: '',
              type: 'Boolean',
              desc: (
                <div>
                  개인정보 마스킹 처리 여부
                  <div className='description'>Default value</div>
                  <table>
                    <thead style={{ backgroundColor: '#F5F6F9' }}>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sub_sol_no_list',
              required: '',
              type: 'Number[]',
              desc: (
                <div>
                  하위계정 sol_no 리스트
                  <div className='description'>
                    (값이 없는 경우 하위계정 전체 조회)
                  </div>
                </div>
              ),
            },
          ],
          body_example: {
            start: 0,
            length: 100,
            orderby: 'wdate desc',
            search_key: '',
            search_word: '',
            search_type: 'partial',
            date_type: 'wdate',
            sdate: '2020-03-10',
            edate: '2020-04-10',
            shop_id: '',
            shop_cd: '',
            status: ['신규주문'],
            delay_status: false,
            multi_type: 'bundle_no',
            multi_search_word: '100000003302300\n12345567890000',
            delay_ship: false,
          },
          response: [
            {
              title: 'results',
              required: 'O',
              type: 'Object[]',
              desc: '주문정보',
            },
            {
              title: 'sol_no',
              required: 'O',
              type: 'Number(8)',
              desc: '솔루션번호',
              indent: 1,
            },
            {
              title: 'uniq',
              required: 'O',
              type: 'String(20)',
              desc: '주문 고유번호',
              indent: 1,
            },
            {
              title: 'ori_unique',
              required: 'O',
              type: 'String(20)',
              desc: '주문 묶음번호',
              indent: 1,
            },
            {
              title: 'ori_bundle_no',
              required: 'O',
              type: 'String(20)',
              desc: '변경전 주문 묶음번호',
              indent: 1,
            },
            {
              title: 'ord_status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  주문상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>결제완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>신규주문</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>출고대기</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>출고보류</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>운송장출력</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>출고완료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>배송중</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>배송완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>구매결정</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>취소요청</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>취소완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>반품요청</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>반품진행</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>반품회수완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>반품교환요청</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>반품완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>교환요청</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>교환진행</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>교환완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>교환회수완료</code>
                          </strong>
                        </td>
                        <td> 
                          <strong>
                            <code>맞교환요청</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>맞교환완료</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>주문재확인</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            <code>주문보류</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매완료</code>
                          </strong>
                        </td>
                        <td colSpan='5'></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'carr_no',
              required: 'O',
              type: 'Number(5)',
              desc: '택배사 코드',
              indent: 1,
            },
            {
              title: 'carr_name',
              required: 'O',
              type: 'String(30)',
              desc: '택배사 이름',
              indent: 1,
            },
            {
              title: 'invoice_no',
              required: 'O',
              type: 'String(20)',
              desc: '운송장 번호',
              indent: 1,
            },
            {
              title: 'ship_plan_date',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  배송예정일
                  <div className='description'>(YYYY-MM-DD)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String(4)',
              desc: '쇼핑몰코드',
              indent: 1,
            },
            {
              title: 'shop_name',
              required: 'O',
              type: 'String(50)',
              desc: '쇼핑몰 이름',
              indent: 1,
            },
            {
              title: 'shop_id',
              required: 'O',
              type: 'String(40)',
              desc: '쇼핑몰 계정',
              indent: 1,
            },
            {
              title: 'seller_nick',
              required: 'O',
              type: 'String(50)',
              desc: '쇼핑몰 별칭',
              indent: 1,
            },
            {
              title: 'shop_ord_no',
              required: 'O',
              type: 'String(50)',
              desc: '쇼핑몰 주문번호',
              indent: 1,
            },
            {
              title: 'shop_sale_no',
              required: 'O',
              type: 'String(40)',
              desc: '쇼핑몰 상품코드',
              indent: 1,
            },
            {
              title: 'shop_sale_name',
              required: 'O',
              type: 'String(255)',
              desc: '상품명',
              indent: 1,
            },
            {
              title: 'shop_sku_cd',
              required: 'O',
              type: 'String(40)',
              desc: '쇼핑몰에서 수집된 SKU코드',
              indent: 1,
            },
            {
              title: 'shop_opt_name',
              required: 'O',
              type: 'String(250)',
              desc: '옵션명',
              indent: 1,
            },
            {
              title: 'sale_cnt',
              required: 'O',
              type: 'Number(8)',
              desc: '주문수량',
              indent: 1,
            },
            {
              title: 'shop_add_opt_name',
              required: 'O',
              type: 'String(255)',
              desc: '추가구매옵션명',
              indent: 1,
            },
            {
              title: 'ship_method',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  배송방법
                  <div className='description'>Allowed values</div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>무료배송</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>선결제</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>착불</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>조건부배송</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>방문수령</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>퀵배송</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>일반우편</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>설치배송</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>기타</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'out_time',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  출고일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'wdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  주문수집일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ord_time',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  주문일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'pay_time',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  결제완료일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'mdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  주문수정일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ord_confirm_time',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  주문확인일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ord_status_mdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  상태변경일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'claim_time',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  취소, 상품, 교환등의 클레임 상태 변경일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ship_hope_time',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  배송희망일
                  <div className='description'>(YYYY-MM-DD)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_discount',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  쇼핑몰부담할인액
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'seller_discount',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  판매자부담할인액
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'coupon_discount',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  쿠폰할인액
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'point_discount',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  포인트할인액
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sales',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  판매금액
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sales_tax',
              required: 'O',
              type: 'Number(11)',
              desc: (
                <div>
                  부가세
                  <div className='description'>(data type: Decimal(11,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_cost_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  쇼핑몰 원가
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_supply_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  쇼핑몰 공급가
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ship_delay_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '배송지연여부',
              indent: 1,
            },
            {
              title: 'ord_name',
              required: 'O',
              type: 'String(30)',
              desc: '주문자명',
              indent: 1,
            },
            {
              title: 'ord_id',
              required: 'O',
              type: 'String(30)',
              desc: '주문자 아이디',
              indent: 1,
            },
            {
              title: 'order_tel',
              required: 'O',
              type: 'String(20)',
              desc: '주문자 전화번호',
              indent: 1,
            },
            {
              title: 'order_htel',
              required: 'O',
              type: 'String(20)',
              desc: '주문자 핸드폰번호',
              indent: 1,
            },
            {
              title: 'order_email',
              required: 'O',
              type: 'String(50)',
              desc: '주문자 이메일',
              indent: 1,
            },
            {
              title: 'ship_msg',
              required: 'O',
              type: 'String(65535)',
              desc: '배송메세지',
              indent: 1,
            },
            {
              title: 'ship_delay_msg',
              required: 'O',
              type: 'String(255)',
              desc: '배송지연 메세지',
              indent: 1,
            },
            {
              title: 'out_order_time',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  출고지시일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'to_ctry_cd',
              required: 'O',
              type: 'String(2)',
              desc: '수령자 국가',
              indent: 1,
            },
            {
              title: 'to_name',
              required: 'O',
              type: 'String(30)',
              desc: '수령자명',
              indent: 1,
            },
            {
              title: 'to_name_eng',
              required: 'O',
              type: 'String(50)',
              desc: '수령자 국가(영문)',
              indent: 1,
            },
            {
              title: 'to_tel',
              required: 'O',
              type: 'String(20)',
              desc: '수령자 전화번호',
              indent: 1,
            },
            {
              title: 'to_htel',
              required: 'O',
              type: 'String(20)',
              desc: '수령자 핸드폰번호',
              indent: 1,
            },
            {
              title: 'to_addr1',
              required: 'O',
              type: 'String(255)',
              desc: '수령자 주소1',
              indent: 1,
            },
            {
              title: 'to_addr2',
              required: 'O',
              type: 'String(255)',
              desc: '수령자 주소2',
              indent: 1,
            },
            {
              title: 'to_zipcd',
              required: 'O',
              type: 'String(10)',
              desc: '수령자 우편번호',
              indent: 1,
            },
            {
              title: 'ship_cost',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  배송비
                  <div className='description'>(data type: Decimal(8,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'c_sale_Cd',
              required: 'O',
              type: 'String(40)',
              desc: '판매자 관리코드',
              indent: 1,
            },
            {
              title: 'ord_curr_cd',
              required: 'O',
              type: 'String(4)',
              desc: '통화',
              indent: 1,
            },
            {
              title: 'curr_sign',
              required: 'O',
              type: 'String(3)',
              desc: '통화표시',
              indent: 1,
            },
            {
              title: 'gprivate_no',
              required: 'O',
              type: 'String(50)',
              desc: '개인통관번호',
              indent: 1,
            },
            {
              title: 'barcode',
              required: 'O',
              type: 'String(40)',
              desc: '바코드',
              indent: 1,
            },
            {
              title: 'model_no',
              required: 'O',
              type: 'String(50)',
              desc: '온라인상품 모델번호',
              indent: 1,
            },
            {
              title: 'depot_no',
              required: 'O',
              type: 'Number(8)',
              desc: '배송처코드',
              indent: 1,
            },
            {
              title: 'depot_name',
              required: 'O',
              type: 'String(45)',
              desc: '배송처이름',
              indent: 1,
            },
            {
              title: 'invoice_send_time',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  운송장전송일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'gift_name',
              required: 'O',
              type: 'String(50)',
              desc: '사은품',
              indent: 1,
            },
            {
              title: 'map_yn',
              required: 'O',
              type: 'Number(1)',
              desc: 'SKU상품 매칭여부',
              indent: 1,
            },
            {
              title: 'ship_avail_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '출고가능 여부',
              indent: 1,
            },
            {
              title: 'ship_unable_reason',
              required: 'O',
              type: 'String(100)',
              desc: '출고불가 이유',
              indent: 1,
            },
            {
              title: 'ord_status_msg',
              required: 'O',
              type: 'String(255)',
              desc: '클레임 사유',
              indent: 1,
            },
            {
              title: 'exchange_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '교환여부',
              indent: 1,
            },
            {
              title: 'memo_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '메모유무',
              indent: 1,
            },
            {
              title: 'memo_complete_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '메모완료 유무',
              indent: 1,
            },
            {
              title: 'bundle_avail_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '합포장여부',
              indent: 1,
            },
            {
              title: 'set_no',
              required: 'O',
              type: 'Number(10)',
              desc: '세트번호',
              indent: 1,
            },
            {
              title: 'pack_unit',
              required: 'O',
              type: 'Number(6)',
              desc: '출고수량',
              indent: 1,
            },
            {
              title: 'set_name',
              required: 'O',
              type: 'String(200)',
              desc: '세트상품명',
              indent: 1,
            },
            {
              title: 'pay_method',
              required: 'O',
              type: 'String(20)',
              desc: '결제방법',
              indent: 1,
            },
            {
              title: 'misc1',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc2',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc3',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc4',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc5',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc6',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc7',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc8',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc9',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc10',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc11',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc12',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc13',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc14',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc15',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc16',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc17',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc18',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc19',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'misc20',
              required: 'O',
              type: 'String(60)',
              desc: '기타정보',
              indent: 1,
            },
            {
              title: 'shop_ship_no',
              required: 'O',
              type: 'String(30)',
              desc: '쇼핑몰 배송번호',
              indent: 1,
            },
            {
              title: 'ship_type',
              required: 'O',
              type: 'String(10)',
              desc: '배송구분',
              indent: 1,
            },
            {
              title: 'oriShopOrdNo',
              required: 'O',
              type: 'String(50)',
              desc: '기타쇼핑몰주문번호',
              indent: 1,
            },
            {
              title: 'extraShopOrdNo',
              required: 'O',
              type: 'String(50)',
              desc: '기타쇼핑몰주문순번',
              indent: 1,
            },
            {
              title: 'results_prod',
              required: 'O',
              type: 'Object[]',
              desc: '매칭된 SKU상품 정보',
            },
            {
              title: 'sol_no',
              required: 'O',
              type: 'Number(8)',
              desc: '솔루션번호',
              indent: 1,
            },
            {
              title: 'uniq',
              required: 'O',
              type: 'String(20)',
              desc: '주문 고유번호',
              indent: 1,
            },
            {
              title: 'bundle_no',
              required: 'O',
              type: 'String(20)',
              desc: '주문묶음번호',
              indent: 1,
            },
            {
              title: 'ord_opt_seq',
              required: 'O',
              type: 'Number(5)',
              desc: '옵션순번',
              indent: 1,
            },
            {
              title: 'set_no',
              required: 'O',
              type: 'Number(10)',
              desc: '매칭된 세트상품번호',
              indent: 1,
            },
            {
              title: 'set_no',
              required: 'O',
              type: 'Number(10)',
              desc: '매칭된 세트상품번호',
              indent: 1,
            },
            {
              title: 'set_name',
              required: 'O',
              type: 'String(200)',
              desc: '매칭된 세트상품명',
              indent: 1,
            },
            {
              title: 'add_opt_name',
              required: 'O',
              type: 'String(120)',
              desc: '옵션명',
              indent: 1,
            },
            {
              title: 'prod_no',
              required: 'O',
              type: 'Number(10)',
              desc: '매칭된 SKU상품 고유번호',
              indent: 1,
            },
            {
              title: 'prod_name',
              required: 'O',
              type: 'String(200)',
              desc: '매칭된 SKU상품명',
              indent: 1,
            },
            {
              title: 'attri',
              required: 'O',
              type: 'String(50)',
              desc: '매칭된 SKU상품 속성',
              indent: 1,
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String(40)',
              desc: '매칭된 SKU상품 sku코드',
              indent: 1,
            },
            {
              title: 'stock_cd',
              required: 'O',
              type: 'String(100)',
              desc: '매칭된 SKU상품 재고관리코드',
              indent: 1,
            },
            {
              title: 'bundle_avail_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '합포장가능여부',
              indent: 1,
            },
            {
              title: 'barcode',
              required: 'O',
              type: 'String(40)',
              desc: '매칭된 SKU상품 바코드',
              indent: 1,
            },
            {
              title: 'model',
              required: 'O',
              type: 'String(50)',
              desc: '매칭된 SKU상품 모델명',
              indent: 1,
            },
            {
              title: 'model_no',
              required: 'O',
              type: 'String(50)',
              desc: '매칭된 SKU상품 모델 번호',
              indent: 1,
            },
            {
              title: 'prod_img',
              required: 'O',
              type: 'String(255)',
              desc: '매칭된 SKU상품 이미지',
              indent: 1,
            },
            {
              title: 'opt_sale_cnt',
              required: 'O',
              type: 'Number(8)',
              desc: '옵션수량',
              indent: 1,
            },
            {
              title: 'pack_unit',
              required: 'O',
              type: 'Number(6)',
              desc: '출고수량',
              indent: 1,
            },
            {
              title: 'supp_no',
              required: 'O',
              type: 'Number(8)',
              desc: '매입처 번호',
              indent: 1,
            },
            {
              title: 'supp_name',
              required: 'O',
              type: 'String(50)',
              desc: '매입처명',
              indent: 1,
            },
            {
              title: 'sale_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  매칭된 SKU상품 판매가
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cost_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  매칭된 SKU상품 원가
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'depot_no',
              required: 'O',
              type: 'Number(8)',
              desc: '배송처 번호',
              indent: 1,
            },
            {
              title: 'depot_name',
              required: 'O',
              type: 'String(45)',
              desc: '배송처명',
              indent: 1,
            },
            {
              title: 'memo_list',
              required: 'O',
              type: 'Object[]',
              desc: '주문메모',
            },
            {
              title: 'wdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  메모 등록일
                  <div className='description'> (YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'mdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  메모 수정일
                  <div className='description'> (YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'memo_status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  메모상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>등록</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>완료</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'name',
              required: 'O',
              type: 'String(60)',
              desc: '메모 작성자명',
              indent: 1,
            },
            {
              title: 'content',
              required: 'O',
              type: 'String(65535)',
              desc: '메모내용',
              indent: 1,
            },
            {
              title: 'uniq',
              required: 'O',
              type: 'String(20)',
              desc: '주문 고유번호',
              indent: 1,
            },
            {
              title: 'recordsTotal',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  검색된 전체 주문건수
                  <div>
                    <strong>
                      <code>묶음주문기준</code>
                    </strong>
                  </div>
                </div>
              ),
            },
            {
              title: 'recordsFiltered',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  검색된 전체 주문건수
                  <div>
                    <strong>
                      <code>묶음주문기준</code>
                    </strong>
                  </div>
                </div>
              ),
            },
            {
              title: 'recordsTotalCount',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  검색된 전체 주문건수
                  <div>
                    <strong>
                      <code>단건주문기준</code>
                    </strong>
                  </div>
                </div>
              ),
            },
          ],
          response_example: {
            results: [
              {
                sol_no: 1,
                uniq: '3846823391100000000',
                ori_uniq: '3846823391100000000',
                bundle_no: '3846835220000000',
                ori_bundle_no: null,
                ord_status: '신규주문',
                carr_no: null,
                carr_name: null,
                invoice_no: null,
                ship_plan_date: null,
                shop_cd: 'A001',
                pa_shop_cd: 'A001',
                shop_name: '옥션',
                shop_id: 'testID',
                seller_nick: '옥션 테스트계정 별칭',
                shop_ord_no: '999999999999999 9999',
                shop_sale_no: '',
                shop_sale_name: '온라인상품명 샘플',
                shop_sku_cd: '',
                shop_opt_name: '',
                sale_cnt: 1,
                shop_add_opt_name: '추가옵션명샘플1,추가옵션명샘플2',
                ship_method: '무료배송',
                out_time: null,
                wdate: '2021-11-09 16:09:16',
                ord_time: '2021-11-09 16:09:16',
                pay_time: '2021-11-09 16:09:16',
                mdate: '2021-11-09 16:36:04',
                ord_confirm_time: null,
                ord_status_mdate: null,
                claim_time: null,
                ship_hope_time: null,
                shop_discount: 0,
                seller_discount: 0,
                coupon_discount: 0,
                point_discount: 0,
                sales: 200000,
                sales_tax: 0,
                shop_cost_price: 6000,
                shop_supply_price: 0,
                ship_delay_yn: 0,
                order_name: '테스트',
                order_id: '',
                order_tel: '',
                order_htel: '01000000000',
                order_email: '',
                ship_msg: '',
                ship_delay_msg: null,
                out_order_time: null,
                to_ctry_cd: 'KR',
                to_name: '테스트',
                to_name_eng: '',
                to_ctry_name_eng: 'Republic of Korea',
                to_tel: '',
                to_htel: '01000000000',
                to_addr1:
                  '서울특별시 구로구 디지털로26길 123(구로동) 플레이오토',
                to_addr2: '',
                to_zipcd: '08390',
                ship_cost: 0,
                c_sale_cd: null,
                ord_curr_cd: 'KRW',
                curr_sign: '₩',
                gprivate_no: null,
                barcode: '',
                model_no: null,
                depot_no: 1,
                depot_name: '기본배송처명',
                invoice_send_time: null,
                gift_name: '',
                map_yn: 1,
                ship_avail_yn: 1,
                ship_unable_reason: '',
                ord_status_msg: null,
                exchange_yn: 0,
                memo_yn: 1,
                memo_complete_yn: 0,
                bundle_avail_yn: 1,
                set_no: null,
                set_name: null,
                pay_method: null,
                misc1: null,
                misc2: null,
                misc3: null,
                misc5: null,
                misc6: null,
                misc7: null,
                misc8: null,
                misc9: null,
                misc11: null,
                misc12: null,
                misc13: null,
                misc14: null,
                misc15: null,
                misc16: null,
                shop_ship_no: 1211122211,
                ship_type: '스마일배송',
                oriShopOrdNo: '999999999999999',
                extraShopOrdNo: '9999',
              },
            ],
            results_prod: [
              {
                sol_no: 1,
                uniq: '3846823391100000000',
                bundle_no: '3846835220000000',
                ord_opt_seq: 1,
                set_no: null,
                set_name: null,
                add_opt_yn: 0,
                ord_opt_name: '옵션명 샘플',
                prod_no: 9999999999,
                out_avail_yn: 1,
                prod_name: 'SKU상품명 샘플',
                attri: '파랑_L_새틴',
                sku_cd: 'S19390000000',
                stock_cd: null,
                bundle_avail_yn: 1,
                barcode: '',
                model: '',
                model_no: null,
                prod_img:
                  'https://s3-ap-northeast-2.amazonaws.com/20211105153553.jpg',
                opt_sale_cnt: 1,
                pack_unit: 1,
                supp_no: null,
                supp_name: null,
                sale_price: 15000,
                cost_price: 10000,
                depot_no: 1,
                depot_name: '기본배송처명',
              },
              {
                sol_no: 1,
                uniq: '3846823391100000000',
                bundle_no: '3846835220000000',
                ord_opt_seq: 2,
                set_no: null,
                set_name: null,
                add_opt_yn: 1,
                ord_opt_name: '추가옵션명샘플1',
                prod_no: 9999999998,
                out_avail_yn: 1,
                prod_name: 'SKU상품명 샘플2',
                attri: '노랑_L_새틴',
                sku_cd: 'S18220000000',
                stock_cd: null,
                bundle_avail_yn: 1,
                barcode: '',
                model: '',
                model_no: 'model_no_sample',
                prod_img:
                  'https://s3-ap-northeast-2.amazonaws.com/20211105153009.jpg',
                opt_sale_cnt: 2,
                pack_unit: 1,
                supp_no: null,
                supp_name: null,
                sale_price: 15000,
                cost_price: 10000,
                depot_no: 1,
                depot_name: '기본배송처명',
              },
              {
                sol_no: 1,
                uniq: '3846823391100000000',
                bundle_no: '3846835220000000',
                ord_opt_seq: 3,
                set_no: null,
                set_name: null,
                add_opt_yn: 1,
                ord_opt_name: '추가옵션명샘플2',
                prod_no: 9999999997,
                out_avail_yn: 1,
                prod_name: 'SKU상품명 샘플3',
                attri: '노랑_S_새틴',
                sku_cd: 'S275AAD000000',
                stock_cd: null,
                bundle_avail_yn: 1,
                barcode: '',
                model: '',
                model_no: null,
                prod_img:
                  'https://s3-ap-northeast-2.amazonaws.com/20211105152939.jpg',
                opt_sale_cnt: 3,
                pack_unit: 1,
                supp_no: null,
                supp_name: null,
                sale_price: 15000,
                cost_price: 10000,
                depot_no: 1,
                depot_name: '기본배송처명',
              },
            ],
            memo_list: [
              {
                wdate: '2021-11-11 15:41:11',
                mdate: '2021-11-11 15:41:11',
                memo_status: '등록',
                name: '김플토',
                content: '메모내용 샘플1',
                uniq: '3846823391100000000',
              },
              {
                wdate: '2021-11-11 15:41:27',
                mdate: '2021-11-11 15:41:41',
                memo_status: '완료',
                name: '김플토',
                content: '메모내용 샘플2',
                uniq: '3846823391100000000',
              },
            ],
            recordsTotal: 240,
            recordsFiltered: 240,
            recordsTotalCount: 260,
          },
        },
      ],
    },
    {
      title: '주문 등록',
      url: '/doc/주문 등록',
      data: [
        {
          version: 'v1.0',
          url: '/order/add',
          desc: (
            <ul className='style'>
              <li>신규주문을 등록합니다.</li>
              <li>opts 는 무조건 하나 이상 입력되어야 합니다.</li>
              <li>입력하시는 옵션의 갯수만큼 주문건이 생성됩니다.</li>
              <li>(ex&gt; 옵션이 4개인 경우 4개의 묶음 주문건 생성)</li>
              <li>
                매칭SKU상품(sku_cd) 또는 매칭 세트상품 (set_no, set_cd) 중에
                하나의 값만 넣어주셔야 합니다.
              </li>
              <li>매칭되는 SKU상품의 배송처는 모두 동일해야 합니다.</li>
            </ul>
          ),
          method: 'POST',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'ord_time',
              required: '',
              type: 'String',
              desc: (
                <div>
                  주문일
                  <div className='description'>
                    <ul className='style'>
                      <li>0000-00-00 00:00:00 의 형식으로 입력해주십시오.</li>
                    </ul>
                  </div>
                </div>
              ),
            },
            {
              title: 'pay_time',
              required: '',
              type: 'String',
              desc: (
                <div>
                  결제일
                  <div className='description'>
                    <ul className='style'>
                      <li>0000-00-00 00:00:00 의 형식으로 입력해주십시오.</li>
                    </ul>
                  </div>
                </div>
              ),
            },
            {
              title: 'custom_shop_cd',
              required: '',
              type: 'String',
              desc: (
                <div>
                  직접입력 쇼핑몰 shop_cd
                  <div className='description'>
                    (custom_shop_id 입력시 필수값)
                    <ul className='style'>
                      <li>코드는 쇼핑몰코드조회 API로 확인 가능</li>
                    </ul>
                  </div>
                </div>
              ),
            },
            {
              title: 'custom_shop_id',
              required: '',
              type: 'String',
              desc: (
                <div>
                  직접입력 쇼핑몰 shop_id
                  <div className='description'>
                    (custom_shop_cd 입력시 필수값)
                  </div>
                </div>
              ),
            },
            {
              title: 'order_name',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  주문자명
                  <div className='description'>
                    <ul className='style'>
                      <li>
                        한글, 영문, 숫자, 공백, - _ / ~ + , . ( ) {} [ ] % & '
                        만 입력가능합니다.
                      </li>
                    </ul>
                  </div>
                </div>
              ),
            },
            {
              title: 'order_id',
              required: '',
              type: 'String',
              desc: '주문자아이디',
            },
            {
              title: 'order_email',
              required: '',
              type: 'String',
              desc: '주문자이메일',
            },
            {
              title: 'order_tel',
              required: '',
              type: 'String',
              desc: (
                <div>
                  주문자 전화번호
                  <div className='description'>
                    <ul className='style'>
                      <li>숫자, 하이픈(-)만 입력가능합니다.</li>
                    </ul>
                  </div>
                </div>
              ),
            },
            {
              title: 'order_htel',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  주문자 핸드폰번호
                  <div className='description'>
                    <ul className='style'>
                      <li>숫자, 하이픈(-)만 입력가능합니다.</li>
                    </ul>
                  </div>
                </div>
              ),
            },
            {
              title: 'to_name',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  수령자명
                  <div className='description'>
                    <ul className='style'>
                      <li>
                        한글, 영문, 숫자, 공백, - _ / ~ + , . ( ) {} [ ] % & '
                        만 입력가능합니다.
                      </li>
                    </ul>
                  </div>
                </div>
              ),
            },
            {
              title: 'to_name_eng',
              required: '',
              type: 'String',
              desc: '수령자영문명',
            },
            {
              title: 'to_email',
              required: '',
              type: 'String',
              desc: '수령자이메일',
            },
            {
              title: 'to_tel',
              required: '',
              type: 'String',
              desc: (
                <div>
                  수령자 전화번호
                  <div className='description'>
                    <ul className='style'>
                      <li>숫자, 하이픈(-)만 입력가능합니다.</li>
                    </ul>
                  </div>
                </div>
              ),
            },
            {
              title: 'to_htel',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  수령자 핸드폰번호
                  <div className='description'>
                    <ul className='style'>
                      <li>숫자, 하이픈(-)만 입력가능합니다.</li>
                    </ul>
                  </div>
                </div>
              ),
            },
            {
              title: 'to_zipcd',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  수령자 우편번호
                  <div className='description'>
                    <ul className='style'>
                      <li>숫자, 하이픈(-)만 입력가능합니다.</li>
                    </ul>
                  </div>
                </div>
              ),
            },
            {
              title: 'to_addr1',
              required: 'O',
              type: 'String',
              desc: '수령자주소',
            },
            {
              title: 'to_addr2',
              required: '',
              type: 'String',
              desc: '수령자주소상세',
            },
            {
              title: 'c_sale_cd',
              required: '',
              type: 'String',
              desc: <div>판매자 관리코드</div>,
            },
            {
              title: 'shop_ord_no',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  주문번호
                  <div className='description'>
                    <ul className='style'>
                      <li> __AUTO__ 입력시 업로드 시간으로 자동생성됩니다.</li>
                    </ul>
                  </div>
                </div>
              ),
            },
            {
              title: 'shop_sale_name',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  주문상품명
                  <div className='description'>
                    <ul className='style'>
                      <li>
                        한글, 영문, 숫자, 공백, - _ / ~ + , . ( ) {} [ ] % & '
                        만 입력가능합니다.
                      </li>
                    </ul>
                  </div>
                </div>
              ),
            },
            {
              title: 'shop_sale_no',
              required: '',
              type: 'String',
              desc: (
                <div>
                  쇼핑몰상품코드
                  <div className='description'>
                    <ul className='style'>
                      <li>직접입력 쇼핑몰 주문일 경우에만 입력 가능합니다.</li>
                    </ul>
                  </div>
                </div>
              ),
            },
            {
              title: 'gift_name',
              required: '',
              type: 'String',
              desc: '사은품이름',
            },
            {
              title: 'ship_method',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  배송방법
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>무료배송</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>선결제</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>착불</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>방문수령</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>퀵배송</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>일반우편</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>설치배송</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>조건부배송</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>기타</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'ship_cost',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  배송비
                  <div className='description'>
                    <ul className='style'>
                      <li>숫자만 입력가능합니다.</li>
                    </ul>
                  </div>
                </div>
              ),
            },
            {
              title: 'ship_msg',
              required: '',
              type: 'String',
              desc: '배송메세지',
            },
            {
              title: 'depot_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  배송처번호
                  <div className='description'>
                    <ul className='style'>
                      <li>
                        배송처조회 API 를 통하여 확인된 배송처 번호를
                        입력해주십시오.
                      </li>
                      <li>
                        미입력시 매칭된 SKU상품의 배송처, 또는 기본배송처로
                        등록됩니다.
                      </li>
                    </ul>
                  </div>
                </div>
              ),
            },
            {
              title: 'gprivate_no',
              required: '',
              type: 'String',
              desc: '개인통관번호',
            },
            {
              title: 'opts',
              required: 'O',
              type: 'Object',
              desc: '옵션정보',
            },
            {
              title: 'opt_name',
              required: 'O',
              type: 'String',
              desc: '옵션명',
              indent: 1,
            },
            {
              title: 'sale_price',
              required: 'O',
              type: 'Number',
              desc: '판매가',
              indent: 1,
            },
            {
              title: 'shop_cost_price',
              required: '',
              type: 'Number',
              desc: '원가',
              indent: 1,
            },
            {
              title: 'shop_supply_price',
              required: '',
              type: 'Number',
              desc: '공급가',
              indent: 1,
            },
            {
              title: 'sale_cnt',
              required: 'O',
              type: 'Number',
              desc: '판매수량',
              indent: 1,
            },
            {
              title: 'sku_cd',
              required: '',
              type: 'String',
              desc: '매칭된 SKU상품의 SKU 코드',
              indent: 1,
            },
            {
              title: 'pack_unit',
              required: '',
              type: 'Number',
              desc: '매칭된 SKU상품의 출고수량',
              indent: 1,
            },
            {
              title: 'set_no',
              required: '',
              type: 'Number',
              desc: '매칭된 세트상품 번호',
              indent: 1,
            },
            {
              title: 'set_cd',
              required: '',
              type: 'String',
              desc: '매칭된 세트상품 코드',
              indent: 1,
            },
            {
              title: 'set_pack_unit',
              required: '',
              type: 'Number',
              desc: '매칭된 세트상품의 출고수량',
              indent: 1,
            },
            {
              title: 'add_opts',
              required: '',
              type: 'Object',
              desc: '추가구매옵션',
              indent: 1,
            },
            {
              title: 'opt_name',
              required: 'O',
              type: 'String',
              desc: '추가구매옵션명',
              indent: 2,
            },
            {
              title: 'sale_price',
              required: 'O',
              type: 'Number',
              desc: '추가금액',
              indent: 2,
            },
            {
              title: 'sale_cnt',
              required: 'O',
              type: 'Number',
              desc: '구매수량',
              indent: 2,
            },
            {
              title: 'sku_cd',
              required: '',
              type: 'String',
              desc: '매칭된 SKU상품 SKU 코드',
              indent: 2,
            },
            {
              title: 'pack_unit',
              required: '',
              type: 'Number',
              desc: '매칭된 SKU상품의 출고수량',
              indent: 2,
            },
            {
              title: 'set_no',
              required: '',
              type: 'Number',
              desc: '매칭된 세트상품 번호',
              indent: 2,
            },
            {
              title: 'set_cd',
              required: '',
              type: 'String',
              desc: '매칭된 세트상품 코드',
              indent: 2,
            },
            {
              title: 'set_pack_unit',
              required: '',
              type: 'Number',
              desc: '매칭된 세트상품의 출고수량',
              indent: 2,
            },
          ],
          body_example: {
            ord_time: '2020-06-18 12:00:00',
            pay_time: '2020-06-18 19:40:00',
            order_name: '김바보',
            order_id: 'babo',
            order_email: 'babo@sample.com',
            order_tel: '02-3333-4444',
            order_htel: '010-2222-3333',
            to_name: '김똘똘',
            to_name_eng: 'kim ddol ddol',
            to_email: 'ddol@sample.com',
            to_tel: '02-9999-8888',
            to_htel: '010-7777-6666',
            to_zipcd: '08390',
            to_addr1: '서울특별시 구로구 디지털로26길 123 (구로동) ',
            to_addr2: '14층 플레이오토',
            c_sale_cd: 'smapleUpload',
            shop_ord_no: '__AUTO__',
            shop_sale_name: 'api 수동입력 테스트 주문',
            gift_name: '사은품이름',
            ship_method: '선결제',
            ship_msg: '빠른배송 부탁드립니다.',
            ship_cost: 2500,
            depot_no: '',
            gprivate_no: '',
            opts: [
              {
                opt_name: '옵션1',
                sale_price: 10000,
                shop_cost_price: 0,
                shop_supply_price: 0,
                sale_cnt: 1,
                sku_cd: 'rony12345',
                pack_unit: 1,
                set_no: '',
                set_pack_unit: '',
                add_opts: [
                  {
                    opt_name: '추가구매옵션1',
                    sale_price: 5000,
                    sale_cnt: 2,
                    sku_cd: 'S322622F3F42',
                    pack_unit: 1,
                    set_no: '',
                    set_pack_unit: '',
                  },
                  {
                    opt_name: '추가구매옵션2',
                    sale_price: 3000,
                    sale_cnt: 1,
                    sku_cd: '',
                    pack_unit: '',
                    set_no: '10896',
                    set_pack_unit: '1',
                  },
                ],
              },
            ],
          },
          response: [
            {
              title: 'results',
              required: 'O',
              type: 'Object',
              desc: '결과상세',
            },
            {
              title: 'uniq',
              required: 'O',
              type: 'String(20)',
              desc: '주문 고유번호',
              indent: 1,
            },
            {
              title: 'bundle_no',
              required: 'O',
              type: 'String',
              desc: '주문 묶음번호',
              indent: 1,
            },
            {
              title: 'status',
              required: 'O',
              type: 'String',
              desc: '성공, 실패여부',
              indent: 1,
            },
            {
              title: 'message',
              required: 'O',
              type: 'String',
              desc: '실패시 에러메시지',
              indent: 1,
            },
          ],
          response_example: [
            {
              uniq: '3795927781274656794',
              bundle_no: '3796486704437327',
              status: '성공',
              message: '',
            },
          ],
        },
      ],
    },
    {
      title: '주문 분할',
      url: '/doc/주문 분할',
      data: [
        {
          version: 'v1.0',
          url: '/order/divide',
          desc: (
            <span>
              대상 주문을 묶음배송 주문에서 개별 배송 주문으로 분리합니다.
              <ul className='style'>
                <br />
                <li>주문에 적용된 사은품 규칙이 삭제됩니다.</li>
                <li>
                  로그 조회시 uniq, inq_uniq, prod_no, ol_shop_no 중의 하나의
                  호출정보는 반드시 입력해주셔야 합니다.
                </li>
              </ul>
            </span>
          ),
          method: 'PUT',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'uniqList',
              required: 'O',
              type: 'String[]',
              desc: '분할할 주문의 고유번호 리스트',
            },
            {
              title: 'type',
              required: '',
              type: 'String',
              desc: (
                <span>
                  분할 방식 선택 <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>divide: 모든 주문건을 각각의 묶음번호로 분할합니다.</li>
                    <li>
                      bundle: 전송된 주문건을 하나의 묶음번호로 분할합니다.
                    </li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>divide</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>divide</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>bundle</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
              ),
            },
          ],
          body_example: {
            uniqList: [
              '1596083929550784071',
              '1260041106462302156',
              '1597899464125631341',
            ],
          },
          response: [
            {
              title: 'results',
              required: 'O',
              type: 'Object[]',
              desc: '결과',
            },
            {
              title: 'uniq',
              required: 'O',
              type: 'String',
              desc: '주문 고유번호',
              indent: 1,
            },
            {
              title: 'old_bundle_no',
              required: 'O',
              type: 'String',
              desc: '이전묶음번호',
              indent: 1,
            },
            {
              title: 'new_bundle_no',
              required: 'O',
              type: 'String',
              desc: '새로 변경된 묶음번호',
              indent: 1,
            },
          ],
          response_example: [
            {
              result: [
                {
                  uniq: '1596083929550784071',
                  old_bundle_no: '3788908693668112',
                  new_bundle_no: '3788953930533930',
                },
                {
                  uniq: '1260041106462302156',
                  old_bundle_no: '3788919600564694',
                  new_bundle_no: '3788937262027900',
                },
                {
                  uniq: '1597899464125631341',
                  old_bundle_no: '3788901443233555',
                  new_bundle_no: '3788977807049989',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: '주문 수정',
      url: '/doc/주문 수정',
      data: [
        {
          version: 'v1.1',
          url: '/order/edit',
          desc: (
            <span>
              등록된 주문 정보를 수정합니다.
              <ul className='style'>
                <li>
                  수령자 정보, 택배사, 송장번호 변경시 동일 묶음 주문의 데이터가
                  일괄 수정됩니다.
                </li>
              </ul>
            </span>
          ),
          method: 'PUT',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'uniq',
              required: 'O',
              type: 'String',
              desc: '주문 고유번호',
            },
            {
              title: 'shop_sale_name',
              required: '',
              type: 'String',
              desc: (
                <div>
                  주문상품명
                  <div className='description'>
                    <ul className='style'>
                      <li>
                        한글, 영문, 숫자, 공백, - _ / ~ + , . ( ) {} [ ] % & '
                        만 입력가능합니다.
                      </li>
                    </ul>
                  </div>
                </div>
              ),
            },
            {
              title: 'shop_opt_name',
              required: '',
              type: 'String',
              desc: '옵션명',
            },
            {
              title: 'sales',
              required: '',
              type: 'Number',
              desc: '판매금액',
            },
            {
              title: 'gift_name',
              required: '',
              type: 'String',
              desc: '사은품이름',
            },
            {
              title: 'order_name',
              required: '',
              type: 'String',
              desc: (
                <div>
                  주문자명
                  <div className='description'>
                    <ul className='style'>
                      <li>
                        한글, 영문, 숫자, 공백, - _ / ~ + , . ( ) {} [ ] % & '
                        만 입력가능합니다.
                      </li>
                    </ul>
                  </div>
                </div>
              ),
            },
            {
              title: 'order_id',
              required: '',
              type: 'String',
              desc: '주문자아이디',
            },
            {
              title: 'order_tel',
              required: '',
              type: 'String',
              desc: (
                <div>
                  주문자 전화번호
                  <div className='description'>
                    <ul className='style'>
                      <li>숫자, 하이픈(-)만 입력가능합니다.</li>
                    </ul>
                  </div>
                </div>
              ),
            },
            {
              title: 'order_htel',
              required: '',
              type: 'String',
              desc: (
                <div>
                  주문자 핸드폰번호
                  <div className='description'>
                    <ul className='style'>
                      <li> 숫자, 하이픈(-)만 입력가능합니다.</li>
                    </ul>
                  </div>
                </div>
              ),
            },
            {
              title: 'to_name',
              required: '',
              type: 'String',
              desc: (
                <div>
                  수령자명
                  <div className='description'>
                    <ul className='style'>
                      <li>
                        한글, 영문, 숫자, 공백, - _ / ~ + , . ( ) {} [ ] % & '
                        만 입력가능합니다.
                      </li>
                    </ul>
                  </div>
                </div>
              ),
            },
            {
              title: 'to_name_eng',
              required: '',
              type: 'String',
              desc: '수령자영문명',
            },
            {
              title: 'to_tel',
              required: '',
              type: 'String',
              desc: (
                <div>
                  수령자 전화번호
                  <div className='description'>
                    <ul className='style'>
                      <li>숫자, 하이픈(-)만 입력가능합니다.</li>
                    </ul>
                  </div>
                </div>
              ),
            },
            {
              title: 'to_htel',
              required: '',
              type: 'String',
              desc: (
                <div>
                  수령자 핸드폰번호
                  <div className='description'>
                    <ul className='style'>
                      <li>숫자, 하이픈(-)만 입력가능합니다.</li>
                    </ul>
                  </div>
                </div>
              ),
            },
            {
              title: 'to_zipcd',
              required: '',
              type: 'String',
              desc: (
                <div>
                  수령자 우편번호
                  <div className='description'>
                    <ul className='style'>
                      <li>숫자, 하이픈(-)만 입력가능합니다.</li>
                    </ul>
                  </div>
                </div>
              ),
            },
            {
              title: 'to_addr1',
              required: '',
              type: 'String',
              desc: '수령자주소',
            },
            {
              title: 'to_addr2',
              required: '',
              type: 'String',
              desc: '수령자주소상세',
            },
            {
              title: 'ship_method',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  배송방법
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>무료배송</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>선결제</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>착불</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>방문수령</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>퀵배송</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>일반우편</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>설치배송</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>조건부배송</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>기타</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'ship_cost',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  배송비
                  <div className='description'>
                    <ul className='style'>숫자만 입력가능합니다.</ul>
                  </div>
                </div>
              ),
            },
            {
              title: 'ship_msg',
              required: '',
              type: 'String',
              desc: '배송메세지',
            },
            {
              title: 'depot_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  배송처번호
                  <div className='description'>
                    <ul className='style'>
                      <li>
                        배송처조회 API 를 통하여 확인된 배송처 번호를
                        입력해주십시오.
                      </li>
                    </ul>
                  </div>
                </div>
              ),
            },
            {
              title: 'gprivate_no',
              required: '',
              type: 'String',
              desc: '개인통관번호',
            },
            {
              title: 'carr_info',
              required: '',
              type: 'Object',
              desc: (
                <span>
                  택배정보 <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>주문, 상품정보의 로그를 조회합니다.</li>
                    <li>
                      로그 조회시 uniq, inq_uniq, prod_no, ol_shop_no 중의
                      하나의 호출정보는 반드시 입력해주셔야 합니다.
                    </li>
                  </ul>
                </span>
              ),
            },
            {
              title: 'carr_no',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  택배사코드
                  <div className='description'>
                    (택배사코드조회 api를 호출하여 확인)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'invoice_no',
              required: 'O',
              type: 'String',
              desc: '송장번호',
              indent: 1,
            },
          ],
          body_example: {
            uniq: '1596083929550784071',
            shop_sale_name: 'api 수동입력 테스트 주문',
            sales: '1000000730',
            gift_name: '',
            order_name: '김플토',
            order_id: 'plto_kim',
            order_tel: '02-3333-4444',
            order_htel: '010-2222-3333',
            to_name: '김플토',
            to_name_eng: 'kim pl to',
            to_tel: '02-3333-4444',
            to_htel: '010-2222-3333',
            to_zipcd: '08390',
            to_addr1: '서울특별시 구로구 디지털로26길 123 (구로동)',
            to_addr2: '14층 플레이오토',
            ship_method: '선결제',
            ship_cost: '2500',
            depot_no: '',
            carr_info: [
              {
                carr_no: '01',
                invoice_no: '',
              },
            ],
          },
          response: [
            {
              title: 'results',
              required: 'O',
              type: 'Object[]',
              desc: '결과상세',
            },
            {
              title: 'uniq',
              required: 'O',
              type: 'String',
              desc: '주문고유번호',
              indent: 1,
            },
            {
              title: 'bundle_no',
              required: 'O',
              type: 'String',
              desc: '묶음번호',
              indent: 1,
            },
            {
              title: 'status',
              required: 'O',
              type: 'String',
              desc: '성공/실패 여부',
              indent: 1,
            },
            {
              title: 'message',
              required: 'O',
              type: 'String',
              desc: '실패시 에러메시지',
              indent: 1,
            },
          ],
          response_example: [
            {
              result: [
                {
                  uniq: '3795927781274656794',
                  status: '성공',
                  result: [
                    '주문자명: 이플토->김플토',
                    '주문자전화번호: 01-2222-3333->02-3333-4444',
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: '주문 합포장',
      url: '/doc/주문 합포장',
      data: [
        {
          version: 'v1.0',
          url: '/order/combine',
          desc: (
            <span>
              대상 개별 주문건들을 묶음배송 주문으로 합포장 처리합니다.
              <ul className='style'>
                <li>주문에 적용된 사은품 규칙이 삭제 후 재적용됩니다.</li>
                <li>묶음번호와 수령자 정보가 통일됩니다.</li>
                <li>
                  다음은 이베이물류 사용자에게 해당되는 내용입니다.
                  <br />
                  이베이 물류 사용시 연동상태가 발송요청 대기인 주문을
                  합포장하는 경우
                  <br />
                  묶음주문의 연동상태가 발송정보 수정대기로 변경됩니다.
                  <br />
                  합포장한 묶음주문은 발송정보 전송/수정 작업을 통해 이베이
                  물류서비스로 등록하여야 수정한 정보로 발송정보가 등록됩니다.
                </li>
              </ul>
            </span>
          ),
          method: 'PUT',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'uniqList',
              required: 'O',
              type: '	String[]',
              desc: (
                <span>
                  합포장할 주문의 고유번호 리스트
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      uniqList의 첫번째 주문의 묶음번호와 수령자 정보로 묶음
                      주문의 정보가
                      <br />
                      모두 변경됩니다.
                    </li>
                  </ul>
                </span>
              ),
            },
          ],
          body_example: [
            {
              uniqList: [
                '1596083929550784071',
                '1260041106462302156',
                '1597899464125631341',
              ],
            },
          ],
          response: [
            {
              title: 'result',
              required: 'O',
              type: 'Object[]',
              desc: '결과',
            },
            {
              title: 'uniq',
              required: 'O',
              type: 'String',
              desc: '주문 고유번호',
              indent: 1,
            },
            {
              title: 'old_bundle_no',
              required: 'O',
              type: 'String',
              desc: '이전묶음번호',
              indent: 1,
            },
            {
              title: 'new_bundle_no',
              required: 'O',
              type: 'String',
              desc: '새로 변경된 묶음번호',
              indent: 1,
            },
          ],
          response_example: [
            {
              result: [
                {
                  uniq: '1596083929550784071',
                  old_bundle_no: '3788953930533930',
                  new_bundle_no: '3788953930533930',
                },
                {
                  uniq: '1260041106462302156',
                  old_bundle_no: '3788919600564694',
                  new_bundle_no: '3788953930533930',
                },
                {
                  uniq: '1597899464125631341',
                  old_bundle_no: '3788901443233555',
                  new_bundle_no: '3788953930533930',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: '출고 지시',
      url: '/doc/출고 지시',
      data: [
        {
          version: 'v1.0',
          url: '/order/instruction',
          desc: (
            <ul className='style'>
              <li>입력한 묶음번호의 주문상태를 출고대기 상태로 변경합니다.</li>
              <li>묶음번호 미입력시 입력된 기간내 신규주문 상태의 주문건을 일괄 출고지시 처리 합니다.</li>
            </ul>
          ),
          method: 'PUT',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'bundle_codes',
              required: '',
              type: 'String[]',
              desc: (
                <div>
                  주문묶음번호 리스트
                  <div className='description'>
                    - 묶음번호 입력시 sdate, edate 값은 무시됩니다.
                  </div>
                </div>
              )
            },
            {
              title: 'auto_bundle',
              required: 'O',
              type: 'Boolean',
              desc: (
                <div>
                  주문 묶음 여부
                  <div className='description'>
                    true 로 설정시 출고관리에 존재하는 출고대기, 출고보류 상태
                    주문 중<br /> 현재 출고지시하는 주문과 묶음기준이 동일한
                    주문이 존재하는 경우,
                    <br /> 출고관리에서 자동으로 묶음처리 합니다.
                  </div>
                </div>
              ),
            },
            {
              title: 'sdate',
              required: '',
              type: 'Date',
              desc: (
                <div>
                  조회 시작일
                  <div className='description'>
                    - YYYY-MM-DD 형식으로 입력<br />
                    - bundle_codes 미입력시 필수
                  </div>
                </div>
              )
            },
            {
              title: 'edate',
              required: '',
              type: 'Date',
              desc: (
                <div>
                  조회 종료일
                  <div className='description'>
                    - YYYY-MM-DD 형식으로 입력<br />
                    - bundle_codes 미입력시 필수
                  </div>
                </div>
              )
            },
          ],
          body_example: {
            bundle_codes: ['3870201794300489674', '3870203818826115936'],
            auto_bundle: false,
          },
          response: [
            {
              title: 'results',
              required: 'O',
              type: 'String',
              desc: '성공, 실패여부',
            },
          ],
          response_example: {
            results: '성공',
          },
          error_example: {
            "error_code": "e2009",
            "messages": [
              "신규주문 상태가 아닌 주문건이 포함되어있습니다. 신규주문 상태의 주문건만 출고지시 처리가 가능합니다."
            ],
            "bundle_codes": [
              "3889453142495596",
              "3889424544691818",
              "3889483210345083",
              "3889441602149444",
              "3889413634009229",
              "3889413076144405",
              "3889402687740872",
              "3889432770507346",
              "3889404127805853",
              "3889406699343147",
              "3889458316438943",
              "3889407042667964"
            ]
          }
        },
      ],
    },
    {
      title: '출고 완료',
      url: '/doc/unstoringComplete',
      data: [
        {
          version: 'v1.0',
          url: '/order/unstoring/complete',
          desc: (
            <ul className='style'>
              <li>
                입력한 묶음번호 주문을 출고완료 처리합니다.
              </li>
              <li>결과 데이터는 해당 묶음번호로 조회되는 주문들 중 출고완료에 성공한 주문수가 success, 실패한 주문수가 fail로 출력됩니다.</li>
            </ul>
          ),
          authorized_m_no: [1, 1957, 5422, 77327],
          method: 'PATCH',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'bundle_codes',
              required: 'O',
              type: 'String[]',
              desc: '출고완료 처리할 주문들의 묶음번호',
            }
          ],
          body_example: {
            bundle_codes: [
              "3895364785332425"
            ]
          },
          response: [
            {
              title: 'total',
              required: 'O',
              type: 'Number',
              desc: '출고관리 탭에 있는 주문들 중 입력된 묶음번호로 조회되는 총 주문 건수',
            },
            {
              title: 'success',
              required: 'O',
              type: 'Number',
              desc: '출고완료 성공한 주문건수',
            },
            {
              title: 'fail',
              required: 'O',
              type: 'Number',
              desc: '출고완료 실패한 주문건수',
            },
          ],
          response_example: [
            {
              result: 1,
              success: 1,
              fail: 0,
            }
          ],
        },
      ],
    },
    {
      title: '주문상태변경',
      url: '/doc/주문상태변경',
      data: [
        {
          version: 'v1.0',
          url: '/orders/status',
          desc: (
            <ul className='style'>
              <li>
                입력한 묶음번호의 주문상태를 [신규주문, 출고대기, 배송완료, 판매완료, 반품요청, 반품완료, 교환요청, 교환완료] 상태로
                변경합니다.
              </li>
              <li>
                [주문재확인] 상태의 주문은 uniqs에 주문고유번호 값을 입력하시면 해당 주문건만 상태 변경이 가능합니다.
              </li>
              <li>
                묶음번호와 uniqs를 둘다 입력하시는 경우 묶음번호 값은 무시되며 uniqs에 해당하는 주문건만 상태가 변경됩니다. (우선순위 1. uniqs 2. bundle_codes)
              </li>
              <li>
                신규주문으로 변경가능한 현재 주문상태 : 주문재확인, 주문보류
              </li>
              <li>
                출고대기로 변경가능한 현재 주문상태 : 주문재확인, 출고보류
              </li>
              <li>
                배송완료로 변경가능한 현재 주문상태 : 주문재확인
              </li>
              <li>
                판매완료로 변경가능한 현재 주문상태 : 주문재확인
              </li>
              <li>
                반품요청으로 변경가능한 현재 주문상태 : 주문재확인
              </li>
              <li>
                반품완료로 변경가능한 현재 주문상태 : 주문재확인
              </li>
              <li>
                교환요청으로 변경가능한 현재 주문상태 : 주문재확인
              </li>
              <li>
                교환완료로 변경가능한 현재 주문상태 : 주문재확인
              </li>
            </ul>
          ),
          method: 'PATCH',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'bundle_codes',
              required: '',
              type: 'String[]',
              desc: '주문묶음번호 리스트',
            },
            {
              title: 'uniqs',
              required: '',
              type: 'String[]',
              desc: '주문고유번호',
            },
            {
              title: 'status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  변경하려고 하는 주문상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>신규주문</code>
                          </strong>
                          <div className='description' style={{ padding: '12px 0', 'font-size': '12px' }}>
                            현재 주문상태가 <strong>[주문재확인, 주문보류]</strong>인 경우에만 변경가능합니다.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>출고대기</code>
                          </strong>
                          <div className='description' style={{ padding: '12px 0', 'font-size': '12px' }}>
                            현재 주문상태가 <strong>[주문재확인, 출고보류]</strong>인 경우에만 변경가능합니다.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>배송완료</code>
                          </strong>
                          <div className='description' style={{ padding: '12px 0', 'font-size': '12px' }}>
                            현재 주문상태가 <strong>[주문재확인]</strong>인 경우에만 변경가능합니다.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매완료</code>
                          </strong>
                          <div className='description' style={{ padding: '12px 0', 'font-size': '12px' }}>
                            현재 주문상태가 <strong>[주문재확인]</strong>인 경우에만 변경가능합니다.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>반품요청</code>
                          </strong>
                          <div className='description' style={{ padding: '12px 0', 'font-size': '12px' }}>
                            현재 주문상태가 <strong>[주문재확인]</strong>인 경우에만 변경가능합니다.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>반품완료</code>
                          </strong>
                          <div className='description' style={{ padding: '12px 0', 'font-size': '12px' }}>
                            현재 주문상태가 <strong>[주문재확인]</strong>인 경우에만 변경가능합니다.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>교환요청</code>
                          </strong>
                          <div className='description' style={{ padding: '12px 0', 'font-size': '12px' }}>
                            현재 주문상태가 <strong>[주문재확인]</strong>인 경우에만 변경가능합니다.
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>교환완료</code>
                          </strong>
                          <div className='description' style={{ padding: '12px 0', 'font-size': '12px' }}>
                            현재 주문상태가 <strong>[주문재확인]</strong>인 경우에만 변경가능합니다.
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
          ],
          body_example: {
            bundle_codes: [
              '3885441989781833',
              '3886782611074522',
              '3885942403359338',
            ],
            status: '신규주문',
          },
          response: [
            {
              title: 'results',
              required: 'O',
              type: 'String',
              desc: '성공, 실패여부',
            },
          ],
          response_example: {
            results: '성공',
          },
          error_example: {
            "error_code": "e2999",
            "messages": [
                "출고대기 주문상태변경은 출고보류, 주문재확인 상태의 주문건만 가능합니다."
            ]
        }
        },
      ],
    },
    {
      title: '규칙 사은품 분배',
      url: '/doc/giftDistribution',
      data: [
        {
          version: 'v1.0',
          url: '/order/gift/distribution',
          desc: (
            <ul className='style'>
              <li>사은품 분배가 안된 주문 건이 있거나 추가된 사은품 규칙에 대한 사은품 분배를 진행합니다.</li>
              <li>묶음번호를 입력하지 않으시는경우 주문상태가 '신규주문','출고대기','출고보류','주문보류' 이고 교환주문이 아닌 주문건에 사은품 규칙이 적용됩니다.</li>
            </ul>
          ),
          method: 'PATCH',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'bundle_codes',
              required: '',
              type: 'String[]',
              desc: '주문 묶음번호',
            },
          ],
          body_example: {
            bundle_codes: [
              '3885441989781833',
              '3886782611074522',
              '3885942403359338',
            ]
          },
          response: [
            {
              title: 'result',
              required: 'O',
              type: 'String',
              desc: '성공, 실패여부',
            },
          ],
          response_example: {
            result: 'success',
          },
          error_example: {
            error_code: "e2999",
            messages: [
              '규칙 사은품 분배에 실패했습니다.'
            ]
          }
        },
      ],
    },
    {
      title: '매칭규칙 등록',
      url: '/doc/addMatchingRules',
      data: [
        {
          version: 'v1.0',
          url: '/orders/matching-rules',
          desc: (
            <ul className='style'>
              <li>주문 SKU 매칭규칙을 등록 합니다.</li>
              <li>주문옵션명을 등록하지 않으시는경우 쇼핑몰 상품명으로 대체되어 입력됩니다.</li>
              <li>sku코드 또는 세트코드 둘중에 하나의 값은 필수로 입력해주셔야 하고 둘다 입력시 에러가 발생됩니다.</li>
              <li>등록된 매칭규칙은 다음 주문수집 재고매칭시 반영됩니다.</li>
            </ul>
          ),
          authorized_m_no: [1, 1957, 225824, 232317, 238055, 6580, 199509],
          method: 'POST',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'matchingRules',
              required: 'O',
              type: 'Array',
              desc: '등록하는 매칭정보',
            },
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰코드',
              indent: 1,
            },
            {
              title: 'shop_id',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 아이디',
              indent: 1,
            },
            {
              title: 'c_sale_cd',
              required: '',
              type: 'String',
              desc: '쇼핑몰 상품 판매자관리코드',
              indent: 1,
            },
            {
              title: 'shop_sale_no',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 상품코드',
              indent: 1,
            },
            {
              title: 'shop_sale_name',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 상품명',
              indent: 1,
            },
            {
              title: 'shop_opt_name',
              required: '',
              type: 'String',
              desc: '쇼핑몰 옵션명<br />(빈값으로 입력시 상품명이 대체 입력됩니다.)',
              indent: 1,
            },
            {
              title: 'sku_cds',
              required: '',
              type: 'String[]',
              desc: 'SKU코드\n(set_cd 가 없는 경우 필수)',
              indent: 1,
            },
            {
              title: 'set_cd',
              required: '',
              type: 'String',
              desc: '세트코드\n(sku_cds가 없는 경우 필수)',
              indent: 1,
            },
            {
              title: 'pack_units',
              required: 'O',
              type: 'Number[]',
              desc: '출고수량\n(등록하는 상품의 갯수만큼 입력해주셔야 합니다.)',
              indent: 1,
            },
          ],
          body_example: {
            matchingRules: [
              {
                shop_cd: 'A001',
                shop_id: 'playauto',
                c_sale_cd: 'm202308145850e89a2',
                shop_sale_no: 'testnumber1',
                shop_sale_name: '테스트 상품명',
                shop_opt_name: '주문옵션명',
                sku_cds: [
                  'SKUCODE001', 'SKUCODE002', 'SKUCODE003'
                ],
                set_cd: '',
                pack_units: [1, 1, 2]
              },
              {
                shop_cd: 'A006',
                shop_id: 'playauto',
                c_sale_cd: 'm202308145850e89a2',
                shop_sale_no: 'testnumber2',
                shop_sale_name: '테스트 상품 2',
                shop_opt_name: '',
                sku_cds: [],
                set_cd: 'SETCODE001',
                pack_units: [1]
              }
            ]
          },
          response: [
            {
              title: 'results',
              required: '',
              type: 'Array',
              desc: '',
            },
            {
              title: 'status',
              required: 'O',
              type: 'success | fail',
              desc: '성공, 실패여부',
              indent: 1,
            },
            {
              title: 'message',
              required: 'O',
              type: 'String',
              desc: '실패시 에러 메세지',
              indent: 1,
            },
            {
              title: 'shop_sale_no',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 상품코드',
              indent: 1,
            },
          ],
          response_example: {
            results: [
              {
                  "status": "success",
                  "message": "",
                  "shop_sale_no": "testnumber1"
              },
              {
                  "status": "fail",
                  "message": "SKU 또는 세트코드 중 하나만 입력해주세요.",
                  "shop_sale_no": "testnumber2"
              }
          ]
          },
          error_example: {
            error_code: "e2058",
            messages: [
              '존재하지 않는 쇼핑몰계정 입니다.'
            ]
          }
        },
      ],
    },
    {
      title: '상태별 주문수량 조회',
      url: '/doc/getOrderCount',
      data: [
        {
          version: 'v1.0',
          url: '/orders/count',
          desc: (
            <ul className='style'>
              <li>입력한 상태별 주문수량을 조회합니다.</li>
            </ul>
          ),
          method: 'GET',
          header: default_header,
          header_example: default_header_example,
          query: [
            {
              title: 'ord_status',
              required: 'O',
              type: 'String',
              desc: '조회하려는 주문상태\n(콤마로 구분하여 여러개 입력 가능)\n(예: ord_status=신규주문,출고대기,운송장출력)\n\n조회가능 상태: 결제완료, 신규주문, 출고대기, 출고보류, 운송장출력, 출고완료, 배송중, 배송완료, 구매결정, 판매완료, 취소요청, 취소완료, 반품요청, 반품진행, 반품회수완료, 반품완료, 교환요청, 교환회수완료, 교환완료, 맞교환요청, 맞교환완료, 주문재확인, 교환진행, 주문보류',
            },
            {
              title: 'sdate',
              required: '',
              type: 'Date (YYYY-MM-DD)',
              desc: '조회 시작일\n(미입력시 종료일 기준 한달로 조회)',
            },
            {
              title: 'edate',
              required: '',
              type: 'Date (YYYY-MM-DD)',
              desc: '조회 종료일\n(미입력시 오늘 날짜로 조회)',
            },
          ],
          response: [
            {
              title: 'results',
              required: '',
              type: 'Array',
              desc: '',
            },
            {
              title: 'ord_status',
              required: 'O',
              type: 'String',
              desc: '주문상태',
              indent: 1,
            },
            {
              title: 'count',
              required: 'O',
              type: 'Number',
              desc: '주문수',
              indent: 1,
            }
          ],
          response_example: {
            results: [
              {
                "ord_status": "신규주문",
                "count": 41
              },
              {
                  "ord_status": "출고대기",
                  "count": 245
              },
              {
                  "ord_status": "운송장출력",
                  "count": 129
              }
            ]
          }
        },
      ],
    },
    {
      title: '[해외] 패키지 생성',
      url: '/doc/패키지 생성',
      data: [
        {
          version: 'v1.0',
          url: '/order/package/add',
          desc: (
            <ul className='style'>
              <li>
                패키지를 생성하는 작업입니다.
              </li>
              <li>
                패키징 처리는 해외 주문 건의 국내 집하지로 보내기 위한 묶음 작업입니다.<br/>
                여러개의 주문을 각각 포장 및 해외 송장 부착 후 한박스에 모두 포장하여 집하지로 배송 -> 집하지에서 박스 오픈 후 해외 출고 작업
              </li>
              <li>
                [출고대기], [운송장출력] 상태일 경우에만 패키지 생성이 가능합니다.<br/>
                직접 입력 주문, 국내 주문 건일 경우 패키지 성생이 불가능합니다.
              </li>
              <li>
                패키지 생성 시 패키지 번호는 입력한 묶음 번호 중 하나로 생성 됩니다.
              </li>
              <li>
                패키지 묶음 당 송장번호 1개 발번
              </li>
              <li>
                묶음 기준 1000개까지 패키지 가능(shopee 정책)
              </li>
            </ul>
          ),
          authorized_m_no: [1, 1957, 5422, 77327, 129725, 121024, 122081, 122082],
          authorized_aff_no: [1051],
          method: 'PATCH',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'bundle_codes',
              required: 'O',
              type: 'String[]',
              desc: '주문묶음번호 리스트',
            }
          ],
          body_example: {
            bundle_codes: [
              '3885441989781833',
              '3886782611074522'
            ]
          },
          response: [
            {
              title: 'results',
              required: 'O',
              type: 'Object[]',
              desc: '결과',
            },
            {
              title: 'bundle_no',
              required: 'O',
              type: 'String',
              desc: '묶음번호',
              indent: 1,
            },
            {
              title: 'pa_bundle_no',
              required: 'O',
              type: 'String',
              desc: '생성된 패키지 번호',
              indent: 1,
            }
          ],
          response_example: {
            results: [{
              bundle_no: '3885441989781833',
              pa_bundle_no: '3885441989781833'
            },
            {
              bundle_no: '3886782611074522',
              pa_bundle_no: '3885441989781833'
            }
            ],
          },
        },
      ],
    },
    {
      title: '[해외] 패키지 삭제/분리',
      url: '/doc/패키지 삭제',
      data: [
        {
          version: 'v1.0',
          url: '/order/package/divide',
          desc: (
            <ul className='style'>
              <li>
                여러 개의 패키지를 하나의 패키지 번호로 합치는 작업입니다.
              </li>
              <li>
                입력하신 묶음 번호의 패키지 번호가 묶음번호와 동일할 경우 같은 패키지로 묶여있는 묶음 주문들의 패키지 번호가 변경됩니다.
              </li>
            </ul>
          ),
          authorized_m_no: [1, 1957, 5422, 77327, 129725, 121024, 122081, 122082],
          authorized_aff_no: [1051],
          method: 'PATCH',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'bundle_codes',
              required: 'O',
              type: 'String[]',
              desc: '대상 묶음 번호 리스트',
            },
            {
              title: 'type',
              required: 'O',
              type: 'String',
              desc: <div>
              작업 유형
              <div className='description' style={{ padding: '12px 0' }}>
                Allowed values
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Value</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>
                        <code>divide</code>
                      </strong>
                    </td>
                    <td>
                      <strong>패키지 분할</strong><br/>
                      <div>입력한 묶음 번호를 새로운 패키지로 분할합니다.</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        <code>delete</code>
                      </strong>
                    </td>
                    <td>
                      <strong>패키지 삭제</strong><br/>
                      <div>입력한 묶음 번호의 패키지 번호를 삭제합니다.</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>,
            }
          ],
          body_example: {
            bundle_codes: [
              '3885441989781833',
              '3886782611074522'
            ],
            type: 'divide'
          },
          response: [
            {
              title: 'results',
              required: 'O',
              type: 'Object[]',
              desc: '결과',
            },
            {
              title: 'old_pa_bundle_no',
              required: 'O',
              type: 'String',
              desc: '기존 패키지 번호',
              indent: 1,
            },
            {
              title: 'new_pa_bundle_no',
              required: 'O',
              type: 'String',
              desc: '새로 변경된 패키지 번호',
              indent: 1,
            }
          ],
          response_example: {
            results: [{
              bundle_no: '3885441989781833',
              old_pa_bundle_no: '3886782611074522',
              new_pa_bundle_no: '3885441989781833'
            },
            {
              bundle_no: '3886782611074522',
              old_pa_bundle_no: '3886782611074522',
              new_pa_bundle_no: '3886782611074522'
            }
            ],
          },
        },
      ],
    },
    {
      title: '[해외] 패키지 합치기',
      url: '/doc/패키지 합치기',
      data: [
        {
          version: 'v1.0',
          url: '/order/package/combine',
          desc: (
            <ul className='style'>
              <li>
                여러 개의 패키지를 하나의 패키지 번호로 합치는 작업입니다.
              </li>
              <li>
                패키지 묶음 기준을 설정 하지 않을 시 입력하신 패키지 번호의 첫번째 인덱스가 패키지 묶음 기준으로 설정 됩니다.
              </li>
            </ul>
          ),
          authorized_m_no: [1, 1957, 5422, 77327, 129725, 121024, 122081, 122082],
          authorized_aff_no: [1051],
          method: 'PATCH',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'pa_bundle_codes',
              required: 'O',
              type: 'String[]',
              desc: '대상 패키지 번호 리스트',
            },
            {
              title: 'main_bundle_no',
              required: 'X',
              type: 'String',
              desc: '묶음 기준 패키지 번호',
            }
          ],
          body_example: {
            pa_bundle_codes: [
              '3885441989781833',
              '3886782611074522'
            ],
            main_bundle_no: '3886782611074522'
          },
          response: [
            {
              title: 'results',
              required: 'O',
              type: 'Object[]',
              desc: '결과',
            },
            {
              title: 'old_pa_bundle_no',
              required: 'O',
              type: 'String',
              desc: '기존 패키지 번호',
              indent: 1,
            },
            {
              title: 'new_pa_bundle_no',
              required: 'O',
              type: 'String',
              desc: '새로 변경된 패키지 번호',
              indent: 1,
            }
          ],
          response_example: {
            results: [{
              old_pa_bundle_no: '3885441989781833',
              new_pa_bundle_no: '3886782611074522'
            },
            {
              old_pa_bundle_no: '3886782611074522',
              new_pa_bundle_no: '3886782611074522'
            }
            ],
          },
        },
      ],
    },
    {
      title: '[해외] 배송정보 업데이트',
      url: '/doc/해외 배송정보 업데이트',
      data: [
        {
          version: 'v1.0',
          url: '/order/setInvoiceGlobal',
          desc: (
            <ul className='style'>
              <li>
                입력한 묶음번호의 주문의 해외송장번호와 해외택배사를 업데이트 합니다.
              </li>
              <li>묶음번호 및 해외택배사번호, 해외송장번호는 모두 필수 값입니다.</li>
              <li>
                overwrite 는 이미 값이 입력되어있는경우 덮어쓸지 여부로 기본값은
                false 입니다.
              </li>
              <li>
                change_complete 는 상태변경시 출고완료로 변경할건지의
                여부입니다. 기본값은 false 이며 변경되는 상태는 다음과 같습니다.
                : false - 운송장출력 : true - 출고완료
              </li>
              <li>결과 데이터는 전송한 묶음번호만큼 Array 로 리턴됩니다</li>
            </ul>
          ),
          authorized_m_no: [1, 1957, 5422, 77327, 129725, 121024, 122081, 122082],
          authorized_aff_no: [1051],
          method: 'PUT',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'orders',
              required: 'O',
              type: 'Array',
              desc: '변경할 주문데이터',
            },
            {
              title: 'bundle_no',
              required: 'O',
              type: 'String',
              desc: '묶음주문번호',
              indent: 1,
            },
            {
              title: 'global_carr_no',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  해외 택배사코드
                  <div className='description'>
                    (택배사코드조회 api를 호출하여 확인)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'global_invoice_no',
              required: 'O',
              type: 'String',
              desc: '해외송장번호',
              indent: 1,
            },
            {
              title: 'overwrite',
              required: '',
              type: 'boolean',
              desc: (
                <div>
                  이미 해외송장번호가 입력되어있는 주문일경우 신규정보로 덮어쓸지
                  여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'change_complete',
              required: '',
              type: 'boolean',
              desc: (
                <div>
                  해외송장번호와 해외택배사가 정상 반영된경우 변경할 주문상태
                  <div className='description'>
                    (true:출고완료, false:운송장출력)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
          ],
          body_example: {
            orders: [
              {
                bundle_no: '3772225519903274',
                global_carr_no: '944',
                global_invoice_no: '377811119999',
              },
              {
                bundle_no: '3772207204045999',
                global_carr_no: '944',
                global_invoice_no: '377811118888',
              },
            ],
            overwrite: true,
            change_complete: true,
          },
          response: [
            {
              title: 'bundle_no',
              required: 'O',
              type: 'String',
              desc: '주문 묶음번호',
            },
            {
              title: 'result',
              required: 'O',
              type: 'Boolean',
              desc: '성공, 실패 여부',
            },
            {
              title: 'message',
              required: 'O',
              type: 'Boolean',
              desc: '실패시 실패사유',
            },
          ],
          response_example: [
            {
              bundle_no: '3772225519903273',
              result: '성공',
              message: '',
            },
            {
              bundle_no: '3772225519903274',
              result: '실패',
              error_code: 'e2001',
              messages: [
                '[출고관리]메뉴에서 조회되지 않는 묶음고유번호입니다.'
              ],
            },
          ],
        },
      ],
    },
    {
      title: '출고지시 및 배송정보 업데이트',
      url: '/doc/출고지시 및 배송정보 업데이트',
      data: [
        {
          authorized_m_no: [91809, 91810, 91812, 223054, 88912, 88913, 88916, 64370],
          version: 'v1.0',
          url: '/order/ship-set-invoice',
          desc: (
            <div>
              입력한 묶음번호의 주문의 출고지시 및 송장번호/배송사 업데이트를
              진행합니다.
              <br />
              <br />
              <div>
                🚨해당 API로 작업시 입력한 주문과 묶음기준이 동일한 주문이
                출고관리에 존재해도 자동으로 묶음처리되지 않습니다.🚨
                <br />
                자동 묶음처리를 원하실경우
                <a href='/doc/출고 지시' style={{ color: 'blue' }}>
                  출고지시
                </a>
                API를 이용해주시기 바랍니다.
              </div>
            </div>
          ),
          method: 'PUT',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'orders',
              required: 'O',
              type: 'Array',
              desc: '변경할 주문데이터',
            },
            {
              title: 'bundle_no',
              required: 'O',
              type: 'String',
              desc: '묶음번호',
              indent: 1,
            },
            {
              title: 'carr_no',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  택배사 코드
                  <div className='description'>
                    ( 택배사코드조회 api 를 호출하여 확인 )
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'invoice_no',
              required: 'O',
              type: 'String',
              desc: '송장번호',
              indent: 1,
            },
            {
              title: 'overwrite',
              required: '',
              type: 'Boolean',
              desc: (
                <div>
                  이미 송장번호가 입력되어있는 주문일경우 신규정보로 덮어쓸지
                  여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'change_complete',
              required: 'O',
              type: 'Boolean',
              desc: (
                <div>
                  송장번호와 택배사가 정상 반영된경우 변경할 주문상태
                  <div className='description'>
                    (true:출고완료, false:운송장출력)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sub_sol_no',
              required: 'O',
              type: 'Number',
              desc: '하위계정 sol_no',
            },
          ],
          body_example: {
            sub_sol_no: 999999,
            orders: [
              {
                bundle_no: '3772225519903274',
                carr_no: '4',
                invoice_no: '377811119999',
              },
              {
                bundle_no: '3772207204045999',
                carr_no: '5',
                invoice_no: '377811118888',
              },
            ],
            overwrite: true,
            change_complete: true,
          },
          response: [
            {
              title: 'bundle_no',
              required: 'O',
              type: 'String',
              desc: '주문 묶음번호',
            },
            {
              title: 'result',
              required: 'O',
              type: 'Boolean',
              desc: '성공, 실패여부',
            },
            {
              title: 'message',
              required: 'O',
              type: 'String',
              desc: '실패시 실패사유',
            },
          ],
          response_example: [
            {
              bundle_no: '3772225519903273',
              result: '성공',
              message: '',
            },
            {
              bundle_no: '3772225519903274',
              result: '실패',
              error_code: 'e2001',
              messages: [
                '[출고관리]메뉴에서 조회되지 않는 묶음고유번호입니다.'
              ],
            },
          ],
        },
      ],
    },
  ],
});

export default data;
