import CommonApiDocData from './Common';
import OrderApiDocData from './Order';
import ProductApiDocData from './Product';
import InquiryApiDocData from './Inquiry';
import StockApiDocData from './Stock';
import CalculateApiDocData from './Calculate';
import MemoApiDocData from './Memo';
import WorkApiDocData from './Work';
import EtcApiDocData from './Etc';
import ErrorApiDocData from './Error';
import ReleaseApiDocData from './Release';
import ShopApiDocData from './Shop';

// OpenAPI 문서 페이지별 데이터
const PageDataList = (userInfo) => {
  userInfo = userInfo || userInfoDefault

  return ([
  // 공통 API 문서
  CommonApiDocData(userInfo),
  // 쇼핑몰계정 관리 API 문서
  ShopApiDocData(userInfo),
  // 주문 API 문서
  OrderApiDocData(userInfo),
  // 상품 API 문서
  ProductApiDocData(userInfo),
  // 문의 API 문서
  InquiryApiDocData(userInfo),
  // 재고 API 문서
  StockApiDocData(userInfo),
  // 정산 API 문서
  CalculateApiDocData(userInfo),
  // 메모 API 문서
  MemoApiDocData(userInfo),
  // 작업 API 문서
  WorkApiDocData(userInfo),
  // 기타 API 문서
  EtcApiDocData(userInfo),
  // API호출 에러 문서
  ErrorApiDocData(userInfo),
  // API 변경 내역 문서
  ReleaseApiDocData(userInfo)
])};


// userInfo 호출 전 임시 데이터
const userInfoDefault = {
  pa_sol_no: null,
  sol_no: 1,
  sol_type: 'GMP',
  sol_sub_type: 'PRODUCT',
  sol_version: 'BASIC',
  sol_stock: 1,
  sol_sdate: '2001-01-01',
  sol_edate: '2099-01-01',
  sol_ser: 'gmp',
  last_sync_time: 'test',
  first_pay_no: null,
  domain: 'test',
  db_conn_name: 'test',
  default_template_group_no: 0,
  mas_m_no: 1957,
  wm_no: 1957,
  wm_name: '',
  m_no: 1,
  m_type: '마스터',
  m_status: '정상',
  wdate: '2022-01-01',
  mdate: '2022-01-01',
  last_login_time: '2022-01-01',
  last_pwd_mod_time: '2022-01-01',
  leave_time: null,
  last_login_ip: 333,
  name: 'testname',
  email: 'test@test.com',
  pwd: '1234',
  c_no: 2026,
  ctry_cd: 'kr',
  tel: '010',
  htel: '010',
  email_yn: 'Y',
  sms_yn: 'Y',
  dept: '1',
  posi: 'test',
  reg_auth_key: null,
  pwd_auth_key: null,
  auth_type: 'test',
  roles_arr: ['test'],
  depot_no: null,
  adb_id: '',
  company_name: 'test',
  biz_reg_no: 'test',
  c_tel: 'test',
  zipcd: null,
  addr1: 'test',
  addr2: null,
  user_hash: 'test',
  adminMode: false,
  iat: 0,
  exp: 0,
  ip: 'test',
  token: 'test',
  account_id: ''
}

export default PageDataList;