/**
 * 가이드 메뉴
 */
export const GuideContentList = [
  {
    title: '소개',
    url: '/overview'
  },
  {
    title: '시작하기',
    url: '/instruction'
  },
  {
    title: '호출제한',
    url: '/limit'
  },
  {
    title: '버전관리',
    url: '/version'
  }
];
  
/**
 * 고객지원 메뉴
 */
export const SupportContentList = [
  {
    title: '공지사항',
    url: '/notice'
  },
  {
    title: '1:1 문의',
    url: '/inquiry'
  }
];