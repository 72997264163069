import classes from './ReleasePage.module.css';
import AccordionSideNav from '../../layout/SideNav/AccordionSideNav/AccordionSideNav';
import PageDataList from '../../../store/PageDetailData/PageDetailData';
import AuthContext from '../../../store/auth-context';
import { useContext } from 'react';

/**
 * 변경 내역 페이지 컴포넌트
 */
const ReleasePage = () => {
  const authCtx = useContext(AuthContext);
  const memberNo = Number(authCtx.memberNo);
  const affNo = Number(authCtx.userInfo?.aff_no || 0);
  const pageData = PageDataList(authCtx.userInfo).filter(list => list.url === '/release')[0].data;
  const releaseGroup = pageData.reduce((acc, curr) => {
    const { date } =curr;

    if(acc[date]) acc[date].push(curr);
    else acc[date] = [curr];

    return acc;
  }, {})

  const filteredPageDataList = process.env.NODE_ENV === 'development' ? PageDataList(authCtx.userInfo) : PageDataList(authCtx.userInfo).map(
    actions => (
      {...actions,
        children:
        actions.children
          .filter(action => {
            if (actions.title === '에러코드') {
              return !(action.unrecognized_aff_no?.length && action.unrecognized_aff_no.includes(affNo))
            }
            else if (!action.data[0].authorized_m_no?.length && !action.data[0].authorized_aff_no?.length) {
              return true;
            } else if (action.data[0].authorized_m_no?.length && action.data[0].authorized_aff_no?.length) {
              return action.data[0].authorized_m_no.includes(memberNo) || action.data[0].authorized_aff_no.includes(affNo)
            } else {
              return (action.data[0].authorized_m_no?.length ? action.data[0].authorized_m_no.includes(memberNo) : true) && (action.data[0].authorized_aff_no?.length ? action.data[0].authorized_aff_no.includes(affNo) : true)
            }
          })
      })
  ).filter(actions =>
    (actions.children.length || actions.alwaysShow) &&
    (!actions.unrecognized_aff_no || !actions.unrecognized_aff_no.length || !actions.unrecognized_aff_no.includes(affNo)));

  return (
    <>
      <AccordionSideNav data={filteredPageDataList} />
      <div className={classes.container}>
        <h1 className={classes.heading}>API 변경내역</h1>
        {
          Object.entries(releaseGroup).map(([date, list]) => {
            return (
              <div className={classes.section}>
                <div key={date}>
                  <h3>{date}</h3>
                  {
                    list.map((value, i) => {
                      return (
                        <div key={i} style={{ paddingBottom: '10px' }}>
                          <div className={classes.url}>
                          {
                            value.method ? 
                            (
                              <div className={classes[value.method.toUpperCase()]}>
                                <div className={classes.method}>{value.method.toUpperCase()}</div>
                              </div>
                            ) 
                            : ''
                          }
                            <div style={{ paddingLeft:'10px', paddingTop: '2px', textAlign: 'center'}}>{ value.action ? `https://openapi.playauto.io/api${value.action}` : '' }</div>
                          </div>
                          <div className={classes.detail}>
                            {value.detail}
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            );
          })
        }
      </div>
    </>
  );
};

export default ReleasePage;