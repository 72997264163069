import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import DescriptionPageTemplate from './DescriptionPageTemplate';
import { findPageData } from '../../../util/HelperFunctions';
import AuthContext from '../../../store/auth-context';
import PageDataList from '../../../store/PageDetailData/PageDetailData';
import useValidatePageAccess from 'hooks/useValidatePageAccess';

/**
 * Open API 문서 상세 페이지 컴포넌트
 */
const DetailLoadingPage = () => {
  const authCtx = useContext(AuthContext);
  const newParams = useParams();
  const [params, setParams] = useState('');
  const pageDataRaw = findPageData(params['*'], PageDataList(authCtx.userInfo));
  const navigate = useNavigate();
  const memberNo = Number(authCtx.memberNo);
  const [affNo, setAffNo] = useState(Number(authCtx.affNo));

  useValidatePageAccess(authCtx.isLoggedIn 
    && ((!pageDataRaw.data[0].authorized_m_no?.length && !pageDataRaw.data[0].authorized_aff_no?.length) ||
      ((pageDataRaw.data[0].authorized_m_no?.length && pageDataRaw.data[0].authorized_m_no.includes(memberNo)) || (pageDataRaw.data[0].authorized_aff_no?.length && pageDataRaw.data[0].authorized_aff_no.includes(affNo))) ||
      ((pageDataRaw.data[0].authorized_m_no?.length ? pageDataRaw.data[0].authorized_m_no.includes(memberNo) : true) && (pageDataRaw.data[0].authorized_aff_no?.length ? pageDataRaw.data[0].authorized_aff_no.includes(affNo) : true)))
    && (pageDataRaw.data[0].unrecognized_aff_no?.length ? !pageDataRaw.data[0].unrecognized_aff_no.includes(affNo) : true)
  );
  

  // 페이지가 변경되면 API 테스트 섹션에서 데이터 리셋
  useEffect(() => {
    setParams(newParams);
    setAffNo(Number(authCtx.affNo))
  }, [authCtx, newParams, pageDataRaw, memberNo, navigate]);

  // m_no에 따라 사이드 네비게이션 바에 노출되는 항목들 필터링
  const filteredPageDataList = PageDataList(authCtx.userInfo).map(
    actions => (
      {...actions,
        children:
        actions.children
          .filter(action => {
            if (actions.title === '에러코드') {
              return !(action.unrecognized_aff_no?.length && action.unrecognized_aff_no.includes(affNo))
            }
            else if (!action.data[0].authorized_m_no?.length && !action.data[0].authorized_aff_no?.length) {
              return true;
            } else if (action.data[0].authorized_m_no?.length && action.data[0].authorized_aff_no?.length) {
              return action.data[0].authorized_m_no.includes(memberNo) || action.data[0].authorized_aff_no.includes(affNo)
            } else {
              return (action.data[0].authorized_m_no?.length ? action.data[0].authorized_m_no.includes(memberNo) : true) && (action.data[0].authorized_aff_no?.length ? action.data[0].authorized_aff_no.includes(affNo) : true)
            }
          })
      })
  ).filter(actions =>
      (actions.children.length || actions.alwaysShow) &&
      (!actions.unrecognized_aff_no || !actions.unrecognized_aff_no.length || !actions.unrecognized_aff_no.includes(affNo)));

    const pageDataRawFiltered = { ...pageDataRaw, data: pageDataRaw.data.filter(version => {
      if (version.authorized_m_no?.length && version.authorized_aff_no?.length) {
        return true;
      } else if (version.authorized_m_no?.length && version.authorized_aff_no?.length) {
        return version.authorized_m_no.includes(memberNo) || version.authorized_aff_no.includes(affNo)
      } else {
        return (version.authorized_m_no?.length ? version.authorized_m_no.includes(memberNo) : true) && (version.authorized_aff_no?.length ? version.authorized_aff_no.includes(affNo) : true)
      }
    })}
  return (
    (newParams === params) ? <DescriptionPageTemplate pageDataRaw={pageDataRawFiltered} filteredPageDataList={filteredPageDataList} /> : ''
  );
};

export default DetailLoadingPage;