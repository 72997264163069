import { useEffect, useCallback } from 'react';

function useEscModalClose(props) {
  const handleEscClick = useCallback(e => {
    if (e.key === 'Escape') {
      props.closeModal();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Esc 클릭 시 모달이 닫히도록 이벤트 리스너 등록
    document.addEventListener('keydown', handleEscClick);
    return () => document.removeEventListener('keydown', handleEscClick);
  });
}

export default useEscModalClose;


