import { useEffect, useContext } from 'react';
import AuthContext from 'store/auth-context';
import { useNavigate } from 'react-router-dom';

/**
 * 해당 페이지에 접속 권한이 있는지 확인 후 없으면 루트 경로로 리다이렉트 해주는 커스텀 훅
 * @param {*} isValid 
 */
function useValidatePageAccess(isValid = true) {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isValid) return navigate('/');
    if (!authCtx.userInfo) {
      navigate('/');
    }
  });
}

export default useValidatePageAccess;