import classes from './TermsDetailModal.module.css';
import Modal from '../Modal';
import useEscModalClose from '../../../hooks/useEscModalClose';


/**
 * 1:1문의 작성 모달 컴포넌트
 */
const TermsDetailModal = props => {
  useEscModalClose(props);

  return (
    <Modal>
      <div className={classes.container}>
        <div className={classes.content}>
          <h2>플레이오토 개발자센터 이용약관</h2>
          <h6>제 1 조 (목적)</h6>
          <p>플레이오토 개발자센터 이용 약관(이하 ‘본 약관’)은 플레이오토 주식회사(이하 '회사')가 제공하는 개발자센터 API 서비스의 이용과 관련한 회사와 이용자의 권리, 의무 및 책임에 관한 제반 사항과 기타 필요한 사항을 구체적으로 규정함을 목적으로 합니다.</p>
          <h6>제 2 조 (용어의 정의)</h6>
          <p>
            1. 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br />
            ① ‘개발자센터’라 함은 회사 API 서비스 제공 웹사이트(https://developers.playauto.io)를 의미합니다.<br />
            ② ‘이용자’란 본 약관에 따라 개발자센터를 통해 제공되는 API 서비스의 이용계약(이하 ‘이용계약’)을 회사와 체결한 자를 의미합니다.<br />
            ③ ‘개발자 계정’이란 이용자 중 API 서비스를 이용하여 이용고객의<br />
            통신판매업 영위에 도움을 줄 수 있는 솔루션, 서비스, 프로그램 등을 개발, 제작하여 판매 또는 제공하는 자를 의미합니다.
            ④ ‘이용고객’이란 이용자의 솔루션 등을 이용하는 판매자를 의미합니다.<br />
            ⑤ ‘API’란 Application Programming Interface의 약자로서 이용자가 자신의 애플리케이션에서 회사가 제공하는 기술을 사용하기 위한 인터페이스를 의미합니다.<br />
            ⑥ ‘API 서비스’란 회사가 개발자센터를 통해 API를 이용자에게 제공하여 연동하게 하고, 연동된 API를 통한 데이터통신을 제공하는 것을 의미합니다.<br />
            ⑦ ‘애플리케이션’이라 함은 이용자가 API 서비스를 적용시키고자 하는 이용자의 서비스, 프로그램 또는 웹사이트 등을 의미합니다.<br />
            ⑧ '솔루션'이란 개발자 계정이 API 서비스를 통해 획득한 데이터를 포함하여 제작한 서비스로, 이용고객들이 통신판매 사업 영위 과정에서 필요에 따라 구매·제공받아 사용 가능한 서비스 또는 플레이오토의 쇼핑몰 통합 솔루션을 의미합니다.<br />
            ⑨ ⑨ ‘인증정보'라 함은 API를 제공받아 사용하고자 하는 이용자의 애플리케이션이 API 서비스를 통한 데이터 취득 권한을 이용고객으로부터 허락 받은 이용자의 애플리케이션인 것을 API 서비스 이용 시 식별할 수 있도록 회사가 이용자에게 할당한 고유한 인증 값을 의미합니다.<br />
            ⑩ 판매자'란 플레이오토 쇼핑몰 통합솔루션 서비스 이용약관에 동의하여 회사와 서비스 이용계약을 체결한 회원(법인 또는 개인)을 의미합니다.<br />
            ⑪ ‘시스템’이란 API 서비스 및 이를 운영하는데 소요되는 회사 및 이용자의 서버와 통신망 등을 의미합니다.<br />
            ⑫’통합홈페이지’란 플레이오토 통합홈페이지(https://www.plto.com/)를 의미합니다.<br />
          </p>
          <h6>제 3 조 (약관의 게시와 개정)</h6>
          <p>
            1. 회사는 본 약관의 내용을 이용자가 쉽게 알 수 있도록 개발자센터 초기 화면에 게시합니다.<br />
            2. 회사는 필요한 경우 약관의 규제에 관한 법률 등 관련 법령에 위배되지 않는 범위 안에서 본 약관을 개정할 수 있습니다.<br />
            3. 회사는 본 약관을 개정할 경우, 적용 일자 7일 전부터 적용 일자 전일까지 약관의 개정내용 및 적용 일자를 개발자센터에서 공지합니다. 다만, 본 약관이 이용자에게 불리하게 개정되는 경우에는 적용 일자로부터 30일 이전부터 공지하고, 이용자에게 본 약관 제16조에 정한 수단으로 개별적으로 통지합니다.<br />
            4. 회사가 전항에 따라 공지하면서 이용자에게 적용 일자 전일까지 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지하거나 개별적 통지하였음에도 이용자가 명시적으로 거부의 의사표시를 하지 않은 경우 이용자가 개정 약관에 동의한 것으로 간주합니다.<br />
            5. 이용자는 개정 약관에 동의하지 않는 경우 적용 일자 전일까지 회사에 거부의사를 표시하고 API 서비스 이용을 중지할 수 있습니다.<br />
          </p>
          <h6>제 4 조 (API 서비스 이용 신청 및 이용계약의 체결)</h6>
          <p>
            1. API 서비스 이용계약은 API 서비스를 이용하고자 하는 자(이하 ‘이용신청자’)가 본 약관의 내용에 동의를 한 다음, 개발자센터에 회사가 정해 놓은 절차에 따라 API 서비스 이용 신청을 하면, 회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.<br />
            2. 회사는 이용신청자의 신청에 대하여 아래 각 호의 사유가 있는 경우에는 승낙을 하지 않을 수 있으며, 가입 이후에도 아래 각 호의 사유가 확인될 경우에는 이용신청자에 대하여 통보 없이 API 서비스 이용 제한 조치를 할 수 있습니다.<br />
              가. 이용신청자가 플레이오토 이용약관에 따른 이용 제한 중인 경우<br />
              나. 이용신청자가 본 약관에 의하여 이전에 이용자 자격을 상실한 적이 있는 경우<br />
              다. 이용신청자가 본 약관 위반 등의 사유로 API 서비스 이용 제한 중에 해지·탈퇴 후 재가입신청을 하는 경우<br />
              라. 이용신청 시 허위 또는 잘못된 정보를 기재 또는 제공하거나 회사가 제시하는 사항을 기재하지 않은 경우<br />
              마. 이용신청자의 귀책사유로 인하여 승낙이 불가능하거나 기타 본 약관에서 규정한 제반 사항을 위반하여 신청하는 경우<br />
              바. 이용자가 거주하고 있거나 API 서비스를 사용하고 있는 국가의 법률에 의할 때 API 서비스를 사용하거나 받는 것이 금지된 경우<br />
              사. 기타 회사가 판단하기에 이용자가 회사의 API 서비스를 이용하는 것이 부적절한 경우<br />
            3. 이용계약의 성립 시기는 통합홈페이지 가입 완료를 신청절차상에서 표시하고 본 약관의 내용에 동의를 한 다음, 개발자센터에 회사가 정해 놓은 절차에 따라 API 서비스 이용 신청을 한 시점으로 합니다.<br />
            4. 만 19세 미만의 미성년자인 이용신청자가 이용계약을 체결하기 위하여는 부모 등 법정대리인의 동의를 얻어야 합니다. 만 19세 미만의 미성년자인 이용신청자가 부모 등 법정대리인의 동의를 얻지 않으면 미성년자인 이용신청자 본인 또는 법정대리인은 본 서비스 이용계약을 취소할 수 있으며, 회사는 해당 내용을 계약 체결 전에 고지합니다. 단, 만 19세 미만의 미성년자인 이용신청자가 성년자(성인)인 타인의 고유정보를 도용하는 등의 방법으로 본인이 성년자(성인)인 것으로 표시하여 이용계약을 체결하였거나 또는 성년자(성인)인 제3자의 결제정보를 동의 없이 이용하여 결제를 하는 등 속임수를 사용하여 회사로 하여금 이용신청자가 성년자(성인)이거나 법정대리인의 동의가 있었던 것으로 믿게 한 경우에는, 법정대리인의 동의가 없더라도 이용신청자 또는 법정대리인이 이용계약을 일방적으로 취소할 수 없습니다.<br />
          </p>
          <h6>제 5 조 (API 서비스의 제공)</h6>
          <p>
            1. 회사는 운영상의 필요 및 신규 서비스 개발, 그리고 이용자들의 API 이용량 등을 고려하여 API 서비스를 무상 또는 유상으로 제공할 수 있습니다. 단, 회사가 무상으로 제공하던 API 서비스를 유상 제공으로 전환할 경우, 이용자에게 불리한 약관 개정에 준하여 충분한 기간을 두고 개발자센터를 통해 공지하고 이용자들에게 개별적으로도 통지합니다.<br />
            2. 회사는 API 서비스를 제공함에 있어서 API 서비스의 범위, 이용 가능 시간, 이용 가능 횟수를 지정할 수 있으며, 이와 같은 제공조건은 개발자센터를 통해 공지되고, 이용자는 그와 같이 정해진 제공 조건에 한하여 API 서비스를 이용할 수 있습니다.<br />
            3.회사는 API 서비스를 이용해 이용자가 제공받은 API 서비스의 출력 결과에 광고를 게재할 수 있습니다. 다만, 이와 같이 광고를 게재하고자 할 경우에는 광고 게재 적용일로부터 30일 전에 개발자센터를 통해 공지합니다.<br />
            4. 이용자는 솔루션 등에 적용된 API의 결과화면과 함께 이용고객의 판매활동을 보조하는 서비스·솔루션 서비스 자체의 홍보목적 외의 광고를 게재할 수 없습니다.<br />
            5. 하나의 애플리케이션에는 하나의 애플리케이션 아이디 및 하나의 비밀번호만이 인증정보로 발급될 수 있으며, 인증정보는 발급일로부터 유효합니다.<br />
            6. 회사가 운영정책으로 정한 경우이거나, 이용자의 부주의로 인해 인증정보가 공개되거나 또는 이를 제3자가 취득할 가능성이 높다고 객관적으로 판단되는 경우, 회사는 이용자의 인증정보를 재설정하거나 파기할 수 있습니다. 단, 본 항에 따라 이용자의 인증정보를 재설정하거나 파기한 경우, 회사는 본 약관 제16조에 정한 수단으로 이용자에게 통지합니다.<br />
          </p>
          <h6>제 6 조 (이용고객 정보 취급)</h6>
          <p>
            1. 이용자는 회사의 사전 서면 동의가 있는 경우에만 이용고객 외의 제3자에게 이용고객의 데이터를 이전, 공유, 판매, 공개 또는 제공하는 것을 주요 목적으로 하는 애플리케이션을 발급받거나 사용할 수 있습니다.<br />
            2. 이용자는 API 서비스를 이용함에 있어 개발자센터를 통해 애플리케이션과 인증정보를 발급받아야 하며, 발급받은 인증정보를 이용하여 API 서비스를 제공받을 수 있습니다. 다만 주문 정보 등 일정한 이용고객의 정보에 대해서는 인증정보 발급 이외에 회사가 정하는 추가적인 인증절차를 거쳐야 API 서비스를 이용할 수 있습니다.<br />
          </p>
          <h6>제 7 조 (이용자의 권리 및 의무)</h6>
          <p>
            1. 이용자의 권리<br />
              가. 이용자는 본 약관에서 허용하는 범위 내에서 회사의 API 서비스를 사용할 수 있는 제한된 권리를 취득합니다.<br />
              나. 이용자는 이용자에게 발급된 애플리케이션을 이용하여 API 서비스를 이용할 수 있습니다. 단, 주문 정보 등과 같은 특정 데이터의 경우, 이용자는 애플리케이션 외에도, 회사가 정한 추가적인 신원 확인 절차 등 회사가 정하는 일정한 요건을 충족하여야 해당 데이터에 대한 API 서비스를 이용할 수 있습니다.<br />
            2. 이용자는 API 서비스를 이용함에 있어서 다음과 같은 의무를 부담합니다.<br />
              가. 이용자는 API 서비스 이용계약 체결 과정에 있어서 타인의 정보 혹은 허위의 정보를 제출하는 등 회사를 기망하는 행위를 하여서는 안되며, 이용계약 체결 과정에서 고지되는 회사의 정책을 준수하여야 합니다.<br />
              나. 이용자는 자신이 발급받은 애플리케이션 인증정보가 타인에 의하여 도용 당하지 않도록 관리하여야 합니다. 또한 이용자 ID, 비밀번호의 관리 의무는 이용자에게 있으며 이를 소홀히 하여 발생하는 모든 결과에 대한 책임 또한 이용자가 부담하며 회사는 이에 대하여 어떠한 책임도 부담하지 않습니다.<br />
              다. 이용자는 API 서비스를 사용할 때 다음 각 호를 준수해야 합니다.<br />
                i. API 서비스를 이용함에 있어 본 약관 및 운영정책을 준수합니다.<br />
                ii. API 서비스를 이용하여 제작한 솔루션을 개발 및 배포하거나, 이용고객이 플레이오토 서비스와 함께 사용할 수 있도록 하는 경우, 인증정보, API 서비스 결과 데이터 등 API 서비스를 통해 취득한 데이터의 기밀유지 의무를 준수해야 합니다.<br />
                iii. 서비스의 전부 또는 일부의 가용성, 성능 또는 기능을 모니터링하기 위한 목적 또는 유사한 벤치마킹 목적으로 API 서비스를 사용하지 않아야 합니다.<br />
                iv. API 서비스에 포함되거나 API 결과 데이터에 포함된 저작권, 소유권 또는 비밀유지 표시를 임의로 제거하지 않아야 합니다.<br />
                v. API 서비스와 관련하여 취득한 정보를 회사의 서면 동의 없이 추가광고 등의 목적으로 사용하거나, 외부에 유출하거나 권한 없는 제3자에게 이용하게 하여서는 아니되고, 위법하게 사용하거나 제3자에게 제공하여서는 안됩니다.<br />
                vi. 시스템을 업계 표준에 부합하도록 구성하여 API 서비스를 안전하게 이용하고, API 서비스를 통해 취득한 데이터에 대한 공개 또는 무단 사용을 방지하여야 하며, 보안상의 결함을 적시에 적절히 시정하고, 알려진 혹은 의심스러운 침입이나 침입자를 즉시 차단해야 합니다.<br />
              라. 이용자는 회사 및 제3자의 지식재산권 및 관련 권리를 침해하여서는 안 됩니다.<br />
            3. 이용자는 이용고객의 개인정보를 다음과 같이 안전하게 관리 및 보호하여야 합니다.<br />
              가. 이용자는 이용고객에게 솔루션 등을 제공함으로써 이용고객의 업무를 처리하게 되며, 이 과정에서 이용고객의 개인정보 처리업무를 위탁 처리하는 경우에는 개인정보보호법 상 수탁자의 지위에서 개인정보보호법 및 동법 시행령/시행규칙, 개인정보의 기술적·관리적 보호조치 기준(개인정보보호위원회 고시), 개인정보의 안전성 확보조치 기준, 기타 관련 법령에 명시된 바에 따라 이용고객의 개인정보를 보호 및 관리하는 등 관계 법령을 준수하여야 합니다.<br />
              나. 이용자는 이용고객의 판매활동을 보조하는 서비스를 개발, 구현 및 제공하는 범위 내에서만 이용고객이 위탁한 개인정보를 처리할 수 있으며, 이용자의 독자적인 이용목적을 위해 개인정보를 처리할 수 없습니다.<br />
              다. 이용자는 개인정보를 훼손 · 변조 · 침해하거나 제3자에게 개인정보를 임의로 제공하거나 공개하여서는 아니되며, 이를 위해 개인정보를 취급하는 인력에게 필요한 교육을 시켜야 하고, 개인정보에 접근할 수 있는 인력을 최소한으로 유지 및 관리하여야 합니다.<br />
              라. 이용자는 ID 및 Password, 방화벽 등과 같은 접근통제시스템를 설치하여야 하고, 위 접근통제시스템의 운영을 통해 접근 권한이 없는 인력이 해당 설비에 접근할 수 없도록 하여야 하며, ID 및 Password, 인증정보 등의 공유·누설 금지 및 주기적인 Password 변경과 같은 ID 및 Password 기타 개인정보 보호를 위한 보안정책을 수립·시행해야 합니다.<br />
              마. 이용자는 백신 프로그램을 설치, 운영하여 회사 및 이용고객에게 유포될 가능성이 있는 컴퓨터 바이러스의 유포를 방지하고, 암호 알고리즘 등의 이용을 통하여 개인정보를 네트워크상에서 안전하게 전송할 수 있도록 보안조치를 취해야 합니다.<br />
              바. 이용자는 개인정보의 안전성 확보조치 기준 고시 및 개인정보의 기술적·관리적 보호조치 기준 고시에서 암호화를 요구하는 개인정보에 대해 암호화하여 보관하여야 합니다.<br />
              사. 이용자는 이용고객으로부터 그 처리를 위탁 받은 제3자의 개인정보의 처리업무위탁 목적이 달성된 경우 지체 없이 처리를 위탁받은 제3자의 개인정보를 파기하여야 합니다.<br />
              아. 이용자는 API 서비스를 이용하여 서비스를 제공하는 과정에서 이용고객의 개인정보가 회사에 전송되지 않도록 하여야 합니다. 단, 서비스 제공과정에서 불가피하게 이용고객의 개인정보가 회사에 제공되어야 하는 경우, 회사의 요청에 따라 개인정보보호법 등 관련 법령의 준수를 위하여 위탁자인 이용고객과 협의하여 개인정보 처리위탁 또는 제3자 제공에 따른 적법한 고지 또는 동의 절차를 거쳐야 하며, 회사가 그와 같은 절차의 이행에 대한 확인을 할 수 있도록 하여야 합니다.<br />
              자. 이용자는 이용자 자신이 개인정보보호의 의무를 위반하여 발생하게 되는 모든 법적 책임을 부담하며, 그와 관련하여 회사의 귀책사유가 없었음에도 불구하고 회사를 상대로 소송이 제기되는 등 분쟁이 발생하는 경우에는 자신의 비용과 노력으로 회사를 면책하고 방어하여야 합니다.<br />
          </p>
          <h6>제 8 조 (이용자의 금지행위)</h6>
          <p>
            1. 이용자는 아래의 목적으로는 API 서비스를 이용할 수 없습니다.<br />
              가. API 서비스를 통해 제공받은 데이터를 가공하지 아니하고 그대로 중개, 제공하는 것임에도 불구하고 이용고객들로부터 해당 데이터 제공에 대한 대가를 제공받을 목적<br />
              나. API 서비스를 이용하여 플레이오토 서비스를 이용하는 자가 의도하지 않는 기능을 사용하게 하거나 또는 화면을 추가하는 등 임의로 플레이오토 서비스를 변경하기 위한 목적<br />
            2. 이용자는 API 서비스를 이용함에 있어서 다음과 같은 행위를 하는 것이 금지됩니다.<br />
              가. API 서비스의 제공주체 및 제휴관계 등에 대해서 오인될 수 있는 외관을 형성하는 행위(예를 들어, 이용자를 회사의 임직원 혹은 계열회사 혹은 회사로 사칭하거나 이용자를 API 서비스의 제공자로 표시하거나 그와 같은 오인될 수 있는 외관을 형성하는 행위 및 이용자와 회사 사이에 제휴 관계가 존재하는 것으로 오인될 수 있는 외관을 형성하는 행위)<br />
              나. API 서비스를 모방하거나, 회사의 등록상표, 브랜드 등을 모방, 도용하는 행위<br />
              다. 플레이오토의 BI 가이드(플레이오토API센터를 통해 별도 제공되는 가이드가 있을 경우 이를 포함한다.)에 반하여 API 서비스를 이용하는 행위<br />
              라. API 서비스를 스팸의 전송 또는 피싱, 스파이웨어, 애드웨어 기타 불법적인 프로그램 전송을 위하여 이용하는 행위<br />
              마. 이용고객의 지시 또는 위탁에 의한 통신판매 사업 보조목적과 무관한 작업을 수행하기 위한 기능과 API 서비스를 함께 제공하는 다음 각호의 행위 및 이와 유사한 행위<br />
                i. 이용고객의 주문 데이터 상 지역정보를 수집하여 해당 정보를 이용자 자신의 광고에 이용하는 행위<br />
                ii. 각 API의 제공 취지나 목적에 맞지 않게 API 결과 데이터를 수집하여 별도 데이터베이스로 관리하는 행위(이용고객 주문 데이터 API로 취득한 데이터를 주문배송 처리와 무관한 서비스에 이용하는 행위 등을 포함)<br />
                iii. API 서비스를 이용하여 취득한 정보 (플레이오토 회원, 이용고객 계정 관련 정보, 플레이오토 서비스의 콘텐츠 내용 등 일체의 데이터를 포함함)를 본 약관에서 허용한 범위를 넘어서서 무단으로 복제, 저장(캐시 행위 포함), 가공, 배포 등 이용하거나 제3자에게 제공하는 행위<br />
              바. 다음과 같은 내용을 포함하는 서비스 등에 API 서비스를 적용하는 행위<br />
                i. 정보통신망 이용촉진 및 정보보호 등에 관한 법률 제44조의7 제1항 각호에 해당하는 불법정보<br />
                ii. 청소년보호법 상 청소년유해매체물 혹은 방송통신심의위원회의 인터넷 내용등급기준 2등급 이상의 선정적, 폭력적, 혐오적, 반사회적, 비도덕적, 불법적인 내용<br />
                iii. 아동·청소년을 디지털 성범죄의 대상으로 하거나 비인격화 하는 내용<br />
                iv. 회사 또는 제3자의 저작권 등 지식재산권을 침해하는 내용<br />
                v. 회사 또는 제3자의 명예를 훼손하거나 제3자를 모욕하는 내용<br />
                vi. 타인의 초상권, 성명권 등 인격권을 침해하는 내용<br />
                vii. 개인정보보호법 등에 반하여 타인의 개인정보 또는 사생활을 침해하는 내용<br />
                viii. 기타 범죄를 교사하거나 현행법에 위반되는 내용<br />
              사. 기타 회사의 업무에 방해가 되는 행위 및 회사의 명예를 손상시키는 행위<br />
              아. 회사 또는 제3자의 서버, 보안, 네트워크, 데이터, 애플리케이션 또는 기타 재산이나 서비스를 방해, 제한, 훼손, 손상하거나 또는 무단으로 접근하는 등의 행위<br />
              자. 회사의 명시적인 서면 승인 없이 회사의 서비스와 관련하여 사용되는 기능에 대해 회사와 협의 없이 추가적인 절차를 도입하는 등의 간섭행위<br />
              차. 제한된 API 서비스 이용량을 늘리거나, 오로지 데이터를 추출·저장하기 위한 목적으로 동일한 목적의 솔루션 등 다수의 애플리케이션을 사용하여 API 서비스를 이용하는 행위<br />
              카. API의 일부 또는 전부를 수정, 번역, 역설계, 분해, 분석, 디컴파일, 복사 또는 재생산하는 행위<br />
              타. 직접적인 데이터베이스 접근을 방지하거나 그러한 취지의 도구 또는 제품을 제조하기 위한 기술적 조치를 우회하는 행위<br />
              파. 애플리케이션에 필요한 API에 적정한 데이터 양 이상을 요청하는 행위<br />
              하. 애플리케이션 이용 시 인증정보 등 고유 식별자를 무단 변조/변경하거나 솔루션에서 발생하는 쿼리의 출처를 모호하게 하거나 변경하는 행위<br />
              가. 웹훅 가입 또는 설치를 요청하는 행위
          </p>
          <h6>제 9 조 (모니터링)</h6>
          <p>
            회사는 이용자의 법령 위반, 본 약관 위반, 기타 금지행위 여부에 대한 모니터링을 실시할 수 있으며, 이용자는 회사의 모니터링 업무에 성실하게 협조하여야 하고, 회사가 요청하는 소명 사항에 대한 확인 및 답변과 증빙 자료를 제출할 의무가 있습니다.
          </p>
          <h6>제 10 조 (지식재산권)</h6>
          <p>
            1. 회사가 제공하는 API 서비스 및 API 서비스와 관련하여 회사가 제작한 프로그램, 디자인, 상표 등의 지식재산권 등 제반 권리는 회사에게 귀속되며, API 서비스를 이용한 결과 데이터의 저작권 등 제반 권리는 회사 또는 원저작자 등 적법한 권리자에게 있습니다.<br />
            2. 회사의 이용자의 API 서비스 이용에 대한 승낙은 API 서비스 및 결과 데이터에 대한 본 약관에 따른 제한된 사용권의 부여만을 의미할 뿐, 어떠한 경우에도 (그와 같은 제한된 사용권의 부여를 넘어선) 이용자의 API 서비스와 관련된 지식재산권 혹은 관련 권리의 취득 혹은 결과 데이터에 대한 권리의 취득을 의미하지는 않습니다.<br />
            3. 회사의 API 서비스 이용 승낙은 회사가 이용자에게 회사의 등록상표 또는 API 서비스 외 저작물에 대한 이용권 등 기타 여하한 권리를 부여하는 것이 아닙니다.<br />
            4. 이용자는 회사가 사전에 서면으로 별도 허락한 경우에 한하여만 회사의 등록상표, 디자인 또는 저작물 등 회사의 지식재산권을 사용할 수 있으며, 그러한 경우에도 회사의 지식재산권 이용기준을 준수하여야 하고 임의로 이를 변형, 변경하거나 훼손하여서는 아니되며, 회사는 이용자가 회사의 지식재산권 이용기준을 준수하지 않음으로써 발생하게 되는 어떠한 손해에 대하여도 법적 책임을 부담하지 않습니다.<br />
          </p>
          <h6>제 11 조 (서비스의 변경, 중단 및 제한)</h6>
          <p>
            1. 회사는 회사의 정책 및 운영의 필요상 API 서비스(서비스의 범위, 사용빈도, 사용량 등을 포함하되, 이에 한정되지 아니함)를 변경할 수 있습니다.<br />
            2. API 서비스는 기본적으로 연중무휴, 365일 24시간 운영을 원칙으로 합니다. 다만, 회사는 다음과 같은 경우 API 서비스의 전부 혹은 일부를 중단 혹은 이용을 제한할 수 있습니다.<br />
              가. 국가비상사태, 정전 및 그에 준하는 상황이 발생한 경우<br />
              나. 시스템 정기점검 또는 증설 및 교체, 새로운 API 서비스의 적용 등을 위해 필요한 경우<br />
              다. API 서비스 이용량 증가 및 과도한 호출 등으로 시스템의 장애가 발생한 경우<br />
              라. 기타 회사의 업무상 혹은 기술상 API 서비스를 유지하기 어려운 상황이 발생한 경우<br />
            3. 본조 제1항 내지 제2항에 따라 API 서비스를 변경하거나 중단 혹은 그 이용을 제한할 경우, 회사는 그에 대해서 사전에 개발자센터를 통해 공지합니다. 다만, 사전에 공지할 수 없는 부득이한 사유가 있는 경우에는 사후에 그 사유와 함께 공지합니다.<br />
            4. 회사는 본 약관 제9조에 의한 모니터링, 정부기관이나 수사기관의 요청, 제3자의 신고 등을 통해 이용자가 관련 법령 또는 본 약관에서 정한 이용자의 의무(개별 API별 특약조건 포함)를 위반한 것을 확인하였거나 또는 본 약관상 금지행위를 하였거나, 그러한 위법, 부당한 행위가 있는 것으로 의심 될 만한 상당한 이유가 있는 경우, 경고, 소명 요청, API 서비스 이용제한(API 제공을 중단하거나 발급한 인증 정보의 사용 제한, 정지 혹은 삭제 등을 포함하되 이에 한정되지 아니함), 민사상 손해배상 청구, 형사상 고소, 고발 등 필요한 조치를 취할 수 있으며, 필요한 경우 본 약관 제15조에 따라 이용계약을 해지할 수 있습니다.<br />
            5. 회사는 본조 제4항에서 정한 조치를 하기 이전에 필요하다고 판단하는 경우에는 해당 이용자에 대하여 의견 청취 절차를 진행할 수 있으며, 이용자는 30일 이내에 소명하여야 합니다.<br />
          </p>
          <h6>제 12 조 (회사의 책임 제한)</h6>
          <p>
            1. 회사는 API 서비스의 최신성, 정확성, 적합성, 신뢰성, 정당성, 적법성, 지속성 등을 보증하지는 않으며, 회사의 고의 또는 중과실이 없는 한 API 서비스의 품질, 사용불능, 오류 등으로 인하여 이용자에게 발생한 손해에 대해서 책임을 지지 않습니다.<br />
            2. 회사는 본 약관, 개별 API별 특약조건, API 서비스 운영정책 등 회사의 정책을 준수하지 아니한 이용자의 API 서비스 이용으로 인한 결과에 대하여 어떠한 법적 책임도 지지 않습니다.<br />
            3. 회사는 이용자 상호 간 및 이용자와 제3자(이용고객을 포함하며, 이하 본 항에서 같음) 간에 API 서비스를 매개로 발생한 분쟁에 대하여 개입할 의무가 없으며, 회사의 고의 또는 중과실이 없는 한 해당 분쟁에서의 손해에 대한 배상 책임 또한 지지 않습니다. 만약 이용자와 분쟁 중인 제3자가 회사를 상대로 이의를 제기할 경우 이용자는 자신의 비용과 책임으로 문제를 직접 해결하고 회사를 면책 시켜야 하며 회사에 발생한 손해를 배상하여야 합니다.<br />
            4. 회사는 천재지변, 폭동, 국내/해외 정부기관의 규제 또는 사회통념상 이에 준하는 불가항력적인 사유 혹은 시스템 점검/업그레이드 과정에서 예상치 못한 기술적 장애 혹은 시스템상 예상치 못한 장애로 인하여 일시적 또는 종국적으로 API 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임을 면합니다. 이 경우 회사는 개발자센터를 통해 공지하거나 기타의 방법으로 이용자들에게 통지합니다.<br />
            5. 회사는 이용자가 회사의 API 서비스를 이용하여 기대하는 수익을 얻지 못하거나 상실한 것에 대하여 어떠한 책임도 지지 않습니다.<br />
            6. 이용자는 기타 관련 법령, 본 약관 및 개별 API 문서 등의 변경에 따른 개발자센터 공지사항, 회사의 개별 통지 등을 숙지하고 지속적으로 확인하여야 하며, 이용자가 공지 및 통지 확인 의무를 게을리하여 발생한 피해에 대해서 회사는 책임을 지지 아니합니다.<br />
          </p>
          <h6>제 13 조 (개인정보의 보호 등)</h6>
          <p>
            1. 회사는 이용자가 본 약관에 동의한 시점 이후 이용자의 통합홈페이지 가입정보를 개발자센터 이용을 위해 수집합니다.<br />
            2. 회사는 API 서비스를 운영함에 있어 이용자에 대한 각종 고지사항의 전달, 이용실태에 대한 조사, 서비스 개선을 위한 연락 등을 위하여 이용자의 연락처 등 개인정보를 수집, 보관할 수 있습니다.<br />
            3. 회사는 이용자의 개인정보 수집, 제공 및 이용 동의에 따라 이용자가 직접 제공하거나 이용자로부터 수집한 정보만을 보관하며, 회사의 개인정보 처리방침(https://www.plto.com/policy/Policy/?tabIndex=2)에 따라 이를 철저히 관리합니다.<br />
            4. 회사는 API 서비스의 제공을 종국적으로 중단하거나 이용자가 개인정보 수집 및 이용 동의를 철회한 경우에는 관계 법령 및 회사의 개인정보 처리방침에 따라 이용자의 개인정보를 파기합니다.<br />
            5. 회사는 기타 개인정보보호 법령에서 정하고 있는 내용을 준수합니다.<br />
          </p>
          <h6>제 14 조 (사용이력의 보호, 보관 및 활용 등)</h6>
          <p>
            1. 회사는 API 서비스를 제공함에 따라 발생하는 API 호출 시간, 호출내용 등 이용자의 API 이용에 관한 이용기록(이하 ‘로그정보’)을 최대 30일간 보관한 후 영구적으로 삭제합니다. 또한 회사는 그와 같이 보관하고 있는 로그정보를 API 서비스의 성능 향상을 위해 활용할 수 있습니다.<br />
            2. 이용자는 회사가 전 항에 따라 로그정보를 적법하게 보관 및 활용할 수 있도록 이용자의 약관 등을 통해 자신의 이용고객으로부터 이용고객이 이용자의 솔루션 등을 이용하는 과정에서 생성되는 정보 중 일부(이용자가 회사의 API 서비스를 이용함에 따라 회사로 제공하는 정보)가 회사에 제공, 보관, 활용될 수 있다는 점에 대해서 동의를 얻어야 하며, 회사가 이용자의 이용고객에 대한 동의 획득에 대해서 확인할 수 있도록 하여야 합니다.<br />
          </p>
          <h6>제 15 조 (이용계약의 해지)</h6>
          <p>
            1. 이용자는 개발자센터에서 제공하는 해지절차나, 고객센터를 통한 신청으로 API 서비스 이용계약을 해지할 수 있습니다. 단, 이용자가 API 서비스를 이용 중인 경우 해지 신청이 제한될 수 있습니다.<br />
            2. 회사는 다음 각호의 사유가 발생한 경우, 본 약관 제4조에 따라 체결된 API 서비스 이용계약을 통보 없이 해지할 수 있습니다. 이로 인해 발행하는 손실에 대한 책임을 부담하지 않습니다.<br />
              가. 이용자가 본 약관 및 운영정책을 위반하고 회사로부터 그 시정을 요구 받은 후 7일 이내에 이를 시정하지 아니한 경우<br />
              나. 금융기관의 거래정지, 어음이나 수표의 부도, 회생 및 파산절차의 개시, 영업정지 및 취소 등의 행정처분, 주요 자산에 대한 (가)압류 등 보전처분, 영업양도 및 합병 등으로 API 서비스 이용 계약의 이행이 불가능한 경우<br />
              다. 관련 법령을 위반하거나 이용자의 책임 있는 사유로 인하여 회사가 명예 실추 등 유·무형적 손해를 입은 경우<br />
              라. 본 약관 제4조 제2항의 승낙 거부 사유가 있음이 확인된 경우<br />
              마. 기타 위 가.호 내지 라.호에 준할 정도로 객관적으로 API 서비스 이용계약의 유지가 어려운 경우<br />
              바. 6개월 이상 이용자의 솔루션 등에서 API 서비스를 이용한 기록이 없을 경우<br />
              사. 이용자의 솔루션 사용 기간이 만료일로부터 14일이 경과했을 경우<br />
              아. 플레이오토 통합 홈페이지(https://www.plto.com/)에서 회원 탈퇴를 했을 경우<br />
            3. 전항에 따라 회사가 이용계약을 해지하는 경우, 별도의 이의 신청 기간을 부여하지 않을 수 있습니다.<br />
            4. 본 조에 따라 회사가 이용계약을 해지하는 경우에 있어서, 그 해지의 사유가 관련 법령 위반이거나 또는 본 약관의 심각한 위반 등에 해당하여 객관적으로 회사와 이용자 사이의 신뢰관계의 회복이 어려운 정도의 현저한 것일 경우에는, 회사는 이용자가 회사와 이용계약을 체결하고 이용하는 회사의 다른 서비스(쇼핑몰 통합 솔루션 서비스 등)에 대하여 기 이용을 제한하거나, 이용계약을 직권 해지할 수 있습니다.<br />
            5. 이용계약 해지 시 이용자의 로그정보, 자료 등이 삭제될 수 있으며, 본 조 제2항에 따른 계약 해지로 인해 발생한 이용자의 손해에 대해서 회사는 책임을 부담하지 아니합니다.<br />
            6. 본 조에 의한 이용계약의 해지는 기 발생한 양 당사자의 권리관계 및 손해배상 청구권에 영향을 미치지 아니합니다.<br />
          </p>
          <h6>제 16 조 (통지의 방법)</h6>
          <p>
            1. 회사가 이용자 전체에 대한 통지를 하여야 하는 경우, 회사는 개발자센터 내 공지사항에 이를 게재함으로써 이용자 모두에 대하여 통지를 한 것으로 갈음할 수 있습니다.<br />
            2. 회사가 이용자에게 개별적으로 통지를 하여야 하는 경우, 회사는 이용자가 통합홈페이지 또는API 서비스 사용신청 시에 등록한 전자우편 주소, 카카오톡 메신저, 전화번호 혹은 SMS 등의 수단으로 통지합니다.<br />
            3. 이용자는 회사의 통지를 수신할 수 있도록 전자우편주소 및 휴대전화번호 등 회사에 제공한 자신의 정보를 항상 최신으로 유지하여야 하며, 이를 이행하지 않아 이용자에게 발생한 불이익에 대해서 회사는 책임을 부담하지 않습니다.<br />
            4. 회사는 통합홈페이지 또는 API 서비스 사용신청 시에 등록된 이용자의 연락처(본 조 제2항의 수단을 포함함)가 부정확하거나 이용자의 장기 부재로 인해 통지를 받지 못하여 발생하는 손실에 대한 책임을 부담하지 않습니다.<br />
          </p>
          <h6>제 17 조 (양도의 금지)</h6>
          <p>
            이용자는 어떠한 경우에도 본 약관에 의해 체결된 계약 상 권리 및 의무 등 계약 상 지위를 회사의 사전 서면 동의 없이 제3자에게 양도할 수 없습니다.
          </p>
          <h6>제 18 조 (약관의 해석)</h6>
          <p>
            1. 회사는 본 약관 외에 개별 API 문서 등을 통해 개별 API별 특약조건이나 운영정책을 둘 수 있습니다. 개별 API별 특약조건의 내용과 본 약관의 내용이 충돌하는 경우, 개별 API별 특약조건이 우선하여 적용됩니다.<br />
            2. 본 약관에서 정하지 않은 사항이나 해석에 대하여는 플레이오토 이용약관(https://www.plto.com/policy/Policy/)과 관련 운영정책, 관련 법령 및 상 관례에 따릅니다.<br />
          </p>
          <h6>제 19 조 (준거법 및 재판관할)</h6>
          <p>
            1. 본 약관의 준거법은 대한민국 법으로 합니다.<br />
            2. 회사와 이용자 간에 발생한 본 약관 및 API 서비스에 관한 법적 분쟁에 관한 재판관할은 민사소송법에 따릅니다.<br />
          </p>
          <h6>
            {'<부칙>'}
          </h6>
          <p>1. 본 약관은 2023년 2월 21일부터 시행됩니다.</p>
        </div>
        <div className={classes.footer}>
          <button onClick={props.closeModal}>
            닫기
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default TermsDetailModal;
