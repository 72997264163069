import { useNavigate, useParams } from 'react-router-dom';
import classes from './ErrorviewPage.module.css';
import { findPageData } from '../../../util/HelperFunctions';
import AccordionSideNav from '../../layout/SideNav/AccordionSideNav/AccordionSideNav';
import PageDataList from '../../../store/PageDetailData/PageDetailData';
import { useContext, useEffect, useState } from 'react';
import useValidatePageAccess from 'hooks/useValidatePageAccess';
import AuthContext from '../../../store/auth-context';

/**
 * 에러 문서 페이지 컴포넌트
 */
const ErrorviewPage = () => {
  const authCtx = useContext (AuthContext);
  const newParams = useParams();
  const [params, setParams] = useState ('');
  const pageDataRaw = findPageData(params['*'], PageDataList(authCtx.userInfo));
  const navigate = useNavigate ();
  const memberNo = Number(authCtx.memberNo);
  const affNo = Number(authCtx.userInfo?.aff_no || 0);

  useValidatePageAccess (authCtx.isLoggedIn 
    && ((!pageDataRaw.data[0].authorized_m_no?.length && !pageDataRaw.data[0].authorized_aff_no?.length) ||
      ((pageDataRaw.data[0].authorized_m_no?.length && pageDataRaw.data[0].authorized_m_no.includes(memberNo)) || (pageDataRaw.data[0].authorized_aff_no?.length && pageDataRaw.data[0].authorized_aff_no.includes(affNo))) ||
      ((pageDataRaw.data[0].authorized_m_no?.length ? pageDataRaw.data[0].authorized_m_no.includes(memberNo) : true) && (pageDataRaw.data[0].authorized_aff_no?.length ? pageDataRaw.data[0].authorized_aff_no.includes(affNo) : true)))
    && (pageDataRaw.data[0].unrecognized_aff_no?.length ? !pageDataRaw.data[0].unrecognized_aff_no.includes(affNo) : true)
  );

  // 페이지가 변경되면 API 테스트 섹션에서 데이터 리셋
  useEffect (() => {
    setParams(newParams);
  }, [authCtx, newParams, pageDataRaw, memberNo, affNo, navigate]);

  const filteredPageDataList = process.env.NODE_ENV === 'development' ? PageDataList(authCtx.userInfo) : PageDataList(authCtx.userInfo).map(
    actions => (
      {...actions,
        children:
        actions.children
          .filter(action => {
            if (actions.title === '에러코드') {
              return !(action.unrecognized_aff_no?.length && action.unrecognized_aff_no.includes(affNo))
            }
            else if (!action.data[0].authorized_m_no?.length && !action.data[0].authorized_aff_no?.length) {
              return true;
            } else if (action.data[0].authorized_m_no?.length && action.data[0].authorized_aff_no?.length) {
              return action.data[0].authorized_m_no.includes(memberNo) || action.data[0].authorized_aff_no.includes(affNo)
            } else {
              return (action.data[0].authorized_m_no?.length ? action.data[0].authorized_m_no.includes(memberNo) : true) && (action.data[0].authorized_aff_no?.length ? action.data[0].authorized_aff_no.includes(affNo) : true)
            }
          })
      })
  ).filter(actions =>
    (actions.children.length || actions.alwaysShow) &&
    (!actions.unrecognized_aff_no || !actions.unrecognized_aff_no.length || !actions.unrecognized_aff_no.includes(affNo)));

  return (
    <>
      <AccordionSideNav data={filteredPageDataList} />
      <div className={classes.container}>
        <div className={classes.section}>
          <h1 className={classes['sub-heading']}>{pageDataRaw.title} 에러</h1>
          <table>
            <colgroup>
              {[170, 700].map((width, i) => <col width={width} key={i} />)}
            </colgroup>
            <tbody>
              <tr>
                <th>코드<br />(Code)</th>
                <th>설명<br />(Description)</th>
              </tr>
              {
                pageDataRaw.data.map((item, i) => (
                  <tr key={i}>
                    <td className='text-center'>{item.code}</td>
                    <td>{item.desc}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ErrorviewPage;