import AceEditor from 'react-ace';

/**
 * OpenAPI 테스트 호출 및 response 확인 시 사용되는 에디터 컴포넌트
 */
const ApiCallEditor = (props) => {
  return (
    <AceEditor
      mode='json'
      theme='chrome'
      onChange={props.onChange}
      name='ace-editor'
      editorProps={{ $blockScrolling: true }}
      width='100%'
      height={props.height || '300px'}
      showPrintMargin={false}
      value={props.value}
      wrapEnabled={true}
      fontSize='14px'
      setOptions={{
        useWorker: false
      }}
  />
  );
};

export default ApiCallEditor;
