import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchButton from '../UI/SearchButton';
import classes from './Header.module.css';
import AuthContext from '../../store/auth-context';
import Logo from '../UI/Logo';
import Swal from 'sweetalert2';

/**
 * 화면 상단 네비게이션 컴포넌트
 */
const Header = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;
  const [enableSubNavigation, setEnableSubNavigation] = useState(true);

  async function logout() {
    const confirm = await Swal.fire({
      title: '확인',
      html: '로그아웃 하시겠습니까?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
    });

    if (confirm.isConfirmed) {
      authCtx.logout();
      navigate('/');
    }
  }

  function login() {
    navigate('/login');
  }

  function navigatePage(site) {
    closeSubNavigation();
    if (
      ['inquiry', 'doc/인증%20토큰%20발급', 'notice', 'termsAgree'].includes(site) &&
      !authCtx.isLoggedIn
    ) {
      return Swal.fire({
        icon: 'error',
        text: '해당 기능은 로그인 후 이용 가능합니다.',
        confirmButtonColor: '#3085d6',
      }).then(() => {navigate('/login')});
    }

    // 사용신청완료시 신청현황 페이지로 이동.
    if (site === 'termsAgree' && authCtx.userInfo.api_key) {
      site = 'applyStatus';
    }

    navigate(`/${site}`);
  }

  function closeSubNavigation() {
    setEnableSubNavigation(false);
    setTimeout(() => {
      setEnableSubNavigation(true);
    });
  }

  return (
    <header className={classes.container}>
      <Logo />
      <nav>
        <ul className={classes.gnb}>
          <li className={classes['sub-menu']}>
            <button onClick={() => navigatePage('overview')}>이용 가이드</button>
            <div
              className={
                classes['dropdown-content'] +
                ' ' +
                (enableSubNavigation ? '' : classes.hide)
              }
            >
              <button onClick={() => navigatePage('overview')}>소개</button>
              <button onClick={() => navigatePage('instruction')}>
                시작하기
              </button>
              <button onClick={() => navigatePage('limit')}>호출제한</button>
              <button onClick={() => navigatePage('version')}>버전관리</button>
            </div>
          </li>
          <li>
            <button
              onClick={() => {
                navigatePage('doc/인증%20토큰%20발급');
              }}
            >
              API 문서
            </button>
          </li>
          <li className={classes['sub-menu']}>
            <button
              onClick={() => {
                navigatePage('notice');
              }}
            >
              고객지원
            </button>
            <div
              className={
                classes['dropdown-content'] +
                ' ' +
                (enableSubNavigation ? '' : classes.hide)
              }
            >
              <button onClick={() => navigatePage('notice')}>공지사항</button>
              <button onClick={() => navigatePage('inquiry')}>1:1문의</button>
            </div>
          </li>
          <li>
            <button
              onClick={() => {
                navigatePage('termsAgree');
              }}
            >
              사용신청
            </button>
          </li>
        </ul>
        <ul className={classes.snb}>
          {isLoggedIn && (
            <li>
              <SearchButton />
            </li>
          )}
          <li>
            {isLoggedIn ? (
              <div className={classes.loginNav}>
                {
                /**
                 * mypage 주석 (추후 사용할때 여기서 사용)
                 */
                <button
                  onClick={() => {
                    navigatePage('mypage');
                  }}
                >
                  내정보
                </button>}
                <div className={classes.loginNavContent}>
                  <span>{authCtx.userInfo?.username || ''}님</span>
                  <button onClick={logout}>로그아웃</button>
                </div>
              </div>
            ) : (
              <button onClick={login} className={classes.loginBtn}>로그인</button>
            )}
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
