import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './TermsAgreePage.module.css';
import TermsDetailModal from 'components/modals/TermsDetailModal/TermsDetailModal';
import useValidatePageAccess from 'hooks/useValidatePageAccess';

const TermsAgreePage = () => {
  const navigate = useNavigate();
  const [readTerms, setReadTerms] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  useValidatePageAccess();

  return (
    <>
      <div className={classes.container}>
        <h2>
          사용신청
          <p>아래 약관에 동의 후 API 신청 및 사용이 가능합니다.</p>
        </h2>
        <div className={classes.agreeWrap}>
          <h3>약관동의</h3>
          <div className={`${classes.terms} ${readTerms ? classes.read : ''}`}>
            <span onClick={() => setModalOpen(true)} style={{cursor: 'pointer'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28">
                <g>
                  <path d="M14 26.353A12.353 12.353 0 1 0 1.647 14 12.353 12.353 0 0 0 14 26.353ZM14 28a14 14 0 1 1 14-14 14 14 0 0 1-14 14Z" data-name="패스 814"/>
                  <path d="M8.547 13.553a.659.659 0 0 0-1.058.023 1.053 1.053 0 0 0 0 1.262l4.07 4.942a.659.659 0 0 0 1.058 0l7.926-9.625a1.052 1.052 0 0 0-.018-1.285.657.657 0 0 0-1.04 0l-7.4 8.982-3.541-4.3Z" data-name="패스 815"/>
                </g>
              </svg>
            </span>
            플레이오토 개발자센터 이용약관(필수)
            <button onClick={() => setModalOpen(true)}>약관보기</button>
          </div>
          <div className={classes.confirmBtn}>
            <button onClick={() => navigate('/apply')} disabled={!readTerms}>약관동의</button>
          </div>
        </div>
      </div>
      {modalOpen && <TermsDetailModal closeModal={() => { setReadTerms(true); setModalOpen(false) }} />}
    </>
  );
};

export default TermsAgreePage;