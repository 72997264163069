// 리퀘스트 헤더 문서 기본값
export const default_header = [
  {
    title: 'x-api-key',
    required: 'O',
    type: 'String',
    desc: '승인시 발급받은 API KEY',
  },
  {
    title: 'Authorization',
    required: 'O',
    type: 'String',
    desc: '인증토큰',
  },
];

// 리퀘스트 헤더 예시 기본값
export const default_header_example = {
  'x-api-key': 'ejlkiasoj..',
  Authorization: 'Token eyJhbGciOiJIUzU......',
};

// 리스폰스 에러 종류별 모음
export const default_error_list = {
  공통: [
    {
      code: 400,
      desc: '필수 파라미터 누락',
    },
    {
      code: 'e1001',
      desc: '사용자 정보가 조회되지 않습니다.',
    },
    {
      code: 'e1002',
      desc: '비활성 사용자 입니다.',
    },
    {
      code: 'e1003',
      desc: '탈퇴한 사용자 입니다.',
    },
    {
      code: 'e1004',
      desc: '승인되지않은 사용자 입니다.',
    },
    {
      code: 'e1005',
      desc: '사용자 인증에 실패하였습니다.',
    },
    {
      code: 'e1006',
      desc: 'OPEN-API 미승인 사용자 입니다. 1:1 문의를 통해 OPEN-API 승인 요청 후 사용해주십시오.',
    },
    {
      code: 'e1007',
      desc: '검색방식은 and, or 만 입력가능합니다.',
    },
    {
      code: 'e1008',
      desc: '검색타입은 exact, partial 만 입력가능합니다.',
    },
    {
      code: 'e1009',
      desc: 'uniq, inq_uniq, prod_no, ol_shop_no 중 하나는 필수입니다.',
    },
    {
      code: 'e1010',
      desc: '조회 시작일의 날짜의 형식이 올바르지 않습니다.',
    },
    {
      code: 'e1011',
      desc: '조회 종료일의 날짜의 형식이 올바르지 않습니다.',
    },
    {
      code: 'e1012',
      desc: '입력하신 쇼핑몰코드로 조회되는 쇼핑몰 정보가 없습니다.'
    },
    {
      code: 'e1013',
      desc: '이미 등록되어 있는 쇼핑몰 계정입니다.'
    },
    {
      code: 'e1014',
      desc: '이용중인 버전에서 사용할 수 있는 쇼핑몰 ID 수를 초과하였습니다.'
    },
    {
      code: 'e1015',
      desc: '해당 아이디로 조회되는 쇼핑몰 계정이 없습니다.'
    },
    {
      code: 'e1016',
      desc: '지원되지 않는 버전입니다.'
    },
    {
      code: 'e1017',
      desc: '응답 데이터가 10MB를 초과하여 조회에 실패했습니다. 조회를 분할해서 재시도 부탁드립니다.',
    },
    {
      code: 'e1018',
      desc: '타입이 올바르지 않습니다. SET, RELEASE 중 하나를 입력해주세요.',
    },
    {
      code: 'e1999',
      desc: '정의되지 않은 오류',
    }
  ],
  상품: [
    {
      code: 'e4001',
      desc: (
        <div>
          판매자관리코드에
          <strong>
            <code>'(작은따옴표)</code>
          </strong>
          ,
          <strong>
            <code>"(큰따옴표)</code>
          </strong>
          ,
          <strong>
            <code>`(억음부호)</code>
          </strong>
          ,
          <strong>
            <code>\(역슬래시)</code>
          </strong>
          는 사용이 불가능합니다.
        </div>
      ),
    },
    {
      code: 'e4002',
      desc: 'Number 형식으로 입력해주십시오.[parameter]',
    },
    {
      code: 'e4003',
      desc: (
        <div>
          판매자관리코드에 자동생성처리는 불가능합니다.
          <br />
          수정하시려는 상품의 판매자관리코드를 입력해주십시오.
        </div>
      ),
    },
    {
      code: 'e4004',
      desc: '사이트정보에 쇼핑몰코드, 쇼핑몰 아이디, 템플릿번호는 모두 필수입니다.',
    },
    {
      code: 'e4005',
      desc: (
        <div>
          상품명에 특수문자는
          <strong>
            <code>-</code>
          </strong>
          ,
          <strong>
            <code>_</code>
          </strong>
          ,
          <strong>
            <code>/</code>
          </strong>
          ,
          <strong>
            <code>~</code>
          </strong>
          ,
          <strong>
            <code>+</code>
          </strong>
          ,
          <strong>
            <code>.</code>
          </strong>
          ,
          <strong>
            <code>,</code>
          </strong>
          ,
          <strong>
            <code>()</code>
          </strong>
          ,
          <strong>
            <code>{}</code>
          </strong>
          ,
          <strong>
            <code>[]</code>
          </strong>
          ,
          <strong>
            <code>%</code>
          </strong>
          ,
          <strong>
            <code>&</code>
          </strong>
          ,
          <strong>
            <code>'</code>
          </strong>
          만 사용 가능합니다.
        </div>
      ),
    },
    {
      code: 'e4006',
      desc: '상품명은 최대 100Byte까지 입력 가능합니다.',
    },
    {
      code: 'e4007',
      desc: '옵션구분은 옵션없음, 조합형, 독립형만 입력가능합니다.',
    },
    {
      code: 'e4008',
      desc: '과세여부는 과세, 면세, 영세, 비과세만 입력가능합니다.',
    },
    {
      code: 'e4009',
      desc: '배송비 종류는 무료, 착불, 선결제만 입력가능합니다.',
    },
    {
      code: 'e4010',
      desc: '제조일은 YYYY-MM-DD 형식으로 입력해주십시오.',
    },
    {
      code: 'e4011',
      desc: '유효일은 YYYY-MM-DD 형식으로 입력해주십시오.',
    },
    {
      code: 'e4012',
      desc: '동일 계정에 동일 관리자상품코드는 사용이 불가능합니다.',
    },
    {
      code: 'e4013',
      desc: '동일 판매자관리코드로 이미 존재하는 쇼핑몰 상품이 있습니다.',
    },
    {
      code: 'e4014',
      desc: '존재하지 않는 카테고리입니다.',
    },
    {
      code: 'e4015',
      desc: '지원하지 않는 쇼핑몰이거나 쇼핑몰 정보가 잘못되었습니다.',
    },
    {
      code: 'e4016',
      desc: '존재하지 않는 템플릿입니다.',
    },
    {
      code: 'e4017',
      desc: '존재하지 않는 머리말/꼬리말 입니다.',
    },
    {
      code: 'e4018',
      desc: '템플릿 정보에 묶음배송비가 없습니다.',
    },
    {
      code: 'e4019',
      desc: '인증정보를 정확하게 입력하세요',
    },
    {
      code: 'e4020',
      desc: '인증번호 조회에 실패했습니다.',
    },
    {
      code: 'e4021',
      desc: '입력하신 원산지 번호가 잘못되었습니다.',
    },
    {
      code: 'e4022',
      desc: '대표 SKU상품 정보가 누락되었습니다.',
    },
    {
      code: 'e4023',
      desc: 'UPC/EAN코드를 정확하게 입력해주세요.',
    },
    {
      code: 'e4024',
      desc: '상품분류코드를 정확히 입력해주세요.',
    },
    {
      code: 'e4025',
      desc: '입력형 옵션에는 엔터가 불가능합니다.',
    },
    {
      code: 'e4026',
      desc: '같은 판매자관리코드로 개별등록시 카테고리는 같거나 없어야합니다.',
    },
    {
      code: 'e4027',
      desc: 'EMS 2.0(옥션, G마켓) 단일상품의 경우에는 입력형 옵션을 입력하실 수 없습니다.',
    },
    {
      code: 'e4028',
      desc: '이미지 다운로드에 실패했습니다.',
    },
    {
      code: 'e4029',
      desc: '정상적인 이미지가 아닙니다.',
    },
    {
      code: 'e4030',
      desc: '이미지가 제한크기 3MB보다 큽니다.',
    },
    {
      code: 'e4031',
      desc: '정상적인 이미지URL이 아닙니다. 공백이나 엔터를 확인해주세요.',
    },
    {
      code: 'e4032',
      desc: '단일상품인경우 독립형 옵션은 사용하실 수 없습니다.',
    },
    {
      code: 'e4033',
      desc: '상품이 조회되지 않습니다.',
    },
    {
      code: 'e4034',
      desc: '단일상품 옵션은 카테고리가 존재하는 상품만 수정이 가능합니다.',
    },
    {
      code: 'e4035',
      desc: '상품에 적용된 솔루션 카테고리에 해당 쇼핑몰의 카테고리 정보가 없습니다.',
    },
    {
      code: 'e4036',
      desc: '존재하지 않는 마스터상품입니다.',
    },
    {
      code: 'e4037',
      desc: '존재하지 않는 배송처 입니다.',
    },
    {
      code: 'e4038',
      desc: '대표 SKU상품이 설정되지 않은 상품입니다.',
    },
    {
      code: 'e4039',
      desc: '동일 쇼핑몰의 계정에 같은 쇼핑몰 상품코드가 존재합니다.',
    },
    {
      code: 'e4040',
      desc: '단일상품 등록은 다음의 쇼핑몰만 가능합니다. (옥션, 지마켓, 11번가, 위메프 2.0)',
    },
    {
      code: 'e4041',
      desc: '입력하신 솔루션 카테고리에 해당 쇼핑몰의 카테고리 정보가 없습니다.',
    },
    {
      code: 'e4042',
      desc: '키워드 정보는 배열로 입력해주셔야 합니다.',
    },
    {
      code: 'e4043',
      desc: '배송방법이 무료배송이 아닌경우 배송비를 입력해주셔야 합니다.',
    },
    {
      code: 'e4044',
      desc: '인증정보타입은 인증유형등록, 인증대상아님, 상세설명내등록 만 입력가능합니다.',
    },
    {
      code: 'e4045',
      desc: 'UPC/EAN 타입 입력값이 잘못되었습니다.',
    },
    {
      code: 'e4046',
      desc: '바코드는 영문, 숫자, 띄어쓰기와 특수문자 _ 만 입력가능합니다.',
    },
    {
      code: 'e4047',
      desc: '이미지 주소가 유효하지 않습니다.',
    },
    {
      code: 'e4048',
      desc: '단일상품인경우 상품명은 50 Byte를 초과할 수 없습니다.',
    },
    {
      code: 'e4049',
      desc: '입력하신 ol_shop_no 로 조회되는 온라인상품이 없습니다.',
    },
    {
      code: 'e4050',
      desc: '쇼핑몰 정보는 배열로 입력해주셔야 합니다.',
    },
    {
      code: 'e4051',
      desc: '상품정보제공고시 정보는 배열로 입력해주셔야 합니다.',
    },
    {
      code: 'e4052',
      desc: '조회 시작일의 날짜의 형식이 올바르지 않습니다.',
    },
    {
      code: 'e4053',
      desc: '조회 종료일의 날짜의 형식이 올바르지 않습니다.',
    },
    {
      code: 'e4054',
      desc: '검색날짜 타입이 올바르지 않습니다.',
    },
    {
      code: 'e4055',
      desc: '검색조건이 올바르지 않습니다.',
    },
    {
      code: 'e4056',
      desc: '검색타입이 올바르지 않습니다.',
    },
    {
      code: 'e4057',
      desc: '멀티검색조건이 올바르지 않습니다.',
    },
    {
      code: 'e4058',
      desc: '상품 판매 상태 조회값이 올바르지 않습니다.',
    },
    {
      code: '4061',
      desc: '상품은 최대 1500건까지 조회가능합니다.',
    },
    {
      code: 'e4062',
      desc: '유효하지 않은 HS코드 입니다.',
    },
    {
      code: 'e4064',
      desc: '29CM 상품의 경우 상품 상세 이미지는 필수입니다.',
    },
    {
      code: 'e4070',
      desc: '조회되지않는 sku코드 또는 세트번호가 있습니다. 존재하는 SKU인지 확인해주세요.',
    },
    {
      code: 'e4071',
      desc: '조회시작 카운트가 올바르지 않습니다.',
    },
    {
      code: 'e4072',
      desc: '조회 갯수가 올바르지 않습니다.',
    },
    {
      code: 'e4073',
      desc: '위메프2.0 상품은 단일상품으로만 등록, 수정이 가능합니다.',
    },
    {
      code: 'e4074',
      desc: '사용가능한 옵션값이 아닙니다.',
    },
    {
      code: 'e4075',
      desc: '옵션 정보는 필수값입니다.',
    },
    {
      code: 'e4076',
      desc: '옵션상태는 정상, 품절만 입력가능합니다.',
    },
    {
      code: 'e4077',
      desc: '위메프2.0은 3가지 옵션명을 사용할 수 없습니다.',
    },
    {
      code: 'e4078',
      desc: '이미 등록된 상품정보가 있습니다.',
    },
    {
      code: 'e4079',
      desc: '멀티검색값이 유효하지 않습니다.',
    },
    {
      code: 'e4080',
      desc: '옵션사용여부는 빈값 또는 Y, N 중에 하나의 값으로 입력해 주십시오.',
    },
    {
      code: 'e4081',
      desc: '사용가능한 옵션명이 아닙니다.',
    },
    {
      code: 'e4082',
      desc: '해당 상품정보제공고시 내 필수값이 없습니다. (필요 항목 : 필요 항목 키값)',
    },
    {
      code: 'e4999',
      desc: '정의되지 않은 오류',
    },
  ],
  주문: [
    {
      code: 400,
      desc: '필수 파라미터 누락',
    },
    {
      code: 'e2001',
      desc: '[출고관리]메뉴에서 조회되지 않는 묶음고유번호입니다.',
    },
    {
      code: 'e2002',
      desc: '묶음번호는 필수 입니다.',
    },
    {
      code: 'e2003',
      desc: '유효한 택배사 코드가 아닙니다.',
    },
    {
      code: 'e2004',
      desc: '운송장번호는 필수 입니다.',
    },
    {
      code: 'e2005',
      desc: '조회되지 않는 묶음고유번호입니다.',
    },
    {
      code: 'e2006',
      desc: '동일한 묶음번호에는 동일한 택배사/운송장번호를 입력하여주십시오.',
    },
    {
      code: 'e2007',
      desc: '배송정보가 이미 입력되어있습니다.',
    },
    {
      code: 'e2008',
      desc: '입력한 묶음번호로 조회되는 주문이 없습니다.',
    },
    {
      code: 'e2009',
      desc: '신규주문 상태가 아닌 주문건이 포함되어있습니다. 신규주문 상태의 주문건만 출고지시 처리가 가능합니다.',
    },
    {
      code: 'e2010',
      desc: '쇼핑몰 주문번호는 40byte 까지만 입력이 가능합니다.',
    },
    {
      code: 'e2011',
      desc: `주문자명은 한글, 영문, 숫자, 공백, - _ / ~ + , . ( ) { } [ ] % & ' 만 입력가능합니다.`,
    },
    {
      code: 'e2012',
      desc: '주문자이메일을 정확하게 입력해주십시오.',
    },
    {
      code: 'e2013',
      desc: '주문자전화번호는 숫자, 하이픈(-)만 입력가능합니다.',
    },
    {
      code: 'e2014',
      desc: '주문자핸드폰번호는 숫자, 하이픈(-)만 입력가능합니다.',
    },
    {
      code: 'e2015',
      desc: `수령자명은 한글, 영문, 숫자, 공백, - _ / ~ + , . ( ) { } [ ] % & ' 만 입력가능합니다.`,
    },
    {
      code: 'e2016',
      desc: `수령자 영문명은 영문, 숫자, 공백, - _ / ~ + , . ( ) { } [ ] % & ' 만 입력가능합니다.`,
    },
    {
      code: 'e2017',
      desc: '수령자이메일을 정확하게 입력해 주십시오.',
    },
    {
      code: 'e2018',
      desc: '수령자전화번호는 숫자, 하이픈(-)만 입력가능합니다.',
    },
    {
      code: 'e2019',
      desc: '수령자핸드폰번호는 숫자, 하이픈(-)만 입력가능합니다.',
    },
    {
      code: 'e2020',
      desc: '우편번호는 숫자, 하이픈(-)만 입력가능합니다.',
    },
    {
      code: 'e2021',
      desc: `주문상품명은 한글, 영문, 숫자, 공백, - _ / ~ + , . ( ) { } [ ] % & ' 만 입력가능합니다.`,
    },
    {
      code: 'e2022',
      desc: '배송비는 숫자만 입력가능합니다.',
    },
    {
      code: 'e2023',
      desc: '배송처번호는 숫자만 입력가능합니다.',
    },
    {
      code: 'e2024',
      desc: '추가구매옵션 정보에 sku_cd 또는 set_no 중 하나의 값만 입력해주셔야 합니다.',
    },
    {
      code: 'e2025',
      desc: '기본 배송처 조회 실패. 기본 배송처를 확인하여 주시기 바랍니다.',
    },
    {
      code: 'e2026',
      desc: '하나의 주문건에는 동일한 배송처를 가진 SKU상품만 매칭될 수 있습니다.',
    },
    {
      code: 'e2027',
      desc: '하나의 주문건에는 동일한 배송처를 가진 세트상품만 매칭될 수 있습니다.',
    },
    {
      code: 'e2028',
      desc: '세트상품의 배송처가 매칭된 SKU상품의 배송처와 다릅니다.',
    },
    {
      code: 'e2029',
      desc: '입력된 배송처와 매칭된 SKU상품의 배송처가 다릅니다.',
    },
    {
      code: 'e2030',
      desc: '주문 유니크번호 생성에 실패했습니다.',
    },
    {
      code: 'e2031',
      desc: '묶음번호 생성에 실패했습니다.',
    },
    {
      code: 'e2032',
      desc: '주문분할방식은 divide, bundle 만 입력가능합니다.',
    },
    {
      code: 'e2033',
      desc: '검색조건이 올바르지 않습니다.',
    },
    {
      code: 'e2034',
      desc: '검색타입이 올바르지 않습니다.',
    },
    {
      code: 'e2035',
      desc: '검색날짜 타입이 올바르지 않습니다.',
    },
    {
      code: 'e2036',
      desc: '조회 시작일의 날짜의 형식이 올바르지 않습니다.',
    },
    {
      code: 'e2037',
      desc: '조회 종료일의 날짜의 형식이 올바르지 않습니다.',
    },
    {
      code: 'e2038',
      desc: '주문상태 조회값이 올바르지 않습니다.',
    },
    {
      code: 'e2039',
      desc: '멀티검색조건이 올바르지 않습니다.',
    },
    {
      code: 'e2040',
      desc: '출고불가능한 주문건이 포함되어있습니다. 출고가능한 주문건만 출고지시 처리가 가능합니다.',
    },
    {
      code: 'e2043',
      desc: '송장번호 업데이트가 정상적으로 처리되지 않았습니다. (잠시후 재시도 요망)',
    },
    {
      code: 'e2044',
      desc: '주문은 최대 3000건까지 조회가능합니다. (length Max value: 3000)',
    },
    {
      code: 'e2045',
      desc: 'API 호출여부 값이 올바르지 않습니다.',
    },
    {
      code: 'e2046',
      desc: '주문상태 정보는 배열로 입력해주셔야 합니다.',
    },
    {
      code: 'e2047',
      desc: '하위계정 sol_no을 정확하게 입력해주십시오.',
    },
    {
      code: 'e2048',
      desc: '택배사 번호와 송장번호를 모두 입력해주세요.',
    },
    {
      code: 'e2049',
      desc: '[결제완료] 주문은 주문정보 수정이 불가능합니다.',
    },
    {
      code: 'e2050',
      desc: '기존 주문정보와 변경사항이 없습니다.',
    },
    {
      code: 'e2051',
      desc: '택배사 코드가 공란인 상태로 송장번호를 입력할 수 없습니다.',
    },
    {
      code: 'e2052',
      desc: '조회시작 카운트가 올바르지 않습니다.',
    },
    {
      code: 'e2053',
      desc: '조회 갯수가 올바르지 않습니다.',
    },
    {
      code: 'e2054',
      desc: 'custom_shop_cd 입력시 custom_shop_id는 필수값입니다.',
    },
    {
      code: 'e2055',
      desc: 'custom_shop_id 입력시 custom_shop_cd는 필수값입니다.',
    },
    {
      code: 'e2056',
      desc: '직접입력한 쇼핑몰 shop_cd만 입력 가능합니다.',
    },
    {
      code: 'e2057',
      desc: '존재하지 않는 shop_cd 입니다.',
    },
    {
      code: 'e2058',
      desc: '존재하지 않는 쇼핑몰계정 입니다.',
    },
    {
      code: 'e2059',
      desc: '중복 주문이 존재합니다.',
    },
    {
      code: 'e2060',
      desc: '보류처리가 불가능한 상태의 주문이 포함되어있습니다.',
    },
    {
      code: 'e2061',
      desc: '입력한 uniq번호로 조회되는 주문이 없습니다.',
    },
    {
      code: 'e2062',
      desc: '취소완료 상태의 주문건이 있어 주문상태 변경에 실패했습니다.',
    },
    {
      code: 'e2063',
      desc: '입력한 SKU코드 또는 세트코드로 조회되는 주문이 없습니다.',
    },
    {
      code: 'e2064',
      desc: '주문묶음번호 리스트 또는 유니크를 입력해주세요.',
    },
    {
      code: 'e2065',
      desc: '조회 시작일이 조회 종료일보다 큽니다.',
    },
    {
      code: 'e2066',
      desc: '묶음번호 미입력시 시작일, 종료일은 필수 입니다.',
    },
    {
      code: 'e2999',
      desc: '정의되지 않은 오류',
    },
  ],
  문의: [
    {
      code: 'e5001',
      desc: '이미 전송완료된 문의는 답변처리가 불가능합니다.',
    },
    {
      code: 'e5002',
      desc: '조회되지 않는 문의입니다.',
    },
    {
      code: 'e5003',
      desc: '답변정보 업데이트 실패.',
    },
    {
      code: 'e5004',
      desc: '검색조건이 올바르지 않습니다.',
    },
    {
      code: 'e5005',
      desc: '검색타입이 올바르지 않습니다.',
    },
    {
      code: 'e5006',
      desc: '검색날짜 타입이 올바르지 않습니다.',
    },
    {
      code: 'e5007',
      desc: '조회 시작일의 날짜의 형식이 올바르지 않습니다.',
    },
    {
      code: 'e5008',
      desc: '조회 종료일의 날짜의 형식이 올바르지 않습니다.',
    },
    {
      code: 'e5009',
      desc: '문의유형이 올바르지 않습니다.',
    },
    {
      code: 'e5010',
      desc: '문의상태가 올바르지 않습니다.',
    },
    {
      code: 'e5011',
      desc: '멀티검색조건이 올바르지 않습니다.',
    },
    {
      code: 'e5999',
      desc: '정의되지 않은 오류',
    },
  ],
  재고: [
    {
      code: 'e3001',
      desc: 'SKU코드는 40자 이하로 등록가능합니다.',
    },
    {
      code: 'e3002',
      desc: '상품명은 한글, 영문, 숫자 최대 100Byte까지만 등록됩니다.',
    },
    {
      code: 'e3003',
      desc: (
        <div>
          SKU코드는 영문, 숫자, 특수문자,
          <strong>
            <code>{'('}</code>
          </strong>
          ,
          <strong>
            <code>{')'}</code>
          </strong>
          ,
          <strong>
            <code>-</code>
          </strong>
          ,
          <strong>
            <code>_</code>
          </strong>
          ,
          <strong>
            <code>=</code>
          </strong>
          <strong>
            <code>+</code>
          </strong>
          ,
          <strong>
            <code>.</code>
          </strong>
          ,
          <strong>
            <code>/</code>
          </strong>
          ,
          <strong>
            <code>,</code>
          </strong>
        </div>
      ),
    },
    {
      code: 'e3004',
      desc: '이미지 주소가 올바르지 않습니다.',
    },
    {
      code: 'e3005',
      desc: '모델명은 한글, 영문, 숫자 최대 40Byte까지만 등록됩니다.',
    },
    {
      code: 'e3006',
      desc: '하나의 속성은 반드시 입력해주셔야 합니다. 속성이 없는경우 opt1_type 에 "없음" 으로 입력해주십시오.',
    },
    {
      code: 'e3007',
      desc: '입력하신 배송처 정보가 존재하지 않습니다.',
    },
    {
      code: 'e3008',
      desc: '이미 등록된 sku_cd 입니다.',
    },
    {
      code: 'e3009',
      desc: '입력하신 매입처 정보가 존재하지 않습니다.',
    },
    {
      code: 'e3010',
      desc: '원산지 정보를 정확히 입력해주십시오.',
    },
    {
      code: 'e3011',
      desc: '품목정보고시의 상품분류코드를 정확히 입력해주십시오.',
    },
    {
      code: 'e3012',
      desc: '조회되지 않는 상품정보 입니다.',
    },
    {
      code: 'e3013',
      desc: '업로드 하신 이미지가 정상적이지 않습니다.',
    },
    {
      code: 'e3014',
      desc: '재고관리코드는 한글, 영문, 숫자 최대 100Byte 입력 가능합니다.',
    },
    {
      code: 'e3015',
      desc: '조회되지 않는 배송처코드 입니다.',
    },
    {
      code: 'e3016',
      desc: '이베이 물류서비스에서 가져온 SKU는 합포장여부, 배송처 수정이 불가합니다.',
    },
    {
      code: 'e3017',
      desc: '과세여부는 과세,면세,영세,비과세만 입력가능합니다.',
    },
    {
      code: 'e3018',
      desc: '옵션구분과 옵션명의 수가 일치하지 않습니다.',
    },
    {
      code: 'e3019',
      desc: '옵션구분은 중복등록이 불가능합니다.',
    },
    {
      code: 'e3020',
      desc: '수정할 정보가 없습니다.',
    },
    {
      code: 'e3021',
      desc: '재고상태 조회값이 올바르지 않습니다.',
    },
    {
      code: 'e3022',
      desc: '검색날짜 타입이 올바르지 않습니다.',
    },
    {
      code: 'e3023',
      desc: '정렬대상이 올바르지 않습니다.',
    },
    {
      code: 'e3024',
      desc: '정렬타입은 ASC, DESC 만 입력가능합니다.',
    },
    {
      code: 'e3025',
      desc: '조회 시작일의 날짜의 형식이 올바르지 않습니다.',
    },
    {
      code: 'e3026',
      desc: '조회 종료일의 날짜의 형식이 올바르지 않습니다.',
    },
    {
      code: 'e3027',
      desc: '검색타입이 올바르지 않습니다.',
    },
    {
      code: 'e3028',
      desc: '검색조건이 올바르지 않습니다.',
    },
    {
      code: 'e3030',
      desc: 'UPC/EAN 타입 입력값이 잘못되었습니다.',
    },
    {
      code: 'e3031',
      desc: '바코드는 영문, 숫자, 띄어쓰기와 특수문자 _ 만 입력가능합니다.',
    },
    {
      code: 'e3032',
      desc: '세트상태가 올바르지 않습니다.',
    },
    {
      code: 'e3033',
      desc: '유효하지 않은 하위계정을 포함하고 있습니다.',
    },
    {
      code: 'e3036',
      desc: '유효하지 않은 HS코드 입니다.',
    },
    {
      code: 'e3037',
      desc: '조회된 SKU가 없습니다.',
    },
    {
      code: 'e3038',
      desc: '배송처 정보를 입력해주십시오.',
    },
    {
      code: 'e3039',
      desc: '배송처코드 및 실재고는 필수입니다.',
    },
    {
      code: 'e3040',
      desc: '다중배송처 사용이 불가능합니다. 하나의 배송처만 사용해 주십시오.',
    },
    {
      code: 'e3041',
      desc: '대표배송처는 수정하는 배송처에 포함되어야 합니다.',
    },
    {
      code: 'e3042',
      desc: '주문및 상품, 세트상품에 매칭되어있는 배송처정보는 삭제가 불가능합니다.',
    },
    {
      code: 'e3043',
      desc: '배송처 관련정보를 수정하시려는 경우 실재고 수량을 필수로 입력해주셔야 합니다.',
    },
    {
      code: 'e3044',
      desc: (
        <div>
          미출고 주문에 매칭된 SKU상품은 배송처 변경이 불가능합니다. <br />
          매칭된 주문을 [출고완료] 처리 후 배송처를 변경해 주시거나, 주문의
          매칭을 해제 후 배송처를 변경하여 재매칭해주시기 바랍니다.
        </div>
      ),
    },
    {
      code: 'e3045',
      desc: '배송처코드는 숫자만 입력 가능합니다.',
    },
    {
      code: 'e3046',
      desc: '조회시작 카운트가 올바르지 않습니다.',
    },
    {
      code: 'e3047',
      desc: '조회 갯수가 올바르지 않습니다.',
    },
    {
      code: 'e3048',
      desc: '해당 상품정보제공고시 내 필수값이 없습니다. (필요 항목 : 필요 항목 키값)',
    },
    {
      code: 'e3999',
      desc: '정의되지 않은 오류',
    },
  ],
  정산: [
    {
      code: 'e7001',
      desc: '정렬타입은 ASC, DESC만 입력가능합니다.',
    },
    {
      code: 'e7002',
      desc: '정렬가능한 항목이 아닙니다.',
    },
    {
      code: 'e7003',
      desc: '조회 시작일의 날짜 형식이 올바르지 않습니다.',
    },
    {
      code: 'e7004',
      desc: '조회 종료일의 날짜 형식이 올바르지 않습니다.',
    },
    {
      code: 'e7005',
      desc: '검색타입이 올바르지 않습니다.',
    },
    {
      code: 'e7006',
      desc: '정산상태가 올바르지 않습니다.',
    },
    {
      code: 'e7007',
      desc: '정산구분이 올바르지 않습니다.',
    },
    {
      code: 'e7008',
      desc: '검색키가 올바르지 않습니다.',
    },
    {
      code: 'e7009',
      desc: '검색일이 올바르지 않습니다.',
    },
    {
      code: 'e7011',
      desc: '조회시작 카운트가 올바르지 않습니다.',
    },
    {
      code: 'e7012',
      desc: '조회 갯수가 올바르지 않습니다.',
    },
    {
      code: 'e7999',
      desc: '정의되지 않은 오류',
    },
  ],
  메모: [
    {
      code: 'e8001',
      desc: '입력하신 uniq로 조회되는 주문이 없습니다.',
    },
    {
      code: 'e8002',
      desc: '입력하신 ol_shop_no로 조회되는 온라인상품이 없습니다.',
    },
    {
      code: 'e8003',
      desc: '입력하신 prod_no로 조회되는 SKU상품이 없습니다.',
    },
    {
      code: 'e8004',
      desc: '메모유형이 올바르지 않습니다.',
    },
    {
      code: 'e8005',
      desc: '메모유형이 ord인 경우 uniq는 필수입니다.',
    },
    {
      code: 'e8006',
      desc: '메모유형이 ol_shop인 경우 ol_shop_no는 필수입니다.',
    },
    {
      code: 'e8007',
      desc: '메모유형이 prod인 경우 prod_no는 필수입니다.',
    },
    {
      code: 'e8008',
      desc: '메모상태는 등록 또는 완료로만 입력이 가능합니다.',
    },
    {
      code: 'e8009',
      desc: '메모수정에 실패하였습니다.',
    },
    {
      code: 'e8010',
      desc: '메모삭제에 실패하였습니다.',
    },
    {
      code: 'e8999',
      desc: '정의되지 않은 오류',
    },
  ],
  작업: [
    {
      code: 'e6001',
      desc: '지원하지 않는 작업입니다.',
    },
    {
      code: 'e6002',
      desc: '점검으로 인해 작업이 제한됩니다.',
    },
    {
      code: 'e6003',
      desc: '이미 등록된 작업이 있습니다.',
    },
    {
      code: 'e6004',
      desc: '작업 카운트가 부족하여 작업 등록이 실패되었습니다.',
    },
    {
      code: 'e6005',
      desc: '쇼핑몰 정보 조회에 실패하였습니다.',
    },
    {
      code: 'e6006',
      desc: '문의전송에 필요한 필수값이 없습니다.',
    },
    {
      code: 'e6007',
      desc: '작업등록에 실패하였습니다.',
    },
    {
      code: 'e6008',
      desc: '작업가능한 데이터가 없습니다.',
    },
    {
      code: 'e6009',
      desc: '해당 작업이 조회되지않습니다.',
    },
    {
      code: 'e6010',
      desc: '작업이 아직 완료되지 않았습니다.',
    },
    {
      code: 'e6011',
      desc: (
        <div>
          상품이 조회되지 않거나 수정대기 상태가 아닙니다.
          <br />
          수정대기 상태의 상품만 수정작업이 가능합니다.
        </div>
      ),
    },
    {
      code: 'e6012',
      desc: '상품작업 등록시 쇼핑몰코드 및 쇼핑몰 아이디는 필수입니다.',
    },
    {
      code: 'e6013',
      desc: '부분수정항목은 boolean타입으로 입력해주십시오.',
    },
    {
      code: 'e6014',
      desc: '부분수정항목을 하나이상 "true"로 설정해주십시오.',
    },
    {
      code: 'e6015',
      desc: '부분수정 작업시 부분수정항목은 필수입니다.',
    },
    {
      code: 'e6016',
      desc: '판매중, 수정대기, 종료대기 상태의 상품만 (일시품절 | 판매중지)이 가능합니다.',
    },
    {
      code: 'e6017',
      desc: '판매대기 상태의 상품만 등록작업이 가능합니다.',
    },
    {
      code: 'e6018',
      desc: '단일상품여부는 Y 또는 N만 등록가능합니다.',
    },
    {
      code: 'e6019',
      desc: '판매중시 상태의 상품만 작업이 가능합니다.',
    },
    {
      code: 'e6020',
      desc: '일시품절 상태의 상품만 작업이 가능합니다.',
    },
    {
      code: 'e6021',
      desc: '온라인상품 동기화 작업시 동기화항목은 필수입니다.',
    },
    {
      code: 'e6022',
      desc: (
        <div>
          판매기간 연장은
          <strong>
            <code>옥션</code>
          </strong>
          ,
          <strong>
            <code>지마켓</code>
          </strong>
          ,
          <strong>
            <code>인터파크</code>
          </strong>
          ,
          <strong>
            <code>스마트스토어</code>
          </strong>
          ,
          <strong>
            <code>11번가</code>
          </strong>
          ,
          <strong>
            <code>카카오 선물하기</code>
          </strong>
          만 가능합니다.
        </div>
      ),
    },
    {
      code: 'e6023',
      desc: '판매기간 연장시 연장일은 필수입니다.',
    },
    {
      code: 'e6024',
      desc: (
        <div>
          <strong>
            <code>15</code>
          </strong>
          ,
          <strong>
            <code>30</code>
          </strong>
          ,
          <strong>
            <code>60</code>
          </strong>
          ,
          <strong>
            <code>90일</code>
          </strong>
          만 입력가능합니다.
        </div>
      ),
    },
    {
      code: 'e6025',
      desc: '사용가능한 쇼핑몰이 아닙니다.',
    },
    {
      code: 'e6026',
      desc: '요청하신 작업은 해당 쇼핑몰에서 지원하지 않습니다.',
    },
    {
      code: 'e6027',
      desc: '카테고리가 설정되어있지 않습니다.',
    },
    {
      code: 'e6028',
      desc: '택배사 또는 송장번호가 누락된 주문건이 존재합니다.',
    },
    {
      code: 'e6029',
      desc: '올바른 형식의 주소가 아닙니다.',
    },
    {
      code: 'e6030',
      desc: (
        <div>
          계정정보가 오래되어 작업등록에 실패했습니다.
          <br />
          쇼핑몰 관리 페이지에서 수정 후 작업 재시도 부탁드립니다.
        </div>
      ),
    },
    {
      code: 'e6031',
      desc: '특정 작업 삭제 시 work_nos는 필수값입니다.',
    },
    {
      code: 'e6032',
      desc: '존재하는 작업이 없습니다.',
    },
    {
      code: 'e6033',
      desc: '전체 작업 삭제여부는 Y 또는 N만 가능합니다.',
    },
    {
      code: 'e6034',
      desc: '단일상품여부 값이 잘못되었습니다.',
    },
    {
      code: 'e6035',
      desc: '\'1\', \'2\', \'3\', \'4\', \'5\', \'6\', \'7\', \'8\', \'9\', \'10\' 만 입력가능합니다.',
    },
    {
      code: 'e6036',
      desc: '상품코드 유지 재판매를 지원하지 않는 쇼핑몰 입니다.',
    },
    {
      code: 'e6037',
      desc: '직접입력 주문건은 작업등록이 불가능합니다.',
    },
    {
      code: 'e6038',
      desc: '[승인대기, 판매중, 수정대기, 종료대기, 일시품절, 판매중지, 반려] 상태의 상품만 상품동기화 작업이 가능합니다.',
    },
    {
      code: 'e6999',
      desc: '정의되지 않은 오류',
    },
  ],
  기타: [
    {
      code: 'e9001',
      desc: '회원기능 사용이 불가능한 사용자입니다.',
    },
    {
      code: 'e9002',
      desc: '수정이 불가능한 대상입니다.',
    },
    {
      code: 'e9999',
      desc: '유효하지 않은 입력값입니다.',
    }
  ],
};
