import React, { useState, useEffect, useCallback } from 'react';
import { simpleSubmitHandler } from 'util/HelperFunctions';

let logoutTimer;

const AuthContext = React.createContext({
  token: '',
  isLoggedIn: false,
  memberNo: 0,
  affNo: 0,
  login: (token) => {},
  logout: () => {},
  userInfo: null,
  setUserInfo: (userInfo) => {}
});

/**
 * 토큰 만료 시간을 Date 형식으로 받아서 만료까지 남은 시간을 milliseconds 형식으로 반환하는 함수
 */
const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();

  const remainingDuration = adjExpirationTime - currentTime;

  return remainingDuration;
};

/**
 * 로컬 스토리지에 저장되어 있는 토큰을 만료까지 남은 시간과 함께 반환하는 함수
 * 이미 만료가 된 경우에는 null 반환
 * memberNo도 함께 반환
 */
const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem('token');
  const storedExpirationDate = localStorage.getItem('expirationTime');
  const storedMemberNo = localStorage.getItem('memberNo');
  const storedAffNo = localStorage.getItem('affNo');

  const remainingTime = calculateRemainingTime(storedExpirationDate);

  // 로그인 토큰 만료시간이 다 되었을 때 토큰 삭제
  if (remainingTime <= 0) {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('memberNo');
    localStorage.removeItem('affNo');
    return null;
  }

  return {
    token: storedToken,
    duration: remainingTime,
    memberNo: storedMemberNo,
    affNo: storedAffNo
  };
};

export const AuthContextProvider = (props) => {
  const tokenData = retrieveStoredToken();
  
  let initialToken;
  let initialMemberNo = 0;
  let initialAffNo = 0;

  if (tokenData) {
    initialToken = tokenData.token;
    initialMemberNo = tokenData.memberNo;
    initialAffNo = tokenData.affNo;
  }

  const [token, setToken] = useState(initialToken);
  const [memberNo, setMemberNo] = useState(initialMemberNo);
  const [affNo, setAffNo] = useState(initialAffNo);
  const [userInfo, setUserInfo] = useState({});
  const userIsLoggedIn = !!token;

  const logoutHandler = useCallback(() => {
    setToken(null);
    setMemberNo(0);
    setAffNo(0);
    setUserInfo(null);
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('memberNo');
    localStorage.removeItem('affNo');
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  const loginHandler = (token, memberNo, expirationTime, affNo) => {
    setToken(token);
    setMemberNo(memberNo);
    setAffNo(affNo);
    localStorage.setItem('token', token);
    localStorage.setItem('memberNo', memberNo);
    localStorage.setItem('affNo', affNo);
    localStorage.setItem('expirationTime', expirationTime);
    const remainingTime = calculateRemainingTime(expirationTime);
    logoutTimer = setTimeout(logoutHandler, remainingTime);
  };

  useEffect(() => {
    if (tokenData) {
      logoutTimer = setTimeout(logoutHandler, tokenData.duration);
      const requestOption = {
        method: 'GET',
        headers: {
          Authorization: token
        }
      };
      simpleSubmitHandler(null, '/center/users/getUserInfo', requestOption, userInfo => setUserInfo(userInfo));
    } else {
      setUserInfo(null);
    }
    // eslint-disable-next-line
  }, [token]);

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    memberNo: memberNo,
    affNo: affNo,
    userInfo: userInfo,
    setUserInfo
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
