import { default_error_list } from './defaultData';

const data = (userInfo) => ({
  title: '에러코드',
  children: [
    {
      title: '공통',
      url: '/err/공통에러',
      data: default_error_list.공통,
    },
    {
      title: '주문',
      url: '/err/주문에러',
      data: default_error_list.주문,
    },
    {
      title: '상품',
      unrecognized_aff_no: [1051],
      url: '/err/상품에러',
      data: default_error_list.상품,
    },
    {
      title: '문의',
      unrecognized_aff_no: [1051],
      url: '/err/문의에러',
      data: default_error_list.문의,
    },
    {
      title: '재고',
      unrecognized_aff_no: [1051],
      url: '/err/재고에러',
      data: default_error_list.재고,
    },
    {
      title: '정산',
      unrecognized_aff_no: [1051],
      url: '/err/정산에러',
      data: default_error_list.정산,
    },
    {
      title: '메모',
      unrecognized_aff_no: [1051],
      url: '/err/메모에러',
      data: default_error_list.메모,
    },
    {
      title: '작업',
      url: '/err/작업에러',
      data: default_error_list.작업,
    },
    {
      title: '기타',
      url: '/err/기타에러',
      data: default_error_list.기타,
    },
  ],
});

export default data;
