import { useState } from 'react';
import classes from './Footer.module.css';
import footerLogo from 'assets/img/f_logo.svg';
import TermsDetailModal from 'components/modals/TermsDetailModal/TermsDetailModal';

/**
 * 화면 상단 네비게이션 컴포넌트
 */
const Footer = () => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <footer className={classes.container}>
        <div className={classes.footerWrap}>
          <div>
            <img src={footerLogo} alt={'playauto'} />
          </div>
          <ul>
            <li>
              <button onClick={() => setModalOpen(true)}>이용약관</button>
            </li>
            <li>
              <a href='https://www.plto.com/policy/Policy/?tabIndex=2' target="_blank" rel="noreferrer">개인정보취급방침</a>
            </li>
            <li>
              <a href='https://www.plto.com/customer/Question/?type=%EC%A0%9C%ED%9C%B4%EB%AC%B8%EC%9D%98' target="_blank" rel="noreferrer">제휴문의</a>
            </li>
          </ul>
        </div>
      </footer>
      {modalOpen && <TermsDetailModal closeModal={() => {setModalOpen(false)}} />}
    </>
  );
};

export default Footer;
