import { default_header, default_header_example } from './defaultData';

const data = (userInfo) => ({
  title: '작업',
  children: [
    {
      title: '작업 등록',
      url: '/doc/작업 등록',
      data: [
        {
          version: 'v1.2',
          url: '/work/addWork/v1.2',
          method: 'POST',
          desc: (
            <ul className='style'>
              <li>
                <p>호출한 액션의 작업을 등록합니다.</p>
              </li>
              <li>
                <p>작업은 쇼핑몰 별로 동시에 작업 가능합니다.</p>
              </li>
              <li>
                <p>
                  호출가능한 액션은 아래와 같습니다. <br />
                  <br />
                </p>
                <table width='700px'>
                  <tbody>
                    <tr>
                      <th colSpan='2'>주문</th>
                    </tr>
                    <tr>
                      <td width='200px'>
                        <font color='red'>ScrapOrderConfirmList</font>
                      </td>
                      <td>
                        결제완료 주문수집 <br/>
                        <code>작업명: 발주 확인전 주문수집</code>  <br/>
                        <div className='description' style={{ padding: '12px 0' }}>
                          <ul className='style'>
                            <li>발주확인을 지원하지 않는 쇼핑몰은 ScrapOrder 작업으로 자동 등록됩니다.</li>
                            <li>스마트스토어 발주 확인 된 주문 수집 시 결제 완료 주문도 같이 수집되고 있으므로 ScrapOrder 액션으로 호출해 주시기 바랍니다.</li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width='200px'>
                        <font color='red'>ScrapOrder</font>
                      </td>
                      <td>
                        발주확인된 주문 수집 <br/>
                        <code>작업명: 주문수집</code> 
                        <div className='description' style={{ padding: '12px 0' }}>
                          스마트스토어의 경우 해당 액션 호출 시, 결제 완료 주문이 같이 수집됩니다.
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width='200px'>
                        <font color='red'>ScrapOrderAndConfirmDoit</font>
                      </td>
                      <td>
                        결제완료 주문건 모두 발주확인 처리 후 주문수집 <br/>
                        <code>작업명: 발주확인후 주문수집</code> 
                        <div className='description' style={{ padding: '12px 0' }}>
                          <ul className='style'>
                            <li>
                              발주확인을 지원하지 않는 쇼핑몰은 ScrapOrder 작업으로 자동 등록됩니다.
                            </li>
                          </ul>
                        </div>
                        </td>
                    </tr>
                    <tr>
                      <td width='200px'>
                        <font color='red'>SyncOrderState</font>
                      </td>
                      <td>
                        주문동기화 <br/>
                        <code>작업명: 주문동기화</code> 
                        </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                { userInfo?.aff_no !== 1051 ?
                <>
                  <table width='700px'>
                    <tbody>
                      <tr>
                        <th colSpan='2'>문의</th>
                      </tr>
                      <tr>
                        <td width='200px'>
                          <font color='red'>ScrapCS</font>
                        </td>
                        <td>문의수집</td>
                      </tr>
                      <tr>
                        <td width='200px'>
                          <font color='red'>ScrapEmergencyCS</font>
                        </td>
                        <td>긴급 문의 수집</td>
                      </tr>
                      <tr>
                        <td width='200px'>
                          <font color='red'>ScrapReview</font>
                        </td>
                        <td>상품평 수집</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <table width='700px'>
                    <tbody>
                      <tr>
                        <th colSpan='2'>정산</th>
                      </tr>
                      <tr>
                        <td width='200px'>
                          <font color='red'>ScrapPayBack</font>
                        </td>
                        <td>정산수집</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                   <table width='700px'>
                    <tbody>
                      <tr>
                        <th colSpan='2'>상품</th>
                      </tr>
                      <tr>
                        <td width='200px'>
                          <font color='red'>ScrapProd</font>
                        </td>
                        <td>상품수집</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </> : ''
              }
              </li>
              <li>
                <p>쇼핑몰 코드는 쇼핑몰코드 조회 api 를 통하여 확인바랍니다.</p>
              </li>
              <li>
                <p>
                  등록된 작업은 PLAYAUTO 2.0 솔루션에 접속하여 확인가능합니다.
                </p>
              </li>
              <li>
                <p>
                  작업결과는 리턴되는 작업번호(work_no)로 작업결과조회를
                  호출하여 확인가능합니다.
                </p>
              </li>
              <li>
                <p>
                  <code>api_callback_url</code> 등록시 작업결과를 POST 방식으로 입력하신 주소로 전송합니다.<br />
                  - 전송되는 데이터 포멧은 아래의 예시를 참고해주세요
                </p>
                <pre>
                  [{'{'}
                  <br />  work_no: 172279187,
                  <br />  job_name: '주문수집',
                  <br />  status: '완료',
                  <br />  message: ['[성공] : 변경사항이 없습니다'],
                  <br />{'}'}]
                </pre>
              </li>
            </ul>
          ),
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'act',
              required: 'O',
              type: 'String',
              desc: '작업액션',
            },
            {
              title: 'params',
              required: 'O',
              type: 'Object',
              desc: '',
            },
            {
              title: 'site_code',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 코드',
              indent: 1,
            },
            {
              title: 'site_id',
              required: 'O',
              type: 'String[]',
              desc: '쇼핑몰 아이디',
              indent: 1,
            },
            {
              title: 'std_ol_yn',
              required: '',
              type: 'String',
              desc: (
                <div>
                  단일상품여부
                  <div className='description'>
                    온라인상품 작업시 사용됩니다.
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>N</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>Y</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>N</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'code_scrap_yn',
              required: '',
              type: 'Boolean',
               desc: (
                <div>
                    상품 수집 시 특정 상품 수집 여부
                  <div className='description'>
                    상품수집 작업 시 사용하는 값입니다.
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>true</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            특정 상품 가져오기<br />
                            prod_codes(가져올 상품의 쇼핑몰 상품코드)가 필수로 들어가야 합니다.
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            등록일 기준 모두 가져오기 <br/>
                            prod_scrap_date(수집기간)이 없는경우 1년전 상품까지 수집합니다.
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <strong>
                    <ul className='style'>
                      <li>
                        <code>값을 넣지않는경우 등록일 기준 전체 수집으로 작업합니다.</code>
                      </li>
                    </ul>
                  </strong>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_codes',
              required: '',
              type: 'Array',
              desc: '특정 상품 가져오기 시 가져올 상품의 쇼핑몰 상품코드',
              indent: 1,
            },
            {
              title: 'prod_scrap_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  수집기간
                  <div className='description'>
                    상품 등록일 기준 입력한 값 년전인 상품까지 수집합니다.
                  </div>
                   <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>1</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>1</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>2</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>3</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>4</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>5</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>6</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>7</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>8</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>9</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>10</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'show_shop_info_yn',
              required: '',
              type: 'Boolean',
              desc: (
                <div>
                  등록 작업 쇼핑몰정보 노출여부
                  <div className='description'>
                    콜백URL 결과 데이터 응답 시 사용됩니다.
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>true</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'api_callback_url',
              required: '',
              type: 'String',
              desc: (
                <div>
                  작업완료시 작업결과를 수신받을 URL
                  <div className='description'>
                    해당정보 입력시 작업결과를 입력하신 주소로 POST 방식으로
                    전송합니다.
                  </div>
                </div>
              ),
            },
            ...([1051].includes(userInfo.aff_no) ? [{
              title: 'target',
              required: '',
              type: 'String',
              desc: (
                <div>
                  스마트스토어 타겟 키

                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>normal</code>
                          </strong>
                        </td>
                        <td>
                          <strong>일반 주문만</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>guarantee</code>
                          </strong>
                        </td>
                        <td>
                          <strong>도착 주문만</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>all</code>
                          </strong>
                        </td>
                        <td>
                          <strong>전체</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <strong>
                    <ul className='style'>
                      <li>
                        <code>값을 넣지않는경우 전체검색됩니다.</code>
                      </li>
                    </ul>
                  </strong>
                </div>
              ),
              indent: 1,
            }] : []),
          ],
          body_example: {
            act: 'ScrapOrder',
            params: {
              site_code: 'A001',
              site_id: ['test_shop', 'test_shop2'],
            },
            api_callback_url: 'https://test.io/callback',
          },
          response: [
            {
              title: 'work_no',
              required: 'O',
              type: 'Number',
              desc: '등록된 작업번호',
            },
            {
              title: 'message',
              required: 'O',
              type: 'String',
              desc: '결과 메세지',
            },
          ],
          response_example: {
              "results": [
                  {
                      "shop_cd": "A001",
                      "shop_id": "test_shop",
                      "status": "성공",
                      "work_no": 349767639,
                      "messages": [
                          "작업이 정상적으로 등록되었습니다."
                      ]
                  },
                  {
                      "shop_cd": "A001",
                      "shop_id": "test_shop2",
                      "status": "실패",
                      "error_code": "e6025",
                      "messages": [
                          "사용가능한 쇼핑몰이 아닙니다."
                      ]
                  }
              ]
          },
          error_example: {
            "error_code": "e6xxx",
            "messages": [
              "에러 메세지"
            ]
          }
        },
        {
          version: 'v1.1',
          url: '/work/addWork/v1.1',
          method: 'POST',
          desc: (
            <ul className='style'>
              <li>
                <p>호출한 액션의 작업을 등록합니다.</p>
              </li>
              <li>
                <p>
                  호출가능한 액션은 아래와 같습니다. <br />
                  <br />
                </p>
                <table width='700px'>
                  <tbody>
                    <tr>
                      <th colSpan='2'>주문</th>
                    </tr>
                    <tr>
                      <td width='200px'>
                        <font color='red'>ScrapOrderConfirmList</font>
                      </td>
                      <td>
                        결제완료 주문수집 <br/>
                        <code>작업명: 발주 확인전 주문수집</code>  <br/>
                        <div className='description' style={{ padding: '12px 0' }}>
                          <ul className='style'>
                            <li>발주확인을 지원하지 않는 쇼핑몰은 ScrapOrder 작업으로 자동 등록됩니다.</li>
                            <li>스마트스토어 발주 확인 된 주문 수집 시 결제 완료 주문도 같이 수집되고 있으므로 ScrapOrder 액션으로 호출해 주시기 바랍니다.</li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width='200px'>
                        <font color='red'>ScrapOrder</font>
                      </td>
                      <td>
                        발주확인된 주문 수집 <br/>
                        <code>작업명: 주문수집</code> 
                        <div className='description' style={{ padding: '12px 0' }}>
                          스마트스토어의 경우 해당 액션 호출 시, 결제 완료 주문이 같이 수집됩니다.
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width='200px'>
                        <font color='red'>ScrapOrderAndConfirmDoit</font>
                      </td>
                      <td>
                        결제완료 주문건 모두 발주확인 처리 후 주문수집 <br/>
                        <code>작업명: 발주확인후 주문수집</code> 
                        <div className='description' style={{ padding: '12px 0' }}>
                          <ul className='style'>
                            <li>
                              발주확인을 지원하지 않는 쇼핑몰은 ScrapOrder 작업으로 자동 등록됩니다.
                            </li>
                          </ul>
                        </div>
                        </td>
                    </tr>
                    <tr>
                      <td width='200px'>
                        <font color='red'>SyncOrderState</font>
                      </td>
                      <td>
                        주문동기화 <br/>
                        <code>작업명: 주문동기화</code> 
                        </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                { userInfo?.aff_no !== 1051 ?
                <>
                  <table width='700px'>
                    <tbody>
                      <tr>
                        <th colSpan='2'>문의</th>
                      </tr>
                      <tr>
                        <td width='200px'>
                          <font color='red'>ScrapCS</font>
                        </td>
                        <td>문의수집</td>
                      </tr>
                      <tr>
                        <td width='200px'>
                          <font color='red'>ScrapEmergencyCS</font>
                        </td>
                        <td>긴급 문의 수집</td>
                      </tr>
                      <tr>
                        <td width='200px'>
                          <font color='red'>ScrapReview</font>
                        </td>
                        <td>상품평 수집</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <table width='700px'>
                    <tbody>
                      <tr>
                        <th colSpan='2'>정산</th>
                      </tr>
                      <tr>
                        <td width='200px'>
                          <font color='red'>ScrapPayBack</font>
                        </td>
                        <td>정산수집</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                   <table width='700px'>
                    <tbody>
                      <tr>
                        <th colSpan='2'>상품</th>
                      </tr>
                      <tr>
                        <td width='200px'>
                          <font color='red'>ScrapProd</font>
                        </td>
                        <td>상품수집</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </> : ''
              }
              </li>
              <li>
                <p>쇼핑몰 코드는 쇼핑몰코드 조회 api 를 통하여 확인바랍니다.</p>
              </li>
              <li>
                <p>
                  등록된 작업은 PLAYAUTO 2.0 솔루션에 접속하여 확인가능합니다.
                </p>
              </li>
              <li>
                <p>
                  작업결과는 리턴되는 작업번호(work_no)로 작업결과조회를
                  호출하여 확인가능합니다.
                </p>
              </li>
              <li>
                <p>
                  <code>api_callback_url</code> 등록시 작업결과를 POST 방식으로
                  입력하신 주소로 전송합니다.
                  <br />
                  (전송되는 정보는 작업결과 조회시 조회되는 포멧과 동일합니다.)
                </p>
              </li>
            </ul>
          ),
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'act',
              required: 'O',
              type: 'String',
              desc: '작업액션',
            },
            {
              title: 'params',
              required: 'O',
              type: 'Object',
              desc: '',
            },
            {
              title: 'site_code',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 코드',
              indent: 1,
            },
            {
              title: 'site_id',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 아이디',
              indent: 1,
            },
            {
              title: 'std_ol_yn',
              required: '',
              type: 'String',
              desc: (
                <div>
                  단일상품여부
                  <div className='description'>
                    온라인상품 작업시 사용됩니다.
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>N</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>Y</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>N</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'code_scrap_yn',
              required: '',
              type: 'Boolean',
               desc: (
                <div>
                    상품 수집 시 특정 상품 수집 여부
                  <div className='description'>
                    상품수집 작업 시 사용하는 값입니다.
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>true</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            특정 상품 가져오기<br />
                            prod_codes(가져올 상품의 쇼핑몰 상품코드)가 필수로 들어가야 합니다.
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                        <td>
                          <strong>
                            등록일 기준 모두 가져오기 <br/>
                            prod_scrap_date(수집기간)이 없는경우 1년전 상품까지 수집합니다.
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <strong>
                    <ul className='style'>
                      <li>
                        <code>값을 넣지않는경우 등록일 기준 전체 수집으로 작업합니다.</code>
                      </li>
                    </ul>
                  </strong>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_codes',
              required: '',
              type: 'Array',
              desc: '특정 상품 가져오기 시 가져올 상품의 쇼핑몰 상품코드',
              indent: 1,
            },
            {
              title: 'prod_scrap_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  수집기간
                  <div className='description'>
                    상품 등록일 기준 입력한 값 년전인 상품까지 수집합니다.
                  </div>
                   <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>1</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>1</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>2</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>3</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>4</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>5</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>6</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>7</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>8</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>9</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>10</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'show_shop_info_yn',
              required: '',
              type: 'Boolean',
              desc: (
                <div>
                  등록 작업 쇼핑몰정보 노출여부
                  <div className='description'>
                    콜백URL 결과 데이터 응답 시 사용됩니다.
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>true</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'api_callback_url',
              required: '',
              type: 'String',
              desc: (
                <div>
                  작업완료시 작업결과를 수신받을 URL
                  <div className='description'>
                    해당정보 입력시 작업결과를 입력하신 주소로 POST 방식으로
                    전송합니다.
                  </div>
                </div>
              ),
            },
          ],
          body_example: {
            act: 'ScrapOrder',
            params: {
              site_code: 'A001',
              site_id: 'test_shop',
            },
            api_callback_url: 'https://test.io/callback',
          },
          response: [
            {
              title: 'work_no',
              required: 'O',
              type: 'Number',
              desc: '등록된 작업번호',
            },
            {
              title: 'message',
              required: 'O',
              type: 'String',
              desc: '결과 메세지',
            },
          ],
          response_example: [
            {
              work_no: 36022757,
              message: '작업이 정상적으로 등록되었습니다.',
            },
            ' --- api_callback_url 설정시 수신 데이터 -----------',
            ...([1051].includes(userInfo.aff_no) ?
              [{
                com: 'PLAYAUTO',
                encver: '2.0',
                encYn: 'N',
                msg: '{"body":{"work_no":172279187,"job_name":"주문수집","status":"완료","message":"[성공] : 변경사항이 없습니다"},"header":{"bizPtrId":"PLAYAUTO","callId":"playauto0001","ifCd":"PLAYAUTO","encType":"UTF-8","token":"PLAYAUTO[13.125.30.102]FTGsfgr5ht4HhfrDbBFW6j==20230010100001"}}'
              }]
              : [{
              work_no: 172279187,
              job_name: '주문수집',
              status: '완료',
              message: ['[성공] : 변경사항이 없습니다'],
            }])
          ],
          error_example: {
            "error_code": "e6xxx",
            "messages": [
              "에러 메세지"
            ]
          }
        },
      ],
    },
    {
      title: '선택작업 등록',
      url: '/doc/선택작업 등록',
      data: [
        {
          version: 'v1.0',
          url: '/work/addWorkSelect',
          method: 'POST',
          desc: (
            <ul className='style'>
              <li>
                <p>전송하는 주문데이터에 적용될 작업을 등록합니다.</p>
              </li>
              <li>
                <p>
                  호출가능한 액션은 아래와 같습니다. <br />
                  <br />
                </p>
                <table width='700px'>
                  <tbody>
                    <tr>
                      <th colSpan='2'>주문</th>
                    </tr>
                    <tr>
                      <td width='200px'>
                        <font color='red'>SEND_INVOICE</font>
                      </td>
                      <td>
                        운송장전송 <br/>
                        <code>작업명: 송장전송</code>
                      </td>
                    </tr>
                    <tr>
                      <td width='200px'>
                        <font color='red'>CONFIRM_ORDER</font>
                      </td>
                      <td>
                        주문확인(발주확인) <br/>
                        <code>작업명: 발주확인</code>
                      </td>
                    </tr>
                    <tr>
                      <td width='200px'>
                        <font color='red'>DELAYED_ORDER_PAYMENT</font>
                      </td>
                      <td>
                        발주확인 전 주문(결제완료주문) 배송지연 <br/>
                        <code>작업명: 배송지연</code>
                      </td>
                    </tr>
                    <tr>
                      <td width='200px'>
                        <font color='red'>DELAYED_ORDER</font>
                      </td>
                      <td>
                        신규주문 배송지연 <br/>
                        <code>작업명: 배송지연</code>
                      </td>
                    </tr>
                    {
                      [1, 1957, 5422, 77327, 129725, 121024, 122081, 122082].includes(userInfo?.m_no) || userInfo.aff_no === 1051 ? 
                      <tr>
                        <td width='200px'>
                          <font color='red'>PRINT_SLS_LABEL</font>
                        </td>
                        <td>샤피 SLS 송장발행</td>
                      </tr> : ''
                    }
                  </tbody>
                </table>
                <br />
                { userInfo?.aff_no !== 1051 ?
                <>
                  <table width='700px'>
                    <tbody>
                      <tr>
                        <th colSpan='2'>온라인상품</th>
                      </tr>
                      <tr>
                        <td width='200px'>
                          <font color='red'>REGIST_PROD</font>
                        </td>
                        <td>
                          쇼핑몰 등록 전송(판매대기 상태의 상품만 전송가능합니다.)
                        </td>
                      </tr>
                      <tr>
                        <td width='200px'>
                          <font color='red'>EDIT_PROD_ALL</font>
                        </td>
                        <td>
                          쇼핑몰 수정 전송(수정대기 상태의 상품만 전송가능합니다.)
                        </td>
                      </tr>
                      <tr>
                        <td width='200px'>
                          <font color='red'>EDIT_PROD</font>
                        </td>
                        <td>
                          쇼핑몰 부분수정 전송(수정대기 상태의 상품만
                          전송가능합니다.)
                        </td>
                      </tr>
                      <tr>
                        <td width='200px'>
                          <font color='red'>STOP_PROD</font>
                        </td>
                        <td>
                          쇼핑몰 품절 전송(판매중, 수정대기, 종료대기 상태의
                          상품만 전송가능합니다.)
                        </td>
                      </tr>
                      <tr>
                        <td width='200px'>
                          <font color='red'>RESTORE_PROD</font>
                        </td>
                        <td>
                          쇼핑몰 일시품절복구 전송(일시품절 상태의 상품만
                          전송가능합니다.)
                        </td>
                      </tr>
                      <tr>
                        <td width='200px'>
                          <font color='red'>REGIST_PROD_RE</font>
                        </td>
                        <td>
                          쇼핑몰 재판매 전송(판매중지 상태의 상품만
                          전송가능합니다.)<br />
                          <code>(쇼핑몰 상품코드 유지)</code>
                        </td>
                      </tr>
                      <tr>
                        <td width='200px'>
                          <font color='red'>REGIST_PROD_NEW</font>
                        </td>
                        <td>
                          쇼핑몰 재판매 전송(판매중지 상태의 상품만
                          전송가능합니다.)<br />
                          <code>(새상품으로 등록)</code>
                        </td>
                      </tr>
                      <tr>
                        <td width='200px'>
                          <font color='red'>SYNC_PROD_STATE</font>
                        </td>
                        <td>온라인상품 동기화</td>
                      </tr>
                      <tr>
                        <td width='200px'>
                          <font color='red'>EXTENT_PROD</font>
                        </td>
                        <td>
                          쇼핑몰 판매기간 연장 전송 <br /> (옥션, 지마켓,
                          인터파크, 스마트스토어, 11번가, 카카오 선물하기만 가능)
                        </td>
                      </tr>
                      <tr>
                        <td width='200px'>
                          <font color='red'>CANCEL_PROD</font>
                        </td>
                        <td>쇼핑몰 판매중지 전송</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <table width='700px'>
                    <tbody>
                      <tr>
                        <th colSpan='2'>문의</th>
                      </tr>
                      <tr>
                        <td width='200px'>
                          <font color='red'>SEND_CS</font>
                        </td>
                        <td>문의답변전송</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </> : ''
                }
              </li>
              <li>
                <p>운송장전송시 출고완료 상태의 주문건만 전송이 가능합니다.</p>
              </li>
              { userInfo?.aff_no !== 1051 ?
              <>
                <li>
                  <p>
                    문의답변 전송시 답변작성완료 상태의 문의만 전송 가능합니다.
                  </p>
                </li>
              </> : ''
              }
              <li>
                <p>
                  500 개 이상의 데이터를 전송하시는경우 500 개단위로 작업이
                  분할되어 등록됩니다.
                </p>
              </li>
              <li>
                <p>
                  <code>api_callback_url</code> 등록시 작업결과를 POST 방식으로
                  입력하신 주소로 전송합니다. <br />
                  - product_info 항목은 상품 작업인 경우에만 출력됩니다.<br />
                  - 전송되는 데이터 포멧은 아래의 예시를 참고해주세요.
                  <pre className='prettyprint'>
                    ---- 상품작업 결과 (성공시) ----
                    <br />{'{'}
                    <br />  "work_no": 172966702,
                    <br />  "job_name": "상품등록",
                    <br />  "status": "완료",
                    <br />  "message": [
                    <br />    "[성공] : m2024051034984a215 / 3789117229"
                    <br />  ],
                    <br />  "product_info": [
                    <br />    {'{'}
                    <br />      "c_sale_cd": "m2024051034984a215",
                    <br />      "shop_sale_no": "3789117229",
                    <br />      "status": "성공",
                    <br />      "msg": "m2024051034984a215 / 3789117229"
                    <br />    {'}'}
                    <br />  ]
                    <br />{'}'}
                    <br />
                    <br />---- 상품작업 결과 (실패시) ----
                    <br />{'{'}
                    <br />  "work_no": 172966702,
                    <br />  "job_name": "판매중지",
                    <br />  "status": "실패",
                    <br />  "message": [
                    <br />    "[실패] : m20220914f60d00d1b, ESM어드민에 [9999999999]상품이 존재하지 않습니다. ESM어드민 접속 하시어 상품을 확인해 주시기 바랍니다.",
                    <br />    "[실패] : m20220927a547a62c6, ESM어드민 상에서 [[888888888]]상품이 조회되지 않습니다. ESM 어드민으로 접속 하시어 상품을 확인해 주시기 바랍니다."
                    <br />  ],
                    <br />  "product_info": [
                    <br />      {'{'}
                    <br />        "c_sale_cd": "판매자관리코드",
                    <br />        "shop_sale_no": "쇼핑몰 상품코드",
                    <br />        "status": "실패",
                    <br />        "msg": "m20220914f60d00d1b, ESM어드민에 [9999999999]상품이 존재하지 않습니다. ESM어드민 접속 하시어 상품을 확인해 주시기 바랍니다."
                    <br />      {'}'},
                    <br />      {'{'}
                    <br />        "c_sale_cd": "m20220927a547a62c6",
                    <br />        "shop_sale_no": "888888888",
                    <br />        "status": "실패",
                    <br />        "msg": "m20220927a547a62c6, ESM어드민 상에서 [[888888888]]상품이 조회되지 않습니다. ESM 어드민으로 접속 하시어 상품을 확인해 주시기 바랍니다."
                    <br />      {'}'}
                    <br />    ]
                    <br />  {'}'}
                    <br />
                    <br />---- 상품 외 작업 결과 (성공시) ----
                    { [1051].includes(userInfo.aff_no) ? 
                    <>
                      <br />[{'{'}
                      <br />  com: 'PLAYAUTO',
                      <br />  encver: '2.0',
                      <br />  encYn: 'N',
                      <br />  msg: '{'{'}"body":{'{'}"work_no":172279187,"job_name":"주문수집","status":"완료","message":"[성공] : 변경사항이 없습니다"{'}'},"header":'{'{'}"bizPtrId":"PLAYAUTO","callId":"playauto0001","ifCd":"PLAYAUTO","encType":"UTF-8","token":"PLAYAUTO[13.125.30.102]FTGsfgr5ht4HhfrDbBFW6j==20230010100001"{'}}'}'
                      <br />{'}'}]
                    </>
                    : 
                    <>
                      <br />{'{'}
                      <br />  "work_no": 172279187,
                      <br />  "job_name": "주문수집",
                      <br />  "status": "완료",
                      <br />  "message": [
                      <br />    "[성공] : 2건 성공 / 0건 실패 "
                      <br />  ]
                      <br />{'}'}
                    </>}
                    <br />
                    <br />---- 상품 외 작업 결과 (실패시) ----
                    <br />{'{'}
                    <br />  "work_no": 172279187,
                    <br />  "job_name": "주문수집",
                    <br />  "status": "실패",
                    <br />  "message": [
                    <br />    "[성공] : wpartner.wemakeprice.com 주문 수집 종료"
                    <br />  ]
                    <br />{'}'}
                  </pre>
                </p>
              </li>
            </ul>
          ),
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'work_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  작업액션
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>SEND_INVOICE</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>SEND_CS</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>CONFIRM_ORDER</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>DELAYED_ORDER_PAYMENT</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>DELAYED_ORDER</code>
                          </strong>
                        </td>
                      </tr>
                      {
                        [1, 1957, 5422, 77327, 129725, 121024, 122081, 122082].includes(userInfo?.m_no) || userInfo.aff_no === 1051?
                        <tr>
                          <td>
                            <strong>
                              <code>PRINT_SLS_LABEL</code>
                            </strong>
                          </td>
                        </tr> : ''
                      }
                      <tr>
                        <td>
                          <strong>
                            <code>REGIST_PROD</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EDIT_PROD_ALL</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EDIT_PROD</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>STOP_PROD</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>RESTORE_PROD</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>REGIST_PROD_RE</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>REGIST_PROD_NEW</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>SYNC_PROD_STATE</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EXTENT_PROD</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>CANCEL_PROD</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'shop_cd',
              required: '',
              type: 'String',
              desc: (
                <div>
                  쇼핑몰코드
                  <div className='description'>
                    온라인상품 작업시 필수입니다.
                  </div>
                </div>
              ),
            },
            {
              title: 'shop_id',
              required: '',
              type: 'String',
              desc: (
                <div>
                  쇼핑몰아이디
                  <div className='description'>
                    온라인상품 작업시 필수입니다.
                  </div>
                </div>
              ),
            },
            {
              title: 'std_ol_yn',
              required: '',
              type: 'String',
              desc: (
                <div>
                  단일상품여부
                  <div className='description'>
                    온라인상품 작업시 사용됩니다.
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>N</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>Y</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>N</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'exclude_yn',
              required: '',
              type: 'String',
              desc: (
                <div>
                  대상상품이 아닌경우 제외하고 작업을 진행할지의 여부
                  <div className='description'>
                    (온라인상품 수정 작업에만 적용됩니다.)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>N</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>Y</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>N</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'list',
              required: '',
              type: 'String[]',
              desc: (
                <div>
                  주문 또는 문의 고유번호
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>주문: uniq 또는 bundle_no</li>
                    <li>문의: inq_uniq</li>
                    <li>상품: c_sale_cd(판매자관리코드)</li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'edit_col',
              required: '',
              type: 'Object',
              desc: (
                <div>
                  부분수정할 항목
                  <div className='description'>
                    온라인상품 부분수정 작업시 필수입니다.
                    <br />
                    수정하지 않을 항목은 입력하지 않아도 됩니다.
                  </div>
                </div>
              ),
            },
            {
              title: 'isEditName',
              required: '',
              type: 'Boolean',
              desc: '상품명',
              indent: 1,
            },
            {
              title: 'isEditPrice',
              required: '',
              type: 'Boolean',
              desc: '판매가',
              indent: 1,
            },
            {
              title: 'isEditOpt',
              required: '',
              type: 'Boolean',
              desc: '옵션',
              indent: 1,
            },
            {
              title: 'isEditAddopt',
              required: '',
              type: 'Boolean',
              desc: '추가구매옵션',
              indent: 1,
            },
            {
              title: 'isEditContent',
              required: '',
              type: 'Boolean',
              desc: (
                <div>
                  상세설명
                  <div className='description'>(머리말/꼬리말 포함)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'isEditImage',
              required: '',
              type: 'Boolean',
              desc: '이미지',
              indent: 1,
            },
            {
              title: 'sync_col',
              required: '',
              type: 'Object',
              desc: (
                <div>
                  상품동기화할 항목
                  <div className='description'>
                    온라인상품 동기화 작업시 필수입니다.
                    <br />
                    수정하지 않을 항목은 입력하지 않아도 됩니다.
                  </div>
                </div>
              ),
            },
            {
              title: 'status',
              required: '',
              type: 'Boolean',
              desc: '상품상태',
              indent: 1,
            },
            {
              title: 'count',
              required: '',
              type: 'Boolean',
              desc: '판매수량',
              indent: 1,
            },
            {
              title: 'period',
              required: '',
              type: 'Boolean',
              desc: '상품 판매 종료일',
              indent: 1,
            },
            {
              title: 'price',
              required: '',
              type: 'Boolean',
              desc: '판매가격',
              indent: 1,
            },
            {
              title: 'extension_date',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  판매기간연장일
                  <div className='description'>
                    온라인상품 판매기간연장 작업시 필수입니다.
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>15</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>30</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>60</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>90</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'delay_reason',
              required: '',
              type: 'String',
              desc: '배송지연 사유',
            },
            {
              title: 'delay_date',
              required: '',
              type: 'String',
              desc: '배송지연 날짜',
            },
            {
              title: 'show_shop_info_yn',
              required: '',
              type: 'Boolean',
              desc: (
                <div>
                  등록 작업 쇼핑몰정보 노출여부
                  <div className='description'>
                    콜백URL 결과 데이터 응답 시 사용됩니다.
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>true</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )
            },
            {
              title: 'api_callback_url',
              required: '',
              type: 'String',
              desc: (
                <div>
                  작업완료시 작업결과를 수신받을 URL
                  <div className='description'>
                    해당정보 입력시 작업결과를 입력하신 주소로 POST 방식으로
                    전송합니다.
                  </div>
                </div>
              ),
            },
          ],
          body_example: [
            '# 운송장정보 쇼핑몰 전송',
            {
              work_type: 'SEND_INVOICE',
              list: [
                '3782381925823309795',
                '3782381925823309796',
                '3782381925823309797',
              ],
              api_callback_url: 'https://test.io/callback',
            },
            '# 온라인상품 쇼핑몰 등록',
            {
              work_type: 'REGIST_PROD',
              shop_cd: 'A001',
              shop_id: 'testshop',
              std_ol_yn: 'Y',
              list: ['P000002018'],
              api_callback_url: 'https://test.io/callback',
            },
            '# 온라인상품 부분수정 전송',
            {
              work_type: 'EDIT_PROD',
              shop_cd: 'A001',
              shop_id: 'testshop',
              std_ol_yn: 'N',
              list: ['P000002018'],
              edit_col: {
                isEditName: false,
                isEditPrice: false,
                isEditOpt: true,
                isEditContent: false,
                isEditImage: false,
              },
              api_callback_url: 'https://test.io/callback',
            },
            '# 온라인상품 수정 전송',
            {
              work_type: 'EDIT_PROD_ALL',
              shop_cd: 'A001',
              shop_id: 'testshop',
              std_ol_yn: 'N',
              list: ['P000002018'],
              api_callback_url: 'https://test.io/callback',
            },
            '# 온라인상품 품절전송',
            {
              work_type: 'STOP_PROD',
              shop_cd: 'A001',
              shop_id: 'testshop',
              std_ol_yn: 'Y',
              list: ['P000002018'],
              api_callback_url: 'https://test.io/callback',
            },
            '# 온라인상품 품절복구 전송',
            {
              work_type: 'REGIST_PROD_RE',
              shop_cd: 'A006',
              shop_id: 'testshop',
              std_ol_yn: 'N',
              list: ['m202103154766b7665'],
              api_callback_url: 'https://test.io/callback',
            },
            '# 온라인상품 동기화',
            {
              work_type: 'SYNC_PROD_STATE',
              shop_cd: 'A001',
              shop_id: 'testshop',
              std_ol_yn: 'N',
              list: ['m20210716533cbfe65'],
              sync_col: {
                status: true,
                count: true,
                period: true,
                price: true,
              },
              api_callback_url: 'https://test.io/callback',
            },
            '# 온라인상품 판매기간 연장',
            {
              work_type: 'EXTENT_PROD',
              shop_cd: 'A001',
              shop_id: 'testshop',
              std_ol_yn: 'N',
              list: ['m20210716533cbfe65'],
              extension_date: 15,
              api_callback_url: 'https://test.io/callback',
            },
            '# 온라인상품 판매중지',
            {
              work_type: 'CANCEL_PROD',
              shop_cd: 'A001',
              shop_id: 'testshop',
              std_ol_yn: 'N',
              list: ['m20210716533cbfe65'],
              api_callback_url: 'https://test.io/callback',
            },
          ],
          response: [
            {
              title: 'results',
              required: 'O',
              type: 'Array',
              desc: '결과\n(실패시 error 로 출력)',
            },
            {
              title: 'work_no',
              required: 'O',
              type: 'Number',
              desc: '등록된 작업번호',
              indent: 1,
            },
            {
              title: 'error_code',
              required: '',
              type: 'String',
              desc: '실패시 출력되는 에러코드',
              indent: 1,
            },
            {
              title: 'message',
              required: 'O',
              type: 'String',
              desc: '성공, 실패 메세지',
              indent: 1,
            },
            {
              title: 'list',
              required: '',
              type: 'String[]',
              desc: '등록한 list 정보\n(성공인경우 출력)\n(주문작업인 경우 list 에 uniq 값을 넣었어도 묶음번호 데이터로 출력)',
              indent: 1,
            }
          ],
          response_example: {
            "results": [
                {
                    "work_no": 487772398,
                    "message": "작업이 정상적으로 등록되었습니다.",
                    "list": [
                        "3938009934902534",
                        "3938713558156561"
                    ]
                }
            ]
        },
          error_example: {
            "error": [
                {
                    "work_no": 0,
                    "error_code": "e6999",
                    "messages": [
                        "작업등록에 실패하였습니다."
                    ]
                }
            ]
        }
        },
      ],
    },
    {
      title: '작업결과 조회',
      url: '/doc/작업결과 조회',
      data: [
        {
          version: 'v1.0',
          url: '/work/:work_no',
          method: 'GET',
          desc: (
            <ul className='style'>
              <li>등록된 작업결과를 조회합니다.</li>
              <li>완료 상태의 작업만 조회됩니다.</li>
              <li>진행중 또는 대기상태의 작업은 조회되지 않습니다.</li>
              <li>작업등록시 리턴된 work_no 를 입력하여 조회 가능합니다.</li>
            </ul>
          ),
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'work_no',
              required: 'O',
              type: 'Number',
              desc: '작업번호',
            },
          ],
          body_example: {
            work_no: 11591018,
          },
          response: [
            {
              title: 'results',
              required: 'O',
              type: 'Object',
              desc: '조회하는 작업',
            },
            {
              title: 'work_no',
              required: 'O',
              type: 'Number',
              desc: '작업번호',
              indent: 1,
            },
            {
              title: 'job_name',
              required: 'O',
              type: 'String',
              desc: '작업명',
              indent: 1,
            },
            {
              title: 'status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  작업 상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>완료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>실패</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'message',
              required: 'O',
              type: 'String[]',
              desc: (
                <div>
                  결과 메세지
                  <div className='description'>(성공 또는 실패)</div>
                </div>
              ),
              indent: 1,
            },
          ],
          response_example: {
            results: {
              job_name: '송장전송',
              status: '실패',
              message: [
                '[실패] : 7668708872786275 : 상품코드가 비어있습니다. 주문정보를 확인해 주세요.',
                '[실패] : 7668708872786276 : 상품코드가 비어있습니다. 주문정보를 확인해 주세요.',
              ],
            },
          },
        },
      ],
    },
    {
      title: '작업 삭제',
      url: '/doc/작업 삭제',
      data: [
        {
          version: 'v1.1',
          url: '/work/delete/v1.1',
          method: 'DELETE',
          desc: (
            <ul className='style'>
              <li>등록된 작업을 삭제합니다.</li>
              <li>대기,완료,실패,오류 상태의 작업만 삭제됩니다.</li>
            </ul>
          ),
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'work_nos',
              required: 'O',
              type: 'Number[]',
              desc: '작업번호',
            },
            {
              title: 'is_all',
              required: '',
              type: 'String',
              desc: (
                <div>
                  전체 작업 삭제 여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>N</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>Y</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>N</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
          ],
          body_example: {
            '- 선택 작업만 삭제하는 경우': {
              work_nos: [181044917, 181167926],
              is_all: 'N',
            },
            '- 전체 작업을 삭제하는 경우': {
              work_nos: [],
              is_all: 'Y',
            },
          },
          response: [
            {
              title: 'message',
              required: 'O',
              type: 'String',
              desc: '결과 메세지',
            },
            {
              title: 'delete_cnt',
              required: 'O',
              type: 'Number',
              desc: '삭제된 작업 개수',
            },
          ],
          response_example: {
            message: '작업이 정상적으로 삭제되었습니다.',
            delete_cnt: 2,
          },
        },
      ],
    },
  ],
});

export default data;
