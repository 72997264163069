import { useState } from 'react';

function useFormValue(options) {
  const [formValues, setFormValues] = useState(options);

  function handleInputUpdate(key, value) {
    setFormValues(old_value => {
      return {...old_value, [key]: {...old_value[key], value}};
    });
  }
  
  const formValidity = (() => {
    // eslint-disable-next-line
    for (const [_, value] of Object.entries(formValues)) {
      // 유효성 검사를 하지 않는 인풋의 경우에는 유효성 검사 로직 자동 통과 처리
      if (!value.regex && !value.valid) continue;
      // 아닌 경우에는 ValidationInput 컴포넌트의 유효성 검사 로직과 동일하게 처리
      const valid = (value.optional && !value.value?.trim()) ? true : value.regex ? value.regex.test(value.value) : value.valid;
      if (!valid) {
        return false;
      }
    }
    return true;
  })();

  return [formValues, handleInputUpdate, formValidity, setFormValues];
}

export default useFormValue;