import classes from './AccordionSideNav.module.css';
import AccordionSideNavNode from './AccordionSideNavNode';
import { NavLink } from 'react-router-dom';

/**
 * 왼쪽 사이드바 컴포넌트 (Accordion 형식)
 */
const AccordionSideNav = (props) => {
  return (
    <section className={classes.container}>
      <ul>
        {
          props.data.map((item, i) => {
            if(item.children?.length) {
              return <AccordionSideNavNode data={item} key={i} level={0}/>

            } else {
              return (
                <button key={i}>
                  <NavLink 
                    to={item.url}
                  >
                    {item.title}
                  </NavLink>
                </button>
              );
            }
          })
        }
      </ul>
    </section>
  );
};

export default AccordionSideNav;
