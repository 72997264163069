import { useState } from 'react';
import classes from './DataTable.module.css';
import arrowLeft from 'assets/img/arrow_left.svg';
import arrowRight from 'assets/img/arrow_right.svg';
import arrowDoubleLeft from 'assets/img/double_arrow_left.svg';
import arrowDoubleRight from 'assets/img/double_arrow_right.svg';

import {
  getCoreRowModel,
  flexRender,
  useReactTable,
  getSortedRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';

/**
 * 1:1문의 목록 리스팅 페이지 컴포넌트
 */
const DataTable = (props) => {
  const [sorting, setSorting] = useState([]);

  const table = useReactTable({
    data: props.data,
    columns: props.columns,
    state: {
      sorting,
    },
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
  });

  return (
    <div className={classes.container}>
      <div className={classes['pagination-top']}>
        <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}개씩 보기
            </option>
          ))}
        </select>
      </div>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ width: header.getSize() }}
                    onClick={header.column.columnDef.notSortable ? null : header.column.getToggleSortingHandler()}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                    {header.column.getCanResize() && (
                      <div
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        className={classes.resizer}
                      ></div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} style={{ width: cell.column.getSize() }}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className={classes['pagination-bottom']}>
        <button
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          <img src={arrowDoubleLeft} alt='처음으로'/>
        </button>
        <button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <img src={arrowLeft} alt='이전'/>
        </button>
        {table.getState().pagination.pageIndex + 1}
        <button
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <img src={arrowRight} alt='다음'/>
        </button>
        <button
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          <img src={arrowDoubleRight} alt='마지막으로'/>
        </button>
      </div>
    </div>
  );
};

export default DataTable;
