import { default_header, default_header_example } from './defaultData';

// 쇼핑몰계정 관리 API 문서
const data = (userInfo) => ({
  title: '쇼핑몰계정 관리',
  children: [
    {
      title: '쇼핑몰 코드 조회',
      url: '/doc/쇼핑몰 코드 조회',
      data: [
        {
          version: 'v1.0',
          url: '/shops',
          desc: 'PLAYAUTO 에서 사용되는 쇼핑몰 코드 리스트를 조회합니다.',
          method: 'GET',
          header: default_header,
          header_example: default_header_example,
          query: [
            {
              title: 'used',
              required: '',
              type: 'String',
              desc: (
                <div>
                  사용중인 쇼핑몰 조회여부
                  <br />
                  <div className='description'>
                    해당값을 true로 보내실경우 사용중인 쇼핑몰 정보만
                    조회됩니다.
                  </div>
                  <br />
                  <div style={{ paddingBottom: '12px', color: 'gray' }}>
                    <strong>Allowed values</strong>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>true</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'etc_detail',
              required: '',
              type: 'String',
              desc: (
                <div>
                  etc 설명
                  <br />
                  <div className='description'>
                  쇼핑몰별 etc 필드 정보 조회 여부 <br /> 해당값을 true로 보내실경우 쇼핑몰의 etc넘버와 정의가 함께 리턴됩니다.
                  </div>
                  <br />
                  <div style={{ paddingBottom: '12px', color: 'gray' }}>
                    <strong>Allowed values</strong>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>true</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'usable_shop',
              required: '',
              type: 'String',
              desc: (
                <div>
                  사용가능여부
                  <br />
                  <div className='description'>
                  쇼핑몰별 사용 가능/중단 여부 <br /> 해당값을 true로 보내실경우 쇼핑몰별 사용 가능/중단 여부가 함께 리턴됩니다.
                  </div>
                  <br />
                  <div style={{ paddingBottom: '12px', color: 'gray' }}>
                    <strong>Allowed values</strong>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>true</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
          ],
          response: [
            {
              title: 'shop_name',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 이름',
            },
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 코드',
            },
            {
              title: 'shop_id',
              required: '',
              type: 'String',
              desc: (
                <div>
                  쇼핑몰 아이디
                  <div className='description'>
                    호출 파라미터에 used=true로 조회한 경우에만 출력됩니다.
                  </div>
                </div>
              ),
            },
            {
              title: 'seller_nick',
              required: '',
              type: 'String',
              desc: (
                <div>
                  별칭
                  <div className='description'>
                    호출 파라미터에 used=true로 조회한 경우에만 출력됩니다.
                  </div>
                </div>
              ),
            },
             {
              title: 'shop_etc',
              required: '',
              type: 'String',
              desc: (
                <div>
                  etc정보
                  <div className='description'>
                    호출 파라미터에 etc_detail=true로 조회한 경우에만 출력됩니다.
                  </div>
                </div>
              ),
            },
            {
              title: 'usable',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  사용가능여부
                  <div className='description'>
                    호출 파라미터에 usable_shop=true로 조회한 경우에만 출력됩니다.
                  </div>
                  <br/>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>1</code>
                          </strong>
                        </td>
                        <td>
                          사용가능
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                        <td>
                          사용불가
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
          ],
          response_example: [
            {
              shop_name: '옥션',
              shop_cd: 'A001',
            },
            {
              shop_name: '지마켓',
              shop_cd: 'A006',
            },
          ],
        },
      ],
    },
    {
      title: '쇼핑몰 계정 등록',
      url: '/doc/쇼핑몰 계정 등록',
      data: [
        {
          version: 'v1.0',
          url: '/shop/add',
          desc: (
            <ul className='style'>
              <li>
                입력된 계정정보가 유효한지 검사하고 쇼핑몰 계정을 등록합니다.
              </li>
            </ul>
          ),
          method: 'POST',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'id',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 계정',
            },
            {
              title: 'pwd',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 비밀번호',
            },
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 코드',
            },
            {
              title: 'seller_nick',
              required: '',
              type: 'String',
              desc: '별칭',
            },
            {
              title: 'etc',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  기타정보
                  <br />
                  각 쇼핑몰 별 etc 정보는 쇼핑몰 코드 조회 API를 통하여 확인할 수 있습니다.
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>etc 필드가 없을 경우: {'{}'}</li>
                    <li>
                      etc 필드 목록에 맞춰 객체 형식으로 작성해주세요.
                      <br />
                    </li>
                    <li>ex{')'} 지마켓: {'{}'}</li>
                    <li>
                      ex{')'} 스마트스토어: {'{'}
                      <br />
                      &nbsp;&nbsp;etc1: "n9xxxxxx" // API ID
                      <br />
                      &nbsp;&nbsp;etc2: "glass" // 스토어URL
                      (http://smartstore.naver.com/glass)
                      <br />
                      &nbsp;&nbsp;etc3: "1" // 계정 유형 (1: 판매자ID , 2: 네이버ID)
                      <br />
                      &nbsp;&nbsp;etc4: "shopn" // API 연동용 판매자 ID
                      <br />
                      &nbsp;&nbsp;etc7: {'{'}"channelName": "복수"{'}'} {'//'}
                      복수스토어
                      <br />
                      {'}'}
                    </li>
                  </ul>
                </div>
              ),
            },
          ],
          body_example: {
            id: 'playauto',
            pwd: '1234',
            shop_cd: 'A077',
            etc: {
              etc1: 'n9xxxxxx',
              etc2: 'glass',
              etc3: '1',
              etc4: 'shopn',
              etc7: { channelName: '복수' }
            },
          },
          response: [
            {
              title: 'success',
              required: 'O',
              type: 'boolean',
              desc: '성공여부',
            },
          ],
          response_example: {
            success: true,
          },
          error_example: {
            "error_code": "e1999",
            "messages": [
              "쇼핑몰 계정 등록에 실패했습니다."
            ]
          }
        },
      ],
    },
    {
      title: '쇼핑몰 계정 수정',
      url: '/doc/쇼핑몰 계정 수정',
      data: [
        {
          version: 'v1.0',
          url: '/shop/edit',
          desc: (
            <ul className='style'>
              <li>입력된 계정정보가 유효한지 검사하고 쇼핑몰 계정을 수정합니다.</li>
              <li>비밀번호, etc, 별칭 정보만 수정 가능합니다.</li>
              <li>기존에 id정보가 있는지 확인 후 &gt; 쇼핑몰에서 변경된 정보가 유효한지 확인 후 DB에 저장합니다.</li>
            </ul>
          ),
          method: 'PATCH',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'id',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 계정',
            },
            {
              title: 'pwd',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 비밀번호',
            },
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 코드',
            },
            {
              title: 'seller_nick',
              required: '',
              type: 'String',
              desc: '별칭',
            },
            {
              title: 'etc',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  기타정보
                  <br />
                  각 쇼핑몰 별 etc 정보는 쇼핑몰 코드 조회 API를 통하여 확인할 수 있습니다.
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>etc 필드가 없을 경우: {'{}'}</li>
                    <li>
                      etc 필드 목록에 맞춰 객체 형식으로 작성해주세요.
                      <br />
                    </li>
                    <li>ex{')'} 지마켓: {'{}'}</li>
                    <li>
                      ex{')'} 스마트스토어: {'{'}
                      <br />
                      &nbsp;&nbsp;etc1: "n9xxxxxx" // API ID
                      <br />
                      &nbsp;&nbsp;etc2: "glass" // 스토어URL
                      (http://smartstore.naver.com/glass)
                      <br />
                      &nbsp;&nbsp;etc3: "1" // 계정 유형 (1: 판매자ID , 2: 네이버ID)
                      <br />
                      &nbsp;&nbsp;etc4: "shopn" // API 연동용 판매자 ID
                      <br />
                      &nbsp;&nbsp;etc7: {'{'}"channelName": "복수"{'}'} {'//'}
                      복수스토어
                      <br />
                      {'}'}
                    </li>
                  </ul>
                </div>
              ),
            },
          ],
          body_example: {
            id: 'playauto',
            pwd: '1234',
            shop_cd: 'A077',
            etc: {
              etc1: 'n9xxxxxx',
              etc2: 'glass',
              etc3: '1',
              etc4: 'shopn',
              etc7: { channelName: '복수' }
            },
          },
          response: [
            {
              title: 'success',
              required: 'O',
              type: 'boolean',
              desc: '성공여부',
            },
          ],
          response_example: {
            success: true,
          },
          error_example: {
            "error_code": "e1999",
            "messages": [
              "쇼핑몰 계정 수정에 실패했습니다."
            ]
          }
        },
      ],
    },
    {
      title: '쇼핑몰 계정 삭제',
      url: '/doc/쇼핑몰 계정 삭제',
      data: [
        {
          version: 'v1.0',
          url: '/shop/delete',
          desc: (
            <ul className='style'>
              <li>
                입력된 쇼핑몰 계정을 삭제합니다.
              </li>
              <li>
                계정 삭제 시 상품, 주문, 문의 등 해당 계정의 모든 정보가 삭제되며 복구가 불가능하므로 주의바랍니다.
              </li>
              <li>
                "출고완료" 이후 (출고완료/배송중/구매결정/배송완료) 주문은 삭제되지 않습니다.
              </li>
            </ul>
          ),
          method: 'DELETE',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'id',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 계정',
            },
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 코드',
            }
          ],
          body_example: {
            id: 'playauto',
            shop_cd: 'A077',
          },
          response: [
            {
              title: 'success',
              required: 'O',
              type: 'boolean',
              desc: '성공여부',
            },
          ],
          response_example: {
            success: true,
          },
          error_example: {
            "error_code": "e1999",
            "messages": [
              "쇼핑몰 계정 삭제에 실패했습니다."
            ]
          }
        },
      ],
    },
  ]
});

export default data;