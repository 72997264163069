import { default_header, default_header_example } from './defaultData';

// 공통 API 문서
const data = (userInfo) => ({
  title: '공통',
  children: [
    {
      title: '인증 토큰 발급',
      url: '/doc/인증 토큰 발급',
      data: [
        {
          version: 'v1.0',
          url: '/auth',
          desc: (
            <ul className='style'>
              <li>
                입력된 계정정보가 유효한지 검사하고 open api 사용에 필요한 인증토큰 생성합니다.
              </li>
              <li>
                발급된 토큰은 다른 api 호출시 header 에 넣어 전송해주셔야 합니다.
              </li>
              <li>
                Header 에 넣을시 토큰정보 앞에 "Token " 을 붙여 넣어주셔야 합니다.
                <br />
                ex&gt; Token asdasiqwjenb.....
              </li>
              <li>발급된 토큰의 유효기간은 24시간 입니다.</li>
              <li>토큰발급은 이메일, 패스워드 또는 솔루션 인증키로 발급이 가능합니다.</li>
              <li>솔루션 인증키는 <font color="red">2.0 솔루션 접속 > 환경설정 > API 사용설정</font> 에서 확인 가능합니다.</li>
            </ul>
          ),
          method: 'POST',
          header: [
            {
              title: 'x-api-key',
              required: 'O',
              type: 'String',
              desc: '승인시 발급받은 API KEY',
            },
          ],
          header_example: {
            'x-api-key': 'ejlkiasoj..',
          },
          body: [
            {
              title: 'email',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  이메일 주소
                  <div className='description'>
                    이메일, 비밀번호로 토큰 발행시 필수<br/>
                    솔루션 인증키로 토큰 발행시 비필수
                  </div>
                </div>
              ),
            },
            {
              title: 'password',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  비밀번호
                  <div className='description'>
                    이메일, 비밀번호로 토큰 발행시 필수<br/>
                    솔루션 인증키로 토큰 발행시 비필수
                  </div>
                </div>
              ),
            },
            {
              title: 'authentication_key',
              required: 'X',
              type: 'String',
              desc: (
                <div>
                  솔루션 인증키
                  <div className='description'>
                    솔루션 인증키로 토큰 발행시 필수<br/>
                    이메일, 비밀번호로 토큰 발행시 비필수
                  </div>
                </div>
              ),
            },
          ],
          body_example: {
            email: 'api@sample.com',
            password: '123456789',
          },
          response: [
            {
              title: 'token',
              required: 'O',
              type: 'String',
              desc: '발행된 토큰',
            },
            {
              title: 'sol_no',
              required: 'O',
              type: 'Number',
              desc: '솔루션번호',
            },
          ],
          response_example: [
            {
              token: 'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJhdXRoX3R5cGUiOiLstJ3qtITqtIDrpqzsnpAiLCJzb2xfbm8iOjEsInBhX3NvbF9ubyI6bnVsbCwic29sX3R5cGUiOiJHTVAiLCJzb2xfc3ViX3R5cGUiOiJERVYiLCJzb2xfdmVyc2lvbiI6IlZJUCIsInNvbF9zdG9jayI6MSwid21fbm8iOm51bGwsInNvbF9zZGF0ZSI6IjIwMTctMDUtMjYiLCJzb2xfZWRhdGUiOiIyMDMxLTAzLTI5IiwibGFzdF9zeW5jX3RpbWUiOiIyMDIwLTAzLTA0IDExOjA4OjM2IiwiZmlyc3RfcGF5X25vIjo2LCJkb21haW4iOiJwbGF5YXV0by5jby5rciIsImRiX2Nvbm5fbmFtZSI6ImtyX3VzZXIxIiwidHV0b3JpYWxfc3RlcCI6MTEsImFwaV9hdmFpbF95biI6MSwiZGVwb3Rfbm8iOm51bGwsImViYXlkZXBvdF95biI6bnVsbCwiZGVmYXVsdF90ZW1wbGF0ZV9nc',
              sol_no: 12345
            },
          ],
        },
      ],
    },
    {
      title: '배송처 조회',
      url: '/doc/배송처 조회',
      data: [
        {
          version: 'v1.0',
          url: '/depots',
          desc: '사용중인 솔루션에 등록된 배송처 리스트를 조회합니다.',
          method: 'GET',
          header: default_header,
          header_example: default_header_example,
          query: [
            {
              title: 'masking_yn',
              required: 'O',
              type: 'Boolean',
              desc: (
                <div>
                  개인정보 마스킹 처리 여부
                  <br />
                  <br />
                  <div style={{ paddingBottom: '12px', color: 'gray' }}>
                    <strong>Default value</strong>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <code>
                            <b>true</b>
                          </code>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
          ],
          response: [
            {
              title: 'no',
              required: 'O',
              type: 'Number',
              desc: '배송처번호',
            },
            {
              title: 'sol_no',
              required: 'O',
              type: 'Number',
              desc: '솔루션번호',
            },
            {
              title: 'default_yn',
              required: 'O',
              type: 'Boolean',
              desc: '기본배송처여부',
            },
            {
              title: 'name',
              required: 'O',
              type: 'String',
              desc: '배송처명',
            },
            {
              title: 'address',
              required: 'O',
              type: 'String',
              desc: '배송처주소',
            },
            {
              title: 'zip',
              required: 'O',
              type: 'String',
              desc: '우편번호',
            },
            {
              title: 'use_yn',
              required: 'O',
              type: 'Boolean',
              desc: '사용여부',
            },
            {
              title: 'charge_name',
              required: 'O',
              type: 'String',
              desc: '담당자명',
            },
          ],
          response_example: [
            {
              no: 1111,
              sol_no: 999999,
              default_yn: true,
              name: '기본배송처',
              address: '서울시 구로구 구로동 123',
              zip: '11122',
              use_yn: true,
              charge_name: '김담당',
            },
            {
              no: 1112,
              sol_no: 999999,
              default_yn: false,
              name: '테스트배송처',
              address: '',
              zip: '',
              use_yn: false,
              charge_name: '',
            },
          ],
        },
      ],
    },
    {
      title: 'HS코드 조회',
      url: '/doc/HS코드 조회',
      data: [
        {
          version: 'v1.0',
          url: '/hscds',
          desc: 'HS코드 리스트를 조회합니다.',
          method: 'GET',
          header: default_header,
          header_example: default_header_example,
          response: [
            {
              title: 'hscd',
              required: 'O',
              type: 'String',
              desc: 'HS코드',
            },
            {
              title: 'item_kor',
              required: 'O',
              type: 'String',
              desc: '상세정보',
            },
            {
              title: 'h2',
              required: 'O',
              type: 'String',
              desc: '기준세율',
            },
          ],
          response_example: [
            {
              hscd: '0101',
              item_kor: '살아 있는 말·당나귀·노새·버새',
              h2: '',
            },
            {
              hscd: '01012',
              item_kor: '말',
              h2: '',
            },
          ],
        },
      ],
    },
    {
      title: '로그 조회',
      url: '/doc/로그 조회',
      data: [
        {
          version: 'v1.1',
          url: '/logs/v1.1',
          desc: (
            <ul className='style'>
              <li>주문, 상품정보의 로그를 조회합니다.</li>
              <li>
                로그 조회시 uniq, inq_uniq, prod_no, ol_shop_no 중의 하나의
                호출정보는 반드시 입력해주셔야 합니다.
              </li>
            </ul>
          ),
          method: 'GET',
          header: default_header,
          header_example: default_header_example,
          query: [
            {
              title: 'uniq',
              required: '',
              type: 'String',
              desc: (
                <div>
                  주문 고유번호
                  <div className='description'>(주문로그 검색인 경우 입력)</div>
                </div>
              ),
            },
            {
              title: 'inq_uniq',
              required: '',
              type: 'String',
              desc: (
                <div>
                  문의 고유번호
                  <div className='description'>(문의로그 검색인 경우 입력)</div>
                </div>
              ),
            },
            {
              title: 'prod_no',
              required: '',
              type: 'String',
              desc: (
                <div>
                  SKU상품 고유번호
                  <div className='description'>
                    (SKU상품로그 검색인 경우 입력)
                  </div>
                </div>
              ),
            },
            {
              title: 'ol_shop_no',
              required: '',
              type: 'String',
              desc: (
                <div>
                  상품 고유번호
                  <div className='description'>(상품로그 검색인 경우 입력)</div>
                </div>
              ),
            },
            {
              title: 'sdate',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색시작일
                  <div className='description'>(YYYY-MM-DD)</div>
                </div>
              ),
            },
            {
              title: 'edate',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색종료일
                  <div className='description'>(YYYY-MM-DD)</div>
                </div>
              ),
            },
          ],
          response: [
            {
              title: 'results',
              required: 'O',
              type: 'Object',
              desc: '조회된 로그 리스트',
            },
            {
              title: 'wdate',
              required: 'O',
              type: 'String',
              desc: '등록일',
              indent: 1,
            },
            {
              title: 'name',
              required: 'O',
              type: 'String',
              desc: '작업자 이름',
              indent: 1,
            },
            {
              title: 'uniq',
              required: '',
              type: 'String',
              desc: (
                <div>
                  주문 고유번호
                  <div className='description'>(주문로그 검색인 경우 출력)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'inq_uniq',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  문의 고유번호
                  <div className='description'>(문의로그 검색인 경우 출력)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_no',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  SKU상품 고유번호
                  <div className='description'>
                    (SKU 상품로그 검색인 경우 출력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ol_shop_no',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품 고유번호
                  <div className='description'>(상품로그 검색인 경우 출력)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'content',
              required: 'O',
              type: 'String',
              desc: '작업내역',
              indent: 1,
            },
            {
              title: 'result',
              required: 'O',
              type: 'String',
              desc: '작업결과',
              indent: 1,
            },
          ],
          response_example: {
            results: [
              {
                wdate: '2022-07-19 13:45:46',
                name: '테스터',
                uniq: '3870764799502785130',
                content: '주문분할',
                result: '성공',
              },
              {
                wdate: '2022-07-08 11:31:46',
                name: '테스터',
                uniq: '3870764799502785130',
                content: '주문 상세정보 수정',
                result: '성공',
              },
              {
                wdate: '2022-07-08 11:31:46',
                name: '테스터',
                uniq: '3870764799502785130',
                content: '신규주문 수집',
                result: '성공',
              },
            ],
          },
        },
      ],
    },
    {
      title: '매입처 조회',
      url: '/doc/매입처 조회',
      data: [
        {
          version: 'v1.0',
          url: '/suppliers',
          desc: '사용중인 솔루션에 등록된 매입처 리스트를 조회합니다.',
          method: 'GET',
          header: default_header,
          header_example: default_header_example,
          query: [
            {
              title: 'masking_yn',
              required: '',
              type: 'Boolean',
              desc: (
                <div>
                  개인정보 마스킹 처리 여부
                  <br />
                  <br />
                  <div style={{ paddingBottom: '12px', color: 'gray' }}>
                    <strong>Default value</strong>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <code>
                            <strong>false</strong>
                          </code>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
          ],
          response: [
            {
              title: 'no',
              required: 'O',
              type: 'Number',
              desc: '매입처번호',
            },
            {
              title: 'name',
              required: 'O',
              type: 'String',
              desc: '매입처명',
            },
            {
              title: 'sol_no',
              required: 'O',
              type: 'Number',
              desc: '솔루션번호',
            },
            {
              title: 'type',
              required: 'O',
              type: 'String',
              desc: '업체타입',
            },
            {
              title: 'business_number',
              required: 'O',
              type: 'String',
              desc: '사업자등록번호',
            },
            {
              title: 'corp_number',
              required: 'O',
              type: 'String',
              desc: '법인등록번호',
            },
            {
              title: 'address',
              required: 'O',
              type: 'String',
              desc: '매입처주소',
            },
            {
              title: 'zip',
              required: 'O',
              type: 'String',
              desc: '매입처 우편번호',
            },
            {
              title: 'return_address',
              required: 'O',
              type: 'String',
              desc: '반품주소',
            },
            {
              title: 'return_zip',
              required: 'O',
              type: 'String',
              desc: '반품우편번호',
            },
            {
              title: 'trade_prod_cnt',
              required: 'O',
              type: 'Number',
              desc: '거래상품수',
            },
            {
              title: 'use_yn',
              required: 'O',
              type: 'Boolean',
              desc: '사용여부',
            },
            {
              title: 'charge_name',
              required: 'O',
              type: 'String',
              desc: '담당자명',
            },
            {
              title: 'charge_tel',
              required: 'O',
              type: 'String',
              desc: '담당자 전화번호',
            },
            {
              title: 'charge_email',
              required: 'O',
              type: 'String',
              desc: '담당자 이메일',
            },
          ],
          response_example: [
            {
              no: 68,
              name: '매입처 1',
              sol_no: 99999,
              type: '법인',
              business_number: '123456789',
              corp_number: '',
              address: '',
              zip: '',
              return_address: '서울특별시 구로구 디지털로26길 123 (구로동)',
              return_zip: '08390',
              trade_prod_cnt: 10,
              use_yn: false,
              charge_name: '',
              charge_tel: '',
              charge_email: '',
            },
            {
              no: 71,
              name: '매입처 2',
              sol_no: 99999,
              type: '일반',
              business_number: '',
              corp_number: '',
              address: '',
              zip: '',
              return_address: '',
              return_zip: '',
              trade_prod_cnt: 0,
              use_yn: true,
              charge_name: '',
              charge_tel: '',
              charge_email: '',
            },
          ],
        },
      ],
    },
    {
      title: '원산지 조회',
      url: '/doc/원산지 조회',
      data: [
        {
          version: 'v1.0',
          url: '/origins',
          desc: '솔루션에서 사용하는 원산지 항목을 조회합니다.',
          method: 'GET',
          header: default_header,
          header_example: default_header_example,
          query: [
            {
              title: 'group',
              required: '',
              type: 'String',
              desc: '국내인경우 지역/해외인경우 대륙',
            },
            {
              title: 'name',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색타입
                  <br />
                  <br />
                  <div style={{ paddingBottom: '12px', color: 'gray' }}>
                    <strong>Default value</strong>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>partial</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <div style={{ paddingBottom: '12px', color: 'gray' }}>
                    <strong>Allowed values</strong>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>exact</code>
                          </strong>
                        </td>
                        <td>
                          <strong>완전일치</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>partial</code>
                          </strong>
                        </td>
                        <td>
                          <strong>부분일치</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'combine',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색방식
                  <br />
                  <br />
                  <div style={{ paddingBottom: '12px', color: 'gray' }}>
                    <strong>Default value</strong>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>or</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <div style={{ paddingBottom: '12px', color: 'gray' }}>
                    <strong>Allowed values</strong>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>or</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>and</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
          ],
          response: [
            {
              title: 'no',
              required: 'O',
              type: 'Number',
              desc: '원산지번호',
            },
            {
              title: 'type',
              required: 'O',
              type: 'String',
              desc: '국내 해외 여부',
            },
            {
              title: 'group',
              required: 'O',
              type: 'String',
              desc: '국내인경우 도, 해외인경우 지역',
            },
            {
              title: 'name',
              required: 'O',
              type: 'String',
              desc: '상세지역',
            },
          ],
          response_example: [
            {
              no: 1,
              type: '국내',
              group: '강원',
              name: '강릉시',
            },
            {
              no: 2,
              type: '국내',
              group: '강원',
              name: '고성군',
            },
            {
              no: 3,
              type: '국내',
              group: '강원',
              name: '동해시',
            },
          ],
        },
      ],
    },
    {
      title: '택배사 코드 조회',
      url: '/doc/택배사 코드 조회',
      data: [
        {
          version: 'v1.0',
          url: '/carriers',
          desc: 'PLAYAUTO 에서 사용되는 택배사 코드 리스트를 조회합니다.',
          method: 'GET',
          header: default_header,
          header_example: default_header_example,
          response: [
            {
              title: 'carrier_code',
              required: 'O',
              type: 'Number',
              desc: '택배사코드',
            },
            {
              title: 'carrier_name',
              required: 'O',
              type: 'String',
              desc: '택배사명',
            },
          ],
          response_example: [
            {
              carrier_code: 4,
              carrier_name: '대한통운',
            },
            {
              carrier_code: 5,
              carrier_name: '한진택배',
            },
          ],
        },
      ],
    },
    {
      title: '공지사항 조회',
      url: '/doc/공지사항 조회',
      data: [
        {
          version: 'v1.0',
          url: '/announcements',
          desc: 'PLAYAUTO 공지사항을 조회합니다.',
          method: 'GET',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'start',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  검색 시작 값
                  <div className='description'>
                    기본값 0부터 시작됩니다. 
                    <ul className='style'>
                      <li>start: 0, length: 10 으로 검색한 경우, 검색결과의 index 0-10 건까지 검색됩니다.</li>
                      <li>다음 주문건을 검색하기 위해서는 이전 입력한 start, length 프로퍼티의 값을 고려하여 start: 10 을 입력하여 호출합니다.</li>
                    </ul>
                    <div style={{ padding: '12px 0' }}>
                      <strong>Default value: <code>0</code></strong><br />
                    </div>
                  </div>
                </div>
              )
            },
            {
              title: 'length',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  조회할 갯수
                  <div className='description'>
                    <div style={{ padding: '12px 0' }}>
                      <strong>Default value: <code>20</code></strong>
                    </div>
                  </div>
                </div>
              ),
            },
            {
              title: 'search_key',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색 키<br />
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>title</code>
                          </strong>
                        </td>
                        <td>
                          <strong>공지제목</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>content</code>
                          </strong>
                        </td>
                        <td>
                          <strong>공지내용</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>all</code>
                          </strong>
                        </td>
                        <td>
                          <strong>공지제목 + 공지내용</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <strong>
                    <ul className='style'>
                      <li>
                        <code>값을 넣지않는경우 전체검색됩니다.</code>
                      </li>
                    </ul>
                  </strong>
                </div>
              ),
            },
            {
              title: 'search_word',
              required: '',
              type: 'String',
              desc: '검색어',
            },
            {
              title: 'search_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색타입
                  <br />
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>exact</code>
                          </strong>
                        </td>
                        <td>
                          <strong>완전일치</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>partial</code>
                          </strong>
                        </td>
                        <td>
                          <strong>부분일치</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'noti_type',
              required: '',
              type: 'Array',
              desc: (
                <div>
                  공지유형
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>안내</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>업데이트</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>점검</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>교육</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>장애</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>이벤트</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>정기점검</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>도움말</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>긴급</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <strong>
                    <ul className='style'>
                      <li>
                        <code>값을 넣지 않거나 모든 값이 잘못된 값인 경우 전체 검색됩니다.</code>
                      </li>
                    </ul>
                  </strong>
                </div>
              ),
            },
            {
              title: 'sdate',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색시작일 (기본 한달))
                  <div className='description'>
                    (YYYY-MM-DD)
                    <div style={{ padding: '12px 0' }}>
                      <strong>Default value: <code>1달</code></strong>
                    </div>
                  </div>
                </div>
              ),
            },
            {
              title: 'edate',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색종료일
                  <div className='description'>
                    (YYYY-MM-DD)
                    <div style={{ padding: '12px 0' }}>
                      <strong>Default value: <code>오늘</code></strong>
                    </div>
                  </div>
                </div>
              ),
            },
          ],
          body_example: {
            start: 0,
            length: 10,
            search_key: 'all',
            search_wrod: '플레이오토',
            search_type: 'partial',
            noti_type: ["점검", "업데이트"],
            sdate: '2023-02-28',
            edate: '2023-03-28'
          },
          response: [
            {
              title: 'noti_no',
              required: 'O',
              type: 'Number',
              desc: '공지번호',
            },
            {
              title: 'wdate',
              required: 'O',
              type: 'String',
              desc: '공지 작성일',
            },
            {
              title: 'noti_type',
              required: 'O',
              type: 'String',
              desc: '공지유형',
            },
            {
              title: 'title',
              required: 'O',
              type: 'String',
              desc: '공지 제목',
            },
            {
              title: 'content',
              required: 'O',
              type: 'String',
              desc: '공지내용',
            },
          ],
          response_example: [
            {
              noti_no: 12345,
              wdate: '2023-03-03 21:23:11',
              noti_type: '안내',
              title: '플레이오토 공지 제목',
              content: '반갑습니다. 공지 내용 입니다.'
            }
          ],
          error_example: {
            "error_code": "e1999",
            "messages": [
              "검색 종료일은 검색시작일보다 커야 합니다."
            ]
          }
        },
      ],
    },
    {
      title: 'API 대행사 설정',
      url: '/doc/set_api_agency',
      data: [
        {
          version: 'v1.0',
          url: '/agency',
          desc: 'PLAYAUTO 2.0 솔루션에 연동 대행사를 설정합니다.',
          method: 'POST',
          authorized_m_no: [1, 1957, 225824, 232317, 238055, 6580, 199509, 224022],
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  <div style={{ marginBottom: 10 }}>대행사 설정, 해제 여부</div>
                  <div className='description'>
                    <table>
                      <thead>
                        <tr>
                          <th>Allowed values</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <strong>
                              <code>SET</code>
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>
                              <code>RELEASE</code>
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div style={{ padding: '12px 0' }}>
                      <strong>Default value: <code>SET</code></strong><br />
                    </div>
                  </div>
                </div>
              )
            }
          ],
          body_example: {
            type: 'SET'
          },
          response: [
            {
              title: 'results',
              required: 'O',
              type: 'String',
              desc: '결과값',
            },
          ],
          response_example: {
            results: 'success'
          },
          error_example: {
            "error_code": "e1018",
            "messages": [
              "타입이 올바르지 않습니다. SET, RELEASE 중 하나를 입력해주세요."
            ]
          }
        },
      ],
    },
  ],
});

export default data;
