import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './ApplyCompletePage.module.css';
import AuthContext from 'store/auth-context';

const ApplyCompletePage = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  useEffect(() => {
    if (!authCtx.userInfo) {
      navigate('/');
    }
  });

  return (
    <div className={classes.container}>
      <h1>사용신청</h1>
      <h2>사용 신청이 완료되었습니다.</h2>
      <p>사용 승인 결과는 이메일({authCtx.userInfo.email})로 발송되며, [사용신청]에서도 확인 가능합니다.</p>
      <button className={classes.confirmBtn} onClick={() => navigate('/')}>확인</button>
    </div>
  );
};

export default ApplyCompletePage;