import { useState, useMemo } from 'react';
import classes from '../NoticePage/Notice.module.css';
import moment from 'moment';
import { Link } from 'react-router-dom';
import useFetchData from '../../../hooks/useFetchData';
import DataTable from '../../UI/DataTable';

/**
 * 공지사항 목록 리스팅 페이지 컴포넌트
 */
const NoticeListPage = () => {
  const [noticeList, setNoticeList] = useState([]);
  const option = {method: 'GET'};
  useFetchData('/center/notice', option, res_json => setNoticeList(res_json));

  const columns = useMemo(() => [
    {
      header: '공지번호',
      accessorKey: 'noti_no',
      size: 170
    },
    {
      header: '분류',
      accessorKey: 'noti_type',
      size: 170
    },
    {
      header: '제목',
      accessorKey: 'title',
      cell: cell => <Link to={`/notice/detail/${cell.row.original.noti_no}`}>{cell.row.original.title}</Link>,
      size: 640,
      notSortable: true
    },
    {
      header: '공지일자',
      accessorKey: 'sdate',
      cell: cell => moment(cell.getValue()).format('YYYY-MM-DD'),
      size: 200
    }
  ], []);

  return (
    <>
      <div className={classes.container}>
        <h2 className={classes.heading}>공지사항</h2>
        <h4 className={classes.tableTitle}>공지사항 목록</h4>
        <DataTable 
          columns={columns}
          data={noticeList}
        />
      </div>
    </>
  );
};

export default NoticeListPage;