import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './ApplyStatusPage.module.css';
import AuthContext from '../../../store/auth-context';
import btnCopy from 'assets/img/content_copy.svg';
import Swal from 'sweetalert2';
import useValidatePageAccess from 'hooks/useValidatePageAccess';

const ApplyStatusPage = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const userInfo = authCtx.userInfo || {};
  useValidatePageAccess(userInfo.api_key);
  let statusMsg, statusSubMsg, status = '';

  if (userInfo.api_key === 'pending') {
    statusMsg = '사용 신청이 완료 되었습니다.';
    statusSubMsg = '신청 이메일로 승인 결과가 전송됩니다. 승인 과정에 궁금한 사항이 있으면 1:1 문의를 이용해 주세요.';
    status = 'pending'
  } else if (userInfo.api_key?.length > 20) {
    statusMsg = '사용 신청이 승인 되었습니다.';
    statusSubMsg = 'API Key 발급이 완료되어 API 사용이 가능합니다.';
    status = 'approve'
  } else if (userInfo.api_key === 'rejected') {
    statusMsg = '사용 신청이 거절 되었습니다.';
    statusSubMsg = '거절 사유를 확인할 수 있습니다. 궁금한 사항은 1:1 문의를 이용해 주세요.';
    status = 'rejected'
  }


  // 아코디언 노출설정
  const [viewApplyDetail, setViewApplyDetail] = useState(false);
  const [viewRejectReason, setViewRejectReason] = useState(false);

  // 클립보드 복사
  const copyClipBoard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      Swal.fire('클립보드에 복사되었습니다.');
    } catch (err) {}
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.headerDetails}>
          <p className={classes['c-company']}>{userInfo.company_name}</p>
          <p className={classes['c-name']}><span>{userInfo.username}</span> 님, 안녕하세요!</p>
        </div>
      </div>
      <h5>API 사용신청 현황</h5>
      <div className={classes.main}>
        <div className={classes.applyType}>{userInfo.agency_yn ? '대행사' : '일반'}</div>
        <div className={classes[status]}>
          <p className={classes.msg}>{statusMsg}</p>
          <p className={classes.subMsg}>{statusSubMsg}</p>
          {
            // 승인거절시
            status === 'rejected' &&
              <center>
                <button className={classes.resend} onClick={()=> { navigate('/apply') }}>사용신청 다시하기</button>
              </center>
          }
        </div>
        {
          // 승인완료시
          status === 'approve' &&
            <div className={classes.apikey} >
              <span className={classes.key}>API Key</span>
              <span className={classes.value}>{userInfo.api_key}</span>
              <button onClick={() => {copyClipBoard(userInfo.api_key)}}><img src={btnCopy} alt={'copy button'} title='Copy' /></button>
            </div>
        }
        {
          status === 'rejected' &&
            <>
              <div className={`${classes.pannel} ${viewRejectReason ? classes.active : ''}`}>
                <div className={classes.pannelTitle} onClick={() => {setViewRejectReason(!viewRejectReason)}}>거절 사유 확인</div>
                <ul>
                  <li>
                    <span className={classes.key}>사유</span>
                    <span className={classes.value}>{userInfo.api_rjct_reason}</span>
                  </li>
                </ul>
              </div>
              <br />
            </>
        }
        <div className={`${classes.pannel} ${viewApplyDetail ? classes.active : ''}`}>
          <div className={classes.pannelTitle} onClick={() => {setViewApplyDetail(!viewApplyDetail)}}>사용 신청 상세</div>
          <ul>
            <li>
              <span className={classes.key}>사용자 구분</span>
              <span className={classes.value}>{userInfo.agency_yn ? '대행사' : '일반'}</span>
            </li>
            {
              // 대행사
              userInfo.agency_yn ?
                <>
                  <li>
                    <span className={classes.key}>사업자명</span>
                    <span className={classes.value}>{userInfo.company_name}</span>
                  </li>
                  <li>
                    <span className={classes.key}>사업자번호</span>
                    <span className={classes.value}>{userInfo.biz_reg_no}</span>
                  </li>
                  <li>
                    <span className={classes.key}>노출 대행사명</span>
                    <span className={classes.value}>{userInfo.agency_name}</span>
                  </li>
                  <li>
                    <span className={classes.key}>API사용 용도</span>
                    <span className={classes.value}>{userInfo.api_reason}</span>
                  </li>
                </>
                :
                // 일반
                <>
                  <li>
                    <span className={classes.key}>연동할 솔루션 계정</span>
                    <span className={classes.value}>{userInfo.email}</span>
                  </li>
                </>
            }
            <li>
              <span className={classes.key}>신청자명</span>
              <span className={classes.value}>{userInfo.username}</span>
            </li>
            <li>
              <span className={classes.key}>휴대폰번호</span>
              <span className={classes.value}>{userInfo.htel}</span>
            </li>
            
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ApplyStatusPage;