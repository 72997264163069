import { default_header, default_header_example } from './defaultData';

const data = (userInfo) => ({
  title: '재고',
  unrecognized_aff_no: [1051],
  children: [
    {
      title: 'SKU상품 리스트 조회',
      url: '/doc/SKU상품 리스트 조회',
      data: [
        {
          version: 'v1.2',
          url: '/stock/list/v1.2',
          method: 'POST',
          desc: (
            <ul className='style'>
              <li>SKU상품 리스트를 조회합니다.</li>
              <li>기본 날짜 검색일은 90일 입니다.</li>
            </ul>
          ),
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'start',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  조회할 시작 카운트
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'limit',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  조회할 갯수
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>100</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'search_key',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색 키
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>all</code>
                          </strong>
                        </td>
                        <td>
                          <strong>전체검색</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>sku_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>stock_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>재고관리코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>prod_name</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU상품명</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'search_word',
              required: '',
              type: 'String[]',
              desc: '검색어',
            },
            {
              title: 'search_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>partial</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>exact</code>
                          </strong>
                        </td>
                        <td>
                          <strong>완전일치</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>partial</code>
                          </strong>
                        </td>
                        <td>
                          <strong>부분일치</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'data_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  날짜검색대상
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>등록일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>mdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>수정일</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sdate',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색시작일
                  <div className='description'>
                    ( YYYY-MM-DD | YYYY-MM-DD HH:MM:SS )<br />
                    미입력시 기본 90일로 조회됩니다.
                  </div>
                </div>
              ),
            },
            {
              title: 'edate',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색종료일
                  <div className='description'>
                    ( YYYY-MM-DD | YYYY-MM-DD HH:MM:SS )
                  </div>
                </div>
              ),
            },
            ...(userInfo.sol_no === 104083 ? [{
              title: 'sub_sol_no_list',
              required: '',
              type: 'Number[]',
              desc: (
                <div>
                  하위계정 sol_no 리스트
                  <div className='description'>
                    (값이 없는 경우 하위계정 전체 조회)
                  </div>
                </div>
              ),
            }] : []),
          ],
          body_example: {
            start: 0,
            limit: 100,
            search_key: 'all',
            search_word: ['456'],
            search_type: 'partial',
            date_type: 'wdate',
            sdate: '2020-12-25',
            edate: '2021-01-25',
          },
          response: [
            {
              title: 'results',
              required: 'O',
              type: 'Array',
              desc: '결과',
            },
            {
              title: 'sol_no',
              required: 'O',
              type: 'Number(8)',
              desc: '솔루션번호',
              indent: 1,
            },
            {
              title: 'ori_prod_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  원본 SKU상품 고유번호
                  <div className='description'>
                    (조회하는 SKU상품이 사본인경우 해당)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_no',
              required: 'O',
              type: 'Number(10)',
              desc: 'SKU상품고유번호',
              indent: 1,
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String(40)',
              desc: 'SKU코드',
              indent: 1,
            },
            {
              title: 'stock_cd',
              required: 'O',
              type: 'String(100)',
              desc: '재고관리코드',
              indent: 1,
            },
            {
              title: 'prod_name',
              required: 'O',
              type: 'String(200)',
              desc: 'SKU상품명',
              indent: 1,
            },
            {
              title: 'main_depot_no',
              required: 'O',
              type: 'Number(10)',
              desc: 'SKU상품의 대표배송처 번호',
              indent: 1,
            },
            {
              title: 'made_date',
              required: 'O',
              type: 'String(10)',
              desc: '제조일자',
              indent: 1,
            },
            {
              title: 'expire_date',
              required: 'O',
              type: 'String(10)',
              desc: '유효일자',
              indent: 1,
            },
            {
              title: 'prod_width',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  상품규격(가로)
                  <div className='description'>(data type: Decimal(8,3))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'prod_height',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  상품규격(세로)
                  <div className='description'>(data type: Decimal(8,3))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'prod_depth',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  상품규격(높이)
                  <div className='description'>(data type: Decimal(8,3))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'prod_weight',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  상품규격(무게)
                  <div className='description'>(data type: Decimal(8,3))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'bundle_avail_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  합포장여부
                  <div className='description'>(1: 가능, 0: 불가능)</div>
                </div>
              ),
            },
            {
              title: 'in_unit_cnt',
              required: 'O',
              type: 'Number(10)',
              desc: '입고단위수량',
              indent: 1,
            },
            {
              title: 'in_unit',
              required: 'O',
              type: 'String(10)',
              desc: '입고단위',
              indent: 1,
            },
            {
              title: 'supp_no',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  매입처번호
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'supp_name',
              required: 'O',
              type: 'String(50)',
              desc: (
                <div>
                  매입처
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'style_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  스타일코드
                  <div className='description'>
                    (스타일등록 상품인경우 해당)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'wdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  등록시간
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
            },
            {
              title: 'mdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  수정시간
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
            },
            {
              title: 'madein_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  원산지 국내외 여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>국내</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>해외</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'madein_group',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  원산지 시/도
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'madein_name',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  원산지 읍/면
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'madein_etc',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  기타원산지인경우
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'multi_madein_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  복수원산지여부
                  <div className='description'>
                    (1: 복수원산지, 0: 복수원산지 아님)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'tax_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  과세여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>과세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>영세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>면세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>비과세</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'brand',
              required: 'O',
              type: 'String(50)',
              desc: '브랜드',
            },
            {
              title: 'model',
              required: 'O',
              type: 'String(50)',
              desc: '모델명',
            },
            {
              title: 'maker',
              required: 'O',
              type: 'String(50)',
              desc: '제조사',
            },
            {
              title: 'maker_eng',
              required: 'O',
              type: 'String(50)',
              desc: '영문 제조사',
            },
            {
              title: 'global_barcode_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  UPC/EAN
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>UPC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EAN</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'global_barcode',
              required: 'O',
              type: 'String(13)',
              desc: (
                <div>
                  UPC/EAN코드
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'barcode',
              required: 'O',
              type: 'String(50)',
              desc: '바코드',
              indent: 1,
            },
            {
              title: 'hscd',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  hscd 코드 4~10자리
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'isbn',
              required: 'O',
              type: 'Number(13)',
              desc: (
                <div>
                  isbn 코드 13자리
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'use_age',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  사용연령
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>전체</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>0~3세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>4~6세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>7~12세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>13~15세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>16~19세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>20세이상</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'prod_img',
              required: 'O',
              type: 'String(255)',
              desc: 'SKU상품 이미지',
            },
            {
              title: 'contents',
              required: 'O',
              type: 'String(65535)',
              desc: '상세설명',
            },
            {
              title: 'product_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  판매가
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
            },
            {
              title: 'cost_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  원가
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
            },
            {
              title: 'supply_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  공급가
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
            },
            {
              title: 'options',
              required: 'O',
              type: 'object[]',
              desc: '옵션',
            },
            {
              title: 'attri',
              required: 'O',
              type: 'String(50)',
              desc: '옵션타입',
              indent: 1,
            },
            {
              title: 'attri_type',
              required: 'O',
              type: 'String(30)',
              desc: '옵션명',
              indent: 1,
            },
            {
              title: 'depots',
              required: 'O',
              type: 'object[]',
              desc: '배송처 정보',
            },
            {
              title: 'depot_no',
              required: 'O',
              type: 'Number(11)',
              desc: '배송처 번호',
              indent: 1,
            },
            {
              title: 'depot_name',
              required: 'O',
              type: 'String(45)',
              desc: '배송처명',
              indent: 1,
            },
            {
              title: 'real_stock',
              required: 'O',
              type: 'Number(8)',
              desc: '실재고',
              indent: 1,
            },
            {
              title: 'safe_stock',
              required: 'O',
              type: 'Number(8)',
              desc: '안전재고',
              indent: 1,
            },
            {
              title: 'total',
              required: 'O',
              type: 'Number',
              desc: '총 카운트',
            },
          ],
          response_example: {
            results: [
              {
                  sol_no: 1,
                  ori_prod_no: 9999999,
                  prod_no: 9988888,
                  sku_cd: "TEST_SKU_CD",
                  stock_cd: null,
                  prod_name: "TEST",
                  main_depot_no: 99999,
                  made_date: "0000-00-00",
                  expire_date: "0000-00-00",
                  prod_width: 0,
                  prod_height: 0,
                  prod_depth: 0,
                  prod_weight: 0,
                  bundle_avail_yn: 1,
                  in_unit_cnt: null,
                  in_unit: "EA",
                  supp_no: null,
                  supp_name: null,
                  style_no: null,
                  wdate: "2024-02-22 11:54:20",
                  mdate: "2024-02-22 12:03:11",
                  madein_type: null,
                  madein_group: null,
                  madein_name: null,
                  madein_etc: "TEST",
                  multi_madein_yn: 0,
                  tax_type: "과세",
                  brand: "",
                  model: "",
                  maker: "",
                  maker_eng: "",
                  global_barcode_type: null,
                  global_barcode: null,
                  barcode: "",
                  hscd: null,
                  isbn: null,
                  use_age: "",
                  prod_img: "",
                  contents: "TEST DATA",
                  product_price: 0,
                  cost_price: 0,
                  supply_price: 0,
                  options: [
                      {
                          attri: "색상",
                          attri_type: "색상"
                      }
                  ],
                  depots: [
                      {
                          depot_no: 99999,
                          depot_name: "TEST",
                          real_stock: 90,
                          safe_stock: 50
                      }
                  ]
              }
          ],
            total: 1
          },
        },
      ],
    },
    {
      title: 'SKU상품 상세 리스트 조회',
      url: '/doc/SKU상품 상세 리스트 조회',
      data: [
        {
          version: 'v1.1',
          url: '/stock/detail-list/v1.1',
          authorized_m_no: [91809, 91810, 91812, 223054, 88912, 88913, 88916, 64370],
          desc: (
            <div>
              사용중인 솔루션에 등록된 SKU상품 상세정보 리스트를 조회합니다.
              <br />
              <br />
              🚨 상세정보를 리스팅하는 API로, 재고현황 조회 API 보다 조회
              소요시간이 더 길어질 수 있습니다.🚨
              <br />
              가급적{' '}
              <a href='/doc/재고현황조회' style={{ color: 'blue' }}>
                재고현황 조회
              </a>{' '}
              혹은{' '}
              <a href='/doc/SKU상품 상세 조회' style={{ color: 'blue' }}>
                SKU상품 상세 조회
              </a>{' '}
              API를 이용해주시기 바랍니다.
            </div>
          ),
          method: 'POST',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'start',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  조회할 시작 카운트
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'limit',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  조회할 갯수
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>100</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'orderbyType',
              required: '',
              type: 'String',
              desc: (
                <div>
                  정렬타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>DESC</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>ASC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>DESC</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'orderbyColumn',
              required: '',
              type: 'String',
              desc: (
                <div>
                  정렬대상
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>등록일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>sku_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>stock_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>재고관리코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>stock_status</code>
                          </strong>
                        </td>
                        <td>
                          <strong>재고상태</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>stock_cnt_real</code>
                          </strong>
                        </td>
                        <td>
                          <strong>실재고</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>stock_cnt_safe</code>
                          </strong>
                        </td>
                        <td>
                          <strong>안전재고</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'search_key',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색 키
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>sku_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>stock_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>재고관리코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>prod_name</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU상품명</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <strong>
                    <ul className='style'>
                      <li>
                        <code>값을 넣지않는경우 전체검색됩니다.</code>
                      </li>
                    </ul>
                  </strong>
                </div>
              ),
            },
            {
              title: 'search_word',
              required: '',
              type: 'String',
              desc: '검색어',
            },
            {
              title: 'search_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>partial</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>exact</code>
                          </strong>
                        </td>
                        <td>
                          <strong>완전일치</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>partial</code>
                          </strong>
                        </td>
                        <td>
                          <strong>부분일치</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'date_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  날짜검색대상
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>등록일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>mdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>수정일</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sdate',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색시작일
                  <div className='description'>
                    ( YYYY-MM-DD | YYYY-MM-DD HH:MM:SS )<br />
                    미입력시 기본 90일로 조회됩니다.
                  </div>
                </div>
              ),
            },
            {
              title: 'edate',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색종료일
                  <div className='description'>
                    ( YYYY-MM-DD | YYYY-MM-DD HH:MM:SS )
                  </div>
                </div>
              ),
            },
            {
              title: 'supp_no',
              required: '',
              type: 'Number',
              desc: '매입처 번호',
            },
            {
              title: 'depot_no',
              required: '',
              type: 'Number',
              desc: '배송처 번호',
            },
            {
              title: 'state',
              required: '',
              type: 'String',
              desc: (
                <div>
                  재고상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>재고부족</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'multi_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  멀티검색 키
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>sku_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>prod_name</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU상품명</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>stock_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>재고관리코드</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'multi_search_word',
              required: '',
              type: 'String',
              desc: (
                <div>
                  멀티검색어
                  <div className='description'>
                    ( \n 으로 묶어서 전송 ) ex{'>'} 하나\n둘\n셋\n넷
                  </div>
                </div>
              ),
            },
          ],
          body_example: {
            start: 0,
            limit: 100,
            orderbyColumn: 'wdate',
            orderbyType: 'DESC',
            search_key: 'all',
            search_word: '',
            search_type: 'partial',
            date_type: 'wdate',
            sdate: '2020-12-25',
            edate: '2021-01-25',
          },
          response: [
            {
              title: 'results',
              required: 'O',
              type: 'Array',
              desc: '결과',
            },
            {
              title: 'sol_no',
              required: 'O',
              type: 'Number(8)',
              desc: '솔루션번호',
              indent: 1,
            },
            {
              title: 'prod_no',
              required: 'O',
              type: 'Number(10)',
              desc: 'SKU상품고유번호',
              indent: 1,
            },
            {
              title: 'depot_name',
              required: 'O',
              type: 'String(45)',
              desc: '배송처이름',
              indent: 1,
            },
            {
              title: 'stock_status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  재고상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>재고부족</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String(40)',
              desc: 'SKU코드',
              indent: 1,
            },
            {
              title: 'stock_cd',
              required: 'O',
              type: 'String(100)',
              desc: (
                <div>
                  재고관리코드
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_img',
              required: 'O',
              type: 'String(255)',
              desc: '이미지',
              indent: 1,
            },
            {
              title: 'prod_name',
              required: 'O',
              type: 'String(200)',
              desc: 'SKU상품명',
              indent: 1,
            },
            {
              title: 'brand',
              required: 'O',
              type: 'String(50)',
              desc: '브랜드',
              indent: 1,
            },
            {
              title: 'maker',
              required: 'O',
              type: 'String(50)',
              desc: '제조사',
              indent: 1,
            },
            {
              title: 'barcode',
              required: 'O',
              type: 'String(50)',
              desc: '바코드',
              indent: 1,
            },
            {
              title: 'made_date',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  제조일자
                  <div className='description'>(YYYY-MM-DD)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'expire_date',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  유효일자
                  <div className='description'>(YYYY-MM-DD)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'wdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  SKU상품 등록일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'model_no',
              required: 'O',
              type: 'String(50)',
              desc: (
                <div>
                  모델 번호
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cost_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  원가
                  <div className='description'>(data type: Decimal(12,2))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'supply_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  공급가
                  <div className='description'>(data type: Decimal(12,2))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'supp_name',
              required: 'O',
              type: 'String(50)',
              desc: (
                <div>
                  매입처
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'stock_cnt',
              required: 'O',
              type: 'Number(8)',
              desc: '판매가능재고',
              indent: 1,
            },
            {
              title: 'in_cnt_accum',
              required: 'O',
              type: 'Number(10)',
              desc: '입고수량',
              indent: 1,
            },
            {
              title: 'out_cnt_accum',
              required: 'O',
              type: 'Number(10)',
              desc: '출고수량',
              indent: 1,
            },
            {
              title: 'out_scheduled',
              required: 'O',
              type: 'Number(10)',
              desc: '출고예정수',
              indent: 1,
            },
            {
              title: 'sale_cnt_accum',
              required: 'O',
              type: 'Number(10)',
              desc: '구매수량',
              indent: 1,
            },
            {
              title: 'return_cnt_accum',
              required: 'O',
              type: 'Number(10)',
              desc: '반품수량',
              indent: 1,
            },
            {
              title: 'exchange_cnt_accum',
              required: 'O',
              type: 'Number(10)',
              desc: '교환수량',
              indent: 1,
            },
            {
              title: 'unavail_out_cnt',
              required: 'O',
              type: 'Number(10)',
              desc: '미출고',
              indent: 1,
            },
            {
              title: 'link_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  연동상품여부
                  <div className='description'>
                    (1: 연동상품 있음, 0: 연동상품 없음)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'link_set_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  연동세트상품여부
                  <div className='description'>
                    (1: 연동세트상품 있음, 0: 연동세트상품 없음)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'link_ord_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  연동상품여부
                  <div className='description'>
                    (1: 연동주문 있음, 0: 연동주문 없음)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'memo_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '메모여부',
              indent: 1,
            },
            {
              title: 'memo_complete_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '완료여부',
              indent: 1,
            },
            {
              title: 'alloc_rule_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '재고할당여부',
              indent: 1,
            },
            {
              title: 'realloc_rule_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '재고재할당여부',
              indent: 1,
            },
            {
              title: 'ori_prod_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  원본 SKU상품 고유번호
                  <div className='description'>
                    (조회하는 SKU상품이 사본인경우 해당)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'style_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  스타일코드
                  <div className='description'>
                    (스타일등록 상품인경우 해당)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'mdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  SKU상품 수정시간
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_noti_data',
              required: 'O',
              type: 'Object',
              desc: (
                <div>
                  상품정보제공고시
                  <div className='description' style={{ padding: '12px 0' }}>
                    <ul style={{ color: 'gray' }}>
                      <li>
                        상품분류코드에 따라 infoDetail 안에 키 값이 달라질 수
                        있습니다.
                      </li>
                      <li>
                        Default value:
                        <code style={{ fontSize: '15px' }}>{'{}'}</code>
                      </li>
                    </ul>
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'infoCode',
              required: 'O',
              type: 'String',
              desc: '상품분류코드',
              indent: 2,
            },
            {
              title: 'infoName',
              required: 'O',
              type: 'String',
              desc: '상품분류명',
              indent: 2,
            },
            {
              title: 'infoDetail',
              required: 'O',
              type: 'Object',
              desc: '상품정보제공고시 데이터',
              indent: 2,
            },
            {
              title: 'madein_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  원산지 타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>{}</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>국내</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>해외</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'madein_group',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  원산지 그룹
                  <div className='description'>(국내: 시/도, 해외: 대륙)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'madein_name',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  원산지 그룹
                  <div className='description'>
                    (국내: 시/군/구, 해외: 국가)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'madein_etc',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  기타원산지인경우
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'multi_madein_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  복수원산지여부
                  <div className='description'>
                    (1: 복수원산지, 0: 복수원산지 아님)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'tax_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  과세타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>과세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>면세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>영세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>비과세</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'model',
              required: 'O',
              type: 'String(50)',
              desc: '모델명',
              indent: 1,
            },
            {
              title: 'maker_eng',
              required: 'O',
              type: 'String(50)',
              desc: '영문 제조사',
              indent: 1,
            },
            {
              title: 'bundle_avail_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  합포장여부
                  <div className='description'>(1: 가능 0: 불가능)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_width',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  상품규격(가로)
                  <div className='description'>(data type: Decimal(8,3))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_height',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  상품규격(세로)
                  <div className='description'>(data type: Decimal(8,3))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_depth',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  상품규격(높이)
                  <div className='description'>(data type: Decimal(8,3))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_weight',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  상품규격(가로)
                  <div className='description'>(data type: Decimal(8,3))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'global_barcode_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  UPC/EAN
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>UPC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EAN</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'global_barcode',
              required: 'O',
              type: 'String(13)',
              desc: (
                <div>
                  UPC/EAN 코드
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'hscd',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  HS코드 (4~10자리)
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'isbn',
              required: 'O',
              type: 'Number(13)',
              desc: (
                <div>
                  isbn코드 (13자리)
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'supp_no',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  매입처번호
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'delivery_vendor',
              required: 'O',
              type: 'Number(8)',
              desc: '배송처번호',
              indent: 1,
            },
            {
              title: 'use_age',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  사용연령
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>전체</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>0~3세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>4~6세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>7~12세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>13~15세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>16~19세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>20세이상</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'contents',
              required: 'O',
              type: 'String(65535)',
              desc: '상세설명',
              indent: 1,
            },
            {
              title: 'product_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  판매가
                  <div className='description'>(data type: Decimal(12,2))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'real_stock',
              required: 'O',
              type: 'Number(8)',
              desc: '실재고',
              indent: 1,
            },
            {
              title: 'safe_stock',
              required: 'O',
              type: 'Number(8)',
              desc: '안전재고',
              indent: 1,
            },
            {
              title: 'options',
              required: 'O',
              type: 'Object[]',
              desc: '옵션',
              indent: 1,
            },
            {
              title: 'attri',
              required: 'O',
              type: 'String(50)',
              desc: '옵션타입',
              indent: 2,
            },
            {
              title: 'attri_type',
              required: 'O',
              type: 'String(30)',
              desc: '옵션명',
              indent: 2,
            },
          ],
          response_example: {
            results: [
              {
                prod_no: 9999999999,
                depot_name: '기본배송처',
                stock_status: '정상',
                sku_cd: 'TEST000002',
                stock_cd: null,
                prod_img:
                  'https://s3-ap-northeast-2.amazonaws.com/2021110800000.jpg',
                prod_name: 'SKU상품등록테스트',
                brand: '브랜드명',
                maker: '제조사명',
                barcode: '',
                made_date: '0000-00-00',
                expire_date: '2022-06-01',
                wdate: '2021-11-08 16:52:27',
                model_no: 'AABD7734XYZ',
                cost_price: 2000,
                supply_price: 5000,
                supp_name: null,
                stock_cnt: 200,
                in_cnt_accum: 999,
                out_cnt_accum: 799,
                out_scheduled: 0,
                sale_cnt_accum: 0,
                return_cnt_accum: 0,
                exchange_cnt_accum: 0,
                unavail_out_cnt: 0,
                link_yn: 0,
                link_set_yn: 0,
                link_ord_yn: 0,
                memo_yn: 0,
                memo_complete_yn: 0,
                alloc_rule_yn: 0,
                realloc_rule_yn: 0,
                ori_prod_no: null,
                sol_no: 999999,
                style_no: 9999999,
                mdate: '2021-11-08 17:11:00',
                prod_noti_data: {
                  infoCode: 'Wear2023',
                  infoName: '의류',
                  infoDetail: {
                    material: '1',
                    color: '2',
                    size: '3',
                    manufacturer: '4',
                    made_in: '5',
                    caution: '6',
                    release: '7',
                    warranty: '8',
                    customer_service: '9',
                  },
                },
                madein_type: '국내',
                madein_group: '강원',
                madein_name: '고성군',
                madein_etc: '',
                multi_madein_yn: 0,
                tax_type: '영세',
                model: '',
                maker_eng: 'english maker',
                bundle_avail_yn: 1,
                prod_width: 10,
                prod_height: 20,
                prod_depth: 30,
                prod_weight: 40,
                global_barcode_type: null,
                global_barcode: '',
                isbn: 1313131313131,
                supp_no: null,
                delivery_vendor: 999999,
                use_age: '전체',
                contents: '상세설명 내용',
                product_price: 0,
                real_stock: 200,
                safe_stock: 10,
                options: [
                  {
                    attri: '노랑',
                    attri_type: '색상',
                  },
                  {
                    attri: 'L',
                    attri_type: '사이즈',
                  },
                  {
                    attri: '새틴',
                    attri_type: '재질',
                  },
                ],
              },
            ],
          },
        },
        {
          version: 'v1.0',
          url: '/stock/detail-list',
          authorized_m_no: [91809, 91810, 91812, 223054, 88912, 88913, 88916, 64370],
          desc: (
            <div>
              사용중인 솔루션에 등록된 SKU상품 상세정보 리스트를 조회합니다.
              <br />
              <br />
              🚨 상세정보를 리스팅하는 API로, 재고현황 조회 API 보다 조회
              소요시간이 더 길어질 수 있습니다.🚨
              <br />
              가급적{' '}
              <a href='/doc/재고현황조회' style={{ color: 'blue' }}>
                재고현황 조회
              </a>{' '}
              혹은{' '}
              <a href='/doc/SKU상품 상세 조회' style={{ color: 'blue' }}>
                SKU상품 상세 조회
              </a>{' '}
              API를 이용해주시기 바랍니다.
            </div>
          ),
          method: 'POST',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'start',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  조회할 시작 카운트
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'limit',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  조회할 갯수
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>100</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'orderbyType',
              required: '',
              type: 'String',
              desc: (
                <div>
                  정렬타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>DESC</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>ASC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>DESC</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'orderbyColumn',
              required: '',
              type: 'String',
              desc: (
                <div>
                  정렬대상
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>등록일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>sku_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>stock_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>재고관리코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>stock_status</code>
                          </strong>
                        </td>
                        <td>
                          <strong>재고상태</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>stock_cnt_real</code>
                          </strong>
                        </td>
                        <td>
                          <strong>실재고</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>stock_cnt_safe</code>
                          </strong>
                        </td>
                        <td>
                          <strong>안전재고</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'search_key',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색 키
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>sku_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>stock_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>재고관리코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>prod_name</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU상품명</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <strong>
                    <ul className='style'>
                      <li>
                        <code>값을 넣지않는경우 전체검색됩니다.</code>
                      </li>
                    </ul>
                  </strong>
                </div>
              ),
            },
            {
              title: 'search_word',
              required: '',
              type: 'String',
              desc: '검색어',
            },
            {
              title: 'search_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>partial</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>exact</code>
                          </strong>
                        </td>
                        <td>
                          <strong>완전일치</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>partial</code>
                          </strong>
                        </td>
                        <td>
                          <strong>부분일치</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'date_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  날짜검색대상
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>등록일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>mdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>수정일</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sdate',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색시작일
                  <div className='description'>
                    ( YYYY-MM-DD | YYYY-MM-DD HH:MM:SS )<br />
                    미입력시 기본 90일로 조회됩니다.
                  </div>
                </div>
              ),
            },
            {
              title: 'edate',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색종료일
                  <div className='description'>
                    ( YYYY-MM-DD | YYYY-MM-DD HH:MM:SS )
                  </div>
                </div>
              ),
            },
            {
              title: 'supp_no',
              required: '',
              type: 'Number',
              desc: '매입처 번호',
            },
            {
              title: 'depot_no',
              required: '',
              type: 'Number',
              desc: '배송처 번호',
            },
            {
              title: 'state',
              required: '',
              type: 'String',
              desc: (
                <div>
                  재고상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>재고부족</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'multi_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  멀티검색 키
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>sku_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>prod_name</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU상품명</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>stock_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>재고관리코드</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'multi_search_word',
              required: '',
              type: 'String',
              desc: (
                <div>
                  멀티검색어
                  <div className='description'>
                    ( \n 으로 묶어서 전송 ) ex{'>'} 하나\n둘\n셋\n넷
                  </div>
                </div>
              ),
            },
          ],
          body_example: {
            start: 0,
            limit: 100,
            orderbyColumn: 'wdate',
            orderbyType: 'DESC',
            search_key: 'all',
            search_word: '',
            search_type: 'partial',
            date_type: 'wdate',
            sdate: '2020-12-25',
            edate: '2021-01-25',
          },
          response: [
            {
              title: 'results',
              required: 'O',
              type: 'Array',
              desc: '결과',
            },
            {
              title: 'sol_no',
              required: 'O',
              type: 'Number(8)',
              desc: '솔루션번호',
              indent: 1,
            },
            {
              title: 'prod_no',
              required: 'O',
              type: 'Number(10)',
              desc: 'SKU상품고유번호',
              indent: 1,
            },
            {
              title: 'depot_name',
              required: 'O',
              type: 'String(45)',
              desc: '배송처이름',
              indent: 1,
            },
            {
              title: 'stock_status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  재고상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>재고부족</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String(40)',
              desc: 'SKU코드',
              indent: 1,
            },
            {
              title: 'stock_cd',
              required: 'O',
              type: 'String(100)',
              desc: (
                <div>
                  재고관리코드
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_img',
              required: 'O',
              type: 'String(255)',
              desc: '이미지',
              indent: 1,
            },
            {
              title: 'prod_name',
              required: 'O',
              type: 'String(200)',
              desc: 'SKU상품명',
              indent: 1,
            },
            {
              title: 'brand',
              required: 'O',
              type: 'String(50)',
              desc: '브랜드',
              indent: 1,
            },
            {
              title: 'maker',
              required: 'O',
              type: 'String(50)',
              desc: '제조사',
              indent: 1,
            },
            {
              title: 'barcode',
              required: 'O',
              type: 'String(50)',
              desc: '바코드',
              indent: 1,
            },
            {
              title: 'made_date',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  제조일자
                  <div className='description'>(YYYY-MM-DD)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'expire_date',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  유효일자
                  <div className='description'>(YYYY-MM-DD)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'wdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  SKU상품 등록일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'model_no',
              required: 'O',
              type: 'String(50)',
              desc: (
                <div>
                  모델 번호
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cost_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  원가
                  <div className='description'>(data type: Decimal(12,2))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'supply_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  공급가
                  <div className='description'>(data type: Decimal(12,2))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'supp_name',
              required: 'O',
              type: 'String(50)',
              desc: (
                <div>
                  매입처
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'stock_cnt',
              required: 'O',
              type: 'Number(8)',
              desc: '판매가능재고',
              indent: 1,
            },
            {
              title: 'in_cnt_accum',
              required: 'O',
              type: 'Number(10)',
              desc: '입고수량',
              indent: 1,
            },
            {
              title: 'out_cnt_accum',
              required: 'O',
              type: 'Number(10)',
              desc: '출고수량',
              indent: 1,
            },
            {
              title: 'out_scheduled',
              required: 'O',
              type: 'Number(10)',
              desc: '출고예정수',
              indent: 1,
            },
            {
              title: 'sale_cnt_accum',
              required: 'O',
              type: 'Number(10)',
              desc: '구매수량',
              indent: 1,
            },
            {
              title: 'return_cnt_accum',
              required: 'O',
              type: 'Number(10)',
              desc: '반품수량',
              indent: 1,
            },
            {
              title: 'exchange_cnt_accum',
              required: 'O',
              type: 'Number(10)',
              desc: '교환수량',
              indent: 1,
            },
            {
              title: 'unavail_out_cnt',
              required: 'O',
              type: 'Number(10)',
              desc: '미출고',
              indent: 1,
            },
            {
              title: 'link_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  연동상품여부
                  <div className='description'>
                    (1: 연동상품 있음, 0: 연동상품 없음)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'link_set_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  연동세트상품여부
                  <div className='description'>
                    (1: 연동세트상품 있음, 0: 연동세트상품 없음)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'link_ord_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  연동상품여부
                  <div className='description'>
                    (1: 연동주문 있음, 0: 연동주문 없음)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'memo_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '메모여부',
              indent: 1,
            },
            {
              title: 'memo_complete_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '완료여부',
              indent: 1,
            },
            {
              title: 'alloc_rule_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '재고할당여부',
              indent: 1,
            },
            {
              title: 'realloc_rule_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '재고재할당여부',
              indent: 1,
            },
            {
              title: 'ori_prod_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  원본 SKU상품 고유번호
                  <div className='description'>
                    (조회하는 SKU상품이 사본인경우 해당)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'style_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  스타일코드
                  <div className='description'>
                    (스타일등록 상품인경우 해당)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'mdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  SKU상품 수정시간
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_noti_data',
              required: 'O',
              type: 'Object',
              desc: (
                <div>
                  상품정보제공고시
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>{}</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'infoCode',
              required: 'O',
              type: 'String',
              desc: '상품분류코드',
              indent: 2,
            },
            {
              title: 'infoName',
              required: 'O',
              type: 'String',
              desc: '상품분류명',
              indent: 2,
            },
            {
              title: 'prod_info_1',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시1',
              indent: 2,
            },
            {
              title: 'prod_info_2',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시2',
              indent: 2,
            },
            {
              title: 'prod_info_3',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시3',
              indent: 2,
            },
            {
              title: 'prod_info_4',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시4',
              indent: 2,
            },
            {
              title: 'prod_info_5',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시5',
              indent: 2,
            },
            {
              title: 'madein_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  원산지 타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>{}</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>국내</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>해외</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'madein_group',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  원산지 그룹
                  <div className='description'>(국내: 시/도, 해외: 대륙)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'madein_name',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  원산지 그룹
                  <div className='description'>
                    (국내: 시/군/구, 해외: 국가)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'madein_etc',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  기타원산지인경우
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'multi_madein_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  복수원산지여부
                  <div className='description'>
                    (1: 복수원산지, 0: 복수원산지 아님)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'tax_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  과세타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>과세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>면세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>영세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>비과세</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'model',
              required: 'O',
              type: 'String(50)',
              desc: '모델명',
              indent: 1,
            },
            {
              title: 'maker_eng',
              required: 'O',
              type: 'String(50)',
              desc: '영문 제조사',
              indent: 1,
            },
            {
              title: 'bundle_avail_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  합포장여부
                  <div className='description'>(1: 가능 0: 불가능)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_width',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  상품규격(가로)
                  <div className='description'>(data type: Decimal(8,3))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_height',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  상품규격(세로)
                  <div className='description'>(data type: Decimal(8,3))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_depth',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  상품규격(높이)
                  <div className='description'>(data type: Decimal(8,3))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_weight',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  상품규격(가로)
                  <div className='description'>(data type: Decimal(8,3))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'global_barcode_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  UPC/EAN
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>UPC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EAN</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'global_barcode',
              required: 'O',
              type: 'String(13)',
              desc: (
                <div>
                  UPC/EAN 코드
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'hscd',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  HS코드 (4~10자리)
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'isbn',
              required: 'O',
              type: 'Number(13)',
              desc: (
                <div>
                  isbn코드 (13자리)
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'supp_no',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  매입처번호
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'delivery_vendor',
              required: 'O',
              type: 'Number(8)',
              desc: '배송처번호',
              indent: 1,
            },
            {
              title: 'use_age',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  사용연령
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>전체</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>0~3세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>4~6세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>7~12세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>13~15세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>16~19세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>20세이상</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'contents',
              required: 'O',
              type: 'String(65535)',
              desc: '상세설명',
              indent: 1,
            },
            {
              title: 'product_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  판매가
                  <div className='description'>(data type: Decimal(12,2))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'real_stock',
              required: 'O',
              type: 'Number(8)',
              desc: '실재고',
              indent: 1,
            },
            {
              title: 'safe_stock',
              required: 'O',
              type: 'Number(8)',
              desc: '안전재고',
              indent: 1,
            },
            {
              title: 'options',
              required: 'O',
              type: 'Object[]',
              desc: '옵션',
              indent: 1,
            },
            {
              title: 'attri',
              required: 'O',
              type: 'String(50)',
              desc: '옵션타입',
              indent: 2,
            },
            {
              title: 'attri_type',
              required: 'O',
              type: 'String(30)',
              desc: '옵션명',
              indent: 2,
            },
          ],
          response_example: {
            results: [
              {
                prod_no: 9999999999,
                depot_name: '기본배송처',
                stock_status: '정상',
                sku_cd: 'TEST000002',
                stock_cd: null,
                prod_img:
                  'https://s3-ap-northeast-2.amazonaws.com/2021110800000.jpg',
                prod_name: 'SKU상품등록테스트',
                brand: '브랜드명',
                maker: '제조사명',
                barcode: '',
                made_date: '0000-00-00',
                expire_date: '2022-06-01',
                wdate: '2021-11-08 16:52:27',
                model_no: 'AABD7734XYZ',
                cost_price: 2000,
                supply_price: 5000,
                supp_name: null,
                stock_cnt: 200,
                in_cnt_accum: 999,
                out_cnt_accum: 799,
                out_scheduled: 0,
                sale_cnt_accum: 0,
                return_cnt_accum: 0,
                exchange_cnt_accum: 0,
                unavail_out_cnt: 0,
                link_yn: 0,
                link_set_yn: 0,
                link_ord_yn: 0,
                memo_yn: 0,
                memo_complete_yn: 0,
                alloc_rule_yn: 0,
                realloc_rule_yn: 0,
                ori_prod_no: null,
                sol_no: 999999,
                style_no: 9999999,
                mdate: '2021-11-08 17:11:00',
                prod_noti_data: {
                  infoCode: '01',
                  infoName: '의류',
                  prod_info_1: '면-95',
                  prod_info_2: 'N',
                  prod_info_3: '빨강,파랑',
                  prod_info_4: '85,90',
                  prod_info_5: '플레이오토',
                  prod_info_6: 'Y',
                  prod_info_7: '플레이오토',
                  prod_info_8: '중국',
                  prod_info_9: '드라이크리닝해주세요',
                  prod_info_10: '화기에 주의하세요',
                  prod_info_11: '2016-09',
                  prod_info_12: '구입일로부터 1년',
                  prod_info_13: '플레이오토/070-000-0000',
                },
                madein_type: '국내',
                madein_group: '강원',
                madein_name: '고성군',
                madein_etc: '',
                multi_madein_yn: 0,
                tax_type: '영세',
                model: '',
                maker_eng: 'english maker',
                bundle_avail_yn: 1,
                prod_width: 10,
                prod_height: 20,
                prod_depth: 30,
                prod_weight: 40,
                global_barcode_type: null,
                global_barcode: '',
                isbn: 1313131313131,
                supp_no: null,
                delivery_vendor: 999999,
                use_age: '전체',
                contents: '상세설명 내용',
                product_price: 0,
                real_stock: 200,
                safe_stock: 10,
                options: [
                  {
                    attri: '노랑',
                    attri_type: '색상',
                  },
                  {
                    attri: 'L',
                    attri_type: '사이즈',
                  },
                  {
                    attri: '새틴',
                    attri_type: '재질',
                  },
                ],
              },
            ],
          },
        },
      ],
    },
    {
      title: 'SKU상품 상세 조회',
      url: '/doc/SKU상품 상세 조회',
      data: [
        {
          version: 'v1.2',
          url: '/stock/:sku_cd/v1.2',
          desc: '사용중인 솔루션에 등록된 SKU상품 정보를 조회합니다.',
          method: 'GET',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String',
              desc: 'SKU 코드',
            },
          ],
          response: [
            {
              title: 'prod_no',
              required: 'O',
              type: 'Number(10)',
              desc: 'SKU상품 고유번호',
            },
            {
              title: 'ori_prod_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  원본 SKU상품 고유번호
                  <div className='description'>
                    (조회하는 SKU상품이 사본인 경우 해당)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sol_no',
              required: 'O',
              type: 'Number(8)',
              desc: '솔루션번호',
            },
            {
              title: 'style_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  스타일코드
                  <div className='description'>
                    (스타일등록 상품인경우 해당)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'wdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  등록시간
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
            },
            {
              title: 'mdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  수정시간
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String(40)',
              desc: 'sku코드',
            },
            {
              title: 'prod_name',
              required: 'O',
              type: 'String(200)',
              desc: 'SKU상품명',
            },
            {
              title: 'prod_noti_data',
              required: 'O',
              type: 'object',
              desc: (
                <div>
                  상품정보제공고시
                  <div className='description' style={{ padding: '12px 0' }}>
                    <ul style={{ color: 'gray' }}>
                      <li>
                        상품분류코드에 따라 infoDetail 안에 키 값이 달라질 수
                        있습니다.
                      </li>
                      <li>
                        Default value:
                        <code style={{ fontSize: '15px' }}>{'{}'}</code>
                      </li>
                    </ul>
                  </div>
                </div>
              ),
            },
            {
              title: 'infoCode',
              required: 'O',
              type: 'String',
              desc: '상품분류코드',
              indent: 1,
            },
            {
              title: 'infoName',
              required: 'O',
              type: 'String',
              desc: '상품분류명',
              indent: 1,
            },
            {
              title: 'infoDetail',
              required: 'O',
              type: 'String',
              desc: '상품정보 제공고시 데이터',
              indent: 1,
            },
            {
              title: 'madein_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  원산지 국내외 여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>국내</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>해외</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'madein_group',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  원산지 시/도
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'madein_name',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  원산지 읍/면
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'madein_etc',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  기타원산지인경우
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'multi_madein_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  복수원산지여부
                  <div className='description'>
                    (1: 복수원산지, 0: 복수원산지 아님)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'tax_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  과세여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>과세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>영세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>면세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>비과세</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'brand',
              required: 'O',
              type: 'String(50)',
              desc: '브랜드',
            },
            {
              title: 'model',
              required: 'O',
              type: 'String(50)',
              desc: '모델명',
            },
            {
              title: 'maker',
              required: 'O',
              type: 'String(50)',
              desc: '제조사',
            },
            {
              title: 'maker_eng',
              required: 'O',
              type: 'String(50)',
              desc: '영문 제조사',
            },
            {
              title: 'made_date',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  제조일자
                  <div className='description'>(YYYY-MM-DD)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'expire_date',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  유효일자
                  <div className='description'>(YYYY-MM-DD)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'bundle_avail_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  합포장여부
                  <div className='description'>(1: 가능, 0: 불가능)</div>
                </div>
              ),
            },
            {
              title: 'prod_width',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  상품규격(가로)
                  <div className='description'>(data type: Decimal(8,3))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'prod_height',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  상품규격(세로)
                  <div className='description'>(data type: Decimal(8,3))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'prod_depth',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  상품규격(높이)
                  <div className='description'>(data type: Decimal(8,3))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'prod_weight',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  상품규격(무게)
                  <div className='description'>(data type: Decimal(8,3))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'global_barcode_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  UPC/EAN
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>UPC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EAN</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'global_barcode',
              required: 'O',
              type: 'String(13)',
              desc: (
                <div>
                  UPC/EAN코드
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'barcode',
              required: 'O',
              type: 'String(50)',
              desc: '바코드',
            },
            {
              title: 'hscd',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  hscd 코드 4~10자리
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'isbn',
              required: 'O',
              type: 'Number(13)',
              desc: (
                <div>
                  isbn 코드 13자리
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'supp_no',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  매입처번호
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'use_age',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  사용연령
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>전체</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>0~3세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>4~6세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>7~12세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>13~15세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>16~19세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>20세이상</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'prod_img',
              required: 'O',
              type: 'String(255)',
              desc: 'SKU상품 이미지',
            },
            {
              title: 'contents',
              required: 'O',
              type: 'String(65535)',
              desc: '상세설명',
            },
            {
              title: 'stock_cd',
              required: 'O',
              type: 'String(100)',
              desc: (
                <div>
                  재고관리코드
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'product_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  판매가
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
            },
            {
              title: 'cost_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  원가
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
            },
            {
              title: 'supply_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  공급가
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
            },
            {
              title: 'options',
              required: 'O',
              type: 'object[]',
              desc: '옵션',
            },
            {
              title: 'attri',
              required: 'O',
              type: 'String(50)',
              desc: '옵션타입',
              indent: 1,
            },
            {
              title: 'attri_type',
              required: 'O',
              type: 'String(30)',
              desc: '옵션명',
              indent: 1,
            },
            {
              title: 'depots',
              required: 'O',
              type: 'object[]',
              desc: '배송처 정보',
            },
            {
              title: 'depot_no',
              required: 'O',
              type: 'Number(11)',
              desc: '배송처 번호',
              indent: 1,
            },
            {
              title: 'depot_name',
              required: 'O',
              type: 'String(45)',
              desc: '배송처명',
              indent: 1,
            },
            {
              title: 'real_stock',
              required: 'O',
              type: 'Number(8)',
              desc: '실재고',
              indent: 1,
            },
            {
              title: 'safe_stock',
              required: 'O',
              type: 'Number(8)',
              desc: '안전재고',
              indent: 1,
            },
          ],
          response_example: {
            prod_no: 345272,
            ori_prod_no: null,
            sol_no: 640,
            style_no: null,
            wdate: '2021-05-20 14:47:09',
            mdate: '2021-06-02 05:01:29',
            sku_cd: 'S61731261611',
            prod_name: '테스트 SKU상품',
            prod_noti_data: {
              infoCode: 'Wear2023',
              infoName: '의류',
              infoDetail: {
                material: '1',
                color: '2',
                size: '3',
                manufacturer: '4',
                made_in: '5',
                caution: '6',
                release: '7',
                warranty: '8',
                customer_service: '9',
              },
            },
            madein_type: '국내',
            madein_group: '강원',
            madein_name: '강릉시',
            madein_etc: '',
            multi_madein_yn: 0,
            tax_type: '과세',
            brand: '브랜드',
            model: '모델명',
            maker: '제조사',
            maker_eng: 'english maker',
            made_date: '2021-01-01',
            expire_date: '2025-03-01',
            bundle_avail_yn: 1,
            prod_width: 10,
            prod_height: 11,
            prod_depth: 12,
            prod_weight: 3,
            global_barcode_type: 'UPC',
            global_barcode: '9999999',
            barcode: 'barcode',
            hscd: '6201201000',
            isbn: 123456789012,
            supp_no: 2294,
            use_age: '20세이상',
            prod_img:
              'https://s3-ap-northeast-2.amazonaws.com/gmp01/640/prod/S61731261611/e7.jpg',
            contents:
              '<p><span style=\'font-size: 20px; color: red;\'>※ 실제 판매되는 상품이 아닙니다.<br>구매하셔도 배송 되지 않습니다. 구매 시, 별도 안내 없이 환불 됩니다.</span></p><div><img alt="" src="http://playauto.playimage.co.kr/playauto/page.jpg" /><br /></div>',
            stock_cd: 'stock_manage_code',
            product_price: 251000,
            cost_price: 240000,
            supply_price: 248000,
            options: [
              {
                attri: '블루',
                attri_type: '색상',
              },
            ],
            depots: [
              {
                depot_no: 775,
                depot_name: '테스트 배송처',
                real_stock: 100,
                safe_stock: 10,
              },
              {
                depot_no: 4305,
                depot_name: '테스트 배송처_copy1',
                real_stock: 220,
                safe_stock: 15,
              },
            ],
          },
        },
        {
          version: 'v1.1',
          url: '/stock/:sku_cd/v1.1',
          desc: '사용중인 솔루션에 등록된 SKU상품 정보를 조회합니다.',
          method: 'GET',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String',
              desc: 'SKU 코드',
            },
          ],
          response: [
            {
              title: 'prod_no',
              required: 'O',
              type: 'Number(10)',
              desc: 'SKU상품 고유번호',
            },
            {
              title: 'ori_prod_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  원본 SKU상품 고유번호
                  <div className='description'>
                    (조회하는 SKU상품이 사본인 경우 해당)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sol_no',
              required: 'O',
              type: 'Number(8)',
              desc: '솔루션번호',
            },
            {
              title: 'style_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  스타일코드
                  <div className='description'>
                    (스타일등록 상품인경우 해당)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'wdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  등록시간
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
            },
            {
              title: 'mdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  수정시간
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String(40)',
              desc: 'sku코드',
            },
            {
              title: 'prod_name',
              required: 'O',
              type: 'String(200)',
              desc: 'SKU상품명',
            },
            {
              title: 'prod_noti_data',
              required: 'O',
              type: 'object',
              desc: (
                <div>
                  상품정보제공고시
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>{'{}'}</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'infoCode',
              required: 'O',
              type: 'String',
              desc: '상품분류코드',
              indent: 1,
            },
            {
              title: 'infoName',
              required: 'O',
              type: 'String',
              desc: '상품분류명',
              indent: 1,
            },
            {
              title: 'prod_info_1',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시1',
              indent: 1,
            },
            {
              title: 'prod_info_2',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시2',
              indent: 1,
            },
            {
              title: 'prod_info_3',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시3',
              indent: 1,
            },
            {
              title: 'prod_info_4',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시4',
              indent: 1,
            },
            {
              title: 'prod_info_5',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시5',
              indent: 1,
            },
            {
              title: 'madein_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  원산지 국내외 여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>국내</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>해외</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'madein_group',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  원산지 시/도
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'madein_name',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  원산지 읍/면
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'madein_etc',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  기타원산지인경우
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'multi_madein_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  복수원산지여부
                  <div className='description'>
                    (1: 복수원산지, 0: 복수원산지 아님)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'tax_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  과세여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>과세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>영세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>면세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>비과세</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'brand',
              required: 'O',
              type: 'String(50)',
              desc: '브랜드',
            },
            {
              title: 'model',
              required: 'O',
              type: 'String(50)',
              desc: '모델명',
            },
            {
              title: 'maker',
              required: 'O',
              type: 'String(50)',
              desc: '제조사',
            },
            {
              title: 'maker_eng',
              required: 'O',
              type: 'String(50)',
              desc: '영문 제조사',
            },
            {
              title: 'made_date',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  제조일자
                  <div className='description'>(YYYY-MM-DD)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'expire_date',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  유효일자
                  <div className='description'>(YYYY-MM-DD)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'bundle_avail_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  합포장여부
                  <div className='description'>(1: 가능, 0: 불가능)</div>
                </div>
              ),
            },
            {
              title: 'prod_width',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  상품규격(가로)
                  <div className='description'>(data type: Decimal(8,3))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'prod_height',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  상품규격(세로)
                  <div className='description'>(data type: Decimal(8,3))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'prod_depth',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  상품규격(높이)
                  <div className='description'>(data type: Decimal(8,3))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'prod_weight',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  상품규격(무게)
                  <div className='description'>(data type: Decimal(8,3))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'global_barcode_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  UPC/EAN
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>UPC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EAN</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'global_barcode',
              required: 'O',
              type: 'String(13)',
              desc: (
                <div>
                  UPC/EAN코드
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'barcode',
              required: 'O',
              type: 'String(50)',
              desc: '바코드',
            },
            {
              title: 'hscd',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  hscd 코드 4~10자리
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'isbn',
              required: 'O',
              type: 'Number(13)',
              desc: (
                <div>
                  isbn 코드 13자리
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'supp_no',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  매입처번호
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'use_age',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  사용연령
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>전체</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>0~3세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>4~6세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>7~12세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>13~15세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>16~19세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>20세이상</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'prod_img',
              required: 'O',
              type: 'String(255)',
              desc: 'SKU상품 이미지',
            },
            {
              title: 'contents',
              required: 'O',
              type: 'String(65535)',
              desc: '상세설명',
            },
            {
              title: 'stock_cd',
              required: 'O',
              type: 'String(100)',
              desc: (
                <div>
                  재고관리코드
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'product_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  판매가
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
            },
            {
              title: 'cost_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  원가
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
            },
            {
              title: 'supply_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  공급가
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
            },
            {
              title: 'options',
              required: 'O',
              type: 'object[]',
              desc: '옵션',
            },
            {
              title: 'attri',
              required: 'O',
              type: 'String(50)',
              desc: '옵션타입',
              indent: 1,
            },
            {
              title: 'attri_type',
              required: 'O',
              type: 'String(30)',
              desc: '옵션명',
              indent: 1,
            },
            {
              title: 'depots',
              required: 'O',
              type: 'object[]',
              desc: '배성처정보',
            },
            {
              title: 'depot_no',
              required: 'O',
              type: 'Number(11)',
              desc: '배송처 번호',
              indent: 1,
            },
            {
              title: 'depot_name',
              required: 'O',
              type: 'String(45)',
              desc: '배송처명',
              indent: 1,
            },
            {
              title: 'real_stock',
              required: 'O',
              type: 'Number(8)',
              desc: '실재고',
              indent: 1,
            },
            {
              title: 'safe_stock',
              required: 'O',
              type: 'Number(8)',
              desc: '안전재고',
              indent: 1,
            },
          ],
          response_example: {
            prod_no: 345272,
            ori_prod_no: null,
            sol_no: 640,
            style_no: null,
            wdate: '2021-05-20 14:47:09',
            mdate: '2021-06-02 05:01:29',
            sku_cd: 'S61731261611',
            prod_name: '테스트 SKU상품',
            prod_noti_data: {
              infoCode: '35',
              infoName: '기타재화',
              prod_info_1: '[상세설명참조]',
              prod_info_2: '[상세설명참조]',
              prod_info_3: '[상세설명참조]',
              prod_info_4: '[상세설명참조]',
              prod_info_5: '[상세설명참조]',
              prod_info_6: 'N',
              prod_info_7: '[상세설명참조]',
              prod_info_8: '[상세설명참조]',
            },
            madein_type: '국내',
            madein_group: '강원',
            madein_name: '강릉시',
            madein_etc: '',
            multi_madein_yn: 0,
            tax_type: '과세',
            brand: '브랜드',
            model: '모델명',
            maker: '제조사',
            maker_eng: 'english maker',
            made_date: '2021-01-01',
            expire_date: '2025-03-01',
            bundle_avail_yn: 1,
            prod_width: 10,
            prod_height: 11,
            prod_depth: 12,
            prod_weight: 3,
            global_barcode_type: 'UPC',
            global_barcode: '9999999',
            barcode: 'barcode',
            hscd: '6201201000',
            isbn: 123456789012,
            supp_no: 2294,
            use_age: '20세이상',
            prod_img:
              'https://s3-ap-northeast-2.amazonaws.com/gmp01/640/prod/S61731261611/e7.jpg',
            contents:
              '<p><span style=\'font-size: 20px; color: red;\'>※ 실제 판매되는 상품이 아닙니다.<br>구매하셔도 배송 되지 않습니다. 구매 시, 별도 안내 없이 환불 됩니다.</span></p><div><img alt="" src="http://playauto.playimage.co.kr/playauto/page.jpg" /><br /></div>',
            stock_cd: 'stock_manage_code',
            product_price: 251000,
            cost_price: 240000,
            supply_price: 248000,
            options: [
              {
                attri: '블루',
                attri_type: '색상',
              },
            ],
            depots: [
              {
                depot_no: 775,
                depot_name: '테스트 배송처',
                real_stock: 100,
                safe_stock: 10,
              },
              {
                depot_no: 4305,
                depot_name: '테스트 배송처_copy1',
                real_stock: 220,
                safe_stock: 15,
              },
            ],
          },
        },
        {
          version: 'v1.0',
          url: '/stock/:sku_cd',
          desc: '사용중인 솔루션에 등록된 SKU상품 정보를 조회합니다.',
          header: default_header,
          header_example: default_header_example,
          method: 'GET',
          body: [
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String',
              desc: 'SKU 코드',
            },
          ],
          response: [
            {
              title: 'prod_no',
              required: 'O',
              type: 'Number(10)',
              desc: 'SKU상품 고유번호',
            },
            {
              title: 'ori_prod_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  원본 SKU상품 고유번호
                  <div className='description'>
                    (조회하는 SKU상품이 사본인경우 해당)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sol_no',
              required: 'O',
              type: 'Number(8)',
              desc: '솔루션번호',
            },
            {
              title: 'style_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  스타일코드
                  <div className='description'>
                    (스타일등록 상품인경우 해당)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'wdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  등록시간
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
            },
            {
              title: 'mdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  수정시간
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String(40)',
              desc: 'SKU코드',
            },
            {
              title: 'prod_name',
              required: 'O',
              type: 'String(200)',
              desc: 'SKU상품명',
            },
            {
              title: 'prod_noti_data',
              required: 'O',
              type: 'Object',
              desc: (
                <div>
                  상품정보제공고시
                  <div className='description'>
                    (조회하는 SKU상품이 사본인경우 해당)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>{'{}'}</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'infoCode',
              required: 'O',
              type: 'String',
              desc: '상품분류코드',
              indent: 1,
            },
            {
              title: 'infoName',
              required: 'O',
              type: 'String',
              desc: '상품분류명',
              indent: 1,
            },
            {
              title: 'prod_info_1',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시1',
              indent: 1,
            },
            {
              title: 'prod_info_2',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시2',
              indent: 1,
            },
            {
              title: 'prod_info_3',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시3',
              indent: 1,
            },
            {
              title: 'prod_info_4',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시4',
              indent: 1,
            },
            {
              title: 'prod_info_5',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시5',
              indent: 1,
            },
            {
              title: 'madein_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  원산지 국내외 여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>국내</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>해외</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'madein_group',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  원산지 시/도
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'madein_name',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  원산지 읍/면
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'madein_etc',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  기타원산지인경우
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'multi_madein_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  복수원산지여부
                  <div className='description'>
                    (1: 복수원산지, 0: 복수원산지 아님)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'tax_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  과세여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>과세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>면세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>영세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>비과세</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'brand',
              required: 'O',
              type: 'String(50)',
              desc: '브랜드',
            },
            {
              title: 'model',
              required: 'O',
              type: 'String(50)',
              desc: '모델명',
            },
            {
              title: 'maker',
              required: 'O',
              type: 'String(50)',
              desc: '제조사',
            },
            {
              title: 'maker_eng',
              required: 'O',
              type: 'String(50)',
              desc: '영문 제조사',
            },
            {
              title: 'made_date',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  제조일자
                  <div className='description'>(YYYY-MM-DD)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'expire_date',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  유효일자
                  <div className='description'>(YYYY-MM-DD)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'bundle_avail_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  합포장여부
                  <div className='description'>(1: 가능, 0: 불가능)</div>
                </div>
              ),
            },
            {
              title: 'prod_width',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  상품규격(가로)
                  <div className='description'>(data type: Decimal(8,3))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'prod_height',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  상품규격(세로)
                  <div className='description'>(data type: Decimal(8,3))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'prod_depth',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  상품규격(높이)
                  <div className='description'>(data type: Decimal(8,3))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'prod_weight',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  상품규격(무게)
                  <div className='description'>(data type: Decimal(8,3))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'global_barcode_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  UPC/EAN
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>UPC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EAN</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'global_barcode',
              required: 'O',
              type: 'String(13)',
              desc: (
                <div>
                  UPC/EAN코드
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'barcode',
              required: 'O',
              type: 'String(50)',
              desc: '바코드',
            },
            {
              title: 'hscd',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  hscd코드 4~10자리
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'isbn',
              required: 'O',
              type: 'Number(13)',
              desc: (
                <div>
                  isbn코드 13자리
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'supp_no',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  매입처번호
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'delivery_vendor',
              required: 'O',
              type: 'Number(8)',
              desc: '배송처번호',
            },
            {
              title: 'depot_name',
              required: 'O',
              type: 'String(45)',
              desc: '배송처명',
            },
            {
              title: 'use_age',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  사용연령
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>전체</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>0~3세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>4~6세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>7~12세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>13~15세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>16~19세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>20세이상</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'prod_img',
              required: 'O',
              type: 'String(255)',
              desc: 'SKU상품 이미지',
            },
            {
              title: 'contents',
              required: 'O',
              type: 'String(65535)',
              desc: '상세설명',
            },
            {
              title: 'stock_cd',
              required: 'O',
              type: 'String(100)',
              desc: (
                <div>
                  재고관리코드
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'product_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  판매가
                  <div className='description'>(data type: Decimal:(12,2))</div>
                </div>
              ),
            },
            {
              title: 'cost_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  원가
                  <div className='description'>(data type: Decimal:(12,2))</div>
                </div>
              ),
            },
            {
              title: 'supply_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  공급가
                  <div className='description'>(data type: Decimal:(12,2))</div>
                </div>
              ),
            },
            {
              title: 'real_stock',
              required: 'O',
              type: 'Number(8)',
              desc: '실재고',
            },
            {
              title: 'safe_stock',
              required: 'O',
              type: 'Number(8)',
              desc: '안전재고',
            },
            {
              title: 'options',
              required: 'O',
              type: 'object[]',
              desc: '옵션',
            },
            {
              title: 'attri',
              required: 'O',
              type: 'String(50)',
              desc: '옵션타입',
              indent: 1,
            },
            {
              title: 'attri_type',
              required: 'O',
              type: 'String(50)',
              desc: '옵션명',
              indent: 1,
            },
          ],
          response_example: {
            prod_no: 345272,
            ori_prod_no: null,
            sol_no: 640,
            style_no: null,
            wdate: '2021-05-20 14:47:09',
            mdate: '2021-06-02 05:01:29',
            sku_cd: 'S61731261611',
            prod_name: '테스트 SKU상품',
            prod_noti_data: {
              infoCode: '35',
              infoName: '기타재화',
              prod_info_1: '[상세설명참조]',
              prod_info_2: '[상세설명참조]',
              prod_info_3: '[상세설명참조]',
              prod_info_4: '[상세설명참조]',
              prod_info_5: '[상세설명참조]',
              prod_info_6: 'N',
              prod_info_7: '[상세설명참조]',
              prod_info_8: '[상세설명참조]',
            },
            madein_type: '국내',
            madein_group: '강원',
            madein_name: '강릉시',
            madein_etc: '',
            multi_madein_yn: 0,
            tax_type: '과세',
            brand: '브랜드',
            model: '모델명',
            maker: '제조사',
            maker_eng: 'english maker',
            made_date: '2021-01-01',
            expire_date: '2025-03-01',
            bundle_avail_yn: 1,
            prod_width: 10,
            prod_height: 11,
            prod_depth: 12,
            prod_weight: 3,
            global_barcode_type: 'UPC',
            global_barcode: '9999999',
            barcode: 'barcode',
            hscd: '6201201000',
            isbn: 123456789012,
            supp_no: 2294,
            delivery_vendor: 775,
            depot_name: '테스트 배송처',
            use_age: '20세이상',
            prod_img:
              'https://s3-ap-northeast-2.amazonaws.com/gmp01/640/prod/S61731261611/e7.jpg',
            contents:
              '<p><span style=\'font-size: 20px; color: red;\'>※ 실제 판매되는 상품이 아닙니다.<br>구매하셔도 배송 되지 않습니다. 구매 시, 별도 안내 없이 환불 됩니다.</span></p><div><img alt="" src="http://playauto.playimage.co.kr/playauto/page.jpg" /><br /></div>',
            stock_cd: 'stock_manage_code',
            product_price: 251000,
            cost_price: 240000,
            supply_price: 248000,
            real_stock: 99999,
            safe_stock: 10,
            options: [
              {
                attri: '블루',
                attri_type: '색상',
              },
            ],
          },
        },
      ],
    },
    {
      title: 'SKU상품 등록',
      url: '/doc/SKU상품 등록',
      data: [
        {
          version: 'v1.2',
          url: '/stock/add/v1.2',
          desc: (
            <ul className='style'>
              <li>SKU상품 정보를 등록합니다.</li>
              <li>
                속성없이 등록을 원하시는 경우 opt 항목의 opt1_type에 "없음"으로
                입력해주십시오.
              </li>
              <li>opt는 필수 입력 항목입니다.</li>
              <li>
                두 건 이상의 opt 정보를 입력하시는 경우 스타일 상품으로 등록이
                됩니다.
              </li>
            </ul>
          ),
          method: 'POST',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'prod_name',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  SKU상품명
                  <div className='description'>
                    상품명은 한글, 영문, 숫자 최대 100Byte 까지만
                    등록가능합니다.
                    <br />
                    특수문자 - _ / ~ + . , ( ) {} [ ] % & '만 사용 가능합니다.
                  </div>
                </div>
              ),
            },
            {
              title: 'madein',
              required: '',
              type: 'Object',
              desc: '원산지',
            },
            {
              title: 'madein_no',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  원산지번호
                  <div className='description'>
                    (원산지 조회 api 호출하여 확인)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'madein_etc',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  기타원산지
                  <div className='description'>
                    (등록하려는 원산지가 origins에서 조회되지않을 때 madein_no를
                    비우고 madein_etc에 값을 넣어줍니다.)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'multi_yn',
              required: 'O',
              type: 'Boolean',
              desc: (
                <div>
                  복수원산지여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'tax_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  과세여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>과세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>면세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>영세</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'brand',
              required: '',
              type: 'String',
              desc: '브랜드',
            },
            {
              title: 'maker',
              required: '',
              type: 'String',
              desc: '제조사',
            },
            {
              title: 'maker_eng',
              required: '',
              type: 'String',
              desc: '영문 제조사',
            },
            {
              title: 'made_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  제조일자
                  <div className='description'>
                    (YYYY-MM-DD의 형식으로 입력해주십시오.)
                  </div>
                </div>
              ),
            },
            {
              title: 'expire_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  유효일자
                  <div className='description'>
                    (YYYY-MM-DD의 형식으로 입력해주십시오.)
                  </div>
                </div>
              ),
            },
            {
              title: 'cost_price',
              required: '',
              type: 'Number',
              desc: '원가',
            },
            {
              title: 'supply_price',
              required: '',
              type: 'Number',
              desc: '공급가',
            },
            {
              title: 'hscd',
              required: '',
              type: 'String',
              desc: 'HS코드 (4~10자리)',
            },
            {
              title: 'isbn',
              required: '',
              type: 'Number',
              desc: 'ISBN 코드 (13자리 숫자)',
            },
            {
              title: 'supp_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  매입처코드
                  <div className='description'>(매입처조회 api 이용)</div>
                </div>
              ),
            },
            {
              title: 'prod_width',
              required: '',
              type: 'Number',
              desc: '규격 - 가로 (cm)',
            },
            {
              title: 'prod_height',
              required: '',
              type: 'Number',
              desc: '규격 - 세로 (cm)',
            },
            {
              title: 'prod_depth',
              required: '',
              type: 'Number',
              desc: '규격 - 높이 (cm)',
            },
            {
              title: 'prod_weight',
              required: '',
              type: 'Number',
              desc: '규격 - 무게 (kg)',
            },
            {
              title: 'use_age',
              required: '',
              type: 'String',
              desc: (
                <div>
                  사용연령
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>전체</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>전체</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>0~3세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>4~6세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>7~12세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>13~15세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>16~19세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>20세이상</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'contents',
              required: '',
              type: 'String',
              desc: '상세설명',
            },
            {
              title: 'opt',
              required: 'O',
              type: 'Object[]',
              desc: '속성정보',
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  SKU코드
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      SKU 코드는 영문/숫자/특수문자 ( ) - _ = + , . / 만 입력이
                      가능합니다.
                    </li>
                    <li>
                      SKU상품코드 자동생성을 원하시는경우 'AUTO' 로 하십시오.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'barcode',
              required: '',
              type: 'String',
              desc: (
                <div>
                  바코드
                  <div className='description'>
                    (영문, 숫자, 띄어쓰기, _만 허용)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'model',
              required: '',
              type: 'String',
              desc: '모델명',
              indent: 1,
            },
            {
              title: 'product_price',
              required: '',
              type: 'Number',
              desc: '판매가',
              indent: 1,
            },
            {
              title: 'main_depot_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  sku상품의 대표배송처 코드
                  <div className='description'>
                    (미입력시 입력되는 배송처 정보중 첫번재 값으로 등록)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'depots',
              required: 'O',
              type: 'Object[]',
              desc: '배송처 및 재고정보',
              indent: 1,
            },
            {
              title: 'depot_no',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  배송처코드
                  <div className='description'>(배송처조회 api 이용)</div>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'real_stock',
              required: 'O',
              type: 'Number',
              desc: '실재고',
              indent: 2,
            },
            {
              title: 'safe_stock',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  안전재고
                  <div className='description'>
                    (안전재고는 실재고를 초과할 수 없습니다.)
                  </div>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'img_url',
              required: '',
              type: 'String',
              desc: '이미지',
              indent: 1,
            },
            {
              title: 'opt1_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션구분
                  <div className='description'>
                    (옵션이 없는 SKU상품인경우 "없음"으로 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'opt1',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션값
                  <div className='description'>(ex{'>'}빨강, 파랑....)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'opt2_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  두 번째 옵션이 있는경우 두 번째 옵션구분
                  <div className='description'>(ex{'>'}사이즈)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'opt2',
              required: '',
              type: 'String',
              desc: '두번째 옵션이 있는경우 두번째 옵션 값',
              indent: 1,
            },
            {
              title: 'prod_info',
              required: '',
              type: 'Object',
              desc: (
                <div>
                  품목정보고시
                  <br />
                  <div className='description'>
                    <ul style={{ color: 'gray' }}>
                      <li>아래의 등록가이드를 참고해주세요.</li>
                      <li>
                        <a
                          href='https://gmp-master.s3.ap-northeast-2.amazonaws.com/download/Sample_prodInfo_guide_OPENAPI.xlsx'
                          style={{ color: 'blue' }}
                        >
                          상품 품목정보고시 가이드 개정판
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              ),
            },
            {
              title: 'infoCode',
              required: 'O',
              type: 'String',
              desc: '상품분류코드',
              indent: 1,
            },
            {
              title: 'infoDetail',
              required: 'O',
              type: 'Object',
              desc: (
                <div>
                  상품 정보 제공고시 데이터
                  <ul style={{ color: 'gray' }}>
                    <li>
                      품목 정보고시 가이드에 기재된 각 항목에 따른 key와 value를
                      입력합니다.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'is_desc_referred',
              type: 'boolean',
              desc: (
                <div>
                  상품 정보 제공고시 데이터 일괄 [상세설명참조] 적용
                  <ul style={{ color: 'gray' }}>
                    <li>
                      해당 값을 <code>true</code> 로 설정 시 입력한 값과 상관 없이 일괄 <code>[상세설명참조]</code>로 적용됩니다.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'global_barcode_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  UPC/EAN 타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>UPC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EAN</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'global_barcode',
              required: '',
              type: 'Number',
              desc: 'UPC/EAN 코드',
            },
            {
              title: 'barcode',
              required: '',
              type: 'String',
              desc: '바코드',
            },
            {
              title: 'stock_cd',
              required: '',
              type: 'String',
              desc: '재고관리코드',
            },
          ],
          body_example: {
            prod_name: '테스트 SKU상품',
            madein: {
              madein_no: '2',
              madein_etc: '',
              multi_yn: false,
            },
            tax_type: '영세',
            brand: '브랜드',
            maker: '제조사',
            maker_eng: 'english maker',
            made_date: '',
            expire_date: '2020-06-01',
            cost_price: '10000',
            supply_price: '12000',
            global_barcode: '',
            hscd: '6201201000',
            isbn: '1313131313131',
            use_age: '전체',
            contents: '상세설명',
            prod_width: '10',
            prod_height: '20',
            prod_depth: '30',
            prod_weight: '40',
            supp_no: '1933',
            main_depot_no: '746',
            opt: [
              {
                sku_cd: 'AUTO',
                barcode: '',
                model: '',
                product_price: '15000',
                depots: [
                  {
                    depot_no: 746,
                    real_stock: 999,
                    safe_stock: 10,
                  },
                ],
                opt1_type: '색상',
                opt2_type: '사이즈',
                opt1: '빨강',
                opt2: '큼',
                img_url: 'http://sample.playauto.io/sample.png',
              },
              {
                sku_cd: 'S109CFEF4160',
                barcode: '',
                model: '',
                product_price: '15000',
                depots: [
                  {
                    depot_no: 800,
                    real_stock: 88,
                    safe_stock: 5,
                  },
                ],
                opt1_type: '색상',
                opt2_type: '사이즈',
                opt1: '빨강',
                opt2: '작음',
                img_url: 'http://sample.playauto.io/sample.png',
              },
            ],
            prod_info: {
              infoCode: 'Wear2023',
              infoDetail: {
                material: '1',
                color: '2',
                size: '3',
                manufacturer: '4',
                made_in: '5',
                caution: '6',
                release: '7',
                warranty: '8',
                customer_service: '9',
              },
            },
          },
          response: [
            {
              title: 'success',
              required: 'O',
              type: 'Number',
              desc: '등록 성공 갯수',
            },
            {
              title: 'fail',
              required: 'O',
              type: 'Number',
              desc: '등록 실패 갯수',
            },
            {
              title: 'results',
              required: 'O',
              type: 'Object[]',
              desc: '결과상세',
            },
            {
              title: 'prod_no',
              required: 'O',
              type: 'Number',
              desc: 'SKU상품 고유 키',
              indent: 1,
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String',
              desc: 'sku 코드',
              indent: 1,
            },
            {
              title: 'messages',
              required: 'O',
              type: 'String[]',
              desc: '등록이 실패된경우 에러 메세지',
              indent: 1,
            },
          ],
          response_example: {
            success: 1,
            fail: 1,
            results: [
              {
                prod_no: 5126403,
                sku_cd: 'S220DCBA83A3',
                message: '',
              },
              {
                prod_no: 0,
                sku_cd: 'S109CFEF4160',
                error_code: 'e3008',
                messages: [
                  '이미 등록된 sku_cd 입니다.'
                ],
              },
            ],
          },
        },
        {
          version: 'v1.1',
          url: '/stock/add/v1.1',
          desc: (
            <ul className='style'>
              <li>SKU상품 정보를 등록합니다.</li>
              <li>
                속성없이 등록을 원하시는 경우 opt 항목의 opt1_type에 "없음"으로
                입력해주십시오.
              </li>
              <li>opt는 필수 입력 항목입니다.</li>
              <li>
                두 건 이상의 opt 정보를 입력하시는 경우 스타일 상품으로 등록이
                됩니다.
              </li>
            </ul>
          ),
          method: 'POST',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'prod_name',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  SKU상품명
                  <div className='description'>
                    상품명은 한글, 영문, 숫자 최대 100Byte 까지만
                    등록가능합니다.
                    <br />
                    특수문자 - _ / ~ + . , ( ) {} [ ] % & '만 사용 가능합니다.
                  </div>
                </div>
              ),
            },
            {
              title: 'madein',
              required: '',
              type: 'Object',
              desc: '원산지',
            },
            {
              title: 'madein_no',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  원산지번호
                  <div className='description'>
                    (원산지 조회 api 호출하여 확인)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'madein_etc',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  기타원산지
                  <div className='description'>
                    (등록하려는 원산지가 origins에서 조회되지않을 때 madein_no를
                    비우고 madein_etc에 값을 넣어줍니다.)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'multi_yn',
              required: 'O',
              type: 'Boolean',
              desc: (
                <div>
                  복수원산지여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'tax_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  과세여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>과세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>면세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>영세</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'brand',
              required: '',
              type: 'String',
              desc: '브랜드',
            },
            {
              title: 'maker',
              required: '',
              type: 'String',
              desc: '제조사',
            },
            {
              title: 'maker_eng',
              required: '',
              type: 'String',
              desc: '영문 제조사',
            },
            {
              title: 'made_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  제조일자
                  <div className='description'>
                    (YYYY-MM-DD의 형식으로 입력해주십시오.)
                  </div>
                </div>
              ),
            },
            {
              title: 'expire_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  유효일자
                  <div className='description'>
                    (YYYY-MM-DD의 형식으로 입력해주십시오.)
                  </div>
                </div>
              ),
            },
            {
              title: 'cost_price',
              required: '',
              type: 'Number',
              desc: '원가',
            },
            {
              title: 'supply_price',
              required: '',
              type: 'Number',
              desc: '공급가',
            },
            {
              title: 'hscd',
              required: '',
              type: 'String',
              desc: 'HS코드 (4~10자리)',
            },
            {
              title: 'isbn',
              required: '',
              type: 'Number',
              desc: 'ISBN 코드 (13자리 숫자)',
            },
            {
              title: 'supp_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  매입처코드
                  <div className='description'>(매입처조회 api 이용)</div>
                </div>
              ),
            },
            {
              title: 'prod_width',
              required: '',
              type: 'Number',
              desc: '규격 - 가로 (cm)',
            },
            {
              title: 'prod_height',
              required: '',
              type: 'Number',
              desc: '규격 - 세로 (cm)',
            },
            {
              title: 'prod_depth',
              required: '',
              type: 'Number',
              desc: '규격 - 높이 (cm)',
            },
            {
              title: 'prod_weight',
              required: '',
              type: 'Number',
              desc: '규격 - 무게 (kg)',
            },
            {
              title: 'use_age',
              required: '',
              type: 'String',
              desc: (
                <div>
                  사용연령
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>전체</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>전체</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>0~3세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>4~6세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>7~12세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>13~15세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>16~19세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>20세이상</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'contents',
              required: '',
              type: 'String',
              desc: '상세설명',
            },
            {
              title: 'opt',
              required: 'O',
              type: 'Object[]',
              desc: '속성정보',
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  SKU코드
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      SKU 코드는 영문/숫자/특수문자 ( ) - _ = + , . / 만 입력이
                      가능합니다.
                    </li>
                    <li>
                      SKU상품코드 자동생성을 원하시는경우 'AUTO' 로 하십시오.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'barcode',
              required: '',
              type: 'String',
              desc: (
                <div>
                  바코드
                  <div className='description'>
                    (영문, 숫자, 띄어쓰기, _만 허용)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'model',
              required: '',
              type: 'String',
              desc: '모델명',
              indent: 1,
            },
            {
              title: 'product_price',
              required: '',
              type: 'Number',
              desc: '판매가',
              indent: 1,
            },
            {
              title: 'main_depot_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  sku상품의 대표배송처 코드
                  <div className='description'>
                    (미입력시 입력되는 배송처 정보중 첫번재 값으로 등록)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'depots',
              required: 'O',
              type: 'Object[]',
              desc: '배송처 및 재고정보',
              indent: 1,
            },
            {
              title: 'depot_no',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  배송처코드
                  <div className='description'>(배송처조회 api 이용)</div>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'real_stock',
              required: 'O',
              type: 'Number',
              desc: '실재고',
              indent: 2,
            },
            {
              title: 'safe_stock',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  안전재고
                  <div className='description'>
                    (안전재고는 실재고를 초과할 수 없습니다.)
                  </div>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'img_url',
              required: '',
              type: 'String',
              desc: '이미지',
              indent: 1,
            },
            {
              title: 'opt1_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션구분
                  <div className='description'>
                    (옵션이 없는 SKU상품인경우 "없음"으로 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'opt1',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션값
                  <div className='description'>(ex{'>'}빨강, 파랑....)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'opt2_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  두 번째 옵션이 있는경우 두 번째 옵션구분
                  <div className='description'>(ex{'>'}사이즈)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'opt2',
              required: '',
              type: 'String',
              desc: '두번째 옵션이 있는경우 두번째 옵션 값',
              indent: 1,
            },
            {
              title: 'prod_info',
              required: '',
              type: 'Object',
              desc: (
                <div>
                  품목정보고시
                  <br />
                  <br />
                  <div className='description'>
                    아래의 등록가이드를 참고해주세요.
                  </div>
                  <a
                    style={{ color: 'blue' }}
                    href='https://gmp-master.s3.ap-northeast-2.amazonaws.com/download/Sample_prodInfo_guide.xlsx'
                  >
                    품목정보고시 가이드
                  </a>
                </div>
              ),
            },
            {
              title: 'code',
              required: 'O',
              type: 'String',
              desc: '상품분류코드',
              indent: 1,
            },
            {
              title: 'prod_info_1',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시1',
              indent: 1,
            },
            {
              title: 'prod_info_2',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시2',
              indent: 1,
            },
            {
              title: 'prod_info_3',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시3',
              indent: 1,
            },
            {
              title: 'prod_info_4',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시4',
              indent: 1,
            },
            {
              title: 'prod_info_5',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시5',
              indent: 1,
            },
            {
              title: 'prod_info_6',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시6',
              indent: 1,
            },
            {
              title: 'prod_info_7',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시7',
              indent: 1,
            },
            {
              title: 'prod_info_8',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시8',
              indent: 1,
            },
            {
              title: 'prod_info_9',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시9',
              indent: 1,
            },
            {
              title: 'prod_info_10',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시10
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_11',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시11
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_12',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시12
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_13',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시13
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_14',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시14
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_15',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시15
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_16',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시16
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_17',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시17
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_18',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시18
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_19',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시19
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_20',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시20
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_21',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시21
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_22',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시22
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_23',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시23
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_24',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시24
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'global_barcode_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  UPC/EAN 타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>UPC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EAN</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'global_barcode',
              required: '',
              type: 'Number',
              desc: 'UPC/EAN 코드',
            },
            {
              title: 'barcode',
              required: '',
              type: 'String',
              desc: '바코드',
            },
            {
              title: 'stock_cd',
              required: '',
              type: 'String',
              desc: '재고관리코드',
            },
          ],
          body_example: {
            prod_name: '테스트 SKU상품',
            madein: {
              madein_no: '2',
              madein_etc: '',
              multi_yn: false,
            },
            tax_type: '영세',
            brand: '브랜드',
            maker: '제조사',
            maker_eng: 'english maker',
            made_date: '',
            expire_date: '2020-06-01',
            cost_price: '10000',
            supply_price: '12000',
            global_barcode: '',
            hscd: '6201201000',
            isbn: '1313131313131',
            use_age: '전체',
            contents: '상세설명',
            prod_width: '10',
            prod_height: '20',
            prod_depth: '30',
            prod_weight: '40',
            supp_no: '1933',
            main_depot_no: '746',
            opt: [
              {
                sku_cd: 'AUTO',
                barcode: '',
                model: '',
                product_price: '15000',
                depots: [
                  {
                    depot_no: 746,
                    real_stock: 999,
                    safe_stock: 10,
                  },
                ],
                opt1_type: '색상',
                opt2_type: '사이즈',
                opt1: '빨강',
                opt2: '큼',
                img_url: 'http://sample.playauto.io/sample.png',
              },
              {
                sku_cd: 'S109CFEF4160',
                barcode: '',
                model: '',
                product_price: '15000',
                depots: [
                  {
                    depot_no: 800,
                    real_stock: 88,
                    safe_stock: 5,
                  },
                ],
                opt1_type: '색상',
                opt2_type: '사이즈',
                opt1: '빨강',
                opt2: '작음',
                img_url: 'http://sample.playauto.io/sample.png',
              },
            ],
            prod_info: {
              code: '01',
              prod_info_1: '면-95',
              prod_info_2: 'N',
              prod_info_3: '빨강,파랑',
              prod_info_4: '85,90',
              prod_info_5: '플레이오토',
              prod_info_6: 'Y',
              prod_info_7: '플레이오토',
              prod_info_8: '중국',
              prod_info_9: '드라이크리닝해주세요',
              prod_info_10: '화기에 주의하세요',
              prod_info_11: '2016-09',
              prod_info_12: '구입일로부터 1년',
              prod_info_13: '플레이오토/070-123-4567',
              prod_info_14: '',
              prod_info_15: '',
              prod_info_16: '',
              prod_info_17: '',
              prod_info_18: '',
              prod_info_19: '',
              prod_info_20: '',
              prod_info_21: '',
              prod_info_22: '',
              prod_info_23: '',
              prod_info_24: '',
            },
          },
          response: [
            {
              title: 'success',
              required: 'O',
              type: 'Number',
              desc: '등록 성공 갯수',
            },
            {
              title: 'fail',
              required: 'O',
              type: 'Number',
              desc: '등록 실패 갯수',
            },
            {
              title: 'results',
              required: 'O',
              type: 'Object[]',
              desc: '결과상세',
            },
            {
              title: 'prod_no',
              required: 'O',
              type: 'Number',
              desc: 'SKU상품 고유 키',
              indent: 1,
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String',
              desc: 'sku 코드',
              indent: 1,
            },
            {
              title: 'messages',
              required: 'O',
              type: 'String[]',
              desc: '등록이 실패된경우 에러 메세지',
              indent: 1,
            },
          ],
          response_example: {
            success: 1,
            fail: 1,
            results: [
              {
                prod_no: 5126403,
                sku_cd: 'S220DCBA83A3',
                message: '',
              },
              {
                prod_no: 0,
                sku_cd: 'S109CFEF4160',
                error_code: 'e3008',
                message: [
                  '이미 등록된 sku_cd 입니다.'
                ]
              },
            ],
          },
        },
        {
          version: 'v1.0',
          url: '/stock/add',
          method: 'POST',
          desc: (
            <ul className='style'>
              <li>SKU상품 정보를 등록합니다.</li>
              <li>
                속성없이 등록을 원하시는경우 opt 항목의 opt1_type 에 "없음" 으로
                입력해주십시오.
              </li>
              <li>opt 는 필수 입력 항목입니다.</li>
              <li>
                두건 이상의 opt 정보를 입력하시는경우 스타일상품으로 등록이
                됩니다.
              </li>
            </ul>
          ),
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'prod_name',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  SKU상품명
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      상품명은 한글, 영문, 숫자 최대 100Byte 까지만
                      등록가능합니다.
                    </li>
                    <li>
                      특수문자 - _ / ~ + . , ( ) {} [ ] % & '만 사용 가능합니다.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'madein',
              required: '',
              type: 'Object',
              desc: '원산지',
            },
            {
              title: 'madein_no',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  원산지번호
                  <div className='description'>
                    원산지 조회 api 호출하여 확인
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'madein_etc',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  기타원산지
                  <div className='description'>
                    (등록하려는 원산지가 origins에서 조회되지않을 때 madein_no를
                    비우고 madein_etc에 값을 넣어줍니다.)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'multi_yn',
              required: 'O',
              type: 'Boolean',
              desc: (
                <div>
                  복수원산지여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'tax_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  과세여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>과세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>면세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>영세</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'brand',
              required: '',
              type: 'String',
              desc: '브랜드',
            },
            {
              title: 'maker',
              required: '',
              type: 'String',
              desc: '제조사',
            },
            {
              title: 'maker_eng',
              required: '',
              type: 'String',
              desc: '영문 제조사',
            },
            {
              title: 'made_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  제조일자
                  <div className='description'>
                    (YYYY-MM-DD의 형식으로 입력해주십시오.)
                  </div>
                </div>
              ),
            },
            {
              title: 'expire_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  유효일자
                  <div className='description'>
                    (YYYY-MM-DD의 형식으로 입력해주십시오.)
                  </div>
                </div>
              ),
            },
            {
              title: 'cost_price',
              required: '',
              type: 'Number',
              desc: '원가',
            },
            {
              title: 'supply_price',
              required: '',
              type: 'Number',
              desc: '공급가',
            },
            {
              title: 'hscd',
              required: '',
              type: 'String',
              desc: 'HS코드 (4~10자리)',
            },
            {
              title: 'isbn',
              required: '',
              type: 'Number',
              desc: 'ISBN코드 (13자리)',
            },
            {
              title: 'delivery_vendor',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  배송처코드
                  <div className='description'>(배송처조회 api 이용)</div>
                </div>
              ),
            },
            {
              title: 'supp_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  매입처코드
                  <div className='description'>(매입처조회 api 이용)</div>
                </div>
              ),
            },
            {
              title: 'prod_width',
              required: '',
              type: 'Number',
              desc: '규격 - 가로 (cm)',
            },
            {
              title: 'prod_height',
              required: '',
              type: 'Number',
              desc: '규격 - 세로 (cm)',
            },
            {
              title: 'prod_depth',
              required: '',
              type: 'Number',
              desc: '규격 - 높이 (cm)',
            },
            {
              title: 'prod_weight',
              required: '',
              type: 'Number',
              desc: '규격 - 무게 (kg)',
            },
            {
              title: 'use_age',
              required: '',
              type: 'String',
              desc: (
                <div>
                  사용연령
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>전체</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>전체</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>0~3세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>4~6세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>7~12세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>13~15세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>16~19세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>20세이상</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'contents',
              required: '',
              type: 'String',
              desc: '상세설명',
            },
            {
              title: 'opt',
              required: 'O',
              type: 'Object[]',
              desc: '속성정보',
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  SKU코드
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      SKU 코드는 영문/숫자/특수문자 ( ) - _ = + , . / 만 입력이
                      가능합니다.
                    </li>
                    <li>
                      SKU상품코드 자동생성을 원하시는경우 'AUTO' 로 하십시오.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'barcode',
              required: '',
              type: 'String',
              desc: (
                <div>
                  바코드
                  <div className='description'>
                    (영문, 숫자, 띄어쓰기, _ 만 허용)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'model',
              required: '',
              type: 'String',
              desc: '모델명',
              indent: 1,
            },
            {
              title: 'product_price',
              required: '',
              type: 'Number',
              desc: '판매가',
              indent: 1,
            },
            {
              title: 'real_stock',
              required: 'O',
              type: 'Number',
              desc: '실재고',
              indent: 1,
            },
            {
              title: 'safe_stock',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  안전재고
                  <div className='description'>
                    (안전재고는 실재고를 초과할 수 없습니다.)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'img_url',
              required: '',
              type: 'String',
              desc: '이미지',
              indent: 1,
            },
            {
              title: 'opt1_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션구분
                  <div className='description'>
                    (옵션이 없는 SKU상품인경우 "없음" 으로 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'opt1',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션값
                  <div className='description'> (ex{'>'}빨강, 파랑....)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'opt2_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  두 번째 옵션이 있는경우 두 번째 옵션구분
                  <div className='description'> (ex{'>'}사이즈)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'opt2',
              required: '',
              type: 'String',
              desc: '두 번째 옵션이 있는경우 두 번째 옵션값',
              indent: 1,
            },
            {
              title: 'prod_info',
              required: '',
              type: 'Object',
              desc: (
                <div>
                  품목정보고시
                  <br />
                  <br />
                  <div className='description'>
                    아래의 등록가이드를 참고해주세요.
                  </div>
                  <a
                    style={{ color: 'blue' }}
                    href='https://gmp-master.s3.ap-northeast-2.amazonaws.com/download/Sample_prodInfo_guide.xlsx'
                  >
                    품목정보고시 가이드
                  </a>
                </div>
              ),
            },
            {
              title: 'code',
              required: 'O',
              type: 'String',
              desc: '상품분류코드',
              indent: 1,
            },
            {
              title: 'prod_info_1',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시1',
              indent: 1,
            },
            {
              title: 'prod_info_2',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시2',
              indent: 1,
            },
            {
              title: 'prod_info_3',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시3',
              indent: 1,
            },
            {
              title: 'prod_info_4',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시4',
              indent: 1,
            },
            {
              title: 'prod_info_5',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시5',
              indent: 1,
            },
            {
              title: 'prod_info_6',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시6',
              indent: 1,
            },
            {
              title: 'prod_info_7',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시7',
              indent: 1,
            },
            {
              title: 'prod_info_8',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시8',
              indent: 1,
            },
            {
              title: 'prod_info_9',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시9',
              indent: 1,
            },
            {
              title: 'prod_info_10',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시10
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_11',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시11
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_12',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시12
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_13',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시13
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_14',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시14
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_15',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시15
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_16',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시16
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_17',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시17
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_18',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시18
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_19',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시19
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_20',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시20
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_21',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시21
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_22',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시22
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_23',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시23
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_24',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시24
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'global_barcode_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  UPC/EAN타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>UPC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EAN</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'global_barcode',
              required: '',
              type: 'Number',
              desc: 'UPC/EAN 코드',
            },
            {
              title: 'barcode',
              required: '',
              type: 'String',
              desc: '바코드',
            },
            {
              title: 'stock_cd',
              required: '',
              type: 'String',
              desc: '재고관리코드',
            },
          ],
          body_example: {
            prod_name: '테스트 SKU상품',
            madein: {
              madein_no: '2',
              madein_etc: '',
              multi_yn: false,
            },
            tax_type: '영세',
            brand: '브랜드',
            maker: '제조사',
            maker_eng: 'english maker',
            made_date: '',
            expire_date: '2020-06-01',
            cost_price: '10000',
            supply_price: '12000',
            global_barcode: '',
            hscd: '6201201000',
            isbn: '1313131313131',
            delivery_vendor: '746',
            use_age: '전체',
            contents: '상세설명',
            prod_width: '10',
            prod_height: '20',
            prod_depth: '30',
            prod_weight: '40',
            supp_no: '1933',
            opt: [
              {
                sku_cd: 'AUTO',
                barcode: '',
                model: '',
                product_price: '15000',
                real_stock: '999',
                safe_stock: '10',
                opt1_type: '색상',
                opt2_type: '사이즈',
                opt1: '빨강',
                opt2: '큼',
                img_url: 'http://sample.playauto.io/sample.png',
              },
              {
                sku_cd: 'S109CFEF4160',
                barcode: '',
                model: '',
                product_price: '15000',
                real_stock: '999',
                safe_stock: '10',
                opt1_type: '색상',
                opt2_type: '사이즈',
                opt1: '빨강',
                opt2: '작음',
                img_url: 'http://sample.playauto.io/sample.png',
              },
            ],
            prod_info: {
              code: '01',
              prod_info_1: '면-95',
              prod_info_2: 'N',
              prod_info_3: '빨강,파랑',
              prod_info_4: '85,90',
              prod_info_5: '플레이오토',
              prod_info_6: 'Y',
              prod_info_7: '플레이오토',
              prod_info_8: '중국',
              prod_info_9: '드라이크리닝해주세요',
              prod_info_10: '화기에 주의하세요',
              prod_info_11: '2016-09',
              prod_info_12: '구입일로부터 1년',
              prod_info_13: '플레이오토/070-123-4567',
              prod_info_14: '',
              prod_info_15: '',
              prod_info_16: '',
              prod_info_17: '',
              prod_info_18: '',
              prod_info_19: '',
              prod_info_20: '',
              prod_info_21: '',
              prod_info_22: '',
              prod_info_23: '',
              prod_info_24: '',
            },
          },
          response: [
            {
              title: 'success',
              required: 'O',
              type: 'Number',
              desc: '등록 성공 갯수',
            },
            {
              title: 'fail',
              required: 'O',
              type: 'Number',
              desc: '등록 실패 갯수',
            },
            {
              title: 'results',
              required: 'O',
              type: 'Object[]',
              desc: '결과상세',
            },
            {
              title: 'prod_no',
              required: 'O',
              type: 'Number',
              desc: 'SKU상폼 고유 키',
              indent: 1,
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String',
              desc: 'SKU코드',
              indent: 1,
            },
            {
              title: 'message',
              required: 'O',
              type: 'String',
              desc: '등록이 실패된 경우 에러 메세지',
              indent: 1,
            },
          ],
          response_example: {
            success: 1,
            fail: 1,
            results: [
              {
                prod_no: 5126403,
                sku_cd: 'S220DCBA83A3',
                message: '',
              },
              {
                prod_no: 0,
                sku_cd: 'S109CFEF4160',
                error_code: 'e3008',
                message: [
                  '이미 등록된 sku_cd 입니다.'
                ],
              },
            ],
          },
        },
      ],
    },
    {
      title: 'SKU상품 수정',
      url: '/doc/SKU상품 수정',
      data: [
        {
          version: 'v1.2',
          url: '/stock/edit/v1.2',
          method: 'PUT',
          desc: (
            <ul className='style'>
              <li>SKU상품 정보를 수정합니다.</li>
            </ul>
          ),
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String',
              desc: 'sku코드',
            },
            {
              title: 'prod_name',
              required: '',
              type: 'String',
              desc: (
                <div>
                  SKU상품명
                  <div className='description'>
                    상품명은 한글, 영문, 숫자 최대 100Byte 까지만
                    등록가능합니다.
                    <br />
                    특수문자 - _ / ~ + . , ( ) {} [ ] % & '만 사용 가능합니다.
                  </div>
                </div>
              ),
            },
            {
              title: 'madein',
              required: '',
              type: 'Object',
              desc: '원산지',
            },
            {
              title: 'madein_no',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  원산지 번호
                  <div className='description'>
                    (원산지 조회 api 호출하여 확인)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'madein_etc',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  기타원산지
                  <div className='description'>
                    (등록하려는 원산지가 origins 에서 조회되지않을때 madein_no
                    를 비우고 madein_etc 에 값을 넣어줍니다.)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'multi_yn',
              required: 'O',
              type: 'Boolean',
              desc: (
                <div>
                  복수원산지여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'tax_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  과세여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>과세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>면세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>영세</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'brand',
              required: '',
              type: 'String',
              desc: '브랜드',
            },
            {
              title: 'model',
              required: '',
              type: 'String',
              desc: '모델명',
            },
            {
              title: 'maker',
              required: '',
              type: 'String',
              desc: '제조사',
            },
            {
              title: 'maker_eng',
              required: '',
              type: 'String',
              desc: '영문 제조사',
            },
            {
              title: 'made_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  제조일자
                  <div className='description'>
                    (YYYY-MM-DD의 형식으로 입력해주십시오.)
                  </div>
                </div>
              ),
            },
            {
              title: 'expire_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  유효일자
                  <div className='description'>
                    (YYYY-MM-DD의 형식으로 입력해주십시오.)
                  </div>
                </div>
              ),
            },
            {
              title: 'supp_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  매입처코드
                  <div className='description'>(매입처조회 api 이용)</div>
                </div>
              ),
            },
            {
              title: 'prod_width',
              required: '',
              type: 'Number',
              desc: '규격 - 가로 (cm)',
            },
            {
              title: 'prod_height',
              required: '',
              type: 'Number',
              desc: '규격 - 세로 (cm)',
            },
            {
              title: 'prod_depth',
              required: '',
              type: 'Number',
              desc: '규격 - 높이 (cm)',
            },
            {
              title: 'prod_weight',
              required: '',
              type: 'Number',
              desc: '규격 - 무게 (kg)',
            },
            {
              title: 'use_age',
              required: '',
              type: 'String',
              desc: (
                <div>
                  사용연령
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>전체</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>전체</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>0~3세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>4~6세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>7~12세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>13~15세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>16~19세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>20세이상</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'contents',
              required: '',
              type: 'String',
              desc: '상세설명',
            },
            {
              title: 'opt1_type',
              required: '',
              type: 'String',
              desc: '첫번째 옵션구분',
            },
            {
              title: 'opt1',
              required: '',
              type: 'String',
              desc: '첫번째 옵션값',
            },
            {
              title: 'opt2_type',
              required: '',
              type: 'String',
              desc: '두번째 옵션구분',
            },
            {
              title: 'opt2',
              required: '',
              type: 'String',
              desc: '두번째 옵션값',
            },
            {
              title: 'opt3_type',
              required: '',
              type: 'String',
              desc: '세번째 옵션구분',
            },
            {
              title: 'opt3',
              required: '',
              type: 'String',
              desc: '세번째 옵션값',
            },
            {
              title: 'depots',
              required: '',
              type: 'Object[]',
              desc: '배송처 및 재고정보',
            },
            {
              title: 'depot_no',
              required: 'O',
              type: 'Number',
              desc: '배송처번호',
              indent: 1,
            },
            {
              title: 'real_stock',
              required: 'O',
              type: 'Number',
              desc: '실재고',
              indent: 1,
            },
            {
              title: 'safe_stock',
              required: '',
              type: 'Number',
              desc: '안전재고',
              indent: 1,
            },
            {
              title: 'prod_info',
              required: '',
              type: 'Object',
              desc: (
                <div>
                  품목정보고시
                  <br />
                  <br />
                  <div className='description'>
                    아래의 등록가이드를 참고해주세요.
                  </div>
                  <a
                    style={{ color: 'blue' }}
                    href='https://gmp-master.s3.ap-northeast-2.amazonaws.com/download/Sample_prodInfo_guide_OPENAPI.xlsx'
                  >
                    상품 품목정보고시 가이드 개정판
                  </a>
                </div>
              ),
            },
            {
              title: 'infoCode',
              required: 'O',
              type: 'String',
              desc: '상품분류코드',
              indent: 1,
            },
            {
              title: 'infoDetail',
              required: 'O',
              type: 'Object',
              desc: (
                <div>
                  상품 정보 제공고시 데이터
                  <ul style={{ color: 'gray' }}>
                    <li>
                      품목 정보고시 가이드에 기재된 각 항목에 따른 key와 value를
                      입력합니다.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'is_desc_referred',
              type: 'boolean',
              desc: (
                <div>
                  상품 정보 제공고시 데이터 일괄 [상세설명참조] 적용
                  <ul style={{ color: 'gray' }}>
                    <li>
                      해당 값을 <code>true</code> 로 설정 시 입력한 값과 상관 없이 일괄 <code>[상세설명참조]</code>로 적용됩니다.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'product_price',
              required: '',
              type: 'Number',
              desc: '판매가',
            },
            {
              title: 'supply_price',
              required: '',
              type: 'Number',
              desc: '공급가',
            },
            {
              title: 'cost_price',
              required: '',
              type: 'Number',
              desc: '원가',
            },
            {
              title: 'global_barcode_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  UPC/EAN
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>UPC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EAN</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'global_barcode',
              required: '',
              type: 'Number',
              desc: 'UPC/EAN 코드',
            },
            {
              title: 'hscd',
              required: '',
              type: 'String',
              desc: 'HS코드',
            },
            {
              title: 'isbn',
              required: '',
              type: 'Number',
              desc: 'ISBN 코드 (13자리 숫자)',
            },
            {
              title: 'barcode',
              required: '',
              type: 'String',
              desc: '바코드',
            },
            {
              title: 'stock_cd',
              required: '',
              type: 'String',
              desc: '재고관리코드',
            },
          ],
          body_example: {
            sku_cd: 'aowlkj123',
            prod_name: '테스트 SKU상품',
            madein: {
              madein_no: '2',
              madein_etc: '',
              multi_yn: false,
            },
            tax_type: '영세',
            brand: '브랜드',
            maker: '제조사',
            cost_price: '10000',
            delivery_vendor: '746',
            contents: '상세설명',
            opt1_type: '색상',
            opt1: '빨강',
            depots: [
              {
                depot_no: 994,
                real_stock: 99,
                safe_stock: 5,
              },
              {
                depot_no: 1025,
                real_stock: 100,
                safe_stock: 35,
              },
            ],
            prod_info: {
              infoCode: 'Wear2023',
              infoDetail: {
                material: '1',
                color: '2',
                size: '3',
                manufacturer: '4',
                made_in: '5',
                caution: '6',
                release: '7',
                warranty: '8',
                customer_service: '9',
              },
            },
          },
          response: [
            {
              title: 'sku_cd',
              required: 'O',
              type: 'Number',
              desc: 'sku 코드',
            },
            {
              title: 'result',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  성공, 실패 여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>성공</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>실패</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'error_code',
              required: 'O',
              type: 'String',
              desc: '에러코드',
            },
            {
              title: 'message',
              required: 'O',
              type: 'String',
              desc: '에러메세지',
            },
          ],
          response_example: {
            sku_cd: 'S55512193',
            result: '성공',
            message: '',
          },
          error_example: {
            "sku_cd": "",
            "result": "실패",
            "error_code": "e3020",
            "messages": [
              "수정할 정보가 없습니다."
            ]
          }
        },
        {
          version: 'v1.1',
          url: '/stock/edit/v1.1',
          method: 'PUT',
          desc: (
            <ul className='style'>
              <li>SKU상품 정보를 수정합니다.</li>
            </ul>
          ),
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String',
              desc: 'sku코드',
            },
            {
              title: 'prod_name',
              required: '',
              type: 'String',
              desc: (
                <div>
                  SKU상품명
                  <div className='description'>
                    상품명은 한글, 영문, 숫자 최대 100Byte 까지만
                    등록가능합니다.
                    <br />
                    특수문자 - _ / ~ + . , ( ) {} [ ] % & '만 사용 가능합니다.
                  </div>
                </div>
              ),
            },
            {
              title: 'madein',
              required: '',
              type: 'Object',
              desc: '원산지',
            },
            {
              title: 'madein_no',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  원산지 번호
                  <div className='description'>
                    (원산지 조회 api 호출하여 확인)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'madein_etc',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  기타원산지
                  <div className='description'>
                    (등록하려는 원산지가 origins 에서 조회되지않을때 madein_no
                    를 비우고 madein_etc 에 값을 넣어줍니다.)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'multi_yn',
              required: 'O',
              type: 'Boolean',
              desc: (
                <div>
                  복수원산지여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'tax_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  과세여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>과세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>면세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>영세</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'brand',
              required: '',
              type: 'String',
              desc: '브랜드',
            },
            {
              title: 'model',
              required: '',
              type: 'String',
              desc: '모델명',
            },
            {
              title: 'maker',
              required: '',
              type: 'String',
              desc: '제조사',
            },
            {
              title: 'maker_eng',
              required: '',
              type: 'String',
              desc: '영문 제조사',
            },
            {
              title: 'made_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  제조일자
                  <div className='description'>
                    (YYYY-MM-DD의 형식으로 입력해주십시오.)
                  </div>
                </div>
              ),
            },
            {
              title: 'expire_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  유효일자
                  <div className='description'>
                    (YYYY-MM-DD의 형식으로 입력해주십시오.)
                  </div>
                </div>
              ),
            },
            {
              title: 'supp_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  매입처코드
                  <div className='description'>(매입처조회 api 이용)</div>
                </div>
              ),
            },
            {
              title: 'prod_width',
              required: '',
              type: 'Number',
              desc: '규격 - 가로 (cm)',
            },
            {
              title: 'prod_height',
              required: '',
              type: 'Number',
              desc: '규격 - 세로 (cm)',
            },
            {
              title: 'prod_depth',
              required: '',
              type: 'Number',
              desc: '규격 - 높이 (cm)',
            },
            {
              title: 'prod_weight',
              required: '',
              type: 'Number',
              desc: '규격 - 무게 (kg)',
            },
            {
              title: 'use_age',
              required: '',
              type: 'String',
              desc: (
                <div>
                  사용연령
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>전체</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>전체</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>0~3세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>4~6세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>7~12세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>13~15세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>16~19세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>20세이상</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'contents',
              required: '',
              type: 'String',
              desc: '상세설명',
            },
            {
              title: 'opt1_type',
              required: '',
              type: 'String',
              desc: '첫번째 옵션구분',
            },
            {
              title: 'opt1',
              required: '',
              type: 'String',
              desc: '첫번째 옵션값',
            },
            {
              title: 'opt2_type',
              required: '',
              type: 'String',
              desc: '두번째 옵션구분',
            },
            {
              title: 'opt2',
              required: '',
              type: 'String',
              desc: '두번째 옵션값',
            },
            {
              title: 'opt3_type',
              required: '',
              type: 'String',
              desc: '세번째 옵션구분',
            },
            {
              title: 'opt3',
              required: '',
              type: 'String',
              desc: '세번째 옵션값',
            },
            {
              title: 'depots',
              required: '',
              type: 'Object[]',
              desc: '배송처 및 재고정보',
            },
            {
              title: 'depot_no',
              required: 'O',
              type: 'Number',
              desc: '배송처번호',
              indent: 1,
            },
            {
              title: 'real_stock',
              required: 'O',
              type: 'Number',
              desc: '실재고',
              indent: 1,
            },
            {
              title: 'safe_stock',
              required: '',
              type: 'Number',
              desc: '안전재고',
              indent: 1,
            },
            {
              title: 'prod_info',
              required: '',
              type: 'Object',
              desc: (
                <div>
                  품목정보고시
                  <br />
                  <br />
                  <div className='description'>
                    아래의 등록가이드를 참고해주세요.
                  </div>
                  <a
                    style={{ color: 'blue' }}
                    href='https://gmp-master.s3.ap-northeast-2.amazonaws.com/download/Sample_prodInfo_guide.xlsx'
                  >
                    품목정보고시 가이드
                  </a>
                </div>
              ),
            },
            {
              title: 'code',
              required: 'O',
              type: 'String',
              desc: '상품분류코드',
              indent: 1,
            },
            {
              title: 'prod_info_1',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시1',
              indent: 1,
            },
            {
              title: 'prod_info_2',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시2',
              indent: 1,
            },
            {
              title: 'prod_info_3',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시3',
              indent: 1,
            },
            {
              title: 'prod_info_4',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시4',
              indent: 1,
            },
            {
              title: 'prod_info_5',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시5',
              indent: 1,
            },
            {
              title: 'prod_info_6',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시6',
              indent: 1,
            },
            {
              title: 'prod_info_7',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시7',
              indent: 1,
            },
            {
              title: 'prod_info_8',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시8',
              indent: 1,
            },
            {
              title: 'prod_info_9',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시9',
              indent: 1,
            },
            {
              title: 'prod_info_10',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시10
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_11',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시11
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_12',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시12
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_13',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시13
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_14',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시14
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_15',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시15
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_16',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시16
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_17',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시17
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_18',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시18
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_19',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시19
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_20',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시20
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_21',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시21
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_22',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시22
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_23',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시23
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_24',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시24
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'product_price',
              required: '',
              type: 'Number',
              desc: '판매가',
            },
            {
              title: 'supply_price',
              required: '',
              type: 'Number',
              desc: '공급가',
            },
            {
              title: 'cost_price',
              required: '',
              type: 'Number',
              desc: '원가',
            },
            {
              title: 'global_barcode_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  UPC/EAN
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>UPC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EAN</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'global_barcode',
              required: '',
              type: 'Number',
              desc: 'UPC/EAN 코드',
            },
            {
              title: 'hscd',
              required: '',
              type: 'String',
              desc: 'HS코드',
            },
            {
              title: 'isbn',
              required: '',
              type: 'Number',
              desc: 'ISBN 코드 (13자리 숫자)',
            },
            {
              title: 'barcode',
              required: '',
              type: 'String',
              desc: '바코드',
            },
            {
              title: 'stock_cd',
              required: '',
              type: 'String',
              desc: '재고관리코드',
            },
          ],
          body_example: {
            sku_cd: 'aowlkj123',
            prod_name: '테스트 SKU상품',
            madein: {
              madein_no: '2',
              madein_etc: '',
              multi_yn: false,
            },
            tax_type: '영세',
            brand: '브랜드',
            maker: '제조사',
            cost_price: '10000',
            delivery_vendor: '746',
            contents: '상세설명',
            opt1_type: '색상',
            opt1: '빨강',
            depots: [
              {
                depot_no: 994,
                real_stock: 99,
                safe_stock: 5,
              },
              {
                depot_no: 1025,
                real_stock: 100,
                safe_stock: 35,
              },
            ],
            prod_info: {
              code: '01',
              prod_info_1: '면-95',
              prod_info_2: 'N',
              prod_info_3: '빨강,파랑',
              prod_info_4: '85,90',
              prod_info_5: '플레이오토',
              prod_info_6: 'Y',
              prod_info_7: '플레이오토',
              prod_info_8: '중국',
              prod_info_9: '드라이크리닝해주세요',
              prod_info_10: '화기에 주의하세요',
              prod_info_11: '2016-09',
              prod_info_12: '구입일로부터 1년',
              prod_info_13: '플레이오토/070-123-4567',
              prod_info_14: '',
              prod_info_15: '',
              prod_info_16: '',
              prod_info_17: '',
              prod_info_18: '',
              prod_info_19: '',
              prod_info_20: '',
              prod_info_21: '',
              prod_info_22: '',
              prod_info_23: '',
              prod_info_24: '',
            },
          },
          response: [
            {
              title: 'sku_cd',
              required: 'O',
              type: 'Number',
              desc: 'sku 코드',
            },
            {
              title: 'result',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  성공, 실패 여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>성공</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>실패</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'error_code',
              required: 'O',
              type: 'String',
              desc: '에러코드',
            },
            {
              title: 'message',
              required: 'O',
              type: 'String',
              desc: '에러메세지',
            },
          ],
          response_example: {
            sku_cd: 'S55512193',
            result: '성공',
            error_code: '',
            message: '',
          },
        },
        {
          version: 'v1.0',
          url: '/stock/edit',
          method: 'PUT',
          desc: (
            <ul className='style'>
              <li>SKU상품 정보를 수정합니다.</li>
            </ul>
          ),
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String',
              desc: 'SKU코드',
            },
            {
              title: 'prod_name',
              required: '',
              type: 'String',
              desc: (
                <div>
                  SKU상품명
                  <div className='description'>
                    상품명은 한글, 영문, 숫자 최대 100Byte 까지만
                    등록가능합니다.
                    <br />
                    특수문자 - _ / ~ + . , ( ) {} [ ] % & '만 사용 가능합니다.
                  </div>
                </div>
              ),
            },
            {
              title: 'madein',
              required: '',
              type: 'Object',
              desc: '원산지',
            },
            {
              title: 'madein_no',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  원산지번호
                  <div className='description'>
                    (원산지 조회 api 호출하여 확인)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'madein_etc',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  기타원산지
                  <div className='description'>
                    (등록하려는 원산지가 origins 에서 조회되지않을때 madein_no
                    를 비우고 madein_etc 에 값을 넣어줍니다.)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'multi_yn',
              required: 'O',
              type: 'Boolean',
              desc: (
                <div>
                  복수원산지여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'tax_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  과세여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>과세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>면세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>영세</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'brand',
              required: '',
              type: 'String',
              desc: '브랜드',
            },
            {
              title: 'model',
              required: '',
              type: 'String',
              desc: '모델명',
            },
            {
              title: 'maker',
              required: '',
              type: 'String',
              desc: '제조사',
            },
            {
              title: 'maker_eng',
              required: '',
              type: 'String',
              desc: '영문 제조사',
            },
            {
              title: 'made_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  제조일자
                  <div className='description'>
                    (YYYY-MM-DD의 형식으로 입력해주십시오.)
                  </div>
                </div>
              ),
            },
            {
              title: 'expire_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  유효일자
                  <div className='description'>
                    (YYYY-MM-DD의 형식으로 입력해주십시오.)
                  </div>
                </div>
              ),
            },
            {
              title: 'delivery_vendor',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  배송처코드
                  <div className='description'>(배송처조회 api 이용)</div>
                </div>
              ),
            },
            {
              title: 'supp_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  매입처코드
                  <div className='description'> (매입처조회 api 이용)</div>
                </div>
              ),
            },
            {
              title: 'prod_width',
              required: '',
              type: 'Number',
              desc: '규격 - 가로 (cm)',
            },
            {
              title: 'prod_height',
              required: '',
              type: 'Number',
              desc: '규격 - 세로 (cm)',
            },
            {
              title: 'prod_depth',
              required: '',
              type: 'Number',
              desc: '규격 - 높이 (cm)',
            },
            {
              title: 'prod_weight',
              required: '',
              type: 'Number',
              desc: '규격 - 무게 (kg)',
            },
            {
              title: 'use_age',
              required: '',
              type: 'String',
              desc: (
                <div>
                  사용연령
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>전체</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>전체</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>0~3세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>4~6세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>7~12세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>13~15세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>16~19세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>20세이상</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'contents',
              required: '',
              type: 'String',
              desc: '상세설명',
            },
            {
              title: 'opt1_type',
              required: '',
              type: 'String',
              desc: '첫번째 옵션구분',
            },
            {
              title: 'opt1',
              required: '',
              type: 'String',
              desc: '첫번째 옵션값',
            },
            {
              title: 'opt2_type',
              required: '',
              type: 'String',
              desc: '두번째 옵션구분',
            },
            {
              title: 'opt2',
              required: '',
              type: 'String',
              desc: '두번째 옵션값',
            },
            {
              title: 'opt3_type',
              required: '',
              type: 'String',
              desc: '세번째 옵션구분',
            },
            {
              title: 'opt3',
              required: '',
              type: 'String',
              desc: '세번째 옵션값',
            },
            {
              title: 'real_stock',
              required: '',
              type: 'Number',
              desc: '실재고',
            },
            {
              title: 'safe_stock',
              required: '',
              type: 'Number',
              desc: '안전재고',
            },
            {
              title: 'prod_info',
              required: '',
              type: 'Object',
              desc: (
                <div>
                  품목정보고시
                  <br />
                  <br />
                  <div className='description'>
                    아래의 등록가이드를 참고해주세요.
                  </div>
                  <a
                    style={{ color: 'blue' }}
                    href='https://gmp-master.s3.ap-northeast-2.amazonaws.com/download/Sample_prodInfo_guide.xlsx'
                  >
                    품목정보고시 가이드
                  </a>
                </div>
              ),
            },
            {
              title: 'code',
              required: 'O',
              type: 'String',
              desc: '상품분류코드',
              indent: 1,
            },
            {
              title: 'prod_info_1',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시1',
              indent: 1,
            },
            {
              title: 'prod_info_2',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시2',
              indent: 1,
            },
            {
              title: 'prod_info_3',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시3',
              indent: 1,
            },
            {
              title: 'prod_info_4',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시4',
              indent: 1,
            },
            {
              title: 'prod_info_5',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시5',
              indent: 1,
            },
            {
              title: 'prod_info_6',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시6',
              indent: 1,
            },
            {
              title: 'prod_info_7',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시7',
              indent: 1,
            },
            {
              title: 'prod_info_8',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시8',
              indent: 1,
            },
            {
              title: 'prod_info_9',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시9',
              indent: 1,
            },
            {
              title: 'prod_info_10',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시10
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_11',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시11
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_12',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시12
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_13',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시13
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_14',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시14
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_15',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시15
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_16',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시16
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_17',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시17
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_18',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시18
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_19',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시19
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_20',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시20
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_21',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시21
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_22',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시22
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_23',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시23
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_24',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시24
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'product_price',
              required: '',
              type: 'Number',
              desc: '판매가',
            },
            {
              title: 'supply_price',
              required: '',
              type: 'Number',
              desc: '공급가',
            },
            {
              title: 'cost_price',
              required: '',
              type: 'Number',
              desc: '원가',
            },
            {
              title: 'global_barcode_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  UPC/EAN 타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>UPC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EAN</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'global_barcode',
              required: '',
              type: 'Number',
              desc: 'UPC/EAN 코드',
            },
            {
              title: 'hscd',
              required: '',
              type: 'String',
              desc: 'HS코드',
            },
            {
              title: 'isbn',
              required: '',
              type: 'Number',
              desc: 'ISBN코드 (13자리 숫자)',
            },
            {
              title: 'barcode',
              required: '',
              type: 'String',
              desc: '바코드',
            },
            {
              title: 'stock_cd',
              required: '',
              type: 'String',
              desc: '재고관리코드',
            },
          ],
          body_example: {
            sku_cd: 'aowlkj123',
            prod_name: '테스트 SKU상품',
            madein: {
              madein_no: '2',
              madein_etc: '',
              multi_yn: false,
            },
            tax_type: '영세',
            brand: '브랜드',
            maker: '제조사',
            cost_price: '10000',
            delivery_vendor: '746',
            contents: '상세설명',
            opt1_type: '색상',
            opt1: '빨강',
            prod_info: {
              code: '01',
              prod_info_1: '면-95',
              prod_info_2: 'N',
              prod_info_3: '빨강,파랑',
              prod_info_4: '85,90',
              prod_info_5: '플레이오토',
              prod_info_6: 'Y',
              prod_info_7: '플레이오토',
              prod_info_8: '중국',
              prod_info_9: '드라이크리닝해주세요',
              prod_info_10: '화기에 주의하세요',
              prod_info_11: '2016-09',
              prod_info_12: '구입일로부터 1년',
              prod_info_13: '플레이오토/070-123-4567',
              prod_info_14: '',
              prod_info_15: '',
              prod_info_16: '',
              prod_info_17: '',
              prod_info_18: '',
              prod_info_19: '',
              prod_info_20: '',
              prod_info_21: '',
              prod_info_22: '',
              prod_info_23: '',
              prod_info_24: '',
            },
          },
          response: [
            {
              title: 'sku_cd',
              required: 'O',
              type: 'Number',
              desc: 'SKU코드',
            },
            {
              title: 'result',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  성공, 실패 여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>성공</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>실패</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'error_code',
              required: 'O',
              type: 'String',
              desc: '에러코드',
            },
            {
              title: 'message',
              required: 'O',
              type: 'String',
              desc: '에러메세지',
            },
          ],
          response_example: {
            sku_cd: 'S55512193',
            result: '성공',
            error_code: '',
            message: '',
          }
        },
      ],
    },
    {
      title: '세트상품 조회',
      url: '/doc/세트상품 조회',
      data: [
        {
          version: 'v1.0',
          url: '/stock/sets',
          method: 'POST',
          desc: (
            <ul className='style'>
              <li>세트상품목록을 조회합니다.</li>
            </ul>
          ),
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'start',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  조회할 시작 카운트
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'limit',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  조회할 갯수
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>100</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'orderbyType',
              required: '',
              type: 'String',
              desc: (
                <div>
                  정렬타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>DESC</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>ASC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>DESC</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'orderbyColumn',
              required: '',
              type: 'String',
              desc: (
                <div>
                  정렬대상
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>등록일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>set_no</code>
                          </strong>
                        </td>
                        <td>
                          <strong>세트코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>set_status</code>
                          </strong>
                        </td>
                        <td>
                          <strong>상태</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>mdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>수정일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>link_yn</code>
                          </strong>
                        </td>
                        <td>
                          <strong>연동상품</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>link_ord_yn</code>
                          </strong>
                        </td>
                        <td>
                          <strong>연동주문</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'date_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  날짜검색대상
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>등록일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>mdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>수정일</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sdate',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색시작일
                  <div className='description'>(YYYY-MM-DD)</div>
                  <strong>
                    <code>미입력시 기본 30일로 조회됩니다.</code>
                  </strong>
                </div>
              ),
            },
            {
              title: 'edate',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색종료일
                  <div className='description'>(YYYY-MM-DD)</div>
                </div>
              ),
            },
            {
              title: 'search_key',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색 키
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowd values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>set_no</code>
                          </strong>
                        </td>
                        <td>
                          <strong>세트번호</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>set_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>세트코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>set_name</code>
                          </strong>
                        </td>
                        <td>
                          <strong>세트상품명</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>sku_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>stock_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>재고관리코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>prod_name</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU상품명</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>memo</code>
                          </strong>
                        </td>
                        <td>
                          <strong>메모</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <strong>
                    <ul className='style'>
                      <li>
                        <code>값을 넣지않는경우 전체검색됩니다.</code>
                      </li>
                    </ul>
                  </strong>
                </div>
              ),
            },
            {
              title: 'search_word',
              required: '',
              type: 'String',
              desc: '검색어',
            },
            {
              title: 'search_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>partial</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>exact</code>
                          </strong>
                        </td>
                        <td>
                          <strong>완전일치</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>partial</code>
                          </strong>
                        </td>
                        <td>
                          <strong>부분일치</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'depot_no',
              required: '',
              type: 'Number',
              desc: '배송처 번호',
            },
            {
              title: 'set_status',
              required: '',
              type: 'String',
              desc: (
                <div>
                  세트상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>재고부족</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>사용불가</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
          ],
          body_example: {
            start: 0,
            limit: 100,
            orderbyColumn: 'wdate',
            orderbyType: 'DESC',
            search_key: '',
            search_word: '테스트',
            search_type: 'partial',
            date_type: 'wdate',
            sdate: '2020-12-25',
            edate: '2021-03-23',
          },
          response: [
            {
              title: 'results',
              required: 'O',
              type: 'Array',
              desc: '결과',
            },
            {
              title: 'set_no',
              required: 'O',
              type: 'Number(10)',
              desc: '세트번호(고유값)',
              indent: 1,
            },
            {
              title: 'sol_no',
              required: 'O',
              type: 'Number(8)',
              desc: '솔루션번호',
              indent: 1,
            },
            {
              title: 'depot_no',
              required: 'O',
              type: 'Number(8)',
              desc: '배송처번호',
              indent: 1,
            },
            {
              title: 'wdate',
              required: 'O',
              type: 'String(19)',
              desc: '세트 등록일',
              indent: 1,
            },
            {
              title: 'mdate',
              required: 'O',
              type: 'String(19)',
              desc: '세트 수정일',
              indent: 1,
            },
            {
              title: 'set_cd',
              required: 'O',
              type: 'String(40)',
              desc: '세트 코드',
              indent: 1,
            },
            {
              title: 'set_status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  세트상태
                  <div className='description'>
                    (정상, 재고부족, 사용불가, 품절)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>재고부족</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>사용불가</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'set_name',
              required: 'O',
              type: 'String(200)',
              desc: '세트이름',
              indent: 1,
            },
            {
              title: 'sale_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  세트판매가
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'use_sale_cnt_limit_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  세트판매수량 사용여부
                  <div className='description'>(1:사용함, 0:사용안함)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sale_cnt_limit',
              required: 'O',
              type: 'Number(10)',
              desc: '세트판매수량',
              indent: 1,
            },
            {
              title: 'main_prod_no',
              required: 'O',
              type: 'Number(10)',
              desc: '대표SKU상품번호',
              indent: 1,
            },
            {
              title: 'delivery_vendor_name',
              required: 'O',
              type: 'String(45)',
              desc: '배송처명',
              indent: 1,
            },
            {
              title: 'set_prod_cnt',
              required: 'O',
              type: 'Number',
              desc: '세트에 할당된 SKU상품 수',
              indent: 1,
            },
            {
              title: 'set_sku_cds',
              required: 'O',
              type: 'String',
              desc: '세트에 할당된 SKU상품 sku코드',
              indent: 1,
            },
            {
              title: 'link_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '매칭된 온라인상품 수',
              indent: 1,
            },
            {
              title: 'link_ord_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '매칭된 주문 수',
              indent: 1,
            },
            {
              title: 'memo',
              required: 'O',
              type: 'Array',
              desc: '세트에 등록된 메모정보',
              indent: 1,
            },
            {
              title: 'set_no',
              required: 'O',
              type: 'Number(10)',
              desc: '세트번호(고유값)',
              indent: 2,
            },
            {
              title: 'memo_no',
              required: 'O',
              type: 'Number(10)',
              desc: '메모번호(고유값)',
              indent: 2,
            },
            {
              title: 'wdate',
              required: 'O',
              type: 'String(19)',
              desc: '메모등록일',
              indent: 2,
            },
            {
              title: 'mdate',
              required: 'O',
              type: 'String(19)',
              desc: '메모수정일',
              indent: 2,
            },
            {
              title: 'memo_Status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  메모상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>등록</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>완료</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'wm_no',
              required: 'O',
              type: 'Number(8)',
              desc: '메모등록자',
              indent: 2,
            },
            {
              title: 'content',
              required: 'O',
              type: 'String(65535)',
              desc: '메모내용',
              indent: 2,
            },
            {
              title: 'total',
              required: 'O',
              type: 'Number',
              desc: '검색된 세트상품 수',
            },
          ],
          response_example: {
            results: [
              {
                set_no: 23149,
                sol_no: 640,
                depot_no: 746,
                wdate: '2021-03-23 11:34:55',
                mdate: '2021-03-23 11:34:55',
                model_no: 'SETA.12340',
                set_status: '정상',
                set_name: '테스트 세트',
                set_cd: 'SETDAFEA-BLS-FFATAD-ADF5-4',
                sale_price: 40000,
                use_sale_cnt_limit_yn: 0,
                sale_cnt_limit: null,
                main_prod_no: 5515759,
                delivery_vendor_name: '로니 배송처',
                set_prod_cnt: 3,
                set_sku_cds: 'S2321B1D9CF9,S109CFEF4160,S1107CB504E4',
                link_yn: 0,
                link_ord_yn: 0,
                memo: [
                  {
                    set_no: 23149,
                    memo_no: 2313832,
                    wdate: '2021-03-23 11:41:29',
                    mdate: '2021-03-23 11:41:29',
                    memo_Status: '등록',
                    wm_no: 1957,
                    content: '메모 해봅니다.',
                  },
                ],
              },
              {
                set_no: 23148,
                sol_no: 640,
                depot_no: 775,
                wdate: '2021-03-23 11:34:07',
                mdate: '2021-03-23 11:34:07',
                model_no: null,
                set_status: '정상',
                set_name: '캐주얼 패딩',
                set_cd: 'SETFDDFA-BSF-FFATAD-ADF5-6',
                sale_price: 90000,
                use_sale_cnt_limit_yn: 1,
                sale_cnt_limit: 10,
                main_prod_no: 4598998,
                delivery_vendor_name: '로니 배송처',
                set_prod_cnt: 5,
                set_sku_cds:
                  'S2891CCA5D16,S8065D991761,S226F05C48B3,S9000CBBD792,S1415CA419AA',
                link_yn: 0,
                link_ord_yn: 0,
                memo: [],
              },
            ],
            total: 2,
          },
        },
      ],
    },
    {
      title: '재고수정',
      url: '/doc/재고수정',
      data: [
        {
          version: 'v1.1',
          url: '/stock/manage/v1.1',
          method: 'PUT',
          desc: (
            <ul className='style'>
              <li>SKU상품의 실재고의 재고를 차감 또는 증가시킵니다.</li>
              <li>SKU상품의 안전재고를 변경합니다.</li>
            </ul>
          ),
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'stocks',
              required: 'O',
              type: 'Object[]',
              desc: '재고를 출고, 또는 입고시킬 sku 리스트를 입력합니다.',
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String',
              desc: 'sku코드',
              indent: 1,
            },
            {
              title: 'depot_no',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  배송처번호
                  <div className='description'>(배송처조회 api 이용)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'set',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  재고를 변경할 대상을 설정합니다.
                  <div className='description'>
                    안전재고로 설정시 입력한 수량이 입,출고되는게 아닌 입력한
                    수량으로 안전재고값이 업데이트됩니다.
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>실재고</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>안전재고</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  재고적용 방식을 설정합니다.
                  <div className='description'>실재고 입력시 필수입니다..</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>출고</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>입고</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'count',
              required: 'O',
              type: 'Number',
              desc: '변경할 재고수량을 입력합니다.',
            },
          ],
          body_example: {
            stocks: [
              {
                sku_cd: 'S9385D316739',
                depot_no: 775,
              },
            ],
            set: '실재고',
            type: '입고',
            count: 20,
          },
          response: [
            {
              title: 'result',
              required: 'O',
              type: 'String',
              desc: '성공, 실패여부',
            },
            {
              title: 'stockAlarm',
              required: 'O',
              type: 'Object[]',
              desc: '품절 및 재고부족 상품정보',
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String',
              desc: '품절 및 재고부족 상품정보',
              indent: 1,
            },
            {
              title: 'prod_name',
              required: 'O',
              type: 'String',
              desc: '품절 및 재고부족 상품명',
              indent: 1,
            },
            {
              title: 'stock_status',
              required: 'O',
              type: 'String',
              desc: '품절 또는 재고부족 상태',
              indent: 1,
            },
          ],
          response_example: {
            result: '성공',
            stockAlarm: [
              {
                sku_cd: 'S9385D316739',
                prod_name: '다중배송처 엑셀 수정 테스트',
                stock_status: '재고 부족',
              },
            ],
          },
        },
        {
          version: 'v1.0',
          url: '/stock/manage',
          method: 'PUT',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'sku_cds',
              required: 'O',
              type: 'Object[]',
              desc: '재고를 출고, 또는 입고시킬 sku 리스트를 입력합니다.',
            },
            {
              title: 'set',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  재고를 변경할 대상을 설정합니다.
                  <div className='description'>
                    안전재고로 설정시 입력한 수량이 입,출고되는게 아닌 입력한
                    수량으로 안전재고값이 업데이트됩니다.
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>실재고</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>안전재고</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  재고적용 방식을 설정합니다.
                  <div className='description'>실재고 입력시 필수입니다..</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>출고</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>입고</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'count',
              required: 'O',
              type: 'Number',
              desc: '변경할 재고수량을 입력합니다.',
            },
          ],
          body_example: {
            sku_cds: ['S999C90F18A4'],
            set: '실재고',
            type: '입고',
            count: 20,
          },
          response: [
            {
              title: 'sku_cds',
              required: 'O',
              type: 'String[]',
              desc: '호출된 sku 정보',
            },
            {
              title: 'result',
              required: 'O',
              type: 'String',
              desc: '성공,실패여부',
            },
            {
              title: 'stockAlarm',
              required: 'O',
              type: 'Object[]',
              desc: '품절 및 재고부족 상품정보',
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String',
              desc: '품절 및 재고부족 상품의 SKU코드',
              indent: 1,
            },
            {
              title: 'stock_status',
              required: 'O',
              type: 'String',
              desc: '품절 또는 재고부족 상태',
              indent: 1,
            },
          ],
          response_example: {
            sku_cds: ['S999C90F18A4'],
            result: '성공',
            stockAlarm: [
              {
                sku_cd: 'S999C90F18A4',
                stock_status: '재고 부족',
              },
            ],
          },
        },
      ],
    },
    {
      title: '재고현황조회',
      url: '/doc/재고현황조회',
      data: [
        {
          version: 'v1.0',
          url: '/stock/condition',
          method: 'POST',
          desc: (
            <ul className='style'>
              <li>재고 현황을 조회합니다.</li>
              <li>기본 날짜 검색일을 90일 입니다.</li>
            </ul>
          ),
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'start',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  조회할 시작 카운트
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'limit',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  조회할 갯수
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>100</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'orderbyType',
              required: '',
              type: 'String',
              desc: (
                <div>
                  정렬타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>DESC</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>ASC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>DESC</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'orderbyColumn',
              required: '',
              type: 'String',
              desc: (
                <div>
                  정렬대상
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>등록일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>sku_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>stock_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>재고관리코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>stock_status</code>
                          </strong>
                        </td>
                        <td>
                          <strong>재고상태</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>stock_cnt_real</code>
                          </strong>
                        </td>
                        <td>
                          <strong>실재고</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>stock_cnt_safe</code>
                          </strong>
                        </td>
                        <td>
                          <strong>안전재고</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'search_key',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색 키
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>sku_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>stock_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>재고관리코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>prod_name</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU상품명</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <strong>
                    <ul className='style'>
                      <li>
                        <code>값을 넣지않는경우 전체검색됩니다.</code>
                      </li>
                    </ul>
                  </strong>
                </div>
              ),
            },
            {
              title: 'search_word',
              required: '',
              type: 'String',
              desc: '검색어',
            },
            {
              title: 'search_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>partial</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>exact</code>
                          </strong>
                        </td>
                        <td>
                          <strong>완전일치</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>partial</code>
                          </strong>
                        </td>
                        <td>
                          <strong>부분일치</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'data_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  날짜검색대상
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>등록일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>mdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>수정일</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sdate',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색시작일
                  <div className='description'>
                    ( YYYY-MM-DD | YYYY-MM-DD HH:MM:SS )<br />
                    미입력시 기본 90일로 조회됩니다.
                  </div>
                </div>
              ),
            },
            {
              title: 'edate',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색종료일
                  <div className='description'>
                    ( YYYY-MM-DD | YYYY-MM-DD HH:MM:SS )
                  </div>
                </div>
              ),
            },
            {
              title: 'supp_no',
              required: '',
              type: 'Number',
              desc: '매입처 번호',
            },
            {
              title: 'depot_no',
              required: '',
              type: 'Number',
              desc: '배송처 번호',
            },
            {
              title: 'state',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  재고상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>재고부족</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            ...(userInfo.sol_no === 104083 ? [{
              title: 'sub_sol_no_list',
              required: '',
              type: 'Number[]',
              desc: (
                <div>
                  하위계정 sol_no 리스트
                  <div className='description'>
                    (값이 없는 경우 하위계정 전체 조회)
                  </div>
                </div>
              ),
            }] : []),
          ],
          body_example: {
            start: 0,
            limit: 100,
            orderbyColumn: 'wdate',
            orderbyType: 'DESC',
            search_key: 'all',
            search_word: '',
            search_type: 'partial',
            date_type: 'wdate',
            sdate: '2020-12-25',
            edate: '2021-01-25',
          },
          response: [
            {
              title: 'results',
              required: 'O',
              type: 'Array',
              desc: '결과',
            },
            {
              title: 'sol_no',
              required: 'O',
              type: 'Number(8)',
              desc: '솔루션번호',
              indent: 1,
            },
            {
              title: 'prod_no',
              required: 'O',
              type: 'Number(10)',
              desc: 'SKU상품고유번호',
              indent: 1,
            },
            {
              title: 'depot_no',
              required: 'O',
              type: 'Number(8)',
              desc: '배송처번호',
              indent: 1,
            },
            {
              title: 'depot_name',
              required: 'O',
              type: 'String(45)',
              desc: '배송처이름',
              indent: 1,
            },
            {
              title: 'stock_status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  재고상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>재고부족</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String(40)',
              desc: 'SKU코드',
              indent: 1,
            },
            {
              title: 'prod_img',
              required: 'O',
              type: 'String(255)',
              desc: '이미지',
              indent: 1,
            },
            {
              title: 'prod_name',
              required: 'O',
              type: 'String(200)',
              desc: 'SKU상품명',
              indent: 1,
            },
            {
              title: 'brand',
              required: 'O',
              type: 'String(50)',
              desc: '브랜드',
              indent: 1,
            },
            {
              title: 'maker',
              required: 'O',
              type: 'Sting(50)',
              desc: '제조사',
              indent: 1,
            },
            {
              title: 'barcode',
              required: 'O',
              type: 'String(50)',
              desc: '바코드',
              indent: 1,
            },
            {
              title: 'made_date',
              required: 'O',
              type: 'String(10)',
              desc: '제조일자',
              indent: 1,
            },
            {
              title: 'expire_date',
              required: 'O',
              type: 'String(10)',
              desc: '유효일자',
              indent: 1,
            },
            {
              title: 'wdate',
              required: 'O',
              type: 'String(19)',
              desc: 'SKU상품 등록일',
              indent: 1,
            },
            {
              title: 'sale_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  판매가
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cost_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  원가
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'supply_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  공급가
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'supp_name',
              required: 'O',
              type: 'String(50)',
              desc: '매입처',
              indent: 1,
            },
            {
              title: 'attri',
              required: 'O',
              type: 'String(150)',
              desc: '옵션',
              indent: 1,
            },
            {
              title: 'stock_cnt_real',
              required: 'O',
              type: 'Number(8)',
              desc: '실재고',
              indent: 1,
            },
            {
              title: 'stock_cnt',
              required: 'O',
              type: 'Number(8)',
              desc: '판매가능재고',
              indent: 1,
            },
            {
              title: 'in_cnt_accum',
              required: 'O',
              type: 'Number(10)',
              desc: '입고수량',
              indent: 1,
            },
            {
              title: 'out_cnt_accum',
              required: 'O',
              type: 'Number(10)',
              desc: '출고수량',
              indent: 1,
            },
            {
              title: 'out_scheduled',
              required: 'O',
              type: 'Number(10)',
              desc: '출고예정수',
              indent: 1,
            },
            {
              title: 'sale_cnt_accum',
              required: 'O',
              type: 'Number(10)',
              desc: '구매수량',
              indent: 1,
            },
            {
              title: 'return_cnt_accum',
              required: 'O',
              type: 'Number(10)',
              desc: '반품수량',
              indent: 1,
            },
            {
              title: 'exchange_cnt_accum',
              required: 'O',
              type: 'Number(10)',
              desc: '교환수량',
              indent: 1,
            },
            {
              title: 'unavail_out_cnt',
              required: 'O',
              type: 'Number(10)',
              desc: '미출고',
              indent: 1,
            },
            {
              title: 'link_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  연동상품여부
                  <div className='description'>
                    (1: 연동상품 있음, 0: 연동상품 없음)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'link_set_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  연동세트상품여부
                  <div className='description'>
                    (1: 연동세트상품 있음, 0: 연동세트상품 없음)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'link_ord_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  연동주문여부
                  <div className='description'>
                    (1: 연동주문 있음, 0: 연동주문 없음)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'memo_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '메모여부',
              indent: 1,
            },
            {
              title: 'memo_complete_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '완료메모여부',
              indent: 1,
            },
            {
              title: 'alloc_rule_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '재고할당여부',
              indent: 1,
            },
            {
              title: 'realloc_rule_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '재고 재할당여부',
              indent: 1,
            },
            {
              title: 'recordsTotal',
              required: 'O',
              type: 'Number',
              desc: '총 카운트',
            },
            {
              title: 'all_stock',
              required: 'O',
              type: 'Number',
              desc: '조회된 모든 상태의 SKU상품 수',
            },
            {
              title: 'ok_stock',
              required: 'O',
              type: 'Number',
              desc: '정상상태 SKU상품 수',
            },
            {
              title: 'real_stock_none',
              required: 'O',
              type: 'Number',
              desc: '재고부족 SKU상품 수',
            },
            {
              title: 'safe_stock_alert',
              required: 'O',
              type: 'Number',
              desc: '품절 SKU상품 수',
            },
          ],
          response_example: {
            results: [
              {
                sol_no: 9999999,
                prod_no: 5945137,
                depot_no: 2998,
                depot_name: 'TEST 배송처',
                stock_status: '정상',
                sku_cd: 'S244E0FF7456',
                stock_cd: '',
                prod_img: '',
                prod_name: 'TEST SKU상품',
                brand: '',
                maker: '',
                barcode: '',
                made_date: null,
                expire_date: null,
                wdate: '2020-12-28 12:00:36',
                sale_price: 0,
                cost_price: 0,
                supply_price: 0,
                supp_name: null,
                attri: "'ㅁ'",
                stock_cnt_real: 100,
                stock_cnt: 100,
                stock_cnt_safe: 25,
                in_cnt_accum: 100,
                out_cnt_accum: 0,
                out_scheduled: 0,
                sale_cnt_accum: 0,
                return_cnt_accum: 0,
                exchange_cnt_accum: 0,
                unavail_out_cnt: 0,
                link_yn: 0,
                link_set_yn: 0,
                link_ord_yn: 0,
                memo_yn: 0,
                memo_complete_yn: 0,
                alloc_rule_yn: 0,
                realloc_rule_yn: 0,
              },
            ],
            recordsTotal: 1,
            all_stock: 1,
            ok_stock: 1,
            real_stock_none: 0,
            safe_stock_alert: 0,
          },
        },
      ],
    },
  ],
});

export default data;
