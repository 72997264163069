import classes from './DescriptionPageTemplate.module.css';
import { Fragment, useState } from 'react';
import jsonFormat from 'json-format';
import ApiCallEditor from '../../UI/ApiCallEditor';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-chrome';
import 'ace-builds/src-noconflict/ext-language_tools';
import { simpleSubmitHandler } from '../../../util/HelperFunctions';
import AccordionSideNav from '../../layout/SideNav/AccordionSideNav/AccordionSideNav';
import config from '../../../store/config';


/**
 * API문서 내부의 각 테이블에 해당하는 컴포넌트
 */
const TableComponent = (props) => {
  return (
    <table>
      <colgroup>
        {[170, 90, 50, 560].map((width, i) => <col width={width} key={i} />)}
      </colgroup>
      <tbody>
        <tr>
          <th>변수<br />(Parameter)</th>
          <th>타입<br />(Type)</th>
          <th>필수<br />여부</th>
          <th>설명<br />(Description)</th>
        </tr>
        {
          props.data.map((item, i) => (
            <tr key={i}>
              <td>{item.indent && Array(item.indent).fill(null).map((_, i) => <span className={classes.indent} key={i}></span>)}{item.title}</td>
              <td className='text-center'>{item.type}</td>
              <td className='text-center'>{item.required}</td>
              <td>{typeof item.desc === 'string' ? 
                item.desc.split('\n').map((str, i) => (
                  <Fragment key={i}>
                    {i ? <br /> : null}
                    {str}
                  </Fragment>
                )) :
                item.desc}
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  );
}

/**
 * json데이터를 json형태의 문자열로 반환하는 함수
 */
function jsonFormatIfExist(value) {
  return (value && jsonFormat(value)) || '';
}

/**
 * Open API 문서 상세 페이지 컴포넌트
 */
const DescriptionPageTemplate = (props) => {
  const [versionIndex, setVersionIndex] = useState(0); 
  const pageData = props.pageDataRaw.data[versionIndex];

  const [responseTabIndex, setResponseTabIndex] = useState('success');
  const [headerJsonString, setHeaderJsonString] = useState(jsonFormatIfExist(pageData.header_example));
  const [bodyJsonString, setBodyJsonString] = useState(jsonFormatIfExist(pageData.body_example));
  const [responseJsonString, setResponseJsonString] = useState(jsonFormatIfExist(pageData.response_example));
  const [responseErrorString, setResponseErrorString] = useState(jsonFormatIfExist(pageData.error_example));
  const [isLoading, setIsLoading] = useState(false);
  const [requestUrl, setRequestUrl] = useState(pageData.url);

  const onVerChange = (e) => {
    setVersionIndex(e.target.value);
    const updated_doc_data = props.pageDataRaw.data[e.target.value];
    setHeaderJsonString(jsonFormatIfExist(updated_doc_data.header_example));
    setBodyJsonString(jsonFormatIfExist(updated_doc_data.body_example));
    setResponseJsonString(jsonFormatIfExist(updated_doc_data.response_example));
    setResponseErrorString(jsonFormatIfExist(updated_doc_data.error_example));
  }
  
  /**
   * Open API 테스트 호출 처리 함수
   */
  async function handleTestRequest() {
    setIsLoading(true);
    let request_url = `/api${requestUrl}`;
    const requestOption = {
      method: pageData.method,
      headers: {...JSON.parse(headerJsonString), 'Content-Type': 'application/json'},
      credentials: 'include'
    };
    if (pageData.method !== 'GET') {
      requestOption.body = bodyJsonString
    }

    simpleSubmitHandler(setIsLoading, request_url, requestOption, res_json => setResponseJsonString(jsonFormat(res_json)));
  }

  return (
    <>
      <AccordionSideNav data={props.filteredPageDataList} />
      <div className={classes.container}>
        <select className={classes.versionSelect } onChange={onVerChange}>
        {
          props.pageDataRaw.data.map((data, index) => {
          return <option key={data.version} value={index}>{data.version}</option>
          })
        }
        </select>
        <h1 className={classes.heading}>{props.pageDataRaw.title}</h1>
        <div className={classes.section}>
          <h4 className={classes['sub-heading']}>Description</h4>
          {pageData.desc}
        </div>
        <div id='http-method' className={classes.section}>
          <h4>HTTP Method</h4>
          <div className={classes[pageData.method]}>
            <div className={classes.method}>{pageData.method}</div>
          </div>
          {
            pageData.method === 'POST' &&
            '(Content-Type: application/json; charset=UTF-8)'
          }
        </div>
        <div id='request-url' className={classes.section}>
          <h4 className={classes['sub-heading']}>Request URL</h4>
          <div>{config.backendUrl + '/api' + pageData.url}</div>
        </div>
        {
          ['Header', 'Body', 'Query', 'Parameter', 'Response'].map((item, i) => {
            if (!pageData[item.toLowerCase()]) return null;
            return <div id={`${item}-table`} className={classes.section} key={i}>
              <h4 className={classes['sub-heading']}>{item === 'Parameter' ?  'URL Parameters' : ['Header', 'Response'].includes(item) ? item : item + ' Parameters'}</h4>
              <TableComponent data={pageData[item.toLowerCase()]} />
            </div>
          })
        }
        <div className={classes.section + ' ' + classes['api-test']}>
          <h4 className={classes['sub-heading']}>API TEST</h4>
          <div className={classes.url}>
            {/* MEHTOD 삽입 */}
            <div className={classes[pageData.method]}>
              <div className={classes.method}>{pageData.method}</div>
            </div>
            {/* api 주소 입력 영역 */}
            <div className={classes.input}>
              <div>
                {config.backendUrl + '/api'}
              </div>
              <input className='focus-highlight' type='text' value={requestUrl} onChange={e => setRequestUrl(e.target.value)} />
            </div>
            <button onClick={handleTestRequest} className={`${classes.send}`} disabled={isLoading}>Send</button>
          </div>
          <div className={classes.editor}>
            <h6 className={classes.header}>Header</h6>
              <ApiCallEditor 
                onChange={value => setHeaderJsonString(value)}
                value={headerJsonString}
                height='100px'
              />
          </div>
          {
            pageData.body_example && 
            <div className={classes.editor}>
              <h6 className={classes.header}>Parameter</h6>
              <ApiCallEditor 
                  onChange={value => setBodyJsonString(value)}
                  value={bodyJsonString}
                />
            </div>
          }
          <div className={classes.editor}>
            <div className={classes.header}>
              <div className={classes['tab-group']}>
                <button onClick={() => setResponseTabIndex('success')} className={responseTabIndex === 'success' ? classes.active : ''}>Success Response</button>
                {
                  responseErrorString && 
                  <button onClick={() => setResponseTabIndex('error')} className={responseTabIndex === 'error' ? classes.active : ''}>Error Response</button>
                }
              </div>
            </div>
            <ApiCallEditor 
              onChange={value => {responseTabIndex === 'success' ? setResponseJsonString(value): setResponseErrorString(value)}}
              value={responseTabIndex === 'error' && responseErrorString ? responseErrorString : responseJsonString}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DescriptionPageTemplate;
