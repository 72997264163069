import classes from './ValidationInput.module.css';
import { useState } from 'react';
import cryptoRandomString from 'crypto-random-string';

/**
 * 유효성 검사 기능이 있는 인풋 컴포넌트
 */
const ValidationInput = (props) => {
  const [isTouched, setIsTouched] = useState(false);
  const randomId = cryptoRandomString({length: 10, characters: 'abc'});
  const valid = (props.optional && !props.value?.trim()) ? true : props.regex ? props.regex.test(props.value) : props.valid;

  return (
    <div className={classes.container + ' ValidationInput'}>
      <label htmlFor={randomId}>
        {props.label}
      </label>
      <input 
        value={props.value || ''}
        id={randomId} 
        type={props.type}
        disabled={props.disabled}
        onBlur={() => setIsTouched(true)}
        onChange={e => props.onChange(e)}
        placeholder={props.placeholder}
        className={(valid ? classes.valid : '') +  ' ' + ((isTouched || props.isTouched) ? classes.touched : '')}
      />
      {(!valid && (isTouched || props.isTouched)) && <div className={classes.warning}>{!props.value ? '필수 항목입니다.' : props.validation_err_msg || `올바른 ${props.label || props.title} 형식이 아닙니다.`}</div>}
    </div>
  );
};

export default ValidationInput;
