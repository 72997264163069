import { useEffect } from 'react';
import Swal from 'sweetalert2';
import config from '../store/config';


function useFetchData(url, option, handleResponse, ...dependency) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(config.backendUrl + url, option)
        if (res.ok) {
          const res_json = await res.json();
          if (res_json.status === 'error' || res_json.response?.error) {
            throw new Error(res_json.message);
          }
          handleResponse(res_json);
        } else {
          throw new Error(res.message);
        }
      } catch (e) {
        Swal.fire({
          icon: 'error',
          text: e.message,
          confirmButtonColor: '#3085d6',
        });
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, dependency);
}

export default useFetchData;