import styled from 'styled-components';
import SimpleSideNav from '../../layout/SideNav/SimpleSideNav';
import { GuideContentList } from '../../../store/LeftContentList';
import { Link } from 'react-router-dom';

const VersionPage = () => {
  return (
    <>
      <SimpleSideNav data={GuideContentList} />
      <Container>
        <h2>버전관리</h2>
        <br /><br />

        PLAYAUTO 2.0 OPEN-API 는 버전관리를 지원합니다.<br /><br /><br />

        기본 1.0 버전으로 1.1, 1.2 등으로 버전이 올라갑니다.<br />
        각 API 의 버전은 API 문서를 통해 확인이 가능하며 기본적으로 최신 버전의 내용으로 문서가 출력됩니다.<br /><br /><br />

        이전 버전의 문서를 확인하고 싶으신경우 각문서에서 확인을 원하는 버전을 선택하여 주시면 됩니다.<br /><br /><br />

        기존 프로세스에 영향이 없는 파라미터 추가및 리턴값등의 업데이트에는 버전변경이 없으나<br />
        호출 파라미터 삭제 및 리턴포멧 변경등의 기존프로세스 변경이 있는경우에 새로운 버전이 추가되어 지원됩니다.<br /><br /><br />

        각 API 의 버전별 변경내역은<br />
        <Link to='/release'>
          <font color="red">API 문서 {'=>'} 변경내역</font>
        </Link><br />
        페이지에서 확인 가능합니다.<br /><br /><br />

        구 버전의 경우 일정 시간 경과후 지원이 종료될 수 있으니<br />
        가급적 최신 버전의 API 를 유지하여 주십시오.<br /><br /><br />

      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 110px 100px;

  > h2 {
    font-size: 36px;
    font-weight: bold;
  }
`;

export default VersionPage;