import styled from 'styled-components';
import SimpleSideNav from '../../layout/SideNav/SimpleSideNav';
import { GuideContentList } from '../../../store/LeftContentList';
import tokenBucket from '../../../assets/img/token_bucket.png';

const LimitPage = () => {
  return (
    <>
      <SimpleSideNav data={GuideContentList} />
      <Container>
        <h2>호출제한</h2>
        <br /><br />

        <h4>API 호출제한 정책</h4>
        <ul class='style'>
          <li>하루에 호출가능한 API 카운트는 12,000 입니다.</li>
          <li>하루의 호출 카운트가 초기화되는 시간은 매일 오전 9시 입니다.</li>
          <li>API 호출은 초당 3회의 제한을 가집니다.</li>
          <li>작업 API의 경우 쇼핑몰과의 연동이슈로 초당 1회의 호출만 가능합니다.</li>
        </ul>

        <br /><br />
        <h4>API 호출제한 적용 알고리즘</h4>
        PLAYAUTO 2.0 API 는 Token Bucket 알고리즘을 사용합니다.<br /><br />
        <ul class='style'>
          <li>버킷에는 최대 3개까지의 토큰이 저장되며 토큰은 1초에 3개씩 생성됩니다.</li>
          <li>API 호출시 토큰이 남아있는경우 정상적 호출되고 토큰이 없는경우 429(Too Many Request) 에러가 발생됩니다.</li>
        </ul>

        <img src={tokenBucket} alt='token bucket' /><br /><br />

        호출 예시<br /><br />
        <ul>
          <li>1초에 3번씩 API 호출 : 정상</li>
          <li>한번에 3번의 API 동시호출 : 정상</li>
          <li>1초에 5번 호출: 3번까지는 정상, 그 이후는 429 에러</li>
        </ul>
        <br /><br />

      </Container>
    </>
  );
};

const Container = styled.div`
  
  width: 100%;
  padding: 110px 100px;
    
  > h2 {
    font-size: 36px;
    font-weight: bold;
  }

  > img {
    display: block;
    width: 749px;
  }
`;

export default LimitPage;