import { default_header, default_header_example } from './defaultData';

const data = (userInfo) => ({
  title: '메모',
  unrecognized_aff_no: [1051],
  children: [
    {
      title: '메모 등록',
      url: '/doc/메모 등록',
      data: [
        {
          version: 'v1.0',
          url: '/memo',
          method: 'POST',
          desc: '주문, 상품, 재고등에 기록되는 메모를 등록합니다.',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'memo_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  메모유형
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>ord</code>
                          </strong>
                        </td>
                        <td>
                          <strong>주문</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>ol_shop</code>
                          </strong>
                        </td>
                        <td>
                          <strong>온라인상품</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>prod</code>
                          </strong>
                        </td>
                        <td>
                          <strong>재고</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'content',
              required: 'O',
              type: 'String',
              desc: '메모내용',
            },
            {
              title: 'uniq',
              required: '',
              type: 'String',
              desc: (
                <div>
                  주문의 고유번호
                  <div className='description'>(주문메모 등록시 필수)</div>
                </div>
              ),
            },
            {
              title: 'ol_shop_no',
              required: '',
              type: 'String',
              desc: (
                <div>
                  온라인상품 고유값
                  <div className='description'>
                    (온라인상품 메모 등록시 필수)
                  </div>
                </div>
              ),
            },
            {
              title: 'prod_no',
              required: '',
              type: 'String',
              desc: (
                <div>
                  SKU상품 고유값
                  <div className='description'>(SKU상품 메모 등록시 필수)</div>
                </div>
              ),
            },
          ],
          body_example: {
            memo_type: 'ord',
            uniq: '3794448715708789453',
            content: '고객님께서 빠른 배송을 원하십니다.',
          },
          response: [
            {
              title: 'result',
              required: 'O',
              type: 'Object',
              desc: '',
            },
            {
              title: 'status',
              required: 'O',
              type: 'String',
              desc: '결과',
              indent: 1,
            },
            {
              title: 'memo_no',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  등록된 메모번호
                  <div className='description'>(메모 고유값)</div>
                </div>
              ),
              indent: 1,
            },
          ],
          response_example: {
            result: {
              status: 'success',
              memo_no: 111111,
            },
          },
        },
      ],
    },
    {
      title: '메모 삭제',
      url: '/doc/메모 삭제',
      data: [
        {
          version: 'v1.0',
          url: '/memo',
          method: 'DELETE',
          desc: '주문, 상품, 재고등에 기록된 메모정보를 삭제합니다.',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'memo_no',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  메모번호
                  <div className='description'>(메모고유값)</div>
                </div>
              ),
            },
          ],
          body_example: {
            memo_no: 5555555,
          },
          response: [
            {
              title: 'result',
              required: 'O',
              type: 'Object',
              desc: '',
            },
            {
              title: 'inq_iniq',
              required: 'O',
              type: 'String',
              desc: '문의 고유번호',
              indent: 1,
            },
          ],
          response_example: {
            result: {
              status: 'success',
            },
          },
        },
      ],
    },
    {
      title: '메모 수정',
      url: '/doc/메모 수정',
      data: [
        {
          version: 'v1.0',
          url: '/memo',
          method: 'PUT',
          desc: '주문, 상품, 재고등에 기록된 메모정보를 수정합니다.',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'memo_no',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  메모번호
                  <div className='description'>(메모고유값)</div>
                </div>
              ),
            },
            {
              title: 'content',
              required: 'O',
              type: 'String',
              desc: '메모내용',
            },
            {
              title: 'memo_status',
              required: '',
              type: 'String',
              desc: (
                <div>
                  메모상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>                  
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>등록</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>완료</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
          ],
          body_example: {
            memo_no: 5555555,
            content: '김바보 고객님께서 총알 배송을 원하십니다.',
          },
          response: [
            {
              title: 'result',
              required: 'O',
              type: 'Object',
              desc: '',
            },
            {
              title: 'inq_uniq',
              required: 'O',
              type: 'String',
              desc: '문의 고유번호',
              indent: 1,
            },
          ],
          response_example: {
            result: {
              status: 'success',
            },
          },
        },
      ],
    },
  ],
});

export default data;
