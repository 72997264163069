import { default_header, default_header_example } from './defaultData';

const data = (userInfo) => ({
  title: '기타',
  children: [
    {
      title: 'Sub 계정 리스트 조회',
      url: '/doc/Sub 계정 리스트 조회',
      data: [
        {
          version: 'v1.0',
          url: '/sub-infos',
          method: 'POST',
          authorized_m_no: [91809, 91810, 91812, 223054, 88912, 88913, 88916, 64370, 77327],
          unrecognized_aff_no: [1051, 1057],
          desc: '해당 계정의 모든 하위 계정을 조회합니다.',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'sub_sol_no_list',
              required: '',
              type: 'Number[]',
              desc: (
                <div>
                  하위계정 sol_no 리스트
                  <div className='description'>
                    (값이 없는 경우 하위계정 전체 조회)
                  </div>
                </div>
              ),
            },
            {
              title: 'biz_reg_no_list',
              required: '',
              type: 'String[]',
              desc: '사업자 등록번호 리스트',
            },
            {
              title: 'masking_yn',
              required: '',
              type: 'Boolean',
              desc: (
                <div>
                  개인정보 마스킹 처리 여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
          ],
          body_example: {
            sub_sol_no_list: [9999990, 9999991],
          },
          response: [
            {
              title: 'results',
              required: 'O',
              type: 'Object[]',
              desc: '하위계정정보',
            },
            {
              title: 'pa_sol_no',
              required: 'O',
              type: 'Number(8)',
              desc: '상위계정 솔루션번호',
              indent: 1,
            },
            {
              title: 'sol_no',
              required: 'O',
              type: 'Number(8)',
              desc: '솔루션번호',
              indent: 1,
            },
            {
              title: 'c_no',
              required: 'O',
              type: 'Number(8)',
              desc: '회사고유번호',
              indent: 1,
            },
            {
              title: 'company_name',
              required: 'O',
              type: 'String(60)',
              desc: '회사명',
              indent: 1,
            },
            {
              title: 'ceo_name',
              required: 'O',
              type: 'String(60)',
              desc: '대표자명',
              indent: 1,
            },
            {
              title: 'biz_reg_no',
              required: 'O',
              type: 'String(30)',
              desc: '사업자등록번호',
              indent: 1,
            },
            {
              title: 'c_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  사업장유형
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>개인사업자(간이과세자)</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>개인사업자(일반과세자)</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>법인사업자</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>면세사업자</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>해외사업자</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>개인판매자</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>개인사업자</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'uptae',
              required: 'O',
              type: 'String(30)',
              desc: '업태',
              indent: 1,
            },
            {
              title: 'upjong',
              required: 'O',
              type: 'String(30)',
              desc: '업종',
              indent: 1,
            },
            {
              title: 'c_tel',
              required: 'O',
              type: 'String(20)',
              desc: '회사전화번호',
              indent: 1,
            },
            {
              title: 'c_fax',
              required: 'O',
              type: 'String(20)',
              desc: '회사팩스번호',
              indent: 1,
            },
            {
              title: 'zipcd',
              required: 'O',
              type: 'String(10)',
              desc: '회사 우편번호',
              indent: 1,
            },
            {
              title: 'addr1',
              required: 'O',
              type: 'String(255)',
              desc: '회사 주소1',
              indent: 1,
            },
            {
              title: 'addr2',
              required: 'O',
              type: 'String(255)',
              desc: '회사 주소2',
              indent: 1,
            },
            {
              title: 'm_no',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  회원번호
                  <div className='description'>(마스터계정이 조회됩니다.)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'name',
              required: 'O',
              type: 'String(60)',
              desc: '회원명',
              indent: 1,
            },
            {
              title: 'email',
              required: 'O',
              type: 'String(60)',
              desc: '회원 이메일',
              indent: 1,
            },
            {
              title: 'htel',
              required: 'O',
              type: 'String(20)',
              desc: '회원 핸드폰번호',
              indent: 1,
            },
            {
              title: 'shop_list',
              required: 'O',
              type: 'Object[]',
              desc: '쇼핑몰리스트',
              indent: 1,
            },
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String(4)',
              desc: '쇼핑몰 코드',
              indent: 2,
            },
            {
              title: 'shop_id',
              required: 'O',
              type: 'String(40)',
              desc: '쇼핑몰 아이디',
              indent: 2,
            },
            {
              title: 'shop_name',
              required: 'O',
              type: 'String(50)',
              desc: '쇼핑몰 이름',
              indent: 2,
            },
          ],
          response_example: [
            {
              pa_sol_no: 99999999,
              sol_no: 1,
              c_no: 1,
              company_name: '회사명샘플',
              ceo_name: '김플토',
              biz_reg_no: '10123456789',
              c_type: '개인사업자(간이과세자)',
              uptae: '업태샘플',
              upjong: '업종샘플',
              c_tel: '01000000001',
              c_fax: null,
              zipcd: '12345',
              addr1: '서울시 구로구 디지털로',
              addr2: null,
              m_no: 1,
              name: '최플토',
              email: 'testID0001@test.com',
              htel: '01011110000',
              shop: [
                {
                  shop_cd: 'A001',
                  shop_id: 'testID01',
                  shop_name: '옥션',
                },
                {
                  shop_cd: 'A006',
                  shop_id: 'testID02',
                  shop_name: '지마켓',
                },
                {
                  shop_cd: 'A006',
                  shop_id: 'testID03',
                  shop_name: '지마켓',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: '메이크샵 전용 온라인상품 등록',
      url: '/doc/메이크샵 전용 온라인상품 등록',
      data: [
        {
          version: 'v1.0',
          url: '/products/addMakeShop',
          authorized_m_no: [6234, 6893],
          unrecognized_aff_no: [1051, 1057],
          method: 'POST',
          desc: (
            <ul className='style'>
              <li>온라인상품 정보를 등록합니다.</li>
              <li>
                단일상품의 경우 쇼핑몰별로 입력가능한 옵션이 다를 수 있습니다.
              </li>
              <li>
                해당경우 입력가능한 옵션정보가 같은 상품정보끼리 묶어
                등록해주시기 바랍니다.
              </li>
              <br />
              <li>옵션등록 방법</li>
              <pre className='prettyprint'>
                ex1 &gt; 조합형 옵션이고 옵션명이 색상, 옵션값이 빨강,
                파랑인경우 <br /> 색상-빨강, 색상-파랑
                <br /> 이런 형식으로 두개의 옵션정보가 생성되므로
                <br /> {'{'}
                <br /> opt_sort1: 색상,
                <br /> opt_sort1_desc: 빨강,
                <br /> ...
                <br /> {'}'},<br /> {'{'}
                <br /> opt_sort1: 색상,
                <br /> opt_sort1_desc: 파랑,
                <br /> ...
                <br /> {'}'}
                <br /> 의 형식으로 입력하면 됩니다.
                <br /> <br /> ex2 &gt; 조합형 옵션이고 옵션정보가 [색상 =
                빨강,파랑, 사이즈 = Large, Small] 인경우
                <br /> 빨강-Large, 파랑-Large, 빨강-Small, 파랑-Small
                <br /> 이런 형식으로 총 4개의 옵션정보가 생성되어야 합니다.
                <br /> {'{'}
                <br /> opt_sort1: 색상,
                <br /> opt_sort2: 사이즈,
                <br /> opt_sort1_desc: 빨강,
                <br /> opt_sort2_desc: Large,
                <br /> ...
                <br /> {'}'},<br /> {'{'}
                <br /> opt_sort1: 색상,
                <br /> opt_sort2: 사이즈,
                <br /> opt_sort1_desc: 빨강,
                <br /> opt_sort2_desc: Small
                <br /> ...
                <br /> {'}'},<br /> {'{'}
                <br /> opt_sort1: 색상,
                <br /> opt_sort2: 사이즈,
                <br /> opt_sort1_desc: 파랑,
                <br /> opt_sort2_desc: Large
                <br /> ...
                <br /> {'}'},<br /> {'{'}
                <br /> opt_sort1: 색상,
                <br /> opt_sort2: 사이즈,
                <br /> opt_sort1_desc: 파랑,
                <br /> opt_sort2_desc: Small
                <br /> ...
                <br /> {'}'}
                <br /> <br /> ex3&gt; 독립형 옵션인경우
                <br /> 독립형옵션인경우에는 opt_sort1 에 옵션명, opt_sort1_desc
                에 옵션값만 넣어주면 됩니다.
                <br /> 옵션명: 색상, 사이즈 <br /> 옵션값: Large, Small <br />
                위의 정보를 독립형 옵션으로 생성하는 경우
                <br /> {'{'}
                <br /> opt_sort1: 색상,
                <br /> opt_sort1_desc: 빨강,
                <br /> ...
                <br /> {'}'},<br /> {'{'}
                <br /> opt_sort1: 색상,
                <br /> opt_sort1_desc: 파랑,
                <br /> ...
                <br /> {'}'},<br /> {'{'}
                <br /> opt_sort1: 사이즈,
                <br /> opt_sort1_desc: Large,
                <br /> ...
                <br /> {'}'},<br /> {'{'}
                <br /> opt_sort1: 사이즈,
                <br /> opt_sort1_desc: Large,
                <br /> ...
                <br /> {'}'}
                <br /> 위의 방식으로 입력해주시면 됩니다.
                <br /> 이 방식은 추가구매 옵션등록시에도 동일합니다.
              </pre>
            </ul>
          ),
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'c_sale_cd',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  판매자관리코드
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      <strong>
                        <code>__AUTO__</code>
                      </strong>
                      로 입력시 판매자관리코드를 자동생성합니다.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'sol_cate_no',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  카테고리코드
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      카테고리 조회 api 를 통해 확인된 카테고리 번호를
                      입력해주십시오.
                      <br />
                      (site_list.cateData 있을경우 비필수)
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'shop_sale_name',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품명
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>상품명은 최대 100Byte까지 등록가능합니다.</li>
                    <li>
                      특수문자는 - _ / ~ + . , ( ) {} [ ] % & '만 사용
                      가능합니다.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'minor_yn',
              required: '',
              type: 'Boolean',
              desc: (
                <div>
                  미성년자 구매여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>true</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sale_price',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  판매가격
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>금액은 10원 단위로 입력해주십시오.</li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'sale_cnt_limit',
              required: 'O',
              type: 'Number',
              desc: '판매수량',
            },
            {
              title: 'shop_sale_no',
              required: 'O',
              type: 'Number',
              desc: '쇼핑몰 상품코드',
            },
            {
              title: 'sale_status',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  쇼핑몰 상태
                  <div className='description'>
                    ('판매대기','승인대기','반려','판매중','종료대기','수정대기','판매중지','일시품절')
                  </div>
                </div>
              ),
            },
            {
              title: 'site_list',
              required: 'O',
              type: 'Object[]',
              desc: '등록할 쇼핑몰 정보',
            },
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  쇼핑몰 코드
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      쇼핑몰코드조회 문서를 참고하여 등록하시려는 사이트의
                      코드를 입력해주십시오.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_id',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 아이디',
              indent: 1,
            },
            {
              title: 'template_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  템플릿 번호
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      템플릿 조회 api 를 통해 확인된 템플릿 번호를
                      입력해주십시오.
                    </li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_sale_name_addcontent_no',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  상품명 머리말/꼬리말 템플릿 번호
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      머리말/꼬리말 조회 api 를 통해 확인된 상품명 머리말/꼬리말
                      템플릿 번호를 입력해주십시오.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'addcontent_no',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  상세설명 머리말/꼬리말 템플릿 번호
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      머리말/꼬리말 조회 api 를 통해 확인된 상세설명
                      머리말/꼬리말 템플릿 번호를 입력해주십시오.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cateData',
              required: 'O',
              type: 'Object',
              desc: (
                <div>
                  카테고리 데이터
                  <div className='description'>
                    (sol_cate_no 있을경우 비 필수)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_cate_name',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  카테고리 이름 (' {'>'} ' 로 구분)
                  <div className='description'>
                    ex) "의류 {'>'} 티셔츠 {'>'} 라운드"
                  </div>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'shop_cate_cd',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  카테고리 코드 ('_' 로 구분)
                  <div className='description'>ex) "001_001001_001001001"</div>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'std_ol_yn',
              required: '',
              type: 'String',
              desc: (
                <div>
                  단일상품여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>Y</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>N</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'opt_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션구분
                  <br />
                  <br />
                  <div className='description'>
                    <strong>홈플러스, 갤러리아몰, 롯데ON</strong>
                    <ul className='style'>
                      <li>
                        마스터 상품에 옵션 일괄 설정 시 대표 SKU상품만 매칭된
                        ‘옵션없음’ 상품으로 등록됩니다.
                      </li>
                      <li>
                        카테고리에 따라 옵션 설정이 필요한 상품은 온라인 상품
                        생성 후 상세에서 별도로 옵션을 생성해 주셔야 합니다.
                      </li>
                    </ul>
                  </div>
                  <div className='description'>
                    <strong>쿠팡</strong>
                    <ul className='style'>
                      <li>
                        쿠팡은 독립형 옵션 미지원으로 마스터 상품의 옵션을
                        독립형으로 설정하신 경우 대표 SKU상품만 매칭된
                        ‘옵션없음’ 상품으로 등록됩니다.
                      </li>
                    </ul>
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>옵션없음</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>조합형</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>독립형</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'opts',
              required: '',
              type: 'Object[]',
              desc: (
                <div>
                  옵션
                  <div className='description'>
                    (옵션구분이 옵션없음이 아닌경우 필수입력.)
                  </div>
                </div>
              ),
            },
            {
              title: 'opt_sort1',
              required: 'O',
              type: 'String',
              desc: '옵션명1',
              indent: 1,
            },
            {
              title: 'opt_sort2',
              required: 'O',
              type: 'String',
              desc: '옵션명2',
              indent: 1,
            },
            {
              title: 'opt_sort3',
              required: 'O',
              type: 'String',
              desc: '옵션명3',
              indent: 1,
            },
            {
              title: 'opt_sort1_desc',
              required: 'O',
              type: 'String',
              desc: '옵션값1',
              indent: 1,
            },
            {
              title: 'opt_sort2_desc',
              required: 'O',
              type: 'String',
              desc: '옵션값2',
              indent: 1,
            },
            {
              title: 'opt_sort3_desc',
              required: 'O',
              type: 'String',
              desc: '옵션값3',
              indent: 1,
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String',
              desc: 'SKU코드',
              indent: 1,
            },
            {
              title: 'pack_unit',
              required: 'O',
              type: 'Number',
              desc: '출고수량',
              indent: 1,
            },
            {
              title: 'add_price',
              required: 'O',
              type: 'Number',
              desc: '추가옵션금액',
              indent: 1,
            },
            {
              title: 'stock_cnt',
              required: 'O',
              type: 'Number',
              desc: '판매수량',
              indent: 1,
            },
            {
              title: 'weight',
              required: 'O',
              type: 'Number',
              desc: '옵션무게',
              indent: 1,
            },
            {
              title: 'status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'input_opt_name',
              required: '',
              type: 'Array',
              desc: '입력형옵션',
            },
            {
              title: 'add_opts',
              required: '',
              type: 'Object[]',
              desc: '추가구매옵션',
            },
            {
              title: 'opt_sort',
              required: 'O',
              type: 'String',
              desc: '추가항목명',
              indent: 1,
            },
            {
              title: 'opt_sort_desc',
              required: 'O',
              type: 'String',
              desc: '추가옵션명',
              indent: 1,
            },
            {
              title: 'price',
              required: 'O',
              type: 'Number',
              desc: '추가금액',
              indent: 1,
            },
            {
              title: 'stock_cnt',
              required: 'O',
              type: 'Number',
              desc: '판매수량',
              indent: 1,
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String',
              desc: 'SKU코드',
              indent: 1,
            },
            {
              title: 'pack_unit',
              required: 'O',
              type: 'Number',
              desc: '출고수량',
              indent: 1,
            },
            {
              title: 'weight',
              required: 'O',
              type: 'Number',
              desc: '추가구매옵션 무게',
              indent: 1,
            },
            {
              title: 'status',
              required: 'O',
              type: 'String',
              desc: '추가구매옵션 상태',
              indent: 1,
            },
            {
              title: 'tax_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  과세여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>과세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>면세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>영세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>비과세</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'madein',
              required: '',
              type: 'Object',
              desc: '원산지',
            },
            {
              title: 'madin_no',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  원산지번호
                  <div className='description'>
                    (원산지 조회 api 호출하여 확인)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'madein_etc',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  기타원산지
                  <div className='description'>
                    (등록하려는 원산지가 origins 에서 조회되지않을때 made_no 를
                    비우고 madein_etc 에 값을 입력합니다.)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'madein_yn',
              required: 'O',
              type: 'Boolean',
              desc: (
                <div>
                  복수원산지여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'made_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  제조일자
                  <div className='description'>
                    (YYYY-MM-DD 의 형식으로 입력해 주십시오.)
                  </div>
                </div>
              ),
            },
            {
              title: 'expire_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  유효일자
                  <div className='description'>
                    (YYYY-MM-DD 의 형식으로 입력해 주십시오.)
                  </div>
                </div>
              ),
            },
            {
              title: 'detail_desc',
              required: 'O',
              type: 'String',
              desc: '상품상세설명',
            },
            {
              title: 'brand',
              required: '',
              type: 'String',
              desc: '브랜드',
            },
            {
              title: 'model',
              required: '',
              type: 'String',
              desc: '모델명',
            },
            {
              title: 'maker',
              required: '',
              type: 'String',
              desc: '제조사',
            },
            {
              title: 'keyword',
              required: '',
              type: 'String[]',
              desc: (
                <div>
                  키워드
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      키워드는 최대 40개 까지 등록가능하며, 쇼핑몰에 상품전송시
                      쇼핑몰에서 지원하는 입력 갯수만큼만 전송됩니다.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'sale_img1',
              required: 'O',
              type: 'String',
              desc: '기본이미지 URL',
            },
            {
              title: 'sale_img2',
              required: '',
              type: 'String',
              desc: '추가이미지 1 URL',
            },
            {
              title: 'sale_img3',
              required: '',
              type: 'String',
              desc: '추가이미지 2 URL',
            },
            {
              title: 'sale_img4',
              required: '',
              type: 'String',
              desc: '추가이미지 3 URL',
            },
            {
              title: 'sale_img5',
              required: '',
              type: 'String',
              desc: '추가이미지 4 URL',
            },
            {
              title: 'sale_img6',
              required: '',
              type: 'String',
              desc: '추가이미지 5 URL',
            },
            {
              title: 'sale_img7',
              required: '',
              type: 'String',
              desc: '추가이미지 6 URL',
            },
            {
              title: 'sale_img8',
              required: '',
              type: 'String',
              desc: '추가이미지 7 URL',
            },
            {
              title: 'sale_img9',
              required: '',
              type: 'String',
              desc: '추가이미지 8 URL',
            },
            {
              title: 'sale_img10',
              required: '',
              type: 'String',
              desc: '추가이미지 9 URL',
            },
            {
              title: 'sale_img11',
              required: '',
              type: 'String',
              desc: '추가이미지 10 URL',
            },
            {
              title: 'prod_info',
              required: 'O',
              type: 'Object[]',
              desc: (
                <div>
                  품목정보고시
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>품목정보고시정보는 3개까지 입력가능합니다.</li>
                    <li>아래의 등록가이드를 참고해주세요.</li>
                    <li>
                      https://gmp-master.s3.ap-northeast-2.amazonaws.com/download/Sample_prodInfo_guide.xlsx
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'code',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  품목정보고시
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      대표 SKU상품 정보를 사용하고 싶으신 경우 '대표
                      SKU상품'으로 입력합니다.
                    </li>
                    <li>
                      대표 SKU상품으로 입력시 아래의 정보는 입력할 필요가
                      없습니다.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_1',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시 1',
              indent: 1,
            },
            {
              title: 'prod_info_2',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시 2',
              indent: 1,
            },
            {
              title: 'prod_info_3',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시 3',
              indent: 1,
            },
            {
              title: 'prod_info_4',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시 4',
              indent: 1,
            },
            {
              title: 'prod_info_5',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시 5',
              indent: 1,
            },
            {
              title: 'prod_info_6',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시 6',
              indent: 1,
            },
            {
              title: 'prod_info_7',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시 7',
              indent: 1,
            },
            {
              title: 'prod_info_8',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시 8',
              indent: 1,
            },
            {
              title: 'prod_info_9',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시 9',
              indent: 1,
            },
            {
              title: 'prod_info_10',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시 10
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_11',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시 11
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_12',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시 12
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_13',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시 13
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_14',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시 14
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_15',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시 15
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_16',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시 16
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_17',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시 17
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_18',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시 18
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_19',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시 19
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_20',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시 20
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_21',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시 21
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_22',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시 22
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_23',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시 23
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_24',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시 24
                  <div className='description'>
                    (해당되는 값이 있는경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ship_price_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  배송방법
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>무료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>선결제</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>착불</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'ship_price',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  배송비
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>배송방법이 무료가 아닌경우 필수입니다.</li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'gift_name',
              required: '',
              type: 'String',
              desc: '사은품',
            },
            {
              title: 'supply_price',
              required: '',
              type: 'Number',
              desc: '공급가',
            },
            {
              title: 'cost_price',
              required: '',
              type: 'Number',
              desc: '원가',
            },
            {
              title: 'street_price',
              required: '',
              type: 'Number',
              desc: '시중가',
            },
            {
              title: 'global_barcode_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  UPC/EAN 타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowd values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>UPC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EAN</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'global_barcode',
              required: '',
              type: 'Number',
              desc: 'UPC/EAN 코드',
            },
            {
              title: 'isbn',
              required: '',
              type: 'Number',
              desc: 'ISBN 코드',
            },
            {
              title: 'barcode',
              required: '',
              type: 'String',
              desc: '바코드',
            },
            {
              title: 'hscd',
              required: '',
              type: 'String',
              desc: 'HS 코드',
            },
            {
              title: 'prod_weight',
              required: '',
              type: 'Number',
              desc: '상품무게',
            },
            {
              title: 'cert_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  인증정보타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>인증대상아님</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>인증유형등록</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>인증대상아님</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>상세설명내등록</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'certs',
              required: '',
              type: 'Array',
              desc: (
                <div>
                  인증정보
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>인증정보는 10개까지 등록 가능합니다.</li>
                    <li>
                      인증정보타입이 인증유형 등록인경우 필수로
                      입력해주셔야합니다.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'cert_id',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  인증유형
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>cert_01: 생활용품 안전인증</li>
                    <li>cert_02: 생활용품 안전확인</li>
                    <li>cert_03: 생활용품 어린이보호포장</li>
                    <li>cert_04: 생활용품 안전품질표시</li>
                    <li>cert_05: 생활용품 공급자적합성확인</li>
                    <li>cert_06: 전기용품 안전인증</li>
                    <li>cert_07: 전기용품 안전확인</li>
                    <li>cert_08: 전기용품 공급자적합성확인</li>
                    <li>cert_09: 방송통신용품 적합성인증확인</li>
                    <li>cert_10: 어린이제품 안전인증</li>
                    <li>cert_11: 어린이제품 안전확인</li>
                    <li>cert_12: 어린이제품 공급자적합성확인</li>
                    <li>cert_13: 위해우려제품 자가검사</li>
                    <li>cert_14: 방송통신기자재 잠정인증</li>
                    <li>cert_15: 방송통신기자재 적합등록</li>
                    <li>cert_16: 원산지증명</li>
                    <li>cert_17: 해외안전인증</li>
                    <li>cert_18: 수입신고필증</li>
                    <li>cert_19: 친환경인증-무농약농산물</li>
                    <li>cert_20: 친환경인증-유기축산물</li>
                    <li>cert_21: 친환경인증-유기농산물</li>
                    <li>cert_22: 친환경인증-저농약농산물</li>
                    <li>cert_23: 친환경인증-무항생제축산물</li>
                    <li>cert_24: HACCP(위해요소 중점관리우수식품)</li>
                    <li>cert_25: GMP(우수건강기능식품 제조기준)</li>
                    <li>cert_26: GAP(농산물우수관리인증)</li>
                    <li>cert_27: 농산물이력추적관리</li>
                    <li>cert_28: 수산물 품질인증</li>
                    <li>cert_29: 수산특산물 품질인증</li>
                    <li>cert_30: 수산전통식품 품질인증</li>
                    <li>cert_31: 친환경수산물 품질인증</li>
                    <li>cert_32: 친환경수산가공품 품질인증</li>
                    <li>cert_33: 전통식품 품질인증</li>
                    <li>cert_34: 유기가공식품 인증</li>
                    <li>cert_35: 가공식품 산업표준 KS인증</li>
                    <li>cert_36: 건강기능식품 광고사전심의</li>
                    <li>cert_37: 의료기기 광고사전심의</li>
                    <li>cert_38: 축산물 등급판정 확인서</li>
                    <li>cert_39: 도축검사증명서</li>
                    <li>cert_40: 식품명인(Grand master) 인증</li>
                    <li>cert_41: 사회적기업 인증</li>
                    <li>cert_42: 6차산업 인증</li>
                    <li>cert_43: 동물복지 인증</li>
                    <li>cert_44: 마을기업 인증</li>
                    <li>cert_45: 건강기능식품인증</li>
                    <li>cert_46: 생활화학제품</li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cert_exc_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  면제유형
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>kc_01: 안전기준준수대상</li>
                    <li>kc_02: 구매대행</li>
                    <li>kc_03: 병행수입</li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_01</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_02</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_03</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cert_ministry',
              required: 'O',
              type: 'String',
              desc: '인증기관',
              indent: 2,
            },
            {
              title: 'cert_no',
              required: 'O',
              type: 'String',
              desc: '인증번호',
              indent: 2,
            },
            {
              title: 'cert_model',
              required: 'O',
              type: 'String',
              desc: '인증모델',
              indent: 2,
            },
            {
              title: 'cert_cname',
              required: 'O',
              type: 'String',
              desc: '인증상호',
              indent: 2,
            },
            {
              title: 'cert_date',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  인증일
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>YYYY-MM-DD의 날짜형식으로 입력해주십시오.</li>
                  </ul>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'cert_expire_date',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  인증만료일
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>YYYY-MM-DD의 날짜형식으로 입력해주십시오.</li>
                  </ul>
                </div>
              ),
              indent: 2,
            },
          ],
          body_example: {
            c_sale_cd: '__AUTO__',
            shop_sale_name: 'api 테스트 상품',
            minor_yn: true,
            sale_price: 50000,
            sale_cnt_limit: 88,
            shop_sale_no: '3530328',
            sale_status: '판매중',
            site_list: [
              {
                shop_cd: 'B118',
                shop_id: 'plto',
                template_no: 260727,
                cateData: {
                  shop_cate_name: '의류 > 티셔츠 > 라운드',
                  shop_cate_cd: '001_001001_001001001',
                },
              },
            ],
            std_ol_yn: 'N',
            opt_type: '독립형',
            opts: [
              {
                opt_sort1: '색상',
                opt_sort2: '',
                opt_sort3: '',
                opt_sort1_desc: '빨강',
                opt_sort2_desc: '',
                opt_sort3_desc: '',
                sku_cd: '',
                pack_unit: 1,
                add_price: 0,
                stock_cnt: 99,
                weight: 0.5,
                status: '정상',
              },
              {
                opt_sort1: '색상',
                opt_sort2: '',
                opt_sort3: '',
                opt_sort1_desc: '파랑',
                opt_sort2_desc: '',
                opt_sort3_desc: '',
                sku_cd: '',
                pack_unit: 1,
                add_price: 5000,
                stock_cnt: 88,
                weight: '1',
                status: '품절',
              },
            ],
            input_opt_name: ['입력형옵션1', '입력형옵션2'],
            add_opts: [
              {
                opt_sort: '추가구매타입',
                opt_sort_desc: '추가구매1',
                price: 1000,
                stock_cnt: 20,
                sku_cd: '',
                pack_unit: 2,
                weight: 0,
                status: '정상',
              },
              {
                opt_sort: '추가구매타입',
                opt_sort_desc: '추가구매2',
                price: 2000,
                stock_cnt: 30,
                sku_cd: '',
                pack_unit: 1,
                weight: 1,
                status: '품절',
              },
            ],
            tax_type: '과세',
            madein: {
              madein_no: 2,
              madein_etc: '',
              multi_yn: false,
            },
            made_date: '2020-01-01',
            expire_date: '2021-03-01',
            detail_desc: '상세설명',
            brand: '브랜드',
            model: '모델명',
            maker: '제조사',
            sale_img1: 'https://cloud.plto.com/TestImg/TestProd.jpg',
            prod_info: [
              {
                code: '01',
                prod_info_1: '면-95',
                prod_info_2: 'N',
                prod_info_3: '빨강,파랑',
                prod_info_4: '85,90',
                prod_info_5: '플레이오토',
                prod_info_6: 'Y',
                prod_info_7: '플레이오토',
                prod_info_8: '중국',
                prod_info_9: '드라이크리닝해주세요',
                prod_info_10: '화기에 주의하세요',
                prod_info_11: '2016-09',
                prod_info_12: '구입일로부터 1년',
                prod_info_13: '플레이오토/070-123-4567',
                prod_info_14: '',
                prod_info_15: '',
                prod_info_16: '',
                prod_info_17: '',
                prod_info_18: '',
                prod_info_19: '',
                prod_info_20: '',
                prod_info_21: '',
                prod_info_22: '',
                prod_info_23: '',
                prod_info_24: '',
              },
              {
                code: '12',
                prod_info_1: '상세설명 참조',
                prod_info_2: '상세설명 참조',
                prod_info_3: '상세설명 참조',
                prod_info_4: '상세설명 참조',
                prod_info_5: '상세설명 참조',
                prod_info_6: '상세설명 참조',
                prod_info_7: '상세설명 참조',
                prod_info_8: '상세설명 참조',
                prod_info_9: '상세설명 참조',
                prod_info_10: '상세설명 참조',
                prod_info_11: '상세설명 참조',
                prod_info_12: '상세설명 참조',
                prod_info_13: '상세설명 참조',
              },
            ],
          },
          response: [
            {
              title: 'result',
              required: 'O',
              type: 'String',
              desc: '성공/실패',
            },
            {
              title: 'c_sale_cd',
              required: 'O',
              type: 'String',
              desc: '등록된 상품의 판매자관리코드',
            },
            {
              title: 'message',
              required: 'O',
              type: 'String',
              desc: '등록이 실패된경우 에러 메세지',
            },
          ],
          response_example: {
            result: '성공',
            c_sale_cd: 'm20200925497324',
            message: '',
          },
        },
      ],
    },
    {
      title: '온라인상품 등록 (WAKE-UP)',
      url: '/doc/온라인상품 등록WAKE-UP',
      data: [
        {
          version: 'v1.0',
          url: '/wakeUp/ProductAdd',
          authorized_m_no: [1, 1957, 5422],
          unrecognized_aff_no: [1051, 1057],
          method: 'POST',
          desc: (
            <ul className='style'>
              <li>PLAYAUTO 2.0솔루션에 wake-up 상품을 등록합니다.</li>
              <li>WAKE-UP 커스텀 사용자만 호출 가능합니다.</li>
            </ul>
          ),
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'sol_no',
              required: 'O',
              type: 'Number',
              desc: '솔루션번호',
            },
            {
              title: 'dProdData',
              required: 'O',
              type: 'Object',
              desc: '상품데이터',
            },
            {
              title: 'infoData',
              required: 'O',
              type: 'Object',
              desc: '쇼핑몰정보',
            },
          ],
          body_example: {
            sol_no: 1234,
            dProdData: 'dProdData 변수',
            infoData: 'infoData 변수',
          },
          response: [
            {
              title: 'result',
              required: 'O',
              type: 'String',
              desc: '결과',
            },
          ],
          response_example: {
            result: 'success',
          },
        },
      ],
    },
    {
      title: '온라인상품 조회 (WAKE-UP)',
      url: '/doc/온라인상품 조회WAKE-UP',
      data: [
        {
          version: 'v1.0',
          url: '/wakeUp/ProductList',
          desc: (
            <ul className='style'>
              <li>
                PLAYAUTO 2.0 솔루션에 등록되어있는 쇼핑몰 상품코드를 조회합니다.
              </li>
              <li>WAKE-UP 커스텀 사용자만 호출 가능합니다.</li>
            </ul>
          ),
          authorized_m_no: [1, 1957, 5422],
          unrecognized_aff_no: [1051, 1057],
          method: 'POST',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 코드',
            },
            {
              title: 'shop_id',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 아이디',
            },
            {
              title: 'shop_sale_nos',
              required: 'O',
              type: 'String[]',
              desc: '쇼핑몰 상품코드',
            },
            {
              title: 'sol_no',
              required: '',
              type: 'Number',
              desc: '조회할 상품의 솔루션 번호',
            },
          ],
          body_example: {
            shop_cd: 'A001',
            shop_id: 'auction',
            shop_sale_nos: ['1111', '22222', '33333', '4444', '5555'],
            sol_no: 1,
          },
          response: [
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String',
              desc: '조회된 쇼핑몰 코드',
            },
            {
              title: 'shop_id',
              required: 'O',
              type: 'String',
              desc: '조회된 쇼핑몰 아이디',
            },
            {
              title: 'prods',
              required: 'O',
              type: 'Object',
              desc: '조회된 상품 정보',
            },
            {
              title: 'shop_sale_no',
              required: 'O',
              type: 'String',
              desc: '조회된 쇼핑몰 상품코드',
              indent: 1,
            },
            {
              title: 'c_sale_cd',
              required: 'O',
              type: 'String',
              desc: '조회된 판매자관리코드',
              indent: 1,
            },
          ],
          response_example: {
            shop_cd: 'A001',
            shop_id: 'auction',
            prods: [
              {
                shop_sale_no: '22222',
                c_sale_cd: 'm1112344455'
              },{
                shop_sale_no: '5555',
                c_sale_cd: 'm1112344500'
              }
            ],
          },
        },
      ],
    },
    {
      title: '회원 조회 (WAKE-UP)',
      url: '/doc/회원 조회WAKE-UP',
      data: [
        {
          version: 'v1.0',
          url: '/wakeUp/member/:email',
          desc: (
            <ul className='style'>
              <li>
                PLAYAUTO 2.0 솔루션에 등록되어있는 회원 정보를 조회합니다.
              </li>
              <li>WAKE-UP 커스텀 사용자만 호출 가능합니다.</li>
            </ul>
          ),
          authorized_m_no: [1, 1957, 5422],
          unrecognized_aff_no: [1051, 1057],
          method: 'GET',
          header: default_header,
          header_example: default_header_example,
          parameter: [
            {
              title: 'email',
              required: 'O',
              type: 'String',
              desc: '이메일',
            },
          ],
          response: [
            {
              title: 'sol_no',
              required: 'O',
              type: 'number',
              desc: '조회된 회원 sol_no',
            },
          ],
          response_example: {
            sol_no: '999999',
          },
        },
      ],
    },
    {
      title: '표준카테고리 리스트 엑셀 갱신 (EMP)',
      url: '/doc/표준카테고 리스트 엑셀갱신EMP',
      data: [
        {
          version: 'v1.0',
          url: '/product/online/category/updateStandardCateExcel',
          authorized_m_no: [1957, 120115],
          unrecognized_aff_no: [1051, 1057],
          method: 'PUT',
          desc: (
            <ul className='style'>
              <li>
                서버에 저장된 표준카테고리 리스트 엑셀을 갱신합니다.
              </li>
            </ul>
          ),
          header: default_header,
          header_example: default_header_example,
          response: [
            {
              title: 'url',
              required: 'O',
              type: 'String',
              desc: '표준카테고리 리스트 엑셀 다운로드 url'
            },
          ],
          response_example: {
            url: 'https://cloud.plto.com/sampleExcel/Sample_standard_category/Standard_category_list.xlsx'
          }
        },
      ],
    },
    {
      title: '회원 가입',
      url: '/doc/회원 가입',
      data: [
        {
          version: 'v1.0',
          url: '/member/register',
          desc: (
            <ul className='style'>
              <li>
                PLAYAUTO 2.0 솔루션에 신규 회원을 가입합니다.
              </li>
              <li>
                마스터 계정의 API key 로만 호출 가능 하며, 업체 계정의 API key는 호출이 불가합니다.
              </li>
              <li>
                가입 완료시 마스터 계정의 기업분류 (제휴처코드) 가 자동으로 부여됩니다. 
              </li>
            </ul>
          ),
          authorized_m_no: [199509, 214842],
          authorized_aff_no: [1051, 1057],
          method: 'POST',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'email',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  솔루션 가입을 위한 ID / 이메일 형식 / 기존 가입된 플레이오토 ID 사용 불가
                  <div className='description'>
                    가입 시 생성한 이메일ID는 수정이 불가하니 신중하게 결정해주세요.
                  </div>
                </div>
              ),
            },
            {
              title: 'password',
              required: 'O',
              type: 'String',
              desc: '영문 + 숫자 + 특수문자 조합 / 8자 이상',
            },
            {
              title: 'charge_name',
              required: 'O',
              type: 'String',
              desc: '한글, 영문, 숫자, 특수문자 / 영업 담당자님의 성함',
            },
            {
              title: 'charge_htel',
              required: 'O',
              type: 'String',
              desc: '영업 담당자님의 휴대폰 번호',
            },
            {
              title: 'charge_email',
              required: 'O',
              type: 'String',
              desc: '영업 담당자님의 이메일 주소 (세금계산서 발행등에 사용)',
            },
            {
              title: 'sms_yn',
              required: 'O',
              type: 'Boolean',
              desc: 'sms 수신 여부',
            },      
            {
              title: 'email_yn',
              required: 'O',
              type: 'Boolean',
              desc: '이메일 수신 여부',
            },
            {
              title: 'c_type_cd',
              required: '',
              type: 'number',
              desc: (
                <div>
                  사업자유형<br />
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>1</code>
                          </strong>
                        </td>
                        <td>
                          <strong>개인사업자(간이과세자)</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>2</code>
                          </strong>
                        </td>
                        <td>
                          <strong>개인사업자(일반과세자)</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>3</code>
                          </strong>
                        </td>
                        <td>
                          <strong>법인사업자</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>4</code>
                          </strong>
                        </td>
                        <td>
                          <strong>면세사업자</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>5</code>
                          </strong>
                        </td>
                        <td>
                          <strong>해외사업자</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>6</code>
                          </strong>
                        </td>
                        <td>
                          <strong>개인판매자</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                 
                </div>
              ),
            },

            {
              title: 'biz_no',
              required: 'O',
              type: 'String',
              desc: '사업자 번호',
            },
            {
              title: 'biz_name',
              required: 'O',
              type: 'String',
              desc: '사업자명',
            },
            {
              title: 'ceo_name',
              required: '',
              type: 'String',
              desc: '대표자명',
            },
          ],
          body_example: {
            email: 'testID0001@playauto.co.kr',
            password: 'playauto1!',
            charge_name: '김플토',
            charge_htel: '01011112222',
            charge_email: 'testID0001@test.com',
            sms_yn: true,
            email_yn: true,
            c_type_cd: 2,
            biz_no: '10123456789',
            biz_name: '회사명샘플',
            ceo_name: '박플토'
          },
          response: [
            {
              title: 'state',
              required: 'O',
              type: 'string',
              desc: '상태',
            },
            {
              title: 'api_key',
              required: 'O',
              type: 'string',
              desc: '생성된 API 키',
            },
          ],
          response_example:{
            state: 'success',
            api_key: 'VmzabZ3....'
          },
        },
      ],
    }, 
    {
      title: '회원 정보 수정',
      url: '/doc/회원 정보 수정',
      data: [
        {
          version: 'v1.0',
          url: '/member/userEdit',
          desc: (
            <ul className='style'>
              <li>
                PLAYAUTO 2.0 솔루션에 가입된 회원 정보를 수정 업데이트 합니다.
              </li>
              <li>
                마스터 계정의 API key 로만 호출 가능 하며, 업체 계정의 API key는 호출이 불가합니다.
              </li>
              <li>
                솔루션 ID는 수정이 불가 합니다.
              </li>
            </ul>
          ),
          authorized_m_no: [199509, 214842],
          authorized_aff_no: [1051, 1057],
          method: 'POST',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'email',
              required: 'O',
              type: 'String',
              desc: '회원 정보 수정하고자 하는 ID / 이메일 형식',
            },
            {
              title: 'password',
              required: 'O',
              type: 'String',
              desc: '영문 + 숫자 + 특수문자 조합 / 8자 이상',
            },
            {
              title: 'charge_name',
              required: 'O',
              type: 'String',
              desc: '한글, 영문, 숫자, 특수문자 / 영업 담당자님의 성함',
            },
            {
              title: 'charge_htel',
              required: 'O',
              type: 'String',
              desc: '영업 담당자님의 휴대폰 번호',
            },
            {
              title: 'charge_email',
              required: 'O',
              type: 'String',
              desc: '영업 담당자님의 이메일 주소 (세금계산서 발행등에 사용)',
            },
            {
              title: 'sms_yn',
              required: 'O',
              type: 'Boolean',
              desc: 'sms 수신 여부',
            },      
            {
              title: 'email_yn',
              required: 'O',
              type: 'Boolean',
              desc: '이메일 수신 여부',
            },
            {
              title: 'c_type_cd',
              required: '',
              type: 'number',
              desc: (
                <div>
                  사업자유형<br />
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>1</code>
                          </strong>
                        </td>
                        <td>
                          <strong>개인사업자(간이과세자)</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>2</code>
                          </strong>
                        </td>
                        <td>
                          <strong>개인사업자(일반과세자)</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>3</code>
                          </strong>
                        </td>
                        <td>
                          <strong>법인사업자</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>4</code>
                          </strong>
                        </td>
                        <td>
                          <strong>면세사업자</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>5</code>
                          </strong>
                        </td>
                        <td>
                          <strong>해외사업자</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>6</code>
                          </strong>
                        </td>
                        <td>
                          <strong>개인판매자</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                 
                </div>
              ),
            },

            {
              title: 'biz_no',
              required: 'O',
              type: 'String',
              desc: '사업자 번호',
            },
            {
              title: 'biz_name',
              required: 'O',
              type: 'String',
              desc: '사업자명',
            },
            {
              title: 'ceo_name',
              required: '',
              type: 'String',
              desc: '대표자명',
            },
          ],
          body_example: {
            email: 'testID0001@playauto.co.kr',
            password: 'playauto1!',
            name: '김플토',
            htel: '01011112222',
            charge_email: 'testID0001@test.com',
            sms_yn: true,
            email_yn: true,
            c_type_cd: 2,
            biz_no: '10123456789',
            biz_name: '회사명샘플',
            ceo_name: '박플토'
          },
          response: [
            {
              title: 'state',
              required: 'O',
              type: 'string',
              desc: '상태',
            }
          ],
          response_example:{
            state: 'success',
          },
        },
      ],
    },
    {
      title: '회원 탈퇴',
      url: '/doc/회원 탈퇴',
      data: [
        {
          version: 'v1.0',
          url: '/member/userDelete',
          authorized_m_no: [199509, 214842],
          authorized_aff_no: [1051, 1057],
          method: 'POST',
          desc: (
            <ul className='style'>
              <li>
                PLAYAUTO 2.0 솔루션에 가입된 회원을 탈퇴 합니다.
              </li>
              <li>
                마스터 계정의 API key 로만 호출 가능 하며, 업체 계정의 API key는 호출이 불가합니다.
              </li>
              <li>
                탈퇴 처리 시 해당 계정은 휴면계정으로 변경되며, 100일간 데이터를 유지한 후 모든 데이터를 삭제 합니다.
              </li>
            </ul>
          ),
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'email',
              required: 'O',
              type: 'String',
              desc: '회원 탈퇴하고자 하는 ID / 이메일 형식',
            },
          ],
          body_example: {
            email: 'testID0001@playauto.co.kr',
          },
          response: [
            {
              title: 'state',
              required: 'O',
              type: 'string',
              desc: '상태',
            }
          ],
          response_example:{
            state: 'success',
          },
        },
      ],
    },
  ],
});

export default data;
