import {  useContext } from 'react';
import AuthContext from '../../../../store/auth-context';

import classes from './AccordionSideNavNode.module.css';
import { NavLink, useLocation } from 'react-router-dom';
import cryptoRandomString from 'crypto-random-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import PageDataList from 'store/PageDetailData/PageDetailData';

/**
 * 사이드바 내부 네비게이션 버튼 컴포넌트
 */
const AccordionSideNavNode = (props) => {
  const authCtx = useContext(AuthContext);
  const location = useLocation();

  if (!props.data.children) {
    return (
      <li className={`${classes.container} ${classes[`level-${props.level}`]}`}>
        <button>
          <NavLink className={({isActive}) => isActive ? classes.active : ''}
            to={props.data.url}
          >
            {props.data.title}
          </NavLink>
        </button>
      </li>
      );
  }

  // 인풋 레이블을 위한 인풋ID 자동 생성
  const randomId = cryptoRandomString({length: 10, characters: 'abc'});
  // SideNav를 통한 페이지 이동 시 SideNav가 접히는 문제가 있어 랜더링 시 직접 초기설정
  const expanded = props.data.title === findOpenAccordionMenuTitle();
  return (
    <li className={`${classes.container} ${classes[`level-${props.level}`]}`}>
      <button type='button' data-bs-toggle='collapse' data-bs-target={`#${randomId}`} aria-expanded={expanded} aria-controls={randomId}>
        {props.data.title}
        <FontAwesomeIcon icon={faAngleDown} />
      </button>
      <ul id={randomId} className={expanded ? '' : 'collapse'}>
        {
          props.data.children.map((item, i) => <AccordionSideNavNode data={item} key={i} level={props.level + 1} parent={props.data}/>)
        }
      </ul>
    </li>
  );

  function findOpenAccordionMenuTitle() {
    for (let category of PageDataList(authCtx.userInfo)) {
      for (let page of category.children) {
        if (page.url === decodeURIComponent(location.pathname)) {
          return category.title;
        }
      }
    }
    return null;
  }
};

export default AccordionSideNavNode;
