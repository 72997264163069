import { useContext, useState, useMemo } from 'react';
import classes from './InquiryListPage.module.css';
import AuthContext from '../../../store/auth-context';
import InquiryModal from '../../modals/InquiryModal/InquiryModal';
import { Link } from 'react-router-dom';
import useFetchData from '../../../hooks/useFetchData';
import DataTable from '../../UI/DataTable';
import useValidatePageAccess from 'hooks/useValidatePageAccess';

/**
 * 1:1문의 목록 리스팅 페이지 컴포넌트
 */
const InquiryListPage = () => {
  useValidatePageAccess();
  const authCtx = useContext(AuthContext);
  const [inquiryList, setInquiryList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const option = {
    method: 'GET',
    headers: {
      Authorization: authCtx.token
    }
  };

  useFetchData('/center/inquiries/list', option, res_json => setInquiryList(res_json.results), authCtx, modalOpen);

  const columns = useMemo(() => [
    {
      header: '문의번호',
      accessorKey: 'cs_no',
      size: 150
    },
    {
      header: '문의상태',
      accessorKey: 'board_status',
      size: 90
    },
    {
      header: '문의유형',
      accessorKey: 'board_type',
      size: 100
    },
    {
      header: '문의제목',
      accessorKey: 'title',
      cell: cell => <Link to={`./detail/${cell.row.original.cs_no}`}>{cell.row.original.title}</Link>,
      size: 410,
      notSortable: true
    },
    {
      header: '문의일시',
      accessorKey: 'wdate',
      size: 200
    },
    {
      header: '답변일',
      accessorKey: 'reply_time',
      size: 200
    }
  ], []);

  return (
    <>
      <div className={classes.container}>
        <h2 className={classes.heading}>1:1문의</h2>
        <h4 className={classes.tableTitle}>1:1문의 목록</h4>
        <DataTable 
          columns={columns}
          data={inquiryList}
        />
        <div className={classes.btnWrap}>
          <button className={classes['modal-open']} onClick={() => setModalOpen(true)}>작성하기</button>
        </div>
      </div>
      {modalOpen && <InquiryModal closeModal={() => setModalOpen(false)} />}
    </>
  );
};

export default InquiryListPage;