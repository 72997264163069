import classes from './CheckboxAndLabel.module.css';
import cryptoRandomString from 'crypto-random-string';

/**
 * 체크박스 + 레이블 컴포넌트
 */
const CheckboxAndLabel = (props) => {
  const randomId = cryptoRandomString({length: 10, characters: 'abc'});

  return (
    <div className={classes.container + ' CheckboxAndLabel'}>
      <input 
        id={randomId}
        type='checkbox'
        checked={props.checked || false}
        onChange={props.onChange}
        value=''
      />
      <label htmlFor={randomId}>
        {props.label}
      </label>
    </div>
  );
};

export default CheckboxAndLabel;
