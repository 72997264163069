import styled from 'styled-components';
import SimpleSideNav from '../../layout/SideNav/SimpleSideNav';
import { GuideContentList } from '../../../store/LeftContentList';
import imgOverviewInfo from 'assets/img/overview-info.png';

const OverviewPage = () => {
  return (
    <>
      <SimpleSideNav data={GuideContentList} />
      <Container>
        <h2>개발자센터 소개</h2>

        <section>
          <h3>플레이오토 개발자센터란?</h3>
          <div>
            <p>
              플레이오토 개발자센터는 플레이오토 2.0 솔루션의 API의 사용이 필요한 개발자를 위한 공간이며, 사용조건에 해당되는 사용자는<br/>
              누구나 API를 사용할 수 있습니다. API를 사용하기 위해서는 플레이오토 개발자센터 가입 후 사용신청을 해야 합니다.
            </p>
          </div>
        </section>

        <section>
          <h3>API 서비스 소개</h3>
          <div>
            <p>
              쇼핑몰 통합 솔루션 플레이오토 2.0의 기능을 API로 제공합니다. <br/>
              주요 API는 상품, 주문, 재고이며 이 외에도 다양한 기능을 사용할 수 있습니다.
            </p>
            <img src={imgOverviewInfo} alt={'API 서비스 소개'}/>
            <dl>
              <dt>주문</dt>
              <dd>
                각 쇼핑몰에서 수집된 주문 정보를 조회할 수 있으며, 출고 작업 후 입력된 배송 정보(택배사, 운송장 번호)를 솔루션에 등록하여 각 쇼핑몰에 전송할 수 있습니다.
              </dd>
              <dt>상품</dt>
              <dd>
                쇼핑몰에 판매할 상품을 솔루션에 등록 및 수정할 수 있습니다.
              </dd>
              <dt>재고</dt>
              <dd>
                솔루션에 등록된 SKU상품 정보를 수정하거나 수량을 조정할 수 있습니다.
              </dd>
            </dl>
          </div>
        </section>

        <section>
          <h3>API 사용 가능 대상</h3>
          <div>
            <p>
              API 사용 신청 시 사용자 구분은 “대행사”와 “일반”으로 나뉩니다. 사용자 구분과 사용신청 가능 대상은 아래와 같습니다. <br/><br/>
              대행사 : 다수의 솔루션 계정 연동이 필요한 사용자 (사업자 번호 필요)<br/>
              일반 : 한 개의 솔루션 계정 연동이 필요한 사용자(솔루션 계정 필요)
            </p>
            <dl>
              <dt>
                대행사 API 사용신청
              </dt>
              <dd>
                유효한 사업자등록번호가 있어야 사용신청이 가능하며, 아래와 같은 경우 사용 승인이 거절될 수 있습니다. <br/><br/>
                1. 신청한 정보와 실제 정보가 상이할 경우<br/>
                2. 업종이 API 사용에 적합하지 않다고 판단되는 경우 <br/>
                3. 사용 용도 검토 후 적합하지 않다고 판단되는 경우
              </dd>
              <dt>
                일반 API 사용신청
              </dt>
              <dd>
                2.0 솔루션을 사용중인 계정만 신청 가능합니다.
              </dd>
            </dl>
          </div>
        </section>

        <section>
          <h3>개발자센터 계정 생성</h3>
          <div>
            <p>
              개발자 센터는 플레이오토 통합 홈페이지(https://www.plto.com/) 계정으로 이용할 수 있습니다.
            </p>
          </div>
        </section>

      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 110px 100px;
  
  > h2 {
    font-size: 36px;
    font-weight: bold;
  }

  > section {
    padding: 50px 0;
    border-bottom: 1px solid #ddd;
    &:last-child {
      border-bottom: none;
    }

    > h3 {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px
    }

    > div {
      
      > p {
        color: #666;
        font-size: 16px;
        line-height: 1.8;
      }

      > img {
        display: block;
        margin: 25px 0;
        width: 749px;
      }

      > dl {
        > dt {
          font-weight: bold;
          font-size: 18px;
          margin: 20px 0 10px;
          &:before {
            content: "•";
            display: inline-block;
            width: 10px;
          }
        }
        > dd {
          color: #666;
          font-size: 16px;
          padding-left: 10px;
          line-height: 1.8;
        }
      }
    }
  }
`;





export default OverviewPage;