import ReactDOM from 'react-dom';
import classes from './Modal.module.css';
import styled from '@emotion/styled';

// 모달 오픈 시 주위 배경색 어둡게 설정 및 클릭 불가
const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.outsideClickEnabled ? props.onConfirm : null} />;
};

/**
 * 모달 공통 적용 컴포넌트
 */
const Modal = (props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onConfirm={props.onConfirm} />,
        document.getElementById('backdrop-root')
      )}
      {ReactDOM.createPortal(
        <ModalContainer>{props.children}</ModalContainer>,
        document.getElementById('overlay-root')
      )}
    </>
  );
};

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
`;

export default Modal;
