import { useState } from 'react';
import classes from './RegisterPage.module.css';
import RegisterInput from '../../UI/RegisterInput';
import useFormValue from '../../../hooks/useFormValue';
import CheckboxAndLabel from '../../UI/CheckboxAndLabel';
import { useNavigate } from 'react-router-dom';
import { simpleSubmitHandler } from '../../../util/HelperFunctions';
import arrowBlack from 'assets/img/arrow-black.svg';
import arrowGray from 'assets/img/arrow-gray.svg';

const RegisterPage = () => {
  const [formValues, handleInputUpdate, formValidity] = useFormValue({
    email: {
      value: '',
      regex: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
      placeholder: '이메일 형식으로 입력하세요',
      label: '이메일',
      type: 'text',
    },
    password: {
      value: '',
      regex: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      label: '비밀번호',
      type: 'password',
      placeholder: '영문+숫자+특문조합 8자 이상 입력하세요.',
    },
    password_confirm: {
      value: '',
      label: '비밀번호 확인',
      type: 'password',
      placeholder: '비밀번호를 다시 입력해주세요',
      validation_err_msg: '비밀번호와 일치하지 않습니다.',
    },
    name: { value: '', regex: /.+/, label: '이름', type: 'text' },
    tel: {
      value: '',
      regex: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3,4}[-\s.]?[0-9]{4,6}$/,
      placeholder: '전화번호 형식으로 입력하세요',
      label: '전화번호',
      type: 'text',
      optional: true,
    },
    htel: {
      value: '',
      regex: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3,4}[-\s.]?[0-9]{4,6}$/,
      placeholder: '전화번호 형식으로 입력하세요',
      label: '휴대전화번호',
      type: 'text',
    },
    email_yn: { value: false },
    sms_yn: { value: false },
  });
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestOption = {
      method: 'POST',
      body: JSON.stringify(
        (() => {
          const ret = {};
          for (const key in formValues) {
            ret[key] = formValues[key].value;
          }
          return ret;
        })()
      ),
      headers: {
        'Content-Type': 'application/json',
      },
    };
    simpleSubmitHandler(
      setIsLoading,
      '/center/users/register',
      requestOption,
      null,
      () => navigate('/login', { state: 'REGISTER_COMPLETE' })
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.pageTitle}>
        <h2>회원가입</h2>
      </div>
      <div className={classes['register-step']}>
        <h3>정보입력</h3>
        <ul>
          <li>
            <div>
              <p>01</p>
              약관동의
            </div>
            <img src={arrowBlack} alt={'검정 화살표'} />
          </li>
          <li>
            <div>
              <p>02</p>
              정보입력
            </div>
            <img src={arrowGray} alt={'회색 화살표'} />
          </li>
          <li>
            <div>
              <p>03</p>
              가입완료
            </div>
          </li>
        </ul>
      </div>
      <form onSubmit={handleSubmit} className={classes['register-form']}>
        <div className={classes.credentials}>
          <h4>로그인정보</h4>
          <div className={classes.formBox}>
            {[
              'email',
              'password',
              'password_confirm',
              'name',
              'tel',
              'htel',
            ].map((item, key) => (
              <RegisterInput
                {...formValues[item]}
                valid={
                  item === 'password_confirm'
                    ? formValues.password.value ===
                      formValues.password_confirm.value
                    : null
                }
                onChange={(e) => handleInputUpdate(item, e.target.value)}
                key={key}
              />
            ))}
          </div>

          <div className={classes['checkbox-group']}>
            <CheckboxAndLabel
              label='이메일 수신동의 여부'
              onChange={(e) => handleInputUpdate('email_yn', e.target.checked)}
              checked={formValues.email_yn.value}
            />
            <CheckboxAndLabel
              label='SMS 수신동의 여부'
              onChange={(e) => handleInputUpdate('sms_yn', e.target.checked)}
              checked={formValues.sms_yn.value}
            />
          </div>
          <button disabled={!formValidity}>다음</button>
        </div>
      </form>
      {isLoading && <p>잠시만 기다려주세요...</p>}
    </div>
  );
};

export default RegisterPage;
