import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import SearchModal from '../modals/SearchModal/SearchModal';

/**
 * 검색창 컴포넌트
 */
const SearchButton = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <button onClick={() => setModalOpen(true)}>
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </button>
      {modalOpen && <SearchModal closeModal={() => setModalOpen(false)} />}
    </>
  );
};

export default SearchButton;
