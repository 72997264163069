import { useState, useContext, useEffect } from 'react';
import classes from './LoginPage.module.css';
import ValidationInput from '../../UI/ValidationInput';
import CheckboxAndLabel from '../../UI/CheckboxAndLabel';
import useFormValue from '../../../hooks/useFormValue';
import AuthContext from '../../../store/auth-context';
import { useNavigate, useLocation } from 'react-router-dom';
import { simpleSubmitHandler } from '../../../util/HelperFunctions';
import Swal from 'sweetalert2';
import { setCookie, getCookie, removeCookie } from '../../../util/cookies';
import moment from 'moment';

const LoginPage = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [formValues, handleInputUpdate, formValidity] = useFormValue({
    email: {
      value: '',
      regex: /^[\w-.]+@([\w-]+\.)+[\w-]{2,5}$/,
      placeholder: '이메일ID',
      label: '이메일',
      type: 'text',
    },
    password: {
      value: '',
      regex: /.+/,
      placeholder: '비밀번호',
      label: '비밀번호',
      type: 'password',
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [saveID, setSaveID] = useState(false);

  // 아이디 저장여부 쿠키에서 로드
  useEffect(() => {
    const savedEmail = getCookie('email');
    const savedPassword = getCookie('password');

    if (savedEmail && savedPassword) {
      formValues.email.value = savedEmail;
      formValues.password.value = savedPassword;

      setSaveID(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestOption = {
      method: 'POST',
      body: JSON.stringify({
        email: formValues.email.value,
        password: formValues.password.value,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    };
    simpleSubmitHandler(
      setIsLoading,
      '/center/users/login',
      requestOption,
      (res_json) =>
        authCtx.login(
          res_json.token,
          res_json.m_no,
          new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString(),
          res_json.aff_no
        ),
      () => {
        // 아이디 저장인경우 쿠키에 7일간 저장.
        if (saveID) {
          setCookie('email', formValues.email.value, { expires: moment().add('7', 'day').toDate() })
          setCookie('password', formValues.password.value, { expires: moment().add('7', 'day').toDate() })
        } else {
          removeCookie('email');
          removeCookie('password');
        }
        navigate('/')
      }
    );
  };

  const sendInfo = encodeURIComponent(
    JSON.stringify({
      email: formValues.email.value || '',
      pwd: formValues.password.value || '',
    })
  );
  localStorage.setItem('sendInfo', sendInfo);

  return (
    <form onSubmit={handleSubmit} className={classes.container}>
      {state && (
        <h2 className={classes['top-inform']}>*회원가입이 신청되었습니다.</h2>
      )}
      <h2>로그인</h2>
      <div className={classes.loginWrap}>
        <ValidationInput
          {...formValues.email}
          onChange={(e) => handleInputUpdate('email', e.target.value)}
        />
        <ValidationInput
          {...formValues.password}
          onChange={(e) => handleInputUpdate('password', e.target.value)}
        />
        <CheckboxAndLabel
          label={'아이디저장'}
          onChange={(e) => setSaveID(e.target.checked)}
          checked={saveID}
         />
        <button className={classes.loginBtn} disabled={!formValidity}>로그인</button>
        <div className={classes.btnGroup}>
          <a href='https://www.plto.com/member/FindId/' target="_blank" rel="noreferrer">아이디/비밀번호 찾기</a> |&nbsp;
          <button
            onClick={async (e) => {
              e.preventDefault();

              const confirm = await Swal.fire({
                icon: 'info',
                title: '회원가입은 플레이오토<br />홈페이지에서 진행됩니다.',
                html: '이미 플레이오토 회원이신 경우<br />별도의 가입없이 로그인이 가능합니다.<br /><br />회원가입 페이지로 이동하시겠습니까?',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
              });

              if (confirm.isConfirmed) {
                window.location.href = 'https://www.plto.com/register/Register/?from=devCenter'
              }
            }}
          >
            회원가입
          </button>
        </div>
        <div className={classes.helpText}>
          ❊ PLAYAUTO 계정으로 로그인 가능합니다.
        </div>
      </div>

      {isLoading && <p>잠시만 기다려주세요...</p>}
    </form>
  );
};

export default LoginPage;
