import Swal from 'sweetalert2';
import * as ReactDOMServer from 'react-dom/server';
import config from '../store/config';

export async function simpleSubmitHandler(setIsLoading, url, option, handleResponse, next) {
  setIsLoading && setIsLoading(true);
  try {
    // 파일첨부시 api gateway 를 타서 문제가 되는건가해서 1:1 문의시 바로 백엔드 호출하도록 도메인 수정
    const call_domain = url === '/center/inquiries/add' ? 'https://open-api.playauto.io' : config.backendUrl;
    const res = await fetch(call_domain + url, option);
    const res_json = await res.json();

    if (res.ok) {
      if (res_json.status === 'error' || res_json.error_code) {
        throw new Error(res_json.messages)
      } else {
        handleResponse && handleResponse(res_json);
      }
    } else {
      throw new Error(res_json.messages || res_json.message || res.message);
    }
    next && next();
  } catch (e) {
    Swal.fire({
      icon: 'error',
      html: /^\/api\//g.test(url) && e.message === 'Failed to fetch' ? '서버와 통신에 실패했습니다.<br />x-api-key 를 올바르게 입력했는지 확인해주십시오.' : e.message,
      confirmButtonColor: '#3085d6',
    });
  } finally {
    setIsLoading && setIsLoading(false);
  }
}

/**
 * OpenAPI 문서 페이지별 데이터 객체로부터 API url에 해당하는 데이터를 추출해서 반환하는 함수
 */
export function findPageData(url, list) {
  for (const pageData of list) {
    if (pageData.url?.match(url)) {
      return pageData;
    }
    if (pageData.children) {
      const temp = findPageData(url, pageData.children);
      if (temp) return temp;
    }
  }
  return false;
}

/**
 * Converts react element to plain text.
 * If input is already string, simply returns it.
 */
export function reactElementToPlainText(element) {
  if (typeof element === 'string') return element;
  
  const translate_map = {
    '&nbsp;': ' ',
    '&amp;' : '&',
    '&quot;': '"',
    '&lt;'  : '<',
    '&gt;'  : '>',
    '&#x27;': '\''
  };

  let ret = ReactDOMServer.renderToStaticMarkup(element).replace(/<[^>]+>/g, '');
  for (const key of Object.keys(translate_map)) {
    ret = ret.replace(new RegExp(key, 'g'), translate_map[key]);
  }

  return ret;
}

// 테스트 배포용 주석