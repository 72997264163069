import classes from './SimpleSideNav.module.css';
import { NavLink } from 'react-router-dom';

/**
 * 왼쪽 사이드바 컴포넌트
 */
const SimpleSideNav = (props) => {
  return (
    <section className={classes.container}>
      <ul>
        {
          props.data.map((item, i) => (
            <li key={i}>
              <button>
                <NavLink className={({ isActive }) => isActive ? classes.active : ''}
                  to={item.url}
                >
                  {item.title}
                </NavLink>
              </button>
            </li>)
          )
        }
      </ul>
    </section>
  );
};

export default SimpleSideNav;