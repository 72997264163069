import classes from './MainPage.module.css';
import { Link } from 'react-router-dom';
import mainSymbol from 'assets/img/main-symbol.gif'

const MainPage = () => {
  return (
    <div className={classes.container}>
      <div className={classes.mainText}>
        <h2>
          플레이오토 2.0
          <br /> OPEN API
        </h2>
        <div>
          <h3>플레이오토 2.0 API 서비스</h3>
          쇼핑몰 통합관리 솔루션 플레이오토 2.0에서 지원하는 <br />
          다양한 기능을 API로 이용해보세요.
        </div>
        <Link to='/instruction'>플레이오토 API 시작하기</Link>
      </div>
      <div className={classes.videoWrap}>
        <img src={mainSymbol} alt={'main symbol'}/>
      </div>
    </div>
  );
};

export default MainPage;
