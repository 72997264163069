import { Link } from 'react-router-dom';

const data = (userInfo) => ({
  title: '변경내역',
  unrecognized_aff_no: [1051],
  url: '/release',
  children: [],
  alwaysShow: true,
  data: [
    {
      date: '2024-08-06',
      method: 'POST',
      action: '/products/add/v1.1',
    },
    {
      date: '2024-08-06',
      method: 'PUT',
      action: '/products/edit/v1.1',
    },
    {
      date: '2024-08-06',
      method: 'POST',
      action: '/stock/add/v1.2',
    },
    {
      date: '2024-08-06',
      method: 'PUT',
      action: '/stock/edit/v1.2',
      detail:
      <ul>
        <li>
          상품정보제공고시 <code>[상세설명참조]</code> 일괄 적용 파라미터 <code>is_desc_referred</code> 추가
        </li>
      </ul>
    },
    {
      date: '2024-07-18',
      method: 'PUT',
      action: '/order/setInvoice',
      detail:
      <ul>
        <li>
          배송정보 업데이트 API 사용 가능 주문상태에 '배송중' 상태 추가
          <code>[출고대기, 출고보류, 운송장출력, 주문재확인, 배송중]</code> 상태 주문만 사용 가능
        </li>
      </ul>
    },
    {
      date: '2024-06-11',
      method: 'GET',
      action: '/categorys',
      detail:
      <ul>
        <li>
          카테고리 조회 시 분할 조회 파라미터 <code>start: 검색 시작값</code>, <code>length: 조회할 카테고리 갯수</code> 추가<br />
          - 미입력시 전체 조회<br />
          - 비필수 값이지만 분할 조회 시에는 둘 다 입력
        </li>
      </ul>
    },
    {
      date: '2024-06-10',
      method: 'POST',
      action: '/work/addWorkSelect',
      detail:
      <ul>
        <li>
          - 상품동기화 작업 등록시 아래의 상태의 상품만 작업이 진행되도록 수정<br />
            대상상품 중 아래의 상태를 포함하는 상품이 하나도 없는경우 작업등록 실패.
        </li>
        <li>
          <code>['승인대기', '판매중', '수정대기', '종료대기', '일시품절', '판매중지', '반려']</code>
        </li>
      </ul>
    },
    {
      date: '2024-06-07',
      method: 'PUT',
      action: '/orders/instruction',
      detail:
      <table style={{marginTop: '15px'}}>
        <tr>
          <td>기존</td>
          <td>출고지시 처리시 bundle_codes 필수</td>
        </tr>
        <tr>
          <td rowSpan={3}>변경</td>
          <td>bundle_codes 비필수</td>
        </tr>
        <tr>
          <td>bundle_codes 가 없는경우 sdate, edate 는 필수로 입력되어야 합니다.</td>
        </tr>
        <tr>
          <td>bundle_codes 가 입력된 경우 sdate, edate 값은 무시되고 bundle_codes 가 없는경우 sdate, edate 내의 신규주문건이 출고지시 처리됩니다.</td>
        </tr>
      </table>
    },
    {
      date: '2024-06-07',
      method: 'GET',
      action: '/orders/count',
      detail:
      <>
        상태별 주문수량 조회에 검색 파라미터 추가<br />
        (검색날짜 미입력시 기본 할달로 조회됩니다.)
      </>
    },
    {
      date: '2024-06-05',
      method: 'GET',
      action: '/orders/count',
      detail:
      <>
        상태별 주문수량 조회 API 추가
      </>
    },
    {
      date: '2024-06-04',
      method: 'POST',
      action: '/inquirys',
      detail:
      <>
        고객문의 조회 항목 추가<br />
        - search key 조회 항목 추가 <code>inq_uniq : 문의고유번호</code>
      </>
    },
    {
      date: '2024-05-21',
      method: 'POST',
      action: '/orders',
      detail:
      <>
        주문 리스트 조회 문서 수정<br />
        - Response 리스트 내 <code>multi_bundle_yn : 묶음주문여부</code> 추가
      </>
    },
    {
      date: '2024-05-17',
      method: 'POST',
      action: '/work/addWorkSelect',
      detail:
      <>
        선택작업 등록 결과에  <code>list</code> 항목 추가 <br />
         - 작업이 성공되는 경우에만 출력<br />
         - 주문작업인경우 list 값에 묶음번호 데이터가 출력됨
      </>
    },
    {
      date: '2024-04-30',
      method: 'PATCH',
      action: '/orders/status',
      detail:
      <table style={{marginTop: '15px'}}>
        <tr>
          <td>기존</td>
          <td>묶음번호 기준으로만 주문상태변경 가능</td>
        </tr>
        <tr>
          <td rowSpan={2}>변경</td>
          <td>uniq 기준 주문상태변경 가능 (uniq 주문상태가 [주문재확인]인 경우에만 변경 가능) </td>
        </tr>
        <tr>
          <td>묶음번호, uniq 둘다 입력 시 uniq 주문 건만 상태 변경 (uniq 우선)</td>
        </tr>
      </table>
    },
    {
      date: '2024-04-18',
      method: 'PATCH',
      action: '/products/sale-cnt',
      detail:
      <>
        버그 수정: <br />
        <code>(기존)</code> 한 상품내 여러 옵션에 동일 sku 매칭 되어있는 경우 sku 처음 매칭된 옵션에만 수량 수정됨<br />
        <code>(수정)</code> 한 상품내 여러 옵션에 동일 sku 매칭 되어있는 경우 sku 매칭된 모든 옵션에 수량 수정됨<br />
      </>
    },
    {
      date: '2024-04-02',
      method: 'PUT',
      action: '/products/edit/v1.1',
      detail:
      <>
        온라인상품 수정 파라미터 수정<br />
        - <code>main_sku_cd</code> 추가 (대표SKU상품 설정 코드)
      </>
    },
    {
      date: '2024-04-02',
      method: 'POST',
      action: '/products/add/v1.1',
      detail:
      <>
       온라인상품 등록 파라미터 수정<br />
        - <code>main_sku_cd</code> 추가 (대표SKU상품 설정 코드)
      </>
    },
    {
      date: '2024-03-13',
      method: 'POST',
      action: '/work/addWorkSelect',
      detail:
      <>
        작업 등록 결과 내 쇼핑몰 정보 노출 여부 파라미터 <br />
        - <code>show_shop_info_yn</code> 추가 (작업 결과 내 쇼핑몰 정보 노출 여부)
      </>
    },
    {
      date: '2024-02-28',
      method: 'PATCH',
      action: '/orders/status',
      detail:
      <>
        주문상태변경 API 파라미터 <code>status</code>에 허용 값 추가<br/>
        - <strong>배송완료</strong> (현재 주문상태가 [주문재확인]인 경우에만 해당 값으로 변경 가능)<br/>
        - <strong>판매완료</strong> (현재 주문상태가 [주문재확인]인 경우에만 해당 값으로 변경 가능)<br/>
      </>
    },
    {
      date: '2024-02-26',
      method: 'POST',
      action: '/stock/list/v1.2',
      detail:
      <>
        재고 - SKU상품 리스트 조회<br/>
        - 조회 항목 추가 : <code>main_depot_no (대표 배송처 번호)</code><br/>
      </>
    },
    {
      date: '2024-02-22',
      method: 'POST',
      action: '/stock/list/v1.2',
      detail:
      <>
        재고 - SKU상품 리스트 조회<br/>
        - 조회 항목 추가<br/>
        - 배송처, 옵션 정보 응답 형식 변경<br />
      </>
    },
    {
      date: '2024-02-20',
      method: 'POST',
      action: '/work/addWork/v1.1',
      detail:
      <>
        작업 등록 결과 내 쇼핑몰 정보 노출 여부 파라미터 <br />
        - <code>show_shop_info_yn</code> 추가 (작업 결과 내 쇼핑몰 정보 노출 여부)
      </>
    },
    {
      date: '2024-02-20',
      method: 'POST',
      action: '/work/addWork/v1.2',
      detail:
      <>
        작업 등록 결과 내 쇼핑몰 정보 노출 여부 파라미터 <br />
        - <code>show_shop_info_yn</code> 추가 (작업 결과 내 쇼핑몰 정보 노출 여부)
      </>
    },
    {
      date: '2024-02-08',
      method: 'POST',
      action: '/stock/list/v1.2',
      detail: '재고: SKU상품 리스트 조회 API 추가'
    },
    {
      date: '2024-02-07',
      method: 'PATCH',
      action: '/products/edit/v1.1',
      detail:
      <>
        상품 수정 파라미터 <br />
        - <code>edit_slave_all</code> 추가 (마스터 상품 연동 수정 여부)
      </>
    },
    {
      date: '2024-01-26',
      detail:
      <>
        주문리스트 조회, 주문상세조회 응답값에 원주문번호(shop_ord_no_real) 추가<br />
        - <code>주문리스트 조회</code>(/api/orders)<br />
        - <code>주문 상세 조회</code>(/api/order/:uniq)
      </>
    },
    {
      date: '2024-01-24',
      detail:
      <>
        주문 액션 내 파라미터, response 문서 설명 변경<br />
        - <code>창고 -> 배송처</code><br />
        - <code>재고코드 -> 재고관리코드</code>
      </>
    },
    {
      date: '2023-12-08',
      detail:
      <>
        주문 리스트 조회 파라미터 길이 변경<br />
        - <code>shop_ship_no (15 → 30)</code>
      </>
    },
    {
      date: '2023-11-28',
      detail:
      <>
        쇼핑몰 계정 관리 내 별칭 기능 추가 지원<br />
        - 쇼핑몰 코드 조회 : <code>used=true</code> 설정 시 별칭 조회 가능<br />
        - 쇼핑몰 계정 등록 : <code>seller_nick 파라미터 추가</code> 별칭 등록 가능<br />
        - 쇼핑몰 계정 수정 : <code>seller_nick 파라미터 추가</code> 별칭 수정 가능<br />
      </>
    },
    {
      date: '2023-10-11',
      method: 'PATCH',
      action: '/orders/status',
      detail: 
      <>
        주문상태변경시 보류상태의 주문건도 상태변경가능하도록 수정<br />
        <table style={{marginTop: '15px'}}>
          <tr>
            <td>기존</td>
            <td>주문재확인 상태의 주문건만 상태변경 가능</td>
          </tr>
          <tr>
            <td rowSpan={2}>변경</td>
            <td>신규주문 상태변경 가능주문 (주문보류, 주문재확인)</td>
          </tr>
          <tr>
            <td>출고대기 상태변경 가능주문 (출고보류, 주문재확인)</td>
          </tr>
        </table>
      </>
    },
    {
      date: '2023-09-06',
      detail: 
      <>
        상품정보제공고시(구) 항목 지원 종료로 인한 아래 액션 미지원<br />
        - <code>SKU상품 등록 v1.0</code>(/api/stock/add)<br />
        - <code>SKU상품 수정 v1.0</code>(/api/stock/edit)<br />
        - <code>SKU상품 상세 조회 v1.0</code>(/api/stock/:sku_cd)<br />
        - <code>SKU상품 등록 v1.1</code>(/api/stock/add/v1.1)<br />
        - <code>SKU상품 수정 v1.1</code>(/api/stock/edit/v1.1)<br />
        - <code>SKU상품 상세 조회 v1.1</code>(/api/stock/:sku_cd/v1.1)<br />
        - <code>상품 등록 v1.0</code>(/api/products/add)<br />
        - <code>상품 수정 v1.0</code>(/api/products/edit)<br />
        - <code>상품 리스트 조회 v1.0</code>(/api/online/list)<br />
        - <code>상품 상세 조회 v1.0</code>(/api/products/:ol_shop_no)<br />
      </>
    },
    {
      date: '2023-08-21',
      detail: 
      <>
        온라인상품 재판매 작업전송시 새상품으로 등록 work_type 추가<br />
        - work_type = <code>REGIST_PROD_NEW</code> 추가<br />
        - 판매중지 상품 재판매시 <code>새로운 쇼핑몰 코드</code>로 등록
      </>
    },
    {
      date: '2023-08-04',
      detail: 
      <>
        주문 리스트 조회 파라미터 설명 변경<br />
        - <code>bundle_yn관련 start, length, bundle_yn 파라미터 설명 변경</code>
      </>
    },
    {
      date: '2023-07-31',
      detail: 
      <>
        온라인상품 등록 수정시 추가이미지 10 추가<br />
        - <code>기존 추가이미지 9 까지만 지원이되어 10 까지 추가</code><br />
        - <code>이미지2 → 추가이미지 1 .. 등으로 안내 문구 변경</code>
      </>
    },
    {
      date: '2023-06-28',
      detail: <>
      에러 응답 포멧 변경<br />
      error_code 와 message 가 있는경우 messages 로 메세지 결과 배열로 리턴되도록 수정
      <pre style={{ width: '500px' }}>
        <code>
          {`
          {
            error_code: 'e9999',
            messages: [
              "에러 메세지"
            ]
          }
          `}
        </code>
      </pre>
    </>
    },
    {
      date: '2023-05-17',
      method: 'PATCH',
      action: '/order/gift/distribution',
      detail: '주문: 규칙 사은품 분배 API 추가'
    },
    {
      date: '2023-03-28',
      method: 'GET',
      action: '/announcements',
      detail: '공통: 공지사항 조회 API 추가'
    },
    {
      date: '2023-02-07',
      method: 'DELETE',
      action: '/shop/delete',
      detail: '공통: 쇼핑몰 계정 삭제 API 추가'
    },
    {
      date: '2023-02-07',
      method: 'POST',
      action: '/shop/add',
    },
    {
      date: '2023-02-07',
      method: 'PATCH',
      action: '/shop/edit',
      detail: <>
        공통: 쇼핑몰 계정 등록/수정 API 전체 쇼핑몰 지원 <br />
        쇼핑몰별 etc필드 정보는 해당 API 문서의 엑셀 파일을 참고하여 작성해주세요.
      </>
    },
    {
      date: '2022-12-30',
      method: 'POST',
      action: '/stock/add/v1.2'
    },
    {
      date: '2022-12-30',
      method: 'PUT',
      action: '/stock/edit/v1.2'
    },
    {
      date: '2022-12-30',
      method: 'GET',
      action: '/stock/:sku_cd/v1.2',
      detail: <>
        재고: 품목정보고시 개정으로 인한 버전 추가<br />
        - <code>SKU상품 등록 v1.2</code><br />
        - <code>SKU상품 수정 v1.2</code><br />
        - <code>SKU상품 상세 조회 v1.2</code><br />
        <h6 style={{ color: 'red' }}>* 신규 버전 이전 API들은 추후 솔루션 (구)상품정보제공고시 사용 불가능 시 사용 불가능 예정</h6>
      </>
    },
    {
      date: '2022-12-30',
      method: 'POST',
      action: '/products/add/v1.1',
    },
    {
      date: '2022-12-30',
      method: 'PUT',
      action: '/products/edit/v1.1',
    },
    {
      date: '2022-12-30',
      method: 'POST',
      action: '/online/list/v1.1',
    },
    {
      date: '2022-12-30',
      method: 'GET',
      action: '/products/:ol_shop_no/v1.1',
      detail: <>
        상품: 품목정보고시 개정으로 인한 버전 추가<br />
        - <code>상품 등록 v1.1</code><br />
        - <code>상품 수정 v1.1</code><br />
        - <code>상품 리스트 조회 v1.1</code><br />
        - <code>상품 상세 조회 v1.1</code><br />
        <h6 style={{ color: 'red' }}>* 신규 버전 이전 API들은 추후 솔루션 (구)상품정보제공고시 사용 불가능 시 사용 불가능 예정</h6>
      </>
    },
    {
      date: '2022-12-14',
      method: 'PUT',
      action: '/order/hold',
      detail: <>
        출고 보류 API 명칭 변경 <code>출고보류</code> { '=>' } <code>보류</code><br />
        status parameter 추가
      </>
    },
    {
      date: '2022-12-13',
      method: 'PATCH',
      action: '/products/sale-cnt',
      detail: '상품: 온라인상품 판매수량 수정 API 추가'
    },
    {
      date: '2022-11-14',
      method: 'POST',
      action: '/orders',
      detail: <>주문 리스트 조회 시 <code>date_type</code> 에 <code>api_read_time</code> 추가</>
    },
    {
      date: '2022-10-14',
      method: 'POST',
      action: '/calculate/list',
      detail: <>
        정산: 정산 주문 번호 필드 삭제
        - <code>shop_ord_no_seq</code> 삭제
      </>
    },
    {
      date: '2022-10-14',
      method: 'DELETE',
      action: '/work/delete/v1.1',
      detail: <>
        작업: 작업 삭제 추가 <br />
        작업 전체, 선택 삭제 가능하도록 추가
      </>
    },
    {
      date: '2022-10-12',
      method: 'GET',
      action: '/addcontents',
      detail: <>
        상품: 머리말/꼬리말 템플릿 조회 항목 추가<br />
        - <code>addcontentTarget</code>: 템플릿 종류
      </>
    },
    {
      date: '2022-10-12',
      method: 'GET',
      action: '/products/:ol_shop_no/v1.1'
    },
    {
      date: '2022-10-12',
      method: 'PUT',
      action: '/products/edit/v1.1'
    },
    {
      date: '2022-10-12',
      method: 'POST',
      action: '/products/add/v1.1',
      detail: <>
        상품: 온라인 상품 등록/수정 조회 시 항목 추가<br />
        - <code>shop_sale_name, addcontent_no(상품명 머리말/꼬리말 템플릿 번호)</code>
      </>
    },
    {
      date: '2022-09-30',
      method: 'POST',
      action: '/order/add',
      detail: <>
        주문: 주문 등록 직접입력 쇼핑몰 지원<br />
        - <code>custom_shop_cd, custom_shop_id</code>
      </>
    },
    {
      date: '2022-09-27',
      method: 'POST',
      action: '/products/online/list/v1.1',
      detail: <>상품: 상품리스트 조회 v1.1 옵션 출력 정보 <code>pack_unit, set_name, prod_name</code>등 추가</>
    },
    {
      date: '2022-09-27',
      method: 'POST',
      action: '/work/addWorkSelect',
      detail: <>
        선택 작업 등록에 상품 작업인 경우 <code>api_callback_url</code> 설정 시 리턴되는 정보에 <code>product_info</code> 추가<br />
        - <code>c_sale_cd</code>: 판매자관리코드, <code>shop_sale_no</code>: 쇼핑몰상품코드
      </>,
    },
    {
      date: '2022-09-21',
      method: 'POST',
      action: '/products/online/list/v1.1',
      detail: <>
        상품: 상품리스트 조회 v1.1 멀티 검색 입력값 포맷 변경
        - v1.1 버전만 변경
        - <code>String</code> { '=>' } <code>String[]</code>
      </>,
    },
    {
      date: '2022-08-22',
      method: 'POST',
      action: '/products/online/list/v1.1',
      detail: <>
        상품: 상품리스트 조회 상품 갯수 제한<br />
        - 호출정보 length: MAX 300<br />
        - 결과 데이터 변경없음
      </>
    },
    {
      date: '2022-08-18',
      method: 'GET',
      action: '/products/online/list/v1.1',
      detail: <>
        상품: 상품리스트 조회 1.1 버전 추가<br />
        - 호출정보 변경없음<br />
        - 결과 데이터 변경 (옵션, 품목정보고시 정보 추가)
      </>
    },
    {
      date: '2022-07-22',
      method: 'POST',
      action: '/stock/add/v1.1',
      detail: <>
        재고: SKU상품 등록 시 대표 배송처 파라미터 위치 변경<br />
        1.1.0 버전에 사용되지 않는 파라미터 <code>delivery_vendor</code> 삭제
      </>
    },
    {
      date: '2022-07-19',
      method: 'GET',
      action: '/logs/v1.1',
      detail: '공통: 로그 조회 API 추가'
    },
    {
      date: '2022-07-14',
      method: 'PUT',
      action: '/order/edit/v1.1',
      detail: '주문: 주문 수정 API 추가'
    },
    {
      date: '2022-07-04',
      method: 'POST',
      action: '/work/addWork/v1.1',
      detail: <>
        작업 등록 시 <code>api_callback_url</code> 파라미터 추가<br />
        작업 등록 시 <code>api_callback_url</code> 를 입력하시면 작업 완료 시 입력한 주소로 작업결과가 전송됩니다.
      </>
    },
    {
      date: '2022-06-28',
      method: 'GET',
      action: '/stock/:sku_cd/v1.1'
    },
    {
      date: '2022-06-28',
      method: 'PUT',
      action: '/stock/manage/v1.1'
    },
    {
      date: '2022-06-28',
      method: 'PUT',
      action: '/stock/edit/v1.1'
    },
    {
      date: '2022-06-28',
      method: 'POST',
      action: '/stock/add/v1.1',
      detail: <>
        해당 API 다중 배송처 적용<br />
        해당 API은 1.1.0 버전으로 추가되었습니다.<br />
        변경사항 확인 및 적용 시 1.1.0 버전으로 문서를 확인해주세요.
      </>
    },
    {
      date: '2022-06-23',
      method: 'POST',
      action: '/orders',
      detail: <>
        주문 리스트 조회 시 파라미터: <code>api_read_status</code>, 응답 필드: <code>api_read_time, api_read_status</code> 추가<br />
        api_read_status 로 api를 통해 호출한 주문, 미호출한 주문을 조회할 수 있습니다.<br />
        <code><Link to="/doc/주문 리스트 조회">주문 리스트 조회</Link></code> 문서를 참고해주십시오
      </>
    },
    {
      date: '2022-05-26',
      method: 'PUT',
      action: '/order/combine',
      detail: '주문: 합포장 API 추가'
    },
    {
      date: '2022-05-26',
      method: 'POST',
      action: '/order/add',
      detail: <>주문동록 시 결과값에 등록한 주문의 <code>uniq</code> 추가</>
    },
    {
      date: '2022-04-06',
      method: 'GET',
      action: '/stock/:sku_cd'
    },
    {
      date: '2022-04-06',
      method: 'POST',
      action: '/stock/add'
    },
    {
      date: '2022-04-06',
      method: 'PUT',
      action: '/stock/edit',
      detail: <>
        <code>기초상품</code>{ '=>' }<code>SKU상품</code> 명칭 변경<br />
        SKU상품 등록/수정/상세 HS코드 <code>hscd</code> 필드 추가
      </>
    },
    {
      date: '2022-04-06',
      method: 'GET',
      action: '/hscds',
      detail: 'hscd 코드 조회 API 추가'
    },
    {
      date: '2022-03-31',
      method: 'POST',
      action: '/orders'
    },
    {
      date: '2022-03-31',
      method: 'POST',
      action: '/orders',
      detail: <>주문 문의 리스트 조회 시 마스킹 처리 여부 <code>masking_yn</code> 추가</>
    },
    {
      date: '2022-02-16',
      method: 'POST',
      action: '/orders',
      detail: <>
        온라인상품등록 결과값에 쇼핑몰 정보 추가
        <pre style={{ width: '500px' }}>
          <code>
            {`site_list: [
              {
                  "shop_name",
                  "shop_cd",
                  "shop_id",
                  "ol_shop_no",
              }
            ]`}
          </code>
        </pre>
      </>,
    },
    {
      date: '2022-01-10',
      method: 'POST',
      action: '/orders',
      detail: <>주문리스트 조회시 적용된 규칙사은품 정보 <code>gift_rule</code> 추가</>,
    },
    {
      date: '2021-11-11',
      method: 'PUT',
      action: '/order/divide',
      detail: <>주문분할 API에 <code>type</code> 파라미터 추가</>,
    },
    {
      date: '2021-11-10',
      method: 'GET',
      action: '/order/:uniq',
      detail: <>
        주문상세조회 결과 변수명 수정<br />
        -클레임메세지: <code>claim_msg</code> {'=>'} <code>ord_status_msg</code>
      </>,
    },
    {
      date: '2021-11-08',
      method: 'POST',
      action: '/stock/edit',
      detail: <>SKU상품수정에 <code>판매가</code>(product_price)추가</>,
    },
    {
      date: '2021-11-05',
      method: 'POST',
      action: '/stock/add',
      detail: <>SKU상품등록 시 원산지, 품목정보고시 <code>필수</code> {'=>'} <code>비필수</code> 로 수정</>,
    },
    {
      date: '	2021-09-14',
      method: 'POST',
      action: '/work/addWork',
      detail: '작업등록 API 추가',
    },
    {
      date: '2021-09-09',
      method: 'POST',
      action: '/work/addWorkSelect',
      detail: <>
        선택 작업 등록 추가<br />
        - <code>온라인상품 판매중지 전송</code>(CANCEL_PROD)<br />
        - <code>온라인상품 판매기간 연장</code>(EXTENT_PROD)
      </>
    },
    {
      date: '2021-07-21',
      method: 'POST',
      action: '/work/addWorkSelect',
      detail: <>
        선택작업등록에 <code>온라인상품 동기화</code>(SYNC_PROD_STATE)추가
      </>
    },
    {
      date: '2021-07-01',
      method: 'POST',
      action: '/products/add',
      detail: <>온라인상품등록시 원산지 <code>[비필수]</code> {'=>'} <code>[필수]</code> 로 수정</>
    },
    {
      date: '2021-06-25',
      method: 'GET',
      action: '/products/:ol_shop_no',
      detail: <>
        온라인상품상세조회 결과 변수명 수정<br />
        - 옵션수량: <code>cnt_limit</code> {'=>'} <code>stock_cnt</code><br />
        - 판매가능재고: <code>stock_cnt</code> {'=>'} <code>stock_cnt_enable</code>
      </>
    },
    {
      date: '2021-06-24',
      method: 'GET',
      action: '/work/:work_no',
      detail: '작업결과조회시 성공, 실패 여부 추가'
    },
    {
      date: '2021-06-15',
      method: 'POST',
      action: '/products/add',
      detail: <>
        - 온라인상품 미성년자 구매여부(minor_yn) 기본값 <code>true</code> 로 변경 (미성년자 구매가능)<br />
        - 온라인상품 수정 결과상태 문서 수정 <code>status</code> {'=>'} <code>result</code>
      </>
    },
    {
      date: '2021-06-04',
      method: 'GET',
      action: '/products/:ol_shop_no',
      detail: '온라인상품 상세조회 API 추가'
    },
    {
      date: '2021-06-02',
      method: 'GET',
      action: '/stock/:sku_cd',
      detail: 'SKU상품 상세조회 API 추가',
    }
  ]
});

export default data;
