import classes from './SimpleInput.module.css';
import cryptoRandomString from 'crypto-random-string';

/**
 * 입력형 인풋 필드 컴포넌트
 */
const SimpleInput = (props) => {
  const randomId = cryptoRandomString({length: 10, characters: 'abc'});

  return (
    <div className={classes.container + ' SimpleInput'}>
      <label htmlFor={randomId}>
        {props.label}
      </label>
      <input 
        id={randomId} 
        type={props.type} 
        placeholder={props.placeholder}
        onChange={e => props.onChange(e)}
        value={props.value || ''}
        disabled={props.disabled}
      />
      {props.note && <span className={classes.note}>{props.note}</span>}
      {props.warning && <span className={classes.warning}>{props.warning}</span>}
    </div>
  );
};

export default SimpleInput;
