import classes from './Logo.module.css';
import { Link } from 'react-router-dom';
import mainLogo from 'assets/img/logo.png';

/**
 * 플레이오토 API 센터 로고 컴포넌트
 */
const Logo = () => {
  return (
    <div className={classes.container}>
      <Link to='/'>
        <img src={mainLogo} alt={'playato logo'} /> 개발자센터
      </Link>
    </div>
  );
};

export default Logo;
