import { useState, useEffect, useContext } from 'react';
import classes from './MyPage.module.css';
import AuthContext from '../../../store/auth-context';
import useFetchData from '../../../hooks/useFetchData';
import moment from 'moment';

import { Spin } from 'antd';
import { uniqBy, groupBy } from 'lodash';

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Title,
  ArcElement
} from 'chart.js';
import { Chart, Doughnut } from 'react-chartjs-2';


/**
 * API 호출 내역 그래프
 */
const MyPage = () => {
  const [totalOpt, setTotalOpt] = useState();
  const [totalGraphData, setTotalData] = useState();
  const [dailyOpt, setDailyOpt] = useState();
  const [dailyGraphData, setDailyData] = useState();
  const [dailyCnt, setDailyCnt] = useState({
    used: 0,
    total: 0
  })
  const [loading, setLoading] = useState(true);

  const authCtx = useContext(AuthContext);

  const option = {
    method: 'GET',
    headers: {
      Authorization: authCtx.token
    }
  };

  useEffect(() => {
    ChartJS.register(
      LinearScale,
      CategoryScale,
      BarElement,
      PointElement,
      LineElement,
      ArcElement,
      Legend,
      Tooltip,
      Title,
      LineController,
      BarController,
    );
  }, []);

  useFetchData('/center/users/apiCallLogs', option, res_json => {
    const totalLabels = uniqBy(res_json.actionCnt.map(logs => logs.work_date), 'work_date');
    const groupByActs = groupBy(res_json.actionCnt, 'action_url');

    setTotalOpt({
      responsive: true,
      interaction: {
        mode: 'index',
      },
      plugins: {
        legend: {
          position: 'bottom',
        },
        title: {
          display: true,
          text: '액션별 API 호출 수',
          font: {
            size: 30,
          },
        },
        tooltip: {
          padding: 10,
          bodySpacing: 5,
          usePointStyle: true,
        }
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    });

    setTotalData({
      totalLabels,
      datasets: [
        {
          type: 'line',
          label: '전체',		//라인차트 데이터
          data: res_json.totalCnt.map(o => ({ x: o.work_date, y: o.cnt})),		 //날짜 label데이터 순서 출력할 라인 차트의 데이터 리스트
          borderColor: 'skyblue',
          backgroundColor: 'skyblue',
          borderWidth: 3,
          fill: false,
        },
        ...Object.values(groupByActs).map(act => {
          const randomColor = `#${Math.floor(Math.random() * 127 + 128).toString(16) + Math.floor(Math.random() * 127 + 128).toString(16) + Math.floor(Math.random() * 127 + 128).toString(16)}`;

          return {
            type: 'bar',
            label: act[0].action_url,
            data: act.map(a => ({ x: a.work_date, y: a.sumCnt })),
            borderColor: randomColor,
            backgroundColor: randomColor,
            barThickness: 25
          }
        })
      ]
    });

    setDailyCnt({
      used: res_json.dailyCallCnt[0],
      total: res_json.dailyCallCnt[1]
    })

    setDailyOpt({
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom',
        },
        title: {
          display: true,
          text: `${moment().format('YYYY-MM-DD')} API 호출 수`,
          font: {
            size: 30,
          },
        },
        tooltip: {
          padding: 10,
          bodySpacing: 5,
          usePointStyle: true
        },
        datalabels: {
          display: true,
          formatter: (value,ctx) => {
              let total = 0
              for(let i = 0 ;i<5; i++ ){
                  total += ctx.dataset.data[i]
              }
              let result = (value / total ) *100
              if(value === 0){
                  return '';
              }else{
                  return result.toFixed(1) + '%';
              }
          },
          color: '#fff',
          // backgroundColor: '#404040'
          weight: 'bold',
          textShadowBlur: 10,
          textShadowColor : 'black',
        },
        doughnutlabel: {
          labels: [{
            text: '0',
            font: {
              size: 20,
              weight: 'bold'
            }
          }, {
            text: 'total'
          }]
        }
      }
    });

    setDailyData({
      labels: ['호출 수', '남은 수'],
      datasets: [
        {
          label: '카운트',
          data: [ res_json.dailyCallCnt[0],res_json.dailyCallCnt[1] - res_json.dailyCallCnt[0] ],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)'
          ],
          borderWidth: 1,
          cutout: '65%',
        },
      ],
    });

    setLoading(false);
  }, null);

  return (
    <div className={classes.container}>
      <h2 className={classes.heading}>내 정보</h2>
      {
        loading ?  <Spin size="large" /> :
        <span className={classes.section}>
          {
            dailyCnt.total ? <div style={{width: '450px', marginLeft: '250px'}}>
            <Doughnut options={dailyOpt} data={dailyGraphData}
            plugins={[
              {
                beforeDraw(chart) {
                  const { width } = chart;
                  const { height } = chart;
                  const { ctx } = chart;
                  ctx.restore();
                  const fontSize = (height / 400).toFixed(2);
                  ctx.font = `${fontSize}em sans-serif`;
                  ctx.fillStyle= "#666666"
                  ctx.textBaseline = 'top';
                  const text = `${dailyCnt.used} / ${dailyCnt.total}`;
                  const textX = Math.round((width - ctx.measureText(text).width) / 2);
                  const textY = height / 2;
                  ctx.fillText(text, textX, textY);
                  ctx.save();
                },
              }]}/>
              <div style={{fontSize: '14px', color: 'red', textAlign: 'center'}}>※당일 호출량으로 실시간 반영이 되지 않을 수 있습니다.</div>
          </div> : <></>
          }
          <br/>
          <div className={classes.callAPIGrid}> <Chart type='bar' options={totalOpt} data={totalGraphData} /></div>
          <div style={{fontSize: '14px', color: 'red', textAlign: 'center'}}>※당일 호출량은 집계되지 않습니다.</div>
        </span>
      }
    </div>
  );
};

/*********************************************
 *     내 정보 수정 지원 시 아래 주석 해제 후 확인     *
 *********************************************/

// const MyPage = () => {
//   const [userInfo, setUserInfo] = useState([]);
//   const [changePw, changePwSet] = useState();
//   const [changePwModal, changePwModalSet] = useState();
//   const [loading, loadingSet] = useState(false);

//   const authCtx = useContext(AuthContext);

//   const option = {
//     method: 'GET',
//     headers: {
//       Authorization: authCtx.token
//     }
//   };
//   useFetchData('/center/users/mypage', option, res_json => setUserInfo(res_json), null);



//   const [formValues, handleInputUpdate, formValidity] = useFormValue({
//     company_name: { value: userInfo.company_name, regex: /.+/, label: '회사명', type: 'text' },
//     email: { value: userInfo.email, regex: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, placeholder: '이메일 형식으로 입력하세요', label: '이메일', type: 'text' },
//     name: { value: userInfo.name, regex: /.+/, label: '이름', type: 'text' },
//     tel: { value: userInfo.tel, regex: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3,4}[-\s.]?[0-9]{4,6}$/, placeholder: '전화번호 형식으로 입력하세요', label: '전화번호', type: 'text', optional: true },
//     htel: { value: userInfo.htel, regex: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3,4}[-\s.]?[0-9]{4,6}$/, placeholder: '전화번호 형식으로 입력하세요', label: '휴대전화번호', type: 'text' },
//     email_yn: { value: userInfo.email_yn },
//     sms_yn: { value: userInfo.sms_yn }
//   });

//   useEffect(() => {
//     handleInputUpdate('company_name', userInfo.company_name);
//     handleInputUpdate('name', userInfo.name);
//     handleInputUpdate('tel', userInfo.tel);
//     handleInputUpdate('htel', userInfo.htel);
//     handleInputUpdate('email', userInfo.email);
//     handleInputUpdate('email_yn', userInfo.email_yn);
//     handleInputUpdate('sms_yn', userInfo.sms_yn);
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [userInfo.company_name, userInfo.email, userInfo.email_yn, userInfo.htel, userInfo.name, userInfo.sms_yn, userInfo.tel]);
  
  
//   const newPwSet = (data) => {
//     changePwModalSet(false);
//     changePwSet(data);
//     Swal.fire('', '페이지 하단에서 [정보수정] 버튼을 눌러 저장하시면 변경된 비밀번호로 적용됩니다.',);
//   };

//   const handleSubmit = async e => {
//     e.preventDefault();
//     const parmas = {
//       company_name : formValues.company_name.value,
//       name: formValues.name.value,
//       tel: formValues.tel.value,
//       htel: formValues.htel.value,
//       password: changePw,
//       email_yn: formValues.email_yn.value === 1 || formValues.email_yn.value === true ? true : false,
//       sms_yn: formValues.sms_yn.value === 1 || formValues.sms_yn.value ===  true ? true : false,
//       m_no: userInfo.m_no
//     };

//     const requestOption = {
//       method: 'POST',
//       body: JSON.stringify(parmas),
//       headers: {
//         'Content-Type': 'application/json',
//       }
//     };

//     if (!(await Swal.fire({ title: '확인', text: '저장하시겠습니까?', showConfirmButton: true, showCancelButton: true })).value) {
//       return;
//     };

//     loadingSet(true);

//     await simpleSubmitHandler(loadingSet, '/center/users/modifyinfo', requestOption, res_json => {
//       if (res_json.success) {
//         Swal.fire('정보수정', '회원정보가 수정되었습니다', 'success');
//       } else {
//         Swal.fire('정보수정', '회원정보 수정 오류', 'error');
//       }
//     }, null);
    
//     loadingSet(false);
//   };
//   return (
//     <div className={classes.container}>
//       <div className="formTitle">기본정보</div>
//       <form>
//         <div className={classes.credentials}>
//           {['company_name', 'email', 'name', 'tel', 'htel'].map((item, key) => (
//           <ValidationInput
//             {...formValues[item]}
//             valid={null}
//             disabled={item === 'company_name' || item === 'email'}
//             onChange={e => handleInputUpdate(item, e.target.value)}
//             key={key}
//           />))}
//           <label>비밀번호</label>
//           <button type="button" onClick={() => { changePwModalSet(true); }}>수정</button>
//           <div className="formTitle">
//             동의내역변경<br/>
//             <span>플레이오토에서 제공하는 공지사항 안내 수신 동의 여부를 변경합니다.</span>
//           </div>
//           <div className={classes['checkbox-group']}>
//             <CheckboxAndLabel 
//               label='이메일 수신동의 여부'
//               onChange={e => handleInputUpdate('email_yn', e.target.checked)}
//               checked={formValues.email_yn.value}
//             />
//             <CheckboxAndLabel 
//               label='SMS 수신동의 여부'
//               onChange={e => handleInputUpdate('sms_yn', e.target.checked)}
//               checked={formValues.sms_yn.value}
//             />
//           </div>
//         </div>
//         <span className="btnArea">
//           <Spin spinning={loading}>
//             <button disabled={!formValidity} onClick={handleSubmit} >정보수정</button> <br />
//           </Spin>
//         </span>
//         {changePwModal &&
//           <><ModifyPwModal
//           closeModal={() => changePwModalSet(false)}
//           newPwSet={newPwSet} />
//         </>
//         }
//       </form>
//     </div>
//   );
// };

export default MyPage;