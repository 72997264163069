import { default_header, default_header_example } from './defaultData';

const data = (userInfo) => ({
  title: '상품',
  unrecognized_aff_no: [1051],
  children: [
    {
      title: '머리말 꼬리말 조회',
      url: '/doc/머리말 꼬리말 조회',
      data: [
        {
          version: 'v1.0',
          url: '/addcontents',
          desc: '사용중인 솔루션에 등록된 머리말꼬리말 목록을 조회합니다.',
          method: 'POST',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'search_key',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색조건
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>addcontent_no</code>
                          </strong>
                        </td>
                        <td>
                          <strong>템플릿코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>addcontent_name</code>
                          </strong>
                        </td>
                        <td>
                          <strong>머리말/꼬리말 템플릿명</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <strong>
                    <ul className='style'>
                      <li>
                        <code>값을 넣지않는경우 전체검색됩니다.</code>
                      </li>
                    </ul>
                  </strong>
                </div>
              ),
            },
            {
              title: 'search_word',
              required: '',
              type: 'String',
              desc: '검색어',
            },
            {
              title: 'addcontentTarget',
              required: '',
              type: 'String',
              desc: (
                <div>
                  템플릿 종류
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>상품명</code>
                          </strong>
                        </td>
                        <td>
                          <strong>상품명</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>상세설명</code>
                          </strong>
                        </td>
                        <td>
                          <strong>상세설명</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <strong>
                    <ul className='style'>
                      <li>
                        <code>값을 넣지않는경우 전체검색됩니다.</code>
                      </li>
                    </ul>
                  </strong>
                </div>
              ),
            },
            {
              title: 'addcontentType',
              required: '',
              type: 'String',
              desc: (
                <div>
                  템플릿 유형
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>공지사항</code>
                          </strong>
                        </td>
                        <td>
                          <strong>공지사항</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>베송지연</code>
                          </strong>
                        </td>
                        <td>
                          <strong>베송지연</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>이벤트</code>
                          </strong>
                        </td>
                        <td>
                          <strong>이벤트</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>기타</code>
                          </strong>
                        </td>
                        <td>
                          <strong>기타</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <strong>
                    <ul className='style'>
                      <li>
                        <code>값을 넣지않는경우 전체검색됩니다.</code>
                      </li>
                    </ul>
                  </strong>
                </div>
              ),
            },
            {
              title: 'viewPosition',
              required: '',
              type: 'String',
              desc: (
                <div>
                  템플릿 유형
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>머리말</code>
                          </strong>
                        </td>
                        <td>
                          <strong>머리말</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>꼬리말</code>
                          </strong>
                        </td>
                        <td>
                          <strong>꼬리말</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <strong>
                    <ul className='style'>
                      <li>
                        <code>값을 넣지않는경우 전체검색됩니다.</code>
                      </li>
                    </ul>
                  </strong>
                </div>
              ),
            },
          ],
          body_example: {
            search_key: '',
            search_word: '',
            addcontentTarget: '상세설명',
            addcontentType: '이벤트',
            viewPosition: '',
          },
          response: [
            {
              title: 'addcontent_no',
              required: 'O',
              type: 'Number',
              desc: '템플릿 코드',
            },
            {
              title: 'addcontentTarget',
              required: 'O',
              type: 'String',
              desc: '템플릿 종류',
            },
            {
              title: 'addcontent_type',
              required: 'O',
              type: 'String',
              desc: '템플릿 유형',
            },
            {
              title: 'wdate',
              required: 'O',
              type: 'String',
              desc: '등록일',
            },
            {
              title: 'mdate',
              required: 'O',
              type: 'String',
              desc: '수정일',
            },
            {
              title: 'addcontent_name',
              required: 'O',
              type: 'String',
              desc: '머리말꼬리말 템플릿명',
            },
            {
              title: 'header_content',
              required: 'O',
              type: 'String',
              desc: '머리말',
            },
            {
              title: 'footer_content',
              required: 'O',
              type: 'String',
              desc: '꼬리말',
            },
            {
              title: 'apply_cnt',
              required: 'O',
              type: 'String',
              desc: '적용상품 수',
            },
          ],
          response_example: {
            results: [
              {
                addcontent_no: 1561,
                addcontentTarget: '상세설명',
                addcontent_type: '이벤트',
                wdate: '2020-10-05 18:25:38',
                mdate: '2020-10-05 18:25:38',
                addcontent_name: '이벤트',
                header_content: '',
                footer_content: 'event!',
                apply_cnt: 0,
              },
            ],
          },
        },
      ],
    },
    {
      title: '상품리스트 조회',
      url: '/doc/상품리스트 조회',
      data: [
        {
          version: 'v1.2',
          url: '/product/online/list/v1.2',
          desc: (
            <div>
              <ul className='style'>
                <li>상품 {'>'} 상품 관리 리스트를 가져옵니다.</li>
                <li>기본 검색은 하나의 검색어만 지원가능합니다.</li>
                <li>
                  여러건의 검색을 원하시는경우 멀티검색(multi_search_word)을
                  이용해 주십시오.
                </li>
              </ul>
            </div>
          ),
          method: 'POST',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'start',
              required: '',
              type: 'Number',
              desc: '검색 시작 값',
            },
            {
              title: 'length',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  조회할 상품 갯수
                  <div className='description'>
                    Max value:{' '}
                    <strong>
                      <code>300</code>
                    </strong>
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>100</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'orderby',
              required: '',
              type: 'String',
              desc: '정렬',
            },
            {
              title: 'search_key',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색 키
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>sku_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_sale_name</code>
                          </strong>
                        </td>
                        <td>
                          <strong>온라인 상품명</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>c_sale_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>판매자관리코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>brand_code</code>
                          </strong>
                        </td>
                        <td>
                          <strong>브랜드코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>barcode</code>
                          </strong>
                        </td>
                        <td>
                          <strong>바코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>global_barcode</code>
                          </strong>
                        </td>
                        <td>
                          <strong>UPC,EAN 코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>isbn</code>
                          </strong>
                        </td>
                        <td>
                          <strong>ISBN코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>model</code>
                          </strong>
                        </td>
                        <td>
                          <strong>모델명</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>keyword</code>
                          </strong>
                        </td>
                        <td>
                          <strong>키워드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_sale_no</code>
                          </strong>
                        </td>
                        <td>
                          <strong>쇼핑몰 상품코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>memo</code>
                          </strong>
                        </td>
                        <td>
                          <strong>메모</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>std_ol_group_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>그룹상품코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_promotion_name</code>
                          </strong>
                        </td>
                        <td>
                          <strong>프로모션용 상품명</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <strong>
                    <ul className='style'>
                      <li>
                        <code>값을 넣지않는경우 전체검색됩니다.</code>
                      </li>
                    </ul>
                  </strong>
                </div>
              ),
            },
            {
              title: 'search_word',
              required: '',
              type: 'String',
              desc: '검색어',
            },
            {
              title: 'search_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>partial</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>exact</code>
                          </strong>
                        </td>
                        <td>
                          <strong>완전일치</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>partial</code>
                          </strong>
                        </td>
                        <td>
                          <strong>부분일치</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'date_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>등록일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>mdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>수정일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>sale_etime</code>
                          </strong>
                        </td>
                        <td>
                          <strong>판매종료일</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sdate',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  검색시작일
                  <div className='description'>(YYYY-MM-DD)</div>
                </div>
              ),
            },
            {
              title: 'edate',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  검색종료일
                  <div className='description'>(YYYY-MM-DD)</div>
                </div>
              ),
            },
            {
              title: 'sol_cate_no',
              required: '',
              type: 'String',
              desc: '솔루션 카테고리 코드',
            },
            {
              title: 'opt_yn',
              required: '',
              type: 'Boolean',
              desc: (
                <div>
                  옵션 사용여부
                  <div className='description'>
                    ["Y: 옵션이 있는 상품만 조회", "N": 옵션이 없는 상품만
                    조회"]
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <strong>
                    <ul className='style'>
                      <li>
                        <code>값을 넣지않는경우 전체검색됩니다.</code>
                      </li>
                    </ul>
                  </strong>
                </div>
              ),
            },
            {
              title: 'shop_cd',
              required: '',
              type: 'String',
              desc: (
                <div>
                  쇼핑몰코드
                  <div className='description'>
                    ( 옥션: A001, 지마켓: A006, 11번가: A112, 인터파크: A027...
                    )
                  </div>
                  <div className='description'>
                    마스터상품 조회시{' '}
                    <strong>
                      <code>only_mas</code>
                    </strong>{' '}
                    로 입력
                  </div>
                </div>
              ),
            },
            {
              title: 'template_no',
              required: '',
              type: 'String',
              desc: '템플릿코드',
            },
            {
              title: 'addcontent_no',
              required: '',
              type: 'String',
              desc: '머리말/꼬리말 템플릿코드',
            },
            {
              title: 'sale_status',
              required: '',
              type: 'Array',
              desc: (
                <div>
                  상품 판매 상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>판매대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>승인대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>반려</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매중</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>종료대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>수정대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매중지</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>일시품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'depot_no',
              required: '',
              type: 'String',
              desc: '배송처 코드',
            },
            {
              title: 'opt_status',
              required: '',
              type: 'String',
              desc: '옵션 상태',
            },
            {
              title: 'multi_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  멀티검색 키
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>c_sale_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>판매자관리코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_sale_no</code>
                          </strong>
                        </td>
                        <td>
                          <strong>쇼핑몰상품코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>sku_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop</code>
                          </strong>
                        </td>
                        <td>
                          <strong>쇼핑몰아이디</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'multi_search_word',
              required: '',
              type: 'String[]',
              desc: '멀티검색 값',
            },
            {
              title: 'view_master',
              required: '',
              type: 'Boolean',
              desc: (
                <div>
                  마스터상품 노출여부 (기본 노출함)
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>true</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
          ],
          body_example: {
            start: 0,
            length: 100,
            sdate: '2020-07-20',
            edate: '2022-12-31',
            date_type: 'wdate',
            search_key: 'all',
            search_type: 'partial',
            search_word: '',
            orderby: 'wdate desc',
            sale_status: ['판매중'],
            opt_yn: '',
            multi_type: 'c_sale_cd',
            multi_search_word: ['m20220914f60d00d1b', 'm20220902078de3813'],
            view_masteroptional: true,
          },
          response: [
            {
              title: 'results',
              required: 'O',
              type: 'Object',
              desc: '검색결과는 판매자관리코드로 그룹화되어 판매자관리코드를 키로 가진 배열로 출력됩니다.',
            },
            {
              title: 'sol_no',
              required: 'O',
              type: 'Number(8)',
              desc: '솔루션번호',
              indent: 1,
            },
            {
              title: 'ol_shop_no',
              required: 'O',
              type: 'Number(10)',
              desc: '상품고유값',
              indent: 1,
            },
            {
              title: 'c_sale_cd',
              required: 'O',
              type: 'String(40)',
              desc: '판매자관리코드',
              indent: 1,
            },
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String(4)',
              desc: '쇼핑몰 코드',
              indent: 1,
            },
            {
              title: 'shop_name',
              required: 'O',
              type: 'String(50)',
              desc: '쇼핑몰 명',
              indent: 1,
            },
            {
              title: 'shop_id',
              required: 'O',
              type: 'String(40)',
              desc: '쇼핑몰 계정 아이디',
              indent: 1,
            },
            {
              title: 'template_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  템플릿 번호
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_sale_name_addcontent_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  상품명 머리말/꼬리말 템플릿코드
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'addcontent_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  상세설명 머리말/꼬리말 템플릿코드
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_sale_name',
              required: 'O',
              type: 'String(200)',
              desc: '쇼핑몰상품명',
              indent: 1,
            },
            {
              title: 'sale_status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품 판매 상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>판매대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>승인대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>반려</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매중</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>종료대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>수정대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매중지</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>일시품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sale_cnt_limit',
              required: 'O',
              type: 'Number(10)',
              desc: '판매수량',
              indent: 1,
            },
            {
              title: 'sale_price',
              required: 'O',
              type: 'Number(12)',
              desc: '상품 금액',
              indent: 1,
            },
            {
              title: 'cost_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  상품 원가
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'supply_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  공급가
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'street_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  시중가
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'opt_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션 타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>옵션없음</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>조합형</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>독립형</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'input_opt_names',
              required: 'O',
              type: 'String[]',
              desc: '입력형옵션',
              indent: 1,
            },
            {
              title: 'tax_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  과세여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>과세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>면세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>영세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>비과세</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ship_price_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  배송비 종류
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>무료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>조건부무료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>착불</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>선결제</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ship_price',
              required: 'O',
              type: 'Number(8)',
              desc: '배송비 금액',
              indent: 1,
            },
            {
              title: 'shop_sale_no',
              required: 'O',
              type: 'String(40)',
              desc: '쇼핑몰 판매번호',
              indent: 1,
            },
            {
              title: 'sale_img1',
              required: 'O',
              type: 'String(230)',
              desc: '기본이미지',
              indent: 1,
            },
            {
              title: 'sale_img2',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 1',
              indent: 1,
            },
            {
              title: 'sale_img3',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 2',
              indent: 1,
            },
            {
              title: 'sale_img4',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 3',
              indent: 1,
            },
            {
              title: 'sale_img5',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 4',
              indent: 1,
            },
            {
              title: 'sale_img6',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 5',
              indent: 1,
            },
            {
              title: 'sale_img7',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 6',
              indent: 1,
            },
            {
              title: 'sale_img8',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 7',
              indent: 1,
            },
            {
              title: 'sale_img9',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 8',
              indent: 1,
            },
            {
              title: 'sale_img10',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 9',
              indent: 1,
            },
            {
              title: 'sale_img11',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 10',
              indent: 1,
            },
            {
              title: 'detail_desc',
              required: 'O',
              type: 'String(16777215)',
              desc: '상품 상세설명',
              indent: 1,
            },
            {
              title: 'keywords',
              required: 'O',
              type: 'String[]',
              desc: '키워드',
              indent: 1,
            },
            {
              title: 'model',
              required: 'O',
              type: 'String(50)',
              desc: '모델명',
              indent: 1,
            },
            {
              title: 'model_no',
              required: 'O',
              type: 'String(50)',
              desc: '모델 번호',
              indent: 1,
            },
            {
              title: 'brand',
              required: 'O',
              type: 'String(50)',
              desc: '브랜드',
              indent: 1,
            },
            {
              title: 'maker',
              required: 'O',
              type: 'String(50)',
              desc: '제조사',
              indent: 1,
            },
            {
              title: 'wdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  상품등록일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'mdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  상품수정일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'std_ol_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  단일상품 여부
                  <div className='description'>(0: 일반상품, 1: 단일상품)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'options',
              required: 'O',
              type: 'Object[]',
              desc: (
                <div>
                  옵션
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      옵션없음이라도 SKU상품이 매칭되어있는경우 하나의 데이터가
                      나옵니다.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sale_opt_seq',
              required: 'O',
              type: 'Number(5)',
              desc: '옵션순번',
              indent: 2,
            },
            {
              title: 'deal_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>일반</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>추가구매</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'opt_sort1',
              required: 'O',
              type: 'String(80)',
              desc: '옵션구분 1',
              indent: 2,
            },
            {
              title: 'opt_sort2',
              required: 'O',
              type: 'String(80)',
              desc: '옵션구분 2',
              indent: 2,
            },
            {
              title: 'opt_sort3',
              required: 'O',
              type: 'String(80)',
              desc: '옵션구분 3',
              indent: 2,
            },
            {
              title: 'opt_name1',
              required: 'O',
              type: 'String(80)',
              desc: '옵션값 1',
              indent: 2,
            },
            {
              title: 'opt_name2',
              required: 'O',
              type: 'String(80)',
              desc: (
                <div>
                  옵션값2
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'opt_name3',
              required: 'O',
              type: 'String(80)',
              desc: (
                <div>
                  옵션값3
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'opt_status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'add_item_name',
              required: 'O',
              type: 'String(80)',
              desc: '추구구매옵션명',
              indent: 2,
            },
            {
              title: 'add_item_opt_name',
              required: 'O',
              type: 'String(80)',
              desc: '추구구매옵션값',
              indent: 2,
            },
            {
              title: 'opt_add_price',
              required: 'O',
              type: 'Number(9)',
              desc: (
                <div>
                  옵션가격
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'prod_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  옵션에 매칭된 sku 상품 고유번호
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String(40)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품 sku코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'prod_name',
              required: 'O',
              type: 'String(200)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품명
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'stock_cd',
              required: 'O',
              type: 'String(100)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품 재고관리코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'stock_cnt',
              required: 'O',
              type: 'Number(9)',
              desc: '옵션수량',
              indent: 2,
            },
            {
              title: 'model_no',
              required: 'O',
              type: 'String(50)',
              desc: '모델 번호',
              indent: 2,
            },
            {
              title: 'pack_unit',
              required: 'O',
              type: 'Number(6)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품 출고수량
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'depot_no',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품 배송처
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'set_cd',
              required: 'O',
              type: 'String(40)',
              desc: (
                <div>
                  옵션에 매칭된 세트상품코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'set_name',
              required: 'O',
              type: 'String(200)',
              desc: (
                <div>
                  옵션에 매칭된 세트상품명
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'set_sale_price',
              required: 'O',
              type: 'String(9)',
              desc: (
                <div>
                  옵션에 매칭된 세트상품 가격
                  <div className='description'> (재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'prod_noti_data',
              required: 'O',
              type: 'Object',
              desc: (
                <div>
                  상품정보제공고시
                  <div className='description'>
                    상품분류코드에 따라 infoDetail 안에 키 값이 달라질 수
                    있습니다.
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'infoCode',
              required: 'O',
              type: 'String',
              desc: '상품분류코드',
              indent: 2,
            },
            {
              title: 'infoName',
              required: 'O',
              type: 'String',
              desc: '상품분류명',
              indent: 2,
            },
            {
              title: 'infoDetail',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시 데이터',
              indent: 2,
            },
            {
              title: 'ol_count',
              required: 'O',
              type: 'Number',
              desc: '검색된 마스터상품 수',
            },
            {
              title: 'normal_count',
              required: 'O',
              type: 'Number',
              desc: '검색된 일반상품 수',
            },
            {
              title: 'single_count',
              required: 'O',
              type: 'Number',
              desc: '검색된 단일상품 수',
            },
          ],
          response_example: {
            results: {
              m2022060881c9e7f86: [
                {
                  sol_no: 640,
                  ol_shop_no: 69224644,
                  c_sale_cd: 'm2022060881c9e7f86',
                  sol_cate_no: 301516,
                  shop_cd: 'Z000',
                  shop_name: '마스터 온라인상품',
                  shop_id: '',
                  template_no: 0,
                  shop_sale_name_addcontent_no: null,
                  addcontent_no: null,
                  shop_sale_name: '테스트 온라인 상품',
                  sale_status: '',
                  sale_cnt_limit: 99,
                  sale_price: 10000,
                  cost_price: 0,
                  supply_price: 0,
                  street_price: 0,
                  opt_type: '조합형',
                  input_opt_names: '',
                  tax_type: '과세',
                  ship_price_type: '무료',
                  ship_price: 0,
                  shop_sale_no: null,
                  sale_img1:
                    'https://s3-ap-northeast-2.amazonaws.com/gmp01/640/online/26070990/Z000/202111041549441.jpg',
                  sale_img2: '',
                  sale_img3: '',
                  sale_img4: '',
                  sale_img5: '',
                  sale_img6: '',
                  sale_img7: '',
                  sale_img8: '',
                  sale_img9: '',
                  sale_img10: '',
                  sale_img11: '',
                  detail_desc: '상세설명',
                  keywords: '',
                  model: '',
                  model_no: '',
                  brand: '',
                  maker: '',
                  wdate: '2022-06-08 19:31:16',
                  mdate: '2022-06-08 19:31:16',
                  std_ol_yn: 0,
                  options: [
                    {
                      sale_opt_seq: 1,
                      deal_type: '일반',
                      opt_sort1: '색상',
                      opt_sort2: '사이즈',
                      opt_sort3: '',
                      opt_name1: '블랙',
                      opt_name2: 'L',
                      opt_name3: '',
                      opt_status: '정상',
                      add_item_name: '',
                      add_item_opt_name: '',
                      opt_add_price: 0,
                      prod_no: 16967900,
                      sku_cd: 'U005-BK-L',
                      prod_name: '21.비비드',
                      stock_cd: '05.기모반목',
                      stock_cnt: 77,
                      model_no: '',
                      pack_unit: 1,
                      depot_no: 775,
                      set_cd: '',
                      set_name: '',
                      set_sale_price: 0,
                    },
                    {
                      sale_opt_seq: 2,
                      deal_type: '일반',
                      opt_sort1: '색상',
                      opt_sort2: '사이즈',
                      opt_sort3: '',
                      opt_name1: '하늘',
                      opt_name2: 'L',
                      opt_name3: '',
                      opt_status: '정상',
                      add_item_name: '',
                      add_item_opt_name: '',
                      opt_add_price: 0,
                      prod_no: 16967891,
                      sku_cd: 'U004-S-L',
                      prod_name: '19.크레오라',
                      stock_cd: '04.기모라운드티셔츠',
                      stock_cnt: 66,
                      model_no: '',
                      pack_unit: 1,
                      depot_no: 775,
                      set_cd: '',
                      set_name: '',
                      set_sale_price: 0,
                    },
                  ],
                  prod_noti_data: {
                    infoCode: 'Wear2023',
                    infoName: '의류',
                    infoDetail: {
                      material: '1',
                      color: '2',
                      size: '3',
                      manufacturer: '4',
                      made_in: '5',
                      caution: '6',
                      release: '7',
                      warranty: '8',
                      customer_service: '9',
                    },
                  },
                },
                {
                  sol_no: 640,
                  ol_shop_no: 81325911,
                  c_sale_cd: 'm2022060881c9e7f86',
                  sol_cate_no: 301516,
                  shop_cd: 'A001',
                  shop_name: '옥션',
                  shop_id: 'myshopy3',
                  template_no: 273155,
                  shop_sale_name_addcontent_no: null,
                  addcontent_no: null,
                  shop_sale_name: '테스트 온라인상품',
                  sale_status: '판매중',
                  sale_cnt_limit: 99,
                  sale_price: 10000,
                  cost_price: 0,
                  supply_price: 0,
                  street_price: 0,
                  opt_type: '조합형',
                  input_opt_names: '',
                  tax_type: '과세',
                  ship_price_type: '무료',
                  ship_price: 0,
                  shop_sale_no: 'C1335724',
                  sale_img1:
                    'https://s3-ap-northeast-2.amazonaws.com/gmp01/640/online/26070990/A001myshopy3/202111041549441.jpg',
                  sale_img2: '',
                  sale_img3: '',
                  sale_img4: '',
                  sale_img5: '',
                  sale_img6: '',
                  sale_img7: '',
                  sale_img8: '',
                  sale_img9: '',
                  sale_img10: '',
                  sale_img11: '',
                  detail_desc: '상세설명',
                  keywords: '',
                  model: '',
                  model_no: '',
                  brand: '',
                  maker: '',
                  wdate: '2022-08-18 11:20:41',
                  mdate: '2022-08-18 15:44:32',
                  std_ol_yn: 0,
                  options: [
                    {
                      sale_opt_seq: 1,
                      deal_type: '일반',
                      opt_sort1: '색상',
                      opt_sort2: '',
                      opt_sort3: '',
                      opt_name1: '빨강',
                      opt_name2: '',
                      opt_name3: '',
                      opt_status: '정상',
                      add_item_name: '',
                      add_item_opt_name: '',
                      opt_add_price: 0,
                      prod_no: 0,
                      sku_cd: '',
                      prod_name: '',
                      stock_cd: '',
                      stock_cnt: 77,
                      model_no: '',
                      pack_unit: 0,
                      depot_no: 0,
                      set_cd: '',
                      set_name: '',
                      set_sale_price: 0,
                    },
                    {
                      sale_opt_seq: 2,
                      deal_type: '일반',
                      opt_sort1: '색상',
                      opt_sort2: '',
                      opt_sort3: '',
                      opt_name1: '파랑',
                      opt_name2: '',
                      opt_name3: '',
                      opt_status: '정상',
                      add_item_name: '',
                      add_item_opt_name: '',
                      opt_add_price: 0,
                      prod_no: 0,
                      sku_cd: '',
                      prod_name: '',
                      stock_cd: '',
                      stock_cnt: 88,
                      model_no: '',
                      pack_unit: 0,
                      depot_no: 0,
                      set_cd: '',
                      set_name: '',
                      set_sale_price: 0,
                    },
                  ],
                  prod_noti_data: {
                    infoCode: 'Wear2023',
                    infoName: '의류',
                    infoDetail: {
                      material: '1',
                      color: '2',
                      size: '3',
                      manufacturer: '4',
                      made_in: '5',
                      caution: '6',
                      release: '7',
                      warranty: '8',
                      customer_service: '9',
                    },
                  },
                },
              ],
            },
            ol_count: 1,
            normal_count: 1,
            single_count: 0,
          },
        },
        {
          version: 'v1.1',
          url: '/product/online/list/v1.1',
          desc: (
            <div>
              <ul className='style'>
                <li>상품 {'>'} 상품 관리 리스트를 가져옵니다.</li>
                <li>기본 검색은 하나의 검색어만 지원가능합니다.</li>
                <li>
                  여러건의 검색을 원하시는경우 멀티검색(multi_search_word)을
                  이용해 주십시오.
                </li>
              </ul>
            </div>
          ),
          method: 'POST',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'start',
              required: '',
              type: 'Number',
              desc: '검색 시작 값',
            },
            {
              title: 'length',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  조회할 상품 갯수
                  <div className='description'>
                    Max value:{' '}
                    <strong>
                      <code>300</code>
                    </strong>
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>100</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'orderby',
              required: '',
              type: 'String',
              desc: '정렬',
            },
            {
              title: 'search_key',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색 키
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>sku_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_sale_name</code>
                          </strong>
                        </td>
                        <td>
                          <strong>온라인 상품명</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>c_sale_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>판매자관리코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>brand_code</code>
                          </strong>
                        </td>
                        <td>
                          <strong>브랜드코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>barcode</code>
                          </strong>
                        </td>
                        <td>
                          <strong>바코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>global_barcode</code>
                          </strong>
                        </td>
                        <td>
                          <strong>UPC,EAN 코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>isbn</code>
                          </strong>
                        </td>
                        <td>
                          <strong>ISBN코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>model</code>
                          </strong>
                        </td>
                        <td>
                          <strong>모델명</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>keyword</code>
                          </strong>
                        </td>
                        <td>
                          <strong>키워드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_sale_no</code>
                          </strong>
                        </td>
                        <td>
                          <strong>쇼핑몰 상품코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>memo</code>
                          </strong>
                        </td>
                        <td>
                          <strong>메모</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>std_ol_group_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>그룹상품코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_promotion_name</code>
                          </strong>
                        </td>
                        <td>
                          <strong>프로모션용 상품명</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <strong>
                    <ul className='style'>
                      <li>
                        <code>값을 넣지않는경우 전체검색됩니다.</code>
                      </li>
                    </ul>
                  </strong>
                </div>
              ),
            },
            {
              title: 'search_word',
              required: '',
              type: 'String',
              desc: '검색어',
            },
            {
              title: 'search_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>partial</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>exact</code>
                          </strong>
                        </td>
                        <td>
                          <strong>완전일치</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>partial</code>
                          </strong>
                        </td>
                        <td>
                          <strong>부분일치</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'date_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>등록일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>mdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>수정일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>sale_etime</code>
                          </strong>
                        </td>
                        <td>
                          <strong>판매종료일</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sdate',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  검색시작일
                  <div className='description'>(YYYY-MM-DD)</div>
                </div>
              ),
            },
            {
              title: 'edate',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  검색종료일
                  <div className='description'>(YYYY-MM-DD)</div>
                </div>
              ),
            },
            {
              title: 'sol_cate_no',
              required: '',
              type: 'String',
              desc: '솔루션 카테고리 코드',
            },
            {
              title: 'opt_yn',
              required: '',
              type: 'Boolean',
              desc: (
                <div>
                  옵션 사용여부
                  <div className='description'>
                    ["Y: 옵션이 있는 상품만 조회", "N": 옵션이 없는 상품만
                    조회"]
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <strong>
                    <ul className='style'>
                      <li>
                        <code>값을 넣지않는경우 전체검색됩니다.</code>
                      </li>
                    </ul>
                  </strong>
                </div>
              ),
            },
            {
              title: 'shop_cd',
              required: '',
              type: 'String',
              desc: (
                <div>
                  쇼핑몰코드
                  <div className='description'>
                    ( 옥션: A001, 지마켓: A006, 11번가: A112, 인터파크: A027...
                    )
                  </div>
                  <div className='description'>
                    마스터상품 조회시{' '}
                    <strong>
                      <code>only_mas</code>
                    </strong>{' '}
                    로 입력
                  </div>
                </div>
              ),
            },
            {
              title: 'template_no',
              required: '',
              type: 'String',
              desc: '템플릿코드',
            },
            {
              title: 'addcontent_no',
              required: '',
              type: 'String',
              desc: '머리말/꼬리말 템플릿코드',
            },
            {
              title: 'sale_status',
              required: '',
              type: 'Array',
              desc: (
                <div>
                  상품 판매 상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>판매대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>승인대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>반려</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매중</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>종료대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>수정대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매중지</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>일시품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'depot_no',
              required: '',
              type: 'String',
              desc: '배송처 코드',
            },
            {
              title: 'opt_status',
              required: '',
              type: 'String',
              desc: '옵션 상태',
            },
            {
              title: 'multi_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  멀티검색 키
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>c_sale_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>판매자관리코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_sale_no</code>
                          </strong>
                        </td>
                        <td>
                          <strong>쇼핑몰상품코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>sku_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop</code>
                          </strong>
                        </td>
                        <td>
                          <strong>쇼핑몰아이디</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'multi_search_word',
              required: '',
              type: 'String[]',
              desc: '멀티검색 값',
            },
            {
              title: 'view_master',
              required: '',
              type: 'Boolean',
              desc: (
                <div>
                  마스터상품 노출여부 (기본 노출함)
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>true</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
          ],
          body_example: {
            start: 0,
            length: 100,
            sdate: '2020-07-20',
            edate: '2022-12-31',
            date_type: 'wdate',
            search_key: 'all',
            search_type: 'partial',
            search_word: '',
            orderby: 'wdate desc',
            sale_status: ['판매중'],
            opt_yn: '',
            multi_type: 'c_sale_cd',
            multi_search_word: ['m20220914f60d00d1b', 'm20220902078de3813'],
            view_masteroptional: true,
          },
          response: [
            {
              title: 'results',
              required: 'O',
              type: 'Object',
              desc: '검색결과는 판매자관리코드로 그룹화되어 판매자관리코드를 키로 가진 배열로 출력됩니다.',
            },
            {
              title: 'sol_no',
              required: 'O',
              type: 'Number(8)',
              desc: '솔루션번호',
              indent: 1,
            },
            {
              title: 'ol_shop_no',
              required: 'O',
              type: 'Number(10)',
              desc: '상품고유값',
              indent: 1,
            },
            {
              title: 'c_sale_cd',
              required: 'O',
              type: 'String(40)',
              desc: '판매자관리코드',
              indent: 1,
            },
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String(4)',
              desc: '쇼핑몰 코드',
              indent: 1,
            },
            {
              title: 'shop_name',
              required: 'O',
              type: 'String(50)',
              desc: '쇼핑몰 명',
              indent: 1,
            },
            {
              title: 'shop_id',
              required: 'O',
              type: 'String(40)',
              desc: '쇼핑몰 계정 아이디',
              indent: 1,
            },
            {
              title: 'template_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  템플릿 번호
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_sale_name_addcontent_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  상품명 머리말/꼬리말 템플릿코드
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'addcontent_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  상세설명 머리말/꼬리말 템플릿코드
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_sale_name',
              required: 'O',
              type: 'String(200)',
              desc: '쇼핑몰상품명',
              indent: 1,
            },
            {
              title: 'sale_status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품 판매 상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>판매대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>승인대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>반려</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매중</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>종료대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>수정대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매중지</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>일시품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sale_cnt_limit',
              required: 'O',
              type: 'Number(10)',
              desc: '판매수량',
              indent: 1,
            },
            {
              title: 'sale_price',
              required: 'O',
              type: 'Number(12)',
              desc: '상품 금액',
              indent: 1,
            },
            {
              title: 'cost_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  상품 원가
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'supply_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  공급가
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'street_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  시중가
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'opt_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션 타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>옵션없음</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>조합형</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>독립형</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'input_opt_names',
              required: 'O',
              type: 'String[]',
              desc: '입력형옵션',
              indent: 1,
            },
            {
              title: 'tax_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  과세여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>과세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>면세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>영세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>비과세</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ship_price_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  배송비 종류
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>무료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>조건부무료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>착불</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>선결제</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ship_price',
              required: 'O',
              type: 'Number(8)',
              desc: '배송비 금액',
              indent: 1,
            },
            {
              title: 'shop_sale_no',
              required: 'O',
              type: 'String(40)',
              desc: '쇼핑몰 판매번호',
              indent: 1,
            },
            {
              title: 'sale_img1',
              required: 'O',
              type: 'String(230)',
              desc: '기본이미지',
              indent: 1,
            },
            {
              title: 'sale_img2',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 1',
              indent: 1,
            },
            {
              title: 'sale_img3',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 2',
              indent: 1,
            },
            {
              title: 'sale_img4',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 3',
              indent: 1,
            },
            {
              title: 'sale_img5',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 4',
              indent: 1,
            },
            {
              title: 'sale_img6',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 5',
              indent: 1,
            },
            {
              title: 'sale_img7',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 6',
              indent: 1,
            },
            {
              title: 'sale_img8',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 7',
              indent: 1,
            },
            {
              title: 'sale_img9',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 8',
              indent: 1,
            },
            {
              title: 'sale_img10',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 9',
              indent: 1,
            },
            {
              title: 'sale_img11',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 10',
              indent: 1,
            },
            {
              title: 'detail_desc',
              required: 'O',
              type: 'String(16777215)',
              desc: '상품 상세설명',
              indent: 1,
            },
            {
              title: 'keywords',
              required: 'O',
              type: 'String[]',
              desc: '키워드',
              indent: 1,
            },
            {
              title: 'model',
              required: 'O',
              type: 'String(50)',
              desc: '모델명',
              indent: 1,
            },
            {
              title: 'model_no',
              required: 'O',
              type: 'String(50)',
              desc: '모델 번호',
              indent: 1,
            },
            {
              title: 'brand',
              required: 'O',
              type: 'String(50)',
              desc: '브랜드',
              indent: 1,
            },
            {
              title: 'maker',
              required: 'O',
              type: 'String(50)',
              desc: '제조사',
              indent: 1,
            },
            {
              title: 'wdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  상품등록일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'mdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  상품수정일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'std_ol_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  단일상품 여부
                  <div className='description'>(0: 일반상품, 1: 단일상품)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'options',
              required: 'O',
              type: 'Object[]',
              desc: (
                <div>
                  옵션
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      옵션없음이라도 SKU상품이 매칭되어있는경우 하나의 데이터가
                      나옵니다.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sale_opt_seq',
              required: 'O',
              type: 'Number(5)',
              desc: '옵션순번',
              indent: 2,
            },
            {
              title: 'deal_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>일반</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>추가구매</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'opt_sort1',
              required: 'O',
              type: 'String(80)',
              desc: '옵션구분 1',
              indent: 2,
            },
            {
              title: 'opt_sort2',
              required: 'O',
              type: 'String(80)',
              desc: '옵션구분 2',
              indent: 2,
            },
            {
              title: 'opt_sort3',
              required: 'O',
              type: 'String(80)',
              desc: '옵션구분 3',
              indent: 2,
            },
            {
              title: 'opt_name1',
              required: 'O',
              type: 'String(80)',
              desc: '옵션값 1',
              indent: 2,
            },
            {
              title: 'opt_name2',
              required: 'O',
              type: 'String(80)',
              desc: (
                <div>
                  옵션값2
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'opt_name3',
              required: 'O',
              type: 'String(80)',
              desc: (
                <div>
                  옵션값3
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'opt_status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'add_item_name',
              required: 'O',
              type: 'String(80)',
              desc: '추구구매옵션명',
              indent: 2,
            },
            {
              title: 'add_item_opt_name',
              required: 'O',
              type: 'String(80)',
              desc: '추구구매옵션값',
              indent: 2,
            },
            {
              title: 'opt_add_price',
              required: 'O',
              type: 'Number(9)',
              desc: (
                <div>
                  옵션가격
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'prod_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  옵션에 매칭된 sku 상품 고유번호
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String(40)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품 sku코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'prod_name',
              required: 'O',
              type: 'String(200)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품명
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'stock_cd',
              required: 'O',
              type: 'String(100)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품 재고관리코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'stock_cnt',
              required: 'O',
              type: 'Number(9)',
              desc: '옵션수량',
              indent: 2,
            },
            {
              title: 'model_no',
              required: 'O',
              type: 'String(50)',
              desc: '모델 번호',
              indent: 2,
            },
            {
              title: 'pack_unit',
              required: 'O',
              type: 'Number(6)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품 출고수량
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'depot_no',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품 배송처
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'set_cd',
              required: 'O',
              type: 'String(40)',
              desc: (
                <div>
                  옵션에 매칭된 세트상품코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'set_name',
              required: 'O',
              type: 'String(200)',
              desc: (
                <div>
                  옵션에 매칭된 세트상품명
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'set_sale_price',
              required: 'O',
              type: 'String(9)',
              desc: (
                <div>
                  옵션에 매칭된 세트상품 가격
                  <div className='description'> (재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'prod_noti_data',
              required: 'O',
              type: 'Object',
              desc: (
                <div>
                  상품정보제공고시
                  <div className='description'>
                    prod_info_1, prod_info_2 등은 상품정보 제공고시 타입이 따라
                    갯수가 바뀔수 있음.
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'infoCode',
              required: 'O',
              type: 'String(20)',
              desc: '상품분류코드',
              indent: 2,
            },
            {
              title: 'infoName',
              required: 'O',
              type: 'String',
              desc: '상품분류명',
              indent: 2,
            },
            {
              title: 'prod_info_1',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시 1',
              indent: 2,
            },
            {
              title: 'prod_info_2',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시 2',
              indent: 2,
            },
            {
              title: 'prod_info_3',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시 3',
              indent: 2,
            },
            {
              title: 'prod_info_4',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시 4',
              indent: 2,
            },
            {
              title: 'prod_info_5',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시 5',
              indent: 2,
            },
            {
              title: 'ol_count',
              required: 'O',
              type: 'Number',
              desc: '검색된 마스터상품 수',
            },
            {
              title: 'normal_count',
              required: 'O',
              type: 'Number',
              desc: '검색된 일반상품 수',
            },
            {
              title: 'single_count',
              required: 'O',
              type: 'Number',
              desc: '검색된 단일상품 수',
            },
          ],
          response_example: {
            results: {
              m2022060881c9e7f86: [
                {
                  sol_no: 640,
                  ol_shop_no: 69224644,
                  c_sale_cd: 'm2022060881c9e7f86',
                  sol_cate_no: 301516,
                  shop_cd: 'Z000',
                  shop_name: '마스터 온라인상품',
                  shop_id: '',
                  template_no: 0,
                  shop_sale_name_addcontent_no: null,
                  addcontent_no: null,
                  shop_sale_name: '테스트 온라인 상품',
                  sale_status: '',
                  sale_cnt_limit: 99,
                  sale_price: 10000,
                  cost_price: 0,
                  supply_price: 0,
                  street_price: 0,
                  opt_type: '조합형',
                  input_opt_names: '',
                  tax_type: '과세',
                  ship_price_type: '무료',
                  ship_price: 0,
                  shop_sale_no: null,
                  sale_img1:
                    'https://s3-ap-northeast-2.amazonaws.com/gmp01/640/online/26070990/Z000/202111041549441.jpg',
                  sale_img2: '',
                  sale_img3: '',
                  sale_img4: '',
                  sale_img5: '',
                  sale_img6: '',
                  sale_img7: '',
                  sale_img8: '',
                  sale_img9: '',
                  sale_img10: '',
                  sale_img11: '',
                  detail_desc: '상세설명',
                  keywords: '',
                  model: '',
                  model_no: '',
                  brand: '',
                  maker: '',
                  wdate: '2022-06-08 19:31:16',
                  mdate: '2022-06-08 19:31:16',
                  std_ol_yn: 0,
                  options: [
                    {
                      sale_opt_seq: 1,
                      deal_type: '일반',
                      opt_sort1: '색상',
                      opt_sort2: '사이즈',
                      opt_sort3: '',
                      opt_name1: '블랙',
                      opt_name2: 'L',
                      opt_name3: '',
                      opt_status: '정상',
                      add_item_name: '',
                      add_item_opt_name: '',
                      opt_add_price: 0,
                      prod_no: 16967900,
                      sku_cd: 'U005-BK-L',
                      prod_name: '21.비비드',
                      stock_cd: '05.기모반목',
                      stock_cnt: 77,
                      model_no: '',
                      pack_unit: 1,
                      depot_no: 775,
                      set_cd: '',
                      set_name: '',
                      set_sale_price: 0,
                    },
                    {
                      sale_opt_seq: 2,
                      deal_type: '일반',
                      opt_sort1: '색상',
                      opt_sort2: '사이즈',
                      opt_sort3: '',
                      opt_name1: '하늘',
                      opt_name2: 'L',
                      opt_name3: '',
                      opt_status: '정상',
                      add_item_name: '',
                      add_item_opt_name: '',
                      opt_add_price: 0,
                      prod_no: 16967891,
                      sku_cd: 'U004-S-L',
                      prod_name: '19.크레오라',
                      stock_cd: '04.기모라운드티셔츠',
                      stock_cnt: 66,
                      model_no: '',
                      pack_unit: 1,
                      depot_no: 775,
                      set_cd: '',
                      set_name: '',
                      set_sale_price: 0,
                    },
                  ],
                  prod_noti_data: {
                    infoCode: '01',
                    infoName: '의류',
                    prod_info_1: '[상세설명참조]',
                    prod_info_2: 'N',
                    prod_info_3: '[상세설명참조]',
                    prod_info_4: '[상세설명참조]',
                    prod_info_5: '[상세설명참조]',
                    prod_info_6: 'N',
                    prod_info_7: '[상세설명참조]',
                    prod_info_8: '[상세설명참조]',
                    prod_info_9: '[상세설명참조]',
                    prod_info_10: '[상세설명참조]',
                    prod_info_11: '[상세설명참조]',
                    prod_info_12: '[상세설명참조]',
                    prod_info_13: '[상세설명참조]',
                  },
                },
                {
                  sol_no: 640,
                  ol_shop_no: 81325911,
                  c_sale_cd: 'm2022060881c9e7f86',
                  sol_cate_no: 301516,
                  shop_cd: 'A001',
                  shop_name: '옥션',
                  shop_id: 'myshopy3',
                  template_no: 273155,
                  shop_sale_name_addcontent_no: null,
                  addcontent_no: null,
                  shop_sale_name: '테스트 온라인상품',
                  sale_status: '판매중',
                  sale_cnt_limit: 99,
                  sale_price: 10000,
                  cost_price: 0,
                  supply_price: 0,
                  street_price: 0,
                  opt_type: '조합형',
                  input_opt_names: '',
                  tax_type: '과세',
                  ship_price_type: '무료',
                  ship_price: 0,
                  shop_sale_no: 'C1335724',
                  sale_img1:
                    'https://s3-ap-northeast-2.amazonaws.com/gmp01/640/online/26070990/A001myshopy3/202111041549441.jpg',
                  sale_img2: '',
                  sale_img3: '',
                  sale_img4: '',
                  sale_img5: '',
                  sale_img6: '',
                  sale_img7: '',
                  sale_img8: '',
                  sale_img9: '',
                  sale_img10: '',
                  sale_img11: '',
                  detail_desc: '상세설명',
                  keywords: '',
                  model: '',
                  model_no: '',
                  brand: '',
                  maker: '',
                  wdate: '2022-08-18 11:20:41',
                  mdate: '2022-08-18 15:44:32',
                  std_ol_yn: 0,
                  options: [
                    {
                      sale_opt_seq: 1,
                      deal_type: '일반',
                      opt_sort1: '색상',
                      opt_sort2: '',
                      opt_sort3: '',
                      opt_name1: '빨강',
                      opt_name2: '',
                      opt_name3: '',
                      opt_status: '정상',
                      add_item_name: '',
                      add_item_opt_name: '',
                      opt_add_price: 0,
                      prod_no: 0,
                      sku_cd: '',
                      prod_name: '',
                      stock_cd: '',
                      stock_cnt: 77,
                      model_no: '',
                      pack_unit: 0,
                      depot_no: 0,
                      set_cd: '',
                      set_name: '',
                      set_sale_price: 0,
                    },
                    {
                      sale_opt_seq: 2,
                      deal_type: '일반',
                      opt_sort1: '색상',
                      opt_sort2: '',
                      opt_sort3: '',
                      opt_name1: '파랑',
                      opt_name2: '',
                      opt_name3: '',
                      opt_status: '정상',
                      add_item_name: '',
                      add_item_opt_name: '',
                      opt_add_price: 0,
                      prod_no: 0,
                      sku_cd: '',
                      prod_name: '',
                      stock_cd: '',
                      stock_cnt: 88,
                      model_no: '',
                      pack_unit: 0,
                      depot_no: 0,
                      set_cd: '',
                      set_name: '',
                      set_sale_price: 0,
                    },
                  ],
                  prod_noti_data: {
                    infoCode: '01',
                    infoName: '의류',
                    prod_info_1: '[상세설명참조]',
                    prod_info_2: 'N',
                    prod_info_3: '[상세설명참조]',
                    prod_info_4: '[상세설명참조]',
                    prod_info_5: '[상세설명참조]',
                    prod_info_6: 'N',
                    prod_info_7: '[상세설명참조]',
                    prod_info_8: '[상세설명참조]',
                    prod_info_9: '[상세설명참조]',
                    prod_info_10: '[상세설명참조]',
                    prod_info_11: '[상세설명참조]',
                    prod_info_12: '[상세설명참조]',
                    prod_info_13: '[상세설명참조]',
                  },
                },
              ],
            },
            ol_count: 1,
            normal_count: 1,
            single_count: 0,
          },
        },
        {
          version: 'v1.0',
          url: '/product/online/list',
          desc: '상품 > 상품 관리 리스트를 가져옵니다.',
          method: 'POST',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'start',
              required: '',
              type: 'Number',
              desc: '검색 시작 값',
            },
            {
              title: 'length',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  조회할 상품 갯수
                  <div className='description'>
                    Max value:{' '}
                    <strong>
                      <code>1500</code>
                    </strong>
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>100</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'orderby',
              required: '',
              type: 'String',
              desc: '정렬',
            },
            {
              title: 'search_key',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색 키
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>sku_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_sale_name</code>
                          </strong>
                        </td>
                        <td>
                          <strong>온라인 상품명</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>c_sale_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>판매자관리코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>brand_code</code>
                          </strong>
                        </td>
                        <td>
                          <strong>브랜드코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>barcode</code>
                          </strong>
                        </td>
                        <td>
                          <strong>바코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>global_barcode</code>
                          </strong>
                        </td>
                        <td>
                          <strong>UPC,EAN 코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>isbn</code>
                          </strong>
                        </td>
                        <td>
                          <strong>ISBN코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>model</code>
                          </strong>
                        </td>
                        <td>
                          <strong>모델명</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>keyword</code>
                          </strong>
                        </td>
                        <td>
                          <strong>키워드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_sale_no</code>
                          </strong>
                        </td>
                        <td>
                          <strong>쇼핑몰 상품코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>memo</code>
                          </strong>
                        </td>
                        <td>
                          <strong>메모</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>std_ol_group_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>그룹상품코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_promotion_name</code>
                          </strong>
                        </td>
                        <td>
                          <strong>프로모션용 상품명</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <strong>
                    <ul className='style'>
                      <li>
                        <code>값을 넣지않는경우 전체검색됩니다.</code>
                      </li>
                    </ul>
                  </strong>
                </div>
              ),
            },
            {
              title: 'search_word',
              required: '',
              type: 'String',
              desc: '검색어',
            },
            {
              title: 'search_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>partial</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>exact</code>
                          </strong>
                        </td>
                        <td>
                          <strong>완전일치</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>partial</code>
                          </strong>
                        </td>
                        <td>
                          <strong>부분일치</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'date_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  날짜검색타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>등록일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>mdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>수정일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>sale_etime</code>
                          </strong>
                        </td>
                        <td>
                          <strong>판매종료일</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sdate',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  검색시작일
                  <div className='description'>(YYYY-MM-DD)</div>
                </div>
              ),
            },
            {
              title: 'edate',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  검색종료일
                  <div className='description'>(YYYY-MM-DD)</div>
                </div>
              ),
            },
            {
              title: 'sol_cate_no',
              required: '',
              type: 'String',
              desc: '솔루션 카테고리 코드',
            },
            {
              title: 'opt_yn',
              required: '',
              type: 'String',
              desc: '옵션 사용여부',
            },
            {
              title: 'shop_cd',
              required: '',
              type: 'String',
              desc: (
                <div>
                  쇼핑몰코드
                  <div className='description'>
                    ( 옥션: A001, 지마켓: A006, 11번가: A112, 인터파크: A027...
                    )<br />
                    마스터상품 조회시{' '}
                    <strong>
                      <code>only_mas</code>
                    </strong>
                    로 입력
                  </div>
                </div>
              ),
            },
            {
              title: 'shop_id',
              required: '',
              type: 'String',
              desc: '쇼핑몰아이디',
            },
            {
              title: 'template_no',
              required: '',
              type: 'String',
              desc: '템플릿코드',
            },
            {
              title: 'addcontent_no',
              required: '',
              type: 'String',
              desc: '머리말/꼬리말 템플릿코드',
            },
            {
              title: 'sale_status',
              required: '',
              type: 'Array',
              desc: (
                <div>
                  상품 판매 상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>판매대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>승인대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>반려</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매중</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>종료대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>수정대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매중지</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>일시품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'depot_no',
              required: '',
              type: 'String',
              desc: '배송처 코드',
            },
            {
              title: 'opt_status',
              required: '',
              type: 'String',
              desc: '옵션 상태',
            },
            {
              title: 'multi_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  멀티검색 키
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>c_sale_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>판매자관리코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_sale_no</code>
                          </strong>
                        </td>
                        <td>
                          <strong>쇼핑몰상품코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>sku_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop</code>
                          </strong>
                        </td>
                        <td>
                          <strong>쇼핑몰아이디</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'multi_search_word',
              required: '',
              type: 'String',
              desc: (
                <div>
                  멀티검색어
                  <div className='description'>
                    (\n으로 묶어서 전송) ex{'>'} 하나\n둘\n셋\n넷
                  </div>
                </div>
              ),
            },
          ],
          body_example: {
            start: 0,
            length: 100,
            sdate: '2020-07-20',
            edate: '2020-07-20',
            date_type: 'wdate',
            multi_shop_id: '',
            multi_type: 'c_sale_cd',
            search_key: 'all',
            search_type: 'partial',
            search_word: '',
            orderby: 'wdate desc',
            brand_code: '',
            catrgory_code: '',
            onlyNormal: true,
            prod_type: '',
            sale_status: '',
            std_ol_type: 'normal',
            memo_yn: '',
            template_yn: '',
            wid: '',
          },
          response: [
            {
              title: 'results',
              required: 'O',
              type: 'Object',
              desc: '검색결과는 판매자관리코드로 그룹화되어 판매자관리코드를 키로 가진 배열로 출력됩니다.',
            },
            {
              title: 'sol_no',
              required: 'O',
              type: 'Number(8)',
              desc: '솔루션번호',
              indent: 1,
            },
            {
              title: 'ol_shop_no',
              required: 'O',
              type: 'Number(10)',
              desc: '상품고유값',
              indent: 1,
            },
            {
              title: 'sale_no',
              required: 'O',
              type: 'Number(11)',
              desc: '판매번호',
              indent: 1,
            },
            {
              title: 'c_sale_cd',
              required: 'O',
              type: 'String(40)',
              desc: '판매자관리코드',
              indent: 1,
            },
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String(4)',
              desc: '쇼핑몰코드',
              indent: 1,
            },
            {
              title: 'pa_shop_cd',
              required: 'O',
              type: 'String(4)',
              desc: '상위 쇼핑몰코드',
              indent: 1,
            },
            {
              title: 'shop_name',
              required: 'O',
              type: 'String(50)',
              desc: '쇼핑몰명',
              indent: 1,
            },
            {
              title: 'wdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  상품등록일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'mdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  상품수정일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_id',
              required: 'O',
              type: 'String(40)',
              desc: '쇼핑몰 계정',
              indent: 1,
            },

            {
              title: 'std_ol_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  단일상품 여부
                  <div className='description'>(0: 일반상품, 1: 단일상품)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'std_ol_group_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  그룹고유값
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'std_ol_group_cd',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  그룹 코드
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'std_ol_group_name',
              required: 'O',
              type: 'String(50)',
              desc: (
                <div>
                  그룹 명
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'std_ol_group_seq',
              required: 'O',
              type: 'Number(3)',
              desc: (
                <div>
                  그룹 내 상품 순번
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_sale_name',
              required: 'O',
              type: 'String(200)',
              desc: '상품명',
              indent: 1,
            },
            {
              title: 'shop_promotion_name',
              required: 'O',
              type: 'String(100)',
              desc: '상품 프로모션명',
              indent: 1,
            },
            {
              title: 'opt_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션 타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>옵션없음</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>조합형</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>독립형</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sale_status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션 타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>판매대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>승인대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>반려</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매중</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>종료대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>수정대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매중지</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>일시품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_sale_no',
              required: 'O',
              type: 'Number(40)',
              desc: (
                <div>
                  쇼핑몰 판매번호
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_sale_no_sub',
              required: 'O',
              type: 'Number(80)',
              desc: (
                <div>
                  쇼핑몰 판매번호 서브
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sale_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  상품금액
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cost_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  상품 원가
                  <div className='description'>(data type: Decimal(12,2))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'supply_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  공급가
                  <div className='description'>(data type: Decimal(12,2))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'street_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  시중가
                  <div className='description'>(data type: Decimal(12,2))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'template_name',
              required: 'O',
              type: 'String(80)',
              desc: (
                <div>
                  템플릿명
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'template_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  템플릿코드
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_sale_name_addcontent_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  상품명 머리말/꼬리말 템플릿코드
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_sale_name_addcontent_name',
              required: 'O',
              type: 'String(50)',
              desc: (
                <div>
                  상품명 머리말/꼬리말 템플릿명
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'addcontent_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  상세설명 머리말/꼬리말 템플릿코드
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'addcontent_name',
              required: 'O',
              type: 'String(50)',
              desc: (
                <div>
                  상세설명 머리말/꼬리말 템플릿명
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sale_stime',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  판매 시작일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sale_etime',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  판매 종료일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ship_price_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  택배비 종류
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>무료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>조건부무료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>착불</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>선결제</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ship_price_free_criteria',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  배송비 조건부 무료
                  <div className='description'>
                    (ex{'>'} 50000 원 이상 무료인경우 50000)
                    <br />
                    (data type: Decimal(10,2))
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'adult_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  성인전용 여부
                  <div className='description'>
                    (1: 성인용품, 0: 성인용품 아님)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'model_no',
              required: 'O',
              type: 'String(50)',
              desc: '모델 번호',
              indent: 1,
            },
            {
              title: 'sol_cate_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  솔루션 카테고리 번호
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sol_cate_name',
              required: 'O',
              type: 'String(60)',
              desc: (
                <div>
                  솔루션 카테고리 명
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'pa_sol_cate_name',
              required: 'O',
              type: 'String(60)',
              desc: (
                <div>
                  솔루션 카테고리명
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ol_cate_name',
              required: 'O',
              type: 'String(110)',
              desc: (
                <div>
                  상품 카테고리명
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sale_img_url',
              required: 'O',
              type: 'String(200)',
              desc: '이미지 URL',
              indent: 1,
            },
            {
              title: 'sale_img1',
              required: 'O',
              type: 'String(30)',
              desc: '기본이미지',
              indent: 1,
            },
            {
              title: 'shop_cate1',
              required: 'O',
              type: 'String(55)',
              desc: (
                <div>
                  카테고리 분류1
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_cate2',
              required: 'O',
              type: 'String(55)',
              desc: (
                <div>
                  카테고리 분류2
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_cate3',
              required: 'O',
              type: 'String(55)',
              desc: (
                <div>
                  카테고리 분류3
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_cate4',
              required: 'O',
              type: 'String(55)',
              desc: (
                <div>
                  카테고리 분류4
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_cate5',
              required: 'O',
              type: 'String(55)',
              desc: (
                <div>
                  카테고리 분류5
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_cate1_cd',
              required: 'O',
              type: 'String(70)',
              desc: (
                <div>
                  카테고리 분류 코드1
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_cate2_cd',
              required: 'O',
              type: 'String(70)',
              desc: (
                <div>
                  카테고리 분류 코드2
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'memo_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '메모여부',
              indent: 1,
            },
            {
              title: 'memo_complete_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '메모 완료 여부',
              indent: 1,
            },
            {
              title: 'ol_count',
              required: 'O',
              type: 'Number',
              desc: '검색된 마스터상품 수',
            },
            {
              title: 'normal_count',
              required: 'O',
              type: 'Number',
              desc: '검색된 일반상품 수',
            },
            {
              title: 'single_count',
              required: 'O',
              type: 'Number',
              desc: '검색된 단일상품 수',
            },
          ],
          response_example: {
            result: {
              판매자관리코드: [
                {
                  sol_no: '솔루션 번호',
                  ol_shop_no: '상품고유값',
                  sale_no: '판매번호',
                  c_sale_cd: '판매자관리코드',
                  shop_cd: '쇼핑몰 코드',
                  pa_shop_cd: '상위 쇼핑몰 코드',
                  shop_name: '쇼핑몰 명',
                  wdate: '상품등록일',
                  mdate: '상품수정일',
                  shop_id: '쇼핑몰 계정',
                  std_ol_yn: '단일상품 여부',
                  std_ol_group_no: '그룹고유값',
                  std_ol_group_cd: '그룹 코드',
                  std_ol_group_name: '그룹 명',
                  std_ol_group_seq: '그룹내 상품 순번',
                  shop_sale_name: '상품 명',
                  shop_promotion_name: '상품 프로모션 명',
                  opt_type: '옵션 타입',
                  sale_status: '상품 판매 상태',
                  shop_sale_no: '쇼핑몰 판매번호',
                  shop_sale_no_sub: '쇼핑몰 판매번호 서브',
                  sale_price: '상품 금액',
                  cost_price: '상품 원가',
                  supply_price: '공급가',
                  street_price: '시중가',
                  template_name: '템플릿 명',
                  template_no: '템플릿 코드',
                  shop_sale_name_addcontent_no:
                    '상품명 머리말/꼬리말 템플릿코드',
                  shop_sale_name_addcontent_name:
                    '상품명 머리말/꼬리말 템플릿 명',
                  addcontent_no: '상세설명 머리말/꼬리말 템플릿코드',
                  addcontent_name: '상세설명 머리말/꼬리말 템플릿 명',
                  sale_stime: '판매 시작일',
                  sale_etime: '판매 종료일',
                  ship_price_type: '배송비 종류',
                  ship_price_free_criteria: '배송비 조건부 무료',
                  ship_price: '배송비 금액',
                  adult_yn: '성인전용 여부',
                  sol_cate_no: '솔루션 카테고리 번호',
                  sol_cate_name: '솔루션 카테고리 명',
                  pa_sol_cate_name: '상위 솔루션 카테고리 명',
                  ol_cate_name: '상품 카테고리 명',
                  sale_img_url: '이미지 1',
                  sale_img1: '기본이미지',
                  shop_cate1: '카테고리 분류 1',
                  shop_cate2: '카테고리 분류 2',
                  shop_cate3: '카테고리 분류 3',
                  shop_cate4: '카테고리 분류 4',
                  shop_cate5: '카테고리 분류 5',
                  shop_cate1_cd: '카테고리 분류 코드 1',
                  shop_cate2_cd: '카테고리 분류 코드 2',
                  memo_yn: '메모 여부',
                  memo_complete_yn: '메모 완료 여부',
                },
              ],
            },
            ol_count: '검색된 마스터상품 수',
            normal_count: '검색된 일반상품 수',
            single_count: '검색된 단일상품 수',
          },
        },
      ],
    },
    {
      title: '온라인상품 등록',
      url: '/doc/온라인상품 등록',
      data: [
        {
          version: 'v1.1',
          url: '/products/add/v1.1',
          desc: (
            <div>
              <ul className='style'>
                <li>온라인상품 정보를 등록합니다.</li>
                <li>
                  단일상품의 경우 쇼핑몰별로 입력가능한 옵션이 다를 수 있습니다.
                </li>
                <li>
                  해당경우 입력가능한 옵션정보가 같은 상품정보끼리 묶어
                  등록해주시기 바랍니다.
                </li>
              </ul>
              <pre className='prettyprint'>
                ex1 &gt; 조합형 옵션이고 옵션명이 색상, 옵션값이 빨강,
                파랑인경우 <br /> 색상-빨강, 색상-파랑
                <br /> 이런 형식으로 두개의 옵션정보가 생성되므로
                <br /> {'{'}
                <br /> opt_sort1: 색상,
                <br /> opt_sort1_desc: 빨강,
                <br /> ...
                <br /> {'}'},<br /> {'{'}
                <br /> opt_sort1: 색상,
                <br /> opt_sort1_desc: 파랑,
                <br /> ...
                <br /> {'}'}
                <br /> 의 형식으로 입력하면 됩니다.
                <br /> <br /> ex2 &gt; 조합형 옵션이고 옵션정보가 [색상 =
                빨강,파랑, 사이즈 = Large, Small] 인경우
                <br /> 빨강-Large, 파랑-Large, 빨강-Small, 파랑-Small
                <br /> 이런 형식으로 총 4개의 옵션정보가 생성되어야 합니다.
                <br /> {'{'}
                <br /> opt_sort1: 색상,
                <br /> opt_sort2: 사이즈,
                <br /> opt_sort1_desc: 빨강,
                <br /> opt_sort2_desc: Large,
                <br /> ...
                <br /> {'}'},<br /> {'{'}
                <br /> opt_sort1: 색상,
                <br /> opt_sort2: 사이즈,
                <br /> opt_sort1_desc: 빨강,
                <br /> opt_sort2_desc: Small
                <br /> ...
                <br /> {'}'},<br /> {'{'}
                <br /> opt_sort1: 색상,
                <br /> opt_sort2: 사이즈,
                <br /> opt_sort1_desc: 파랑,
                <br /> opt_sort2_desc: Large
                <br /> ...
                <br /> {'}'},<br /> {'{'}
                <br /> opt_sort1: 색상,
                <br /> opt_sort2: 사이즈,
                <br /> opt_sort1_desc: 파랑,
                <br /> opt_sort2_desc: Small
                <br /> ...
                <br /> {'}'}
                <br /> <br /> ex3&gt; 독립형 옵션인경우
                <br /> 독립형옵션인경우에는 opt_sort1 에 옵션명, opt_sort1_desc
                에 옵션값만 넣어주면 됩니다.
                <br /> 옵션명: 색상, 사이즈 <br /> 옵션값: Large, Small <br />{' '}
                위의 정보를 독립형 옵션으로 생성하는 경우
                <br /> {'{'}
                <br /> opt_sort1: 색상,
                <br /> opt_sort1_desc: 빨강,
                <br /> ...
                <br /> {'}'},<br /> {'{'}
                <br /> opt_sort1: 색상,
                <br /> opt_sort1_desc: 파랑,
                <br /> ...
                <br /> {'}'},<br /> {'{'}
                <br /> opt_sort1: 사이즈,
                <br /> opt_sort1_desc: Large,
                <br /> ...
                <br /> {'}'},<br /> {'{'}
                <br /> opt_sort1: 사이즈,
                <br /> opt_sort1_desc: Large,
                <br /> ...
                <br /> {'}'}
                <br /> 위의 방식으로 입력해주시면 됩니다.
                <br /> 이 방식은 추가구매 옵션등록시에도 동일합니다.{' '}
              </pre>
            </div>
          ),
          method: 'POST',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'c_sale_cd',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  판매자관리코드
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      <strong>
                        <code>__AUTO__</code>
                      </strong>
                      로 입력시 판매자관리코드를 자동생성합니다.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'sol_cate_no',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  카테고리코드
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      카테고리 조회 api를 통해 확인된 카테고리 번호를
                      입력해주십시오.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'shop_sale_name',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품명
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>상품명은 최대 100Byte까지 등록가능합니다.</li>
                    <li>
                      특수문자는 - _ / ~ + . , ( ) {} [ ] % & '만 사용
                      가능합니다.{' '}
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'minor_yn',
              required: '',
              type: 'Boolean',
              desc: (
                <div>
                  미성년자 구매여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>true</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sale_price',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  판매가격
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>금액은 10원 단위로 입력해주십시오.</li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'sale_cnt_limit',
              required: 'O',
              type: 'Number',
              desc: '판매수량',
            },
            {
              title: 'site_list',
              required: 'O',
              type: 'Object[]',
              desc: '등록할 쇼핑몰 정보',
            },
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  쇼핑몰 코드
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      쇼핑몰 코드조회 문서를 참고하여 등록하시려는 사이트의
                      코드를 입력해주십시오.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_id',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 아이디',
              indent: 1,
            },
            {
              title: 'template_no',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  템플릿번호
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      템플릿 조회 api를 통해 확인된 템플릿 번호를
                      입력해주십시오.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_sale_name',
              required: '',
              type: 'String',
              desc: (
                <div>
                  상품명 개별입력
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>상품명 글자 제한수는 쇼핑몰별로 상이합니다.</li>
                    <li>
                      특수문자는 - _ / ~ + . , ( ) [ ] % & '만 사용 가능합니다.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_sale_name_addcontent_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  상품명 머리말/꼬리말 템플릿 번호를
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      머리말/꼬리말 조회 api를 통해 확인된 상품명 머리말/꼬리말
                      템플릿 번호를 입력해주십시오.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'addcontent_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  상세설명 머리말/꼬리말 템플릿 번호
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      머리말/꼬리말 조회 api를 통해 확인된 상세설명
                      머리말/꼬리말 템플릿 번호를 입력해주십시오.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'std_ol_yn',
              required: '',
              type: 'String',
              desc: (
                <div>
                  단일상품여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>Y</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>N</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'main_sku_cd',
              required: '',
              type: 'String',
              desc: '대표SKU상품 코드'
            },
            {
              title: 'opt_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션구분
                  <br />
                  <span>
                    <br />
                    <b>홈플러스, 갤러리아몰, 롯데ON</b>
                  </span>
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      마스터 상품에 옵션 일괄 설정 시 대표 SKU상품만 매칭된
                      '옵션없음' 상품으로 등록됩니다.
                    </li>
                    <li>
                      카테고리에 따라 옵션 설정이 필요한 상품은 온라인 상품 생성
                      후 상세에서 별도로 옵션을 생성해 주셔야 합니다.
                    </li>
                  </ul>
                  <b>쿠팡</b>
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      쿠팡은 독립형 옵션 미지원으로 마스터 상품의 옵션을
                      독립형으로 설정하신 경우 대표 SKU상품만 매칭된 '옵션없음'
                      상품으로 등록됩니다.
                    </li>
                  </ul>
                  <div>
                    <div className='description' style={{ padding: '12px 0' }}>
                      Allowed values
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <strong>
                              <code>옵션없음</code>
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>
                              <code>조합형</code>
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>
                              <code>독립형</code>
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ),
            },
            {
              title: 'opts',
              required: '',
              type: 'Object[]',
              desc: (
                <div>
                  옵션
                  <div className='description'>
                    (옵션구분이 옵션없음이 아닌경우 필수입력.)
                  </div>
                </div>
              ),
            },
            {
              title: 'opt_sort1',
              required: 'O',
              type: 'String',
              desc: '옵션명1',
              indent: 1,
            },
            {
              title: 'opt_sort2',
              required: '',
              type: 'String',
              desc: '옵션명2',
              indent: 1,
            },
            {
              title: 'opt_sort3',
              required: '',
              type: 'String',
              desc: '옵션명3',
              indent: 1,
            },
            {
              title: 'opt_sort1_desc',
              required: 'O',
              type: 'String',
              desc: '옵션값1',
              indent: 1,
            },
            {
              title: 'opt_sort2_desc',
              required: '',
              type: 'String',
              desc: '옵션값2',
              indent: 1,
            },
            {
              title: 'opt_sort3_desc',
              required: '',
              type: 'String',
              desc: '옵션값3',
              indent: 1,
            },
            {
              title: 'sku_cd',
              required: '',
              type: 'String',
              desc: 'sku 코드',
              indent: 1,
            },
            {
              title: 'pack_unit',
              required: '',
              type: 'Number',
              desc: '출고수량',
              indent: 1,
            },
            {
              title: 'add_price',
              required: '',
              type: 'Number',
              desc: '추가옵션금액',
              indent: 1,
            },
            {
              title: 'stock_cnt',
              required: 'O',
              type: 'Number',
              desc: '판매수량',
              indent: 1,
            },
            {
              title: 'weight',
              required: '',
              type: 'Number',
              desc: '옵션무게',
              indent: 1,
            },
            {
              title: 'status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'input_opt_name',
              required: '',
              type: 'Array',
              desc: '입력형옵션',
            },
            {
              title: 'add_opts',
              required: '',
              type: 'Object[]',
              desc: '추가구매옵션',
            },
            {
              title: 'opt_sort',
              required: 'O',
              type: 'String',
              desc: '추가항목명',
              indent: 1,
            },
            {
              title: 'opt_sort_desc',
              required: 'O',
              type: 'String',
              desc: '추가옵션명',
              indent: 1,
            },
            {
              title: 'price',
              required: 'O',
              type: 'Number',
              desc: '추가금액',
              indent: 1,
            },
            {
              title: 'stock_cnt',
              required: 'O',
              type: 'Number',
              desc: '판매수량',
              indent: 1,
            },
            {
              title: 'sku_cd',
              required: '',
              type: 'String',
              desc: 'sku 코드',
              indent: 1,
            },
            {
              title: 'pack_unit',
              required: '',
              type: 'Number',
              desc: '출고수량',
              indent: 1,
            },
            {
              title: 'weight',
              required: '',
              type: 'Number',
              desc: '추가구매옵션무게',
              indent: 1,
            },
            {
              title: 'status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  추가구매옵션 상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'tax_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  과세여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>과세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>면세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>영세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>비과세</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'madein',
              required: 'O',
              type: 'object',
              desc: '원산지',
            },
            {
              title: 'madein_no',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  원산지번호
                  <div className='description'>
                    (원산지 조회 api 호출하여 확인)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'madein_etc',
              required: '',
              type: 'String',
              desc: (
                <div>
                  기타원산지
                  <div className='description'>
                    (등록하려는 원산지가 origins에서 조회되지않을 때<br />
                    madein_no를 비우고 madein_etc에 값을 입력합니다.)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'multi_yn',
              required: '',
              type: 'Boolean',
              desc: (
                <div>
                  복수원산지여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'made_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  제조일자
                  <div className='description'>
                    (YYYY-MM-DD의 형식으로 입력해주십시오.)
                  </div>
                </div>
              ),
            },
            {
              title: 'expire_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  유효일자
                  <div className='description'>
                    (YYYY-MM-DD의 형식으로 입력해주십시오.)
                  </div>
                </div>
              ),
            },
            {
              title: 'detail_desc',
              required: 'O',
              type: 'String',
              desc: '상품상세설명',
            },
            {
              title: 'brand',
              required: '',
              type: 'String',
              desc: '브랜드',
            },
            {
              title: 'model',
              required: '',
              type: 'String',
              desc: '모델명',
            },
            {
              title: 'maker',
              required: '',
              type: 'String',
              desc: '제조사',
            },
            {
              title: 'keyword',
              required: '',
              type: 'String[]',
              desc: (
                <div>
                  키워드
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      키워드는 최대 40개 까지 등록가능하며, 쇼핑몰에 상품전송시
                      쇼핑몰에서 지원하는 입력 갯수만큼만 전송됩니다.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'sale_img1',
              required: 'O',
              type: 'String',
              desc: '기본이미지 URL',
            },
            {
              title: 'sale_img2',
              required: '',
              type: 'String',
              desc: '추가이미지 1 URL',
            },
            {
              title: 'sale_img3',
              required: '',
              type: 'String',
              desc: '추가이미지 2 URL',
            },
            {
              title: 'sale_img4',
              required: '',
              type: 'String',
              desc: '추가이미지 3 URL',
            },
            {
              title: 'sale_img5',
              required: '',
              type: 'String',
              desc: '추가이미지 4 URL',
            },
            {
              title: 'sale_img6',
              required: '',
              type: 'String',
              desc: '추가이미지 5 URL',
            },
            {
              title: 'sale_img7',
              required: '',
              type: 'String',
              desc: '추가이미지 6 URL',
            },
            {
              title: 'sale_img8',
              required: '',
              type: 'String',
              desc: '추가이미지 7 URL',
            },
            {
              title: 'sale_img9',
              required: '',
              type: 'String',
              desc: '추가이미지 8 URL',
            },
            {
              title: 'sale_img10',
              required: '',
              type: 'String',
              desc: '추가이미지 9 URL',
            },
            {
              title: 'sale_img11',
              required: '',
              type: 'String',
              desc: '추가이미지 10 URL',
            },
            {
              title: 'prod_info',
              required: 'O',
              type: 'Object[]',
              desc: (
                <div>
                  상품정보제공고시
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>품목정보고시정보는 3개까지 입력가능합니다.</li>
                    <li>아래의 등록가이드를 참고해주세요.</li>
                    <li>
                      <a
                        href='https://gmp-master.s3.ap-northeast-2.amazonaws.com/download/Sample_prodInfo_guide_OPENAPI.xlsx'
                        style={{ color: 'blue' }}
                      >
                        상품 품목정보고시 가이드 개정판
                      </a>
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'infoCode',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품분류코드
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      대표 SKU상품 정보를 사용하고 싶으신 경우 '대표SKU상품'으로
                      입력합니다.
                    </li>
                    <li>
                      대표 SKU상품으로 입력시 아래의 정보는 입력할 필요가
                      없습니다.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'infoDetail',
              required: 'O',
              type: 'object',
              desc: (
                <div>
                  상품 정보 제공고시 데이터
                  <ul style={{ color: 'gray' }}>
                    <li>
                      품목 정보고시 가이드에 기재된 각 항목에 따른 key와 value를
                      입력합니다.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'is_desc_referred',
              type: 'boolean',
              desc: (
                <div>
                  상품 정보 제공고시 데이터 일괄 [상세설명참조] 적용
                  <ul style={{ color: 'gray' }}>
                    <li>
                      해당 값을 <code>true</code> 로 설정 시 입력한 값과 상관 없이 일괄 <code>[상세설명참조]</code>로 적용됩니다.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ship_price_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  배송방법
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>무료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>선결제</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>착불</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'ship_price',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  배송비
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>배송방법이 무료가 아닌경우 필수 입니다.</li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'gift_name',
              required: '',
              type: 'String',
              desc: '사은품',
            },
            {
              title: 'supply_price',
              required: '',
              type: 'Number',
              desc: '공급가',
            },
            {
              title: 'cost_price',
              required: '',
              type: 'Number',
              desc: '원가',
            },
            {
              title: 'street_price',
              required: '',
              type: 'Number',
              desc: '시중가',
            },
            {
              title: 'global_barcode_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  UPC/EAN 타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>UPC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EAN</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'global_barcode',
              required: '',
              type: 'Number',
              desc: 'UPC/EAN 코드',
            },
            {
              title: 'isbn',
              required: '',
              type: 'Number',
              desc: 'ISBN 코드',
            },
            {
              title: 'barcode',
              required: '',
              type: 'String',
              desc: '바코드',
            },
            {
              title: 'hscd',
              required: '',
              type: 'String',
              desc: 'HS코드',
            },
            {
              title: 'prod_weight',
              required: '',
              type: 'Number',
              desc: '상품무게',
            },
            {
              title: 'cert_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  인증정보타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>인증대상아님</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>인증유형등록</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>인증대상아님</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>상세설명내등록</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'certs',
              required: '',
              type: 'Array',
              desc: (
                <div>
                  인증정보
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>인증정보는 10개까지 등록 가능합니다.</li>
                    <li>
                      인증정보타입이 인증유형등록인 경우 필수로 입력해주셔야
                      합니다.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'cert_cd',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  인증유형
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>cert_01: 생활용품 안전인증</li>
                    <li>cert_02: 생활용품 안전확인</li>
                    <li>cert_03: 생활용품 어린이보호포장</li>
                    <li>cert_04: 생활용품 안전품질표시</li>
                    <li>cert_05: 생활용품 공급자적합성확인</li>
                    <li>cert_06: 전기용품 안전인증</li>
                    <li>cert_07: 전기용품 안전확인</li>
                    <li>cert_08: 전기용품 공급자적합성확인</li>
                    <li>cert_09: 방송통신용품 적합성인증확인</li>
                    <li>cert_10: 어린이제품 안전인증</li>
                    <li>cert_11: 어린이제품 안전확인</li>
                    <li>cert_12: 어린이제품 공급자적합성확인</li>
                    <li>cert_13: 위해우려제품 자가검사</li>
                    <li>cert_14: 방송통신기자재 잠정인증</li>
                    <li>cert_15: 방송통신기자재 적합등록</li>
                    <li>cert_16: 원산지증명</li> <li>cert_17: 해외안전인증</li>
                    <li>cert_18: 수입신고필증</li>
                    <li>cert_19: 친환경인증-무농약농산물</li>
                    <li>cert_20: 친환경인증-유기축산물</li>
                    <li>cert_21: 친환경인증-유기농산물</li>
                    <li>cert_22: 친환경인증-저농약농산물</li>
                    <li>cert_23: 친환경인증-무항생제축산물</li>
                    <li>cert_24: HACCP(위해요소 중점관리우수식품)</li>
                    <li>cert_25: GMP(우수건강기능식품 제조기준)</li>
                    <li>cert_26: GAP(농산물우수관리인증)</li>
                    <li>cert_27: 농산물이력추적관리</li>
                    <li>cert_28: 수산물품질인증</li>
                    <li>cert_29: 수산특산물품질인증</li>
                    <li>cert_30: 수산전통식품품질인증</li>
                    <li>cert_31: 친환경수산물품질인증</li>
                    <li>cert_32: 친환경수산가공품품질인증</li>
                    <li>cert_33: 전통식품 품질인증</li>
                    <li>cert_34: 유기가공식품 인증</li>
                    <li>cert_35: 가공식품 산업표준 KS인증</li>
                    <li>cert_36: 건강기능식품 광고사전심의</li>
                    <li>cert_37: 의료기기 광고사전심의</li>
                    <li>cert_38: 축산물 등급판정 확인서</li>
                    <li>cert_39: 도축검사증명서</li>
                    <li>cert_40: 식품명인(Grand master) 인증</li>
                    <li>cert_41: 사회적기업 인증</li>
                    <li>cert_42: 6차산업 인증</li>
                    <li>cert_43: 동물복지 인증</li>
                    <li>cert_44: 마을기업 인증</li>
                    <li>cert_45: 건강기능식품인증</li>
                    <li>cert_46: 생활화학제품</li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cert_exc_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  면제유형
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>kc_01: 안전기준준수대상</li>
                    <li>kc_02: 구매대행</li>
                    <li>kc_03: 병행수입</li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_01</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_02</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_03</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cert_ministry',
              required: '',
              type: 'String',
              desc: '인증기관',
              indent: 1,
            },
            {
              title: 'cert_no',
              required: 'O',
              type: 'String',
              desc: '인증번호',
              indent: 1,
            },
            {
              title: 'cert_model',
              required: '',
              type: 'String',
              desc: '인증모델',
              indent: 1,
            },
            {
              title: 'cert_cname',
              required: '',
              type: 'String',
              desc: '인증상호',
              indent: 1,
            },
            {
              title: 'cert_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  인증일
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>YYYY-MM-DD의 날짜형식으로 입력해주십시오.</li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cert_expire_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  인증만료일
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>YYYY-MM-DD의 날짜형식으로 입력해주십시오.</li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'add_desc_info',
              required: '',
              type: 'Object',
              desc: (
                <div>
                  등록 상품이 쇼핑몰 29CM인 경우만 상세 설명 이미지
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>객체의 키 값은 쇼핑몰 코드</li>
                    <li>
                      상세 설명 이미지가 하나 이상은 반드시 존재해야 됩니다.
                    </li>
                    <li>
                      [image_type]
                      <ul className='style' style={{ color: 'gray' }}>
                        <li>4: 기본 타입</li> <li>5: 영상 타입</li>
                        <li>6: 타이틀 구분 타입</li>
                        <li>7: 이미지 링크 타입</li>
                      </ul>
                    </li>
                  </ul>
                  <pre>
                    <span className='pun'>{'{'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>"image_info"</span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='pun'>[</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'    {'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>&nbsp;&nbsp;&nbsp;&nbsp;"title"</span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"타이틀"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"content"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"설명"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_url"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"IMG_URL"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_type"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='lit'>4</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'}'},</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'{'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>&nbsp;&nbsp;&nbsp;&nbsp;"title"</span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"타이틀"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"content"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"설명"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"added_url"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"VIDEO_URL"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_url"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"IMG_URL"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"added_type"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='lit'>1</span>
                    <span className='pun'>,</span>
                    <span className='pln'> </span>
                    <span className='com'> # 1: 유튜브, 2: 비메오</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_type"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='lit'>5</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'}'},</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'{'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>&nbsp;&nbsp;&nbsp;&nbsp;"title"</span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"타이틀"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_url"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='pun'>[</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'        {'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"content"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"설명"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"image_url"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"IMG_URL"</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'        }'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>&nbsp;&nbsp;&nbsp;&nbsp;],</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"is_layout"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"F"</span>
                    <span className='pun'>,</span>
                    <span className='pln'> </span>
                    <span className='com'>
                      {' '}
                      # "F": 레이아웃 1단, "T": 레이아웃 2단{' '}
                    </span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_type"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='lit'>6</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'}'},</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'{'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"added_url"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"IMG_LINK_URL"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_url"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"IMG_URL"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_type"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='lit'>7</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pln'> </span>
                    <span className='pun'>{'    }'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'}'}</span>
                    <span className='pln'> </span>
                  </pre>
                </div>
              ),
            },
          ],
          body_example: {
            c_sale_cd: '__AUTO__',
            sol_cate_no: 11354,
            shop_sale_name: 'api 테스트 상품',
            minor_yn: true,
            sale_price: 50000,
            sale_cnt_limit: 88,
            site_list: [
              {
                shop_cd: 'A112',
                shop_id: 'playauto',
                template_no: 49724,
              },
            ],
            std_ol_yn: 'N',
            opt_type: '독립형',
            opts: [
              {
                opt_sort1: '색상',
                opt_sort2: '',
                opt_sort3: '',
                opt_sort1_desc: '빨강',
                opt_sort2_desc: '',
                opt_sort3_desc: '',
                sku_cd: 'S999C90F24324',
                pack_unit: 1,
                add_price: 0,
                stock_cnt: 99,
                weight: 0.5,
                status: '정상',
              },
              {
                opt_sort1: '색상',
                opt_sort2: '',
                opt_sort3: '',
                opt_sort1_desc: '파랑',
                opt_sort2_desc: '',
                opt_sort3_desc: '',
                sku_cd: 'S999C47FA857424',
                pack_unit: 1,
                add_price: 5000,
                stock_cnt: 88,
                weight: '1',
                status: '품절',
              },
            ],
            input_opt_name: ['입력형옵션1', '입력형옵션2'],
            add_opts: [
              {
                opt_sort: '추가구매타입',
                opt_sort_desc: '추가구매1',
                price: 1000,
                stock_cnt: 20,
                sku_cd: 'S893688C196587',
                pack_unit: 2,
                weight: 0,
                status: '정상',
              },
              {
                opt_sort: '추가구매타입',
                opt_sort_desc: '추가구매2',
                price: 2000,
                stock_cnt: 30,
                sku_cd: 'S1880FB9324',
                pack_unit: 1,
                weight: 1,
                status: '품절',
              },
            ],
            tax_type: '과세',
            madein: {
              madein_no: 2,
              madein_etc: '',
              multi_yn: false,
            },
            made_date: '2020-01-01',
            expire_date: '2021-03-01',
            detail_desc: '상세설명',
            brand: '브랜드',
            model: '모델명',
            maker: '제조사',
            sale_img1: 'https://sample.com/productImage.jpg',
            prod_info: [
              {
                infoCode: 'Wear2023',
                infoDetail: {
                  material: '1',
                  color: '2',
                  size: '3',
                  manufacturer: '4',
                  made_in: '5',
                  caution: '6',
                  release: '7',
                  warranty: '8',
                  customer_service: '9',
                },
              },
              {
                infoCode: 'Shoes2023',
                infoDetail: {
                  material: '1',
                  color: '2',
                  size: '3',
                  manufacturer: '4',
                  made_in: '5',
                  caution: '6',
                  warranty: '7',
                  customer_service: '8',
                },
              },
            ],
            add_desc_info: {
              image_info: [
                {
                  title: '타이틀',
                  content: '설명',
                  image_url: 'IMG_URL',
                  image_type: 4,
                },
              ],
            },
          },
          response: [
            {
              title: 'result',
              required: 'O',
              type: 'String',
              desc: '성공/실패',
            },
            {
              title: 'c_sale_cd',
              required: 'O',
              type: 'String',
              desc: '등록된 상품의 판매자관리코드',
            },
            {
              title: 'site_list',
              required: 'O',
              type: 'Object[]',
              desc: '등록된 상품 쇼핑몰 정보',
            },
            {
              title: 'shop_name',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 이름',
            },
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 코드',
            },
            {
              title: 'shop_id',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 아이디 (마스터 상품의 경우 빈값 출력)',
            },
            {
              title: 'ol_shop_no',
              required: 'O',
              type: 'Number',
              desc: '등록된 온라인상품 고유번호',
            },
            {
              title: 'messages',
              required: 'O',
              type: 'String[]',
              desc: '등록이 실패된 경우 에러메시지',
            },
          ],
          response_example: {
            result: '성공',
            c_sale_cd: 'm20200925497324',
            site_list: [
              {
                shop_name: '마스터 상품',
                shop_cd: 'Z000',
                shop_id: '',
                ol_shop_no: 11111111,
              },
              {
                shop_name: '더현대',
                shop_cd: 'A133',
                shop_id: 'playauto',
                ol_shop_no: 22222222,
              },
              {
                shop_name: '옥션',
                shop_cd: 'A001',
                shop_id: 'playauto',
                ol_shop_no: 33333333,
              },
            ],
            message: '',
          },
        },
        {
          version: 'v1.0',
          url: '/products/add',
          desc: (
            <div>
              <ul className='style'>
                <li>온라인상품 정보를 등록합니다.</li>
                <li>
                  단일상품의 경우 쇼핑몰별로 입력가능한 옵션이 다를 수 있습니다.
                </li>
                <li>
                  해당경우 입력가능한 옵션정보가 같은 상품정보끼리 묶어
                  등록해주시기 바랍니다.
                </li>
              </ul>
              <pre className='prettyprint'>
                ex1 &gt; 조합형 옵션이고 옵션명이 색상, 옵션값이 빨강,
                파랑인경우 <br /> 색상-빨강, 색상-파랑
                <br /> 이런 형식으로 두개의 옵션정보가 생성되므로
                <br /> {'{'}
                <br /> opt_sort1: 색상,
                <br /> opt_sort1_desc: 빨강,
                <br /> ...
                <br /> {'}'},<br /> {'{'}
                <br /> opt_sort1: 색상,
                <br /> opt_sort1_desc: 파랑,
                <br /> ...
                <br /> {'}'}
                <br /> 의 형식으로 입력하면 됩니다.
                <br /> <br /> ex2 &gt; 조합형 옵션이고 옵션정보가 [색상 =
                빨강,파랑, 사이즈 = Large, Small] 인경우
                <br /> 빨강-Large, 파랑-Large, 빨강-Small, 파랑-Small
                <br /> 이런 형식으로 총 4개의 옵션정보가 생성되어야 합니다.
                <br /> {'{'}
                <br /> opt_sort1: 색상,
                <br /> opt_sort2: 사이즈,
                <br /> opt_sort1_desc: 빨강,
                <br /> opt_sort2_desc: Large,
                <br /> ...
                <br /> {'}'},<br /> {'{'}
                <br /> opt_sort1: 색상,
                <br /> opt_sort2: 사이즈,
                <br /> opt_sort1_desc: 빨강,
                <br /> opt_sort2_desc: Small
                <br /> ...
                <br /> {'}'},<br /> {'{'}
                <br /> opt_sort1: 색상,
                <br /> opt_sort2: 사이즈,
                <br /> opt_sort1_desc: 파랑,
                <br /> opt_sort2_desc: Large
                <br /> ...
                <br /> {'}'},<br /> {'{'}
                <br /> opt_sort1: 색상,
                <br /> opt_sort2: 사이즈,
                <br /> opt_sort1_desc: 파랑,
                <br /> opt_sort2_desc: Small
                <br /> ...
                <br /> {'}'}
                <br /> <br /> ex3&gt; 독립형 옵션인경우
                <br /> 독립형옵션인경우에는 opt_sort1 에 옵션명, opt_sort1_desc
                에 옵션값만 넣어주면 됩니다.
                <br /> 옵션명: 색상, 사이즈 <br /> 옵션값: Large, Small <br />{' '}
                위의 정보를 독립형 옵션으로 생성하는 경우
                <br /> {'{'}
                <br /> opt_sort1: 색상,
                <br /> opt_sort1_desc: 빨강,
                <br /> ...
                <br /> {'}'},<br /> {'{'}
                <br /> opt_sort1: 색상,
                <br /> opt_sort1_desc: 파랑,
                <br /> ...
                <br /> {'}'},<br /> {'{'}
                <br /> opt_sort1: 사이즈,
                <br /> opt_sort1_desc: Large,
                <br /> ...
                <br /> {'}'},<br /> {'{'}
                <br /> opt_sort1: 사이즈,
                <br /> opt_sort1_desc: Large,
                <br /> ...
                <br /> {'}'}
                <br /> 위의 방식으로 입력해주시면 됩니다.
                <br /> 이 방식은 추가구매 옵션등록시에도 동일합니다.{' '}
              </pre>
            </div>
          ),
          method: 'POST',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'c_sale_cd',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  판매자관리코드
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      <strong>
                        <code>__AUTO__</code>
                      </strong>
                      로 입력시 판매자관리코드를 자동생성합니다.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'sol_cate_no',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  카테고리코드
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      카테고리 조회 api를 통해 확인된 카테고리 번호를
                      입력해주십시오.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'shop_sale_name',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품명
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>상품명은 최대 100Byte까지 등록가능합니다.</li>
                    <li>
                      특수문자는 - _ / ~ + . , ( ) {} [ ] % & '만 사용
                      가능합니다.{' '}
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'minor_yn',
              required: '',
              type: 'Boolean',
              desc: (
                <div>
                  미성년자 구매여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>true</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sale_price',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  판매가격
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>금액은 10원 단위로 입력해주십시오.</li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'sale_cnt_limit',
              required: 'O',
              type: 'Number',
              desc: '판매수량',
            },
            {
              title: 'site_list',
              required: 'O',
              type: 'Object[]',
              desc: '등록할 쇼핑몰 정보',
            },
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  쇼핑몰 코드
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      쇼핑몰 코드조회 문서를 참고하여 등록하시려는 사이트의
                      코드를 입력해주십시오.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_id',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 아이디',
              indent: 1,
            },
            {
              title: 'template_no',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  템플릿번호
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      템플릿 조회 api를 통해 확인된 템플릿 번호를
                      입력해주십시오.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_sale_name_addcontent_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  상품명 머리말/꼬리말 템플릿 번호를
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      머리말/꼬리말 조회 api를 통해 확인된 상품명 머리말/꼬리말
                      템플릿 번호를 입력해주십시오.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'addcontent_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  상세설명 머리말/꼬리말 템플릿 번호
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      머리말/꼬리말 조회 api를 통해 확인된 상세설명
                      머리말/꼬리말 템플릿 번호를 입력해주십시오.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'std_ol_yn',
              required: '',
              type: 'String',
              desc: (
                <div>
                  단일상품여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>Y</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>N</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'opt_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션구분
                  <br />
                  <span>
                    <br />
                    <b>홈플러스, 갤러리아몰, 롯데ON</b>
                  </span>
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      마스터 상품에 옵션 일괄 설정 시 대표 SKU상품만 매칭된
                      '옵션없음' 상품으로 등록됩니다.
                    </li>
                    <li>
                      카테고리에 따라 옵션 설정이 필요한 상품은 온라인 상품 생성
                      후 상세에서 별도로 옵션을 생성해 주셔야 합니다.
                    </li>
                  </ul>
                  <b>쿠팡</b>
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      쿠팡은 독립형 옵션 미지원으로 마스터 상품의 옵션을
                      독립형으로 설정하신 경우 대표 SKU상품만 매칭된 '옵션없음'
                      상품으로 등록됩니다.
                    </li>
                  </ul>
                  <div>
                    <div className='description' style={{ padding: '12px 0' }}>
                      Allowed values
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <strong>
                              <code>옵션없음</code>
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>
                              <code>조합형</code>
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>
                              <code>독립형</code>
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ),
            },
            {
              title: 'opts',
              required: '',
              type: 'Object[]',
              desc: (
                <div>
                  옵션
                  <div className='description'>
                    (옵션구분이 옵션없음이 아닌경우 필수입력.)
                  </div>
                </div>
              ),
            },
            {
              title: 'opt_sort1',
              required: 'O',
              type: 'String',
              desc: '옵션명1',
              indent: 1,
            },
            {
              title: 'opt_sort2',
              required: '',
              type: 'String',
              desc: '옵션명2',
              indent: 1,
            },
            {
              title: 'opt_sort3',
              required: '',
              type: 'String',
              desc: '옵션명3',
              indent: 1,
            },
            {
              title: 'opt_sort1_desc',
              required: 'O',
              type: 'String',
              desc: '옵션값1',
              indent: 1,
            },
            {
              title: 'opt_sort2_desc',
              required: '',
              type: 'String',
              desc: '옵션값2',
              indent: 1,
            },
            {
              title: 'opt_sort3_desc',
              required: '',
              type: 'String',
              desc: '옵션값3',
              indent: 1,
            },
            {
              title: 'sku_cd',
              required: '',
              type: 'String',
              desc: 'sku 코드',
              indent: 1,
            },
            {
              title: 'pack_unit',
              required: '',
              type: 'Number',
              desc: '출고수량',
              indent: 1,
            },
            {
              title: 'add_price',
              required: '',
              type: 'Number',
              desc: '추가옵션금액',
              indent: 1,
            },
            {
              title: 'stock_cnt',
              required: 'O',
              type: 'Number',
              desc: '판매수량',
              indent: 1,
            },
            {
              title: 'weight',
              required: '',
              type: 'Number',
              desc: '옵션무게',
              indent: 1,
            },
            {
              title: 'status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'input_opt_name',
              required: '',
              type: 'Array',
              desc: '입력형옵션',
            },
            {
              title: 'add_opts',
              required: '',
              type: 'Object[]',
              desc: '추가구매옵션',
            },
            {
              title: 'opt_sort',
              required: 'O',
              type: 'String',
              desc: '추가항목명',
              indent: 1,
            },
            {
              title: 'opt_sort_desc',
              required: 'O',
              type: 'String',
              desc: '추가옵션명',
              indent: 1,
            },
            {
              title: 'price',
              required: 'O',
              type: 'Number',
              desc: '추가금액',
              indent: 1,
            },
            {
              title: 'stock_cnt',
              required: 'O',
              type: 'Number',
              desc: '판매수량',
              indent: 1,
            },
            {
              title: 'sku_cd',
              required: '',
              type: 'String',
              desc: 'sku 코드',
              indent: 1,
            },
            {
              title: 'pack_unit',
              required: '',
              type: 'Number',
              desc: '출고수량',
              indent: 1,
            },
            {
              title: 'weight',
              required: '',
              type: 'Number',
              desc: '추가구매옵션무게',
              indent: 1,
            },
            {
              title: 'status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  추가구매옵션 상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'tax_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  과세여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>과세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>면세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>영세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>비과세</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'madein',
              required: 'O',
              type: 'object',
              desc: '원산지',
            },
            {
              title: 'madein_no',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  원산지번호
                  <div className='description'>
                    (원산지 조회 api 호출하여 확인)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'madein_etc',
              required: '',
              type: 'String',
              desc: (
                <div>
                  기타원산지
                  <div className='description'>
                    (등록하려는 원산지가 origins에서 조회되지않을 때<br />
                    madein_no를 비우고 madein_etc에 값을 입력합니다.)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'multi_yn',
              required: '',
              type: 'Boolean',
              desc: (
                <div>
                  복수원산지여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'made_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  제조일자
                  <div className='description'>
                    (YYYY-MM-DD의 형식으로 입력해주십시오.)
                  </div>
                </div>
              ),
            },
            {
              title: 'expire_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  유효일자
                  <div className='description'>
                    (YYYY-MM-DD의 형식으로 입력해주십시오.)
                  </div>
                </div>
              ),
            },
            {
              title: 'detail_desc',
              required: 'O',
              type: 'String',
              desc: '상품상세설명',
            },
            {
              title: 'brand',
              required: '',
              type: 'String',
              desc: '브랜드',
            },
            {
              title: 'model',
              required: '',
              type: 'String',
              desc: '모델명',
            },
            {
              title: 'maker',
              required: '',
              type: 'String',
              desc: '제조사',
            },
            {
              title: 'keyword',
              required: '',
              type: 'String[]',
              desc: (
                <div>
                  키워드
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      키워드는 최대 40개 까지 등록가능하며, 쇼핑몰에 상품전송시
                      쇼핑몰에서 지원하는 입력 갯수만큼만 전송됩니다.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'sale_img1',
              required: 'O',
              type: 'String',
              desc: '기본이미지 URL',
            },
            {
              title: 'sale_img2',
              required: '',
              type: 'String',
              desc: '추가이미지 1 URL',
            },
            {
              title: 'sale_img3',
              required: '',
              type: 'String',
              desc: '추가이미지 2 URL',
            },
            {
              title: 'sale_img4',
              required: '',
              type: 'String',
              desc: '추가이미지 3 URL',
            },
            {
              title: 'sale_img5',
              required: '',
              type: 'String',
              desc: '추가이미지 4 URL',
            },
            {
              title: 'sale_img6',
              required: '',
              type: 'String',
              desc: '추가이미지 5 URL',
            },
            {
              title: 'sale_img7',
              required: '',
              type: 'String',
              desc: '추가이미지 6 URL',
            },
            {
              title: 'sale_img8',
              required: '',
              type: 'String',
              desc: '추가이미지 7 URL',
            },
            {
              title: 'sale_img9',
              required: '',
              type: 'String',
              desc: '추가이미지 8 URL',
            },
            {
              title: 'sale_img10',
              required: '',
              type: 'String',
              desc: '추가이미지 9 URL',
            },
            {
              title: 'sale_img11',
              required: '',
              type: 'String',
              desc: '추가이미지 10 URL',
            },
            {
              title: 'prod_info',
              required: 'O',
              type: 'Object[]',
              desc: (
                <div>
                  상품정보제공고시
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>품목정보고시정보는 3개까지 입력가능합니다.</li>
                    <li>아래의 등록가이드를 참고해주세요.</li>
                    <li>
                      https://gmp-master.s3.ap-northeast-2.amazonaws.com/download/Sample_prodInfo_guide.xlsx
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'code',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품분류코드
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      대표 SKU상품 정보를 사용하고 싶으신 경우 '대표SKU상품'으로
                      입력합니다.
                    </li>
                    <li>
                      대표 SKU상품으로 입력시 아래의 정보는 입력할 필요가
                      없습니다.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_1',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시1',
              indent: 1,
            },
            {
              title: 'prod_info_2',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시2',
              indent: 1,
            },
            {
              title: 'prod_info_3',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시3',
              indent: 1,
            },
            {
              title: 'prod_info_4',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시4',
              indent: 1,
            },
            {
              title: 'prod_info_5',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시5',
              indent: 1,
            },
            {
              title: 'prod_info_6',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시6',
              indent: 1,
            },
            {
              title: 'prod_info_7',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시7',
              indent: 1,
            },
            {
              title: 'prod_info_8',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시8',
              indent: 1,
            },
            {
              title: 'prod_info_9',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시9',
              indent: 1,
            },
            {
              title: 'prod_info_10',
              required: '',
              type: 'String',
              desc: '상품정보제공고시10',
              indent: 1,
            },
            {
              title: 'prod_info_11',
              required: '',
              type: 'String',
              desc: '상품정보제공고시11',
              indent: 1,
            },
            {
              title: 'prod_info_12',
              required: '',
              type: 'String',
              desc: '상품정보제공고시12',
              indent: 1,
            },
            {
              title: 'prod_info_13',
              required: '',
              type: 'String',
              desc: '상품정보제공고시13',
              indent: 1,
            },
            {
              title: 'prod_info_14',
              required: '',
              type: 'String',
              desc: '상품정보제공고시14',
              indent: 1,
            },
            {
              title: 'prod_info_15',
              required: '',
              type: 'String',
              desc: '상품정보제공고시15',
              indent: 1,
            },
            {
              title: 'prod_info_16',
              required: '',
              type: 'String',
              desc: '상품정보제공고시16',
              indent: 1,
            },
            {
              title: 'prod_info_17',
              required: '',
              type: 'String',
              desc: '상품정보제공고시17',
              indent: 1,
            },
            {
              title: 'prod_info_18',
              required: '',
              type: 'String',
              desc: '상품정보제공고시18',
              indent: 1,
            },
            {
              title: 'prod_info_19',
              required: '',
              type: 'String',
              desc: '상품정보제공고시19',
              indent: 1,
            },
            {
              title: 'prod_info_20',
              required: '',
              type: 'String',
              desc: '상품정보제공고시20',
              indent: 1,
            },
            {
              title: 'prod_info_21',
              required: '',
              type: 'String',
              desc: '상품정보제공고시21',
              indent: 1,
            },
            {
              title: 'prod_info_22',
              required: '',
              type: 'String',
              desc: '상품정보제공고시22',
              indent: 1,
            },
            {
              title: 'prod_info_23',
              required: '',
              type: 'String',
              desc: '상품정보제공고시23',
              indent: 1,
            },
            {
              title: 'prod_info_24',
              required: '',
              type: 'String',
              desc: '상품정보제공고시24',
              indent: 1,
            },
            {
              title: 'ship_price_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  배송방법
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>무료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>선결제</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>착불</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'ship_price',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  배송비
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>배송방법이 무료가 아닌경우 필수 입니다.</li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'gift_name',
              required: '',
              type: 'String',
              desc: '사은품',
            },
            {
              title: 'supply_price',
              required: '',
              type: 'Number',
              desc: '공급가',
            },
            {
              title: 'cost_price',
              required: '',
              type: 'Number',
              desc: '원가',
            },
            {
              title: 'street_price',
              required: '',
              type: 'Number',
              desc: '시중가',
            },
            {
              title: 'global_barcode_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  UPC/EAN 타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>UPC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EAN</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'global_barcode',
              required: '',
              type: 'Number',
              desc: 'UPC/EAN 코드',
            },
            {
              title: 'isbn',
              required: '',
              type: 'Number',
              desc: 'ISBN 코드',
            },
            {
              title: 'barcode',
              required: '',
              type: 'String',
              desc: '바코드',
            },
            {
              title: 'hscd',
              required: '',
              type: 'String',
              desc: 'HS코드',
            },
            {
              title: 'prod_weight',
              required: '',
              type: 'Number',
              desc: '상품무게',
            },
            {
              title: 'cert_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  인증정보타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>인증대상아님</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>인증유형등록</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>인증대상아님</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>상세설명내등록</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'certs',
              required: '',
              type: 'Array',
              desc: (
                <div>
                  인증정보
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>인증정보는 10개까지 등록 가능합니다.</li>
                    <li>
                      인증정보타입이 인증유형등록인 경우 필수로 입력해주셔야
                      합니다.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'cert_cd',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  인증유형
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>cert_01: 생활용품 안전인증</li>
                    <li>cert_02: 생활용품 안전확인</li>
                    <li>cert_03: 생활용품 어린이보호포장</li>
                    <li>cert_04: 생활용품 안전품질표시</li>
                    <li>cert_05: 생활용품 공급자적합성확인</li>
                    <li>cert_06: 전기용품 안전인증</li>
                    <li>cert_07: 전기용품 안전확인</li>
                    <li>cert_08: 전기용품 공급자적합성확인</li>
                    <li>cert_09: 방송통신용품 적합성인증확인</li>
                    <li>cert_10: 어린이제품 안전인증</li>
                    <li>cert_11: 어린이제품 안전확인</li>
                    <li>cert_12: 어린이제품 공급자적합성확인</li>
                    <li>cert_13: 위해우려제품 자가검사</li>
                    <li>cert_14: 방송통신기자재 잠정인증</li>
                    <li>cert_15: 방송통신기자재 적합등록</li>
                    <li>cert_16: 원산지증명</li> <li>cert_17: 해외안전인증</li>
                    <li>cert_18: 수입신고필증</li>
                    <li>cert_19: 친환경인증-무농약농산물</li>
                    <li>cert_20: 친환경인증-유기축산물</li>
                    <li>cert_21: 친환경인증-유기농산물</li>
                    <li>cert_22: 친환경인증-저농약농산물</li>
                    <li>cert_23: 친환경인증-무항생제축산물</li>
                    <li>cert_24: HACCP(위해요소 중점관리우수식품)</li>
                    <li>cert_25: GMP(우수건강기능식품 제조기준)</li>
                    <li>cert_26: GAP(농산물우수관리인증)</li>
                    <li>cert_27: 농산물이력추적관리</li>
                    <li>cert_28: 수산물품질인증</li>
                    <li>cert_29: 수산특산물품질인증</li>
                    <li>cert_30: 수산전통식품품질인증</li>
                    <li>cert_31: 친환경수산물품질인증</li>
                    <li>cert_32: 친환경수산가공품품질인증</li>
                    <li>cert_33: 전통식품 품질인증</li>
                    <li>cert_34: 유기가공식품 인증</li>
                    <li>cert_35: 가공식품 산업표준 KS인증</li>
                    <li>cert_36: 건강기능식품 광고사전심의</li>
                    <li>cert_37: 의료기기 광고사전심의</li>
                    <li>cert_38: 축산물 등급판정 확인서</li>
                    <li>cert_39: 도축검사증명서</li>
                    <li>cert_40: 식품명인(Grand master) 인증</li>
                    <li>cert_41: 사회적기업 인증</li>
                    <li>cert_42: 6차산업 인증</li>
                    <li>cert_43: 동물복지 인증</li>
                    <li>cert_44: 마을기업 인증</li>
                    <li>cert_45: 건강기능식품인증</li>
                    <li>cert_46: 생활화학제품</li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cert_exc_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  면제유형
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>kc_01: 안전기준준수대상</li>
                    <li>kc_02: 구매대행</li>
                    <li>kc_03: 병행수입</li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_01</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_02</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_03</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cert_ministry',
              required: '',
              type: 'String',
              desc: '인증기관',
              indent: 1,
            },
            {
              title: 'cert_no',
              required: 'O',
              type: 'String',
              desc: '인증번호',
              indent: 1,
            },
            {
              title: 'cert_model',
              required: '',
              type: 'String',
              desc: '인증모델',
              indent: 1,
            },
            {
              title: 'cert_cname',
              required: '',
              type: 'String',
              desc: '인증상호',
              indent: 1,
            },
            {
              title: 'cert_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  인증일
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>YYYY-MM-DD의 날짜형식으로 입력해주십시오.</li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cert_expire_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  인증만료일
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>YYYY-MM-DD의 날짜형식으로 입력해주십시오.</li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'add_desc_info',
              required: '',
              type: 'Object',
              desc: (
                <div>
                  등록 상품이 쇼핑몰 29CM인 경우만 상세 설명 이미지
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>객체의 키 값은 쇼핑몰 코드</li>
                    <li>
                      상세 설명 이미지가 하나 이상은 반드시 존재해야 됩니다.
                    </li>
                    <li>
                      [image_type]
                      <ul className='style' style={{ color: 'gray' }}>
                        <li>4: 기본 타입</li> <li>5: 영상 타입</li>
                        <li>6: 타이틀 구분 타입</li>
                        <li>7: 이미지 링크 타입</li>
                      </ul>
                    </li>
                  </ul>
                  <pre>
                    <span className='pun'>{'{'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>"image_info"</span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='pun'>[</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'    {'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>&nbsp;&nbsp;&nbsp;&nbsp;"title"</span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"타이틀"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"content"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"설명"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_url"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"IMG_URL"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_type"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='lit'>4</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'}'},</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'{'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>&nbsp;&nbsp;&nbsp;&nbsp;"title"</span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"타이틀"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"content"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"설명"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"added_url"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"VIDEO_URL"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_url"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"IMG_URL"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"added_type"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='lit'>1</span>
                    <span className='pun'>,</span>
                    <span className='pln'> </span>
                    <span className='com'> # 1: 유튜브, 2: 비메오</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_type"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='lit'>5</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'}'},</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'{'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>&nbsp;&nbsp;&nbsp;&nbsp;"title"</span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"타이틀"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_url"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='pun'>[</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'        {'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"content"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"설명"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"image_url"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"IMG_URL"</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'        }'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>&nbsp;&nbsp;&nbsp;&nbsp;],</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"is_layout"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"F"</span>
                    <span className='pun'>,</span>
                    <span className='pln'> </span>
                    <span className='com'>
                      {' '}
                      # "F": 레이아웃 1단, "T": 레이아웃 2단{' '}
                    </span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_type"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='lit'>6</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'}'},</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'{'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"added_url"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"IMG_LINK_URL"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_url"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"IMG_URL"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_type"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='lit'>7</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pln'> </span>
                    <span className='pun'>{'    }'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'}'}</span>
                    <span className='pln'> </span>
                  </pre>
                </div>
              ),
            },
          ],
          body_example: {
            c_sale_cd: '__AUTO__',
            sol_cate_no: 11354,
            shop_sale_name: 'api 테스트 상품',
            minor_yn: true,
            sale_price: 50000,
            sale_cnt_limit: 88,
            site_list: [
              {
                shop_cd: 'A112',
                shop_id: 'playauto',
                template_no: 49724,
              },
            ],
            std_ol_yn: 'N',
            opt_type: '독립형',
            opts: [
              {
                opt_sort1: '색상',
                opt_sort2: '',
                opt_sort3: '',
                opt_sort1_desc: '빨강',
                opt_sort2_desc: '',
                opt_sort3_desc: '',
                sku_cd: 'S999C90F24324',
                pack_unit: 1,
                add_price: 0,
                stock_cnt: 99,
                weight: 0.5,
                status: '정상',
              },
              {
                opt_sort1: '색상',
                opt_sort2: '',
                opt_sort3: '',
                opt_sort1_desc: '파랑',
                opt_sort2_desc: '',
                opt_sort3_desc: '',
                sku_cd: 'S999C47FA857424',
                pack_unit: 1,
                add_price: 5000,
                stock_cnt: 88,
                weight: '1',
                status: '품절',
              },
            ],
            input_opt_name: ['입력형옵션1', '입력형옵션2'],
            add_opts: [
              {
                opt_sort: '추가구매타입',
                opt_sort_desc: '추가구매1',
                price: 1000,
                stock_cnt: 20,
                sku_cd: 'S893688C196587',
                pack_unit: 2,
                weight: 0,
                status: '정상',
              },
              {
                opt_sort: '추가구매타입',
                opt_sort_desc: '추가구매2',
                price: 2000,
                stock_cnt: 30,
                sku_cd: 'S1880FB9324',
                pack_unit: 1,
                weight: 1,
                status: '품절',
              },
            ],
            tax_type: '과세',
            madein: {
              madein_no: 2,
              madein_etc: '',
              multi_yn: false,
            },
            made_date: '2020-01-01',
            expire_date: '2021-03-01',
            detail_desc: '상세설명',
            brand: '브랜드',
            model: '모델명',
            maker: '제조사',
            sale_img1: 'https://sample.com/productImage.jpg',
            prod_info: [
              {
                code: '01',
                prod_info_1: '면-95',
                prod_info_2: 'N',
                prod_info_3: '빨강,파랑',
                prod_info_4: '85,90',
                prod_info_5: '플레이오토',
                prod_info_6: 'O',
                prod_info_7: '플레이오토',
                prod_info_8: '중국',
                prod_info_9: '드라이크리닝해주세요',
                prod_info_10: '화기에 주의하세요',
                prod_info_11: '2016-09',
                prod_info_12: '구입일로부터 1년',
                prod_info_13: '플레이오토/070-123-4567',
                prod_info_14: '',
                prod_info_15: '',
                prod_info_16: '',
                prod_info_17: '',
                prod_info_18: '',
                prod_info_19: '',
                prod_info_20: '',
                prod_info_21: '',
                prod_info_22: '',
                prod_info_23: '',
                prod_info_24: '',
              },
              {
                code: '12',
                prod_info_1: '상세설명 참조',
                prod_info_2: '상세설명 참조',
                prod_info_3: '상세설명 참조',
                prod_info_4: '상세설명 참조',
                prod_info_5: '상세설명 참조',
                prod_info_6: '상세설명 참조',
                prod_info_7: '상세설명 참조',
                prod_info_8: '상세설명 참조',
                prod_info_9: '상세설명 참조',
                prod_info_10: '상세설명 참조',
                prod_info_11: '상세설명 참조',
                prod_info_12: '상세설명 참조',
                prod_info_13: '상세설명 참조',
              },
            ],
            add_desc_info: {
              image_info: [
                {
                  title: '타이틀',
                  content: '설명',
                  image_url: 'IMG_URL',
                  image_type: 4,
                },
              ],
            },
          },
          response: [
            {
              title: 'result',
              required: 'O',
              type: 'String',
              desc: '성공/실패',
            },
            {
              title: 'c_sale_cd',
              required: 'O',
              type: 'String',
              desc: '등록된 상품의 판매자관리코드',
            },
            {
              title: 'site_list',
              required: 'O',
              type: 'Object[]',
              desc: '등록된 상품 쇼핑몰 정보',
            },
            {
              title: 'shop_name',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 이름',
            },
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 코드',
            },
            {
              title: 'shop_id',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 아이디 (마스터 상품의 경우 빈값 출력)',
            },
            {
              title: 'ol_shop_no',
              required: 'O',
              type: 'Number',
              desc: '등록된 온라인상품 고유번호',
            },
            {
              title: 'messages',
              required: 'O',
              type: 'String[]',
              desc: '등록이 실패된 경우 에러메시지',
            },
          ],
          response_example: {
            result: '성공',
            c_sale_cd: 'm20200925497324',
            site_list: [
              {
                shop_name: '마스터 상품',
                shop_cd: 'Z000',
                shop_id: '',
                ol_shop_no: 11111111,
              },
              {
                shop_name: '더현대',
                shop_cd: 'A133',
                shop_id: 'playauto',
                ol_shop_no: 22222222,
              },
              {
                shop_name: '옥션',
                shop_cd: 'A001',
                shop_id: 'playauto',
                ol_shop_no: 33333333,
              },
            ],
            message: '',
          },
        },
      ],
    },
    {
      title: '온라인상품 상세 리스트 조회',
      url: '/doc/온라인상품 상세 리스트 조회',
      data: [
        {
          version: 'v1.1',
          url: '/product/online/detail-list/v1.1',
          authorized_m_no: [91809, 91810, 91812, 223054, 88912, 88913, 88916, 64370],
          desc: (
            <div>
              사용중인 솔루션에 등록된 온라인상품 상세정보 리스트를 가져옵니다.
              <br />
              <br />
              🚨 상세정보를 리스팅하는 API로, 상품리스트 조회 API 보다 조회
              소요시간이 더 길어질 수 있습니다.🚨
              <br />
              가급적{' '}
              <a href='/doc/상품리스트 조회' style={{ color: 'blue' }}>
                상품리스트 조회
              </a>{' '}
              혹은{' '}
              <a href='/doc/온라인상품 상세 조회' style={{ color: 'blue' }}>
                온라인상품 상세 조회
              </a>{' '}
              API를 이용해주시기 바랍니다.
            </div>
          ),
          method: 'POST',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'start',
              required: '',
              type: 'Number',
              desc: '검색 시작 값',
            },
            {
              title: 'length',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  조회할 상품 갯수
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      상세설명 생략 여부 (noDetailDesc) <code>false</code>일
                      경우 : Max value
                      <code>100</code>
                    </li>
                    <li>
                      상세설명 생략 여부 (noDetailDesc) <code>true</code>일 경우
                      : Max value
                      <code>500</code>
                    </li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>sku_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_sale_name</code>
                          </strong>
                        </td>
                        <td>
                          <strong>온라인상품명</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>c_sale_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>판매자관리코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>brand_code</code>
                          </strong>
                        </td>
                        <td>
                          <strong>브랜드코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>barcode</code>
                          </strong>
                        </td>
                        <td>
                          <strong>바코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>global_barcode</code>
                          </strong>
                        </td>
                        <td>
                          <strong>UPC/EAN코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>isbn</code>
                          </strong>
                        </td>
                        <td>
                          <strong>ISBN코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>model</code>
                          </strong>
                        </td>
                        <td>
                          <strong>모델명</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>keyword</code>
                          </strong>
                        </td>
                        <td>
                          <strong>키워드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_sale_no</code>
                          </strong>
                        </td>
                        <td>
                          <strong>쇼핑몰 상품코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>c_sale_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>판매자 관리코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>memo</code>
                          </strong>
                        </td>
                        <td>
                          <strong>메모</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>std_ol_group_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>그룹상품코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_promotion_name</code>
                          </strong>
                        </td>
                        <td>
                          <strong>프로모션용 상품명</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <strong>
                    <ul className='style'>
                      <li>
                        <code>값을 넣지않는경우 전체검색됩니다.</code>
                      </li>
                    </ul>
                  </strong>
                </div>
              ),
            },
            {
              title: 'search_word',
              required: '',
              type: 'String',
              desc: '검색어',
            },
            {
              title: 'search_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>partial</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>exact</code>
                          </strong>
                        </td>
                        <td>
                          <strong>완전일치</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>partial</code>
                          </strong>
                        </td>
                        <td>
                          <strong>부분일치</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'date_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  날짜검색타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>등록일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>mdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>수정일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>sale_etime</code>
                          </strong>
                        </td>
                        <td>
                          <strong>판매종료일</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sdate',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  검색시작일
                  <div className='description'>
                    ( YYYY-MM-DD | YYYY-MM-DD HH:MM:SS )
                  </div>
                </div>
              ),
            },
            {
              title: 'edate',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  검색종료일
                  <div className='description'>
                    ( YYYY-MM-DD | YYYY-MM-DD HH:MM:SS )
                  </div>
                </div>
              ),
            },
            {
              title: 'sol_cate_no',
              required: '',
              type: 'String',
              desc: '솔루션 카테고리 코드',
            },
            {
              title: 'opt_yn',
              required: '',
              type: 'String',
              desc: '옵션 사용여부',
            },
            {
              title: 'shop_cd',
              required: '',
              type: 'String',
              desc: (
                <div>
                  쇼핑몰코드
                  <div className='description'>
                    ( 옥션: A001, 지마켓: A006, 11번가: A112, 인터파크: A027...
                    )<br />
                    마스터상품 조회시 <code>only_mas</code> 로 입력
                  </div>
                </div>
              ),
            },
            {
              title: 'shop_id',
              required: '',
              type: 'String',
              desc: '쇼핑몰아이디',
            },
            {
              title: 'template_no',
              required: '',
              type: 'String',
              desc: '템플릿코드',
            },
            {
              title: 'addcontent_no',
              required: '',
              type: 'String',
              desc: '머리말/꼬리말 템플릿코드',
            },
            {
              title: 'sale_status',
              required: '',
              type: 'Array',
              desc: (
                <div>
                  상품 판매 상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>판매대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>승인대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>반려</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매중</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>종료대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>수정대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매중지</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>일시품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'depot_no',
              required: '',
              type: 'String',
              desc: '배송처 코드',
            },
            {
              title: 'opt_status',
              required: '',
              type: 'String',
              desc: '옵션 상태',
            },
            {
              title: 'noDetailDesc',
              required: '',
              type: 'Boolean',
              desc: '상세설명 생략 여부',
            },
            {
              title: 'multi_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  멀티검색 키
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>c_sale_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>판매자관리코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_sale_no</code>
                          </strong>
                        </td>
                        <td>
                          <strong>쇼핑몰상품코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>sku_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop</code>
                          </strong>
                        </td>
                        <td>
                          <strong>쇼핑몰아이디</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'multi_search_word',
              required: '',
              type: 'String',
              desc: (
                <div>
                  멀티검색어
                  <div className='description'>
                    {' '}
                    ( \n 으로 묶어서 전송 ) ex{'>'} 하나\n둘\n셋\n넷
                  </div>
                </div>
              ),
            },
            {
              title: 'nextday_ship_ol_yn',
              required: '',
              type: 'String',
              desc: (
                <div>
                  내일 배송 상품 여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>Y</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>N</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sub_sol_no_list',
              required: '',
              type: 'Number[]',
              desc: (
                <div>
                  하위계정 sol_no 리스트
                  <div className='description'>
                    (값이 없는 경우 하위계정 전체 조회)
                  </div>
                </div>
              ),
            },
          ],
          body_example: {
            start: 0,
            length: 100,
            sdate: '2020-07-20',
            edate: '2020-07-20',
            date_type: 'wdate',
            multi_shop_id: '',
            multi_type: 'c_sale_cd',
            search_key: 'all',
            search_type: 'partial',
            search_word: '',
            orderby: 'wdate desc',
            brand_code: '',
            catrgory_code: '',
            onlyNormal: true,
            prod_type: '',
            sale_status: '',
            std_ol_type: 'normal',
            memo_yn: '',
            template_yn: '',
            opt_yn: '',
            wid: '',
          },
          response: [
            {
              title: 'results',
              required: 'O',
              type: 'Object',
              desc: '검색결과는 판매자관리코드로 그룹화되어 판매자관리코드를 키로 가진 배열로 출력됩니다.',
            },
            {
              title: 'sol_no',
              required: 'O',
              type: 'Number(8)',
              desc: '솔루션번호',
              indent: 1,
            },
            {
              title: 'ol_shop_no',
              required: 'O',
              type: 'Number(10)',
              desc: '상품고유값',
              indent: 1,
            },
            {
              title: 'sale_no',
              required: 'O',
              type: 'Number(11)',
              desc: '판매번호',
              indent: 1,
            },
            {
              title: 'c_sale_cd',
              required: 'O',
              type: 'String(40)',
              desc: '판매자관리코드',
              indent: 1,
            },
            {
              title: 'sol_cate_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  솔루션 카테고리 번호
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String(4)',
              desc: '쇼핑몰 코드',
              indent: 1,
            },
            {
              title: 'shop_name',
              required: 'O',
              type: 'String(50)',
              desc: '쇼핑몰명',
              indent: 1,
            },
            {
              title: 'shop_id',
              required: 'O',
              type: 'String(40)',
              desc: '쇼핑몰 계정',
              indent: 1,
            },
            {
              title: 'std_ol_group_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  그룹고유값
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'template_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  템플릿코드
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'addcontent_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  머리말/꼬리말 템플릿코드
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'addcontent_name',
              required: 'O',
              type: 'String(50)',
              desc: (
                <div>
                  머리말/꼬리말 템플릿명
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_sale_name',
              required: 'O',
              type: 'String(200)',
              desc: '상품명',
              indent: 1,
            },
            {
              title: 'shop_promotion_name',
              required: 'O',
              type: 'String(100)',
              desc: '상품 프로모션명',
              indent: 1,
            },
            {
              title: 'sale_status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품판매상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>판매대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>승인대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>반려</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매중</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>종료대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>수정대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매중지</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>일시품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sale_cnt_limit',
              required: 'O',
              type: 'Number(10)',
              desc: '쇼핑몰 판매수량',
              indent: 1,
            },
            {
              title: 'sale_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  상품 금액
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cost_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  상품 원가
                  <div className='description'>
                    (data type: Decimal(12,2))
                    <div className='description' style={{ padding: '12px 0' }}>
                      Default value
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <strong>
                              <code>null</code>
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'supply_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  공급가
                  <div className='description'>
                    (data type: Decimal(12,2))
                    <div className='description' style={{ padding: '12px 0' }}>
                      Default value
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <strong>
                              <code>null</code>
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'street_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  시중가
                  <div className='description'>
                    (data type: Decimal(12,2))
                    <div className='description' style={{ padding: '12px 0' }}>
                      Default value
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <strong>
                              <code>null</code>
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'opt_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션구분
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>옵션없음</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>조합형</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>독립형</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'opt_sort1',
              required: 'O',
              type: 'String(20)',
              desc: (
                <div>
                  옵션명1
                  <div className='description'> (ex{'>'} 색상)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'opt_sort2',
              required: 'O',
              type: 'String(20)',
              desc: '옵션명2',
              indent: 1,
            },
            {
              title: 'opt_sort3',
              required: 'O',
              type: 'String(20)',
              desc: '옵션명3',
              indent: 1,
            },
            {
              title: 'madein_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  원산지 국내/해외 여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>국내</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>해외</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>원양산</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>기타</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'madein_group',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  원산지 시/도
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'madein_name',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  원산지 읍/면
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'madein_etc',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  기타원산지
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'multi_madin_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  복수원산지여부
                  <div className='description'>
                    (1: 복수원산지, 0: 복수원산지 아님)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'tax_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  과세여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>과세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>해외</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>원양산</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>기타</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ship_price_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  배송비 종류
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>무료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>조건부무료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>착불</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>선결제</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ship_price',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  배송비 금액
                  <div className='description'>(data type: Decimal(8,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_sale_no',
              required: 'O',
              type: 'String(40)',
              desc: (
                <div>
                  쇼핑몰 판매번호
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_sale_no_sub',
              required: 'O',
              type: 'String(80)',
              desc: (
                <div>
                  쇼핑몰 판매번호 서브
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sale_img_url',
              required: 'O',
              type: 'String(200)',
              desc: '이미지 URL',
              indent: 1,
            },
            {
              title: 'sale_img1',
              required: 'O',
              type: 'String(23)',
              desc: '기본이미지',
              indent: 1,
            },
            {
              title: 'sale_img2',
              required: 'O',
              type: 'String(23)',
              desc: '추가이미지 1',
              indent: 1,
            },
            {
              title: 'sale_img3',
              required: 'O',
              type: 'String(23)',
              desc: '추가이미지 2',
              indent: 1,
            },
            {
              title: 'sale_img4',
              required: 'O',
              type: 'String(23)',
              desc: '추가이미지 3',
              indent: 1,
            },
            {
              title: 'sale_img5',
              required: 'O',
              type: 'String(23)',
              desc: '추가이미지 4',
              indent: 1,
            },
            {
              title: 'sale_img6',
              required: 'O',
              type: 'String(23)',
              desc: '추가이미지 5',
              indent: 1,
            },
            {
              title: 'sale_img7',
              required: 'O',
              type: 'String(23)',
              desc: '추가이미지 6',
              indent: 1,
            },
            {
              title: 'sale_img8',
              required: 'O',
              type: 'String(23)',
              desc: '추가이미지 7',
              indent: 1,
            },
            {
              title: 'sale_img9',
              required: 'O',
              type: 'String(23)',
              desc: '추가이미지 8',
              indent: 1,
            },
            {
              title: 'sale_img10',
              required: 'O',
              type: 'String(23)',
              desc: '추가이미지 9',
              indent: 1,
            },
            {
              title: 'sale_img11',
              required: 'O',
              type: 'String(23)',
              desc: '추가이미지 10',
              indent: 1,
            },
            {
              title: 'sale_img12',
              required: 'O',
              type: 'String(23)',
              desc: '추가이미지 11',
              indent: 1,
            },
            {
              title: 'sale_img13',
              required: 'O',
              type: 'String(23)',
              desc: '추가이미지 12',
              indent: 1,
            },
            {
              title: 'sale_img14',
              required: 'O',
              type: 'String(23)',
              desc: '추가이미지 13',
              indent: 1,
            },
            {
              title: 'sale_img15',
              required: 'O',
              type: 'String(23)',
              desc: '추가이미지 14',
              indent: 1,
            },
            {
              title: 'detail_desc',
              required: 'O',
              type: 'String(16777215)',
              desc: '상품 상세설명',
              indent: 1,
            },
            {
              title: 'model',
              required: 'O',
              type: 'String(50)',
              desc: '모델명',
              indent: 1,
            },
            {
              title: 'model_no',
              required: 'O',
              type: 'String(50)',
              desc: '모델 번호',
              indent: 1,
            },
            {
              title: 'brand',
              required: 'O',
              type: 'String(50)',
              desc: '브랜드',
              indent: 1,
            },
            {
              title: 'maker',
              required: 'O',
              type: 'String(50)',
              desc: '제조사',
              indent: 1,
            },
            {
              title: 'adult_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  성인전용 여부
                  <div className='description'>
                    (1: 성인용품, 0: 성인용품 아님)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'isbn',
              required: 'O',
              type: 'Number(13)',
              desc: (
                <div>
                  isbn 번호
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'barcode',
              required: 'O',
              type: 'String(50)',
              desc: '바코드',
              indent: 1,
            },
            {
              title: 'cert_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  인증정보타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>인증유형등록</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>인증대상아님</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>상세설명내등록</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'made_date',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  제조일
                  <div className='description'>(YYYY-MM-DD)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'expire_date',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  유효일
                  <div className='description'>(YYYY-MM-DD)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'nextday_ship_ol_yn',
              required: 'O',
              type: 'String(1)',
              desc: (
                <div>
                  내일 배송 상품 여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>Y</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>N</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'nextday_ship_avial_yn',
              required: 'O',
              type: 'String(1)',
              desc: (
                <div>
                  내일 배송 가능 여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>Y</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>N</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'wdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  상품등록일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'mdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  상품수정일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'std_ol_group_cd',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  그룹 코드
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'std_ol_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  단일상품 여부
                  <div className='description'>(0: 일반상품, 1: 단일상품)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'template_name',
              required: 'O',
              type: 'String(80)',
              desc: (
                <div>
                  템플릿명
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'opt_sort1_desc',
              required: 'O',
              type: 'String(65535)',
              desc: (
                <div>
                  옵션값1
                  <div className='description'>(ex{'>'}빨강)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'opt_sort2_desc',
              required: 'O',
              type: 'String(65535)',
              desc: '옵션값2',
              indent: 1,
            },
            {
              title: 'opt_sort3_desc',
              required: 'O',
              type: 'String(65535)',
              desc: '옵션값3',
              indent: 1,
            },
            {
              title: 'sol_cate_name',
              required: 'O',
              type: 'String(60)',
              desc: (
                <div>
                  솔루션 카테고리명
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'std_ol_group_name',
              required: 'O',
              type: 'String(60)',
              desc: (
                <div>
                  그룹명
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'std_ol_group_seq',
              required: 'O',
              type: 'Number(3)',
              desc: (
                <div>
                  그룹내 상품 순번
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sale_stime',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  판매 시작일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sale_etime',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  판매 종료일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ship_price_free_criteria',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  배송비 조건부 무료
                  <div className='description'>
                    (ex{'>'} 50000 원 이상 무료인경우 50000)
                    <br />
                    (data type: Decimal(10,2))
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'pa_shop_cd',
              required: 'O',
              type: 'String(4)',
              desc: '상위 쇼핑몰 코드',
              indent: 1,
            },
            {
              title: 'pa_sol_cate_name',
              required: 'O',
              type: 'String(60)',
              desc: (
                <div>
                  상위 솔루션 카테고리명
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ol_cate_name',
              required: 'O',
              type: 'String(110)',
              desc: (
                <div>
                  상품 카테고리명
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'memo_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '메모 여부',
              indent: 1,
            },
            {
              title: 'memo_complete_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '메모 완료 여부',
              indent: 1,
            },
            {
              title: 'input_opt',
              required: 'O',
              type: 'String[]',
              desc: '입력형옵션',
              indent: 1,
            },
            {
              title: 'keywords',
              required: 'O',
              type: 'String[]',
              desc: '키워드',
              indent: 1,
            },
            {
              title: 'prod_noti_data',
              required: 'O',
              type: 'Object',
              desc: (
                <div>
                  상품정보제공고시
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      상품분류코드에 따라 infoDetail 안에 키 값이 달라질 수
                      있습니다.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'infoCode',
              required: 'O',
              type: 'String',
              desc: '상품분류코드',
              indent: 2,
            },
            {
              title: 'infoName',
              required: 'O',
              type: 'String',
              desc: '상품분류명',
              indent: 2,
            },
            {
              title: 'infoDetail',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시 데이터',
              indent: 2,
            },
            {
              title: 'options',
              required: 'O',
              type: 'Object[]',
              desc: (
                <div>
                  옵션
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      옵션없음이라도 SKU상품이 매칭되어있는경우 하나의 데이터가
                      나옵니다.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_weight',
              required: 'O',
              type: 'Number(7)',
              desc: (
                <div>
                  상품무게
                  <div className='description'>(data type: Decimal(7,2))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'sale_opt_seq',
              required: 'O',
              type: 'Number(5)',
              desc: '옵션순번',
              indent: 2,
            },
            {
              title: 'opt_status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'opt_name1',
              required: 'O',
              type: 'String(80)',
              desc: '옵션값1',
              indent: 2,
            },
            {
              title: 'opt_name2',
              required: 'O',
              type: 'String(80)',
              desc: (
                <div>
                  옵션값2
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'opt_name3',
              required: 'O',
              type: 'String(80)',
              desc: (
                <div>
                  옵션값3
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'opt_add_price',
              required: 'O',
              type: 'Number(9)',
              desc: '옵션가격',
              indent: 2,
            },
            {
              title: 'stock_cnt',
              required: 'O',
              type: 'Number(8)',
              desc: '옵션수량',
              indent: 2,
            },
            {
              title: 'model_no',
              required: 'O',
              type: 'String(50)',
              desc: '모델번호',
              indent: 2,
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String(40)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품 sku코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'prod_name',
              required: 'O',
              type: 'String(200)',
              desc: (
                <div>
                  매칭된 SKU상품명
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'prod_img',
              required: 'O',
              type: 'String(255)',
              desc: (
                <div>
                  옵션이미지
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'global_barcode',
              required: 'O',
              type: 'String(13)',
              desc: (
                <div>
                  UPC/EAN 코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'global_barcode_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  UPC/EAN
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>UPC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EAN</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>KAN</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>JAN</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>HS코드</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'barcode',
              required: 'O',
              type: 'String(40)',
              desc: (
                <div>
                  바코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'stock_cd',
              required: 'O',
              type: 'String(100',
              desc: (
                <div>
                  재고관리코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'stock_cnt_enable',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  판매가능재고
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'stock_cnt_safe',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  안전재고
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'sale_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품의 판매가
                  <div className='description'>
                    (재고관리 미사용시 미노출)
                    <br />
                    (data type: Decimal(12,2))
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'cost_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품의 원가
                  <div className='description'>
                    (재고관리 미사용시 미노출)
                    <br />
                    (data type: Decimal(12,2))
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'supply_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품의 공급가
                  <div className='description'>
                    (재고관리 미사용시 미노출)
                    <br />
                    (data type: Decimal(12,2))
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'prod_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품 고유번호
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'depot_no',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  옵션에 매칭된 배송처번호
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'pack_unit',
              required: 'O',
              type: 'Number(6)',
              desc: (
                <div>
                  출고수량
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'set_name',
              required: 'O',
              type: 'String(200)',
              desc: (
                <div>
                  옵션에 매칭된 세트상품명
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'set_sale_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  옵션에 매칭된 세트상품 가격
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'add_opt',
              required: 'O',
              type: 'Object[]',
              desc: '추가구매옵션',
              indent: 1,
            },
            {
              title: 'sale_opt_seq',
              required: 'O',
              type: 'Number(5)',
              desc: '옵션순번',
              indent: 2,
            },
            {
              title: 'opt_status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'add_item_name',
              required: 'O',
              type: 'String(50)',
              desc: '추가항목명',
              indent: 2,
            },
            {
              title: 'add_item_opt_name',
              required: 'O',
              type: 'String(50)',
              desc: '추가옵션명',
              indent: 2,
            },
            {
              title: 'opt_add_price',
              required: 'O',
              type: 'Number(9)',
              desc: '추가금액',
              indent: 2,
            },
            {
              title: 'stock_cnt',
              required: 'O',
              type: 'Number(9)',
              desc: '옵션수량',
              indent: 2,
            },
            {
              title: 'model_no',
              required: 'O',
              type: 'String(50)',
              desc: '모델 번호',
              indent: 2,
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String(40)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품 sku코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'prod_name',
              required: 'O',
              type: 'String(200)',
              desc: (
                <div>
                  매칭된 SKU상품명
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'prod_img',
              required: 'O',
              type: 'String(255)',
              desc: (
                <div>
                  옵션이미지
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'global_barcode',
              required: 'O',
              type: 'String(13)',
              desc: (
                <div>
                  UPC/EAN 코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'global_barcode_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품 sku코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>UPC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EAN</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>KAN</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>JAN</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>HS코드</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'prod_weight',
              required: 'O',
              type: 'Number(7)',
              desc: (
                <div>
                  UPC/EAN 코드
                  <div className='description'>
                    (재고관리 미사용시 미노출)
                    <br />
                    (data type: Decimal(7,2))
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'stock_cnt_enable',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  판매가능재고
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'stock_cnt_safe',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  안전재고
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'sale_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품의 판매가
                  <div className='description'>
                    (재고관리 미사용시 미노출)
                    <br />
                    (data type: Decimal(12,2))
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'cost_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품의 원가
                  <div className='description'>
                    (재고관리 미사용시 미노출)
                    <br />
                    (data type: Decimal(12,2))
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'supply_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품의 공급가
                  <div className='description'>
                    (재고관리 미사용시 미노출)
                    <br />
                    (data type: Decimal(12,2))
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'prod_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  매칭된 SKU상품 고유번호
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'depot_no',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  옵션에 매칭된 배송처 번호
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'pack_unit',
              required: 'O',
              type: 'Number(6)',
              desc: (
                <div>
                  출고수량
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'shop_cate1',
              required: 'O',
              type: 'String(55)',
              desc: (
                <div>
                  카테고리 분류1
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_cate2',
              required: 'O',
              type: 'String(55)',
              desc: (
                <div>
                  카테고리 분류2
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_cate3',
              required: 'O',
              type: 'String(55)',
              desc: (
                <div>
                  카테고리 분류3
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_cate4',
              required: 'O',
              type: 'String(55)',
              desc: (
                <div>
                  카테고리 분류4
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_cate5',
              required: 'O',
              type: 'String(55)',
              desc: (
                <div>
                  카테고리 분류5
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_cate1_cd',
              required: 'O',
              type: 'String(70)',
              desc: (
                <div>
                  카테고리 분류 코드1
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_cate2_cd',
              required: 'O',
              type: 'String(70)',
              desc: (
                <div>
                  카테고리 분류 코드2
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'certDataList',
              required: 'O',
              type: 'Object[]',
              desc: '인증정보',
              indent: 1,
            },
            {
              title: 'ol_shop_cert_seq',
              required: 'O',
              type: 'Number(1)',
              desc: '인증번호 순번',
              indent: 2,
            },
            {
              title: 'cert_cd',
              required: 'O',
              type: 'String(7)',
              desc: (
                <div>
                  인증유형
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>cert_01: 생활용품 안전인증</li>{' '}
                    <li>cert_02: 생활용품 안전확인</li>{' '}
                    <li>cert_03: 생활용품 어린이보호포장</li>{' '}
                    <li>cert_04: 생활용품 안전품질표시</li>{' '}
                    <li>cert_05: 생활용품 공급자적합성확인</li>{' '}
                    <li>cert_06: 전기용품 안전인증</li>{' '}
                    <li>cert_07: 전기용품 안전확인</li>{' '}
                    <li>cert_08: 전기용품 공급자적합성확인</li>{' '}
                    <li>cert_09: 방송통신용품 적합성인증확인</li>{' '}
                    <li>cert_10: 어린이제품 안전인증</li>{' '}
                    <li>cert_11: 어린이제품 안전확인</li>{' '}
                    <li>cert_12: 어린이제품 공급자적합성확인</li>{' '}
                    <li>cert_13: 위해우려제품 자가검사</li>{' '}
                    <li>cert_14: 방송통신기자재 잠정인증</li>{' '}
                    <li>cert_15: 방송통신기자재 적합등록</li>{' '}
                    <li>cert_16: 원산지증명</li> <li>cert_17: 해외안전인증</li>{' '}
                    <li>cert_18: 수입신고필증</li>{' '}
                    <li>cert_19: 친환경인증-무농약농산물</li>{' '}
                    <li>cert_20: 친환경인증-유기축산물</li>{' '}
                    <li>cert_21: 친환경인증-유기농산물</li>{' '}
                    <li>cert_22: 친환경인증-저농약농산물</li>{' '}
                    <li>cert_23: 친환경인증-무항생제축산물</li>{' '}
                    <li>cert_24: HACCP(위해요소 중점관리우수식품)</li>{' '}
                    <li>cert_25: GMP(우수건강기능식품 제조기준)</li>{' '}
                    <li>cert_26: GAP(농산물우수관리인증)</li>{' '}
                    <li>cert_27: 농산물이력추적관리</li>{' '}
                    <li>cert_28: 수산물품질인증</li>{' '}
                    <li>cert_29: 수산특산물품질인증</li>{' '}
                    <li>cert_30: 수산전통식품품질인증</li>{' '}
                    <li>cert_31: 친환경수산물품질인증</li>{' '}
                    <li>cert_32: 친환경수산가공품품질인증</li>{' '}
                    <li>cert_33: 전통식품 품질인증</li>{' '}
                    <li>cert_34: 유기가공식품 인증</li>{' '}
                    <li>cert_35: 가공식품 산업표준 KS인증</li>{' '}
                    <li>cert_36: 건강기능식품 광고사전심의</li>{' '}
                    <li>cert_37: 의료기기 광고사전심의</li>{' '}
                    <li>cert_38: 축산물 등급판정 확인서</li>{' '}
                    <li>cert_39: 도축검사증명서</li>{' '}
                    <li>cert_40: 식품명인(Grand master) 인증</li>{' '}
                    <li>cert_41: 사회적기업 인증</li>{' '}
                    <li>cert_42: 6차산업 인증</li>{' '}
                    <li>cert_43: 동물복지 인증</li>{' '}
                    <li>cert_44: 마을기업 인증</li>{' '}
                    <li>cert_45: 건강기능식품인증</li>{' '}
                    <li>cert_46: 생활화학제품</li>{' '}
                  </ul>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'cert_exc_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  면제유형
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>kc_01: 안전기준준수대상</li>
                    <li>kc_02: 구매대행</li>
                    <li>kc_03: 병행수입</li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_01</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_02</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_03</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'cert_ministry',
              required: 'O',
              type: 'String',
              desc: '인증기관',
              indent: 2,
            },
            {
              title: 'cert_no',
              required: 'O',
              type: 'String',
              desc: '인증번호',
              indent: 2,
            },
            {
              title: 'cert_model',
              required: 'O',
              type: 'String',
              desc: '인증모델',
              indent: 2,
            },
            {
              title: 'cert_cname',
              required: 'O',
              type: 'String',
              desc: '인증상호',
              indent: 2,
            },
            {
              title: 'cert_date',
              required: 'O',
              type: 'String',
              desc: '인증일',
              indent: 2,
            },
            {
              title: 'cert_expire_date',
              required: 'O',
              type: 'String',
              desc: '인증만료일',
              indent: 2,
            },
          ],
          response_example: {
            results: {
              m20211102e4f1ea3a6: [
                {
                  sol_no: 1,
                  ol_shop_no: 1,
                  sale_no: 1,
                  c_sale_cd: 'm20211102abc83pf',
                  sol_cate_no: 1,
                  shop_cd: 'Z000',
                  shop_name: '마스터 온라인상품',
                  shop_id: '',
                  std_ol_group_no: null,
                  template_no: null,
                  addcontent_no: null,
                  addcontent_name: null,
                  shop_sale_name: '소니캠코더/300만화소/최고의선택',
                  shop_promotion_name: '',
                  sale_status: null,
                  sale_cnt_limit: 100,
                  sale_price: 1000000,
                  cost_price: 0,
                  supply_price: 0,
                  street_price: 0,
                  opt_type: '조합형',
                  opt_sort1: '색상',
                  opt_sort2: '',
                  opt_sort3: '',
                  madein_type: '국내',
                  madein_group: '강원',
                  madein_name: '속초시',
                  madein_etc: null,
                  multi_madein_yn: 0,
                  tax_type: '과세',
                  ship_price_type: '무료',
                  ship_price: 0,
                  shop_sale_no: null,
                  shop_sale_no_sub: null,
                  sale_img_url: 'https://s3-ap-northeast-2.amazonaws.com/',
                  sale_img1:
                    'https://s3-ap-northeast-2.amazonaws.com/202111021271.jpg',
                  sale_img2:
                    'https://s3-ap-northeast-2.amazonaws.com/202111021272.jpg',
                  sale_img3: '',
                  sale_img4: '',
                  sale_img5: '',
                  sale_img6: '',
                  sale_img7: '',
                  sale_img8: '',
                  sale_img9: '',
                  sale_img10: '',
                  detail_desc: '테스트상품입니다. 구매시 취소됩니다.',
                  model: '모델명',
                  brand: '브랜드명',
                  maker: '제조사명',
                  adult_yn: 0,
                  isbn: null,
                  barcode: '',
                  cert_type: '인증대상아님',
                  prod_weight: 0,
                  made_date: null,
                  expire_date: null,
                  nextday_ship_ol_yn: 'N',
                  nextday_ship_avail_yn: 'N',
                  wdate: '2021-11-02 16:33:27',
                  mdate: '2021-11-03 11:16:56',
                  std_ol_group_cd: null,
                  std_ol_yn: null,
                  template_name: null,
                  opt_sort1_desc: '블랙,화이트',
                  opt_sort2_desc: '',
                  opt_sort3_desc: '',
                  sol_cate_name:
                    '기본카테고리: 남성의류 > 가디건 > 브이넥가디건',
                  sale_img11: '',
                  sale_img12: '',
                  sale_img13: '',
                  sale_img14: '',
                  sale_img15: '',
                  std_ol_group_name: null,
                  std_ol_group_seq: null,
                  sale_stime: null,
                  sale_etime: null,
                  ship_price_free_criteria: 0,
                  pa_shop_cd: null,
                  pa_sol_cate_name: '기본카테고리',
                  ol_cate_name: null,
                  memo_yn: 0,
                  memo_complete_yn: 0,
                  input_opt: [],
                  keywords: ['특가', '테스트제품', '구매금지'],
                  prod_noti_data: {
                    infoCode: 'Wear2023',
                    infoName: '의류',
                    infoDetail: {
                      material: '1',
                      color: '2',
                      size: '3',
                      manufacturer: '4',
                      made_in: '5',
                      caution: '6',
                      release: '7',
                      warranty: '8',
                      customer_service: '9',
                    },
                  },
                  options: [
                    {
                      sale_opt_seq: 1,
                      opt_status: '정상',
                      opt_name1: '블랙',
                      opt_name2: '',
                      opt_name3: '',
                      opt_add_price: 0,
                      stock_cnt: 100,
                      model_no: 'ABC.011102',
                      sku_cd: null,
                      prod_name: null,
                      prod_img: null,
                      global_barcode: null,
                      global_barcode_type: null,
                      prod_weight: null,
                      barcode: null,
                      stock_cd: null,
                      stock_cnt_enable: null,
                      stock_cnt_safe: null,
                      sale_price: null,
                      cost_price: null,
                      supply_price: null,
                      prod_no: null,
                      depot_no: null,
                      pack_unit: null,
                      set_name: null,
                      set_sale_price: null,
                    },
                    {
                      sale_opt_seq: 2,
                      opt_status: '정상',
                      opt_name1: '화이트',
                      opt_name2: '',
                      opt_name3: '',
                      opt_add_price: 0,
                      stock_cnt: 100,
                      model_no: null,
                      sku_cd: null,
                      prod_name: null,
                      prod_img: null,
                      global_barcode: null,
                      global_barcode_type: null,
                      prod_weight: null,
                      barcode: null,
                      stock_cd: null,
                      stock_cnt_enable: null,
                      stock_cnt_safe: null,
                      sale_price: null,
                      cost_price: null,
                      supply_price: null,
                      prod_no: null,
                      depot_no: null,
                      pack_unit: null,
                      set_name: null,
                      set_sale_price: null,
                    },
                  ],
                  add_opt: [],
                  certDataList: [],
                },
                {
                  sol_no: 1,
                  ol_shop_no: 1,
                  sale_no: 1,
                  c_sale_cd: 'm20211102abc83pf',
                  sol_cate_no: 1,
                  shop_cd: 'A112',
                  shop_name: '11번가',
                  shop_id: 'test0001',
                  std_ol_group_no: null,
                  template_no: null,
                  addcontent_no: null,
                  addcontent_name: null,
                  shop_sale_name: '소니캠코더/300만화소/최고의선택',
                  shop_promotion_name: null,
                  sale_status: '판매대기',
                  sale_cnt_limit: 100,
                  sale_price: 1000000,
                  cost_price: 0,
                  supply_price: 0,
                  street_price: 0,
                  opt_type: '조합형',
                  opt_sort1: 'SIZE',
                  opt_sort2: '',
                  opt_sort3: '',
                  madein_type: '국내',
                  madein_group: '강원',
                  madein_name: '속초시',
                  madein_etc: null,
                  multi_madein_yn: 0,
                  tax_type: '과세',
                  ship_price_type: '무료',
                  ship_price: 0,
                  shop_sale_no: null,
                  shop_sale_no_sub: null,
                  sale_img_url: 'https://s3-ap-northeast-2.amazonaws.com/',
                  sale_img1:
                    'https://s3-ap-northeast-2.amazonaws.com/202111021271.jpg',
                  sale_img2:
                    'https://s3-ap-northeast-2.amazonaws.com/202111021272.jpg',
                  sale_img3: '',
                  sale_img4: '',
                  sale_img5: '',
                  sale_img6: '',
                  sale_img7: '',
                  sale_img8: '',
                  sale_img9: '',
                  sale_img10: '',
                  detail_desc: '테스트상품입니다. 구매시 취소됩니다.',
                  model: '모델명',
                  brand: '브랜드명',
                  maker: '제조사명',
                  adult_yn: 0,
                  isbn: null,
                  barcode: '',
                  cert_type: '인증유형등록',
                  prod_weight: 0,
                  made_date: null,
                  expire_date: null,
                  nextday_ship_ol_yn: 'Y',
                  nextday_ship_avail_yn: 'N',
                  wdate: '2021-11-02 16:33:28',
                  mdate: '2021-11-03 14:48:35',
                  std_ol_group_cd: null,
                  std_ol_yn: 0,
                  template_name: null,
                  opt_sort1_desc: 'L,XL',
                  opt_sort2_desc: '',
                  opt_sort3_desc: '',
                  sol_cate_name:
                    '기본카테고리: 남성의류 > 가디건 > 브이넥가디건',
                  sale_img11: '',
                  sale_img12: '',
                  sale_img13: '',
                  sale_img14: '',
                  sale_img15: '',
                  std_ol_group_name: null,
                  std_ol_group_seq: null,
                  sale_stime: null,
                  sale_etime: null,
                  ship_price_free_criteria: 0,
                  pa_shop_cd: null,
                  pa_sol_cate_name: '기본카테고리',
                  ol_cate_name: null,
                  memo_yn: 0,
                  memo_complete_yn: 0,
                  input_opt: [],
                  keywords: ['특가', '테스트제품', '구매금지'],
                  prod_noti_data: {
                    infoCode: 'Wear2023',
                    infoName: '의류',
                    infoDetail: {
                      material: '1',
                      color: '2',
                      size: '3',
                      manufacturer: '4',
                      made_in: '5',
                      caution: '6',
                      release: '7',
                      warranty: '8',
                      customer_service: '9',
                    },
                  },
                  options: [
                    {
                      sale_opt_seq: 1,
                      opt_status: '정상',
                      opt_name1: 'L',
                      opt_name2: '',
                      opt_name3: '',
                      opt_add_price: 0,
                      stock_cnt: 10,
                      model_no: null,
                      sku_cd: 'S2106600DE00',
                      prod_name: '테스트SKU상품1',
                      prod_img: '',
                      global_barcode: '',
                      global_barcode_type: null,
                      prod_weight: 0,
                      barcode: '',
                      stock_cd: '',
                      stock_cnt_enable: 18,
                      stock_cnt_safe: 10,
                      sale_price: 0,
                      cost_price: 0,
                      supply_price: 0,
                      prod_no: 9999999999,
                      depot_no: 1,
                      pack_unit: 1,
                      set_name: '테스트세트상품명',
                      set_sale_price: 0,
                    },
                    {
                      sale_opt_seq: 1,
                      opt_status: '정상',
                      opt_name1: 'L',
                      opt_name2: '',
                      opt_name3: '',
                      opt_add_price: 0,
                      stock_cnt: 10,
                      model_no: 'ABC.9340100',
                      sku_cd: 'S24517ABC000E',
                      prod_name: '테스트SKU상품2',
                      prod_img: '',
                      global_barcode: '',
                      global_barcode_type: null,
                      prod_weight: 0,
                      barcode: '',
                      stock_cd: '',
                      stock_cnt_enable: 16,
                      stock_cnt_safe: 10,
                      sale_price: 0,
                      cost_price: 0,
                      supply_price: 0,
                      prod_no: 9999999998,
                      depot_no: 1,
                      pack_unit: 2,
                      set_name: '테스트세트상품명',
                      set_sale_price: 0,
                    },
                    {
                      sale_opt_seq: 2,
                      opt_status: '정상',
                      opt_name1: 'XL',
                      opt_name2: '',
                      opt_name3: '',
                      opt_add_price: 0,
                      stock_cnt: 10,
                      model_no: 'ABC.889900',
                      sku_cd: 'S2843EEEEE5D',
                      prod_name: '상품테스트',
                      prod_img:
                        'https://s3-ap-northeast-2.amazonaws.com/test.jpg',
                      global_barcode: null,
                      global_barcode_type: null,
                      prod_weight: 0,
                      barcode: '',
                      stock_cd: null,
                      stock_cnt_enable: 99999,
                      stock_cnt_safe: 0,
                      sale_price: 9999990,
                      cost_price: 0,
                      supply_price: 0,
                      prod_no: 9999999997,
                      depot_no: 1,
                      pack_unit: 1,
                      set_name: null,
                      set_sale_price: null,
                    },
                  ],
                  add_opt: [
                    {
                      sale_opt_seq: 3,
                      opt_status: '정상',
                      add_item_name: '추가항목 1',
                      add_item_opt_name: '추가옵션1',
                      opt_add_price: 1500,
                      stock_cnt: 10,
                      model_no: 'BBC.AB0340',
                      sku_cd: 'S30431FFFF1',
                      prod_name: 'SKU상품명99',
                      prod_img:
                        'https://s3-ap-northeast-2.amazonaws.com/24.jpg',
                      global_barcode: null,
                      global_barcode_type: null,
                      prod_weight: 0,
                      stock_cnt_enable: 99999,
                      stock_cnt_safe: 0,
                      sale_price: 1000000,
                      cost_price: 0,
                      supply_price: 0,
                      prod_no: 9999999996,
                      depot_no: 1,
                      pack_unit: 1,
                    },
                    {
                      sale_opt_seq: 4,
                      opt_status: '정상',
                      add_item_name: '추가항목 1',
                      add_item_opt_name: '추가옵션2',
                      opt_add_price: 3000,
                      stock_cnt: 11,
                      model_no: 'BBC.ED0034',
                      sku_cd: 'S407DD5DDDD1',
                      prod_name: 'SKU상품명100',
                      prod_img:
                        'https://s3-ap-northeast-2.amazonaws.com/27.jpg',
                      global_barcode: null,
                      global_barcode_type: null,
                      prod_weight: 0,
                      stock_cnt_enable: 99999,
                      stock_cnt_safe: 0,
                      sale_price: 1000000,
                      cost_price: 0,
                      supply_price: 0,
                      prod_no: 9999999995,
                      depot_no: 1,
                      pack_unit: 1,
                    },
                  ],
                  certDataList: [
                    {
                      ol_shop_cert_seq: 1,
                      cert_cd: 'cert_01',
                      cert_exc_type: 'kc_01',
                      cert_ministry: '테스트기관',
                      cert_no: '0000001',
                      cert_model: '테스트모델',
                      cert_cname: '테스트상호',
                      cert_date: '2020-10-29',
                      cert_expire_date: '2024-02-28',
                    },
                  ],
                },
              ],
            },
          },
        },
        {
          version: 'v1.0',
          url: '/product/online/detail-list',
          authorized_m_no: [91809, 91810, 91812, 223054, 88912, 88913, 88916, 64370],
          desc: (
            <div>
              사용중인 솔루션에 등록된 온라인상품 상세정보 리스트를 가져옵니다.
              <br />
              <br />
              🚨 상세정보를 리스팅하는 API로, 상품리스트 조회 API 보다 조회
              소요시간이 더 길어질 수 있습니다.🚨
              <br />
              가급적{' '}
              <a href='/doc/상품리스트 조회' style={{ color: 'blue' }}>
                상품리스트 조회
              </a>{' '}
              혹은{' '}
              <a href='/doc/온라인상품 상세 조회' style={{ color: 'blue' }}>
                온라인상품 상세 조회
              </a>{' '}
              API를 이용해주시기 바랍니다.
            </div>
          ),
          method: 'POST',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'start',
              required: '',
              type: 'Number',
              desc: '검색 시작 값',
            },
            {
              title: 'length',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  조회할 상품 갯수
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      상세설명 생략 여부 (noDetailDesc) <code>false</code>일
                      경우 : Max value
                      <code>100</code>
                    </li>
                    <li>
                      상세설명 생략 여부 (noDetailDesc) <code>true</code>일 경우
                      : Max value
                      <code>500</code>
                    </li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>sku_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_sale_name</code>
                          </strong>
                        </td>
                        <td>
                          <strong>온라인상품명</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>c_sale_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>판매자관리코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>brand_code</code>
                          </strong>
                        </td>
                        <td>
                          <strong>브랜드코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>barcode</code>
                          </strong>
                        </td>
                        <td>
                          <strong>바코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>global_barcode</code>
                          </strong>
                        </td>
                        <td>
                          <strong>UPC/EAN코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>isbn</code>
                          </strong>
                        </td>
                        <td>
                          <strong>ISBN코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>model</code>
                          </strong>
                        </td>
                        <td>
                          <strong>모델명</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>keyword</code>
                          </strong>
                        </td>
                        <td>
                          <strong>키워드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_sale_no</code>
                          </strong>
                        </td>
                        <td>
                          <strong>쇼핑몰 상품코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>c_sale_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>판매자 관리코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>memo</code>
                          </strong>
                        </td>
                        <td>
                          <strong>메모</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>std_ol_group_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>그룹상품코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_promotion_name</code>
                          </strong>
                        </td>
                        <td>
                          <strong>프로모션용 상품명</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <strong>
                    <ul className='style'>
                      <li>
                        <code>값을 넣지않는경우 전체검색됩니다.</code>
                      </li>
                    </ul>
                  </strong>
                </div>
              ),
            },
            {
              title: 'search_word',
              required: '',
              type: 'String',
              desc: '검색어',
            },
            {
              title: 'search_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>partial</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>exact</code>
                          </strong>
                        </td>
                        <td>
                          <strong>완전일치</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>partial</code>
                          </strong>
                        </td>
                        <td>
                          <strong>부분일치</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'date_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  날짜검색타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>wdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>등록일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>mdate</code>
                          </strong>
                        </td>
                        <td>
                          <strong>수정일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>sale_etime</code>
                          </strong>
                        </td>
                        <td>
                          <strong>판매종료일</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sdate',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  검색시작일
                  <div className='description'>
                    ( YYYY-MM-DD | YYYY-MM-DD HH:MM:SS )
                  </div>
                </div>
              ),
            },
            {
              title: 'edate',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  검색종료일
                  <div className='description'>
                    ( YYYY-MM-DD | YYYY-MM-DD HH:MM:SS )
                  </div>
                </div>
              ),
            },
            {
              title: 'sol_cate_no',
              required: '',
              type: 'String',
              desc: '솔루션 카테고리 코드',
            },
            {
              title: 'opt_yn',
              required: '',
              type: 'String',
              desc: '옵션 사용여부',
            },
            {
              title: 'shop_cd',
              required: '',
              type: 'String',
              desc: (
                <div>
                  쇼핑몰코드
                  <div className='description'>
                    ( 옥션: A001, 지마켓: A006, 11번가: A112, 인터파크: A027...
                    )<br />
                    마스터상품 조회시 <code>only_mas</code> 로 입력
                  </div>
                </div>
              ),
            },
            {
              title: 'shop_id',
              required: '',
              type: 'String',
              desc: '쇼핑몰아이디',
            },
            {
              title: 'template_no',
              required: '',
              type: 'String',
              desc: '템플릿코드',
            },
            {
              title: 'addcontent_no',
              required: '',
              type: 'String',
              desc: '머리말/꼬리말 템플릿코드',
            },
            {
              title: 'sale_status',
              required: '',
              type: 'Array',
              desc: (
                <div>
                  상품 판매 상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>판매대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>승인대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>반려</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매중</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>종료대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>수정대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매중지</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>일시품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'depot_no',
              required: '',
              type: 'String',
              desc: '배송처 코드',
            },
            {
              title: 'opt_status',
              required: '',
              type: 'String',
              desc: '옵션 상태',
            },
            {
              title: 'noDetailDesc',
              required: '',
              type: 'Boolean',
              desc: '상세설명 생략 여부',
            },
            {
              title: 'multi_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  멀티검색 키
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>c_sale_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>판매자관리코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop_sale_no</code>
                          </strong>
                        </td>
                        <td>
                          <strong>쇼핑몰상품코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>sku_cd</code>
                          </strong>
                        </td>
                        <td>
                          <strong>SKU코드</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>shop</code>
                          </strong>
                        </td>
                        <td>
                          <strong>쇼핑몰아이디</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'multi_search_word',
              required: '',
              type: 'String',
              desc: (
                <div>
                  멀티검색어
                  <div className='description'>
                    {' '}
                    ( \n 으로 묶어서 전송 ) ex{'>'} 하나\n둘\n셋\n넷
                  </div>
                </div>
              ),
            },
            {
              title: 'nextday_ship_ol_yn',
              required: '',
              type: 'String',
              desc: (
                <div>
                  내일 배송 상품 여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>Y</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>N</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sub_sol_no_list',
              required: '',
              type: 'Number[]',
              desc: (
                <div>
                  하위계정 sol_no 리스트
                  <div className='description'>
                    (값이 없는 경우 하위계정 전체 조회)
                  </div>
                </div>
              ),
            },
          ],
          body_example: {
            start: 0,
            length: 100,
            sdate: '2020-07-20',
            edate: '2020-07-20',
            date_type: 'wdate',
            multi_shop_id: '',
            multi_type: 'c_sale_cd',
            search_key: 'all',
            search_type: 'partial',
            search_word: '',
            orderby: 'wdate desc',
            brand_code: '',
            catrgory_code: '',
            onlyNormal: true,
            prod_type: '',
            sale_status: '',
            std_ol_type: 'normal',
            memo_yn: '',
            template_yn: '',
            opt_yn: '',
            wid: '',
          },
          response: [
            {
              title: 'results',
              required: 'O',
              type: 'Object',
              desc: '검색결과는 판매자관리코드로 그룹화되어 판매자관리코드를 키로 가진 배열로 출력됩니다.',
            },
            {
              title: 'sol_no',
              required: 'O',
              type: 'Number(8)',
              desc: '솔루션번호',
              indent: 1,
            },
            {
              title: 'ol_shop_no',
              required: 'O',
              type: 'Number(10)',
              desc: '상품고유값',
              indent: 1,
            },
            {
              title: 'sale_no',
              required: 'O',
              type: 'Number(11)',
              desc: '판매번호',
              indent: 1,
            },
            {
              title: 'c_sale_cd',
              required: 'O',
              type: 'String(40)',
              desc: '판매자관리코드',
              indent: 1,
            },
            {
              title: 'sol_cate_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  솔루션 카테고리 번호
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String(4)',
              desc: '쇼핑몰 코드',
              indent: 1,
            },
            {
              title: 'shop_name',
              required: 'O',
              type: 'String(50)',
              desc: '쇼핑몰명',
              indent: 1,
            },
            {
              title: 'shop_id',
              required: 'O',
              type: 'String(40)',
              desc: '쇼핑몰 계정',
              indent: 1,
            },
            {
              title: 'std_ol_group_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  그룹고유값
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'template_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  템플릿코드
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'addcontent_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  머리말/꼬리말 템플릿코드
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'addcontent_name',
              required: 'O',
              type: 'String(50)',
              desc: (
                <div>
                  머리말/꼬리말 템플릿명
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_sale_name',
              required: 'O',
              type: 'String(200)',
              desc: '상품명',
              indent: 1,
            },
            {
              title: 'shop_promotion_name',
              required: 'O',
              type: 'String(100)',
              desc: '상품 프로모션명',
              indent: 1,
            },
            {
              title: 'sale_status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품판매상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>판매대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>승인대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>반려</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매중</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>종료대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>수정대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매중지</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>일시품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sale_cnt_limit',
              required: 'O',
              type: 'Number(10)',
              desc: '쇼핑몰 판매수량',
              indent: 1,
            },
            {
              title: 'sale_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  상품 금액
                  <div className='description'>(data type: Decimal(12,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cost_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  상품 원가
                  <div className='description'>
                    (data type: Decimal(12,2))
                    <div className='description' style={{ padding: '12px 0' }}>
                      Default value
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <strong>
                              <code>null</code>
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'supply_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  공급가
                  <div className='description'>
                    (data type: Decimal(12,2))
                    <div className='description' style={{ padding: '12px 0' }}>
                      Default value
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <strong>
                              <code>null</code>
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'street_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  시중가
                  <div className='description'>
                    (data type: Decimal(12,2))
                    <div className='description' style={{ padding: '12px 0' }}>
                      Default value
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <strong>
                              <code>null</code>
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'opt_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션구분
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>옵션없음</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>조합형</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>독립형</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'opt_sort1',
              required: 'O',
              type: 'String(20)',
              desc: (
                <div>
                  옵션명1
                  <div className='description'> (ex{'>'} 색상)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'opt_sort2',
              required: 'O',
              type: 'String(20)',
              desc: '옵션명2',
              indent: 1,
            },
            {
              title: 'opt_sort3',
              required: 'O',
              type: 'String(20)',
              desc: '옵션명3',
              indent: 1,
            },
            {
              title: 'madein_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  원산지 국내/해외 여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>국내</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>해외</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>원양산</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>기타</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'madein_group',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  원산지 시/도
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'madein_name',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  원산지 읍/면
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'madein_etc',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  기타원산지
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'multi_madin_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  복수원산지여부
                  <div className='description'>
                    (1: 복수원산지, 0: 복수원산지 아님)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'tax_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  과세여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>과세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>해외</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>원양산</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>기타</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ship_price_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  배송비 종류
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>무료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>조건부무료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>착불</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>선결제</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ship_price',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  배송비 금액
                  <div className='description'>(data type: Decimal(8,2))</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_sale_no',
              required: 'O',
              type: 'String(40)',
              desc: (
                <div>
                  쇼핑몰 판매번호
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_sale_no_sub',
              required: 'O',
              type: 'String(80)',
              desc: (
                <div>
                  쇼핑몰 판매번호 서브
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sale_img_url',
              required: 'O',
              type: 'String(200)',
              desc: '이미지 URL',
              indent: 1,
            },
            {
              title: 'sale_img1',
              required: 'O',
              type: 'String(23)',
              desc: '기본이미지',
              indent: 1,
            },
            {
              title: 'sale_img2',
              required: 'O',
              type: 'String(23)',
              desc: '추가이미지 1',
              indent: 1,
            },
            {
              title: 'sale_img3',
              required: 'O',
              type: 'String(23)',
              desc: '추가이미지 2',
              indent: 1,
            },
            {
              title: 'sale_img4',
              required: 'O',
              type: 'String(23)',
              desc: '추가이미지 3',
              indent: 1,
            },
            {
              title: 'sale_img5',
              required: 'O',
              type: 'String(23)',
              desc: '추가이미지 4',
              indent: 1,
            },
            {
              title: 'sale_img6',
              required: 'O',
              type: 'String(23)',
              desc: '추가이미지 5',
              indent: 1,
            },
            {
              title: 'sale_img7',
              required: 'O',
              type: 'String(23)',
              desc: '추가이미지 6',
              indent: 1,
            },
            {
              title: 'sale_img8',
              required: 'O',
              type: 'String(23)',
              desc: '추가이미지 7',
              indent: 1,
            },
            {
              title: 'sale_img9',
              required: 'O',
              type: 'String(23)',
              desc: '추가이미지 8',
              indent: 1,
            },
            {
              title: 'sale_img10',
              required: 'O',
              type: 'String(23)',
              desc: '추가이미지 9',
              indent: 1,
            },
            {
              title: 'sale_img11',
              required: 'O',
              type: 'String(23)',
              desc: '추가이미지 10',
              indent: 1,
            },
            {
              title: 'sale_img12',
              required: 'O',
              type: 'String(23)',
              desc: '추가이미지 11',
              indent: 1,
            },
            {
              title: 'sale_img13',
              required: 'O',
              type: 'String(23)',
              desc: '추가이미지 12',
              indent: 1,
            },
            {
              title: 'sale_img14',
              required: 'O',
              type: 'String(23)',
              desc: '추가이미지 13',
              indent: 1,
            },
            {
              title: 'sale_img15',
              required: 'O',
              type: 'String(23)',
              desc: '추가이미지 14',
              indent: 1,
            },
            {
              title: 'detail_desc',
              required: 'O',
              type: 'String(16777215)',
              desc: '상품 상세설명',
              indent: 1,
            },
            {
              title: 'model',
              required: 'O',
              type: 'String(50)',
              desc: '모델명',
              indent: 1,
            },
            {
              title: 'model_no',
              required: 'O',
              type: 'String(50)',
              desc: '모델 번호',
              indent: 1,
            },
            {
              title: 'brand',
              required: 'O',
              type: 'String(50)',
              desc: '브랜드',
              indent: 1,
            },
            {
              title: 'maker',
              required: 'O',
              type: 'String(50)',
              desc: '제조사',
              indent: 1,
            },
            {
              title: 'adult_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  성인전용 여부
                  <div className='description'>
                    (1: 성인용품, 0: 성인용품 아님)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'isbn',
              required: 'O',
              type: 'Number(13)',
              desc: (
                <div>
                  isbn 번호
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'barcode',
              required: 'O',
              type: 'String(50)',
              desc: '바코드',
              indent: 1,
            },
            {
              title: 'cert_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  인증정보타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>인증유형등록</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>인증대상아님</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>상세설명내등록</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'made_date',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  제조일
                  <div className='description'>(YYYY-MM-DD)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'expire_date',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  유효일
                  <div className='description'>(YYYY-MM-DD)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'nextday_ship_ol_yn',
              required: 'O',
              type: 'String(1)',
              desc: (
                <div>
                  내일 배송 상품 여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>Y</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>N</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'nextday_ship_avial_yn',
              required: 'O',
              type: 'String(1)',
              desc: (
                <div>
                  내일 배송 가능 여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>Y</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>N</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'wdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  상품등록일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'mdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  상품수정일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'std_ol_group_cd',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  그룹 코드
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'std_ol_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  단일상품 여부
                  <div className='description'>(0: 일반상품, 1: 단일상품)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'template_name',
              required: 'O',
              type: 'String(80)',
              desc: (
                <div>
                  템플릿명
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'opt_sort1_desc',
              required: 'O',
              type: 'String(65535)',
              desc: (
                <div>
                  옵션값1
                  <div className='description'>(ex{'>'}빨강)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'opt_sort2_desc',
              required: 'O',
              type: 'String(65535)',
              desc: '옵션값2',
              indent: 1,
            },
            {
              title: 'opt_sort3_desc',
              required: 'O',
              type: 'String(65535)',
              desc: '옵션값3',
              indent: 1,
            },
            {
              title: 'sol_cate_name',
              required: 'O',
              type: 'String(60)',
              desc: (
                <div>
                  솔루션 카테고리명
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'std_ol_group_name',
              required: 'O',
              type: 'String(60)',
              desc: (
                <div>
                  그룹명
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'std_ol_group_seq',
              required: 'O',
              type: 'Number(3)',
              desc: (
                <div>
                  그룹내 상품 순번
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sale_stime',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  판매 시작일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sale_etime',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  판매 종료일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ship_price_free_criteria',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  배송비 조건부 무료
                  <div className='description'>
                    (ex{'>'} 50000 원 이상 무료인경우 50000)
                    <br />
                    (data type: Decimal(10,2))
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'pa_shop_cd',
              required: 'O',
              type: 'String(4)',
              desc: '상위 쇼핑몰 코드',
              indent: 1,
            },
            {
              title: 'pa_sol_cate_name',
              required: 'O',
              type: 'String(60)',
              desc: (
                <div>
                  상위 솔루션 카테고리명
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ol_cate_name',
              required: 'O',
              type: 'String(110)',
              desc: (
                <div>
                  상품 카테고리명
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'memo_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '메모 여부',
              indent: 1,
            },
            {
              title: 'memo_complete_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '메모 완료 여부',
              indent: 1,
            },
            {
              title: 'input_opt',
              required: 'O',
              type: 'String[]',
              desc: '입력형옵션',
              indent: 1,
            },
            {
              title: 'keywords',
              required: 'O',
              type: 'String[]',
              desc: '키워드',
              indent: 1,
            },
            {
              title: 'prod_noti_data',
              required: 'O',
              type: 'Object',
              desc: (
                <div>
                  상품정보제공고시
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      prod_info_1, prod_info_2 등은 상품정보 제공고시 타입이
                      따라 갯수가 바뀔수 있음.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'infoCode',
              required: 'O',
              type: 'String(20)',
              desc: '상품분류코드',
              indent: 2,
            },
            {
              title: 'infoName',
              required: 'O',
              type: 'String',
              desc: '상품분류명',
              indent: 2,
            },
            {
              title: 'prod_info_1',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시1',
              indent: 2,
            },
            {
              title: 'prod_info_2',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시2',
              indent: 2,
            },
            {
              title: 'prod_info_3',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시3',
              indent: 2,
            },
            {
              title: 'prod_info_4',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시4',
              indent: 2,
            },
            {
              title: 'prod_info_5',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시5',
              indent: 2,
            },
            {
              title: 'options',
              required: 'O',
              type: 'Object[]',
              desc: (
                <div>
                  옵션
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      옵션없음이라도 SKU상품이 매칭되어있는경우 하나의 데이터가
                      나옵니다.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_weight',
              required: 'O',
              type: 'Number(7)',
              desc: (
                <div>
                  상품무게
                  <div className='description'>(data type: Decimal(7,2))</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'sale_opt_seq',
              required: 'O',
              type: 'Number(5)',
              desc: '옵션순번',
              indent: 2,
            },
            {
              title: 'opt_status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'opt_name1',
              required: 'O',
              type: 'String(80)',
              desc: '옵션값1',
              indent: 2,
            },
            {
              title: 'opt_name2',
              required: 'O',
              type: 'String(80)',
              desc: (
                <div>
                  옵션값2
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'opt_name3',
              required: 'O',
              type: 'String(80)',
              desc: (
                <div>
                  옵션값3
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'opt_add_price',
              required: 'O',
              type: 'Number(9)',
              desc: '옵션가격',
              indent: 2,
            },
            {
              title: 'stock_cnt',
              required: 'O',
              type: 'Number(8)',
              desc: '옵션수량',
              indent: 2,
            },
            {
              title: 'model_no',
              required: 'O',
              type: 'String(50)',
              desc: '모델번호',
              indent: 2,
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String(40)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품 sku코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'prod_name',
              required: 'O',
              type: 'String(200)',
              desc: (
                <div>
                  매칭된 SKU상품명
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'prod_img',
              required: 'O',
              type: 'String(255)',
              desc: (
                <div>
                  옵션이미지
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'global_barcode',
              required: 'O',
              type: 'String(13)',
              desc: (
                <div>
                  UPC/EAN 코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'global_barcode_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  UPC/EAN
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>UPC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EAN</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>KAN</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>JAN</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>HS코드</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'barcode',
              required: 'O',
              type: 'String(40)',
              desc: (
                <div>
                  바코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'stock_cd',
              required: 'O',
              type: 'String(100',
              desc: (
                <div>
                  재고관리코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'stock_cnt_enable',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  판매가능재고
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'stock_cnt_safe',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  안전재고
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'sale_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품의 판매가
                  <div className='description'>
                    (재고관리 미사용시 미노출)
                    <br />
                    (data type: Decimal(12,2))
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'cost_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품의 원가
                  <div className='description'>
                    (재고관리 미사용시 미노출)
                    <br />
                    (data type: Decimal(12,2))
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'supply_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품의 공급가
                  <div className='description'>
                    (재고관리 미사용시 미노출)
                    <br />
                    (data type: Decimal(12,2))
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'prod_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품 고유번호
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'depot_no',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  옵션에 매칭된 배송처번호
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'pack_unit',
              required: 'O',
              type: 'Number(6)',
              desc: (
                <div>
                  출고수량
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'set_name',
              required: 'O',
              type: 'String(200)',
              desc: (
                <div>
                  옵션에 매칭된 세트상품명
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'set_sale_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  옵션에 매칭된 세트상품 가격
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'add_opt',
              required: 'O',
              type: 'Object[]',
              desc: '추가구매옵션',
              indent: 1,
            },
            {
              title: 'sale_opt_seq',
              required: 'O',
              type: 'Number(5)',
              desc: '옵션순번',
              indent: 2,
            },
            {
              title: 'opt_status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'add_item_name',
              required: 'O',
              type: 'String(50)',
              desc: '추가항목명',
              indent: 2,
            },
            {
              title: 'add_item_opt_name',
              required: 'O',
              type: 'String(50)',
              desc: '추가옵션명',
              indent: 2,
            },
            {
              title: 'opt_add_price',
              required: 'O',
              type: 'Number(9)',
              desc: '추가금액',
              indent: 2,
            },
            {
              title: 'stock_cnt',
              required: 'O',
              type: 'Number(9)',
              desc: '옵션수량',
              indent: 2,
            },
            {
              title: 'model_no',
              required: 'O',
              type: 'String(50)',
              desc: '모델 번호',
              indent: 2,
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String(40)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품 sku코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'prod_name',
              required: 'O',
              type: 'String(200)',
              desc: (
                <div>
                  매칭된 SKU상품명
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'prod_img',
              required: 'O',
              type: 'String(255)',
              desc: (
                <div>
                  옵션이미지
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'global_barcode',
              required: 'O',
              type: 'String(13)',
              desc: (
                <div>
                  UPC/EAN 코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'global_barcode_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품 sku코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>UPC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EAN</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>KAN</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>JAN</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>HS코드</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'prod_weight',
              required: 'O',
              type: 'Number(7)',
              desc: (
                <div>
                  UPC/EAN 코드
                  <div className='description'>
                    (재고관리 미사용시 미노출)
                    <br />
                    (data type: Decimal(7,2))
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'stock_cnt_enable',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  판매가능재고
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'stock_cnt_safe',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  안전재고
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'sale_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품의 판매가
                  <div className='description'>
                    (재고관리 미사용시 미노출)
                    <br />
                    (data type: Decimal(12,2))
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'cost_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품의 원가
                  <div className='description'>
                    (재고관리 미사용시 미노출)
                    <br />
                    (data type: Decimal(12,2))
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'supply_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품의 공급가
                  <div className='description'>
                    (재고관리 미사용시 미노출)
                    <br />
                    (data type: Decimal(12,2))
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'prod_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  매칭된 SKU상품 고유번호
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'depot_no',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  옵션에 매칭된 배송처 번호
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'pack_unit',
              required: 'O',
              type: 'Number(6)',
              desc: (
                <div>
                  출고수량
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'shop_cate1',
              required: 'O',
              type: 'String(55)',
              desc: (
                <div>
                  카테고리 분류1
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_cate2',
              required: 'O',
              type: 'String(55)',
              desc: (
                <div>
                  카테고리 분류2
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_cate3',
              required: 'O',
              type: 'String(55)',
              desc: (
                <div>
                  카테고리 분류3
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_cate4',
              required: 'O',
              type: 'String(55)',
              desc: (
                <div>
                  카테고리 분류4
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_cate5',
              required: 'O',
              type: 'String(55)',
              desc: (
                <div>
                  카테고리 분류5
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_cate1_cd',
              required: 'O',
              type: 'String(70)',
              desc: (
                <div>
                  카테고리 분류 코드1
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_cate2_cd',
              required: 'O',
              type: 'String(70)',
              desc: (
                <div>
                  카테고리 분류 코드2
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'certDataList',
              required: 'O',
              type: 'Object[]',
              desc: '인증정보',
              indent: 1,
            },
            {
              title: 'ol_shop_cert_seq',
              required: 'O',
              type: 'Number(1)',
              desc: '인증번호 순번',
              indent: 2,
            },
            {
              title: 'cert_cd',
              required: 'O',
              type: 'String(7)',
              desc: (
                <div>
                  인증유형
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>cert_01: 생활용품 안전인증</li>{' '}
                    <li>cert_02: 생활용품 안전확인</li>{' '}
                    <li>cert_03: 생활용품 어린이보호포장</li>{' '}
                    <li>cert_04: 생활용품 안전품질표시</li>{' '}
                    <li>cert_05: 생활용품 공급자적합성확인</li>{' '}
                    <li>cert_06: 전기용품 안전인증</li>{' '}
                    <li>cert_07: 전기용품 안전확인</li>{' '}
                    <li>cert_08: 전기용품 공급자적합성확인</li>{' '}
                    <li>cert_09: 방송통신용품 적합성인증확인</li>{' '}
                    <li>cert_10: 어린이제품 안전인증</li>{' '}
                    <li>cert_11: 어린이제품 안전확인</li>{' '}
                    <li>cert_12: 어린이제품 공급자적합성확인</li>{' '}
                    <li>cert_13: 위해우려제품 자가검사</li>{' '}
                    <li>cert_14: 방송통신기자재 잠정인증</li>{' '}
                    <li>cert_15: 방송통신기자재 적합등록</li>{' '}
                    <li>cert_16: 원산지증명</li> <li>cert_17: 해외안전인증</li>{' '}
                    <li>cert_18: 수입신고필증</li>{' '}
                    <li>cert_19: 친환경인증-무농약농산물</li>{' '}
                    <li>cert_20: 친환경인증-유기축산물</li>{' '}
                    <li>cert_21: 친환경인증-유기농산물</li>{' '}
                    <li>cert_22: 친환경인증-저농약농산물</li>{' '}
                    <li>cert_23: 친환경인증-무항생제축산물</li>{' '}
                    <li>cert_24: HACCP(위해요소 중점관리우수식품)</li>{' '}
                    <li>cert_25: GMP(우수건강기능식품 제조기준)</li>{' '}
                    <li>cert_26: GAP(농산물우수관리인증)</li>{' '}
                    <li>cert_27: 농산물이력추적관리</li>{' '}
                    <li>cert_28: 수산물품질인증</li>{' '}
                    <li>cert_29: 수산특산물품질인증</li>{' '}
                    <li>cert_30: 수산전통식품품질인증</li>{' '}
                    <li>cert_31: 친환경수산물품질인증</li>{' '}
                    <li>cert_32: 친환경수산가공품품질인증</li>{' '}
                    <li>cert_33: 전통식품 품질인증</li>{' '}
                    <li>cert_34: 유기가공식품 인증</li>{' '}
                    <li>cert_35: 가공식품 산업표준 KS인증</li>{' '}
                    <li>cert_36: 건강기능식품 광고사전심의</li>{' '}
                    <li>cert_37: 의료기기 광고사전심의</li>{' '}
                    <li>cert_38: 축산물 등급판정 확인서</li>{' '}
                    <li>cert_39: 도축검사증명서</li>{' '}
                    <li>cert_40: 식품명인(Grand master) 인증</li>{' '}
                    <li>cert_41: 사회적기업 인증</li>{' '}
                    <li>cert_42: 6차산업 인증</li>{' '}
                    <li>cert_43: 동물복지 인증</li>{' '}
                    <li>cert_44: 마을기업 인증</li>{' '}
                    <li>cert_45: 건강기능식품인증</li>{' '}
                    <li>cert_46: 생활화학제품</li>{' '}
                  </ul>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'cert_exc_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  면제유형
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>kc_01: 안전기준준수대상</li>
                    <li>kc_02: 구매대행</li>
                    <li>kc_03: 병행수입</li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_01</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_02</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_03</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'cert_ministry',
              required: 'O',
              type: 'String',
              desc: '인증기관',
              indent: 2,
            },
            {
              title: 'cert_no',
              required: 'O',
              type: 'String',
              desc: '인증번호',
              indent: 2,
            },
            {
              title: 'cert_model',
              required: 'O',
              type: 'String',
              desc: '인증모델',
              indent: 2,
            },
            {
              title: 'cert_cname',
              required: 'O',
              type: 'String',
              desc: '인증상호',
              indent: 2,
            },
            {
              title: 'cert_date',
              required: 'O',
              type: 'String',
              desc: '인증일',
              indent: 2,
            },
            {
              title: 'cert_expire_date',
              required: 'O',
              type: 'String',
              desc: '인증만료일',
              indent: 2,
            },
          ],
          response_example: {
            results: {
              m20211102e4f1ea3a6: [
                {
                  sol_no: 1,
                  ol_shop_no: 1,
                  sale_no: 1,
                  c_sale_cd: 'm20211102abc83pf',
                  sol_cate_no: 1,
                  shop_cd: 'Z000',
                  shop_name: '마스터 온라인상품',
                  shop_id: '',
                  std_ol_group_no: null,
                  template_no: null,
                  addcontent_no: null,
                  addcontent_name: null,
                  shop_sale_name: '소니캠코더/300만화소/최고의선택',
                  shop_promotion_name: '',
                  sale_status: null,
                  sale_cnt_limit: 100,
                  sale_price: 1000000,
                  cost_price: 0,
                  supply_price: 0,
                  street_price: 0,
                  opt_type: '조합형',
                  opt_sort1: '색상',
                  opt_sort2: '',
                  opt_sort3: '',
                  madein_type: '국내',
                  madein_group: '강원',
                  madein_name: '속초시',
                  madein_etc: null,
                  multi_madein_yn: 0,
                  tax_type: '과세',
                  ship_price_type: '무료',
                  ship_price: 0,
                  shop_sale_no: null,
                  shop_sale_no_sub: null,
                  sale_img_url: 'https://s3-ap-northeast-2.amazonaws.com/',
                  sale_img1:
                    'https://s3-ap-northeast-2.amazonaws.com/202111021271.jpg',
                  sale_img2:
                    'https://s3-ap-northeast-2.amazonaws.com/202111021272.jpg',
                  sale_img3: '',
                  sale_img4: '',
                  sale_img5: '',
                  sale_img6: '',
                  sale_img7: '',
                  sale_img8: '',
                  sale_img9: '',
                  sale_img10: '',
                  detail_desc: '테스트상품입니다. 구매시 취소됩니다.',
                  model: '모델명',
                  brand: '브랜드명',
                  maker: '제조사명',
                  adult_yn: 0,
                  isbn: null,
                  barcode: '',
                  cert_type: '인증대상아님',
                  prod_weight: 0,
                  made_date: null,
                  expire_date: null,
                  nextday_ship_ol_yn: 'N',
                  nextday_ship_avail_yn: 'N',
                  wdate: '2021-11-02 16:33:27',
                  mdate: '2021-11-03 11:16:56',
                  std_ol_group_cd: null,
                  std_ol_yn: null,
                  template_name: null,
                  opt_sort1_desc: '블랙,화이트',
                  opt_sort2_desc: '',
                  opt_sort3_desc: '',
                  sol_cate_name:
                    '기본카테고리: 남성의류 > 가디건 > 브이넥가디건',
                  sale_img11: '',
                  sale_img12: '',
                  sale_img13: '',
                  sale_img14: '',
                  sale_img15: '',
                  std_ol_group_name: null,
                  std_ol_group_seq: null,
                  sale_stime: null,
                  sale_etime: null,
                  ship_price_free_criteria: 0,
                  pa_shop_cd: null,
                  pa_sol_cate_name: '기본카테고리',
                  ol_cate_name: null,
                  memo_yn: 0,
                  memo_complete_yn: 0,
                  input_opt: [],
                  keywords: ['특가', '테스트제품', '구매금지'],
                  prod_noti_data: {
                    infoCode: '01',
                    infoName: '의류',
                    prod_info_1: '상품상세설명 참조',
                    prod_info_2: 'N',
                    prod_info_3: '상품상세설명 참조',
                    prod_info_4: '상품상세설명 참조',
                    prod_info_5: '상품상세설명 참조',
                    prod_info_6: 'N',
                    prod_info_7: '상품상세설명 참조',
                    prod_info_8: '상품상세설명 참조',
                    prod_info_9: '상품상세설명 참조',
                    prod_info_10: '상품상세설명 참조',
                    prod_info_11: '상품상세설명 참조',
                    prod_info_12: '상품상세설명 참조',
                    prod_info_13: '상품상세설명 참조',
                  },
                  options: [
                    {
                      sale_opt_seq: 1,
                      opt_status: '정상',
                      opt_name1: '블랙',
                      opt_name2: '',
                      opt_name3: '',
                      opt_add_price: 0,
                      stock_cnt: 100,
                      model_no: 'ABC.011102',
                      sku_cd: null,
                      prod_name: null,
                      prod_img: null,
                      global_barcode: null,
                      global_barcode_type: null,
                      prod_weight: null,
                      barcode: null,
                      stock_cd: null,
                      stock_cnt_enable: null,
                      stock_cnt_safe: null,
                      sale_price: null,
                      cost_price: null,
                      supply_price: null,
                      prod_no: null,
                      depot_no: null,
                      pack_unit: null,
                      set_name: null,
                      set_sale_price: null,
                    },
                    {
                      sale_opt_seq: 2,
                      opt_status: '정상',
                      opt_name1: '화이트',
                      opt_name2: '',
                      opt_name3: '',
                      opt_add_price: 0,
                      stock_cnt: 100,
                      model_no: null,
                      sku_cd: null,
                      prod_name: null,
                      prod_img: null,
                      global_barcode: null,
                      global_barcode_type: null,
                      prod_weight: null,
                      barcode: null,
                      stock_cd: null,
                      stock_cnt_enable: null,
                      stock_cnt_safe: null,
                      sale_price: null,
                      cost_price: null,
                      supply_price: null,
                      prod_no: null,
                      depot_no: null,
                      pack_unit: null,
                      set_name: null,
                      set_sale_price: null,
                    },
                  ],
                  add_opt: [],
                  certDataList: [],
                },
                {
                  sol_no: 1,
                  ol_shop_no: 1,
                  sale_no: 1,
                  c_sale_cd: 'm20211102abc83pf',
                  sol_cate_no: 1,
                  shop_cd: 'A112',
                  shop_name: '11번가',
                  shop_id: 'test0001',
                  std_ol_group_no: null,
                  template_no: null,
                  addcontent_no: null,
                  addcontent_name: null,
                  shop_sale_name: '소니캠코더/300만화소/최고의선택',
                  shop_promotion_name: null,
                  sale_status: '판매대기',
                  sale_cnt_limit: 100,
                  sale_price: 1000000,
                  cost_price: 0,
                  supply_price: 0,
                  street_price: 0,
                  opt_type: '조합형',
                  opt_sort1: 'SIZE',
                  opt_sort2: '',
                  opt_sort3: '',
                  madein_type: '국내',
                  madein_group: '강원',
                  madein_name: '속초시',
                  madein_etc: null,
                  multi_madein_yn: 0,
                  tax_type: '과세',
                  ship_price_type: '무료',
                  ship_price: 0,
                  shop_sale_no: null,
                  shop_sale_no_sub: null,
                  sale_img_url: 'https://s3-ap-northeast-2.amazonaws.com/',
                  sale_img1:
                    'https://s3-ap-northeast-2.amazonaws.com/202111021271.jpg',
                  sale_img2:
                    'https://s3-ap-northeast-2.amazonaws.com/202111021272.jpg',
                  sale_img3: '',
                  sale_img4: '',
                  sale_img5: '',
                  sale_img6: '',
                  sale_img7: '',
                  sale_img8: '',
                  sale_img9: '',
                  sale_img10: '',
                  detail_desc: '테스트상품입니다. 구매시 취소됩니다.',
                  model: '모델명',
                  brand: '브랜드명',
                  maker: '제조사명',
                  adult_yn: 0,
                  isbn: null,
                  barcode: '',
                  cert_type: '인증유형등록',
                  prod_weight: 0,
                  made_date: null,
                  expire_date: null,
                  nextday_ship_ol_yn: 'Y',
                  nextday_ship_avail_yn: 'N',
                  wdate: '2021-11-02 16:33:28',
                  mdate: '2021-11-03 14:48:35',
                  std_ol_group_cd: null,
                  std_ol_yn: 0,
                  template_name: null,
                  opt_sort1_desc: 'L,XL',
                  opt_sort2_desc: '',
                  opt_sort3_desc: '',
                  sol_cate_name:
                    '기본카테고리: 남성의류 > 가디건 > 브이넥가디건',
                  sale_img11: '',
                  sale_img12: '',
                  sale_img13: '',
                  sale_img14: '',
                  sale_img15: '',
                  std_ol_group_name: null,
                  std_ol_group_seq: null,
                  sale_stime: null,
                  sale_etime: null,
                  ship_price_free_criteria: 0,
                  pa_shop_cd: null,
                  pa_sol_cate_name: '기본카테고리',
                  ol_cate_name: null,
                  memo_yn: 0,
                  memo_complete_yn: 0,
                  input_opt: [],
                  keywords: ['특가', '테스트제품', '구매금지'],
                  prod_noti_data: {
                    infoCode: '01',
                    infoName: '의류',
                    prod_info_1: '상품상세설명 참조',
                    prod_info_2: 'N',
                    prod_info_3: '상품상세설명 참조',
                    prod_info_4: '상품상세설명 참조',
                    prod_info_5: '상품상세설명 참조',
                    prod_info_6: 'N',
                    prod_info_7: '상품상세설명 참조',
                    prod_info_8: '상품상세설명 참조',
                    prod_info_9: '상품상세설명 참조',
                    prod_info_10: '상품상세설명 참조',
                    prod_info_11: '상품상세설명 참조',
                    prod_info_12: '상품상세설명 참조',
                    prod_info_13: '상품상세설명 참조',
                  },
                  options: [
                    {
                      sale_opt_seq: 1,
                      opt_status: '정상',
                      opt_name1: 'L',
                      opt_name2: '',
                      opt_name3: '',
                      opt_add_price: 0,
                      stock_cnt: 10,
                      model_no: null,
                      sku_cd: 'S2106600DE00',
                      prod_name: '테스트SKU상품1',
                      prod_img: '',
                      global_barcode: '',
                      global_barcode_type: null,
                      prod_weight: 0,
                      barcode: '',
                      stock_cd: '',
                      stock_cnt_enable: 18,
                      stock_cnt_safe: 10,
                      sale_price: 0,
                      cost_price: 0,
                      supply_price: 0,
                      prod_no: 9999999999,
                      depot_no: 1,
                      pack_unit: 1,
                      set_name: '테스트세트상품명',
                      set_sale_price: 0,
                    },
                    {
                      sale_opt_seq: 1,
                      opt_status: '정상',
                      opt_name1: 'L',
                      opt_name2: '',
                      opt_name3: '',
                      opt_add_price: 0,
                      stock_cnt: 10,
                      model_no: 'ABC.9340100',
                      sku_cd: 'S24517ABC000E',
                      prod_name: '테스트SKU상품2',
                      prod_img: '',
                      global_barcode: '',
                      global_barcode_type: null,
                      prod_weight: 0,
                      barcode: '',
                      stock_cd: '',
                      stock_cnt_enable: 16,
                      stock_cnt_safe: 10,
                      sale_price: 0,
                      cost_price: 0,
                      supply_price: 0,
                      prod_no: 9999999998,
                      depot_no: 1,
                      pack_unit: 2,
                      set_name: '테스트세트상품명',
                      set_sale_price: 0,
                    },
                    {
                      sale_opt_seq: 2,
                      opt_status: '정상',
                      opt_name1: 'XL',
                      opt_name2: '',
                      opt_name3: '',
                      opt_add_price: 0,
                      stock_cnt: 10,
                      model_no: 'ABC.889900',
                      sku_cd: 'S2843EEEEE5D',
                      prod_name: '상품테스트',
                      prod_img:
                        'https://s3-ap-northeast-2.amazonaws.com/test.jpg',
                      global_barcode: null,
                      global_barcode_type: null,
                      prod_weight: 0,
                      barcode: '',
                      stock_cd: null,
                      stock_cnt_enable: 99999,
                      stock_cnt_safe: 0,
                      sale_price: 9999990,
                      cost_price: 0,
                      supply_price: 0,
                      prod_no: 9999999997,
                      depot_no: 1,
                      pack_unit: 1,
                      set_name: null,
                      set_sale_price: null,
                    },
                  ],
                  add_opt: [
                    {
                      sale_opt_seq: 3,
                      opt_status: '정상',
                      add_item_name: '추가항목 1',
                      add_item_opt_name: '추가옵션1',
                      opt_add_price: 1500,
                      stock_cnt: 10,
                      model_no: 'BBC.AB0340',
                      sku_cd: 'S30431FFFF1',
                      prod_name: 'SKU상품명99',
                      prod_img:
                        'https://s3-ap-northeast-2.amazonaws.com/24.jpg',
                      global_barcode: null,
                      global_barcode_type: null,
                      prod_weight: 0,
                      stock_cnt_enable: 99999,
                      stock_cnt_safe: 0,
                      sale_price: 1000000,
                      cost_price: 0,
                      supply_price: 0,
                      prod_no: 9999999996,
                      depot_no: 1,
                      pack_unit: 1,
                    },
                    {
                      sale_opt_seq: 4,
                      opt_status: '정상',
                      add_item_name: '추가항목 1',
                      add_item_opt_name: '추가옵션2',
                      opt_add_price: 3000,
                      stock_cnt: 11,
                      model_no: 'BBC.ED0034',
                      sku_cd: 'S407DD5DDDD1',
                      prod_name: 'SKU상품명100',
                      prod_img:
                        'https://s3-ap-northeast-2.amazonaws.com/27.jpg',
                      global_barcode: null,
                      global_barcode_type: null,
                      prod_weight: 0,
                      stock_cnt_enable: 99999,
                      stock_cnt_safe: 0,
                      sale_price: 1000000,
                      cost_price: 0,
                      supply_price: 0,
                      prod_no: 9999999995,
                      depot_no: 1,
                      pack_unit: 1,
                    },
                  ],
                  certDataList: [
                    {
                      ol_shop_cert_seq: 1,
                      cert_cd: 'cert_01',
                      cert_exc_type: 'kc_01',
                      cert_ministry: '테스트기관',
                      cert_no: '0000001',
                      cert_model: '테스트모델',
                      cert_cname: '테스트상호',
                      cert_date: '2020-10-29',
                      cert_expire_date: '2024-02-28',
                    },
                  ],
                },
              ],
            },
          },
        },
      ],
    },
    {
      title: '온라인상품 상세 조회',
      url: '/doc/온라인상품 상세 조회',
      data: [
        {
          version: 'v1.1',
          url: '/products/:ol_shop_no/v1.1',
          desc: '사용중인 솔루션에 등록된 온라인상품 정보를 조회합니다.',
          method: 'GET',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'ol_shop_no',
              required: 'O',
              type: 'String',
              desc: '온라인상품 고유번호',
            },
          ],
          response: [
            {
              title: 'sol_no',
              required: 'O',
              type: 'Number(8)',
              desc: '솔루션번호',
            },
            {
              title: 'ol_shop_no',
              required: 'O',
              type: 'Number(10)',
              desc: '온라인상품 고유번호',
            },
            {
              title: 'c_sale_cd',
              required: 'O',
              type: 'String(40)',
              desc: '판매자관리코드',
            },
            {
              title: 'sale_no',
              required: 'O',
              type: 'Number(11)',
              desc: (
                <div>
                  마스터상품 고유번호
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      해당 번호가 같은 온라인상품은 동일 마스터상품에
                      묶여있습니다.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'wdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  온라인상품 등록시간
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
            },
            {
              title: 'mdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  온라인상품 수정시간
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
            },
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String(4)',
              desc: '사이트 코드',
            },
            {
              title: 'shop_name',
              required: 'O',
              type: 'String(50)',
              desc: '사이트명',
            },
            {
              title: 'shop_id',
              required: 'O',
              type: 'String(40)',
              desc: '사이트 아이디',
            },
            {
              title: 'std_ol_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  단일상품여부
                  <div className='description'>(0: 일반상품, 1: 단일상품)</div>
                </div>
              ),
            },
            {
              title: 'ship_price_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  배송방법
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>무료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>조건부무료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>착불</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>선결제</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'ship_price_free_criteria',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  조건부 무료배송 조건
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>30000원 이상 미료일 때 30000</li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'ship_price',
              required: 'O',
              type: 'Number(8)',
              desc: '배송비',
            },
            {
              title: 'sale_status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품 판매 상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>판매대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>승인대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>반려</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매중</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>종료대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>수정대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매중지</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>일시품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sale_cnt_limit',
              required: 'O',
              type: 'Number(10)',
              desc: '쇼핑몰 판매수량',
            },
            {
              title: 'sale_price',
              required: 'O',
              type: 'Number(12)',
              desc: '판매가',
            },
            {
              title: 'supply_price',
              required: 'O',
              type: 'Number(12)',
              desc: '공급가',
            },
            {
              title: 'cost_price',
              required: 'O',
              type: 'Number(12)',
              desc: '원가',
            },
            {
              title: 'street_price',
              required: 'O',
              type: 'Number(12)',
              desc: '시중가',
            },
            {
              title: 'shop_sale_name',
              required: 'O',
              type: 'String(200)',
              desc: '쇼핑몰 상품명',
            },
            {
              title: 'shop_sale_no',
              required: 'O',
              type: 'String(40)',
              desc: (
                <div>
                  쇼핑몰 상품코드
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'detail_desc',
              required: 'O',
              type: 'String(16777215)',
              desc: '상품 상세설명',
            },
            {
              title: 'shop_sale_name_addcontent_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  상품명 머릿말, 꼬릿말 번호
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'addcontent_co',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  상세설명 머릿말, 꼬릿말 번호
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'template_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  템플릿 번호
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'template_name',
              required: 'O',
              type: 'String(80)',
              desc: (
                <div>
                  템플릿 이름
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'barcode',
              required: 'O',
              type: 'String(50)',
              desc: '바코드',
            },
            {
              title: 'isbn',
              required: 'O',
              type: 'Number(13)',
              desc: (
                <div>
                  isbn 번호
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'adult_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  미성년자 구매가능여부
                  <div className='description'>
                    (0: 미성년자 구매가능, 1: 미성년자 구매불가)
                  </div>
                </div>
              ),
            },
            {
              title: 'cert_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  인증정보타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>인증유형등록</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>인증대상아님</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>상세설명내등록</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'model',
              required: 'O',
              type: 'String(50)',
              desc: '모델명',
            },
            {
              title: 'brand',
              required: 'O',
              type: 'String(50)',
              desc: '브랜드',
            },
            {
              title: 'maker',
              required: 'O',
              type: 'String(50)',
              desc: '제조사',
            },
            {
              title: 'made_date',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  제조일
                  <div className='description'>(YYYY-MM-DD)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'expire_date',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  유효일
                  <div className='description'> (YYYY-MM-DD)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'tax_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  과세여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>과세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>면세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>영세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>비과세</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sol_cate_no',
              required: 'O',
              type: 'Number(10)',
              desc: '솔루션 카테고리 번호',
            },
            {
              title: 'sol_cate_name',
              required: 'O',
              type: 'String(60)',
              desc: '솔루션 카테고리 이름',
            },
            {
              title: 'sale_img1',
              required: 'O',
              type: 'String(230)',
              desc: '기본이미지',
            },
            {
              title: 'sale_img2',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 1',
            },
            {
              title: 'sale_img3',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 2',
            },
            {
              title: 'sale_img4',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 3',
            },
            {
              title: 'sale_img5',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 4',
            },
            {
              title: 'sale_img6',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 5',
            },
            {
              title: 'sale_img7',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 6',
            },
            {
              title: 'sale_img8',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 7',
            },
            {
              title: 'sale_img9',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 8',
            },
            {
              title: 'sale_img10',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 9',
            },
            {
              title: 'sale_img11',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 10',
            },
            {
              title: 'sale_img12',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 11',
            },
            {
              title: 'sale_img13',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 12',
            },
            {
              title: 'sale_img14',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 13',
            },
            {
              title: 'sale_img15',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 14',
            },
            {
              title: 'madein_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  원산지 국내/해외 여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>국내</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>해외</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>원양선</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>기타</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'madein_group',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  원산지 시/도
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'madein_name',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  원산지 읍/면
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'madein_etc',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  기타원산지
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'opt_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  dhqtusrnqns
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>옵션없음</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>조합형</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>독립형</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'opt_sort1',
              required: 'O',
              type: 'String(20)',
              desc: '옵션명1(ex>색상)',
            },
            {
              title: 'opt_sort2',
              required: 'O',
              type: 'String(20)',
              desc: '옵션명2',
            },
            {
              title: 'opt_sort3',
              required: 'O',
              type: 'String(20)',
              desc: '옵션명3',
            },
            {
              title: 'opt_sort1_desc',
              required: 'O',
              type: 'String(65535)',
              desc: '옵션값1(ex>빨강)',
            },
            {
              title: 'opt_sort2_desc',
              required: 'O',
              type: 'String(65535)',
              desc: '옵션값2',
            },
            {
              title: 'opt_sort3_desc',
              required: 'O',
              type: 'String(65535)',
              desc: '옵션값3',
            },
            {
              title: 'input_opt',
              required: 'O',
              type: 'String[]',
              desc: '입력형옵션',
            },
            {
              title: 'keywords',
              required: 'O',
              type: 'String[]',
              desc: '키워드',
            },
            {
              title: 'prod_noti_data',
              required: 'O',
              type: 'object',
              desc: (
                <div>
                  상품정보제공고시
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      상품분류코드에 따라 infoDetail 안에 키 값이 달라질 수
                      있습니다.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'infoCode',
              required: 'O',
              type: 'String',
              desc: '상품분류코드',
              indent: 1,
            },
            {
              title: 'infoName',
              required: 'O',
              type: 'String',
              desc: '상품분류명',
              indent: 1,
            },
            {
              title: 'infoDetail',
              required: 'O',
              type: 'Object',
              desc: '상품정보제공고시 데이터',
              indent: 1,
            },
            {
              title: 'options',
              required: 'O',
              type: 'object[]',
              desc: (
                <div>
                  옵션
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      옵션없음이라도 SKU상품이 매칭되어있는 경우 하나의 데이터가
                      나옵니다.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'sale_opt_seq',
              required: 'O',
              type: 'Number(5)',
              desc: '옵션순번',
              indent: 1,
            },
            {
              title: 'opt_status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'opt_name1',
              required: 'O',
              type: 'String(80)',
              desc: '옵션값1',
              indent: 1,
            },
            {
              title: 'opt_name2',
              required: 'O',
              type: 'String(80)',
              desc: (
                <div>
                  옵션값2
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'opt_name3',
              required: 'O',
              type: 'String(80)',
              desc: (
                <div>
                  옵션값3
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'opt_add_price',
              required: 'O',
              type: 'Number(9)',
              desc: '옵션가격',
              indent: 1,
            },
            {
              title: 'stock_cnt',
              required: 'O',
              type: 'Number(8)',
              desc: '옵션수량',
              indent: 1,
            },
            {
              title: 'set_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  옵션에 매칭된 세트상품 고유 번호
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>세트 상품 번호는 옵션 순번에 따라 출력됩니다.</li>
                    <li>
                      세트 상품 번호가 존재할 경우 sale_opt_seq로 옵션을
                      구분해주세요.
                    </li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품 고유 번호
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      세트 상품을 매칭한 옵션의 경우 sku상품 관련 항목은
                      세트상품내 구성된 sku상품 별로 출력됩니다.
                    </li>
                    <li>
                      해당 항목이 다르더라도 sale_opt_seq가 같으면 동일한
                      옵션입니다.
                    </li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'depot_no',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  옵션에 매칭된 배송처 번호
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'pack_unit',
              required: 'O',
              type: 'Number(6)',
              desc: (
                <div>
                  출고수량
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String(40)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품 sku 코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      세트 상품을 매칭한 옵션의 경우 sku상품 관련 항목은
                      세트상품내 구성된 sku상품 별로 출력됩니다.
                    </li>
                    <li>
                      해당 항목이 다르더라도 sale_opt_seq가 같으면 동일
                      옵션입니다.
                    </li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_name',
              required: 'O',
              type: 'String(200)',
              desc: (
                <div>
                  매칭된 SKU상품명
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_img',
              required: 'O',
              type: 'String(255)',
              desc: (
                <div>
                  옵션이미지
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'global_barcode_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  UPC/EAN
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>UPC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EAN</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>KAN</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>JAN</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>HS코드</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'global_barcode',
              required: 'O',
              type: 'String(13)',
              desc: (
                <div>
                  UPC/EAN
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_weight',
              required: 'O',
              type: 'Number(7)',
              desc: (
                <div>
                  옵션무게
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'barcode',
              required: 'O',
              type: 'String(40)',
              desc: (
                <div>
                  바코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'stock_cd',
              required: 'O',
              type: 'String(100)',
              desc: (
                <div>
                  재고관리코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'stock_cnt_enable',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  판매가능재고
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'stock_cnt_safe',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  안전재고
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sale_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품의 판매가
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cost_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품의 원가
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'supply_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품의 공급가
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'set_name',
              required: 'O',
              type: 'String(200)',
              desc: (
                <div>
                  옵션에 매칭된 세트상품명
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'set_sale_price',
              required: 'O',
              type: 'String(12)',
              desc: (
                <div>
                  옵션에 매칭된 세트상품 가격
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'add_opt',
              required: 'O',
              type: 'object[]',
              desc: '추가구매 옵션',
            },
            {
              title: 'sale_opt_seq',
              required: 'O',
              type: 'Number(5)',
              desc: '옵션순번',
              indent: 1,
            },
            {
              title: 'opt_status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'add_item_name',
              required: 'O',
              type: 'String(50)',
              desc: '추가항목명',
              indent: 1,
            },
            {
              title: 'add_item_opt_name',
              required: 'O',
              type: 'String(50)',
              desc: '추가옵션명',
              indent: 1,
            },
            {
              title: 'opt_add_price',
              required: 'O',
              type: 'Number(9)',
              desc: '추가금액',
              indent: 1,
            },
            {
              title: 'stock_cnt',
              required: 'O',
              type: 'Number(9)',
              desc: '옵션수량',
              indent: 1,
            },
            {
              title: 'prod_no',
              required: 'O',
              type: 'Numbr(10)',
              desc: (
                <div>
                  매칭된 SKU상품 고유번호
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      세트 상품을 매칭한 옵션의 경우 sku상품 관련 항목은
                      세트상품내 구성된 sku상품 별로 출력됩니다.
                    </li>
                    <li>
                      해당 항목이 다르더라도 sale_opt_seq가 같으면 동일
                      옵션입니다.
                    </li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'depot_no',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  옵션에 매칭된 배송처 번호
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'pack_unit',
              required: 'O',
              type: 'Number(6)',
              desc: (
                <div>
                  출고수량
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String(40)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품 sku 코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      세트 상품을 매칭한 옵션의 경우 sku상품 관련 항목은
                      세트상품내 구성된 sku상품 별로 출력됩니다.
                    </li>
                    <li>
                      해당 항목이 다르더라도 sale_opt_seq가 같으면 동일
                      옵션입니다.
                    </li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_name',
              required: 'O',
              type: 'String(200)',
              desc: (
                <div>
                  매칭된 SKU상품명
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_img',
              required: 'O',
              type: 'String(255)',
              desc: (
                <div>
                  옵션이미지
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'global_barcode_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  UPC/EAN
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>UPC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EAN</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>KAN</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>JAN</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>HS코드</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'global_barcode',
              required: 'O',
              type: 'String(13)',
              desc: (
                <div>
                  UPC/EAN 코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_weight',
              required: 'O',
              type: 'Number(7)',
              desc: (
                <div>
                  옵션무게
                  <div className='description'>
                    (재고관리 미사용시 미노출)
                    <br />
                    (data type: Decimal(7,2))
                  </div>
                  <br />
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'stock_cnt_enable',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  판매가능재고
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'stock_cnt_safe',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  안전재고
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sale_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품의 판매가
                  <div className='description'>
                    (재고관리 미사용시 미노출)
                    <br />
                    (data type: Decimal(12,2))
                  </div>
                  <br />
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cost_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품의 원가
                  <div className='description'>
                    (재고관리 미사용시 미노출)
                    <br />
                    (data type: Decimal(12,2))
                  </div>
                  <br />
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'supply_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품의 공급가
                  <div className='description'>
                    (재고관리 미사용시 미노출)
                    <br />
                    (data type: Decimal(12,2))
                  </div>
                  <br />
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'certDataList',
              required: 'O',
              type: 'object[]',
              desc: '인증정보',
            },
            {
              title: 'ol_shop_cert_seq',
              required: 'O',
              type: 'object[]',
              desc: '인증번호 순번',
              indent: 1,
            },
            {
              title: 'cert_cd',
              required: 'O',
              type: 'String(7)',
              desc: (
                <div>
                  인증유형
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    {' '}
                    <li>cert_01: 생활용품 안전인증</li>{' '}
                    <li>cert_02: 생활용품 안전확인</li>{' '}
                    <li>cert_03: 생활용품 어린이보호포장</li>{' '}
                    <li>cert_04: 생활용품 안전품질표시</li>{' '}
                    <li>cert_05: 생활용품 공급자적합성확인</li>{' '}
                    <li>cert_06: 전기용품 안전인증</li>{' '}
                    <li>cert_07: 전기용품 안전확인</li>{' '}
                    <li>cert_08: 전기용품 공급자적합성확인</li>{' '}
                    <li>cert_09: 방송통신용품 적합성인증확인</li>{' '}
                    <li>cert_10: 어린이제품 안전인증</li>{' '}
                    <li>cert_11: 어린이제품 안전확인</li>{' '}
                    <li>cert_12: 어린이제품 공급자적합성확인</li>{' '}
                    <li>cert_13: 위해우려제품 자가검사</li>{' '}
                    <li>cert_14: 방송통신기자재 잠정인증</li>{' '}
                    <li>cert_15: 방송통신기자재 적합등록</li>{' '}
                    <li>cert_16: 원산지증명</li> <li>cert_17: 해외안전인증</li>{' '}
                    <li>cert_18: 수입신고필증</li>{' '}
                    <li>cert_19: 친환경인증-무농약농산물</li>{' '}
                    <li>cert_20: 친환경인증-유기축산물</li>{' '}
                    <li>cert_21: 친환경인증-유기농산물</li>{' '}
                    <li>cert_22: 친환경인증-저농약농산물</li>{' '}
                    <li>cert_23: 친환경인증-무항생제축산물</li>{' '}
                    <li>cert_24: HACCP(위해요소 중점관리우수식품)</li>{' '}
                    <li>cert_25: GMP(우수건강기능식품 제조기준)</li>{' '}
                    <li>cert_26: GAP(농산물우수관리인증)</li>{' '}
                    <li>cert_27: 농산물이력추적관리</li>{' '}
                    <li>cert_28: 수산물품질인증</li>{' '}
                    <li>cert_29: 수산특산물품질인증</li>{' '}
                    <li>cert_30: 수산전통식품품질인증</li>{' '}
                    <li>cert_31: 친환경수산물품질인증</li>{' '}
                    <li>cert_32: 친환경수산가공품품질인증</li>{' '}
                    <li>cert_33: 전통식품 품질인증</li>{' '}
                    <li>cert_34: 유기가공식품 인증</li>{' '}
                    <li>cert_35: 가공식품 산업표준 KS인증</li>{' '}
                    <li>cert_36: 건강기능식품 광고사전심의</li>{' '}
                    <li>cert_37: 의료기기 광고사전심의</li>{' '}
                    <li>cert_38: 축산물 등급판정 확인서</li>{' '}
                    <li>cert_39: 도축검사증명서</li>{' '}
                    <li>cert_40: 식품명인(Grand master) 인증</li>{' '}
                    <li>cert_41: 사회적기업 인증</li>{' '}
                    <li>cert_42: 6차산업 인증</li>{' '}
                    <li>cert_43: 동물복지 인증</li>{' '}
                    <li>cert_44: 마을기업 인증</li>{' '}
                    <li>cert_45: 건강기능식품인증</li>{' '}
                    <li>cert_46: 생활화학제품</li>{' '}
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cert_exc_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  면제유형
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>kc_01: 안전기준준수대상</li>
                    <li>kc_02: 구매대행</li>
                    <li>kc_03: 병행수입</li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_01</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_02</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_03</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cert_ministry',
              required: 'O',
              type: 'String',
              desc: '인증기관',
              indent: 1,
            },
            {
              title: 'cert_no',
              required: 'O',
              type: 'String',
              desc: '인증번호',
              indent: 1,
            },
            {
              title: 'cert_model',
              required: 'O',
              type: 'String',
              desc: '인증모델',
              indent: 1,
            },
            {
              title: 'cert_cname',
              required: 'O',
              type: 'String',
              desc: '인증상호',
              indent: 1,
            },
            {
              title: 'cert_date',
              required: 'O',
              type: 'String',
              desc: '인증일',
              indent: 1,
            },
            {
              title: 'cert_expire_date',
              required: 'O',
              type: 'String',
              desc: '인증만료일',
              indent: 1,
            },
          ],
          response_example: {
            ol_shop_no: 25501010,
            c_sale_cd: 'm202105314fade7e8a',
            sale_no: 11142002,
            wdate: '2021-05-31 11:28:16',
            mdate: '2021-06-03 17:26:20',
            sol_no: 640,
            shop_cd: 'A001',
            shop_name: '옥션',
            shop_id: 'myshopy3',
            std_ol_yn: 0,
            ship_price_type: '선결제',
            ship_price_free_criteria: 0,
            ship_price: 3000,
            sale_status: '판매대기',
            sale_cnt_limit: 6543,
            sale_price: 110000,
            supply_price: 100000,
            cost_price: 90000,
            street_price: 120000,
            shop_sale_name: '2.2.22.0 STG 추가구매옵션 테스트',
            shop_sale_no: null,
            detail_desc: 'TEST',
            shop_sale_name_addcontent_no: null,
            addcontent_no: 1561,
            template_no: 262177,
            template_name: '테스트1',
            barcode: '',
            isbn: 7777777777777,
            adult_yn: 0,
            cert_type: '인증유형등록',
            model: '모델명',
            brand: '브랜드',
            maker: '제조사',
            made_date: '2021-01-01',
            expire_date: '2022-01-01',
            tax_type: '면세',
            sol_cate_no: 226970,
            sol_cate_name: '테스트: 테스트',
            sale_img1:
              'https://s3-ap-northeast-2.amazonaws.com/202105281154121.jpg',
            sale_img2: '',
            sale_img3: '',
            sale_img4: '',
            sale_img5: '',
            sale_img6: '',
            sale_img7: '',
            sale_img8: '',
            sale_img9: '',
            sale_img10: '',
            sale_img11: '',
            sale_img12: '',
            sale_img13: '',
            sale_img14: '',
            sale_img15: '',
            madein_type: '국내',
            madein_group: '경기',
            madein_name: '고양시',
            madein_etc: null,
            multi_madein_yn: 0,
            set_info: {
              rdoMode: '',
              madein_type: '기타',
              key_rdoOriginProductType: '4',
            },
            opt_type: '조합형',
            opt_sort1: '색상',
            opt_sort2: '사이즈',
            opt_sort3: '',
            opt_sort1_desc: '빨강,파랑',
            opt_sort2_desc: 'S,L',
            opt_sort3_desc: '',
            input_opt: ['입력형옵션1', '입력형옵션2'],
            keywords: ['키워드1', '키워드2', '키워드3'],
            prod_noti_data: {
              infoCode: 'Wear2023',
              infoName: '의류',
              infoDetail: {
                material: '1',
                color: '2',
                size: '3',
                manufacturer: '4',
                made_in: '5',
                caution: '6',
                release: '7',
                warranty: '8',
                customer_service: '9',
              },
            },
            options: [
              {
                sale_opt_seq: 1,
                opt_status: '정상',
                opt_name1: '빨강',
                opt_name2: 'S',
                opt_name3: '',
                opt_add_price: 0,
                stock_cnt: 10,
                prod_no: 4613545,
                depot_no: 775,
                pack_unit: 1,
                sku_cd: 'S999C90F18A4',
                prod_name: '캐주얼 FW다운/패딩 45종 특별할인전',
                prod_img:
                  'https://s3-ap-northeast-2.amazonaws.com/20191109021116.jpg',
                global_barcode: '8801234567893',
                global_barcode_type: 'EAN',
                prod_weight: null,
                barcode: '',
                stock_cd: null,
                stock_cnt_safe: 20,
                sale_price: 20000,
                cost_price: 0,
                supply_price: 0,
                set_name: null,
                set_sale_price: null,
              },
              {
                sale_opt_seq: 2,
                opt_status: '정상',
                opt_name1: '빨강',
                opt_name2: 'L',
                opt_name3: '',
                opt_add_price: 0,
                stock_cnt: 593,
                prod_no: 4635349,
                depot_no: 775,
                pack_unit: 2,
                sku_cd: 'S999C47FACA0',
                prod_name: '캐주얼 FW다운/패딩 45종 특별할인전',
                prod_img:
                  'https://s3-ap-northeast-2.amazonaws.com/20191113185544.jpg',
                global_barcode: '8801234567893',
                global_barcode_type: 'EAN',
                prod_weight: null,
                barcode: '',
                stock_cd: null,
                stock_cnt_safe: 0,
                sale_price: 20000,
                cost_price: 0,
                supply_price: 0,
                set_name: null,
                set_sale_price: null,
              },
              {
                sale_opt_seq: 3,
                opt_status: '정상',
                opt_name1: '파랑',
                opt_name2: 'S',
                opt_name3: '',
                opt_add_price: 0,
                stock_cnt: 509,
                prod_no: 4638276,
                depot_no: 775,
                pack_unit: 3,
                sku_cd: 'S999B255A306',
                prod_name: '캐주얼 FW다운/패딩 45종 특별할인전',
                prod_img:
                  'https://s3-ap-northeast-2.amazonaws.com/20191113190646.jpg',
                global_barcode: '8801234567893',
                global_barcode_type: 'EAN',
                prod_weight: null,
                barcode: '',
                stock_cd: null,
                stock_cnt_safe: 0,
                sale_price: 20000,
                cost_price: 0,
                supply_price: 0,
                set_name: null,
                set_sale_price: null,
              },
              {
                sale_opt_seq: 4,
                opt_status: '정상',
                opt_name1: '파랑',
                opt_name2: 'L',
                opt_name3: '',
                opt_add_price: 0,
                stock_cnt: 499,
                prod_no: 4632672,
                depot_no: 775,
                pack_unit: 4,
                sku_cd: 'S9997BBA2AC8',
                prod_name: '캐주얼 FW다운/패딩 45종 특별할인전',
                prod_img:
                  'https://s3-ap-northeast-2.amazonaws.com/20191113184913.jpg',
                global_barcode: '8801234567893',
                global_barcode_type: 'EAN',
                prod_weight: null,
                barcode: '',
                stock_cd: null,
                stock_cnt_safe: null,
                sale_price: 20000,
                cost_price: 0,
                supply_price: 0,
                set_name: null,
                set_sale_price: null,
              },
            ],
            add_opt: [
              {
                sale_opt_seq: 5,
                opt_status: '정상',
                add_item_name: '추가구매옵션',
                add_item_opt_name: '추가옵션명1',
                opt_add_price: 0,
                stock_cnt: 499,
                prod_no: 4633241,
                depot_no: 775,
                pack_unit: 77,
                sku_cd: 'S9993B1E90E3',
                prod_name: '캐주얼 FW다운/패딩 45종 특별할인전',
                prod_img:
                  'https://s3-ap-northeast-2.amazonaws.com/20191113184946.jpg',
                global_barcode: '8801234567893',
                global_barcode_type: 'EAN',
                prod_weight: null,
                stock_cnt_safe: null,
                sale_price: 20000,
                cost_price: 0,
                supply_price: 0,
              },
              {
                sale_opt_seq: 6,
                opt_status: '정상',
                add_item_name: '추가구매옵션',
                add_item_opt_name: '추가옵션명2',
                opt_add_price: 500,
                stock_cnt: 500,
                prod_no: 4613868,
                depot_no: 775,
                pack_unit: 77,
                sku_cd: 'S9991917BCED',
                prod_name: '캐주얼 FW다운/패딩 45종 특별할인전',
                prod_img:
                  'https://s3-ap-northeast-2.amazonaws.com/20191109021149.jpg',
                global_barcode: '8801234567893',
                global_barcode_type: 'EAN',
                prod_weight: null,
                stock_cnt_safe: null,
                sale_price: 20000,
                cost_price: 0,
                supply_price: 0,
              },
            ],
            certDataList: [
              {
                ol_shop_cert_seq: 1,
                cert_cd: 'cert_01',
                cert_exc_type: 'kc_01',
                cert_ministry: '인증기관',
                cert_no: '인증번호',
                cert_model: '인증모델',
                cert_cname: '인증상호',
                cert_date: '2021-03-01',
                cert_expire_date: '2021-12-31',
              },
            ],
          },
        },
        {
          version: 'v1.0',
          url: '/products/:ol_shop_no',
          desc: '사용중인 솔루션에 등록된 온라인상품 정보를 조회합니다.',
          method: 'GET',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'ol_shop_no',
              required: 'O',
              type: 'String',
              desc: '온라인상품 고유번호',
            },
          ],
          response: [
            {
              title: 'sol_no',
              required: 'O',
              type: 'Number(8)',
              desc: '솔루션번호',
            },
            {
              title: 'ol_shop_no',
              required: 'O',
              type: 'Number(10)',
              desc: '온라인상품 고유번호',
            },
            {
              title: 'c_sale_cd',
              required: 'O',
              type: 'String(40)',
              desc: '판매자관리코드',
            },
            {
              title: 'sale_no',
              required: 'O',
              type: 'Number(11)',
              desc: (
                <div>
                  마스터상품 고유번호
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      해당 번호가 같은 온라인상품은 동일 마스터상품에
                      묶여있습니다.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'wdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  온라인상품 등록시간
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
            },
            {
              title: 'mdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  온라인상품 수정시간
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
            },
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String(4)',
              desc: '사이트 코드',
            },
            {
              title: 'shop_name',
              required: 'O',
              type: 'String(50)',
              desc: '사이트명',
            },
            {
              title: 'shop_id',
              required: 'O',
              type: 'String(40)',
              desc: '사이트 아이디',
            },
            {
              title: 'std_ol_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  단일상품여부
                  <div className='description'>(0: 일반상품, 1: 단일상품)</div>
                </div>
              ),
            },
            {
              title: 'ship_price_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  배송방법
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>무료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>조건부무료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>착불</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>선결제</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'ship_price_free_criteria',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  조건부 무료배송 조건
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>30000원 이상 미료일 때 30000</li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'ship_price',
              required: 'O',
              type: 'Number(8)',
              desc: '배송비',
            },
            {
              title: 'sale_status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품 판매 상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>판매대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>승인대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>반려</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매중</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>종료대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>수정대기</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>판매중지</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>일시품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sale_cnt_limit',
              required: 'O',
              type: 'Number(10)',
              desc: '쇼핑몰 판매수량',
            },
            {
              title: 'sale_price',
              required: 'O',
              type: 'Number(12)',
              desc: '판매가',
            },
            {
              title: 'supply_price',
              required: 'O',
              type: 'Number(12)',
              desc: '공급가',
            },
            {
              title: 'cost_price',
              required: 'O',
              type: 'Number(12)',
              desc: '원가',
            },
            {
              title: 'street_price',
              required: 'O',
              type: 'Number(12)',
              desc: '시중가',
            },
            {
              title: 'shop_sale_name',
              required: 'O',
              type: 'String(200)',
              desc: '쇼핑몰 상품명',
            },
            {
              title: 'shop_sale_no',
              required: 'O',
              type: 'String(40)',
              desc: (
                <div>
                  쇼핑몰 상품코드
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'detail_desc',
              required: 'O',
              type: 'String(16777215)',
              desc: '상품 상세설명',
            },
            {
              title: 'shop_sale_name_addcontent_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  상품명 머릿말, 꼬릿말 번호
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'addcontent_co',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  상세설명 머릿말, 꼬릿말 번호
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'template_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  템플릿 번호
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'template_name',
              required: 'O',
              type: 'String(80)',
              desc: (
                <div>
                  템플릿 이름
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'barcode',
              required: 'O',
              type: 'String(50)',
              desc: '바코드',
            },
            {
              title: 'isbn',
              required: 'O',
              type: 'Number(13)',
              desc: (
                <div>
                  isbn 번호
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'adult_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  미성년자 구매가능여부
                  <div className='description'>
                    (0: 미성년자 구매가능, 1: 미성년자 구매불가)
                  </div>
                </div>
              ),
            },
            {
              title: 'cert_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  인증정보타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>인증유형등록</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>인증대상아님</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>상세설명내등록</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'model',
              required: 'O',
              type: 'String(50)',
              desc: '모델명',
            },
            {
              title: 'brand',
              required: 'O',
              type: 'String(50)',
              desc: '브랜드',
            },
            {
              title: 'maker',
              required: 'O',
              type: 'String(50)',
              desc: '제조사',
            },
            {
              title: 'made_date',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  제조일
                  <div className='description'>(YYYY-MM-DD)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'expire_date',
              required: 'O',
              type: 'String(10)',
              desc: (
                <div>
                  유효일
                  <div className='description'> (YYYY-MM-DD)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'tax_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  과세여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>과세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>면세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>영세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>비과세</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sol_cate_no',
              required: 'O',
              type: 'Number(10)',
              desc: '솔루션 카테고리 번호',
            },
            {
              title: 'sol_cate_name',
              required: 'O',
              type: 'String(60)',
              desc: '솔루션 카테고리 이름',
            },
            {
              title: 'sale_img1',
              required: 'O',
              type: 'String(230)',
              desc: '기본이미지',
            },
            {
              title: 'sale_img2',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 1',
            },
            {
              title: 'sale_img3',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 2',
            },
            {
              title: 'sale_img4',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 3',
            },
            {
              title: 'sale_img5',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 4',
            },
            {
              title: 'sale_img6',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 5',
            },
            {
              title: 'sale_img7',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 6',
            },
            {
              title: 'sale_img8',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 7',
            },
            {
              title: 'sale_img9',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 8',
            },
            {
              title: 'sale_img10',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 9',
            },
            {
              title: 'sale_img11',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 10',
            },
            {
              title: 'sale_img12',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 11',
            },
            {
              title: 'sale_img13',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 12',
            },
            {
              title: 'sale_img14',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 13',
            },
            {
              title: 'sale_img15',
              required: 'O',
              type: 'String(230)',
              desc: '추가이미지 14',
            },
            {
              title: 'madein_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  원산지 국내/해외 여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>국내</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>해외</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>원양선</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>기타</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'madein_group',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  원산지 시/도
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'madein_name',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  원산지 읍/면
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'madein_etc',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  기타원산지
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'opt_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  dhqtusrnqns
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>옵션없음</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>조합형</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>독립형</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'opt_sort1',
              required: 'O',
              type: 'String(20)',
              desc: '옵션명1(ex>색상)',
            },
            {
              title: 'opt_sort2',
              required: 'O',
              type: 'String(20)',
              desc: '옵션명2',
            },
            {
              title: 'opt_sort3',
              required: 'O',
              type: 'String(20)',
              desc: '옵션명3',
            },
            {
              title: 'opt_sort1_desc',
              required: 'O',
              type: 'String(65535)',
              desc: '옵션값1(ex>빨강)',
            },
            {
              title: 'opt_sort2_desc',
              required: 'O',
              type: 'String(65535)',
              desc: '옵션값2',
            },
            {
              title: 'opt_sort3_desc',
              required: 'O',
              type: 'String(65535)',
              desc: '옵션값3',
            },
            {
              title: 'input_opt',
              required: 'O',
              type: 'String[]',
              desc: '입력형옵션',
            },
            {
              title: 'keywords',
              required: 'O',
              type: 'String[]',
              desc: '키워드',
            },
            {
              title: 'prod_noti_data',
              required: 'O',
              type: 'object',
              desc: (
                <div>
                  상품정보제공고시
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      prod_info_1, prod_info_2 등은 상품정보 제공고시 타입에
                      따라 갯수가 바뀔 수 있음.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'infoCode',
              required: 'O',
              type: 'String(20)',
              desc: '상품분류코드',
              indent: 1,
            },
            {
              title: 'infoName',
              required: 'O',
              type: 'String',
              desc: '상품분류명',
              indent: 1,
            },
            {
              title: 'prod_info_1',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시1',
              indent: 1,
            },
            {
              title: 'prod_info_2',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시2',
              indent: 1,
            },
            {
              title: 'prod_info_3',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시3',
              indent: 1,
            },
            {
              title: 'prod_info_4',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시4',
              indent: 1,
            },
            {
              title: 'prod_info_5',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시5',
              indent: 1,
            },
            {
              title: 'options',
              required: 'O',
              type: 'object[]',
              desc: (
                <div>
                  옵션
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      옵션없음이라도 SKU상품이 매칭되어있는 경우 하나의 데이터가
                      나옵니다.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'sale_opt_seq',
              required: 'O',
              type: 'Number(5)',
              desc: '옵션순번',
              indent: 1,
            },
            {
              title: 'opt_status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'opt_name1',
              required: 'O',
              type: 'String(80)',
              desc: '옵션값1',
              indent: 1,
            },
            {
              title: 'opt_name2',
              required: 'O',
              type: 'String(80)',
              desc: (
                <div>
                  옵션값2
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'opt_name3',
              required: 'O',
              type: 'String(80)',
              desc: (
                <div>
                  옵션값3
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'opt_add_price',
              required: 'O',
              type: 'Number(9)',
              desc: '옵션가격',
              indent: 1,
            },
            {
              title: 'stock_cnt',
              required: 'O',
              type: 'Number(8)',
              desc: '옵션수량',
              indent: 1,
            },
            {
              title: 'set_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  옵션에 매칭된 세트상품 고유 번호
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>세트 상품 번호는 옵션 순번에 따라 출력됩니다.</li>
                    <li>
                      세트 상품 번호가 존재할 경우 sale_opt_seq로 옵션을
                      구분해주세요.
                    </li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_no',
              required: 'O',
              type: 'Number(10)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품 고유 번호
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      세트 상품을 매칭한 옵션의 경우 sku상품 관련 항목은
                      세트상품내 구성된 sku상품 별로 출력됩니다.
                    </li>
                    <li>
                      해당 항목이 다르더라도 sale_opt_seq가 같으면 동일한
                      옵션입니다.
                    </li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'depot_no',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  옵션에 매칭된 배송처 번호
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'pack_unit',
              required: 'O',
              type: 'Number(6)',
              desc: (
                <div>
                  출고수량
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String(40)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품 sku 코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      세트 상품을 매칭한 옵션의 경우 sku상품 관련 항목은
                      세트상품내 구성된 sku상품 별로 출력됩니다.
                    </li>
                    <li>
                      해당 항목이 다르더라도 sale_opt_seq가 같으면 동일
                      옵션입니다.
                    </li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_name',
              required: 'O',
              type: 'String(200)',
              desc: (
                <div>
                  매칭된 SKU상품명
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_img',
              required: 'O',
              type: 'String(255)',
              desc: (
                <div>
                  옵션이미지
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'global_barcode_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  UPC/EAN
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>UPC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EAN</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>KAN</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>JAN</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>HS코드</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'global_barcode',
              required: 'O',
              type: 'String(13)',
              desc: (
                <div>
                  UPC/EAN
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_weight',
              required: 'O',
              type: 'Number(7)',
              desc: (
                <div>
                  옵션무게
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'barcode',
              required: 'O',
              type: 'String(40)',
              desc: (
                <div>
                  바코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'stock_cd',
              required: 'O',
              type: 'String(100)',
              desc: (
                <div>
                  재고관리코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'stock_cnt_enable',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  판매가능재고
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'stock_cnt_safe',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  안전재고
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sale_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품의 판매가
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cost_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품의 원가
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'supply_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품의 공급가
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'set_name',
              required: 'O',
              type: 'String(200)',
              desc: (
                <div>
                  옵션에 매칭된 세트상품명
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'set_sale_price',
              required: 'O',
              type: 'String(12)',
              desc: (
                <div>
                  옵션에 매칭된 세트상품 가격
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'add_opt',
              required: 'O',
              type: 'object[]',
              desc: '추가구매 옵션',
            },
            {
              title: 'sale_opt_seq',
              required: 'O',
              type: 'Number(5)',
              desc: '옵션순번',
              indent: 1,
            },
            {
              title: 'opt_status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'add_item_name',
              required: 'O',
              type: 'String(50)',
              desc: '추가항목명',
              indent: 1,
            },
            {
              title: 'add_item_opt_name',
              required: 'O',
              type: 'String(50)',
              desc: '추가옵션명',
              indent: 1,
            },
            {
              title: 'opt_add_price',
              required: 'O',
              type: 'Number(9)',
              desc: '추가금액',
              indent: 1,
            },
            {
              title: 'stock_cnt',
              required: 'O',
              type: 'Number(9)',
              desc: '옵션수량',
              indent: 1,
            },
            {
              title: 'prod_no',
              required: 'O',
              type: 'Numbr(10)',
              desc: (
                <div>
                  매칭된 SKU상품 고유번호
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      세트 상품을 매칭한 옵션의 경우 sku상품 관련 항목은
                      세트상품내 구성된 sku상품 별로 출력됩니다.
                    </li>
                    <li>
                      해당 항목이 다르더라도 sale_opt_seq가 같으면 동일
                      옵션입니다.
                    </li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'depot_no',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  옵션에 매칭된 배송처 번호
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'pack_unit',
              required: 'O',
              type: 'Number(6)',
              desc: (
                <div>
                  출고수량
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String(40)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품 sku 코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      세트 상품을 매칭한 옵션의 경우 sku상품 관련 항목은
                      세트상품내 구성된 sku상품 별로 출력됩니다.
                    </li>
                    <li>
                      해당 항목이 다르더라도 sale_opt_seq가 같으면 동일
                      옵션입니다.
                    </li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_name',
              required: 'O',
              type: 'String(200)',
              desc: (
                <div>
                  매칭된 SKU상품명
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_img',
              required: 'O',
              type: 'String(255)',
              desc: (
                <div>
                  옵션이미지
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'global_barcode_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  UPC/EAN
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>UPC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EAN</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>KAN</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>JAN</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>HS코드</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'global_barcode',
              required: 'O',
              type: 'String(13)',
              desc: (
                <div>
                  UPC/EAN 코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_weight',
              required: 'O',
              type: 'Number(7)',
              desc: (
                <div>
                  옵션무게
                  <div className='description'>
                    (재고관리 미사용시 미노출)
                    <br />
                    (data type: Decimal(7,2))
                  </div>
                  <br />
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'stock_cnt_enable',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  판매가능재고
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'stock_cnt_safe',
              required: 'O',
              type: 'Number(8)',
              desc: (
                <div>
                  안전재고
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sale_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품의 판매가
                  <div className='description'>
                    (재고관리 미사용시 미노출)
                    <br />
                    (data type: Decimal(12,2))
                  </div>
                  <br />
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cost_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품의 원가
                  <div className='description'>
                    (재고관리 미사용시 미노출)
                    <br />
                    (data type: Decimal(12,2))
                  </div>
                  <br />
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'supply_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품의 공급가
                  <div className='description'>
                    (재고관리 미사용시 미노출)
                    <br />
                    (data type: Decimal(12,2))
                  </div>
                  <br />
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'certDataList',
              required: 'O',
              type: 'object[]',
              desc: '인증정보',
            },
            {
              title: 'ol_shop_cert_seq',
              required: 'O',
              type: 'object[]',
              desc: '인증번호 순번',
              indent: 1,
            },
            {
              title: 'cert_cd',
              required: 'O',
              type: 'String(7)',
              desc: (
                <div>
                  인증유형
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    {' '}
                    <li>cert_01: 생활용품 안전인증</li>{' '}
                    <li>cert_02: 생활용품 안전확인</li>{' '}
                    <li>cert_03: 생활용품 어린이보호포장</li>{' '}
                    <li>cert_04: 생활용품 안전품질표시</li>{' '}
                    <li>cert_05: 생활용품 공급자적합성확인</li>{' '}
                    <li>cert_06: 전기용품 안전인증</li>{' '}
                    <li>cert_07: 전기용품 안전확인</li>{' '}
                    <li>cert_08: 전기용품 공급자적합성확인</li>{' '}
                    <li>cert_09: 방송통신용품 적합성인증확인</li>{' '}
                    <li>cert_10: 어린이제품 안전인증</li>{' '}
                    <li>cert_11: 어린이제품 안전확인</li>{' '}
                    <li>cert_12: 어린이제품 공급자적합성확인</li>{' '}
                    <li>cert_13: 위해우려제품 자가검사</li>{' '}
                    <li>cert_14: 방송통신기자재 잠정인증</li>{' '}
                    <li>cert_15: 방송통신기자재 적합등록</li>{' '}
                    <li>cert_16: 원산지증명</li> <li>cert_17: 해외안전인증</li>{' '}
                    <li>cert_18: 수입신고필증</li>{' '}
                    <li>cert_19: 친환경인증-무농약농산물</li>{' '}
                    <li>cert_20: 친환경인증-유기축산물</li>{' '}
                    <li>cert_21: 친환경인증-유기농산물</li>{' '}
                    <li>cert_22: 친환경인증-저농약농산물</li>{' '}
                    <li>cert_23: 친환경인증-무항생제축산물</li>{' '}
                    <li>cert_24: HACCP(위해요소 중점관리우수식품)</li>{' '}
                    <li>cert_25: GMP(우수건강기능식품 제조기준)</li>{' '}
                    <li>cert_26: GAP(농산물우수관리인증)</li>{' '}
                    <li>cert_27: 농산물이력추적관리</li>{' '}
                    <li>cert_28: 수산물품질인증</li>{' '}
                    <li>cert_29: 수산특산물품질인증</li>{' '}
                    <li>cert_30: 수산전통식품품질인증</li>{' '}
                    <li>cert_31: 친환경수산물품질인증</li>{' '}
                    <li>cert_32: 친환경수산가공품품질인증</li>{' '}
                    <li>cert_33: 전통식품 품질인증</li>{' '}
                    <li>cert_34: 유기가공식품 인증</li>{' '}
                    <li>cert_35: 가공식품 산업표준 KS인증</li>{' '}
                    <li>cert_36: 건강기능식품 광고사전심의</li>{' '}
                    <li>cert_37: 의료기기 광고사전심의</li>{' '}
                    <li>cert_38: 축산물 등급판정 확인서</li>{' '}
                    <li>cert_39: 도축검사증명서</li>{' '}
                    <li>cert_40: 식품명인(Grand master) 인증</li>{' '}
                    <li>cert_41: 사회적기업 인증</li>{' '}
                    <li>cert_42: 6차산업 인증</li>{' '}
                    <li>cert_43: 동물복지 인증</li>{' '}
                    <li>cert_44: 마을기업 인증</li>{' '}
                    <li>cert_45: 건강기능식품인증</li>{' '}
                    <li>cert_46: 생활화학제품</li>{' '}
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cert_exc_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  면제유형
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>kc_01: 안전기준준수대상</li>
                    <li>kc_02: 구매대행</li>
                    <li>kc_03: 병행수입</li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_01</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_02</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_03</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cert_ministry',
              required: 'O',
              type: 'String',
              desc: '인증기관',
              indent: 1,
            },
            {
              title: 'cert_no',
              required: 'O',
              type: 'String',
              desc: '인증번호',
              indent: 1,
            },
            {
              title: 'cert_model',
              required: 'O',
              type: 'String',
              desc: '인증모델',
              indent: 1,
            },
            {
              title: 'cert_cname',
              required: 'O',
              type: 'String',
              desc: '인증상호',
              indent: 1,
            },
            {
              title: 'cert_date',
              required: 'O',
              type: 'String',
              desc: '인증일',
              indent: 1,
            },
            {
              title: 'cert_expire_date',
              required: 'O',
              type: 'String',
              desc: '인증만료일',
              indent: 1,
            },
          ],
          response_example: {
            ol_shop_no: 25501010,
            c_sale_cd: 'm202105314fade7e8a',
            sale_no: 11142002,
            wdate: '2021-05-31 11:28:16',
            mdate: '2021-06-03 17:26:20',
            sol_no: 640,
            shop_cd: 'A001',
            shop_name: '옥션',
            shop_id: 'myshopy3',
            std_ol_yn: 0,
            ship_price_type: '선결제',
            ship_price_free_criteria: 0,
            ship_price: 3000,
            sale_status: '판매대기',
            sale_cnt_limit: 6543,
            sale_price: 110000,
            supply_price: 100000,
            cost_price: 90000,
            street_price: 120000,
            shop_sale_name: '2.2.22.0 STG 추가구매옵션 테스트',
            shop_sale_no: null,
            detail_desc: 'TEST',
            shop_sale_name_addcontent_no: null,
            addcontent_no: 1561,
            template_no: 262177,
            template_name: '테스트1',
            barcode: '',
            isbn: 7777777777777,
            adult_yn: 0,
            cert_type: '인증유형등록',
            model: '모델명',
            brand: '브랜드',
            maker: '제조사',
            made_date: '2021-01-01',
            expire_date: '2022-01-01',
            tax_type: '면세',
            sol_cate_no: 226970,
            sol_cate_name: '테스트: 테스트',
            sale_img1:
              'https://s3-ap-northeast-2.amazonaws.com/202105281154121.jpg',
            sale_img2: '',
            sale_img3: '',
            sale_img4: '',
            sale_img5: '',
            sale_img6: '',
            sale_img7: '',
            sale_img8: '',
            sale_img9: '',
            sale_img10: '',
            sale_img11: '',
            sale_img12: '',
            sale_img13: '',
            sale_img14: '',
            sale_img15: '',
            madein_type: '국내',
            madein_group: '경기',
            madein_name: '고양시',
            madein_etc: null,
            multi_madein_yn: 0,
            set_info: {
              rdoMode: '',
              madein_type: '기타',
              key_rdoOriginProductType: '4',
            },
            opt_type: '조합형',
            opt_sort1: '색상',
            opt_sort2: '사이즈',
            opt_sort3: '',
            opt_sort1_desc: '빨강,파랑',
            opt_sort2_desc: 'S,L',
            opt_sort3_desc: '',
            input_opt: ['입력형옵션1', '입력형옵션2'],
            keywords: ['키워드1', '키워드2', '키워드3'],
            prod_noti_data: {
              infoCode: '35',
              infoName: '기타재화',
              prod_info_1: '[상세설명참조]',
              prod_info_2: '[상세설명참조]',
              prod_info_3: '[상세설명참조]',
              prod_info_4: '[상세설명참조]',
              prod_info_5: '[상세설명참조]',
              prod_info_6: 'N',
              prod_info_7: '[상세설명참조]',
              prod_info_8: '[상세설명참조]',
            },
            options: [
              {
                sale_opt_seq: 1,
                opt_status: '정상',
                opt_name1: '빨강',
                opt_name2: 'S',
                opt_name3: '',
                opt_add_price: 0,
                stock_cnt: 10,
                prod_no: 4613545,
                depot_no: 775,
                pack_unit: 1,
                sku_cd: 'S999C90F18A4',
                prod_name: '캐주얼 FW다운/패딩 45종 특별할인전',
                prod_img:
                  'https://s3-ap-northeast-2.amazonaws.com/20191109021116.jpg',
                global_barcode: '8801234567893',
                global_barcode_type: 'EAN',
                prod_weight: null,
                barcode: '',
                stock_cd: null,
                stock_cnt_safe: 20,
                sale_price: 20000,
                cost_price: 0,
                supply_price: 0,
                set_name: null,
                set_sale_price: null,
              },
              {
                sale_opt_seq: 2,
                opt_status: '정상',
                opt_name1: '빨강',
                opt_name2: 'L',
                opt_name3: '',
                opt_add_price: 0,
                stock_cnt: 593,
                prod_no: 4635349,
                depot_no: 775,
                pack_unit: 2,
                sku_cd: 'S999C47FACA0',
                prod_name: '캐주얼 FW다운/패딩 45종 특별할인전',
                prod_img:
                  'https://s3-ap-northeast-2.amazonaws.com/20191113185544.jpg',
                global_barcode: '8801234567893',
                global_barcode_type: 'EAN',
                prod_weight: null,
                barcode: '',
                stock_cd: null,
                stock_cnt_safe: 0,
                sale_price: 20000,
                cost_price: 0,
                supply_price: 0,
                set_name: null,
                set_sale_price: null,
              },
              {
                sale_opt_seq: 3,
                opt_status: '정상',
                opt_name1: '파랑',
                opt_name2: 'S',
                opt_name3: '',
                opt_add_price: 0,
                stock_cnt: 509,
                prod_no: 4638276,
                depot_no: 775,
                pack_unit: 3,
                sku_cd: 'S999B255A306',
                prod_name: '캐주얼 FW다운/패딩 45종 특별할인전',
                prod_img:
                  'https://s3-ap-northeast-2.amazonaws.com/20191113190646.jpg',
                global_barcode: '8801234567893',
                global_barcode_type: 'EAN',
                prod_weight: null,
                barcode: '',
                stock_cd: null,
                stock_cnt_safe: 0,
                sale_price: 20000,
                cost_price: 0,
                supply_price: 0,
                set_name: null,
                set_sale_price: null,
              },
              {
                sale_opt_seq: 4,
                opt_status: '정상',
                opt_name1: '파랑',
                opt_name2: 'L',
                opt_name3: '',
                opt_add_price: 0,
                stock_cnt: 499,
                prod_no: 4632672,
                depot_no: 775,
                pack_unit: 4,
                sku_cd: 'S9997BBA2AC8',
                prod_name: '캐주얼 FW다운/패딩 45종 특별할인전',
                prod_img:
                  'https://s3-ap-northeast-2.amazonaws.com/20191113184913.jpg',
                global_barcode: '8801234567893',
                global_barcode_type: 'EAN',
                prod_weight: null,
                barcode: '',
                stock_cd: null,
                stock_cnt_safe: null,
                sale_price: 20000,
                cost_price: 0,
                supply_price: 0,
                set_name: null,
                set_sale_price: null,
              },
            ],
            add_opt: [
              {
                sale_opt_seq: 5,
                opt_status: '정상',
                add_item_name: '추가구매옵션',
                add_item_opt_name: '추가옵션명1',
                opt_add_price: 0,
                stock_cnt: 499,
                prod_no: 4633241,
                depot_no: 775,
                pack_unit: 77,
                sku_cd: 'S9993B1E90E3',
                prod_name: '캐주얼 FW다운/패딩 45종 특별할인전',
                prod_img:
                  'https://s3-ap-northeast-2.amazonaws.com/20191113184946.jpg',
                global_barcode: '8801234567893',
                global_barcode_type: 'EAN',
                prod_weight: null,
                stock_cnt_safe: null,
                sale_price: 20000,
                cost_price: 0,
                supply_price: 0,
              },
              {
                sale_opt_seq: 6,
                opt_status: '정상',
                add_item_name: '추가구매옵션',
                add_item_opt_name: '추가옵션명2',
                opt_add_price: 500,
                stock_cnt: 500,
                prod_no: 4613868,
                depot_no: 775,
                pack_unit: 77,
                sku_cd: 'S9991917BCED',
                prod_name: '캐주얼 FW다운/패딩 45종 특별할인전',
                prod_img:
                  'https://s3-ap-northeast-2.amazonaws.com/20191109021149.jpg',
                global_barcode: '8801234567893',
                global_barcode_type: 'EAN',
                prod_weight: null,
                stock_cnt_safe: null,
                sale_price: 20000,
                cost_price: 0,
                supply_price: 0,
              },
            ],
            certDataList: [
              {
                ol_shop_cert_seq: 1,
                cert_cd: 'cert_01',
                cert_exc_type: 'kc_01',
                cert_ministry: '인증기관',
                cert_no: '인증번호',
                cert_model: '인증모델',
                cert_cname: '인증상호',
                cert_date: '2021-03-01',
                cert_expire_date: '2021-12-31',
              },
            ],
          },
        },
      ],
    },
    {
      title: '온라인상품 수정',
      url: '/doc/온라인상품 수정',
      data: [
        {
          version: 'v1.1',
          url: '/products/edit/v1.1',
          desc: (
            <ul className='style'>
              <li>온라인상품 정보를 수정합니다.</li>
            </ul>
          ),
          method: 'PUT',
          header: default_header,
          header_example: default_header_example,
          body: [
            ...(userInfo.sol_no === 104083 ? [{
              title: 'sub_sol_no',
              required: '',
              type: 'Number',
              desc: '하위 계정 sol_no',
            }] : []),
            {
              title: 'c_sale_cd',
              required: 'O',
              type: 'String',
              desc: '판매자관리코드',
            },
            {
              title: 'sol_cate_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  카테고리코드
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>카테고리 수정이 필요한 경우 입력해주십시오.</li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'shop_sale_name',
              required: '',
              type: 'String',
              desc: (
                <div>
                  상품명
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>상품명은 최대 100Byte까지 등록가능합니다.</li>
                    <li>
                      특수문자는 - _ / ~ + . , ( ) {} [ ] % & '만 사용
                      가능합니다.
                    </li>
                  </ul>
                </div>
              ),
            },
            ...(userInfo.sol_no === 104083 ? [{
              title: 'shop_promotion_name',
              required: '',
              type: 'String',
              desc: (
                <div>
                  OEM 부품번호
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>OEM 부품번호는 100byte까지 입력 가능합니다</li>
                    <li>
                      영문, 숫자만 입력 가능하며, 1개 이상 입력 시 콤마(,)로 구분해 입력 가능합니다.
                    </li>
                  </ul>
                </div>
              ),
            }]: []),
            ...(userInfo.sol_no === 104083 ? [{
              title: 'model_no',
              required: '',
              type: 'String',
              desc: (
                <div>
                  차대 번호
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>차대 번호는 50byte까지 입력 가능합니다</li>
                    <li>
                      영문, 숫자만 입력 가능합니다.
                    </li>
                  </ul>
                </div>
              ),
            }]: []),
            {
              title: 'minor_yn',
              required: '',
              type: 'Boolean',
              desc: (
                <div>
                  미성년자 구매여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>true</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sale_price',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  판매가격
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>금액은 10원 단위로 입력해주십시오.</li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'sale_cnt_limit',
              required: '',
              type: 'Number',
              desc: '판매수량',
            },
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  쇼핑몰 코드
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      쇼핑몰코드조회 문서를 참고하여 등록하시려는 사이트의
                      코드를 입력해주십시오.
                    </li>
                    <li>
                      마스터상품을 수정하기 원하시는경우 해당 코드를 'master'로
                      입력해주십시오.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'shop_id',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  쇼핑몰 아이디
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      마스터상품을 수정하기 원하시는 경우 해당값을{' '}
                      <code>master</code>로 입력해주십시오.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'edit_slave_all',
              required: '',
              type: 'Boolean',
              desc: (
                <div>
                  쇼핑몰 상품 연동 수정 여부
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      마스터 상품 수정일 경우 하위 쇼핑몰 상품 수정 여부
                    </li>
                    <li>
                      연동된 쇼핑몰 상품 중 쿠팡, 갤러리아몰, 롯데ON 상품 및 단일상품(옥션, 지마켓 제외)은 옵션이 수정되지 않고 기존 옵션이 유지됩니다.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'template_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  템플릿번호
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      템플릿 조회 api를 통해 확인된 템플릿 번호를
                      입력해주십시오.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'shop_sale_name_addcontent_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  상품명 머리말/꼬리말 번호
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      머리말/꼬리말 조회 api를 통해 확인된 번호를
                      입력해주십시오.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'addcontent_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  상세설명 머리말/꼬리말 번호
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      머리말/꼬리말 조회 api를 통해 확인된 번호를
                      입력해주십시오.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'std_ol_yn',
              required: '',
              type: 'String',
              desc: (
                <div>
                  단일상품여부
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      값 미입력시 일반상품으로 조회되니 단일상품을 수정할 경우
                      반드시 값을 입력해주십시오.
                    </li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>Y</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>N</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'main_sku_cd',
              required: '',
              type: 'String',
              desc: '대표SKU상품 코드'
            },
            {
              title: 'opt_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  <b>홈플러스, 갤러리아몰, 롯데ON</b>
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>옵션없음으로 수정이 불가능합니다.</li>
                  </ul>
                  <b>쿠팡</b>
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      쇼핑몰에서 옵션명 수정이 불가하므로 옵션수정을 지원하지
                      않습니다.
                    </li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>옵션없음</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>조합형</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>독립형</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'opts',
              required: '',
              type: 'Object[]',
              desc: (
                <div>
                  옵션
                  <div className='description'>
                    (옵션구분이 옵션없음이 아닌경우 필수입력.)
                  </div>
                </div>
              ),
            },
            {
              title: 'opt_sort1',
              required: 'O',
              type: 'String',
              desc: '옵션명1',
              indent: 1,
            },
            {
              title: 'opt_sort2',
              required: '',
              type: 'String',
              desc: '옵션명2',
              indent: 1,
            },
            {
              title: 'opt_sort3',
              required: '',
              type: 'String',
              desc: '옵션명3',
              indent: 1,
            },
            {
              title: 'opt_sort1_desc',
              required: 'O',
              type: 'String',
              desc: '옵션값1',
              indent: 1,
            },
            {
              title: 'opt_sort2_desc',
              required: '',
              type: 'String',
              desc: '옵션값2',
              indent: 1,
            },
            {
              title: 'opt_sort3_desc',
              required: '',
              type: 'String',
              desc: '옵션값3',
              indent: 1,
            },
            {
              title: 'sku_cd',
              required: '',
              type: 'String',
              desc: (
                <div>
                  sku코드
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      sku코드 매칭시 모든 옵션의 sku코드를 입력해야 합니다.
                    </li>
                    <li>
                      sku코드를 매칭하지 않는 옵션이 있을 경우 모든 옵션의
                      sku코드를 비워주십시오.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'pack_unit',
              required: '',
              type: 'Number',
              desc: '출고수량',
              indent: 1,
            },
            {
              title: 'add_price',
              required: '',
              type: 'Number',
              desc: '추가옵션금액',
              indent: 1,
            },
            {
              title: 'stock_cnt',
              required: 'O',
              type: 'Numbr',
              desc: '판매수량',
              indent: 1,
            },
            {
              title: 'weight',
              required: '',
              type: 'Number',
              desc: '옵션무게',
              indent: 1,
            },
            {
              title: 'status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'input_opt_name',
              required: '',
              type: 'Array',
              desc: '입력형옵션',
            },
            {
              title: 'add_opts',
              required: '',
              type: 'Object[]',
              desc: '추가구매옵션',
            },
            {
              title: 'opt_sort',
              required: 'O',
              type: 'String',
              desc: '추가항목명',
              indent: 1,
            },
            {
              title: 'opt_sort_desc',
              required: 'O',
              type: 'String',
              desc: '추가옵션명',
              indent: 1,
            },
            {
              title: 'price',
              required: 'O',
              type: 'Number',
              desc: '추가금액',
              indent: 1,
            },
            {
              title: 'stock_cnt',
              required: 'O',
              type: 'Number',
              desc: '판매수량',
              indent: 1,
            },
            {
              title: 'sku_cd',
              required: '',
              type: 'String',
              desc: 'sku 코드',
              indent: 1,
            },
            {
              title: 'pack_unit',
              required: '',
              type: 'Number',
              desc: '출고수량',
              indent: 1,
            },
            {
              title: 'weight',
              required: '',
              type: 'Number',
              desc: '추가구매옵션무게',
              indent: 1,
            },
            {
              title: 'status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  추가구매옵션 상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'tax_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  과세여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>과세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>면세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>영세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>비과세</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'madein',
              required: '',
              type: 'Object',
              desc: '원산지',
            },
            {
              title: 'madein_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  원산지번호
                  <div className='description'>
                    (원산지 조회 api 호출하여 확인)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'madein_etc',
              required: '',
              type: 'String',
              desc: (
                <div>
                  기타원산지
                  <div className='description'>
                    (등록하려는 원산지가 origins에서 조회되지않을때 madein_no를
                    비우고 madein_etc에 값을 입력합니다.)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'multi_yn',
              required: '',
              type: 'Boolean',
              desc: '복수원산지여부',
              indent: 1,
            },
            {
              title: 'made_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  제조일자
                  <div className='description'>
                    (YYYY-MM-DD의 형식으로 입력해주십시오.)
                  </div>
                </div>
              ),
            },
            {
              title: 'expire_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  유효일자
                  <div className='description'>
                    (YYYY-MM-DD의 형식으로 입력해주십시오.)
                  </div>
                </div>
              ),
            },
            {
              title: 'detail_desc',
              required: '',
              type: 'String',
              desc: '상품상세설명',
            },
            {
              title: 'brand',
              required: '',
              type: 'String',
              desc: '브랜드',
            },
            {
              title: 'model',
              required: '',
              type: 'String',
              desc: '모델명',
            },
            {
              title: 'maker',
              required: '',
              type: 'String',
              desc: '제조사',
            },
            {
              title: 'keyword',
              required: '',
              type: 'String[]',
              desc: (
                <div>
                  키워드
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      키워드는 최대 40개 까지 등록가능하며, 쇼핑몰에 상품전송시
                      쇼핑몰에서 지원하는 입력 갯수만큼만 전송됩니다.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'sale_img1',
              required: '',
              type: 'String',
              desc: '기본이미지 URL',
            },
            {
              title: 'sale_img2',
              required: '',
              type: 'String',
              desc: '추가이미지 1 URL',
            },
            {
              title: 'sale_img3',
              required: '',
              type: 'String',
              desc: '추가이미지 2 URL',
            },
            {
              title: 'sale_img4',
              required: '',
              type: 'String',
              desc: '추가이미지 3 URL',
            },
            {
              title: 'sale_img5',
              required: '',
              type: 'String',
              desc: '추가이미지 4 URL',
            },
            {
              title: 'sale_img6',
              required: '',
              type: 'String',
              desc: '추가이미지 5 URL',
            },
            {
              title: 'sale_img7',
              required: '',
              type: 'String',
              desc: '추가이미지 6 URL',
            },
            {
              title: 'sale_img8',
              required: '',
              type: 'String',
              desc: '추가이미지 7 URL',
            },
            {
              title: 'sale_img9',
              required: '',
              type: 'String',
              desc: '추가이미지 8 URL',
            },
            {
              title: 'sale_img10',
              required: '',
              type: 'String',
              desc: '추가이미지 9 URL',
            },
            {
              title: 'sale_img11',
              required: '',
              type: 'String',
              desc: '추가이미지 10 URL',
            },
            {
              title: 'prod_info',
              required: '',
              type: 'Object[]',
              desc: (
                <div>
                  품목정보고시
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>품목정보고시정보는 3개까지 입력가능합니다.</li>
                    <li>아래의 등록가이드를 참고해주세요.</li>
                    <li>
                      <a
                        href='https://gmp-master.s3.ap-northeast-2.amazonaws.com/download/Sample_prodInfo_guide_OPENAPI.xlsx'
                        style={{ color: 'blue' }}
                      >
                        상품 품목정보고시 가이드 개정판
                      </a>
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'infoCode',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품분류코드
                  <br />
                  품목정보고시 입력 시 필수 입니다.
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      대표SKU상품 정보를 사용하고 싶으신경우 '대표SKU상품'으로
                      입력합니다.
                    </li>
                    <li>
                      대표SKU상품으로 입력시 아래의 정보는 입력할 필요가
                      없습니다.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'infoDetail',
              required: 'O',
              type: 'object',
              desc: (
                <div>
                  상품 정보 제공고시 데이터
                  <ul style={{ color: 'gray' }}>
                    <li>
                      품목 정보고시 가이드에 기재된 각 항목에 따른 key와 value를
                      입력합니다.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'is_desc_referred',
              type: 'boolean',
              desc: (
                <div>
                  상품 정보 제공고시 데이터 일괄 [상세설명참조] 적용
                  <ul style={{ color: 'gray' }}>
                    <li>
                      해당 값을 <code>true</code> 로 설정 시 입력한 값과 상관 없이 일괄 <code>[상세설명참조]</code>로 적용됩니다.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ship_price_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  배송방법
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>무료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>선결제</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>착불</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'ship_price',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  배송비
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>배송방법이 무료가 아닌경우 필수입니다.</li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'gift_name',
              required: '',
              type: 'String',
              desc: '사은품',
            },
            {
              title: 'supply_price',
              required: '',
              type: 'Number',
              desc: '공급가',
            },
            {
              title: 'cost_price',
              required: '',
              type: 'Number',
              desc: '원가',
            },
            {
              title: 'street_price',
              required: '',
              type: 'Number',
              desc: '시중가',
            },
            {
              title: 'global_barcode_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  UPC/EAN 타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>UPC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EAN</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'global_barcode',
              required: '',
              type: 'Number',
              desc: 'UPC/EAN 코드',
            },
            {
              title: 'isbn',
              required: '',
              type: 'Number',
              desc: 'ISBN 코드',
            },
            {
              title: 'barcode',
              required: '',
              type: 'String',
              desc: '바코드',
            },
            {
              title: 'hscd',
              required: '',
              type: 'String',
              desc: 'HS코드',
            },
            {
              title: 'prod_weight',
              required: '',
              type: 'Number',
              desc: '상품무게',
            },
            {
              title: 'cert_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  인증정보타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>인증대상아님</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>인증유형등록</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>인증대상아님</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>상세설명내등록</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'certs',
              required: '',
              type: 'Array',
              desc: (
                <div>
                  인증정보
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>인증정보는 10개까지 등록가능합니다.</li>
                    <li>
                      인증정보타입이 인증유형등록인 경우 필수로
                      입력해주셔야합니다.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'cert_cd',
              required: '',
              type: 'String',
              desc: (
                <div>
                  인증유형
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    {' '}
                    <li>cert_01: 생활용품 안전인증</li>{' '}
                    <li>cert_02: 생활용품 안전확인</li>{' '}
                    <li>cert_03: 생활용품 어린이보호포장</li>{' '}
                    <li>cert_04: 생활용품 안전품질표시</li>{' '}
                    <li>cert_05: 생활용품 공급자적합성확인</li>{' '}
                    <li>cert_06: 전기용품 안전인증</li>{' '}
                    <li>cert_07: 전기용품 안전확인</li>{' '}
                    <li>cert_08: 전기용품 공급자적합성확인</li>{' '}
                    <li>cert_09: 방송통신용품 적합성인증확인</li>{' '}
                    <li>cert_10: 어린이제품 안전인증</li>{' '}
                    <li>cert_11: 어린이제품 안전확인</li>{' '}
                    <li>cert_12: 어린이제품 공급자적합성확인</li>{' '}
                    <li>cert_13: 위해우려제품 자가검사</li>{' '}
                    <li>cert_14: 방송통신기자재 잠정인증</li>{' '}
                    <li>cert_15: 방송통신기자재 적합등록</li>{' '}
                    <li>cert_16: 원산지증명</li> <li>cert_17: 해외안전인증</li>{' '}
                    <li>cert_18: 수입신고필증</li>{' '}
                    <li>cert_19: 친환경인증-무농약농산물</li>{' '}
                    <li>cert_20: 친환경인증-유기축산물</li>{' '}
                    <li>cert_21: 친환경인증-유기농산물</li>{' '}
                    <li>cert_22: 친환경인증-저농약농산물</li>{' '}
                    <li>cert_23: 친환경인증-무항생제축산물</li>{' '}
                    <li>cert_24: HACCP(위해요소 중점관리우수식품)</li>{' '}
                    <li>cert_25: GMP(우수건강기능식품 제조기준)</li>{' '}
                    <li>cert_26: GAP(농산물우수관리인증)</li>{' '}
                    <li>cert_27: 농산물이력추적관리</li>{' '}
                    <li>cert_28: 수산물품질인증</li>{' '}
                    <li>cert_29: 수산특산물품질인증</li>{' '}
                    <li>cert_30: 수산전통식품품질인증</li>{' '}
                    <li>cert_31: 친환경수산물품질인증</li>{' '}
                    <li>cert_32: 친환경수산가공품품질인증</li>{' '}
                    <li>cert_33: 전통식품 품질인증</li>{' '}
                    <li>cert_34: 유기가공식품 인증</li>{' '}
                    <li>cert_35: 가공식품 산업표준 KS인증</li>{' '}
                    <li>cert_36: 건강기능식품 광고사전심의</li>{' '}
                    <li>cert_37: 의료기기 광고사전심의</li>{' '}
                    <li>cert_38: 축산물 등급판정 확인서</li>{' '}
                    <li>cert_39: 도축검사증명서</li>{' '}
                    <li>cert_40: 식품명인(Grand master) 인증</li>{' '}
                    <li>cert_41: 사회적기업 인증</li>{' '}
                    <li>cert_42: 6차산업 인증</li>{' '}
                    <li>cert_43: 동물복지 인증</li>{' '}
                    <li>cert_44: 마을기업 인증</li>{' '}
                    <li>cert_45: 건강기능식품인증</li>{' '}
                    <li>cert_46: 생활화학제품</li>{' '}
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cert_exc_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  면제유형
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>kc_01: 안전기준준수대상</li>
                    <li>kc_02: 구매대행</li>
                    <li>kc_03: 병행수입</li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_01</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_02</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_03</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cert_ministry',
              required: '',
              type: 'String',
              desc: '인증기관',
              indent: 1,
            },
            {
              title: 'cert_no',
              required: '',
              type: 'String',
              desc: '인증번호',
              indent: 1,
            },
            {
              title: 'cert_model',
              required: '',
              type: 'String',
              desc: '인증모델',
              indent: 1,
            },
            {
              title: 'cert_cname',
              required: '',
              type: 'String',
              desc: '인증상호',
              indent: 1,
            },
            {
              title: 'cert_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  인증일
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>YYYY-MM-DD의 날짜형식으로 입력해주십시오.</li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cert_expire_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  인증만료일
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>YYYY-MM-DD의 날짜형식으로 입력해주십시오.</li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'set_info',
              required: '',
              type: 'Object',
              desc: (
                <div>
                  등록 상품이 쇼핑몰 29CM인 경우만 상세 설명 이미지
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>객체의 키 값은 쇼핑몰 코드</li>
                    <li>
                      상세 설명 이미지가 하나 이상은 반드시 존재해야 됩니다.
                    </li>
                    <li>
                      [image_type]
                      <ul className='style' style={{ color: 'gray' }}>
                        <li>4: 기본 타입</li> <li>5: 영상 타입</li>
                        <li>6: 타이틀 구분 타입</li>
                        <li>7: 이미지 링크 타입</li>
                      </ul>
                    </li>
                  </ul>
                  <pre>
                    <span className='pun'>{'{'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>"image_info"</span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='pun'>[</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'    {'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>&nbsp;&nbsp;&nbsp;&nbsp;"title"</span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"타이틀"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"content"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"설명"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_url"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"IMG_URL"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_type"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='lit'>4</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'}'},</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'{'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>&nbsp;&nbsp;&nbsp;&nbsp;"title"</span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"타이틀"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"content"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"설명"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"added_url"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"VIDEO_URL"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_url"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"IMG_URL"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"added_type"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='lit'>1</span>
                    <span className='pun'>,</span>
                    <span className='pln'> </span>
                    <span className='com'> # 1: 유튜브, 2: 비메오 </span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_type"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='lit'>5</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'}'},</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'{'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>&nbsp;&nbsp;&nbsp;&nbsp;"title"</span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"타이틀"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_url"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='pun'>[</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'        {'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"content"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"설명"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"image_url"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"IMG_URL"</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'        }'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>&nbsp;&nbsp;&nbsp;&nbsp;],</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"is_layout"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"F"</span>
                    <span className='pun'>,</span>
                    <span className='pln'> </span>
                    <span className='com'>
                      {' '}
                      # "F": 레이아웃 1단, "T": 레이아웃 2단
                    </span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_type"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='lit'>6</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'}'},</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'{'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"added_url"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"IMG_LINK_URL"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_url"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"IMG_URL"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_type"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='lit'>7</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pln'> </span>
                    <span className='pun'>{'    }'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'}'}</span>
                    <span className='pln'> </span>
                  </pre>
                </div>
              ),
            },
          ],
          body_example: {
            c_sale_cd: 'm20200928724997',
            sol_cate_no: 85036,
            shop_sale_name: 'api 테스트 상품 수정',
            minor_yn: true,
            sale_price: 50000,
            sale_cnt_limit: 88,
            shop_cd: 'A112',
            shop_id: 'playauto',
            edit_master_prod: true,
            std_ol_yn: 'N',
            opt_type: '조합형',
            opts: [
              {
                opt_sort1: '색상',
                opt_sort2: '사이즈',
                opt_sort3: '',
                opt_sort1_desc: '빨강',
                opt_sort2_desc: '큼',
                opt_sort3_desc: '',
                sku_cd: 'S999C90F18A4',
                pack_unit: 1,
                add_price: 0,
                stock_cnt: 99,
                weight: 0.5,
                status: '정상',
              },
              {
                opt_sort1: '색상',
                opt_sort2: '사이즈',
                opt_sort3: '',
                opt_sort1_desc: '파랑',
                opt_sort2_desc: '큼',
                opt_sort3_desc: '',
                sku_cd: 'S999C47FACA0',
                pack_unit: 1,
                add_price: 5000,
                stock_cnt: 88,
                weight: '1',
                status: '품절',
              },
            ],
            input_opt_name: ['입력형옵션1', '입력형옵션2'],
            add_opts: [
              {
                opt_sort: '추가구매타입',
                opt_sort_desc: '추가구매1',
                price: 1000,
                stock_cnt: 20,
                sku_cd: 'S893688C1788',
                pack_unit: 2,
                weight: 0,
                status: '정상',
              },
              {
                opt_sort: '추가구매타입',
                opt_sort_desc: '추가구매2',
                price: 2000,
                stock_cnt: 30,
                sku_cd: 'S1880FB95EB1',
                pack_unit: 1,
                weight: 1,
                status: '품절',
              },
            ],
            tax_type: '과세',
            madein: {
              madein_no: 2,
              madein_etc: '',
              multi_yn: false,
            },
            made_date: '2020-01-01',
            expire_date: '2021-03-01',
            detail_desc: '상세설명',
            brand: '브랜드',
            model: '모델명',
            maker: '제조사',
            sale_img1: 'https://sample.com/productImage.jpg',
            prod_info: [
              {
                infoCode: 'Wear2023',
                infoDetail: {
                  material: '1',
                  color: '2',
                  size: '3',
                  manufacturer: '4',
                  made_in: '5',
                  caution: '6',
                  release: '7',
                  warranty: '8',
                  customer_service: '9',
                },
              },
              {
                infoCode: 'Shoes2023',
                infoDetail: {
                  material: '1',
                  color: '2',
                  size: '3',
                  manufacturer: '4',
                  made_in: '5',
                  caution: '6',
                  warranty: '7',
                  customer_service: '8',
                },
              },
            ],
            add_desc_info: {
              image_info: [
                {
                  title: '타이틀',
                  content: '설명',
                  image_url: 'IMG_URL',
                  image_type: 4,
                },
              ],
            },
          },

          response: [
            {
              title: 'result',
              required: 'O',
              type: 'String',
              desc: '성공/실패',
            },
            {
              title: 'c_sale_cd',
              required: 'O',
              type: 'String',
              desc: '등록된 상품의 판매자관리코드',
            },
            {
              title: 'message',
              required: 'O',
              type: 'String',
              desc: '등록이 실패된경우 에러 메세지',
            },
          ],
          response_example: {
            status: true,
            c_sale_cd: 'm20200925497324',
            message: '',
          },
        },
        {
          version: 'v1.0',
          url: '/products/edit',
          desc: (
            <ul className='style'>
              <li>온라인상품 정보를 수정합니다.</li>
            </ul>
          ),
          method: 'PUT',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'c_sale_cd',
              required: 'O',
              type: 'String',
              desc: '판매자관리코드',
            },
            {
              title: 'sol_cate_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  카테고리코드
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>카테고리 수정이 필요한 경우 입력해주십시오.</li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'shop_sale_name',
              required: '',
              type: 'String',
              desc: (
                <div>
                  상품명
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>상품명은 최대 100Byte까지 등록가능합니다.</li>
                    <li>
                      특수문자는 - _ / ~ + . , ( ) {} [ ] % & '만 사용
                      가능합니다.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'minor_yn',
              required: '',
              type: 'Boolean',
              desc: (
                <div>
                  미성년자 구매여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>true</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sale_price',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  판매가격
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>금액은 10원 단위로 입력해주십시오.</li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'sale_cnt_limit',
              required: '',
              type: 'Number',
              desc: '판매수량',
            },
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  쇼핑몰 코드
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      쇼핑몰코드조회 문서를 참고하여 등록하시려는 사이트의
                      코드를 입력해주십시오.
                    </li>
                    <li>
                      마스터상품을 수정하기 원하시는경우 해당 코드를 'master'로
                      입력해주십시오.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'shop_id',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  쇼핑몰 아이디
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      마스터상품을 수정하기 원하시는 경우 해당값을{' '}
                      <code>master</code>로 입력해주십시오.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'template_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  템플릿번호
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      템플릿 조회 api를 통해 확인된 템플릿 번호를
                      입력해주십시오.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'shop_sale_name_addcontent_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  상품명 머리말/꼬리말 번호
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      머리말/꼬리말 조회 api를 통해 확인된 번호를
                      입력해주십시오.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'addcontent_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  상세설명 머리말/꼬리말 번호
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      머리말/꼬리말 조회 api를 통해 확인된 번호를
                      입력해주십시오.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'std_ol_yn',
              required: '',
              type: 'String',
              desc: (
                <div>
                  단일상품여부
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      값 미입력시 일반상품으로 조회되니 단일상품을 수정할 경우
                      반드시 값을 입력해주십시오.
                    </li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>Y</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>N</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'opt_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  <b>홈플러스, 갤러리아몰, 롯데ON</b>
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>옵션없음으로 수정이 불가능합니다.</li>
                  </ul>
                  <b>쿠팡</b>
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      쇼핑몰에서 옵션명 수정이 불가하므로 옵션수정을 지원하지
                      않습니다.
                    </li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>옵션없음</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>조합형</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>독립형</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'opts',
              required: '',
              type: 'Object[]',
              desc: (
                <div>
                  옵션
                  <div className='description'>
                    (옵션구분이 옵션없음이 아닌경우 필수입력.)
                  </div>
                </div>
              ),
            },
            {
              title: 'opt_sort1',
              required: 'O',
              type: 'String',
              desc: '옵션명1',
              indent: 1,
            },
            {
              title: 'opt_sort2',
              required: '',
              type: 'String',
              desc: '옵션명2',
              indent: 1,
            },
            {
              title: 'opt_sort3',
              required: '',
              type: 'String',
              desc: '옵션명3',
              indent: 1,
            },
            {
              title: 'opt_sort1_desc',
              required: 'O',
              type: 'String',
              desc: '옵션값1',
              indent: 1,
            },
            {
              title: 'opt_sort2_desc',
              required: '',
              type: 'String',
              desc: '옵션값2',
              indent: 1,
            },
            {
              title: 'opt_sort3_desc',
              required: '',
              type: 'String',
              desc: '옵션값3',
              indent: 1,
            },
            {
              title: 'sku_cd',
              required: '',
              type: 'String',
              desc: (
                <div>
                  sku코드
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      sku코드 매칭시 모든 옵션의 sku코드를 입력해야 합니다.
                    </li>
                    <li>
                      sku코드를 매칭하지 않는 옵션이 있을 경우 모든 옵션의
                      sku코드를 비워주십시오.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'pack_unit',
              required: '',
              type: 'Number',
              desc: '출고수량',
              indent: 1,
            },
            {
              title: 'add_price',
              required: '',
              type: 'Number',
              desc: '추가옵션금액',
              indent: 1,
            },
            {
              title: 'stock_cnt',
              required: 'O',
              type: 'Numbr',
              desc: '판매수량',
              indent: 1,
            },
            {
              title: 'weight',
              required: '',
              type: 'Number',
              desc: '옵션무게',
              indent: 1,
            },
            {
              title: 'status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'input_opt_name',
              required: '',
              type: 'Array',
              desc: '입력형옵션',
            },
            {
              title: 'add_opts',
              required: '',
              type: 'Object[]',
              desc: '추가구매옵션',
            },
            {
              title: 'opt_sort',
              required: 'O',
              type: 'String',
              desc: '추가항목명',
              indent: 1,
            },
            {
              title: 'opt_sort_desc',
              required: 'O',
              type: 'String',
              desc: '추가옵션명',
              indent: 1,
            },
            {
              title: 'price',
              required: 'O',
              type: 'Number',
              desc: '추가금액',
              indent: 1,
            },
            {
              title: 'stock_cnt',
              required: 'O',
              type: 'Number',
              desc: '판매수량',
              indent: 1,
            },
            {
              title: 'sku_cd',
              required: '',
              type: 'String',
              desc: 'sku 코드',
              indent: 1,
            },
            {
              title: 'pack_unit',
              required: '',
              type: 'Number',
              desc: '출고수량',
              indent: 1,
            },
            {
              title: 'weight',
              required: '',
              type: 'Number',
              desc: '추가구매옵션무게',
              indent: 1,
            },
            {
              title: 'status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  추가구매옵션 상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'tax_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  과세여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>과세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>면세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>영세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>비과세</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'madein',
              required: '',
              type: 'Object',
              desc: '원산지',
            },
            {
              title: 'madein_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  원산지번호
                  <div className='description'>
                    (원산지 조회 api 호출하여 확인)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'madein_etc',
              required: '',
              type: 'String',
              desc: (
                <div>
                  기타원산지
                  <div className='description'>
                    (등록하려는 원산지가 origins에서 조회되지않을때 madein_no를
                    비우고 madein_etc에 값을 입력합니다.)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'multi_yn',
              required: '',
              type: 'Boolean',
              desc: '복수원산지여부',
              indent: 1,
            },
            {
              title: 'made_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  제조일자
                  <div className='description'>
                    (YYYY-MM-DD의 형식으로 입력해주십시오.)
                  </div>
                </div>
              ),
            },
            {
              title: 'expire_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  유효일자
                  <div className='description'>
                    (YYYY-MM-DD의 형식으로 입력해주십시오.)
                  </div>
                </div>
              ),
            },
            {
              title: 'detail_desc',
              required: '',
              type: 'String',
              desc: '상품상세설명',
            },
            {
              title: 'brand',
              required: '',
              type: 'String',
              desc: '브랜드',
            },
            {
              title: 'model',
              required: '',
              type: 'String',
              desc: '모델명',
            },
            {
              title: 'maker',
              required: '',
              type: 'String',
              desc: '제조사',
            },
            {
              title: 'keyword',
              required: '',
              type: 'String[]',
              desc: (
                <div>
                  키워드
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      키워드는 최대 40개 까지 등록가능하며, 쇼핑몰에 상품전송시
                      쇼핑몰에서 지원하는 입력 갯수만큼만 전송됩니다.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'sale_img1',
              required: '',
              type: 'String',
              desc: '기본이미지 URL',
            },
            {
              title: 'sale_img2',
              required: '',
              type: 'String',
              desc: '추가이미지 1 URL',
            },
            {
              title: 'sale_img3',
              required: '',
              type: 'String',
              desc: '추가이미지 2 URL',
            },
            {
              title: 'sale_img4',
              required: '',
              type: 'String',
              desc: '추가이미지 3 URL',
            },
            {
              title: 'sale_img5',
              required: '',
              type: 'String',
              desc: '추가이미지 4 URL',
            },
            {
              title: 'sale_img6',
              required: '',
              type: 'String',
              desc: '추가이미지 5 URL',
            },
            {
              title: 'sale_img7',
              required: '',
              type: 'String',
              desc: '추가이미지 6 URL',
            },
            {
              title: 'sale_img8',
              required: '',
              type: 'String',
              desc: '추가이미지 7 URL',
            },
            {
              title: 'sale_img9',
              required: '',
              type: 'String',
              desc: '추가이미지 8 URL',
            },
            {
              title: 'sale_img10',
              required: '',
              type: 'String',
              desc: '추가이미지 9 URL',
            },
            {
              title: 'sale_img11',
              required: '',
              type: 'String',
              desc: '추가이미지 10 URL',
            },
            {
              title: 'prod_info',
              required: '',
              type: 'Object[]',
              desc: (
                <div>
                  품목정보고시
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>품목정보고시정보는 3개까지 입력가능합니다.</li>
                    <li>
                      아래의 등록가이드를 참고해주세요.
                      <br />
                      https://gmp-master.s3.ap-northeast-2.amazonaws.com/download/Sample_prodInfo_guide.xlsx
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'code',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  상품분류코드
                  <br />
                  품목정보고시 입력 시 필수 입니다.
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      대표SKU상품 정보를 사용하고 싶으신경우 '대표SKU상품'으로
                      입력합니다.
                    </li>
                    <li>
                      대표SKU상품으로 입력시 아래의 정보는 입력할 필요가
                      없습니다.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_1',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시1',
              indent: 1,
            },
            {
              title: 'prod_info_2',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시2',
              indent: 1,
            },
            {
              title: 'prod_info_3',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시3',
              indent: 1,
            },
            {
              title: 'prod_info_4',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시4',
              indent: 1,
            },
            {
              title: 'prod_info_5',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시5',
              indent: 1,
            },
            {
              title: 'prod_info_6',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시6',
              indent: 1,
            },
            {
              title: 'prod_info_7',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시7',
              indent: 1,
            },
            {
              title: 'prod_info_8',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시8',
              indent: 1,
            },
            {
              title: 'prod_info_9',
              required: 'O',
              type: 'String',
              desc: '상품정보제공고시9',
              indent: 1,
            },
            {
              title: 'prod_info_10',
              required: '',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시10
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_11',
              required: '',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시11
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_12',
              required: '',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시12
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_13',
              required: '',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시13
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_14',
              required: '',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시14
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_15',
              required: '',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시15
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_16',
              required: '',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시16
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_17',
              required: '',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시17
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_18',
              required: '',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시18
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_19',
              required: '',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시19
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_20',
              required: '',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시20
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_21',
              required: '',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시21
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_22',
              required: '',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시22
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_23',
              required: '',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시23
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_info_24',
              required: '',
              type: 'String',
              desc: (
                <div>
                  상품정보제공고시24
                  <div className='description'>
                    (해당되는 값이 있는 경우에만 입력)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ship_price_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  배송방법
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>무료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>선결제</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>착불</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'ship_price',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  배송비
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>배송방법이 무료가 아닌경우 필수입니다.</li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'gift_name',
              required: '',
              type: 'String',
              desc: '사은품',
            },
            {
              title: 'supply_price',
              required: '',
              type: 'Number',
              desc: '공급가',
            },
            {
              title: 'cost_price',
              required: '',
              type: 'Number',
              desc: '원가',
            },
            {
              title: 'street_price',
              required: '',
              type: 'Number',
              desc: '시중가',
            },
            {
              title: 'global_barcode_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  UPC/EAN 타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>UPC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>EAN</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'global_barcode',
              required: '',
              type: 'Number',
              desc: 'UPC/EAN 코드',
            },
            {
              title: 'isbn',
              required: '',
              type: 'Number',
              desc: 'ISBN 코드',
            },
            {
              title: 'barcode',
              required: '',
              type: 'String',
              desc: '바코드',
            },
            {
              title: 'hscd',
              required: '',
              type: 'String',
              desc: 'HS코드',
            },
            {
              title: 'prod_weight',
              required: '',
              type: 'Number',
              desc: '상품무게',
            },
            {
              title: 'cert_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  인증정보타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>인증대상아님</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>인증유형등록</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>인증대상아님</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>상세설명내등록</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'certs',
              required: '',
              type: 'Array',
              desc: (
                <div>
                  인증정보
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>인증정보는 10개까지 등록가능합니다.</li>
                    <li>
                      인증정보타입이 인증유형등록인 경우 필수로
                      입력해주셔야합니다.
                    </li>
                  </ul>
                </div>
              ),
            },
            {
              title: 'cert_cd',
              required: '',
              type: 'String',
              desc: (
                <div>
                  인증유형
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    {' '}
                    <li>cert_01: 생활용품 안전인증</li>{' '}
                    <li>cert_02: 생활용품 안전확인</li>{' '}
                    <li>cert_03: 생활용품 어린이보호포장</li>{' '}
                    <li>cert_04: 생활용품 안전품질표시</li>{' '}
                    <li>cert_05: 생활용품 공급자적합성확인</li>{' '}
                    <li>cert_06: 전기용품 안전인증</li>{' '}
                    <li>cert_07: 전기용품 안전확인</li>{' '}
                    <li>cert_08: 전기용품 공급자적합성확인</li>{' '}
                    <li>cert_09: 방송통신용품 적합성인증확인</li>{' '}
                    <li>cert_10: 어린이제품 안전인증</li>{' '}
                    <li>cert_11: 어린이제품 안전확인</li>{' '}
                    <li>cert_12: 어린이제품 공급자적합성확인</li>{' '}
                    <li>cert_13: 위해우려제품 자가검사</li>{' '}
                    <li>cert_14: 방송통신기자재 잠정인증</li>{' '}
                    <li>cert_15: 방송통신기자재 적합등록</li>{' '}
                    <li>cert_16: 원산지증명</li> <li>cert_17: 해외안전인증</li>{' '}
                    <li>cert_18: 수입신고필증</li>{' '}
                    <li>cert_19: 친환경인증-무농약농산물</li>{' '}
                    <li>cert_20: 친환경인증-유기축산물</li>{' '}
                    <li>cert_21: 친환경인증-유기농산물</li>{' '}
                    <li>cert_22: 친환경인증-저농약농산물</li>{' '}
                    <li>cert_23: 친환경인증-무항생제축산물</li>{' '}
                    <li>cert_24: HACCP(위해요소 중점관리우수식품)</li>{' '}
                    <li>cert_25: GMP(우수건강기능식품 제조기준)</li>{' '}
                    <li>cert_26: GAP(농산물우수관리인증)</li>{' '}
                    <li>cert_27: 농산물이력추적관리</li>{' '}
                    <li>cert_28: 수산물품질인증</li>{' '}
                    <li>cert_29: 수산특산물품질인증</li>{' '}
                    <li>cert_30: 수산전통식품품질인증</li>{' '}
                    <li>cert_31: 친환경수산물품질인증</li>{' '}
                    <li>cert_32: 친환경수산가공품품질인증</li>{' '}
                    <li>cert_33: 전통식품 품질인증</li>{' '}
                    <li>cert_34: 유기가공식품 인증</li>{' '}
                    <li>cert_35: 가공식품 산업표준 KS인증</li>{' '}
                    <li>cert_36: 건강기능식품 광고사전심의</li>{' '}
                    <li>cert_37: 의료기기 광고사전심의</li>{' '}
                    <li>cert_38: 축산물 등급판정 확인서</li>{' '}
                    <li>cert_39: 도축검사증명서</li>{' '}
                    <li>cert_40: 식품명인(Grand master) 인증</li>{' '}
                    <li>cert_41: 사회적기업 인증</li>{' '}
                    <li>cert_42: 6차산업 인증</li>{' '}
                    <li>cert_43: 동물복지 인증</li>{' '}
                    <li>cert_44: 마을기업 인증</li>{' '}
                    <li>cert_45: 건강기능식품인증</li>{' '}
                    <li>cert_46: 생활화학제품</li>{' '}
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cert_exc_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  면제유형
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>kc_01: 안전기준준수대상</li>
                    <li>kc_02: 구매대행</li>
                    <li>kc_03: 병행수입</li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_01</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_02</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_03</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cert_ministry',
              required: '',
              type: 'String',
              desc: '인증기관',
              indent: 1,
            },
            {
              title: 'cert_no',
              required: 'O',
              type: 'String',
              desc: '인증번호',
              indent: 1,
            },
            {
              title: 'cert_model',
              required: '',
              type: 'String',
              desc: '인증모델',
              indent: 1,
            },
            {
              title: 'cert_cname',
              required: '',
              type: 'String',
              desc: '인증상호',
              indent: 1,
            },
            {
              title: 'cert_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  인증일
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>YYYY-MM-DD의 날짜형식으로 입력해주십시오.</li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cert_expire_date',
              required: '',
              type: 'String',
              desc: (
                <div>
                  인증만료일
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>YYYY-MM-DD의 날짜형식으로 입력해주십시오.</li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'set_info',
              required: '',
              type: 'Object',
              desc: (
                <div>
                  등록 상품이 쇼핑몰 29CM인 경우만 상세 설명 이미지
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>객체의 키 값은 쇼핑몰 코드</li>
                    <li>
                      상세 설명 이미지가 하나 이상은 반드시 존재해야 됩니다.
                    </li>
                    <li>
                      [image_type]
                      <ul className='style' style={{ color: 'gray' }}>
                        <li>4: 기본 타입</li> <li>5: 영상 타입</li>
                        <li>6: 타이틀 구분 타입</li>
                        <li>7: 이미지 링크 타입</li>
                      </ul>
                    </li>
                  </ul>
                  <pre>
                    <span className='pun'>{'{'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>"image_info"</span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='pun'>[</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'    {'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>&nbsp;&nbsp;&nbsp;&nbsp;"title"</span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"타이틀"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"content"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"설명"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_url"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"IMG_URL"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_type"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='lit'>4</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'}'},</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'{'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>&nbsp;&nbsp;&nbsp;&nbsp;"title"</span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"타이틀"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"content"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"설명"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"added_url"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"VIDEO_URL"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_url"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"IMG_URL"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"added_type"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='lit'>1</span>
                    <span className='pun'>,</span>
                    <span className='pln'> </span>
                    <span className='com'> # 1: 유튜브, 2: 비메오 </span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_type"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='lit'>5</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'}'},</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'{'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>&nbsp;&nbsp;&nbsp;&nbsp;"title"</span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"타이틀"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_url"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='pun'>[</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'        {'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"content"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"설명"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"image_url"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"IMG_URL"</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'        }'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>&nbsp;&nbsp;&nbsp;&nbsp;],</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"is_layout"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"F"</span>
                    <span className='pun'>,</span>
                    <span className='pln'> </span>
                    <span className='com'>
                      {' '}
                      # "F": 레이아웃 1단, "T": 레이아웃 2단
                    </span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_type"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='lit'>6</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'}'},</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'{'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"added_url"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"IMG_LINK_URL"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_url"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='str'>"IMG_URL"</span>
                    <span className='pun'>,</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='str'>
                      &nbsp;&nbsp;&nbsp;&nbsp;"image_type"
                    </span>
                    <span className='pun'>:</span>
                    <span className='pln'> </span>
                    <span className='lit'>7</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pln'> </span>
                    <span className='pun'>{'    }'}</span>
                    <br />
                    <span className='pln'> </span>
                    <span className='pun'>{'}'}</span>
                    <span className='pln'> </span>
                  </pre>
                </div>
              ),
            },
          ],
          body_example: {
            c_sale_cd: 'm20200928724997',
            sol_cate_no: 85036,
            shop_sale_name: 'api 테스트 상품 수정',
            minor_yn: true,
            sale_price: 50000,
            sale_cnt_limit: 88,
            shop_cd: 'A112',
            shop_id: 'playauto',
            edit_master_prod: true,
            std_ol_yn: 'N',
            opt_type: '조합형',
            opts: [
              {
                opt_sort1: '색상',
                opt_sort2: '사이즈',
                opt_sort3: '',
                opt_sort1_desc: '빨강',
                opt_sort2_desc: '큼',
                opt_sort3_desc: '',
                sku_cd: 'S999C90F18A4',
                pack_unit: 1,
                add_price: 0,
                stock_cnt: 99,
                weight: 0.5,
                status: '정상',
              },
              {
                opt_sort1: '색상',
                opt_sort2: '사이즈',
                opt_sort3: '',
                opt_sort1_desc: '파랑',
                opt_sort2_desc: '큼',
                opt_sort3_desc: '',
                sku_cd: 'S999C47FACA0',
                pack_unit: 1,
                add_price: 5000,
                stock_cnt: 88,
                weight: '1',
                status: '품절',
              },
            ],
            input_opt_name: ['입력형옵션1', '입력형옵션2'],
            add_opts: [
              {
                opt_sort: '추가구매타입',
                opt_sort_desc: '추가구매1',
                price: 1000,
                stock_cnt: 20,
                sku_cd: 'S893688C1788',
                pack_unit: 2,
                weight: 0,
                status: '정상',
              },
              {
                opt_sort: '추가구매타입',
                opt_sort_desc: '추가구매2',
                price: 2000,
                stock_cnt: 30,
                sku_cd: 'S1880FB95EB1',
                pack_unit: 1,
                weight: 1,
                status: '품절',
              },
            ],
            tax_type: '과세',
            madein: {
              madein_no: 2,
              madein_etc: '',
              multi_yn: false,
            },
            made_date: '2020-01-01',
            expire_date: '2021-03-01',
            detail_desc: '상세설명',
            brand: '브랜드',
            model: '모델명',
            maker: '제조사',
            sale_img1: 'https://sample.com/productImage.jpg',
            prod_info: [
              {
                code: '01',
                prod_info_1: '면-95',
                prod_info_2: 'N',
                prod_info_3: '빨강,파랑',
                prod_info_4: '85,90',
                prod_info_5: '플레이오토',
                prod_info_6: 'Y',
                prod_info_7: '플레이오토',
                prod_info_8: '중국',
                prod_info_9: '드라이크리닝해주세요',
                prod_info_10: '화기에 주의하세요',
                prod_info_11: '2016-09',
                prod_info_12: '구입일로부터 1년',
                prod_info_13: '플레이오토/070-123-4567',
                prod_info_14: '',
                prod_info_15: '',
                prod_info_16: '',
                prod_info_17: '',
                prod_info_18: '',
                prod_info_19: '',
                prod_info_20: '',
                prod_info_21: '',
                prod_info_22: '',
                prod_info_23: '',
                prod_info_24: '',
              },
            ],
            add_desc_info: {
              image_info: [
                {
                  title: '타이틀',
                  content: '설명',
                  image_url: 'IMG_URL',
                  image_type: 4,
                },
              ],
            },
          },

          response: [
            {
              title: 'result',
              required: 'O',
              type: 'String',
              desc: '성공/실패',
            },
            {
              title: 'c_sale_cd',
              required: 'O',
              type: 'String',
              desc: '등록된 상품의 판매자관리코드',
            },
            {
              title: 'message',
              required: 'O',
              type: 'String',
              desc: '등록이 실패된경우 에러 메세지',
            },
          ],
          response_example: {
            status: true,
            c_sale_cd: 'm20200925497324',
            message: '',
          },
        },
      ],
    },
    {
      title: '온라인상품 판매수량 수정',
      url: '/doc/온라인상품 판매수량 수정',
      data: [
        {
          version: 'v1.0',
          url: '/products/sale-cnt',
          desc: (
            <ul className='style'>
              <li>
                온라인상품의 쇼핑몰판매수량 및 등록된 옵션정보의 판매수량을
                수정합니다.
              </li>
              <li>
                옵션 판매수량 수정시 sku 정보가 매칭된 옵션만 수정가능합니다.
              </li>
            </ul>
          ),
          method: 'PATCH',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'products',
              required: 'O',
              type: 'Array',
              desc: '상품정보',
            },
            {
              title: 'c_sale_cd',
              required: 'O',
              type: 'String',
              desc: '판매자관리코드',
              indent: 1,
            },
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰코드',
              indent: 1,
            },
            {
              title: 'shop_id',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰아이디',
              indent: 1,
            },
            {
              title: 'std_ol_yn',
              required: '',
              type: '',
              desc: (
                <div>
                  단일상품여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>N</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>Y</code>
                          </strong>
                        </td>
                        <td>단일상품</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>N</code>
                          </strong>
                        </td>
                        <td>일반상품</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sale_cnt_limit',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  쇼핑몰판매수량
                  <div className='description'>(상품정보)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'opts',
              required: 'O',
              type: 'Array',
              desc: '상품 옵션정보',
              indent: 1,
            },
            {
              title: 'type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션 구분
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>general</code>
                          </strong>
                        </td>
                        <td>일반옵션</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>additional</code>
                          </strong>
                        </td>
                        <td>추가구매옵션</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'sku_cd',
              required: 'O',
              type: 'String',
              desc: 'SKU코드',
              indent: 2,
            },
            {
              title: 'stock_cnt',
              required: 'O',
              type: 'Number',
              desc: '옵션판매수량',
              indent: 2,
            },
            {
              title: 'status',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션 상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
          ],
          body_example: {
            products: [
              {
                c_sale_cd: 'm202212095bb6ecdb5',
                shop_cd: 'A001',
                shop_id: 'myshopy3',
                std_ol_yn: 'N',
                opts: [
                  {
                    type: 'general',
                    sku_cd: 'S186F46BD14F',
                    stock_cnt: 10,
                    status: '정상',
                  },
                  {
                    type: 'additional',
                    sku_cd: 'S52590348AD7',
                    stock_cnt: 33,
                    status: '정상',
                  },
                ],
              },
            ],
          },
          response: [
            {
              title: 'c_sale_cd',
              required: 'O',
              type: 'String',
              desc: '판매자 관리코드',
            },
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰코드',
            },
            {
              title: 'shop_id',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰아이디',
            },
            {
              title: 'std_ol_yn',
              required: 'O',
              type: 'String',
              desc: '단일상품여부',
            },
            {
              title: 'result',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  성공 또는 실패
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>성공</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>실패</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'message',
              required: 'O',
              type: 'String',
              desc: '실패시 에러메세지',
            },
          ],
          response_example: [
            {
              c_sale_cd: 'm202212095bb6ecdb5',
              shop_cd: 'A001',
              shop_id: 'myshopy3',
              std_ol_yn: 'N',
              result: '성공',
              message: '',
            },
          ],
        },
      ],
    },
    {
      title: '온라인상품 연동 수정/해제',
      url: '/doc/온라인상품 연동 수정',
      data: [
        {
          version: 'v1.0',
          url: '/products/setShopLinkInfo',
          method: 'PATCH',
          desc: (
            <ul className='style'>
              <li>
                온라인 상품의 쇼핑몰 상품코드를 직접 입력하여 쇼핑몰 연동 정보를 변경합니다.
              </li>
              <li>
                온라인상품에 해당 쇼핑몰의 판매중인 상품번호 또는 상품코드를 입력합니다.
              </li>
              <li>
                <div style={{ color: 'red' }}>솔루션의 상태만 변경되고 실제 쇼핑몰의 상태는 변경되지 않습니다.</div>
              </li>
            </ul>
          ),
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'type',
              required: 'O',
              type: 'string',
              desc: (
                <div>
                  연동 방식 선택
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      edit: 연동 수정 (쇼핑몰 상품 코드를 입력하며 해당 상품의 상태를 판매중으로 변경)
                    </li>
                    <li>
                      stop: 연동 해제 (쇼핑몰 상품 코드를 삭제하며 해당 상품의 상태를 판매대기로 변경)
                    </li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>edit</code>
                          </strong>
                        </td>
                        <td>연동 수정</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>stop</code>
                          </strong>
                        </td>
                        <td>연동 해제</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sale_data',
              required: 'O',
              type: 'Object[]',
              desc: '연동할 상품 데이터'
            },
            ...(userInfo.sol_no === 104083 ? [{
              title: 'sub_sol_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  하위계정 sol_no<br/>
                  <span style={{color: 'red'}}>* 하위 계정 상품을 연동 수정할 경우 에픽카(쇼핑몰)만 수정 가능합니다.</span>
                </div>
              ),
              indent: 1
            }] : []),
            {
              title: 'c_sale_cd',
              required: 'O',
              type: 'String',
              desc: '판매자 관리코드',
              indent: 1
            },
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 코드',
              indent: 1
            },
            {
              title: 'shop_id',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 ID',
              indent: 1
            },
            {
              title: 'shop_sale_no',
              required: 'O',
              type: 'String',
              desc: '연동할 쇼핑물 상품코드',
              indent: 1
            }
          ],
          body_example: {
            type: 'edit',
            sale_data: [
              {
                c_sale_cd: 'm202212095bb6ecdb5',
                shop_cd: 'A001',
                shop_id: 'myshopy3',
                shop_sale_no: '12345678'
              },
              {
                c_sale_cd: 'm202212095bb6ecdb5',
                shop_cd: 'A006',
                shop_id: 'myshopy2',
                shop_sale_no: '1234567851525'
              }
            ],
          },
          response: [
            {
              title: 'results',
              required: 'O',
              type: 'Object[]',
              desc: '결과값',
            },
            {
              title: 'success',
              required: '',
              type: 'Number',
              desc: '연동 수정/해제 성공 수\n(성공할 경우에만 존재)',
              indent: 1
            },
            {
              title: 'error',
              required: '',
              type: 'Number',
              desc: '연동 수정/해제 실패 수\n(실패할 경우에만 존재)',
              indent: 1
            },
            {
              title: 'duplicates',
              required: '',
              type: 'Number',
              desc: '입력한 쇼핑몰 상품코드 중 이미 존재하는 쇼핑몰 상품코드 수\n(상품이 중복될 경우에만 존재)',
              indent: 1
            },
            {
              title: 'values',
              required: '',
              type: 'String[]',
              desc: '중복된 쇼핑몰 상품코드 리스트\n(상품이 중복될 경우에만 존재)',
              indent: 1
            },
          ],
          response_example: [
            {
              results: [
                {
                  success: 2,
                  error:2,
                  duplicates:2,
                  values: []
                }
              ]
            }
          ],
        },
      ],
    },
    {
      title: '카테고리 조회',
      url: '/doc/카테고리 조회',
      data: [
        {
          version: 'v1.0',
          url: '/categorys',
          desc: '사용중인 솔루션에 등록된 카테고리 목록을 조회합니다.',
          method: 'GET',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'start',
              required: '',
              type: 'Number',
              desc: '검색 시작 값',
            },
            {
              title: 'length',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  조회할 카테고리 갯수
                  <div className='description'>
                    최대 1,000건 단위로 검색 가능하며 미입력 시 전체 조회됩니다.
                    <div style={{ padding: '12px 0' }}>
                      <strong>Max value: <code>1000</code></strong>
                    </div>
                  </div>
                </div>
              ),
            },
          ],
          response: [
            {
              title: 'group',
              required: 'O',
              type: 'String',
              desc: '카테고리 분류',
            },
            {
              title: 'category_info',
              required: 'O',
              type: 'Array',
              desc: '해당 분류에 설정된 카테고리 정보',
            },
            {
              title: 'cate_no',
              required: 'O',
              type: 'Number',
              desc: '카테고리코드',
              indent: 1,
            },
            {
              title: 'category_name',
              required: 'O',
              type: 'String',
              desc: '설정된 카테고리',
              indent: 1,
            },
            {
              title: 'matched_siteCate',
              required: 'O',
              type: 'Array',
              desc: '해당 카테고리정보에 매칭된 쇼핑몰 카테고리 정보',
              indent: 1,
            },
            {
              title: 'shop_name',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 이름',
              indent: 2,
            },
            {
              title: 'shop_id',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 아이디',
              indent: 2,
            },
            {
              title: 'shop_cate',
              required: 'O',
              type: 'String',
              desc: '해당 쇼핑몰에 매칭된 카테고리',
              indent: 2,
            },
          ],
          response_example: [
            {
              group: '테스트 카테고리',
              category_info: [
                {
                  cate_no: 103592,
                  category_name: '여성의류 > 가디건 > 라운드/U넥가디건',
                  matched_siteCate: [
                    {
                      shop_name: '11번가',
                      shop_id: 'playauto',
                      shop_cate: '여성의류 > 가디건 > 기본 가디건',
                    },
                    {
                      shop_name: '지마켓',
                      shop_id: 'playauto',
                      shop_cate: '여성의류 > 가디건 > 롱가디건',
                    },
                    '...',
                  ],
                },
              ],
            },
            '......',
          ],
        },
      ],
    },
    
    {
      title: '템플릿 조회',
      url: '/doc/템플릿 조회',
      data: [
        {
          version: 'v1.0',
          url: '/templates/?masking_yn=masking_yn',
          desc: '사용중인 솔루션에 등록된 템플릿 목록을 조회합니다.',
          method: 'GET',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'masking_yn',
              required: '',
              type: 'Boolean',
              desc: (
                <div>
                  개인정보 마스킹 처리 여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
          ],
          response: [
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String',
              desc: '사이트코드',
            },
            {
              title: 'shop_name',
              required: 'O',
              type: 'String',
              desc: '사이트명',
            },
            {
              title: 'shop_id',
              required: 'O',
              type: 'String',
              desc: '사이트아이디',
            },
            {
              title: 'template_info',
              required: 'O',
              type: 'Array',
              desc: '템플릿 정보',
            },
            {
              title: 'template_no',
              required: 'O',
              type: 'Number',
              desc: '템플릿코드',
              indent: 1,
            },
            {
              title: 'wdate',
              required: 'O',
              type: 'String',
              desc: '템플릿 등록일',
              indent: 1,
            },
            {
              title: 'mdate',
              required: 'O',
              type: 'String',
              desc: '템플릿 수정일',
              indent: 1,
            },
            {
              title: 'name',
              required: 'O',
              type: 'String',
              desc: '템플릿 이름',
              indent: 1,
            },
            {
              title: 'apply_prod_cnt',
              required: 'O',
              type: 'Number',
              desc: '템플릿이 적용되어있는 온라인 상품 수',
              indent: 1,
            },
            {
              title: 'register_name',
              required: 'O',
              type: 'String',
              desc: '등록자',
              indent: 1,
            },
          ],
          response_example: [
            {
              shop_cd: 'A112',
              shop_name: '11번가',
              shop_id: 'playauto',
              template_info: [
                {
                  template_no: 41538,
                  wdate: '2018-08-31 14:36:07',
                  mdate: '2019-08-05 16:04:12',
                  name: '테스트 템플릿',
                  apply_prod_cnt: 3,
                  register_name: '김바보',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: '쇼핑몰 상품 상태 변경',
      url: '/doc/쇼핑몰 상품 상태 변경',
      data: [
        {
          version: 'v1.0',
          url: '/products/setState',
          method: 'PATCH',
          authorized_m_no: [181578],
          desc: (
            <ul className='style'>
              <li>
                하위 부품사의 [승인대기] 상태인 에픽카 쇼핑몰의 상품을 판매중으로 변경합니다.
              </li>
              <li>
                하위 부품사의 온라인상품코드를 통하여 상품 상태를 변경합니다.
              </li>
              <li>
                <div style={{ color: 'red' }}>에픽카 쇼핑몰인 [승인대기] 상태의 상품인 경우에만 가능합니다.</div>
              </li>
            </ul>
          ),
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'sale_data',
              required: 'O',
              type: 'Object[]',
              desc: '연동할 상품 데이터'
            },
            {
              title: 'sub_sol_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  하위계정 sol_no
                </div>
              ),
              indent: 1
            },
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  <div>쇼핑몰 코드</div><br/>
                  <span style={{color: 'red'}}>* 에픽카(쇼핑몰)만 수정 가능합니다.</span>
                </div>
              ),
              indent: 1
            },
            {
              title: 'shop_id',
              required: 'O',
              type: 'String',
              desc: '쇼핑몰 ID',
              indent: 1
            },
            {
              title: 'shop_sale_no',
              required: 'O',
              type: 'String',
              desc: '수정할 상품의 쇼핑물 상품코드',
              indent: 1
            }
          ],
          body_example: {
            sale_data:[
              {
                sub_sol_no: 103419,
                shop_cd:"P051",
                shop_id:"test",
                shop_sale_no:"424242"
              },
              {
                sub_sol_no: 103419,
                shop_cd:"P051",
                shop_id:"test",
                shop_sale_no:"!!23112344"
              }
            ]

          },
          response: [
            {
              title: 'results',
              required: 'O',
              type: 'Object[]',
              desc: '결과값',
            },
            {
              title: 'status',
              required: '',
              type: 'String',
              desc: '해당 쇼핑몰 상품 상태 업데이트 성공/실패 여부',
              indent: 1
            },
            {
              title: 'shop_sale_no',
              required: '',
              type: 'String',
              desc: '해당 쇼핑몰 상품 번호',
              indent: 1
            },
            {
              title: 'message',
              required: '',
              type: 'String',
              desc: '판매중 상태 변경 실패한 경우 메세지',
              indent: 1
            },
          ],
          response_example: [
            {
              results: [
                {
                  status: 'error',
                  shop_sale_no: '424242',
                  message: "[승인대기] 이외의 상태는 변경할 수 없습니다."
                },
                {
                  status: "success",
                  shop_sale_no: "!!23112344"
                }
              ]
            }
          ],
        },
      ],
    },
    {
      title: '수집 상품 리스트 조회',
      url: '/doc/수집 상품 리스트 조회',
      data: [
        {
          version: 'v1.0',
          url: '/product/online/listOfScrap',
          desc: (
            <div>
              <ul className='style'>
                <li>상품 수집 작업을 통해 수집한 상품을 조회합니다.</li>
              </ul>
            </div>
          ),
          method: 'GET',
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'shop_cd',
              required: '',
              type: 'String',
              desc: (
                <div>
                  쇼핑몰코드
                  <div className='description'>
                    ( 옥션: A001, 지마켓: A006, 11번가: A112, 인터파크: A027...
                    )
                  </div>
                </div>
              ),
            },
            {
              title: 'shop_id',
              required: '',
              type: 'String',
              desc: '쇼핑몰아이디',
            },
            {
              title: 'start',
              required: '',
              type: 'Number',
              desc: '검색 시작 값',
            },
            {
              title: 'length',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  조회할 상품 갯수
                  <div className='description'>
                    Max value:{' '}
                    <strong>
                      <code>300</code>
                    </strong>
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>100</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sdate',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색시작일
                  <div className='description'>(YYYY-MM-DD)</div>
                </div>
              ),
            },
            {
              title: 'edate',
              required: '',
              type: 'String',
              desc: (
                <div>
                  검색종료일
                  <div className='description'>(YYYY-MM-DD)</div>
                </div>
              ),
            },
            {
              title: 'opt_yn',
              required: '',
              type: 'Boolean',
              desc: (
                <div>
                  옵션 사용여부
                  <div className='description'>
                    ["Y: 옵션이 있는 상품만 조회", "N": 옵션이 없는 상품만
                    조회"]
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <strong>
                    <ul className='style'>
                      <li>
                        <code>값을 넣지않는경우 전체검색됩니다.</code>
                      </li>
                    </ul>
                  </strong>
                </div>
              ),
            }
          ],
          body_example: {
            shop_cd: "A001",
            shop_id: "myshopy3",
            start: 0,
            length: 100,
            sdate: '2020-07-20',
            edate: '2022-12-31',
            opt_yn: '',
          },
          response: [
            {
              title: 'results',
              required: 'O',
              type: 'Array',
              desc: '검색결과는 수집 상품 번호로 그룹화되어 배열로 출력됩니다.',
            },
            {
              title: 'sol_no',
              required: 'O',
              type: 'Number(8)',
              desc: '솔루션번호',
              indent: 1,
            },
            {
              title: 'import_ol_shop_no',
              required: 'O',
              type: 'Number(10)',
              desc: '수집 상품 번호',
              indent: 1,
            },
            {
              title: 'shop_cd',
              required: 'O',
              type: 'String(4)',
              desc: '쇼핑몰 코드',
              indent: 1,
            },
            {
              title: 'shop_id',
              required: 'O',
              type: 'String(40)',
              desc: '쇼핑몰 계정 아이디',
              indent: 1,
            },
            {
              title: 'shop_sale_no',
              required: 'O',
              type: 'String(40)',
              desc: '쇼핑몰 판매번호',
              indent: 1,
            },
            {
              title: 'shop_sale_no_sub',
              required: 'O',
              type: 'Number(80)',
              desc: (
                <div>
                  쇼핑몰 판매 번호(쇼핑몰상품코드 서브)
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sale_no',
              required: 'O',
              type: 'Number(11)',
              desc: (
                <div>
                  마스터상품 고유번호
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>
                      해당 번호가 같은 온라인상품은 동일 마스터상품에
                      묶여있습니다.
                    </li>
                  </ul>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'c_sale_cd',
              required: 'O',
              type: 'String(40)',
              desc: '판매자관리코드',
              indent: 1,
            },
            {
              title: 'shop_c_sale_cd',
              required: 'O',
              type: 'String(40)',
              desc: '쇼핑몰판매자 관리코드',
              indent: 1,
            },
            {
              title: 'std_ol_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  단일상품 여부
                  <div className='description'>(0: 일반상품, 1: 단일상품)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'group_info',
              required: 'O',
              type: 'String',
              desc: '그룹 정보',
              indent: 1,
            },
            {
              title: 'wdate',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  수집 상품 등록일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_sale_name',
              required: 'O',
              type: 'String(200)',
              desc: '쇼핑몰상품명',
              indent: 1,
            },
            {
              title: 'shop_promotion_name',
              required: 'O',
              type: 'String(100)',
              desc: '상품 프로모션명',
              indent: 1,
            },
            {
              title: 'shop_cate_no',
              required: 'O',
              type: 'Number(8)',
              desc: '쇼핑몰 카테고리 번호',
              indent: 1,
            },
            {
              title: 'shop_cate_no_add_info',
              required: 'O',
              type: 'Object',
              desc: '쇼핑몰 카테고리 추가 정보',
              indent: 1,
            },
            {
              title: 'sale_stime',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  판매 시작일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sale_etime',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  판매 종료일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'gift_name',
              required: 'O',
              type: 'String',
              desc: '사은품',
              indent: 1,
            },
            {
              title: 'opt_sort1',
              required: 'O',
              type: 'String(80)',
              desc: '옵션구분 1',
              indent: 1,
            },
            {
              title: 'opt_sort2',
              required: 'O',
              type: 'String(80)',
              desc: '옵션구분 2',
              indent: 1,
            },
            {
              title: 'opt_sort3',
              required: 'O',
              type: 'String(80)',
              desc: '옵션구분 3',
              indent: 1,
            },
            {
              title: 'opt_sort1_desc',
              required: 'O',
              type: 'String',
              desc: '옵션값1',
              indent: 1,
            },
            {
              title: 'opt_sort2_desc',
              required: 'O',
              type: 'String',
              desc: '옵션값2',
              indent: 1,
            },
            {
              title: 'opt_sort3_desc',
              required: 'O',
              type: 'String',
              desc: '옵션값3',
              indent: 1,
            },
            {
              title: 'opt_sort1_cd',
              required: 'O',
              type: 'String(10)',
              desc: '옵션구분코드 1',
              indent: 1,
            },
            {
              title: 'opt_sort2_cd',
              required: 'O',
              type: 'String(10)',
              desc: '옵션구분코드 2',
              indent: 1,
            },
            {
              title: 'opt_sort3_cd',
              required: 'O',
              type: 'String(10)',
              desc: '옵션구분코드 3',
              indent: 1,
            },
            {
              title: 'detail_desc',
              required: 'O',
              type: 'String(16777215)',
              desc: '상품 상세설명',
              indent: 1,
            },
            {
              title: 'keyword',
              required: 'O',
              type: 'String',
              desc: '키워드',
              indent: 1,
            },
            {
              title: 'tax_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  과세여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>과세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>면세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>영세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>비과세</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'madein_no',
              required: 'O',
              type: 'Number',
              desc: (
                <div>
                  원산지번호
                  <div className='description'>
                    (원산지 조회 api 호출하여 확인)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'madein_etc',
              required: 'O',
              type: 'String(30)',
              desc: (
                <div>
                  기타원산지
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'multi_madein_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  복수원산지여부
                  <div className='description'>
                    (1: 복수원산지, 0: 복수원산지 아님)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'model',
              required: 'O',
              type: 'String(40)',
              desc: '모델명',
              indent: 1,
            },
            {
              title: 'brand',
              required: 'O',
              type: 'String(40)',
              desc: '브랜드',
              indent: 1,
            },
            {
              title: 'maker',
              required: 'O',
              type: 'String(40)',
              desc: '제조사',
              indent: 1,
            },
            {
              title: 'barcode',
              required: 'O',
              type: 'String(50)',
              desc: '바코드',
              indent: 1,
            },
            {
              title: 'sale_price',
              required: 'O',
              type: 'Number(12)',
              desc: '상품 금액',
              indent: 1,
            },
            {
              title: 'supply_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  공급가
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'cost_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  상품 원가
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'street_price',
              required: 'O',
              type: 'Number(12)',
              desc: (
                <div>
                  시중가
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ship_price_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  배송비 종류
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>무료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>조건부무료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>착불</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>선결제</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'ship_price',
              required: 'O',
              type: 'Number(8)',
              desc: '배송비 금액',
              indent: 1,
            },
            {
              title: 'sale_cnt_limit',
              required: 'O',
              type: 'Number(10)',
              desc: '판매수량',
              indent: 1,
            },
            {
              title: 'sale_img1',
              required: 'O',
              type: 'String(200)',
              desc: '기본이미지',
              indent: 1,
            },
            {
              title: 'sale_img2',
              required: 'O',
               type: 'String(200)',
              desc: '추가이미지 1',
              indent: 1,
            },
            {
              title: 'sale_img3',
              required: 'O',
               type: 'String(200)',
              desc: '추가이미지 2',
              indent: 1,
            },
            {
              title: 'sale_img4',
              required: 'O',
               type: 'String(200)',
              desc: '추가이미지 3',
              indent: 1,
            },
            {
              title: 'sale_img5',
              required: 'O',
               type: 'String(200)',
              desc: '추가이미지 4',
              indent: 1,
            },
            {
              title: 'sale_img6',
              required: 'O',
               type: 'String(200)',
              desc: '추가이미지 5',
              indent: 1,
            },
            {
              title: 'sale_img7',
              required: 'O',
               type: 'String(200)',
              desc: '추가이미지 6',
              indent: 1,
            },
            {
              title: 'sale_img8',
              required: 'O',
               type: 'String(200)',
              desc: '추가이미지 7',
              indent: 1,
            },
            {
              title: 'sale_img9',
              required: 'O',
               type: 'String(200)',
              desc: '추가이미지 8',
              indent: 1,
            },
            {
              title: 'sale_img10',
              required: 'O',
               type: 'String(200)',
              desc: '추가이미지 9',
              indent: 1,
            },
            {
              title: 'sale_img11',
              required: 'O',
               type: 'String(200)',
              desc: '추가이미지 10',
              indent: 1,
            },
            {
              title: 'opt_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  옵션 타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>옵션없음</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>조합형</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>독립형</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>간편옵션</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'use_addopt_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  추가구매옵션 여부
                  <div className='description'>
                    (1: 추가구매옵션 사용, 0: 추가구매옵션 사용안함)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'input_opt_name',
              required: 'O',
              type: 'String',
              desc: '입력형옵션',
              indent: 1,
            },
            {
              title: 'cert_type',
              required: 'O',
              type: 'String',
              desc: (
                <div>
                  인증정보타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>인증유형등록</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>인증대상아님</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>상세설명내등록</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'isbn',
              required: 'O',
              type: 'Number(13)',
              desc: (
                <div>
                  isbn 번호
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_noti_data',
              required: 'O',
              type: 'Object',
              desc: (
                <div>
                  상품정보제공고시
                  <div className='description'>
                    상품분류코드에 따라 infoDetail 안에 키 값이 달라질 수
                    있습니다.
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'made_date',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  생산일 일자
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'expire_date',
              required: 'O',
              type: 'String(19)',
              desc: (
                <div>
                  유통 기한
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'map_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '매핑 여부',
              indent: 1,
            },
            {
              title: 'foreign_ship_avail_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '해외 배송 가능 여부',
              indent: 1,
            },
            {
              title: 'adult_yn',
              required: 'O',
              type: 'Number(1)',
              desc: (
                <div>
                  성인전용 여부
                  <div className='description'>
                    (1: 성인용품, 0: 성인용품 아님)
                  </div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_weight',
              required: 'O',
              type: 'Number(1)',
              desc: '대표 상품 상품 무게',
              indent: 1,
            },
            {
              title: 'detail_desc_ad',
              required: 'O',
              type: 'String',
              desc: '상세설명_광고홍보',
              indent: 1,
            },
            {
              title: 'detail_desc_add',
              required: 'O',
              type: 'String',
              desc: '상세설명_추가',
              indent: 1,
            },
            {
              title: 'detail_desc_eng',
              required: 'O',
              type: 'String',
              desc: '상세설명_영어',
              indent: 1,
            },
            {
              title: 'detail_desc_chn',
              required: 'O',
              type: 'String',
              desc: '상세설명_중국어',
              indent: 1,
            },
            {
              title: 'detail_desc_jpn',
              required: 'O',
              type: 'String',
              desc: '상세설명_일본어',
              indent: 1,
            },

            {
              title: 'cardview_img',
              required: 'O',
              type: 'String(200)',
              desc: '카드뷰 이미지',
              indent: 1,
            },
            {
              title: 'mobile_img',
              required: 'O',
              type: 'String(200)',
              desc: '모바일 이미지',
              indent: 1,
            },
            {
              title: 'mobile_detail_img',
              required: 'O',
              type: 'String(200)',
              desc: '모바일 상세 이미지',
              indent: 1,
            },

            {
              title: 'list_img',
              required: 'O',
              type: 'String(200)',
              desc: '리스트 이미지',
              indent: 1,
            },
            {
              title: 'width_img',
              required: 'O',
              type: 'String(200)',
              desc: '가로 이미지',
              indent: 1,
            },
            {
              title: 'exists_prod_img',
              required: 'O',
              type: 'String(200)',
              desc: '실제제품 이미지',
              indent: 1,
            },
            {
              title: 'expand_img1',
              required: 'O',
              type: 'String(200)',
              desc: '확대 이미지1',
              indent: 1,
            },
            {
              title: 'expand_img2',
              required: 'O',
              type: 'String(200)',
              desc: '확대 이미지2',
              indent: 1,
            },
            {
              title: 'thumbnail_img',
              required: 'O',
              type: 'String(200)',
              desc: '썸네일 이미지',
              indent: 1,
            },
            {
              title: 'worklog_job_id',
              required: 'O',
              type: 'Number',
              desc: '작업번호',
              indent: 1,
            },
            {
              title: 'set_info',
              required: 'O',
              type: 'Object',
              desc: (
                <div>
                  쇼핑몰 정보(세트정보)
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_noti_data1',
              required: 'O',
              type: 'Object',
              desc: (
                <div>
                  상품 정보 고시1
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code></code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_noti_data2',
              required: 'O',
              type: 'Object',
              desc: (
                <div>
                  상품 정보 고시2(상품고시1 실패시 사용)
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'prod_noti_data3',
              required: 'O',
              type: 'Object',
              desc: (
                <div>
                  상품 정보 고시3(상품고시2 실패시 사용)
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'template_info',
              required: 'O',
              type: 'Object',
              desc: (
                <div>
                  템플릿 정보
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'nextday_ship_ol_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '내일배송 상품 여부',
              indent: 1,
            },
            {
              title: 'nextday_ship_avail_yn',
              required: 'O',
              type: 'Number(1)',
              desc: '내일배송 상품 여부',
              indent: 1,
            },
            {
              title: 'prod_cate',
              required: 'O',
              type: 'Array',
              desc: '수집상품 카테고리',
              indent: 1,
            },
            {
              title: 'import_cate_no',
              required: '',
              type: 'Number',
              desc: '수집상품 카테고리 번호',
              indent: 2,
            },
            {
              title: 'shop_cate_name',
              required: '',
              type: 'String(100)',
              desc: '쇼핑몰 카테고리명',
              indent: 2,
            },
            {
              title: 'shop_cate_cd',
              required: '',
              type: 'String(100)',
              desc: '쇼핑몰 카테고리 코드',
              indent: 2,
            },
            {
              title: 'shop_cate_show_yn',
              required: '',
              type: 'Number(1)',
              desc: '쇼핑몰 전시 카테고리 여부',
              indent: 2,
            },
            {
              title: 'map_yn',
              required: '',
              type: 'Number(1)',
              desc: '매핑 여부',
              indent: 2,
            },
            {
              title: 'sol_cate_no',
              required: '',
              type: 'Number',
              desc: '솔루션 카테고리 번호',
              indent: 2,
            },
            {
              title: 'sol_cate_shop_add_info',
              required: '',
              type: 'Object',
              desc: (
                <div>
                  솔루션쇼핑몰 카테고리 추가 정보
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>{}</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'prod_cert',
              required: 'O',
              type: 'Array',
              desc: '수집상품 인증정보',
              indent: 1,
            },
            {
              title: 'import_ol_shop_cert_seq',
              required: '',
              type: 'Number(1)',
              desc: '가져오기 온라인상품 인증 순번',
              indent: 2,
            },
            {
              title: 'wdate',
              required: '',
              type: 'String(19)',
              desc: (
                <div>
                  수집 상품 인증 정보 쓰기시각
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'cert_exc_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  인증 면제 유형
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>kc_01: 안전기준준수대상</li>
                    <li>kc_02: 구매대행</li>
                    <li>kc_03: 병행수입</li>
                    <li>kc_04: 사용안함</li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_01</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_02</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_03</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>kc_04</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'shop_cert_no',
              required: '',
              type: 'String(7)',
              desc: '인증 코드',
              indent: 2,
            },
            {
              title: 'cert_cd',
              required: '',
              type: 'String(7)',
              desc: '인증 코드',
              indent: 2,
            },
            {
              title: 'cert_ministry',
              required: '',
              type: 'String(30)',
              desc: '인증기관',
              indent: 2,
            },
            {
              title: 'cert_no',
              required: '',
              type: 'String(40)',
              desc: '인증 번호',
              indent: 2,
            },
            {
              title: 'cert_model',
              required: '',
              type: 'String(70)',
              desc: '인증 모델',
              indent: 2,
            },
            {
              title: 'cert_cname',
              required: '',
              type: 'String(30)',
              desc: '인증 업체명',
              indent: 2,
            },
            {
              title: 'cert_date',
              required: '',
              type: 'String(19)',
              desc: (
                <div>
                  인증 일자
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'cert_expire_date',
              required: '',
              type: 'String(19)',
              desc: (
                <div>
                  인증 만료일
                  <div className='description'>(YYYY-MM-DD HH:mm:ss)</div>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'prod_opt',
              required: 'O',
              type: 'Array',
              desc: '수집상품 옵션정보',
              indent: 1,
            },
            {
              title: 'import_prod_no',
              required: '',
              type: 'Number',
              desc: '수집 상품의 상품 번호',
              indent: 2,
            },
            {
              title: 'deal_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  옵션타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>기초상품</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>추가구매</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'opt_status',
              required: '',
              type: 'String',
              desc: (
                <div>
                  옵션상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정상</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>품절</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'opt_name1',
              required: '',
              type: 'String(80)',
              desc: (
                <div>
                  옵션값1
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'opt_name2',
              required: '',
              type: 'String(80)',
              desc: (
                <div>
                  옵션값2
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'opt_name3',
              required: '',
              type: 'String(80)',
              desc: (
                <div>
                  옵션값3
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'opt_name1_cd',
              required: '',
              type: 'String(10)',
              desc: '옵션구분코드 1',
              indent: 2,
            },
            {
              title: 'opt_name2_cd',
              required: '',
              type: 'String(10)',
              desc: '옵션구분코드 2',
              indent: 2,
            },
            {
              title: 'opt_name3_cd',
              required: '',
              type: 'String(10)',
              desc: '옵션구분코드 3',
              indent: 2,
            },
            {
              title: 'add_item_name',
              required: '',
              type: 'String(50)',
              desc: '추구구매옵션명',
              indent: 2,
            },
            {
              title: 'add_item_opt_name',
              required: '',
              type: 'String(50)',
              desc: '추구구매옵션값',
              indent: 2,
            },
            {
              title: 'shop_sale_name',
              required: '',
              type: 'String(200)',
              desc: '쇼핑몰상품명',
              indent: 2,
            },
            {
              title: 'prod_img',
              required: '',
              type: 'String(200)',
              desc: '상품 이미지',
              indent: 2,
            },
            {
              title: 'madein_etc',
              required: '',
              type: 'String(30)',
              desc: (
                <div>
                  기타원산지
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'multi_madein_yn',
              required: '',
              type: 'Number(1)',
              desc: (
                <div>
                  복수원산지여부
                  <div className='description'>
                    (1: 복수원산지, 0: 복수원산지 아님)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'maker',
              required: '',
              type: 'String(40)',
              desc: '제조사',
              indent: 2,
            },
            {
              title: 'brand',
              required: '',
              type: 'String(40)',
              desc: '브랜드',
              indent: 2,
            },
            {
              title: 'model',
              required: '',
              type: 'String(40)',
              desc: '모델명',
              indent: 2,
            },
            {
              title: 'opt_price',
              required: '',
              type: 'Number',
              desc: '옵션 판매가',
              indent: 2,
            },
            {
              title: 'sale_opt_cnt_limit',
              required: '',
              type: 'Number',
              desc: '옵션 판매최대수량',
              indent: 2,
            },
            {
              title: 'set_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  옵션에 매칭된 세트상품 고유 번호
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <br />
                  <ul className='style' style={{ color: 'gray' }}>
                    <li>세트 상품 번호는 옵션 순번에 따라 출력됩니다.</li>
                    <li>
                      세트 상품 번호가 존재할 경우 sale_opt_seq로 옵션을
                      구분해주세요.
                    </li>
                  </ul>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'prod_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  옵션에 매칭된 sku 상품 고유번호
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>null</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'shop_sku_cd',
              required: '',
              type: 'String(40)',
              desc: '쇼핑몰에서 수집된 sku_cd',
              indent: 2,
            },
            {
              title: 'save_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  저장방식
                  <div className='description'>
                    (상품정보:prod,직접입력:direct)
                  </div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>prod</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'sku_cd',
              required: '',
              type: 'String(40)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품 sku코드
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'prod_name',
              required: '',
              type: 'String(80)',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품명
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'opt_sale_cd',
              required: '',
              type: 'String(40)',
              desc: '옵션 상품 코드',
              indent: 2,
            },
            {
              title: 'contents',
              required: '',
              type: 'String',
              desc: '상품 상세설명',
              indent: 2,
            },
            {
              title: 'tax_type',
              required: '',
              type: 'String',
              desc: (
                <div>
                  과세여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>과세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>면세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>영세</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>비과세</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'madein_no',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  원산지번호
                  <div className='description'>
                    (원산지 조회 api 호출하여 확인)
                  </div>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'sale_price',
              required: '',
              type: 'Number(10)',
              desc: '상품 금액',
              indent: 2,
            },
            {
              title: 'stock_cnt_real',
              required: '',
              type: 'Number',
              desc: '실재고',
              indent: 2,
            },
            {
              title: 'depot_no',
              required: '',
              type: 'Number',
              desc: '배송처 코드',
              indent: 2,
            },
            {
              title: 'pack_unit',
              required: '',
              type: 'Number',
              desc: (
                <div>
                  옵션에 매칭된 SKU상품 출고수량
                  <div className='description'>(재고관리 미사용시 미노출)</div>
                </div>
              ),
              indent: 2,
            },
            {
              title: 'map_yn',
              required: '',
              type: 'Number(1)',
              desc: '매핑 여부',
              indent: 2,
            },
          ],
          response_example: {
            "results": [
              {
                "import_ol_shop_no": 23204583,
                "sol_no": 640,
                "shop_cd": "A001",
                "shop_id": "myshopy3",
                "shop_sale_no": "D380097342",
                "shop_sale_no_sub": "4743703363",
                "sale_no": null,
                "c_sale_cd": "",
                "shop_c_sale_cd": "790606",
                "std_ol_yn": 0,
                "group_info": "",
                "wdate": "2023-05-30 19:37:42",
                "shop_sale_name": "야구 모자",
                "shop_promotion_name": "",
                "shop_cate_no": null,
                "shop_cate_no_add_info": null,
                "sale_stime": "0001-01-01 00:00:00",
                "sale_etime": "0001-01-01 00:00:00",
                "gift_name": "",
                "opt_sort1": "모자",
                "opt_sort2": "",
                "opt_sort3": "",
                "opt_sort1_desc": "블랙,화이트",
                "opt_sort2_desc": "",
                "opt_sort3_desc": "",
                "opt_sort1_cd": "",
                "opt_sort2_cd": "",
                "opt_sort3_cd": null,
                "detail_desc": "멋있는 모자~ \r\n\r\n\r\n\r\nhttps://img.freepik.com/free-psd/isolated-black-and-white-front-caps_125540-2539.jpg?q=10&h=200",
                "keyword": "",
                "tax_type": "과세",
                "madein_no": null,
                "madein_etc": "국내=서울=강서구",
                "multi_madein_yn": 0,
                "model": "",
                "brand": "",
                "maker": "",
                "barcode": null,
                "sale_price": 7500,
                "supply_price": 0,
                "cost_price": 0,
                "street_price": 0,
                "ship_price_type": "선결제",
                "ship_price": 2500,
                "sale_cnt_limit": 99999,
                "sale_img1": "http://image.auction.co.kr/itemimage/32/75/50/327550b996.jpg",
                "sale_img2": "",
                "sale_img3": "",
                "sale_img4": "",
                "sale_img5": "",
                "sale_img6": "",
                "sale_img7": "",
                "sale_img8": "",
                "sale_img9": "",
                "sale_img10": "",
                "sale_img11": "",
                "opt_type": "독립형",
                "use_addopt_yn": 0,
                "input_opt_name": "",
                "cert_type": "인증대상아님",
                "isbn": 0,
                "prod_noti_data": {
                  "infoCode": "FashionItems2023",
                  "infoName": "패션잡화(모자/벨트/액세서리 등)",
                  "infoDetail": {
                    "size": "66",
                    "type": "모자/캡",
                    "caution": "없음",
                    "made_in": "한국",
                    "material": "천",
                    "warranty": "?",
                    "manufacturer": "집",
                    "customer_service": "010-9"
                  }
                },
                "made_date": "0001-01-01",
                "expire_date": "0001-01-01",
                "map_yn": 0,
                "foreign_ship_avail_yn": 0,
                "adult_yn": 0,
                "prod_weight": null,
                "detail_desc_ad": null,
                "detail_desc_add": null,
                "detail_desc_eng": null,
                "detail_desc_chn": null,
                "detail_desc_jpn": null,
                "cardview_img": "",
                "mobile_img": "",
                "mobile_detail_img": "",
                "list_img": "",
                "width_img": "",
                "exists_prod_img": "",
                "expand_img1": "",
                "expand_img2": "",
                "thumbnail_img": "",
                "worklog_job_id": null,
                "set_info": null,
                "prod_noti_data1": null,
                "prod_noti_data2": null,
                "prod_noti_data3": null,
                "template_info": null,
                "nextday_ship_ol_yn": null,
                "nextday_ship_avail_yn": null,
                "prod_cate": [
                  {
                    "import_cate_no": 23549233,
                    "shop_cate_name": "가방/잡화 > 모자 > 스냅백/힙합모자",
                    "shop_cate_cd": "16_59_14_00",
                    "shop_cate_show_yn": 0,
                    "map_yn": 0,
                    "sol_cate_no": null,
                    "sol_cate_shop_add_info": "{\"catecode_esm\":\"00110003000100000000\"}"
                  }
                ],
                "prod_cert": [
                  {
                    "import_ol_shop_cert_seq": 1,
                    "wdate": "2023-06-14 14:30:11",
                    "cert_exc_type": "kc_01",
                    "shop_cert_no": "cert_14",
                    "cert_cd": "cert_24",
                    "cert_ministry": "한국식품안전관리인증원장",
                    "cert_no": "2021-3-1596",
                    "cert_model": null,
                    "cert_cname": null,
                    "cert_date": null,
                    "cert_expire_date": null
                  }
                ],
                "prod_opt": [
                  {
                    "import_prod_no": 117388690,
                    "deal_type": "기초상품",
                    "opt_status": "정상",
                    "opt_name1": "모자",
                    "opt_name2": "블랙",
                    "opt_name3": "",
                    "opt_name1_cd": "0",
                    "opt_name2_cd": "0",
                    "opt_name3_cd": null,
                    "add_item_name": "",
                    "add_item_opt_name": "",
                    "shop_sale_name": "야구 모자",
                    "prod_img": "http://image.auction.co.kr/itemimage/32/75/50/327550b996.jpg",
                    "madein_etc": "국내=서울=강서구",
                    "multi_madein_yn": 0,
                    "maker": "",
                    "brand": "",
                    "model": "",
                    "opt_price": 0,
                    "sale_opt_cnt_limit": 99999,
                    "set_no": null,
                    "prod_no": null,
                    "shop_sku_cd": null,
                    "save_type": "prod",
                    "sku_cd": null,
                    "prod_name": null,
                    "opt_sale_cd": null,
                    "contents": null,
                    "tax_type": "과세",
                    "madein_no": null,
                    "sale_price": null,
                    "stock_cnt_real": null,
                    "depot_no": null,
                    "pack_unit": 1,
                    "map_yn": 0
                  },
                  {
                    "import_prod_no": 117388691,
                    "deal_type": "기초상품",
                    "opt_status": "정상",
                    "opt_name1": "모자",
                    "opt_name2": "화이트",
                    "opt_name3": "",
                    "opt_name1_cd": "0",
                    "opt_name2_cd": "0",
                    "opt_name3_cd": null,
                    "add_item_name": "",
                    "add_item_opt_name": "",
                    "shop_sale_name": "야구 모자",
                    "prod_img": "http://image.auction.co.kr/itemimage/32/75/50/327550b996.jpg",
                    "madein_etc": "국내=서울=강서구",
                    "multi_madein_yn": 0,
                    "maker": "",
                    "brand": "",
                    "model": "",
                    "opt_price": 0,
                    "sale_opt_cnt_limit": 99999,
                    "set_no": null,
                    "prod_no": null,
                    "shop_sku_cd": null,
                    "save_type": "prod",
                    "sku_cd": null,
                    "prod_name": null,
                    "opt_sale_cd": null,
                    "contents": null,
                    "tax_type": "과세",
                    "madein_no": null,
                    "sale_price": null,
                    "stock_cnt_real": null,
                    "depot_no": null,
                    "pack_unit": 1,
                    "map_yn": 0
                  }
                ]
              }
            ]
          },
        },
      ],
    },
  ],
});

export default data;
