import { useState, useContext, useRef } from 'react';
import useFormValue from '../../../hooks/useFormValue';
import classes from './InquiryModal.module.css';
import Modal from '../Modal';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import AuthContext from '../../../store/auth-context';
import ValidationInput from '../../UI/ValidationInput';
import { simpleSubmitHandler } from '../../../util/HelperFunctions';
import Swal from 'sweetalert2';
import ApiCallEditor from '../../UI/ApiCallEditor';
import useEscModalClose from '../../../hooks/useEscModalClose';

/**
 * 1:1문의 작성 모달 컴포넌트
 */
const InquiryModal = (props) => {
  const authCtx = useContext(AuthContext);
  const fileInputRef = useRef(null);
  useEscModalClose(props);
  const [isLoading, setIsLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [formValues, handleInputUpdate, formValidity] = useFormValue({
    name: { value: authCtx.userInfo.username, regex: /.+/, label: '문의자명', type: 'text' },
    email: { value: authCtx.userInfo.email, regex: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, placeholder: '이메일 형식으로 입력하세요', label: '문의자 이메일', type: 'text' },
    tel: { value: authCtx.userInfo.tel, regex: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3,4}[-\s.]?[0-9]{4,6}$/, placeholder: '전화번호 형식으로 입력하세요', label: '직통번호', type: 'text', optional: true },
    htel: { value: authCtx.userInfo.htel, regex: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3,4}[-\s.]?[0-9]{4,6}$/, placeholder: '전화번호 형식으로 입력하세요', label: '휴대폰번호', type: 'text' },
    type: { value: '일반' },
    title: { value: '', regex: /.+/, label: '제목', type: 'text' },
    email_yn: { value: authCtx.userInfo.email_yn },
    sms_yn: { value: authCtx.userInfo.sms_yn },
    testAgreeYN: { value: true },
    content: { value: '' }
  });
  const [apiRequestInfo, setApiRequestInfo] = useState({
    url: '',
    header: '',
    body: '',
    response: ''
  });
  const [files, setFiles] = useState([]);

  async function handleSubmit() {
    const body = new FormData();
    Object.keys(formValues).forEach(key => {
      if (formValues.type.value === '오류' && key === 'content') {
        const content = `${formValues.content.value}\n\nURL: ${apiRequestInfo.url}\n\nBody: ${apiRequestInfo.body}\n\nResponses: ${apiRequestInfo.response}`.replaceAll(' ', '&nbsp;').replaceAll('\n', '<br>');
        body.append('content', content);
      } else if (typeof formValues[key].value === 'boolean') {
        body.append(key, String(+formValues[key].value));
      } else {
        body.append(key, formValues[key].value);
      }
    });
    files.forEach(file => body.append('file', file, file.name));
    const requestOption = {
      method: 'POST',
      body,
      headers: {
        Authorization: authCtx.token
      }
    };
    simpleSubmitHandler(setIsLoading, '/center/inquiries/add', requestOption, null, () => Swal.fire({
      icon: 'success',
      text: '1:1 문의 전송에 성공하였습니다.'
    }).then(() => props.closeModal()));
  };

  function validationInputGenerator(key) {
    return <ValidationInput
      {...formValues[key]}
      onChange={e => handleInputUpdate(key, e.target.value)}
    />
  }

  return (
    <Modal>
      <div className={classes.container}>
        <button onClick={props.closeModal} className={classes.close}>
          <FontAwesomeIcon icon={faX} />
        </button>
        <h2>문의 등록</h2>
        <div className={classes.form}>
          {validationInputGenerator('name')}
          <div className={classes.flex}>
            {validationInputGenerator('email')}
            <input type='checkbox' id='email-noti' checked={formValues.email_yn.value} onChange={e => handleInputUpdate('email_yn', e.target.checked)} />
            <label htmlFor='email-noti'>
              답변 알림
            </label>
          </div>
          {validationInputGenerator('tel')}
          <div className={classes.flex}>
            {validationInputGenerator('htel')}
            <input type='checkbox' id='email-noti' checked={formValues.sms_yn.value} onChange={e => handleInputUpdate('sms_yn', e.target.checked)} value='' />
            <label htmlFor='email-noti'>
              답변 알림
            </label>
          </div>
          <div className={`${classes['form-group']}`}>
            <FormControl fullWidth>
              <FormLabel id="demo-radio-buttons-group-label">테스트 동의여부</FormLabel>
              <RadioGroup
                onChange={e => handleInputUpdate('testAgreeYN', e.target.value === 'true')}
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                value={formValues.testAgreeYN.value}
                row
              >
                <FormControlLabel value={true} control={<Radio />} label="동의함" />
                <FormControlLabel value={false} control={<Radio />} label="동의하지 않음" />
              </RadioGroup>
            </FormControl>
          </div>
          
          <div className={`${classes['form-group']} ${classes.type}`}>
            <label htmlFor='input-type'>
              유형 선택
            </label>
            <select onChange={e => handleInputUpdate('type', e.target.value)} id='input-type'>
              <option value="일반">일반문의</option>
              <option value="오류">오류문의</option>
            </select>
          </div>
          {validationInputGenerator('title')}
          <div className={`${classes['form-group']} ${classes.mb}`} >
            <label htmlFor='input-content'>
              문의 상세
            </label>
            <textarea
              onChange={e => handleInputUpdate('content', e.target.value)}
              id='input-content'
              value={formValues.content.value}
            />
          </div>
          <div className={`${classes['form-group']} ${classes.files}`}>
            <div className={classes.textLabel}>첨부파일</div>
            <label htmlFor='input-file'>
              파일업로드 
              <input ref={fileInputRef} onChange={e => { setFiles([ ...files, ...e.target.files].slice(0, 5)); e.target.files = null; }} type='file' id='input-file' multiple />
            </label>
            <span style={{ fontSize: '0.875rem', marginTop: '3px' }}>최대 5개까지 업로드 가능합니다</span>
          </div>
          {
            files.length ? (
              <div className={classes.fileList}>
                {
                  files.map((file, i) => (
                    <div key={i} className={classes.file}>
                      <span className={classes.filename}>{file.name}</span>
                      {`(${Math.floor(file.size / 1024)}K)`}
                      <button onClick={() => { setFiles([...files.slice(0, i), ...files.slice(i + 1, files.length)]) }}>
                        <FontAwesomeIcon icon={faX} />
                      </button>
                    </div>)
                  )
                }
              </div>) : <span style={{ color: '#aaa', marginLeft: '150px' }}>첨부파일이 없습니다.</span>
          }
          {
            (formValues.type.value) === '오류' &&
            <div className={`${classes['form-group']} ${classes['request-info']}`}>
              <h6>API 호출 정보</h6>
              <input onChange={e => setApiRequestInfo(old_info => ({ ...old_info, url: e.target.value }))} value={apiRequestInfo.url} className='focus-highlight' type='text' placeholder='https://openapi.playauto.io/api/...' />
              <div className={classes['tab-group']}>
                <div onClick={() => setTabIndex(0)} className={tabIndex === 0 ? classes.active : ''}>Header</div>
                <div onClick={() => setTabIndex(1)} className={tabIndex === 1 ? classes.active : ''}>Body</div>
                <div onClick={() => setTabIndex(2)} className={tabIndex === 2 ? classes.active : ''}>Response</div>
              </div>
              {
                ([0, 1, 2].includes(tabIndex)) && (() => {
                  let onChange, value;
                  switch (tabIndex) {
                    case 0:
                      value = apiRequestInfo.header;
                      onChange = value => setApiRequestInfo(old_state => ({ ...old_state, header: value }));
                      break;
                    case 1:
                      value = apiRequestInfo.body;
                      onChange = value => setApiRequestInfo(old_state => ({ ...old_state, body: value }));
                      break;
                    case 2:
                      value = apiRequestInfo.response;
                      onChange = value => setApiRequestInfo(old_state => ({ ...old_state, response: value }));
                      break;
                    default:
                      onChange = null;
                      value = null;
                      break;
                  }
                  return (
                    <div className={classes.editor}>
                      <ApiCallEditor
                        onChange={onChange}
                        value={value}
                      />
                    </div>
                  )
                })()
              }
            </div>
          }
        </div>
        <div className={classes.btnWrap}>
          <button onClick={handleSubmit} className={classes.submit} disabled={!formValidity}>제출</button>
        </div>
        {isLoading && <p>잠시만 기다려주세요...</p>}
      </div>
    </Modal>
  );
};

export default InquiryModal;