import { useContext, useState } from 'react';
import classes from './InquiryDetailPage.module.css';
import AuthContext from '../../../store/auth-context';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import useFetchData from '../../../hooks/useFetchData';
import moment from 'moment';

/**
 * 1:1문의 목록 리스팅 페이지 컴포넌트
 */
const InquiryDetailPage = () => {
  const authCtx = useContext(AuthContext);
  const [inqData, setInqDetail] = useState({
    now: {},
    prev: null,
    next: null
  });
  const { inq_no } = useParams()

  const option = {
    method: 'GET',
    headers: {
      Authorization: authCtx.token
    }
  };

  useFetchData(`/center/inquiries/detail/${inq_no}`, option, res_json => setInqDetail(res_json), authCtx, inq_no);

  return (
    <>
      <div className={classes.container}>
        <h2 className={classes.heading}>1:1문의</h2>
        <div className={classes.detailWrap}>
          <div className={classes['sub-heading']}>
            <p>{inq_no}</p>
            <p>{inqData.now.board_status}</p>
            <p>{inqData.now.board_type}</p>
            <p>{inqData.now.title}</p>
            <p>{inqData.now.wdate}</p>
          </div>
        </div>
        <div className={classes.inquiryDesc}>
          <p dangerouslySetInnerHTML={{ __html: inqData.now.content?.replace(/\n/g, '<br />') }}></p>
        </div>
        <div className={classes.inquiryAnswer}>
          <p dangerouslySetInnerHTML={{ __html: inqData.now.reply_content?.replace(/\n/g, '<br />') }}></p>
        </div>
        <div className={classes.btnList}>
          <span><Link to={`/inquiry`}>목록</Link></span>
        </div>

        <div className={classes.btnLDetaiList}>
          <div>
            <p>이전문의</p>
            <p>{inqData.prev?.board_type || '-'}</p>
            <p>
              {inqData.prev ?
                <Link to={`/inquiry/detail/${inqData.prev?.cs_no}`}>{inqData.prev?.title}</Link> :
                <span>게시물이 없습니다. 목록 보기를 이용해 주세요.</span>}
            </p>
            <p>{(inqData.prev && moment(inqData.prev.wdate).format('YYYY-MM-DD')) || '-'}</p>
          </div>
          <div>
            <p>다음문의</p>
            <p>{inqData.next?.board_type || '-'}</p>
            <p>
              {inqData.next ?
                <Link to={`/inquiry/detail/${inqData.next?.cs_no}`}>{inqData.next?.title}</Link> :
                <span>게시물이 없습니다. 목록 보기를 이용해 주세요.</span>}
            </p>
            <p>{(inqData.next && moment(inqData.next.wdate).format('YYYY-MM-DD')) || '-'}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default InquiryDetailPage;