import { Route, Routes, Navigate } from 'react-router-dom';
import MainPage from './components/pages/MainPage/MainPage';
import OverviewPage from './components/pages/Guide/OverviewPage';
import ErrorviewPage from './components/pages/ErrorviewPage/ErrorviewPage';
import ReleasePage from './components/pages/ReleasePage/ReleasePage';
import DetailLoadingPage from './components/pages/DescriptionPageTemplate/DetailLoadingPage';
import InquiryListPage from './components/pages/InquiryPage/InquiryListPage';
import LoginPage from './components/pages/LoginPage/LoginPage';
import MyPage from './components/pages/MyPage/MyPage';
import RegisterPage from './components/pages/RegisterPage/RegisterPage';
import NoticeListPage from './components/pages/NoticePage/NoticeList';
import NoticeDetailPage from './components/pages/NoticePage/NoticeDetail';
import InquiryDetailPage from './components/pages/InquiryPage/InquiryDetailPage';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import styled from 'styled-components';
import InstructionPage from './components/pages/Guide/InstructionPage';
import VersionPage from './components/pages/Guide/VersionPage';
import LimitPage from './components/pages/Guide/LimitPage';
import ApplyPage from './components/pages/ApplyPage/ApplyPage';
import ApplyCompletePage from './components/pages/ApplyPage/ApplyCompletePage';
import ApplyStatusPage from './components/pages/ApplyPage/ApplyStatusPage';
import TermsAgreePage from 'components/pages/ApplyPage/TermsAgreePage';

function App() {
  return (
    <>
      <Header />
      <ContentWrap>
        <Routes>
          {/* 계정 관련 */}
          <Route path='/' exact element={<MainPage />} />
          <Route path='/login' exact element={<LoginPage />} />
          <Route path='/register' exact element={<RegisterPage />} />
          <Route path='/mypage' exact element={<MyPage />} />

          {/* 문의 */}
          <Route path='/inquiry' exact element={<InquiryListPage />} />
          <Route path='/inquiry/detail/:inq_no' exact element={<InquiryDetailPage />} />

          {/* 공지 */}
          <Route path='/notice' exact element={<NoticeListPage />} />
          <Route path='/notice/detail/:noti_no' exact element={<NoticeDetailPage />} />
          
          {/* API 문서 */}
          <Route path='/doc/*' exact element={<DetailLoadingPage />} />

          {/* 가이드 */}
          <Route path='/overview' exact element={<OverviewPage />} />
          <Route path='/instruction' exact element={<InstructionPage />} />
          <Route path='/overview' exact element={<OverviewPage />} />
          <Route path='/version' exact element={<VersionPage />} />
          <Route path='/limit' exact element={<LimitPage />} />

          {/* 사용신청 */}
          <Route path='/termsAgree' exact element={<TermsAgreePage />} />
          <Route path='/apply' exact element={<ApplyPage />} />
          <Route path='/applyComplete' exact element={<ApplyCompletePage />} />
          <Route path='/applyStatus' exact element={<ApplyStatusPage />} />

          <Route path='/err/*' exact element={<ErrorviewPage />} />
          <Route path='/release' exact element={<ReleasePage/>} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      </ContentWrap>
      <Footer />
    </>
  );
}

const ContentWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  min-height: calc(100vh - 64px - 120px);
`;

export default App;
