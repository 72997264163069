import { default_header, default_header_example } from './defaultData';

const data = (userInfo) => ({
  title: '정산',
  unrecognized_aff_no: [1051],
  children: [
    {
      title: '정산내역 조회',
      url: '/doc/정산내역 조회',
      data: [
        {
          version: 'v1.0',
          url: '/calculate/list',
          method: 'POST',
          desc: (
            <ul className='style'>
              <li>정산내역을 조회합니다.</li>
              <li>쇼핑몰 정산 페이지에서 수집된 정산관련 정보를 보여줍니다.</li>
              <li>
                쇼핑몰에서 취합된 데이터이므로 PLAYAUTO 2.0 주문과리에 없는
                데이터가 노출될 수 있습니다.
              </li>
            </ul>
          ),
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'start',
              required: 'N',
              type: 'Number',
              desc: (
                <div>
                  조회할 시작 카운트
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'limit',
              required: 'N',
              type: 'Number',
              desc: (
                <div>
                  조회할 정산내역 갯수
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>100</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'orderbyType',
              required: 'N',
              type: 'String',
              desc: (
                <div>
                  정렬타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>DESC</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>ASC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>DESC</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'orderbyColumn',
              required: 'N',
              type: 'String',
              desc: (
                <div>
                  정렬대상
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>sett_com_date</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>sett_com_date</code>
                          </strong>
                        </td>
                        <td>
                          <strong>정산완료일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>sales</code>
                          </strong>
                        </td>
                        <td>
                          <strong>판매가</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>sett_price</code>
                          </strong>
                        </td>
                        <td>
                          <strong>정산금액</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'search_key',
              required: 'N',
              type: 'String',
              desc: (
                <div>
                  검색 키<br />
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                      <td>
                        <strong>
                          <code>shop_ord_no</code>
                        </strong>
                      </td>
                      <td>
                        <strong>주문번호</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          <code>shop_sale_name</code>
                        </strong>
                      </td>
                      <td>
                        <strong>온라인상품명</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          <code>order_name</code>
                        </strong>
                      </td>
                      <td>
                        <strong>주문자명</strong>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <br />
                  <strong>
                    <ul className='style'>
                      <li>
                        <code>값을 넣지않는경우 전체검색됩니다.</code>
                      </li>
                    </ul>
                  </strong>
                </div>
              ),
            },
            {
              title: 'search_word',
              required: 'N',
              type: 'String',
              desc: '검색어',
            },
            {
              title: 'search_type',
              required: 'N',
              type: 'String',
              desc: (
                <div>
                  검색타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                      <td>
                        <strong>
                          <code>partial</code>
                        </strong>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>exact</code>
                          </strong>
                        </td>
                        <td>
                          <strong>완전일치</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>partial</code>
                          </strong>
                        </td>
                        <td>
                          <strong>부분일치</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sdate',
              required: 'Y',
              type: 'String',
              desc: (
                <div>
                  검색시작일
                  <div className='description'>(YYYY-MM-DD)</div>
                </div>
              ),
            },
            {
              title: 'edate',
              required: 'Y',
              type: 'String',
              desc: (
                <div>
                  검색종료일
                  <div className='description'>(YYYY-MM-DD)</div>
                </div>
              ),
            },
            {
              title: 'shop_cd',
              required: 'N',
              type: 'String',
              desc: (
                <div>
                  쇼핑몰코드
                  <div className='description'>
                    (옥션:A001, 지마켓:A006, 11번가:A112, 인터파크:A027...)
                  </div>
                </div>
              ),
            },
            {
              title: 'shop_id',
              required: 'N',
              type: 'String',
              desc: '쇼핑몰아이디',
            },
            {
              title: 'sett_status',
              required: 'N',
              type: 'String',
              desc: (
                <div>
                  정산상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                      <td>
                        <strong>
                          <code>정산완료</code>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          <code>미정산</code>
                        </strong>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sett_type',
              required: 'N',
              type: 'String',
              desc: (
                <div>
                  정산구분
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                      <td>
                        <strong>
                          <code>상품대금</code>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          <code>배송비</code>
                        </strong>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'masking_yn',
              required: 'N',
              type: 'Boolean',
              desc: (
                <div>
                  개인정보 마스킹 처리 여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                      <td>
                        <strong>
                          <code>false</code>
                        </strong>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
          ],
          body_example: {
            start: 0,
            limit: 100,
            orderbyColumn: 'sett_com_date',
            orderbyType: 'desc',
            search_key: 'order_name',
            search_type: 'partial',
            search_word: '김바보',
            sdate: '2001-01-01',
            edate: '2021-01-21',
          },
          response: [
            {
              title: 'results',
              required: 'Y',
              type: 'Array',
              desc: '결과',
            },
            {
              title: 'sett_uniq',
              required: 'Y',
              type: 'Number',
              desc: '정산고유번호',
              indent: 1,
            },
            {
              title: 'wdate',
              required: 'Y',
              type: 'datetime',
              desc: '등록일',
              indent: 1,
            },
            {
              title: 'mdate',
              required: 'Y',
              type: 'datetime',
              desc: '수정일',
              indent: 1,
            },
            {
              title: 'shop_cd',
              required: 'Y',
              type: 'String',
              desc: '쇼핑몰코드',
              indent: 1,
            },
            {
              title: 'shop_id',
              required: 'Y',
              type: 'String',
              desc: '쇼핑몰아이디',
              indent: 1,
            },
            {
              title: 'shop_ord_no',
              required: 'Y',
              type: 'String',
              desc: '주문번호',
              indent: 1,
            },
            {
              title: 'sett_type',
              required: 'Y',
              type: 'String',
              desc: (
                <div>
                  정산타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                      <td>
                        <strong>
                          <code>상품대금</code>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          <code>배송비</code>
                        </strong>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_sale_name',
              required: 'Y',
              type: 'String',
              desc: '상품명',
              indent: 1,
            },
            {
              title: 'order_name',
              required: 'Y',
              type: 'String',
              desc: '주문자명',
              indent: 1,
            },
            {
              title: 'sett_com_date',
              required: 'Y',
              type: 'String',
              desc: '정산완료일',
              indent: 1,
            },
            {
              title: 'sales',
              required: 'Y',
              type: 'Number',
              desc: '판매가(부가세포함)',
              indent: 1,
            },
            {
              title: 'deduct_price',
              required: 'Y',
              type: 'Number',
              desc: '공제금액',
              indent: 1,
            },
            {
              title: 'sett_price',
              required: 'Y',
              type: 'Number',
              desc: '정산금액',
              indent: 1,
            },
            {
              title: 'sett_status',
              required: 'Y',
              type: 'String',
              desc: (
                <div>
                  정산완료여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정산완료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>미정산</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'recordsTotal',
              required: 'Y',
              type: 'Number',
              desc: '총 카운트',
            },
          ],
          response_example: {
            results: [
              {
                sett_uniq: '7380449818631130232',
                wdate: '2020-09-09 08:01:38',
                mdate: '2020-09-09 08:01:38',
                shop_cd: 'A112',
                shop_id: 'torimall',
                shop_ord_no: '202008294313129',
                sett_type: '상품대금',
                shop_sale_name: '로얄벤톤 헤세드 뚝배기 내열냄비 대 (베이지)',
                order_name: '김바보',
                sett_com_date: '2020-09-11',
                sales: 28000,
                deduct_price: 4480,
                sett_price: 23520,
                sett_status: '정산완료',
              },
            ],
            recordsTotal: 11956,
          },
        },
      ],
    },
    {
      title: '주문관리 정산 조회',
      url: '/doc/주문관리 정산 조회',
      data: [
        {
          version: 'v1.0',
          url: '/calculate/listOfOrder',
          method: 'POST',
          desc: (
            <ul className='style'>
              <li>주문관리 정산내역을 조회합니다.</li>
              <li>
                PLAYAUTO 2.0에 수집된 주문정보를 바탕으로 정산데이터를
                보여줍니다.
              </li>
            </ul>
          ),
          header: default_header,
          header_example: default_header_example,
          body: [
            {
              title: 'start',
              required: 'N',
              type: 'Number',
              desc: (
                <div>
                  조회할 시작 카운트
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'limit',
              required: 'N',
              type: 'Number',
              desc: (
                <div>
                  조회할 정산내역 갯수
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>100</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'orderbyType',
              required: 'N',
              type: 'String',
              desc: (
                <div>
                  정렬타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>DESC</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>ASC</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>DESC</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'orderbyColumn',
              required: 'N',
              type: 'String',
              desc: (
                <div>
                  정렬대상
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>sett_com_date</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>sett_com_date</code>
                          </strong>
                        </td>
                        <td>
                          <strong>정산완료일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>pay_time</code>
                          </strong>
                        </td>
                        <td>
                          <strong>결제완료일</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>sales</code>
                          </strong>
                        </td>
                        <td>
                          <strong>판매금액</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>sett_price</code>
                          </strong>
                        </td>
                        <td>
                          <strong>정산금액</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>deduct_price</code>
                          </strong>
                        </td>
                        <td>
                          <strong>공제금액</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'search_key',
              required: 'N',
              type: 'String',
              desc: '검색 키',
            },
            {
              title: 'search_word',
              required: 'N',
              type: 'String',
              desc: '검색어',
            },
            {
              title: 'search_type',
              required: 'N',
              type: 'String',
              desc: (
                <div>
                  검색타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>partial</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>exact</code>
                          </strong>
                        </td>
                        <td>
                          <strong>완전일치</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>partial</code>
                          </strong>
                        </td>
                        <td>
                          <strong>부분일치</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'data_type',
              required: 'N',
              type: 'String',
              desc: (
                <div>
                  검색일
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                      <td>
                        <strong>
                          <code>pay_time</code>
                        </strong>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>pay_time</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>sett_com_date</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sdate',
              required: 'Y',
              type: 'String',
              desc: (
                <div>
                  검색시작일
                  <div className='description'>(YYYY-MM-DD)</div>
                </div>
              ),
            },
            {
              title: 'edate',
              required: 'Y',
              type: 'String',
              desc: (
                <div>
                  검색종료일
                  <div className='description'>(YYYY-MM-DD)</div>
                </div>
              ),
            },
            {
              title: 'shop_cd',
              required: 'N',
              type: 'String',
              desc: (
                <div>
                  쇼핑몰코드
                  <div className='description'>
                    (옥션: A001, 지마켓: A006, 11번가: A112, 인터파크: A027...)
                  </div>
                </div>
              ),
            },
            {
              title: 'shop_id',
              required: 'N',
              type: 'String',
              desc: '쇼핑몰아이디',
            },
            {
              title: 'sett_status',
              required: 'N',
              type: 'String',
              desc: (
                <div>
                  정산상태
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>정산완료</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>미정산</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'sett_type',
              required: 'N',
              type: 'String',
              desc: (
                <div>
                  정산구분
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>상품대금</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>배송비</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
            {
              title: 'ord_status',
              required: 'N',
              type: 'String',
              desc: '주문상태',
            },
            {
              title: 'masking_yn',
              required: 'N',
              type: 'Boolean',
              desc: (
                <div>
                  개인정보 마스킹 처리 여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Default value
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>false</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            },
          ],
          body_example: {
            start: 0,
            limit: 100,
            orderbyColumn: 'sett_com_date',
            orderbyType: 'desc',
            search_key: 'shop_ord_no',
            search_type: 'partial',
            search_word: '20210107457993-001-001-001',
            sdate: '2001-01-01',
            edate: '2021-01-21',
          },
          response: [
            {
              title: 'results',
              required: 'Y',
              type: 'Array',
              desc: '결과',
            },
            {
              title: 'sol_no',
              required: 'Y',
              type: 'Number',
              desc: '솔루션번호',
              indent: 1,
            },
            {
              title: 'uniq',
              required: 'Y',
              type: 'Number',
              desc: '주문고유번호',
              indent: 1,
            },
            {
              title: 'ori_uniq',
              required: 'Y',
              type: 'Number',
              desc: (
                <div>
                  원본주문고유번호
                  <div className='description'>(사본인 경우)</div>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'pay_time',
              required: 'Y',
              type: 'datetime',
              desc: '결제완료일',
              indent: 1,
            },
            {
              title: 'shop_name',
              required: 'Y',
              type: 'String',
              desc: '쇼핑몰명',
              indent: 1,
            },
            {
              title: 'shop_cd',
              required: 'Y',
              type: 'String',
              desc: '쇼핑몰코드',
              indent: 1,
            },
            {
              title: 'shop_id',
              required: 'Y',
              type: 'String',
              desc: '쇼핑몰아이디',
              indent: 1,
            },
            {
              title: 'shop_ord_no',
              required: 'Y',
              type: 'String',
              desc: '주문번호',
              indent: 1,
            },
            {
              title: 'ord_status',
              required: 'Y',
              type: 'String',
              desc: '주문상태',
              indent: 1,
            },
            {
              title: 'exchange_ord_yn',
              required: 'Y',
              type: 'Number',
              desc: (
                <div>
                  교환주문여부
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>1</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'shop_sale_name',
              required: 'Y',
              type: 'String',
              desc: '상품명',
              indent: 1,
            },
            {
              title: 'shop_sale_no',
              required: 'Y',
              type: 'String',
              desc: '쇼핑몰 상품번호',
              indent: 1,
            },
            {
              title: 'order_name',
              required: 'Y',
              type: 'String',
              desc: '주문자명',
              indent: 1,
            },
            {
              title: 'c_sale_cd',
              required: 'Y',
              type: 'String',
              desc: '판매자관리코드',
              indent: 1,
            },
            {
              title: 'sett_type',
              required: 'Y',
              type: 'String',
              desc: (
                <div>
                  정산타입
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>상품대금</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>배송비</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'sett_com_date',
              required: 'Y',
              type: 'date',
              desc: '정산완료일',
              indent: 1,
            },
            {
              title: 'sales',
              required: 'Y',
              type: 'Number',
              desc: '판매가',
              indent: 1,
            },
            {
              title: 'sett_price',
              required: 'Y',
              type: 'Number',
              desc: '정산금액',
              indent: 1,
            },
            {
              title: 'deduct_price',
              required: 'Y',
              type: 'Number',
              desc: '공제금액',
              indent: 1,
            },
            {
              title: 'sku_cd',
              required: 'Y',
              type: 'String',
              desc: 'sku코드',
              indent: 1,
            },
            {
              title: 'sett_yn',
              required: 'Y',
              type: 'Number',
              desc: (
                <div>
                  정산완료여부
                  <div className='description'>(1: 정산완료, 0: 미정산)</div>
                  <div className='description' style={{ padding: '12px 0' }}>
                    Allowed values
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>
                            <code>1</code>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            <code>0</code>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
              indent: 1,
            },
            {
              title: 'recordsTotal',
              required: 'Y',
              type: 'Number',
              desc: '총 카운트',
            },
          ],
          response_example: {
            results: [
              {
                sol_no: 1,
                uniq: 1013238227879967200,
                ori_uniq: null,
                pay_time: '2021-01-07 00:00:01',
                shop_name: '홈앤쇼핑',
                shop_cd: 'B614',
                shop_id: 'e109572',
                shop_ord_no: '20210107457993-001-001-001',
                ord_status: '신규주문',
                exchange_ord_yn: 0,
                shop_sale_name:
                  '[30M] 38평형 외풍차단 난방비절약 투명 창틀 바람막이 문풍지 1M*30개',
                shop_sale_no: '20114947 001',
                order_name: '김바보',
                c_sale_cd: '',
                sett_type: null,
                sett_com_date: null,
                sales: 19710,
                sett_price: null,
                deduct_price: null,
                sku_cd: null,
                sett_yn: 0,
              },
            ],
            recordsTotal: 1,
          },
        },
      ],
    },
  ],
});

export default data;
