import { useEffect, useState, useRef, useContext } from 'react';
import AuthContext from '../../../store/auth-context';
import classes from './SearchModal.module.css';
import Modal from '../Modal';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import PageDataList from '../../../store/PageDetailData/PageDetailData';
import { reactElementToPlainText } from '../../../util/HelperFunctions';
import { HashLink } from 'react-router-hash-link';
import escapeStringRegexp from 'escape-string-regexp';
import useEscModalClose from '../../../hooks/useEscModalClose';

/**
 * 1:1문의 작성 모달 컴포넌트
 */
const SearchModal = props => {
  const [searchWord, setSearchWord] = useState('');
  const searchBarEl = useRef(null);
  const authCtx = useContext(AuthContext);
  const memberNo = Number(authCtx.memberNo);
  const affNo = Number(authCtx.userInfo?.aff_no || 0);

  function funcToElement(v) {
    return typeof v === 'function' ? v(affNo, memberNo) : v;
  }

  const searchData_total_list = PageDataList(authCtx.userInfo).map(actions => {
    if (actions.title === '에러코드') {
      return actions.children.map(err_doc => {
        const list = [];
        const path = `${actions.title} > ${err_doc.title}`;
        const url = err_doc.url;
        // 에러종류 title row 추가
        list.push({
          title: err_doc.title
        });
        err_doc.data.forEach(row => {
          // 에러코드 + 상세 row 추가
          list.push({
            title: `code: ${row.code}`,
            desc: reactElementToPlainText(funcToElement(row.desc))
          });
        });
        return list.map(item => ({...item, path, url}));
      });
    } else {
      function formatDesc(row) {
        return `${row.title}(${row.type}): ${reactElementToPlainText(funcToElement(row.desc))}`;
      }
      return actions.children.map(action => action.data.map(action_v => {
        const list = [];
        // 일반 액션 문서인 경우 
        const path = `${actions.title} > ${action.title} > ${action_v.version}`;
        const url = action.url;
        // 액션명 row 추가
        list.push({
          title: action.title,
          url
        });
        // Description row 추가
        list.push({
          title: 'Description',
          desc: reactElementToPlainText(funcToElement(action_v.desc)),
          url
        });
        // 리퀘스트 URL row 추가
        list.push({
          title: 'Request URL',
          desc: `https://openapi.playauto.io/api${action_v.url}`,
          url: `${url}#request-url`
        });
        // HTTP Method row 추가
        list.push({
          title: 'HTTP Method',
          desc: action_v.method,
          url: `${url}#http-method`
        });
        // Header 테이블 항목 row 추가
        action_v.header?.forEach(row => {
          list.push({
            title: 'Header',
            desc: formatDesc(row),
            url: `${url}#Header-table`
          });
        });
        // Body 테이블 항목 row 추가
        action_v.body?.forEach(row => {
          list.push({
            title: 'Body Parameters',
            desc: formatDesc(row),
            url: `${url}#Body-table`
          });
        });
        // Query 테이블 항목 row 추가
        action_v.query?.forEach(row => {
          list.push({
            title: 'Query Parameters',
            desc: formatDesc(row),
            url: `${url}#Query-table`
          });
        });
        // URL Parameter 테이블 항목 row 추가
        action_v.parameter?.forEach(row => {
          list.push({
            title: 'URL Parameters',
            desc: formatDesc(row),
            url: `${url}#Parameter-table`
          });
        });
        // Response 테이블 항목 row 추가
        action_v.response?.forEach(row => {
          list.push({
            title: 'Response',
            desc: formatDesc(row),
            url: `${url}#Response-table`
          });
        });
        return list.map(item => ({...item, path, authorized_m_no: action_v.authorized_m_no, authorized_aff_no: action_v.authorized_aff_no}));
      }));
    }
  }).flat(3);

  useEscModalClose(props);

  useEffect(() => {
    // 모달 로드 시 검색창에 focus 자동으로 부여
    searchBarEl.current.focus();
  });

  function getSearchResult(keyword) {
    if (!keyword) {
      return null;
    }
    keyword = keyword.toLowerCase();
    return searchData_total_list.filter(item => {
      if (item.title.toLowerCase().includes(keyword)) {
        return true;
      }
      if (item.desc?.toLowerCase().includes(keyword)) {
        return true;
      }
      return false;
    }).filter(item => {
      if (!item.authorized_m_no?.length && !item.authorized_aff_no?.length) {
        return true;
      } else if (item.authorized_m_no?.length && item.authorized_aff_no?.length) {
        return item.authorized_m_no.includes(memberNo) || item.authorized_aff_no.includes(affNo)
      } else {
        return (item.authorized_m_no?.length ? item.authorized_m_no.includes(memberNo) : true) && (item.authorized_aff_no?.length ? item.authorized_aff_no.includes(affNo) : true)
      }
    }).slice(0, 20)
      .map(item => ({
        ...item,
        desc: item.desc?.replace(new RegExp(escapeStringRegexp(keyword), 'ig'), '<b>$&</b>')
      }));
  }

  return (
    <Modal>
      <div className={classes.container}>
        <div className={classes.content}>
          <h3>검색</h3>
          <div className={classes.searchbar}>
            <FontAwesomeIcon icon={faMagnifyingGlass} />
            <input ref={searchBarEl} onChange={e => setSearchWord(e.target.value)} value={searchWord} className='focus-highlight' type='text' />
            {searchWord && <button onClick={() => setSearchWord('')} className={classes.clear}>
              <FontAwesomeIcon icon={faCircleXmark} />
            </button>}
          </div>
          {searchWord &&
            <div className={classes['brief-result']}>
              <span>"{searchWord}"</span> 검색결과: {getSearchResult(searchWord).length}건
            </div>}
          <div className={classes['search-results']}>
            {getSearchResult(searchWord)?.map((item, index) => <HashLink key={index} onClick={props.closeModal} to={item.url} scroll={el => scrollWithOffset(el, 80)}>
              <div className={classes['search-result']}>
                <div className={classes.title}>{item.title}</div>
                <div className={classes.path}>{item.path}</div>
                <div className={classes.desc} dangerouslySetInnerHTML={{ __html: item.desc }}></div>
              </div>
            </HashLink>) || <div className='absolute-center'>주문, 상품 등 궁금한 API 내용을 입력해보세요</div>}
          </div>
        </div>
        <div className={classes.footer}>
          <button onClick={props.closeModal} className={classes.close}>닫기</button>
        </div>
      </div>
    </Modal>
  );
}

export default SearchModal;

function scrollWithOffset(el, offset) {
  const elementPosition = el.offsetTop - offset;
  window.scroll({
    top: elementPosition,
    left: 0,
    behavior: 'instant'
  })
}